/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import UserTeamsDB from '@/firebase/user-teams/user-teams-db'
import { teamStatusConstants } from '@/data/teams/team-status'

export default {
  fetchAllActiveTeamsByUserId: async ({ rootState }, payload) => {
    // console.log(JSON.stringify(payload))
    const userTeamsDB = new UserTeamsDB(payload.userId)

    return userTeamsDB.readAll(payload.constraints)

    // const activeTeams = await userTeamsDB
    //   .readAll(payload.constraints)
    //   .then((response) => {
    //     return response
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     throw err
    //   })
    // if (activeTeams) {
    //   // console.log(JSON.stringify(activeTeams))
    //   return activeTeams
    //   // return Object.keys(activeTeams).map((o) => activeTeams[o]) // Converting to array for buefy table
    // }
    // return null
  },

  // getAllArchiveTeamsByUserId: async ({ commit }, userId) => {
  //   const userTeamsDB = new UserTeamsDB(userId)
  //   const constraints = []
  //   constraints.push(['teamStatus', '==', teamStatusConstants.ARCHIVE])
  //   const allArchivedUserTeams = await userTeamsDB.readAll(constraints)
  //   return allArchivedUserTeams
  // },

  getAllTeamsByUserId: async ({ commit }, userId) => {
    const userTeamsDB = new UserTeamsDB(userId)
    const allUserTeams = await userTeamsDB.readAll()
    if (allUserTeams) {
      commit('setAllUserTeams', allUserTeams)
      return Object.keys(allUserTeams).map((o) => allUserTeams[o])
    }
    return null
  },
}
