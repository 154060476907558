export default {
  setTeamBroadcasts: (state, teamBroadcasts) => (state.teamBroadcasts = teamBroadcasts),

  addToTeamBroadcast: (state, teamBroadcast) => state.teamBroadcasts.push(teamBroadcast),

  setTeamBroadcastUpdate(state, teamBroadcast) {
    const index = state.teamBroadcasts.findIndex((item) => item.id === teamBroadcast.id)
    if (index !== -1) {
      state.teamBroadcasts[index].text = teamBroadcast.text
    }
  },

  setTeamBroadcastToRemove(state, teamBroadcastId) {
    const index = state.teamBroadcasts.findIndex((item) => item.id === teamBroadcastId)
    if (index !== -1) {
      state.teamBroadcasts.splice(index, 1)
    }
  },

  setTeamBroadcastToView: (state, teamBroadcastToView) =>
    (state.teamBroadcastToView = teamBroadcastToView),

  setTeamBroadcastToEdit: (state, teamBroadcastToEdit) =>
    (state.teamBroadcastToEdit = teamBroadcastToEdit),
}
