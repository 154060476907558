<template>
  <section class="email-preferences">
    <skeleton v-if="isLoading"></skeleton>
    <siyn-collapse
      v-if="!isLoading && emailPreferences"
      :title="panelTitle"
      emoji="👍"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <div v-if="emailPreferences" class="table-container">
        <table class="table">
          <thead>
            <tr>
              <th>
                {{
                  showLabelsFromCode(
                    'profile/contactPreferences/emailCheckInterval',
                  )
                }}
                <b-tooltip
                  multilined
                  type="is-dark"
                  :label="
                    showTooltipsFromCode(
                      'profile/contactPreferences/checkMails',
                    )
                  "
                  position="is-right"
                  ><b-icon
                    class="has-text-grey-light"
                    pack="far"
                    icon="question-circle"
                    size="is-small"
                  >
                  </b-icon
                ></b-tooltip>
              </th>
              <th>
                {{
                  showLabelsFromCode(
                    'profile/contactPreferences/responseExpectations',
                  )
                }}
                <b-tooltip
                  multilined
                  append-to-body
                  type="is-dark"
                  :label="
                    showTooltipsFromCode(
                      'profile/contactPreferences/responseExpectations',
                    )
                  "
                  position="is-left"
                  ><b-icon
                    class="has-text-grey-light"
                    pack="far"
                    icon="question-circle"
                    size="is-small"
                  >
                  </b-icon
                ></b-tooltip>
              </th>
              <th>
                {{
                  showLabelsFromCode('profile/contactPreferences/actionsToTake')
                }}
                <b-tooltip
                  multilined
                  type="is-dark"
                  :label="
                    showTooltipsFromCode(
                      'profile/contactPreferences/actionsToTake',
                    )
                  "
                  position="is-left"
                  ><b-icon
                    class="has-text-grey-light"
                    pack="far"
                    icon="question-circle"
                    size="is-small"
                  >
                  </b-icon
                ></b-tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-if="emailPreferences.emailCheckingFrequency">
                {{
                  getEmailCheckingFrequency(
                    emailPreferences.emailCheckingFrequency,
                  )
                }}
              </td>
              <td>{{ emailPreferences.escalationThresholdHours }} hrs</td>
              <td>{{ getEscalationPath(emailPreferences.escalationPath) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-if="emailPreferences" class="pr-3 pl-3">
        {{ showUserMessageFromCode('contactPreferences/prefer') }}
        <b
          ><u class="firstletter">{{
            emailPreferences.internalPreference | formatCommPref
          }}</u></b
        >
        {{ showUserMessageFromCode('contactPreferences/medium') }}
        <b
          ><u class="firstletter">{{
            emailPreferences.externalPreference | formatCommPref
          }}</u></b
        >
        {{ showUserMessageFromCode('contactPreferences/external') }}
      </p>
      <div v-if="!emailPreferences">
        <p class="pl-6">{{ showUserMessageFromCode('generic/none') }}</p>
      </div>
    </siyn-collapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
/* eslint-disable func-names */
import { mapState, mapActions, mapMutations } from 'vuex'
import { viewProfileMixin } from '@/mixins/profile/index'
import {
  emailCheckingFrequencies,
  escalationPaths,
} from '@/data/contactPreferences'

export default {
  name: 'ViewEmailPreferences',
  filters: {
    formatCommPref(value) {
      if (value) {
        return value
          .split(/(?=[A-Z])/)
          .map(function (p) {
            return p.charAt(0).toUpperCase() + p.slice(1)
          })
          .join(' ')
      }
      return null
    },
  },
  components: {},
  mixins: [viewProfileMixin],
  data() {
    return {
      emailCheckingFrequencies,
      escalationPaths,
      panelTitle: this.showLabelsFromCode(
        'profile/contactPreferences/panelTitle',
      ),
      panelname: 'contactPreferences_view',
      isLoading: true,
    }
  },
  computed: {
    ...mapState('emailpreferences', ['emailPreferences']),
  },
  watch: {
    mergedPanelsConfig: {
      handler(mergedPanelsConfig) {
        if (!this.isEmpty(mergedPanelsConfig) && this.profileUser) {
          if (
            this.getAuthUserId === this.$route.params.uid ||
            this.getAuthUserCompanyId === this.profileUser.companyId
          ) {
            this.fetchEmailPreferencesContent()
          } else if (
            mergedPanelsConfig.contactPreferences ===
            this.visibilityConstants.VISIBLE_TO_ALL
          ) {
            this.fetchEmailPreferencesContent()
          } else if (
            mergedPanelsConfig.contactPreferences ===
              this.visibilityConstants.VISIBLE_TO_MY_NW &&
            this.checkIfYouAreInMyNetwork({
              userId: this.profileUser.id,
              network: this.network,
            })
          ) {
            this.fetchEmailPreferencesContent()
          }
        }
      },
    },
    deep: true,
    immediate: true,
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('emailpreferences', ['setEmailPreferences']),
    ...mapActions('emailpreferences', ['fetchEmailPreferencesByUserId']),

    fetchEmailPreferencesContent() {
      this.fetchEmailPreferencesByUserId(this.$route.params.uid)
        .then((emailPreferencesResponse) => {
          if (emailPreferencesResponse) {
            this.nextTick(() => {
              this.setEmailPreferences(this.first(emailPreferencesResponse))
            })
          }
        })
        .catch((err) => {
          if (err.code !== 'permission-denied') {
            console.log(err)
            this.handleErrorMessage(
              `${this.showUserMessageFromCode(
                'generic/error-in-fetch',
              )} contact preferences.`,
            )
          }
          this.setEmailPreferences(null)
          this.trackExceptionEvent(
            'email-preferences_fetch_failed',
            `${
              this.user ? this.user.id : 'visitor'
            } tried to fetch email preferences for ${
              this.$route.params.uid
            }. ${err}`,
            false,
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getEscalationPath(id) {
      return this.getNameForId(id, this.escalationPaths) || 'None'
    },
    getEmailCheckingFrequency(id) {
      return this.getNameForId(id, this.emailCheckingFrequencies) || 'None'
    },
  },
}
</script>
<style lang="scss">
.view-email-preferences {
  .content .table td,
  .content .table th {
    border-color: $primary;
  }
}
</style>
