/* eslint-disable no-unused-vars */

/* eslint-disable import/extensions */

import isNil from 'lodash/isNil'
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth'

import { auth } from '@/firebase/initFirebase'
import router from '@/router'
import UsersDB from '@/firebase/users/users-db'
import { trackEngagementEvent } from '@/misc/analytics'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'
import { parseFirebaseError, showUserMessageFromCode } from '@/misc/helpers'
import { networkStatusConstants } from '@/data/networkStatus'

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit, dispatch, rootState }, firebaseAuthUser) => {
    commit('setAuthMessages', null)
    commit('setUser', undefined)

    if (firebaseAuthUser.uid) {
      // this is because exisiting users in FB have uid (they may or maynot be in database) while new ones have additionaluserinfo

      /* From authentication.js upon authStateChanged it comes here, this always gets
         executed if its register or login. Should not check for new user here since
         i will never get  authResult.additionalUserInfo.isNewUser
    */
      // console.log(`In user.actions line 25 ${JSON.stringify(firebaseAuthUser)}`)

      const { uid, photoURL, email, displayName, emailVerified, providerData } =
        firebaseAuthUser

      // console.log(JSON.stringify(`User Id is ${uid}`))
      // console.log(JSON.stringify(`Email verificaiton is ${emailVerified}`))

      const usersDb = new UsersDB()
      const user = await usersDb.read(uid)

      if (!isNil(user)) {
        // console.log(JSON.stringify(user))
        /* --------------------------------------------------------------------------
        // checking if user is in the database, if yes then login the user. In Login.vue it will get redirected to home
        /* --------------------------------------------------------------------------
          I already tried checking this in watch in login instead of doing it here
          however this is much faster and reliable then using watch.
        -------------------------------------------------------------------------- */

        const constraints = []
        // constraints.push(['status', '==', networkStatusConstants.REQUEST_APPROVED])
        await dispatch('populateNetwork', {
          userId: auth.currentUser.uid,
          constraints,
        })
        await dispatch('populateUserCompany', { user })

        // dispatch('populatePrivateData', {
        //   userId: auth.currentUser.uid,
        //   providerId: first(providerData).providerId,
        // })
        await dispatch('populateCustomClaims')
        await dispatch('populateNotifications', {
          userId: user.id,
        })
        handleSuccessMessage(showUserMessageFromCode('auth/login-success'))
        commit('setUser', user)
        dispatch('logUser', user)
        // Based on login.vue and checklogin the user will be now taken to either their /home or redirectURL
      }
      if (!emailVerified) {
        // user is not in DB but only in firebaseAuth with email not verified so we need to ask to verify email.
        try {
          await sendEmailVerification(auth.currentUser)
          commit('setFetchingUser', false)
        } catch (error) {
          console.log(error)
        } finally {
          handleSuccessMessage('Email Verification Sent')
          commit(
            'setAuthMessages',
            showUserMessageFromCode('auth/email-unverified'),
          )
          await auth.signOut()
          router
            .push({
              name: 'verifyemail',
              params: { useremail: email },
            })
            .catch(() => {})
        }
      }
      if (isNil(user) && emailVerified) {
        commit(
          'setAuthMessages',
          showUserMessageFromCode('auth/email-verified-no-data'),
        )
        router.push('/login')
      }
      /* if (isNil(user) && emailVerified) {
        router.push({ path: `/home` }).catch(() => {})
        //  user is not in DB but is in FB Auth with verified email so we need to create DB record
        // console.log(JSON.stringify('I am creating the user'))
        // console.log(`user id is ${user.id}`)
        // console.log(`emailVerified is ${emailVerified}`)
        /*         await dispatch('createUser', firebaseAuthUser)
          .then((newUser) => {
            // console.log(JSON.stringify(newUser))

            if (!isNil(newUser) && newUser.id) {
              dispatch('populatePrivateData', {
                userId: newUser.id,
                providerId: first(providerData).providerId,
              })
              dispatch('createUserAppConfig', firebaseAuthUser.uid)
              commit('setUser', newUser)
              dispatch('logUser', newUser)
              handleSuccessMessage(
                showUserMessageFromCode('auth/login-success'),
              )
              commit('setFetchingUser', false)
              router.push({ path: `/home` }).catch(() => {})
            }
          })
          .catch((err) => {
            console.log(err)
          })  
      } */
    } else {
      console.log('Is Brand New user, Redirecting them to register screen')
      commit('setFetchingUser', false)
      handleSuccessMessage(showUserMessageFromCode('auth/pleaseregister'))
      router.push('/register')
    }
  },
  fetchUserByUserId: async ({ commit }, userId) => {
    const usersDB = new UsersDB(userId)
    return usersDB.read(userId) // when returning a Promise inside an async function, you don’t need to use await
  },

  updateUser: async ({ commit }, userRecord) => {
    const usersDB = new UsersDB(userRecord.id)
    return usersDB.update(userRecord)
  },

  populateCustomClaims: async ({ commit }, payload) => {
    await auth.currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        // Confirm the user is an Admin.
        if (idTokenResult.claims) {
          if (idTokenResult.claims.companyId) {
            commit('setAuthCompanyId', idTokenResult.claims.companyId)
          }
          if (idTokenResult.claims.role) {
            commit('setAuthUserRole', idTokenResult.claims.role)
          }

          commit('setAuthLicenseType', idTokenResult.claims.licenseType)
        }
        return null
      })
      .catch((error) => {
        console.log(error)
        return null
      })
    return true
  },
  updateFirebaseAuthUserProfile: async ({ commit }, payload) => {
    updateProfile(auth.currentUser, {
      displayName: payload.displayName,
      photoURL: payload.photoURL || '',
    }).catch((error) => {
      console.log(error)
      throw error
    })
  },

  logout: ({ commit }) => {
    // Auth Signout is happening in Navbar!
    trackEngagementEvent('logged_out', 'Log out', 'navbarmenu', 'link')
    /* TODO Instead of doing below just create 1 mutation that initialized everythign to null */
    commit('setUser', null)
    commit('setUserCompany', null)
    commit('setAuthCompanyId', null)
    commit('setAuthLicenseType', null)
    commit('setAuthUserRole', null)

    commit('officehours/setOfficeHours', null, { root: true })
    commit('profileUser/setProfileUser', null, { root: true })
    commit('home/setAllNetworkEvents', null, { root: true })
    commit('home/setAllNetworkUpdates', null, { root: true })
    commit('home/setAllTeamEvents', null, { root: true })
    commit('home/setAllRecurringTeamEvents', null, { root: true })
    commit('home/setAllTeamBulletins', null, { root: true })
    commit('home/setAllTeamBroadcasts', null, { root: true })
    commit('home/setAllTeamUpdates', null, { root: true })
    commit('home/setAllCompanyEvents', null, { root: true })
    commit('search/setAllCompanyUsers', null, { root: true })
    commit('search/setAllCompanyTeams', null, { root: true })
    commit('search/setAllTeamEvents', null, { root: true })
    commit('search/setAllCompanyEvents', null, { root: true })
    commit('appconfig/setProfileUserAppConfig', {}, { root: true })
    commit('appconfig/setProfileUserSchedulerDefaults', null, { root: true })
    commit('appconfig/setMergedAppConfig', {}, { root: true })
    commit('events/setEvents', null, { root: true })
    commit('events/setAllEvents', [], { root: true })
    commit('links/setLinks', null, { root: true })
    commit('teams/setIsUserATeamSubscriber', null, { root: true })
    commit('teams/setIsUserTeamAdminCache', null, { root: true })
    commit('teams/setIsUserPartOfTeamCache', null, { root: true })
    commit('leisures/setLeisures', null, { root: true })
    commit('comments/setComments', null, { root: true })
    commit('emailpreferences/setEmailPreferences', null, { root: true })
    commit('networkStatus/setNetwork', null, { root: true })
    commit('notifications/setNotifications', null, { root: true })
    commit('userTeams/setAllActiveUserTeams', null, { root: true })
    commit('redirects/setRedirects', null, { root: true })
    commit('mentoring/setTopics', null, { root: true })
    commit('company/setCompany', null, { root: true })
    commit('mentoring/setMentoringTopicToView', null, { root: true })
    commit('mentoring/setMentoringTopicToEdit', null, { root: true })
    commit('app/resetAppErrors', null, { root: true })
    commit('company/setCompanyAdmins', undefined, { root: true })
    commit('company/setCompanyNetwork', undefined, { root: true })
    commit('company/setCompanyBroadcasts', [], { root: true })
    commit('company/setCompanyLocations', [], { root: true })
    commit('company/setCompanyEvents', [], { root: true })
    commit('company/setCompanyTokens', [], { root: true })
    commit('company/setIsUserCompanyAdmin', false, { root: true })
    commit('company/setIsUserInSameCompany', false, { root: true })

    commit('company/setCompanyAppConfig', {}, { root: true })

    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push('/index', () => {})
    }
  },

  /*  createUser: ({ commit }, firebaseAuthUser) => {
    const { uid, photoURL, email, displayName, emailVerified, providerData } =
      firebaseAuthUser
    const { providerId } = first(providerData)
    const usersDb = new UsersDB()
    return usersDb
      .create(
        {
          photoURL,
          displayName,
          emailVerified,
          providerId,
          email,
          companyId: 'cnb.com',
          companyName: 'City National Bank',
          licenseType: 'ENT',
        },
        uid,
      )
      .then((userResponse) => {
        if (userResponse) {
          return userResponse
        }
        throw new Error('Unknown error in creating user!')
      })
      .catch((error) => {
        console.log(error)
        throw error
      })
  }, */
  logUser: async ({ commit }, user) => {
    trackEngagementEvent('user_logged_in', 'Log In', undefined, undefined)
    console.log(
      `User ${user.id} is logged in at ${new Date().toLocaleString()}. `,
    )
    commit('setFetchingUser', false)
  },

  populateUserCompany: async ({ commit, dispatch }, payload) => {
    await dispatch('company/fetchCompanyByCompanyId', payload.user.companyId, {
      root: true,
    })
      .then((result) => {
        commit('setUserCompany', result)
      })
      .catch((err) => {
        commit('setUserCompany', null)
      })
  },

  // populatePrivateData: ({ commit, dispatch }, payload) => {
  //   // console.log(JSON.stringify(payload))
  //   dispatch(
  //     'privateinfo/createUpdateUserPrivateData',
  //     {
  //       userId: payload.userId,
  //       providerId: payload.providerId,
  //     },
  //     { root: true },
  //   ).catch((err) => {
  //     console.log(
  //       `Storing private data failed for ${
  //         payload.userId
  //       } Error is ${JSON.stringify(err)}`,
  //     )
  //   })
  // },
  // // 11.18 removed async
  populateNetwork: async ({ commit, dispatch }, payload) => {
    await dispatch(
      'networkStatus/fetchActiveNetworkByUserId',
      {
        userId: payload.userId,
        constraints: payload.constraints,
      },
      { root: true },
    )
      .then((result) => {
        console.log(JSON.stringify('network is populated'))
        commit('networkStatus/setNetwork', result, { root: true })
      })
      .catch((err) => {
        commit('networkStatus/setNetwork', null, { root: true })
      })
    // console.log('network should now be loaded')
  },
  populateNotifications: ({ commit }, payload) => {
    console.log("Don't forget to fetch notifications")
  },
  /*   fetchUserAvatarFullPath: async ({ commit, dispatch }, uid) => {
    const ref = firebase.storage().ref(`avatars/${uid}/profile.png`)
    return ref
      .getDownloadURL()
      .then((url) => {
        if (url) {
          return url
        }
        return null
      })
      .catch((error) => {
        return null
      })
  },
  createUserAppConfig: ({ commit, dispatch }, userId) => {
    // console.log(JSON.stringify(userId))
    dispatch(
      'appconfig/createUserAppPanelsConfig',
      {
        userId,
      },
      { root: true },
    )
      .then((result) => {
        commit('appconfig/setProfileUserAppConfig', result, { root: true })
      })
      .catch((err) => {
        console.log(
          `Storing app config data failed for ${userId} Error is ${JSON.stringify(
            err,
          )}`,
        )
      })
  }, */

  registerNewUserWithGoogle: async ({ commit }, payload) => {
    console.log(JSON.stringify(payload))
    if (
      window.location.hostname === 'localhost' &&
      process.env.VUE_APP_USE_EMULATOR === 'true'
    ) {
      connectAuthEmulator(auth, 'http://127.0.0.1:9099')
    }
    const provider = new GoogleAuthProvider()
    const newUser = await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential.accessToken
        // The signed-in user info.
        const { user } = result
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        return user
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error)
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const { email } = error.customData
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error)
        // ...
        throw error
      })

    return newUser
  },
  registerNewUserEmailPassword: async ({ commit }, payload) => {
    //    console.log(JSON.stringify(payload))
    if (
      window.location.hostname === 'localhost' &&
      process.env.VUE_APP_USE_EMULATOR === 'true'
    ) {
      connectAuthEmulator(auth, 'http://127.0.0.1:9099')
    }

    const newUser = await createUserWithEmailAndPassword(
      auth,
      payload.email,
      payload.password,
    )
      .then((userCredential) => {
        const { user } = userCredential
        updateProfile(auth.currentUser, {
          displayName: payload.displayName,
        })
          .then(() => {
            return user
          })
          .catch((err) => {
            throw err
          })
      })
      .catch((error) => {
        throw error
      })
    return newUser
  },
}
