export default {
  setRedirects: (state, value) => (state.redirectsList = value),
  addToRedirectsList: (state, value) => state.redirectsList.push(value),

  setRedirectToEdit: (state, value) => (state.redirectToEdit = value),
  setRedirectToView: (state, value) => (state.redirectToView = value),
  setCurrentRedirect: (state, value) => (state.currentRedirect = value),

  setRedirectToRemove(state, redirectId) {
    const index = state.redirectsList.findIndex((item) => item.id === redirectId)
    if (index !== -1) {
      state.redirectsList.splice(index, 1)
    }
  },

  setRedirectUpdate(state, redirect) {
    // console.log(JSON.stringify(redirect))
    const index = state.redirectsList.findIndex((item) => item.id === redirect.id)
    if (index !== -1) {
      state.redirectsList[index] = redirect
    }
  },
}
