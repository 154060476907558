<template>
  <section class="list-company-newsletteradmins">
    <div class="container">
      <div :panel-title="panelTitle">
        <b-loading
          v-model="isLoading"
          :is-full-page="false"
          :can-cancel="false"
        >
          <b-icon
            pack="fas"
            icon="spinner"
            size="is-large"
            custom-class="fa-pulse"
            type="is-primary"
          >
          </b-icon
        ></b-loading>
        <SynPanel
          :title="`${showLabelsFromCode('generic/edit')} ${showLabelsFromCode(
            'company/newsletter',
          )} ${showLabelsFromCode('generic/admin')}`"
        >
          <div v-if="companyToView.newsletterAdmins">
            <b-table
              :data="companyToView.newsletterAdmins"
              hoverable
              mobile-cards
              class="list-company-admins__table"
            >
              <b-table-column
                v-slot="props"
                scope="props"
                field="displayName"
                :label="showLabelsFromCode('generic/name')"
                cell-class="valign"
                sortable
              >
                <router-link
                  class="no-text-decoration"
                  :to="{
                    name: 'profile',
                    params: {
                      uid: props.row.id,
                    },
                  }"
                  replace
                >
                  <span class="ml-2">
                    {{ props.row.displayName }}
                  </span></router-link
                >
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="id2"
                label="Delete"
                centered
                cell-class="valign"
              >
                <span>
                  <a
                    class="no-text-decoration"
                    @click="deleteCompanyNewsletterAdmin(props.row)"
                  >
                    <b-icon
                      icon="trash-alt"
                      pack="fa"
                      type="is-danger"
                      size="is-small"
                    >
                    </b-icon> </a
                ></span>
              </b-table-column>
            </b-table>
          </div>
          <div v-else>
            <span class="empty-content">{{
              showLabelsFromCode('generic/none')
            }}</span>
          </div>
          <div class="button-group has-text-centered mt-4">
            <b-field grouped position="is-centered">
              <div class="control">
                <b-button
                  pack="far"
                  type="is-info"
                  class="button"
                  @click="close()"
                >
                  Close
                </b-button>
              </div>
            </b-field>
          </div>
        </SynPanel>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapActions, mapMutations } from 'vuex'
import { editCompanyMixin } from '@/mixins/company/index'
import SynPanel from '@/components/SynPanel.vue'

export default {
  name: 'EditCompanyNewsletterAdmin',
  components: { SynPanel },
  mixins: [editCompanyMixin],
  props: {},
  data() {
    return {
      panelTitle: `${this.showLabelsFromCode(
        'generic/edit',
      )} ${this.showLabelsFromCode(
        'company/newsletter',
      )} ${this.showLabelsFromCode('generic/admin')}`,
      isLoading: true,
    }
  },
  computed: {
    ...mapState('company', ['companyToView']),
  },
  created() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapActions('company', ['removeNewsletterAdminAction']),
    ...mapMutations('company', ['removeNewsletterAdminInCache']),
    deleteCompanyNewsletterAdmin(newsletterAdmin) {
      if (
        // eslint-disable-next-line no-alert
        window.confirm('Are you sure you want to remove the newsletter admin?')
      ) {
        this.removeNewsletterAdminAction({
          newsletterAdmin,
          company: this.companyToEdit,
          user: this.user,
        })
          .then(() => {
            this.trackEngagementEvent(
              'userNewsletterAdmin_removed',
              `${newsletterAdmin.displayName} was removed as Newsletter Admin for ${this.companyId}`,
              'page',
              'button',
            )
            this.removeNewsletterAdminInCache(newsletterAdmin.id)
            this.$emit('remove_successful', newsletterAdmin)
            this.close()
          })
          .catch((err) => {
            this.trackExceptionEvent(
              'remove_newsletterAdmin_failed',
              err.message,
              false,
            )
          })
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
