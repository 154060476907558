<template>
  <div class="view-podcasts">
    <div class="view-podcasts__heading view-podcasts__heading--danger">
      <span class="view-podcasts__heading--emoji">🎧</span
      ><span class="view-podcasts__heading--title">Currently listening to</span>
      <b-button
        tag="router-link"
        :to="{
          name: 'listpodcasts',
          params: {
            uid: profileUser.id,
            mode: 'view',
            fromScreen: 'viewprofile',
            podcasts: podcasts,
          },
        }"
        class="view-podcasts__heading--button"
        size="is-small"
        icon-left="star"
        pack="fas"
        type="is-ghost"
        >{{ showLabelsFromCode('generic/ratings') }}</b-button
      >
    </div>
    <div
      class="columns is-mobile is-multiline is-vcentered is-narrow is-gapless mt-2"
    >
      <div
        v-for="(podcast, index) in podcasts"
        :key="podcast.id"
        class="column is-3"
      >
        <a
          class="view-podcasts__link"
          :href="podcast.link ? addhttp(podcast.link) : '#'"
          :target="podcast.link ? '_blank' : '_self'"
          @click="
            trackEngagementEvent(
              'podcast_clicked',
              `${podcast.title} by ${podcast.author}`,
              podcast.link,
              `User ${user.id} clicked podcast ${index + 1} of ${
                podcasts.length
              } `,
            )
          "
        >
          <img
            class="view-podcasts__coverimage border-color--danger"
            :src="podcast.coverImagePath"
            :alt="`${podcast.title} by ${podcast.author}`"
            :title="`${podcast.title}`"
            @error="setAltImg"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { addhttp } from '@/misc/helpers'
import { viewProfileMixin } from '@/mixins/profile/index'

export default {
  name: 'ViewPodcasts',
  mixins: [viewProfileMixin],
  props: {
    podcasts: Object,
  },
  data() {
    return {
      noCoverImage: process.env.VUE_APP_NO_COVER_IMAGE,
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },

  methods: {
    setAltImg(e) {
      e.target.onerror = null
      e.target.src = this.noCoverImage
    },

    addhttp,
  },
}
</script>

<style lang="scss" scoped></style>
