/* eslint-disable no-unused-vars */

import TeamBroadcastsDB from '@/firebase/teams/team-broadcasts'

export default {
  fetchTeamBroadcastsByTeamId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const teamBroadcastsDB = new TeamBroadcastsDB(payload.teamId)
    return teamBroadcastsDB.readAllWithOrderByLimit(
      payload.constraints,
      payload.orderBys,
      payload.limit,
    )
  },

  fetchTeamBroadcastsById: async ({ commit }, payload) => {
    const teamBroadcastsDB = new TeamBroadcastsDB(payload.teamId)
    return teamBroadcastsDB.read(payload.id)
  },

  createTeamBroadcast: async ({ commit }, payload) => {
    const teamBroadcastsDB = new TeamBroadcastsDB(payload.teamId)
    return teamBroadcastsDB.create(payload.teamBroadcast)
  },

  updateTeamBroadcast: async ({ commit }, payload) => {
    const teamBroadcastsDB = new TeamBroadcastsDB(payload.teamId)
    return teamBroadcastsDB.update(payload.teamBroadcast)
  },

  deleteTeamBroadcast: async ({ commit }, payload) => {
    const teamBroadcastsDB = new TeamBroadcastsDB(payload.teamId)
    return teamBroadcastsDB.delete(payload.teamBroadcastId)
  },
}
