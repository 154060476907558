/* eslint-disable */
import '@/firebase/initFirebase'
import Vue from 'vue'
import Buefy from 'buefy'
import './scss/app.scss'

// import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'

import store from './store'
// import '@/misc/register-service-worker'
import '@/firebase/authentication'
// import '@/misc/handle-apple-install-prompt'
// import 'pwacompat'
import '@/misc/vee-validate'
//import '@/misc/utils/logger'

import '@/misc/handle-network-status'

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.config.debug = true
Vue.config.silent = true

Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

/* Sentry.init({
  Vue,
  dsn: 'https://25ff3d453b4b46938aefa727ce387be1@o4505149994434560.ingest.sentry.io/4505149995941888',
})
 */

/* console.log(
  `fetching User value is ---- ${JSON.stringify(store.state.user.fetchingUser)}`
) */

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
