<template>
  <article class="box media p-4 pl-6 has-background-white">
    <figure class="media-left">
      <span class="image is-256x64">
        <div class="b-skeleton is-animated">
          <div
            class="b-skeleton-item is-rounded"
            style="height: 156px; width: 156px; border-radius: 50%"
          ></div>
        </div>
      </span>
    </figure>
    <div class="media-content">
      <div class="content">
        <span>
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item is-rounded"></div>
          </div>
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item is-rounded" style="height: 40px"></div>
          </div>
          <div class="b-skeleton is-animated">
            <div class="b-skeleton-item is-rounded" style="height: 40px"></div>
          </div>
        </span>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'SkeletonMedia',
}
</script>
