<template>
  <section class="view-company-network">
    <skeleton v-if="isLoading"></skeleton>
    <SiynCollapse
      v-if="companyToView && !isLoading"
      :title="panelTitle"
      emoji="🤝"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <div class="columns is-multiline is-gapless mb-0">
        <template v-for="item in companyNetwork">
          <small-card
            :key="item.id"
            class="column is-4 is-narrow"
            :uid="item.id"
            :display-name="item.friend.displayName"
            :title="item.friend.title"
          />
        </template>
      </div>
      <span v-if="isEmpty(companyNetwork)" class="ml-4 empty-content">{{
        showUserMessageFromCode('generic/none')
      }}</span>
    </SiynCollapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations } from 'vuex'
import { viewCompanyMixin } from '@/mixins/company/index'
import { networkStatusConstants } from '@/data/networkStatus'
import SmallCard from '@/components/SmallCard'

export default {
  name: 'CompanyNetwork',
  components: { SmallCard },

  mixins: [viewCompanyMixin],
  data() {
    return {
      isLoading: true,
      panelTitle: '',
      panelname: 'company_network',
      networkStatusConstants,
    }
  },
  computed: {
    ...mapState('company', ['companyNetwork']),
    ...mapState('user', ['user']),
  },
  watch: {
    companyToView: {
      handler(companyToView) {
        if (companyToView) {
          this.panelTitle = `My Network in ${companyToView.companyName}`
        }
      },
      deep: true,
      immediate: true,
    },
    user: {
      handler(user) {
        if (user && user.id) {
          this.fetchNetwork()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('company', ['setCompanyNetwork']),
    async fetchNetwork() {
      const constraints = []
      constraints.push([
        'status',
        '==',
        networkStatusConstants.REQUEST_APPROVED,
      ])
      constraints.push(['friend.companyId', '==', this.$route.params.companyid])
      await this.$store
        .dispatch(
          'networkStatus/fetchActiveNetworkByUserId',
          {
            userId: this.user?.id,
            constraints,
          },
          { root: true },
        )
        .then((result) => {
          // console.log(JSON.stringify(result))
          this.setCompanyNetwork(result)
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('company/setCompanyNetwork', null, { root: true })
          this.handleErrorMessage(
            this.showUserMessageFromCode('auth/fetch-network-failed'),
          )
        })
    },
    /*     openEditModalScreen(team) {
      this.setTeamToEdit(team)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditTeamInfoForm,
        events: {
          update_successful: () => {
            this.handleSuccessMessage(
              this.showUserMessageFromCode('teams/update/success')
            )
          },
          trapFocus: true,
        },
      })
    }, */
  },
}
</script>
