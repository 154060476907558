export default {
  teams: {},
  newTeamCreated: null,
  teamToEdit: null,
  teamToView: null,
  isUserTeamAdminCache: false,
  isUserPartOfTeamCache: false,
  isUserATeamSubscriber: false,
  isUserInSameCompanyAsTeam: false,
  teamErrors: {
    teamNotFound: false,
    notAllowed: false,
    unknown: false,
  },
}
