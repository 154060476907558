import state from './company.state'
import mutations from './company.mutations'
import actions from './company.actions'
import getters from './company.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
