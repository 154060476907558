<template>
  <section class="list-company-locations">
    <div :panel-title="panelTitle">
      <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="false">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon
      ></b-loading>
      <div v-if="!isLoading && companyLocations">
        <b-table
          :data="companyLocations"
          hoverable
          mobile-cards
          class="list-company-locations__table"
          :show-header="companyLocations.length > 0 ? true : false"
          default-sort="createTimestamp"
          default-sort-direction="desc"
        >
          <template #empty>
            <p>{{ showUserMessageFromCode('generic/none') }}</p>
          </template>
          <b-table-column
            v-slot="props"
            scope="props"
            field="props.row"
            :label="showLabelsFromCode('generic/address')"
            cell-class="valign"
            sortable
          >
            <span> {{ props.row.address }} </span>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="id"
            label="Delete"
            centered
            cell-class="valign"
          >
            <a
              class="no-text-decoration ml-2"
              @click="deleteCompanyLocationsVue(props.row.id)"
            >
              <b-tooltip
                :label="showLabelsFromCode('generic/delete')"
                position="is-left"
                type="is-dark"
              >
                <b-icon
                  icon="trash-alt"
                  pack="fa"
                  type="is-danger"
                  size="is-medium"
                >
                </b-icon>
              </b-tooltip>
            </a>
          </b-table-column>
        </b-table>
      </div>
      <div class="button-group has-text-centered mt-4">
        <b-field grouped position="is-centered">
          <div class="control">
            <b-button pack="far" type="is-info" class="button" @click="close()">
              Close
            </b-button>
          </div>
        </b-field>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapActions, mapMutations } from 'vuex'
// import { editProfileMixin } from '@/mixins/profile/index'
import { trackPageView, trackScreenView } from '@/misc/analytics'

import { editCompanyMixin } from '@/mixins/company/index'

export default {
  name: 'ListCompanyLocationsForEdit',
  components: {},
  mixins: [editCompanyMixin],
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      panelTitle: 'List Company Locations for edit',
      isLoading: true,
    }
  },
  metaInfo: {
    title: 'List Company Locations for Edit',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  computed: {
    ...mapState('company', ['companyToView', 'companyLocations']),
  },
  created() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapActions('company', ['deleteCompanyLocation']),
    ...mapMutations('company', ['removeCompanyLocationById']),
    close() {
      this.$emit('close')
    },
    deleteCompanyLocationsVue(companyLocationId) {
      if (
        // eslint-disable-next-line no-alert
        window.confirm('Are you sure you want to delete the company location?')
      ) {
        this.isLoading = true
        this.deleteCompanyLocation({
          companyId: this.companyToView.id,
          companyLocationId,
        })
          .then(() => {
            this.handleSuccessMessage('Location deleted successfully!')
            this.$emit('remove_successful')
            this.removeCompanyLocationById(companyLocationId)
          })
          .catch((err) => {
            console.log(err)
            this.handleErrorMessage(
              this.showUserMessageFromCode('generic/error/unknown/title'),
            )
          })
          .finally(() => (this.isLoading = false))
      }
    },
  },
}
</script>
