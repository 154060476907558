<template>
  <div class="team-booking-details">
    <div class="box mb-4">
      <div class="columns is-vcentered">
        <div class="column is-10">
          <div class="title is-4 has-text-weight-normal">
            {{ showLabelsFromCode('generic/team') }}
            {{ showLabelsFromCode('scheduling/schedulingpage') }} for
            <router-link
              v-if="user"
              target="_blank"
              :to="{
                name: 'viewteam',
                params: { id: bookingRecord?.team?.id },
              }"
            >
              <span class="team-booking-details__teamName"
                >{{ bookingRecord?.team?.teamName }}
              </span>
            </router-link>
            <span v-else>{{ bookingRecord?.team?.teamName }}</span>

            <template v-if="companyName">
              at
              <router-link
                v-if="companyName"
                target="_blank"
                :to="{
                  name: 'viewcompany',
                  params: { companyid: bookingRecord?.companyId },
                }"
              >
                <view-user-avatar
                  v-if="companyLogoImageURL"
                  class="team-booking-details__companyImageURL"
                  :alt-name="companyName"
                  :imgsrc="companyLogoImageURL"
                />
                {{ companyName }}</router-link
              >
            </template>
          </div>
        </div>

        <div class="column is-2 has-text-right">
          <b-button
            class="info__edit-button ml-2"
            title="Share event"
            type="is-info"
            icon-left="share-from-square"
            pack="fas"
            @click="openShareModal()"
            >Share
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="bookingRecord?.showTeamMembers" class="box mb-4">
      <div class="columns is-gapless is-vcentered">
        <div class="column is-12">
          <span class="valign-super sm-label mr-2"
            >{{ showLabelsFromCode('teams/teammembers/panelTitle') }}:</span
          >
          <span
            v-for="teamMember in bookingRecord?.teamMembersList"
            :key="teamMember.id"
            class="is-inline-block mr-2"
          >
            <router-link
              target="_blank"
              :to="{
                name: 'profile',
                params: { uid: teamMember.id },
              }"
              class="no-text-decoration is-block mt-1"
            >
              <span
                v-if="teamMember?.photoURL"
                class="team-booking-details__imageURL"
              >
                <img
                  :src="teamMember.photoURL"
                  :alt="teamMember.displayName"
                  @error="setAltImg"
                />
              </span>
              <span class="team-booking-details__memberName valign-super"
                >{{ teamMember?.displayName }}
              </span>
            </router-link>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import isEmpty from 'lodash/isEmpty'

import { mapState, mapActions } from 'vuex'
import { truncateText, showLabelsFromCode } from '@/misc/helpers'
import ShareLink from '@/components/ShareLink'
import ViewUserAvatar from '@/components/ViewUserAvatar'

export default {
  name: 'MeetingDetails',
  components: { ViewUserAvatar },
  props: {
    bookingRecord: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      companyName: null,
      companyLogoImageURL: null,
    }
  },

  computed: {
    ...mapState('user', ['user']),
  },
  watch: {
    bookingRecord: {
      handler(bookingRecord) {
        if (bookingRecord.companyId) {
          //  console.log(JSON.stringify(bookingRecord.companyId))
          this.fetchCompany()
        }
      },
    },
  },
  created() {},
  mounted() {
    this.isLoading = false
    if (this.bookingRecord?.companyId) {
      // console.log(JSON.stringify(this.bookingRecord.companyId))
      this.fetchCompany()
    }
  },
  methods: {
    ...mapActions('company', ['fetchCompanyByCompanyId']),
    truncateText,
    showLabelsFromCode,
    setAltImg(e) {
      e.target.onerror = null
      e.target.src = `${this.firebaseStorageBaseURL}/assets%2Fmissing-profile-image.png?alt=media`
    },
    async fetchCompany() {
      await this.fetchCompanyByCompanyId(this.bookingRecord.companyId)
        .then((companyResponse) => {
          if (!isEmpty(companyResponse)) {
            // console.log(JSON.stringify(companyResponse))
            this.companyName = companyResponse.companyName
            this.companyLogoImageURL = companyResponse.logoImageURL
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    openShareModal() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        props: {
          url: window.location.href,
          pageName: this.bookingRecord?.pageName,
        },
        component: ShareLink,
        trapFocus: true,
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
