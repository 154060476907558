var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"email-preferences"},[(_vm.isLoading)?_c('skeleton'):_vm._e(),(!_vm.isLoading && _vm.emailPreferences)?_c('siyn-collapse',{attrs:{"title":_vm.panelTitle,"emoji":"👍","is-open":_vm.collapseHelper(_vm.panelname),"panelname":_vm.panelname}},[(_vm.emailPreferences)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.showLabelsFromCode( 'profile/contactPreferences/emailCheckInterval', ))+" "),_c('b-tooltip',{attrs:{"multilined":"","type":"is-dark","label":_vm.showTooltipsFromCode(
                    'profile/contactPreferences/checkMails',
                  ),"position":"is-right"}},[_c('b-icon',{staticClass:"has-text-grey-light",attrs:{"pack":"far","icon":"question-circle","size":"is-small"}})],1)],1),_c('th',[_vm._v(" "+_vm._s(_vm.showLabelsFromCode( 'profile/contactPreferences/responseExpectations', ))+" "),_c('b-tooltip',{attrs:{"multilined":"","append-to-body":"","type":"is-dark","label":_vm.showTooltipsFromCode(
                    'profile/contactPreferences/responseExpectations',
                  ),"position":"is-left"}},[_c('b-icon',{staticClass:"has-text-grey-light",attrs:{"pack":"far","icon":"question-circle","size":"is-small"}})],1)],1),_c('th',[_vm._v(" "+_vm._s(_vm.showLabelsFromCode('profile/contactPreferences/actionsToTake'))+" "),_c('b-tooltip',{attrs:{"multilined":"","type":"is-dark","label":_vm.showTooltipsFromCode(
                    'profile/contactPreferences/actionsToTake',
                  ),"position":"is-left"}},[_c('b-icon',{staticClass:"has-text-grey-light",attrs:{"pack":"far","icon":"question-circle","size":"is-small"}})],1)],1)])]),_c('tbody',[_c('tr',[(_vm.emailPreferences.emailCheckingFrequency)?_c('td',[_vm._v(" "+_vm._s(_vm.getEmailCheckingFrequency( _vm.emailPreferences.emailCheckingFrequency, ))+" ")]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.emailPreferences.escalationThresholdHours)+" hrs")]),_c('td',[_vm._v(_vm._s(_vm.getEscalationPath(_vm.emailPreferences.escalationPath)))])])])])]):_vm._e(),(_vm.emailPreferences)?_c('p',{staticClass:"pr-3 pl-3"},[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('contactPreferences/prefer'))+" "),_c('b',[_c('u',{staticClass:"firstletter"},[_vm._v(_vm._s(_vm._f("formatCommPref")(_vm.emailPreferences.internalPreference)))])]),_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('contactPreferences/medium'))+" "),_c('b',[_c('u',{staticClass:"firstletter"},[_vm._v(_vm._s(_vm._f("formatCommPref")(_vm.emailPreferences.externalPreference)))])]),_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('contactPreferences/external'))+" ")]):_vm._e(),(!_vm.emailPreferences)?_c('div',[_c('p',{staticClass:"pl-6"},[_vm._v(_vm._s(_vm.showUserMessageFromCode('generic/none')))])]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }