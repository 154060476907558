/* eslint-disable no-unused-vars */

import TokensDB from '@/firebase/tokens/tokens-db'

export default {
  fetchTokenByEmailAndTokenNumber: async ({ commit }, constraints) => {
    // console.log(JSON.stringify(constraints))
    const tokensDB = new TokensDB()
    return tokensDB.readAll(constraints)
  },

  fetchTokenById: async ({ commit }, tokenId) => {
    const tokensDB = new TokensDB(tokenId)
    return tokensDB.read(tokenId)
  },

  createToken: async ({ commit }, payload) => {
    const tokensDB = new TokensDB()
    return tokensDB.create(payload.token)
  },

  updateToken: async ({ commit }, payload) => {
    const tokensDB = new TokensDB()
    return tokensDB.update(payload.token)
  },

  deleteToken: async ({ commit }, payload) => {
    const tokensDB = new TokensDB()
    return tokensDB.delete(payload.tokenId)
  },
}
