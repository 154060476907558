<!--  -->
<template>
  <section class="view-links">
    <b-skeleton
      v-if="isLoading"
      :animated="animated"
      class="has-background-white"
    ></b-skeleton>
    <SiynActionBar
      v-else-if="links && links.length > 0 && !isLoading"
      :links="links"
      emoji="🔗"
      :title="externalTitle || panelTitle"
    ></SiynActionBar>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapActions, mapMutations } from 'vuex'

import { viewProfileMixin } from '@/mixins/profile/index'
import SiynActionBar from '@/components/partials/SiynActionBar.vue'

export default {
  name: 'ViewLinks',
  components: { SiynActionBar },
  mixins: [viewProfileMixin],
  props: {
    externalTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      panelTitle: this.showLabelsFromCode('profile/links/panelTitle'),
    }
  },
  computed: {
    ...mapState('links', ['links']),
  },
  watch: {
    mergedPanelsConfig: {
      handler(mergedPanelsConfig) {
        // console.log(JSON.stringify(mergedPanelsConfig))
        if (!this.isEmpty(mergedPanelsConfig) && this.profileUser) {
          if (
            this.getAuthUserId === this.$route.params.uid ||
            this.getAuthUserCompanyId === this.profileUser.companyId
          ) {
            this.fetchLinksContent()
          } else if (
            mergedPanelsConfig.links === this.visibilityConstants.VISIBLE_TO_ALL
          ) {
            this.fetchLinksContent()
          } else if (
            mergedPanelsConfig.events ===
              this.visibilityConstants.VISIBLE_TO_MY_NW &&
            this.checkIfYouAreInMyNetwork({
              userId: this.profileUser.id,
              network: this.network,
            })
          ) {
            this.fetchLinksContent()
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // console.log(JSON.stringify('View links mounted'))
    this.isLoading = false
    if (!this.isEmpty(this.externalTitle)) {
      this.fetchLinksContent()
    }
  },

  methods: {
    ...mapMutations('links', ['setLinks']),
    ...mapActions('links', ['fetchLinksByUserId']),
    async fetchLinksContent() {
      await this.fetchLinksByUserId(this.$route.params.uid)
        .then((linksResponse) => {
          if (linksResponse) {
            this.nextTick(() => {
              this.setLinks(linksResponse)
            })
          }
        })
        .catch((err) => {
          if (err.code !== 'permission-denied') {
            console.log(err)
            this.handleErrorMessage(
              `${this.showUserMessageFromCode(
                'generic/error-in-fetch',
              )} links.`,
            )
          }
          this.setLinks(null)
          this.trackExceptionEvent(
            'links_fetch_failed',
            `${this.user ? this.user.id : 'visitor'} tried to fetch info for  ${
              this.$route.params.uid
            }. ${err}`,
            false,
          )
        })
    },
  },
}
</script>
