export default {
  setUser: (state, value) => (state.user = value),
  setAuthCompanyId: (state, value) => (state.authCompanyId = value),
  setAuthLicenseType: (state, value) => (state.authLicenseType = value),
  setAuthUserRole: (state, value) => (state.authUserRole = value),
  setFetchingUser: (state, value) => (state.fetchingUser = value),
  setAuthMessages: (state, value) => (state.authMessages = value),
  setUserPhotoURL: (state, value) => {
    state.user.photoURL = value
  },
  setUserMentoring: (state, value) => {
    state.user.openToMentoring = value
  },
  setIsCalendarIntegrated: (state, value) => {
    state.user.isCalendarIntegrated = value
  },
  setUserCompany: (state, value) => {
    state.userCompany = value
  },
  setUserProfileUpdatePending: (state, value) =>
    (state.userProfileUpdatePending = value),
}
