export default {
  appTitle: process.env.VUE_APP_TITLE,
  appShortTitle: process.env.VUE_APP_SHORT_TITLE,
  appURL: process.env.VUE_APP_URL,
  byline: process.env.VUE_APP_BYLINE,
  firestoreBaseURL: process.env.VUE_APP_FIRESTORE_BASEURL,
  firebaseStorageBaseURL: process.env.VUE_APP_FIREBASE_STORAGE_BASEURL,
  networkOnLine: true,
  SWRegistrationForNewContent: null,
  showAddToHomeScreenModalForApple: false,
  refreshingApp: false,
  appHasUpdates: false,
  wrapperBackgroundColor: 'transparent',
  backgroundColor: process.env.VUE_APP_BODY_BGCOLOR,
  criticalAppError: false, // halt application e.g. no DB, under maintainance, etc.
  hardError: {
    // Show error screen but allow path to continue and set some defaults
    profileNotFound: false,
    companyNotFound: false,
    userDisabled: false,
  },
  // show error message
  warnError: {
    appConfigNotFoundForUser: false,
    appConfigNotFoundForCompany: false,
  },
  appMasterConfigs: {
    openAIPrompts: {},
  },
  /* softError: {}, */
}
