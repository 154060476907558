/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */
// import firebase from 'firebase/compat/app'
// import 'firebase/compat/storage'
import isEmpty from 'lodash/isEmpty'
import UsersDB from '@/firebase/users/users-db'
import { trackExceptionEvent } from '@/misc/analytics'
import UserPrivateInfoDB from '@/firebase/private/private-db'
import { getUserIPAddress } from '@/misc/helpers'

export default {
  /**
   * Fetch Private data for the user
   */

  fetchPrivateContactInfoByUserId: async ({ rootState, commit }, userId) => {
    const userPrivateDataDb = new UserPrivateInfoDB(userId)
    const privateContactInfo = await userPrivateDataDb.read('contact')
    if (isEmpty(privateContactInfo)) {
      const newPvtRecord = await userPrivateDataDb.create(
        {
          email: rootState.user.user.email,
        },
        'contact',
      )
      return newPvtRecord
    }
    return privateContactInfo
  },

  fetchPrivateChatInfoByUserId: async ({ commit }, userId) => {
    const userPrivateDataDb = new UserPrivateInfoDB(userId)
    const privateChatInfo = await userPrivateDataDb.read('chat')
    if (isEmpty(privateChatInfo)) {
      const newPvtRecord = await userPrivateDataDb.create(
        {
          authorizationToken: '',
        },
        'chat',
      )
      return newPvtRecord
    }
    return privateChatInfo
  },

  fetchPrivateDataInfoByUserId: async ({ commit }, userId) => {
    const userPrivateDataDb = new UserPrivateInfoDB(userId)
    return userPrivateDataDb.read('data')
  },

  // fetchPrivateLicenseInfoByUserId: async ({ commit }, userId) => {
  //   const userPrivateDataDb = new UserPrivateInfoDB(userId)
  //   const privateLicenseInfo = await userPrivateDataDb.read('license')
  //   return privateLicenseInfo || null
  // },

  /**
   * Update user Private data for the user
   */
  // createUpdateUserPrivateData: async ({ commit }, payload) => {
  //   // console.log(JSON.stringify(payload))
  //   let lastknownIPAddressAndTime = ''
  //   await getUserIPAddress().then((data) => (lastknownIPAddressAndTime = data))
  //   const loginCount = firebase.firestore.FieldValue.increment(1)
  //   const lastLoginTimestamp = firebase.firestore.FieldValue.serverTimestamp()
  //   const userPrivateInfoDb = new UserPrivateInfoDB(payload.userId)
  //   const userPrivateInfo = await userPrivateInfoDb.read('data')

  //   //  console.log(`userPrivateInfo ${JSON.stringify(userPrivateInfo)}`)
  //   //  console.log(JSON.stringify(!isEmpty(userPrivateInfo)))
  //   if (!isEmpty(userPrivateInfo)) {
  //     const userPrivateInfoObj = {}

  //     userPrivateInfoObj.id = userPrivateInfo.id
  //     const responseId = await userPrivateInfoDb
  //       .update({
  //         id: userPrivateInfo.id,
  //         loginCount,
  //         lastLoginTimestamp,
  //         lastknownIPAddressAndTime: `${lastknownIPAddressAndTime} @ ${Date()}`,
  //       })
  //       .then((updPrivateInfo) => {
  //         return updPrivateInfo
  //       })
  //       .catch((error) => {
  //         console.log(console.log(error))
  //         throw error
  //       })

  //     return responseId
  //   }

  //   const responseId = await userPrivateInfoDb
  //     .create(
  //       {
  //         loginCount,
  //         lastLoginTimestamp,
  //         providerId: payload.providerId,
  //       },
  //       'data',
  //     )
  //     .then((privateInfo) => {
  //       return privateInfo.id
  //     })
  //     .catch((error) => {
  //       console.log(console.log(error))
  //       throw error
  //     })
  //   return responseId
  // },

  updatePrivateContactInfo: async ({ rootState, commit }, payload) => {
    const userPrivateInfoDb = new UserPrivateInfoDB(rootState.user.user.id)
    const response = await userPrivateInfoDb
      .createOrUpdate(payload)
      .then((result) => {
        return result
      })
      .catch((err) => {
        throw err
      })
    return response
  },

  createUpdatePrivateChatInfo: async ({ rootState, commit }, payload) => {
    const userPrivateInfoDb = new UserPrivateInfoDB(rootState.user.user.id)
    await userPrivateInfoDb.create(payload, 'chat')
    return payload
  },

  // createPrivateNode: async ({ rootState, commit }, payload) => {
  //   console.log(JSON.stringify(payload))
  //   const userPrivateInfoDb = new UserPrivateInfoDB(payload.user.id)
  //   const lastLoginTimestamp = firebase.firestore.FieldValue.serverTimestamp()

  //   const privateContactPromise = new Promise((resolve, reject) => {
  //     userPrivateInfoDb
  //       .create({
  //         id: 'contact',
  //         email: payload.user.email,
  //       })
  //       .then(async (privateContactResponse) => {
  //         if (privateContactResponse) {
  //           console.log(JSON.stringify(privateContactResponse))
  //           resolve(privateContactResponse) // If i return using resolve then it causes extra layer of object.
  //         }
  //         reject(new Error('Private Contact creation failed'))
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //         reject(error.code)
  //       })
  //   })
  //   const privateDataPromise = new Promise((resolve, reject) => {
  //     userPrivateInfoDb
  //       .create({
  //         id: 'data',
  //         loginCount: 0,
  //         lastLoginTimestamp,
  //       })
  //       .then(async (privateDataResponse) => {
  //         if (privateDataResponse) {
  //           console.log(JSON.stringify(privateDataResponse))
  //           resolve(privateDataResponse) // If i return using resolve then it causes extra layer of object.
  //         }
  //         reject(new Error('Private Data creation failed'))
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //         reject(error.code)
  //       })
  //   })

  //   Promise.all([privateContactPromise, privateDataPromise])
  //     .then((values) => {
  //       console.log(JSON.stringify(values))
  //       return values // [resolvedValue1, resolvedValue2]
  //     })
  //     .catch((error) => {
  //       console.log(error) // rejectReason of any first rejected promise
  //       throw error
  //     })
  // },
}
