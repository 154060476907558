/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import isEmpty from 'lodash/isEmpty'
import {
  arrayUnion,
  arrayRemove,
  doc,
  collection,
  query,
  connectFirestoreEmulator,
  getCountFromServer,
  where,
} from 'firebase/firestore'
import { db } from '@/firebase/initFirebase'
import CompanyDB from '@/firebase/company/company-db'
import CompanyAdminDB from '@/firebase/company/company-admin-db'
import CompanyAppConfigDB from '@/firebase/company/company-app-config-db'
import CompanyLocationsDB from '@/firebase/company/company-locations-db'
import CompanyEventsDB from '@/firebase/company/company-events-db'
import CompanyUpdatesDB from '@/firebase/company/company-updates-db'
import CompanyNewslettersDB from '@/firebase/company/company-newsletters-db'
import CompanyBroadcastsDB from '@/firebase/company/company-broadcasts-db'
import CompanyTokensDB from '@/firebase/company/company-tokens-db'
import CompanyLicenseDB from '@/firebase/company/company-license-db'

import UsersDB from '@/firebase/users/users-db'
import { visibilityConstants } from '@/data/visibilityPreferences'
import { handleErrorMessage } from '@/misc/user-messages'
import {
  // updateCompanyEventBatch,
  // updateCompanyEventStatusBatch,
  removeCompanyEventBatch,
  addInitialCompanyAdminBatch,
  addNewsletterAdminBatch,
  removeNewsletterAdminBatch,
  addCompanyTokenBatch,
} from '@/store/company-collection/companyBatch'

import {
  companyEventStatusConstants,
  companyEventStatus,
} from '@/data/company/company-event-status'

const defaultCompanyAppConfig = {
  comments: visibilityConstants.VISIBLE_TO_MY_NW,
  contactPreferences: visibilityConstants.VISIBLE_TO_MY_NW,
  events: visibilityConstants.VISIBLE_TO_MY_NW,
  leisures: visibilityConstants.VISIBLE_TO_MY_NW,
  links: visibilityConstants.VISIBLE_TO_MY_NW,
  officeHours: visibilityConstants.VISIBLE_TO_MY_NW,
  personalInfo: visibilityConstants.VISIBLE_TO_MY_NW,
  privateInfo: visibilityConstants.VISIBLE_TO_MY_NW,
  projectTeams: visibilityConstants.VISIBLE_TO_NONE,
  mentoring: visibilityConstants.VISIBLE_TO_NONE,
  isPublicallyVisible: true,
}

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}

export default {
  /** *************************************************
   * COMPANY APP PANELS CONFIG
   **************************************************** */

  fetchCompanyAppPanelsConfigByCompanyId: async (
    { commit, dispatch },
    companyId,
  ) => {
    // console.log(JSON.stringify(companyId))
    const companyAppConfigDB = new CompanyAppConfigDB(companyId)
    let response = await companyAppConfigDB.read('panels')
    // console.log(JSON.stringify(response))
    if (isEmpty(response)) {
      response = defaultCompanyAppConfig
      console.log(
        'Company App Config panels not found, defaulting to default values',
      )
    }
    // console.log(JSON.stringify(response))
    return response
  },

  updateCompanyAppPanelsConfig: async ({ rootState }, payload) => {
    // console.log(JSON.stringify(payload.companyConfigs))
    const companyAppConfigDB = new CompanyAppConfigDB(payload.companyId)
    try {
      await companyAppConfigDB.update(payload.companyConfigs)
      return payload.companyConfigs
    } catch (error) {
      return error
    }
  },

  /**
   * COMPANY
   */
  fetchCompanyByCompanyId: async ({ commit }, companyId) => {
    const userCompanyDb = new CompanyDB()
    return userCompanyDb.read(companyId) // when returning a Promise inside an async function, you don’t need to use await
  },

  addCompany: async ({ commit, dispatch }, company) => {
    const userCompanyDb = new CompanyDB()
    return userCompanyDb
      .create(company, company.emailDomain)
      .then((companyResponse) => {
        if (companyResponse.id) {
          // dispatch('createCompanyAppPanelsConfig', companyResponse.id)
          return companyResponse
        }
        return null
      })
      .catch((error) => {
        console.log(error)
        throw error
      })
  },

  // createCompanyAppPanelsConfig: async ({ commit }, companyId) => {
  //   const companyAppConfigDB = new CompanyAppConfigDB(companyId)
  //   return new Promise((resolve, reject) => {
  //     companyAppConfigDB
  //       .create(
  //         {
  //           comments: visibilityConstants.VISIBLE_TO_MY_NW,
  //           contactPreferences: visibilityConstants.VISIBLE_TO_MY_NW,
  //           events: visibilityConstants.VISIBLE_TO_MY_NW,
  //           leisures: visibilityConstants.VISIBLE_TO_MY_NW,
  //           links: visibilityConstants.VISIBLE_TO_MY_NW,
  //           officeHours: visibilityConstants.VISIBLE_TO_MY_NW,
  //           personalInfo: visibilityConstants.VISIBLE_TO_MY_NW,
  //           privateInfo: visibilityConstants.VISIBLE_TO_MY_NW,
  //           projectTeams: visibilityConstants.VISIBLE_TO_NONE,
  //           isPublicallyVisible: true,
  //         },
  //         'panels',
  //       )
  //       .then(async (companyAppConfigResponse) => {
  //         if (companyAppConfigResponse) {
  //           // console.log(JSON.stringify(companyAppConfigResponse))
  //           resolve(companyAppConfigResponse) // If i return using resolve then it causes extra layer of object.
  //         }
  //         reject(new Error('Company App Config creation failed'))
  //       })
  //       .catch((error) => {
  //         console.log(error)
  //         reject(error.code)
  //       })
  //   })
  // },

  updateCompany: async ({ rootState }, company) => {
    const lastUpdatedBy = {
      id: rootState.user.user.id,
      displayName: rootState.user.user.displayName,
    }
    const userCompanyDb = new CompanyDB(company.id)
    const response = userCompanyDb
      .update({
        ...company,
        lastUpdatedBy,
      })
      .then((result) => {
        return result
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
    return response
  },

  updatelogoImageURL: async ({ commit }, payload) => {
    commit('setCompanyUpdatePending', true)
    const userCompanyDb = new CompanyDB(payload.companyId)
    // console.log(payload.logoImageURL)
    await userCompanyDb.update(payload)
    commit('updateStateCompanyLogoPhoto', payload.logoImageURL)
    commit('setCompanyUpdatePending', false)
  },

  /**
   * COMPANY ADMINS
   * */

  fetchCompanyAdminsByCompanyId: async ({ commit }, companyId) => {
    // console.log(JSON.stringify(companyId))
    const userCompanyAdminDb = new CompanyAdminDB(companyId)
    // console.log(JSON.stringify(userCompanyAdminDb))
    return userCompanyAdminDb.readAll()
  },

  updateCompanyAdminStatus: async ({ rootState, commit }, companyAdmin) => {
    const userCompanyAdminDb = new CompanyAdminDB(companyAdmin.companyId)
    return userCompanyAdminDb.update({
      id: companyAdmin.id,
      isEnabled: companyAdmin.isEnabled,
      lastUpdatedBy: rootState.user.user.displayName,
    })
  },

  getUserByEmailAddress: async ({ commit }, payload) => {
    const usersDB = new UsersDB()
    const constraints = []
    constraints.push(['email', '==', payload.email])
    constraints.push(['companyId', '==', payload.companyId])
    return usersDB
      .readAll(constraints)
      .then((user) => {
        if (user) {
          return user
        }
        return false
      })
      .catch((error) => {
        throw error
      })
  },

  getUserByEmailAddressOnly: async ({ commit }, payload) => {
    console.log(JSON.stringify(payload))
    const usersDB = new UsersDB()
    const constraints = []
    constraints.push(['email', '==', payload.email])
    constraints.push(['companyId', '==', payload.companyId])
    return usersDB.readAll(constraints)
  },

  addUserAdmin: async ({ rootState, commit }, companyAdmin) => {
    const userCompanyAdminDb = new CompanyAdminDB(companyAdmin.companyId)
    companyAdmin.createdBy = {}
    companyAdmin.createdBy.displayName = rootState.user.user.displayName
    companyAdmin.createdBy.id = rootState.user.user.id
    return userCompanyAdminDb.create(companyAdmin, companyAdmin.id)
  },

  // addInitialCompanyAdminAction: async ({ commit }, payload) => {
  //   return addInitialCompanyAdminBatch(payload)
  // },
  // checkCompanySuperAdminsByUserIdCompanyId: async (
  //   { rootState },
  //   companyId
  // ) => {
  //   // console.log(JSON.stringify(payload))
  //   const userCompanyAdminDb = new CompanyAdminDB(companyId.companyId)
  //   // console.log(JSON.stringify(companyId.companyId))
  //   // console.log(JSON.stringify(userCompanyAdminDb))
  //   const companyAdmin = await userCompanyAdminDb.read(rootState.user.user.id)
  //   // console.log(companyAdmin)

  //   if (companyAdmin) {
  //     return true
  //   }

  //   return null
  // },
  /**
   * *****************COMPANY lOCATIONS************************
   * */
  fetchCompanyLocationsByCompanyId: async ({ commit }, companyId) => {
    const companyLocationsDB = new CompanyLocationsDB(companyId)
    return companyLocationsDB.readAll()
  },

  createCompanyLocation: async ({ commit }, payload) => {
    const companyLocationsDB = new CompanyLocationsDB(payload.companyId)
    return companyLocationsDB.create(payload.companyLocation)
  },

  updateCompanyLocation: async ({ commit }, companyLocation) => {
    const companyLocationsDB = new CompanyLocationsDB(companyLocation)
    return companyLocationsDB.update(companyLocation)
  },

  deleteCompanyLocation: async ({ commit }, payload) => {
    const companyLocationsDB = new CompanyLocationsDB(payload.companyId)
    return companyLocationsDB.delete(payload.companyLocationId)
  },
  /**
   * COMPANY EVENTS
   *
   * */
  fetchCompanyEventsByCompanyId: async ({ commit }, payload) => {
    const companyEventsDB = new CompanyEventsDB(payload.companyId)
    return companyEventsDB.readAll(payload.constraints)
  },

  fetchCompanyEventsById: async ({ commit }, companyEventId) => {
    const companyEventsDB = new CompanyEventsDB()
    return companyEventsDB.read(companyEventId)
  },

  fetchCompanyEventsByCompanyIdAndUserId: async ({ commit }, payload) => {
    const companyEventsDB = new CompanyEventsDB()
    const { limit } = payload

    let end = null
    if (payload.endDate) {
      end = payload.endDate
    } else {
      const endDays = payload.endDays || 30
      end = new Date()
      end.setDate(end.getDate() + endDays)
    }
    const today = new Date()
    const todayMidnight = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
    )

    const constraints = []
    constraints.push(['endDateTime', '>=', todayMidnight])
    constraints.push(['endDateTime', '<=', end])
    constraints.push(['attendees', 'array-contains', payload.attendee])
    constraints.push(['companyId', '==', payload.companyId])
    constraints.push([
      'eventStatus',
      '==',
      companyEventStatusConstants.SCHEDULED,
    ])
    // const orderBys = []
    // orderBys.push(['eventOccurence', 'asc'])

    return companyEventsDB
      .readAllWithOrderByLimit(constraints, undefined, limit)
      .then((response) => {
        return response
      })
      .catch((err) => {
        throw err
      })
    // if (companyEvents) {
    //   return Object.keys(companyEvents).map((o) => companyEvents[o]) // Converting to array for buefy table
    // }
    // return null
  },

  createCompanyEvent: async ({ commit }, payload) => {
    const companyEventsDB = new CompanyEventsDB()
    return companyEventsDB.create(payload.companyEvent)
  },

  updateCompanyEventAction: async ({ commit }, payload) => {
    const companyEventsDB = new CompanyEventsDB()
    return companyEventsDB.update(payload.companyEvent)
  },
  // updateCompanyEventStatusAction: async ({ commit }, payload) => {
  //   return updateCompanyEventStatusBatch(payload)
  // },

  // removeCompanyEventAction: async ({ commit }, payload) => {
  //   return removeCompanyEventBatch(payload)
  // },

  unAttendEventAction: async ({ commit }, payload) => {
    const companyEventsDB = new CompanyEventsDB()
    return companyEventsDB.update({
      ...payload.companyEvent,
      attendees: arrayRemove(payload.user),
    })
  },
  attendEventAction: async ({ commit }, payload) => {
    const companyEventsDB = new CompanyEventsDB()
    return companyEventsDB.update({
      ...payload.companyEvent,
      attendees: arrayUnion(payload.user),
    })
  },

  /** ****************COMPANY UPDATES  ************************
   * */

  fetchCompanyUpdatesByCompanyId: async ({ commit }, payload) => {
    const companyUpdatesDB = new CompanyUpdatesDB(payload.companyId)
    return companyUpdatesDB.readAll(payload.constraints)
  },

  fetchCompanyUpdateById: async ({ commit }, payload) => {
    const companyUpdatesDB = new CompanyUpdatesDB(payload.companyId)
    return companyUpdatesDB.read(payload.updateId)
  },

  addCompanyUpdate: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const companyUpdatesDB = new CompanyUpdatesDB(payload.companyId)
    return companyUpdatesDB.create(payload.updateRecord)
  },
  /** ****************COMPANY NEWSLETTERS  ************************ */

  fetchCompanyNewsletterByCompanyId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const companyNewslettersDB = new CompanyNewslettersDB(payload.companyId)
    return companyNewslettersDB.readAll(payload.constraints)
  },

  fetchCompanyNewsletterById: async ({ commit }, payload) => {
    const companyNewslettersDB = new CompanyNewslettersDB(payload.companyId)
    return companyNewslettersDB.read(payload.id)
  },

  createCompanyNewsletter: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const companyNewslettersDB = new CompanyNewslettersDB(payload.companyId)
    return companyNewslettersDB.create(payload.companyNewsletter)
  },

  updateCompanyNewsletter: async ({ commit }, payload) => {
    const companyNewslettersDB = new CompanyNewslettersDB(payload.companyId)
    return companyNewslettersDB.update(payload.companyNewsletter)
  },

  addNewsletterAdminAction: async ({ commit }, payload) => {
    return addNewsletterAdminBatch(payload)
  },
  removeNewsletterAdminAction: async ({ commit }, payload) => {
    return removeNewsletterAdminBatch(payload)
  },

  /** ****************COMPANY Broadcasts  ************************ */

  fetchCompanyBroadcastsByCompanyId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const companyBroadcastsDB = new CompanyBroadcastsDB(payload.companyId)
    return companyBroadcastsDB.readAllWithOrderByLimit(
      payload.constraints,
      payload.orderBys,
      payload.limit,
    )
  },

  fetchCompanyBroadcastsById: async ({ commit }, payload) => {
    const companyBroadcastsDB = new CompanyBroadcastsDB(payload.companyId)
    return companyBroadcastsDB.read(payload.id)
  },

  createCompanyBroadcast: async ({ commit }, payload) => {
    const companyBroadcastsDB = new CompanyBroadcastsDB(payload.companyId)
    return companyBroadcastsDB.create(payload.companyBroadcast)
  },

  updateCompanyBroadcast: async ({ commit }, payload) => {
    const companyBroadcastsDB = new CompanyBroadcastsDB(payload.companyId)
    return companyBroadcastsDB.update(payload.companyBroadcast)
  },

  deleteCompanyBroadcast: async ({ commit }, payload) => {
    const companyBroadcastsDB = new CompanyBroadcastsDB(payload.companyId)
    return companyBroadcastsDB.delete(payload.companyBroadcastId)
  },
  /** ****************COMPANY TOKENS  ************************ */

  fetchCompanyTokensByCompanyId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const companyBroadcastsDB = new CompanyTokensDB(payload.companyId)
    return companyBroadcastsDB.readAllWithOrderByLimit(
      payload.constraints,
      payload.orderBys,
      payload.limit,
    )
  },

  fetchCompanyTokensById: async ({ commit }, payload) => {
    const companyTokensDB = new CompanyTokensDB(payload.companyId)
    return companyTokensDB.read(payload.id)
  },

  createCompanyTokenAction: async ({ commit }, payload) => {
    return addCompanyTokenBatch(payload)
  },

  updateCompanyToken: async ({ commit }, payload) => {
    const companyTokensDB = new CompanyTokensDB(payload.companyId)
    return companyTokensDB.update(payload.companytoken)
  },

  /** ****************COMPANY LICENSE  ************************ */

  fetchCompanyLicenseByCompanyId: async ({ commit }, payload) => {
    const companylicenseDB = new CompanyLicenseDB(payload.companyId)
    return companylicenseDB.read(payload.id)
  },
  fetchTotalCompanyTokensCount: async ({ commit }, companyId) => {
    const companyTokenCollection = collection(
      db,
      'company',
      companyId,
      'tokens',
    )
    const snapshot = await getCountFromServer(companyTokenCollection)
    return snapshot.data().count
  },
}
