<template>
  <section class="mb-5">
    <b-table
      :data="events"
      hoverable
      mobile-cards
      class="events__table"
      :show-header="events.length > 0 ? true : false"
      default-sort="startDateTime"
      default-sort-direction="asc"
      :paginated="events.length > 20 ? true : false"
      per-page="20"
      :aria-next-label="showLabelsFromCode('generic/nextpage')"
      :aria-previous-label="showLabelsFromCode('generic/previouspage')"
      :aria-page-label="showLabelsFromCode('generic/page')"
      :aria-current-label="showLabelsFromCode('generic/currentpage')"
    >
      <template #empty>
        <p class="ml-4 empty-content">
          {{ showUserMessageFromCode('generic/none') }}
        </p>
      </template>

      <b-table-column
        v-slot="props"
        scope="props"
        field="eventName"
        :label="showLabelsFromCode('profile/events/eventname')"
        cell-class="valign"
        sortable
      >
        <span
          :title="
            renderEventTypeTitle(props.row.eventCategory, props.row.eventType)
          "
        >
          <router-link
            v-if="props.row.eventCategory === 'team'"
            :to="{
              name: 'viewteamevent',
              params: {
                teamid: props.row.team?.id,
                id: props.row.id,
              },
            }"
            class="no-text-decoration"
            >{{ truncateText(props.row.eventName, 20) }}</router-link
          >
          <router-link
            v-else-if="props.row.eventCategory === 'company'"
            :to="{
              name: 'viewcompanyevent',
              params: {
                companyid: props.row.companyId,
                id: props.row.id,
              },
            }"
            class="no-text-decoration"
            >{{ truncateText(props.row.eventName, 20) }}</router-link
          >
          <span
            v-else-if="
              uid === getAuthUserId ||
              getProfileUserCompanyId === getAuthUserCompanyId
            "
          >
            <router-link
              :to="{
                name: 'viewevent',
                params: {
                  event: props.row,
                  id: props.row.id,
                  uid: uid,
                },
              }"
              class="no-text-decoration"
              >{{
                mode === 'VIEW'
                  ? truncateText(props.row.eventName, 20)
                  : truncateText(props.row.eventName, 70)
              }}</router-link
            >
            <b-tooltip
              v-if="props.row.isOOO"
              type="is-dark"
              :label="showTooltipsFromCode('profile/events/isOOO')"
              position="is-right"
              ><b-icon
                pack="fas"
                icon="building-circle-xmark"
                size="is-small"
                type="is-danger"
                class="ml-1 is-size-9"
                style="display: inline-block; vertical-align: text-top"
              >
              </b-icon
            ></b-tooltip>
          </span>
          <span v-else>{{ truncateText(props.row.eventName, 40) }}</span>
        </span>
      </b-table-column>
      <b-table-column
        v-if="getProfileUserCompanyId === getAuthUserCompanyId"
        v-slot="props"
        scope="props"
        field="eventCategory"
        :label="showLabelsFromCode('profile/events/eventCategory')"
        cell-class="valign"
        sortable
        >{{ capitalizeFirstLetter(props.row.eventCategory) || 'Individual' }}
      </b-table-column>
      <b-table-column
        v-if="mode === 'EDIT' || mode === 'LIST'"
        v-slot="props"
        scope="props"
        field="eventType"
        :label="showLabelsFromCode('profile/events/eventtype')"
        cell-class="valign"
        sortable
      >
        <span>
          {{
            renderEventTypeColumn(props.row.eventCategory, props.row.eventType)
          }}
        </span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        scope="props"
        field="startDateTime"
        :label="showLabelsFromCode('generic/dates')"
        cell-class="valign"
        sortable
      >
        <span>
          {{
            renderFromToDates(props.row.startDateTime, props.row.endDateTime)
          }}
        </span>
        <AddToCalendar
          :event-name="
            props.row.isOOO
              ? `${profileUser?.displayName} is Out of Office today (${props.row.eventName})`
              : props.row.eventName
          "
          :meeting-u-r-l="props.row.eventLocation || ''"
          :event-description="`Added from event created by ${profileUser?.displayName} in ${appTitle}, view their profile at ${appURL}view/profile/${profileUser?.id} for more information.`"
          :start-date-time="props.row.startDateTime"
          :end-date-time="props.row.endDateTime"
          addtext=""
        >
        </AddToCalendar>
      </b-table-column>
      <b-table-column
        v-slot="props"
        scope="props"
        :label="showLabelsFromCode('generic/comments')"
        cell-class="valign notes"
        sortable
      >
        <span class="notes-wrapper">
          <!--           <span v-if="!isEmpty(props.row.teamEventId)">{{
            getNameForId(props.row.eventMemberStatus, teamMemberEventStatus)
          }}</span> -->
          <span v-if="props.row.isAvailableOnSOS">
            <span
              class="default-cursor"
              :title="showLabelsFromCode('profile/events/sos')"
              >🆘</span
            >
          </span>
          <span v-else>
            <span
              v-if="props.row.isAvailableOnDeskPhone"
              :title="showLabelsFromCode('profile/events/deskphone')"
              class="default-cursor"
              >☎️
            </span>
            <span
              v-if="props.row.isAvailableOnEmail"
              :title="showLabelsFromCode('profile/events/email')"
              class="default-cursor"
              >✉️
            </span>
            <span
              v-if="props.row.isAvailableOnChat"
              :title="showLabelsFromCode('profile/events/chat')"
              class="default-cursor"
            >
              💬</span
            >
            <span
              v-if="props.row.isAvailableOnMobilePhone"
              :title="showLabelsFromCode('profile/events/mobilePhone')"
              class="default-cursor"
              >📱</span
            >
          </span>
          <span
            v-if="
              props.row.eventCategory === 'company' &&
              props.row.eventStatus === 2
            "
            ><span class="is-italic">Draft</span></span
          >
          <span v-if="props.row.eventCategory === 'team'"
            ><span>{{
              getNameForId(props.row.eventType, teamEventTypes)
            }}</span></span
          >
        </span>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { eventMixin } from '@/mixins/eventMixin'
import { viewProfileMixin } from '@/mixins/profile/index'
// import EditEventForm from '@/views/events/EditEventForm'
import { teamMemberEventStatus } from '@/data/teams/team-member-event-status'
import {
  capitalizeFirstLetter,
  renderFromToDates,
  truncateText,
} from '@/misc/helpers'
import AddToCalendar from '@/components/AddToCalendar'
import teamEventTypes from '@/data/teams/team-event-types'

export default {
  name: 'ListEventsTable',
  components: { AddToCalendar },
  mixins: [eventMixin, viewProfileMixin],
  props: {
    events: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: 'LIST',
    },
    uid: {
      type: String,
      default: '',
    },
    pagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      teamMemberEventStatus,
      teamEventTypes,
    }
  },
  computed: {
    ...mapState('privateinfo', ['privateContactInfo', 'privateChatInfo']),
  },
  methods: {
    capitalizeFirstLetter,
    renderFromToDates,
    truncateText,
    ...mapMutations('events', [
      'setEvents',
      'setEventToEdit',
      'removeEventById',
    ]),
    renderEventTypeTitle(category, type) {
      if (!type || !category) {
        return ''
      }
      switch (category) {
        case 'team':
          return `Team Event. Type - ${type}`
        case 'company':
          return `Company Wide Event`
        default:
          return `Personal Event. Type - ${this.getNameForId(
            type,
            this.eventTypes,
          )}`
      }
    },
    renderEventTypeColumn(category, type) {
      if (!type) {
        return ''
      }
      switch (category) {
        case 'team':
          return this.getNameForId(type, this.teamEventTypes)
        case 'company':
          return 'Company Wide Event'
        default:
          return this.getNameForId(type, this.eventTypes)
      }
    },
  },
}
</script>
