<template>
  <section class="company-event-attachments">
    <div width-class="is-12" class="view-company-event-attachments">
      <skeleton v-if="isLoading"></skeleton>
      <SynPanel v-if="companyEventToView && !isLoading" :title="panelTitle">
        <div class="view-company-event-attachments__wrapper px-4 py-2">
          <firestore-files
            :folder-path="`/company/${companyEventToView.companyId}/events/${companyEventToView.id}`"
            :allowed-to-add-file="
              (isUserEventCreator || isUserCompanyAdmin) && !isEventUnEditable
            "
            :allowed-to-update-file="
              (isUserEventCreator || isUserCompanyAdmin) && !isEventUnEditable
            "
            :allowed-to-delete-file="
              (isUserEventCreator || isUserCompanyAdmin) && !isEventUnEditable
            "
          ></firestore-files>
        </div>
      </SynPanel>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */

import SynPanel from '@/components/SynPanel.vue'
import { viewCompanyMixin } from '@/mixins/company/index'
import FirestoreFiles from '@/components/FirestoreFiles.vue'
import { companyEventStatusConstants } from '@/data/company/company-event-status'

export default {
  name: 'CompanyEventAttachments',
  components: {
    SynPanel,
    FirestoreFiles,
  },
  mixins: [viewCompanyMixin],
  props: {},
  data() {
    return {
      panelTitle: 'Files / Attachments',
      isLoading: true,
    }
  },
  computed: {
    isUserEventCreator() {
      return this.companyEventToView?.createdBy?.id === this.getAuthUserId
    },
    isPastEvent() {
      if (
        Math.round(new Date().getTime() / 1000) >
        this.companyEventToView.endDateTime.seconds
      ) {
        return true
      }
      return false
    },
    isEventUnEditable() {
      return (
        this.isPastEvent ||
        this.companyEventToView.eventStatus ===
          companyEventStatusConstants.CANCELLED
      )
    },
  },
  created() {},
  mounted() {
    this.isLoading = false
  },
  destroyed() {},
  methods: {},
}
</script>
