import { mapState, mapGetters } from 'vuex'
import { nextTick } from 'vue'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import Error from '@/components/partials/Error'
import FullPage from '@/components/partials/FullPage'
import Skeleton from '@/components/partials/Skeleton'
import SiynCollapse from '@/components/SiynCollapse'
import { visibilityConstants } from '@/data/visibilityPreferences'
import {
  showUserMessageFromCode,
  showLabelsFromCode,
  showTooltipsFromCode,
  getNameForId,
  checkIfYouAreInMyNetwork,
  addhttp,
  collapseHelper,
} from '@/misc/helpers'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'
import {
  trackExperienceEvent,
  trackExceptionEvent,
  trackEngagementEvent,
  trackConversionEvent,
} from '@/misc/analytics'

const viewProfileMixin = {
  components: {
    Error,
    FullPage,
    Skeleton,
    SiynCollapse,
  },
  filters: {
    truncate(value, length) {
      return value.length > length ? `${value.substr(0, length)}...` : value
    },
  },
  data() {
    return {
      visibilityConstants,
      showError: false,
      isPanelVisible: false,
      isLoading: true,
      teamLogoImageBaseURL: process.env.VUE_APP_BASEURL_TEAMLOGOS,
    }
  },
  computed: {
    ...mapState('user', ['user', 'fetchingUser', 'userCompany']),
    ...mapState('profileUser', ['profileUser']),
    ...mapState('appconfig', [
      'profileUserPanelsConfig',
      'companyPanelsConfig',
      'mergedPanelsConfig',
    ]),
    ...mapState('company', ['company', 'companyPanelsConfig']),
    ...mapState('networkStatus', ['network']),
    // ...mapGetters('networkStatus', ['areWeConnected']),
    ...mapGetters('profileUser', [
      'getProfileUserCompanyId',
      'getProfileUserGenericAvatar',
    ]),
    ...mapState('app', [
      'criticalAppError',
      'hardError',
      'warnError',
      'appURL',
      'appTitle',
      'appShortTitle',
      'byline',
      'appHasUpdates',
    ]),
    ...mapGetters('user', [
      'isUserLoggedIn',
      'isThisMyProfile',
      'getAuthUserId',
      'getAuthUserCompanyId',
      'getAuthUserLicenseType',
      'getAuthUserRole',
      'getTinyUser',
      'isUserInSameCompany',
      'isPaidUser',
      'isEnterpriseUser',
      'isGuestUser',
    ]),
  },
  methods: {
    nextTick,
    showLabelsFromCode,
    showTooltipsFromCode,
    showUserMessageFromCode,
    handleErrorMessage,
    handleSuccessMessage,
    trackExperienceEvent,
    trackExceptionEvent,
    trackEngagementEvent,
    trackConversionEvent,
    getNameForId,
    isEmpty,
    checkIfYouAreInMyNetwork,
    addhttp,
    first,
    collapseHelper,
    setAltImg(e) {
      e.target.onerror = null
      e.target.src = this.noCoverImage
    },
    getCompleted(leisureType) {
      return leisureType.filter(
        (leisuresRecord) => leisuresRecord.completed === false,
      )
    },
  },
}

export default viewProfileMixin
