<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="error">
    <error-content :title="title" :image-src="imageSrc" :image-alt="imageAlt">
      <p v-if="message1" class="is-size-5 has-text-danger">
        {{ message1 }}
      </p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-if="message2" class="is-size-6 has-text-grey my-3" v-html="message2"></p>
      <b-button v-if="showButton" class="no-text-decoration" size="is-medium" type="is-danger" @click="redirectFromError">
        {{ user && user.id ? 'Back to Your Profile' : 'Go Back to Home Page' }}
      </b-button>
    </error-content>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState } from 'vuex'
import ErrorContent from '@/components/partials/ErrorContent'
import { trackExceptionEvent } from '@/misc/analytics'

export default {
  name: 'ShowError',
  components: { ErrorContent },
  props: {
    title: {
      type: String,
      default: 'Error',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    imageAlt: {
      type: String,
      default: 'Error',
    },
    message1: {
      type: String,
      default: 'Oops, record was not found.',
    },
    message2: {
      type: String,
      default: '',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('user', ['user']),
  },
  mounted() {
    trackExceptionEvent('profile_fetch_failed', this.uid, false)
  },
  methods: {
    redirectFromError() {
      if (this.user && this.user.id) {
        window.location.assign(`/profile/${this.user.id}`)
      } else {
        window.location.assign(`/home`)
      }
    },
  },
}
</script>
