var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-podcasts"},[_c('div',{staticClass:"view-podcasts__heading view-podcasts__heading--danger"},[_c('span',{staticClass:"view-podcasts__heading--emoji"},[_vm._v("🎧")]),_c('span',{staticClass:"view-podcasts__heading--title"},[_vm._v("Currently listening to")]),_c('b-button',{staticClass:"view-podcasts__heading--button",attrs:{"tag":"router-link","to":{
        name: 'listpodcasts',
        params: {
          uid: _vm.profileUser.id,
          mode: 'view',
          fromScreen: 'viewprofile',
          podcasts: _vm.podcasts,
        },
      },"size":"is-small","icon-left":"star","pack":"fas","type":"is-ghost"}},[_vm._v(_vm._s(_vm.showLabelsFromCode('generic/ratings')))])],1),_c('div',{staticClass:"columns is-mobile is-multiline is-vcentered is-narrow is-gapless mt-2"},_vm._l((_vm.podcasts),function(podcast,index){return _c('div',{key:podcast.id,staticClass:"column is-3"},[_c('a',{staticClass:"view-podcasts__link",attrs:{"href":podcast.link ? _vm.addhttp(podcast.link) : '#',"target":podcast.link ? '_blank' : '_self'},on:{"click":function($event){return _vm.trackEngagementEvent(
            'podcast_clicked',
            `${podcast.title} by ${podcast.author}`,
            podcast.link,
            `User ${_vm.user.id} clicked podcast ${index + 1} of ${
              _vm.podcasts.length
            } `,
          )}}},[_c('img',{staticClass:"view-podcasts__coverimage border-color--danger",attrs:{"src":podcast.coverImagePath,"alt":`${podcast.title} by ${podcast.author}`,"title":`${podcast.title}`},on:{"error":_vm.setAltImg}})])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }