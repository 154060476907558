import state from './userupdates.state'
import mutations from './userupdates.mutations'
import actions from './userupdates.actions'
import getters from './userupdates.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
