<template>
  <div class="profile-button">
    <b-skeleton
      v-if="isLoading"
      width="80%"
      animated
      class="mx-2 has-text-centered"
    ></b-skeleton>
    <template v-if="!isLoading">
      <template v-if="!user">
        <template v-if="profileUserSchedulerDefaults?.guest?.pageURL">
          <a
            class="button is-primary background-color-override"
            style="font-size: 0.97rem"
            :href="profileUserSchedulerDefaults?.guest?.pageURL"
            ><b-icon pack="fas" icon="handshake" size="is-small" class="mr-2">
            </b-icon
            >{{ profileUserSchedulerDefaults?.guest?.buttonLabel }}</a
          >
        </template>
        <template v-if="profileUser?.showSendAMessageToGuests">
          <b-tooltip type="is-dark" label="Send a message" position="is-bottom"
            ><a
              class="ml-2 button is-primary background-color-override"
              style="font-size: 0.97rem"
              @click="openSendAMessageModal"
              ><b-icon pack="far" icon="message" size="is-small"> </b-icon
              ><span v-if="!profileUserSchedulerDefaults?.guest?.pageURL"
                >Send a message</span
              ></a
            ></b-tooltip
          >
        </template>
      </template>
      <template v-else-if="isUserLoggedIn && user.id === $route.params.uid">
        <b-button
          type="is-danger"
          class="profile-button__edit_button background-color-override"
          icon-left="edit"
          icon-pack="far"
          :size="size"
          tag="router-link"
          to="/edit/profile"
          @click.native="trackEvent('click_to_edit_profile')"
        >
          <span class="ml-1">{{
            showLabelsFromCode('profile/edit/panelTitle')
          }}</span>
        </b-button>
        <div class="pt-2 is-size-8 has-text-primary">
          <span>
            <a @click="openShareModal"
              ><b-icon icon="share-from-square" pack="fas" />Share Profile</a
            >
          </span>
          |
          <span>
            <router-link :to="{ name: 'emailsignatures' }"
              ><b-icon icon="envelope" pack="fas" />Signatures</router-link
            >
          </span>
        </div>
      </template>
      <template v-else-if="isUserLoggedIn && user?.id">
        <template
          v-if="
            getOurNetworkRecord($route.params.uid) &&
            getOurNetworkRecord($route.params.uid).status ===
              networkStatusConstants.REQUEST_SEND &&
            getOurNetworkRecord($route.params.uid).requestor.id !== user.id
          "
        >
          <b-dropdown
            :triggers="['hover']"
            aria-role="menu"
            :mobile-modal="false"
            position="is-bottom-left"
          >
            <template #trigger>
              <b-button
                :label="showLabelsFromCode('/profile/profilebutton/requested')"
                type="is-info"
                icon-right="caret-down"
                icon-pack="fas"
                :size="size"
              />
            </template>
            <b-dropdown-item aria-role="menuitem" @click="approveRequestVue()"
              ><b-icon
                icon="thumbs-up"
                pack="fas"
                type="is-info"
                class="mr-2"
              />{{
                showLabelsFromCode('/profile/profilebutton/approve')
              }}</b-dropdown-item
            >
            <b-dropdown-item aria-role="menuitem " @click="rejectRequestVue()"
              ><b-icon
                icon="thumbs-down"
                pack="fas"
                type="is-danger"
                class="mr-2"
              />{{
                showLabelsFromCode('/profile/profilebutton/reject')
              }}</b-dropdown-item
            >
            <b-dropdown-item aria-role="menuitem" @click="blockUserVue()"
              ><b-icon icon="ban" pack="fas" type="is-danger" class="mr-2" />{{
                showLabelsFromCode('/profile/profilebutton/block')
              }}</b-dropdown-item
            >
            <hr class="navbar-divider" />
            <b-dropdown-item aria-role="menuitem" @click="reportUser()"
              ><b-icon
                icon="exclamation-triangle"
                pack="fas"
                type="is-danger"
                class="mr-2"
              /><span class="has-text-danger">{{
                showLabelsFromCode('/profile/profilebutton/report')
              }}</span></b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template
          v-else-if="
            getOurNetworkRecord($route.params.uid) &&
            getOurNetworkRecord($route.params.uid).status ===
              networkStatusConstants.REQUEST_SEND &&
            getOurNetworkRecord($route.params.uid).requestor.id === user.id
          "
        >
          <b-dropdown
            :triggers="['hover']"
            aria-role="menu"
            :mobile-modal="false"
            position="is-bottom-left"
          >
            <template #trigger>
              <b-button
                :size="size"
                :label="showLabelsFromCode('/profile/profilebutton/pending')"
                type="is-warning"
                icon-right="caret-down"
                icon-pack="fas"
              />
            </template>
            <b-dropdown-item aria-role="listitem " @click="cancelRequestVue()"
              ><b-icon
                icon="rectangle-xmark"
                pack="far"
                type="is-danger"
                class="mr-2"
              /><span></span
              >{{
                showLabelsFromCode('/profile/profilebutton/cancel')
              }}</b-dropdown-item
            >
            <b-dropdown-item aria-role="menuitem" @click="blockUserVue()"
              ><b-icon icon="ban" pack="fas" type="is-danger" class="mr-2" />{{
                showLabelsFromCode('/profile/profilebutton/block')
              }}</b-dropdown-item
            >
            <hr class="navbar-divider" />
            <b-dropdown-item aria-role="menuitem" @click="reportUser()"
              ><b-icon
                icon="exclamation-triangle"
                pack="fas"
                type="is-danger"
                class="mr-2"
              /><span class="has-text-danger">{{
                showLabelsFromCode('/profile/profilebutton/report')
              }}</span></b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template
          v-else-if="
            getOurNetworkRecord($route.params.uid) &&
            getOurNetworkRecord($route.params.uid).status ===
              networkStatusConstants.REQUEST_APPROVED
          "
        >
          <b-dropdown
            :triggers="['hover']"
            aria-role="menu"
            :mobile-modal="false"
            position="is-bottom-left"
          >
            <template #trigger>
              <b-button
                :size="size"
                :label="showLabelsFromCode('/profile/profilebutton/innetwork')"
                type="is-primary"
                class="profile-button__button background-color-override"
                icon-right="caret-down"
                icon-pack="fas"
              />
            </template>
            <b-dropdown-item
              aria-role="menuitem"
              @click="openSendAMessageModal()"
              ><b-icon
                type="is-info"
                icon="message"
                pack="far"
                class="mr-2"
              />{{
                showLabelsFromCode('/profile/profilebutton/sendchatmessage')
              }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="profileUserSchedulerDefaults?.network?.pageURL"
              aria-role="menuitem"
              has-link
              class="dropdown-item pl-0 py-0"
            >
              <a class="" :href="profileUserSchedulerDefaults?.network?.pageURL"
                ><b-icon
                  type="is-info"
                  icon="calendar-plus"
                  pack="far"
                  class="mr-2"
                >
                </b-icon
                >{{ profileUserSchedulerDefaults?.network?.buttonLabel }}</a
              >
            </b-dropdown-item>

            <b-dropdown-item
              v-if="getOurNetworkRecord($route.params.uid).subscribed"
              aria-role="menuitem"
              @click="unsubscribe()"
              ><b-icon
                type="is-danger"
                icon="circle-stop"
                pack="fas"
                class="mr-2"
              />{{
                showLabelsFromCode('/profile/profilebutton/unsubscribe')
              }}</b-dropdown-item
            >
            <b-dropdown-item v-else aria-role="menuitem" @click="subscribe()"
              ><b-icon type="is-info" icon="rss" pack="fas" class="mr-2" />{{
                showLabelsFromCode('/profile/profilebutton/subscribe')
              }}</b-dropdown-item
            >
            <b-dropdown-item aria-role="menuitem" @click="removeConnectionVue()"
              ><b-icon
                type="is-danger"
                icon="handshake-simple-slash"
                pack="fas"
                class="mr-2"
              />{{
                showLabelsFromCode('/profile/profilebutton/removeconnection')
              }}</b-dropdown-item
            >
            <b-dropdown-item aria-role="menuitem" @click="blockUserVue()"
              ><b-icon icon="ban" pack="fas" type="is-danger" class="mr-2" />{{
                showLabelsFromCode('/profile/profilebutton/block')
              }}</b-dropdown-item
            >
            <hr class="navbar-divider" />
            <b-dropdown-item aria-role="menuitem" @click="reportUser()"
              ><b-icon
                icon="exclamation-triangle"
                pack="fas"
                type="is-danger"
                class="mr-2"
              /><span class="has-text-danger">{{
                showLabelsFromCode('/profile/profilebutton/report')
              }}</span></b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template
          v-else-if="
            getOurNetworkRecord($route.params.uid) &&
            getOurNetworkRecord($route.params.uid).status ===
              networkStatusConstants.BLOCKED &&
            getOurNetworkRecord($route.params.uid).blockedById === user.id
          "
        >
          <b-dropdown
            :triggers="['hover']"
            aria-role="menu"
            :mobile-modal="false"
            position="is-bottom-left"
          >
            <template #trigger>
              <b-button
                :size="size"
                :label="showLabelsFromCode('/profile/profilebutton/blocked')"
                type="is-danger"
                icon-right="caret-down"
                icon-pack="fas"
              />
            </template>
            <b-dropdown-item aria-role="listitem " @click="unblockUserVue()"
              ><b-icon
                icon="thumbs-up"
                pack="fas"
                type="is-info"
                class="mr-2"
              />{{
                showLabelsFromCode('/profile/profilebutton/unblockuser')
              }}</b-dropdown-item
            >
            <b-dropdown-item aria-role="menuitem" @click="reportUser()"
              ><b-icon
                icon="exclamation-triangle"
                pack="fas"
                type="is-danger"
                class="mr-2"
              /><span class="has-text-danger">{{
                showLabelsFromCode('/profile/profilebutton/report')
              }}</span></b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template
          v-else-if="
            getOurNetworkRecord($route.params.uid) &&
            getOurNetworkRecord($route.params.uid).status ===
              networkStatusConstants.BLOCKED &&
            getOurNetworkRecord($route.params.uid).blockedById !== user.id
          "
        ></template>
        <template v-else-if="isPaidUser || isEnterpriseUser">
          <!-- <b-button v-if="buttonDisable" :size="size" disabled>Loading..</b-button> -->

          <b-dropdown
            :triggers="['hover']"
            aria-role="menu"
            :mobile-modal="false"
            position="is-bottom-left"
          >
            <template #trigger>
              <b-button
                type="is-primary"
                class="profile-button__button background-color-override"
                :size="size"
                :label="showLabelsFromCode('/profile/profilebutton/connect')"
                icon-right="caret-down"
                icon-pack="fas"
              />
            </template>
            <b-dropdown-item
              v-if="
                profileUserSchedulerDefaults?.colleague?.pageURL &&
                profileUser.companyId === user.companyId
              "
              aria-role="menuitem"
              has-link
              class="dropdown-item pl-0 py-0"
            >
              <a
                class=""
                :href="profileUserSchedulerDefaults?.colleague?.pageURL"
                ><b-icon
                  type="is-info"
                  icon="calendar-plus"
                  pack="far"
                  class="mr-2"
                >
                </b-icon
                >{{ profileUserSchedulerDefaults?.colleague?.buttonLabel }}</a
              >
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="menuitem"
              @click="requestConnectionVue()"
              ><b-icon
                icon="handshake-simple"
                pack="fas"
                type="is-info"
                class="mr-2"
              />{{
                showLabelsFromCode('/profile/profilebutton/requestconnection')
              }}</b-dropdown-item
            >
            <b-dropdown-item
              aria-role="menuitem"
              class="profile-button__report"
              @click="reportUser()"
              ><b-icon
                icon="exclamation-triangle"
                pack="fas"
                type="is-danger"
                class="mr-2"
              />{{
                showLabelsFromCode('/profile/profilebutton/report')
              }}</b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template v-else-if="isGuestUser">
          <b-dropdown
            :triggers="['hover']"
            aria-role="menu"
            :mobile-modal="false"
            position="is-bottom-left"
          >
            <template #trigger>
              <b-button
                type="is-primary"
                class="profile-button__button background-color-override"
                :size="size"
                :label="showLabelsFromCode('generic/menu')"
                icon-right="caret-down"
                icon-pack="fas"
              />
            </template>
            <b-dropdown-item
              aria-role="menuitem"
              class="profile-button__report"
              @click="reportUser()"
              ><b-icon
                icon="exclamation-triangle"
                pack="fas"
                type="is-danger"
                class="mr-2"
              />{{
                showLabelsFromCode('/profile/profilebutton/report')
              }}</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { GoogleCalendar } from 'datebook'
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions'

import {
  networkStatusConstants,
  networkStatusValues,
} from '@/data/networkStatus'
import { sendEmail } from '@/misc/user-messages'
import { trackNavigationEvent } from '@/misc/analytics'
import { viewProfileMixin } from '@/mixins/profile/index'
import { functions } from '@/firebase/initFirebase'

import ShareLink from '@/components/ShareLink'
import SendMessage from '@/components/SendMessage'

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
}

export default {
  name: 'ProfileButton',
  mixins: [viewProfileMixin],
  data() {
    return {
      networkStatusConstants,
      networkStatusValues,
      isLoading: true,
      // buttonDisable: false,
    }
  },

  computed: {
    ...mapGetters('networkStatus', ['getOurNetworkRecord']),
    ...mapGetters('user', ['isPaidUser', 'isGuestUser', 'isEnterpriseUser']),
    ...mapState('app', ['appURL']),
    ...mapState('user', ['user']),
    ...mapState('appconfig', ['profileUserSchedulerDefaults']),
  },
  created() {
    const params = new URLSearchParams(window.location.search)
    if (params.has('code')) {
      this.exchangeCodeFromUrlForToken()
        .then((r) => {
          const user = JSON.parse(r)
          this.userId = user.id
          window.history.replaceState({}, '', `/?userId=${user.id}`)
        })
        .catch(() => {
          console.error('An error occurred parsing the response.')
          window.history.replaceState({}, '', '/')
        })
    }

    if (params.has('userId')) {
      this.userId = params.get('userId')
    }
  },
  mounted() {
    this.fetchAppConfig()
    this.isLoading = false
  },

  methods: {
    ...mapActions('appconfig', ['fetchUserAppConfigByUserId']),
    ...mapMutations('appconfig', ['setProfileUserSchedulerDefaults']),
    ...mapActions('networkStatus', [
      'requestConnectionAction',
      'updateStatusAction',
      'removeConnectionAction',
      'updateSubscription',
    ]),
    ...mapMutations('networkStatus', [
      'addToNetwork',
      'setNetworkStatus',
      'blockUserInCache',
      'removeNetworkByFriendId',
      'setSubscriptionStatus',
    ]),
    async fetchAppConfig() {
      this.isLoading = true
      await this.fetchUserAppConfigByUserId({
        userId: this.$route.params.uid,
        documentId: 'scheduler',
      })
        .then((response) => {
          if (response) {
            this.setProfileUserSchedulerDefaults(response)
          }
        })
        .catch((error) => {
          console.log(error)
          this.handleErrorMessage("Error in fetching user's scheduler defaults")
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    trackEvent(eventName) {
      trackNavigationEvent(eventName, 'Edit Profile', 'page', 'button')
    },
    requestMeeting() {
      const config = {
        title: 'Meeting Request',
        location: '',
        description: '',
        start: new Date(+new Date().setHours(13, 0, 0, 0)),
        end: new Date(+new Date().setHours(13, 30, 0, 0)),
        attendees: [
          {
            name: this.profileUser.displayName,
            email: this.profileUser.email,
          },
        ],
      }
      const googleCalendar = new GoogleCalendar(config)
      window.open(googleCalendar.render(), '_blank')
    },
    async requestConnectionVue() {
      // console.log(JSON.stringify(this.user))
      this.isLoading = true
      this.requestConnectionAction({
        profileUser: this.profileUser,
        user: this.user,
        status: networkStatusConstants.REQUEST_SEND,
      })
        .then(async (response) => {
          console.log(JSON.stringify(response))
          const notitificationRecord = {
            title: `🤝 Connection Request`,
            body: `You have a connection request from ${this.user.displayName}`,
            link: `${this.appURL}view/profile/${this.user.id}`,
          }
          const sendNotificationFunction = httpsCallable(
            functions,
            'sendNotification',
          )
          try {
            await sendNotificationFunction({
              uid: this.profileUser.id,
              notitificationRecord,
            })
          } catch (error) {
            console.log(error)
          }

          this.trackEngagementEvent(
            'request_connection',
            `${this.user.id} requested connection to ${this.profileUser.id}`,
            'page',
            'button',
          )
          this.handleSuccessMessage(
            this.showUserMessageFromCode(
              '/profile/profilebutton/connection/sent/success',
            ),
          )
          const friendRecord = {
            id: this.profileUser.id,
            requestor: {
              id: this.user.id,
              displayName: this.user.displayName,
            },
            friend: {
              id: this.profileUser.id,
              displayName: this.profileUser.displayName,
              photoURL: this.profileUser.photoURL,
              title: this.profileUser.title || '',
              email: this.profileUser.email,
              companyId: this.profileUser.companyId || null,
              companyName: this.profileUser.companyName || '',
              licenseType: this.profileUser.licenseType || 'GUEST',
            },
            status: networkStatusConstants.REQUEST_SEND,
          }
          this.addToNetwork(friendRecord)
          sendEmail(
            this.profileUser.email,
            this.profileUser.displayName,
            this.user.displayName,
            this.user.email,
            `You have received a network request from ${this.user.displayName}.You can approve/reject the request by visiting their profile at: ${process.env.VUE_APP_URL}view/profile/${this.user.id}`,
          ).catch((error) => {
            this.trackExceptionEvent(
              'connection_email_sent_failed',
              error.text,
              false,
            )
            this.handleErrorMessage(
              `Error in sending request email to ${this.profileUser.email}`,
            )
          })
        })
        .catch((err) => {
          this.trackExceptionEvent(
            'request_connection_failed',
            `${this.user.id} tried to request connection to ${this.profileUser.id}. ${err}`,
            false,
          )
          this.handleErrorMessage(
            `${this.showUserMessageFromCode(
              '/profile/profilebutton/connection/error',
            )}`,
          )
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async approveRequestVue() {
      this.isLoading = true
      this.updateStatusAction({
        profileUser: this.profileUser,
        user: this.user,
        status: networkStatusConstants.REQUEST_APPROVED,
      })
        .then(async () => {
          const notitificationRecord = {
            title: `🤝 Connection request approved`,
            body: `You are now connected with ${this.user.displayName}`,
            link: `${this.appURL}view/profile/${this.user.id}`,
          }
          const sendNotificationFunction = httpsCallable(
            functions,
            'sendNotification',
          )
          try {
            await sendNotificationFunction({
              uid: this.profileUser.id,
              notitificationRecord,
            })
          } catch (error) {
            console.log(error)
          }

          this.trackEngagementEvent(
            'connection_approved',
            `${this.user.id} approved connection to ${this.profileUser.id}`,
            'page',
            'button',
          )
          this.handleSuccessMessage(
            this.showUserMessageFromCode(
              '/profile/profilebutton/connection/approval/success',
            ),
          )
          this.setNetworkStatus({
            friendId: this.profileUser.id,
            status: networkStatusConstants.REQUEST_APPROVED,
          })
          sendEmail(
            this.profileUser.email,
            this.profileUser.displayName,
            this.user.displayName,
            this.user.email,
            `Your network request was approved by ${this.user.displayName}.You can view their profile at: ${process.env.VUE_APP_URL}view/profile/${this.user.id}`,
          ).catch((error) => {
            this.trackExceptionEvent(
              'connection_email_sent_failed',
              error.text,
              false,
            )
            this.handleErrorMessage(
              `Error in sending request email to ${this.profileUser.email}`,
            )
          })
        })
        .catch((err) => {
          this.trackExceptionEvent(
            'connection_approval_failed',
            `${this.user.id} tried to approve connection for ${this.profileUser.id}`,
            false,
          )
          this.handleErrorMessage(
            this.showUserMessageFromCode(
              '/profile/profilebutton/connection/error',
            ),
          )
          console.log(err.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async removeConnectionVue() {
      this.isLoading = true
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          'Are you sure you want to remove the connection? This request is not reversible',
        )
      ) {
        const notificationRecord = {
          action: 'removed',
          displayName: this.profileUser.displayName,
          friendId: this.profileUser.id,
          message: '',
          photoURL: this.profileUser.photoURL,
          type: 'connection',
        }
        this.removeConnectionAction({
          profileUser: this.profileUser,
          user: this.user,
          notificationRecord,
          status: networkStatusConstants.REMOVED,
        })
          .then(() => {
            this.trackEngagementEvent(
              'connection_removed',
              `${this.user.id} removed connection with ${this.profileUser.id}`,
              'page',
              'button',
            )
            this.handleSuccessMessage(
              this.showUserMessageFromCode(
                '/profile/profilebutton/connection/removal/success',
              ),
            )
            this.removeNetworkByFriendId(this.profileUser.id)
            sendEmail(
              this.user.email,
              this.user.displayName,
              'Admin',
              'bot@siyn.email',
              `You removed connection with ${this.profileUser.displayName}, they would not know about this until they visit your page. If needed you can always perform Block User action for any user. Doing so will ensure they can never send you a network request.`,
            ).catch((error) => {
              this.trackExceptionEvent(
                'connection_remove_email_send_failed',
                error.text,
                false,
              )
              this.handleErrorMessage(
                `Error in sending request email to ${this.user.email}`,
              )
            })
          })
          .catch((err) => {
            this.trackExceptionEvent(
              'connection_removal_failed',
              `${this.user.id} tried to remove connection with ${this.profileUser.id}`,
              false,
            )
            this.handleErrorMessage(
              this.showUserMessageFromCode(
                '/profile/profilebutton/connection/error',
              ),
            )
            console.log(err.message)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    async rejectRequestVue() {
      this.isLoading = true
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          'Are you sure you want to reject the request? This action is not reversible',
        )
      ) {
        const notificationRecord = {
          action: 'rejected',
          displayName: this.profileUser.displayName,
          friendId: this.profileUser.id,
          message: '',
          photoURL: this.profileUser.photoURL,
          type: 'connection',
        }
        this.removeConnectionAction({
          profileUser: this.profileUser,
          user: this.user,
          notificationRecord,
        })
          .then(() => {
            this.trackEngagementEvent(
              'connection_request_rejected',
              `${this.user.id} rejected connection with ${this.profileUser.id}`,
              'page',
              'button',
            )
            this.handleSuccessMessage(
              this.showUserMessageFromCode(
                '/profile/profilebutton/connection/reject/success',
              ),
            )
            this.removeNetworkByFriendId(this.profileUser.id)
          })
          .catch((err) => {
            this.trackExceptionEvent(
              'connection_rejection_failed',
              `${this.user.id} tried to reject connection with ${this.profileUser.id}`,
              false,
            )
            this.handleErrorMessage(
              this.showUserMessageFromCode(
                '/profile/profilebutton/connection/error',
              ),
            )
            console.log(err.message)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    async cancelRequestVue() {
      this.isLoading = true
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          'Are you sure you cancel the request? This action is not reversible',
        )
      ) {
        const notificationRecord = {
          action: 'cancelled',
          displayName: this.profileUser.displayName,
          friendId: this.profileUser.id,
          message: '',
          photoURL: this.profileUser.photoURL,
          type: 'connection',
        }
        this.removeConnectionAction({
          profileUser: this.profileUser,
          user: this.user,
          notificationRecord,
        })
          .then(async () => {
            const notitificationRecord = {
              title: `🤝 Connection request cancelled`,
              body: `${this.user.displayName} cancelled connection request.`,
              link: `${this.appURL}view/profile/${this.user.id}`,
            }
            const sendNotificationFunction = httpsCallable(
              functions,
              'sendNotification',
            )
            try {
              await sendNotificationFunction({
                uid: this.profileUser.id,
                notitificationRecord,
              })
            } catch (error) {
              console.log(error)
            }
            this.trackEngagementEvent(
              'connection_request_cancelled',
              `${this.user.id} cancelled connection request with ${this.profileUser.id}`,
              'page',
              'button',
            )
            this.handleSuccessMessage(
              this.showUserMessageFromCode(
                '/profile/profilebutton/connection/cancel/success',
              ),
            )
            this.removeNetworkByFriendId(this.profileUser.id)
            sendEmail(
              this.profileUser.email,
              this.profileUser.displayName,
              this.user.displayName,
              this.user.email,
              `${this.user.displayName} cancelled their network connection request with you. You can view their profile at: ${this.appURL}view/profile/${this.user.id}`,
            )
              .then(() => {
                this.showUserMessageFromCode(
                  `Informational email sent to ${this.profileUser.email}`,
                )
              })
              .catch((error) => {
                this.trackExceptionEvent(
                  'connection_email_sent_failed',
                  error.text,
                  false,
                )
                this.handleErrorMessage(
                  `Error in sending request email to ${this.profileUser.email}`,
                )
              })
          })
          .catch((err) => {
            this.trackExceptionEvent(
              'connection_cancel_failed',
              `${this.user.id} tried to cancel connection request with ${this.profileUser.id}`,
              false,
            )
            this.handleErrorMessage(
              this.showUserMessageFromCode(
                '/profile/profilebutton/connection/error',
              ),
            )
            console.log(err.message)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    blockUserVue() {
      this.isLoading = true
      if (
        // eslint-disable-next-line no-alert
        window.confirm('Are you sure you want to block the connection?')
      ) {
        const notificationRecord = {
          action: 'blocked',
          displayName: this.profileUser.displayName,
          friendId: this.profileUser.id,
          message: '',
          photoURL: this.profileUser.photoURL,
          type: 'connection',
        }
        this.updateStatusAction({
          profileUser: this.profileUser,
          user: this.user,
          notificationRecord,
          status: networkStatusConstants.BLOCKED,
          blockedById: this.user.id,
        })
          .then(() => {
            this.trackEngagementEvent(
              'connection_blocked',
              `${this.user.id} blocked connection with ${this.profileUser.id}`,
              'page',
              'button',
            )
            this.handleSuccessMessage(
              this.showUserMessageFromCode(
                '/profile/profilebutton/connection/block/success',
              ),
            )
            this.blockUserInCache({
              friendId: this.profileUser.id,
              status: networkStatusConstants.BLOCKED,
              blockedById: this.user.id,
            })
            sendEmail(
              this.user.email,
              this.user.displayName,
              'Admin',
              'bot@siyn.email',
              `You blocked connection with ${this.profileUser.displayName}, they would not know about this until they visit your page. If needed you can always perform Unblock User action for any user by going to their profile. Depending upon your configuration full or partial profile may still be visible to the block user, however they will no longer be able to send you connection request. `,
            ).catch((error) => {
              this.trackExceptionEvent(
                'block_email_send_failed',
                error.text,
                false,
              )
              this.handleErrorMessage(
                `Error in sending informational email to ${this.user.email}`,
              )
            })
          })
          .catch((err) => {
            this.trackExceptionEvent(
              'connection_block_failed',
              `${this.user.id} tried to block connection with ${this.profileUser.id}`,
              false,
            )
            this.handleErrorMessage(
              this.showUserMessageFromCode(
                '/profile/profilebutton/connection/error',
              ),
            )
            console.log(err.message)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    unblockUserVue() {
      this.isLoading = true
      if (
        // eslint-disable-next-line no-alert
        window.confirm('Are you sure you want to unblock the user?')
      ) {
        const notificationRecord = {
          action: 'unblocked',
          displayName: this.profileUser.displayName,
          friendId: this.profileUser.id,
          message: '',
          photoURL: this.profileUser.photoURL,
          type: 'connection',
        }
        this.removeConnectionAction({
          profileUser: this.profileUser,
          user: this.user,
          notificationRecord,
        })
          .then(() => {
            this.trackEngagementEvent(
              'connection_unblocked',
              `${this.user.id} unblocked connection with ${this.profileUser.id}`,
              'page',
              'button',
            )
            this.handleSuccessMessage(
              this.showUserMessageFromCode(
                '/profile/profilebutton/connection/unblock/success',
              ),
            )
            this.removeNetworkByFriendId({
              friendId: this.profileUser.id,
              status: networkStatusConstants.REMOVED,
            })
            this.removeNetworkByFriendId(this.profileUser.id)
          })
          .catch((err) => {
            this.trackExceptionEvent(
              'connection_unblock_failed',
              `${this.user.id} tried to unblock connection request with ${this.profileUser.id}`,
              false,
            )
            this.handleErrorMessage(
              this.showUserMessageFromCode(
                '/profile/profilebutton/connection/error',
              ),
            )
            console.log(err.message)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    reportUser() {
      this.$router.push({
        name: 'report',
        params: {
          myuid: this.user.id,
          friendId: this.profileUser.id,
          friendName: this.profileUser.displayName,
          friendEmail: this.profileUser.email,
          myEmailId: this.user.email,
          myName: this.user.displayName,
        },
      })
    },

    subscribe() {
      this.isLoading = true
      this.updateSubscription({
        userId: this.user.id,
        friendId: this.$route.params.uid,
        subscribed: true,
      })
        .then(() => {
          this.handleSuccessMessage(
            this.showUserMessageFromCode('teams/team/subscribed'),
          )
          this.setSubscriptionStatus({
            friendId: this.$route.params.uid,
            subscribed: true,
          })
        })
        .catch((error) => {
          console.log(error)
          this.handleErrorMessage()
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    unsubscribe() {
      this.isLoading = true
      this.updateSubscription({
        userId: this.user.id,
        friendId: this.$route.params.uid,
        subscribed: false,
      })
        .then(() => {
          this.handleSuccessMessage(
            this.showUserMessageFromCode('teams/team/unsubscribed'),
          )
          this.setSubscriptionStatus({
            friendId: this.$route.params.uid,
            subscribed: false,
          })
        })
        .catch((error) => {
          console.log(error)
          this.handleErrorMessage()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    openShareModal() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        props: {
          url: window.location.href,
          pageName: `${this.user?.displayName}'s Profile`,
        },
        component: ShareLink,
        trapFocus: true,
      })
    },
    openSendAMessageModal() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        props: {
          messageTo: this.profileUser.displayName,
          // pageName: `${this.user?.displayName}'s Profile`,
        },
        component: SendMessage,
        trapFocus: true,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.dropdown-item {
  text-align: left;
}
</style>
