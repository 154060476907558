/* eslint-disable no-unused-vars */

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'
import ViewUserAvatar from '@/components/ViewUserAvatar'
import FullPage from '@/components/partials/FullPage'
import Skeleton from '@/components/partials/Skeleton'
import SiynCollapse from '@/components/SiynCollapse'

import { teamStatusList, teamStatusConstants } from '@/data/teams/team-status'
import {
  teamMemberStatus,
  teamMemberStatusConstants,
} from '@/data/teams/team-member-status'

import {
  notificationActionTypes,
  notificationActionTypesConstants,
} from '@/data/notificationActionTypes'
import memberRoles from '@/data/teams/team-member-role'
import { trackNavigationEvent, trackExceptionEvent } from '@/misc/analytics'
import {
  showLabelsFromCode,
  showTooltipsFromCode,
  showUserMessageFromCode,
  getNameForId,
  momentAgo,
  collapseHelper,
  humanReadableDateTime,
  formatTime,
  formatDateTime,
  renderConferenceProvider,
} from '@/misc/helpers'
import teamEventTypes from '@/data/teams/team-event-types'
import { teamTypesList } from '@/data/teams/team-types'

const viewTeamsMixin = {
  filters: {
    truncate(value, length) {
      return value.length > length ? `${value.substr(0, length)}...` : value
    },
  },
  components: { FullPage, Skeleton, SiynCollapse, ViewUserAvatar },
  data() {
    return {
      teamEventTypes,
      teamTypesList,
      teamStatusList,
      teamStatusConstants,
      teamMemberStatus,
      teamMemberStatusConstants,
      notificationActionTypes,
      notificationActionTypesConstants,
      memberRoles,
      defaultTeamImgSrc: process.env.VUE_APP_DEFAULT_TEAM_IMAGE,
    }
  },
  computed: {
    ...mapState('userTeams', ['activeUserTeams']),
    ...mapState('teamMembers', ['teamMembers']),
    ...mapGetters('user', [
      'isUserLoggedIn',
      'getAuthUserId',
      'getAuthUserCompanyId',
      'getAuthUserLicenseType',
      'isPaidUser',
      'isEnterpriseUser',
      'getUpdatedBy',
      'getTinyUser',
    ]),
    ...mapState('teams', [
      'teamErrors',
      'teamToView',
      'isUserTeamAdminCache',
      'isUserPartOfTeamCache',
      'isUserInSameCompanyAsTeam',
      'isUserATeamSubscriber',
    ]),
    ...mapState('user', ['user', 'userCompany']),
    ...mapGetters('teams', [
      // 'isUserInSameCompanyAsTeam',
      'getTeamStatus',
      'getUsersMemberStatusForTheTeam',
    ]),
    ...mapState('teamsEvents', ['teamEventToView']),
    ...mapState('teamBroadcasts', ['teamBroadcasts']),
  },
  created() {},
  methods: {
    ...mapMutations('teams', [
      'setTeamToView',
      'setTeamToEdit',
      'setTeamNotFoundError',
      'setUnknownError',
      'setIsUserTeamAdminCache',
      'setIsUserPartOfTeamCache',
      'setIsUserInSameCompanyAsTeam',
      'setIsUserATeamSubscriber',
    ]),
    ...mapMutations('teamsUpdates', ['setTeamUpdates']),
    ...mapMutations('teamMembers', ['setTeamMembers']),
    isEmpty,
    showLabelsFromCode,
    showTooltipsFromCode,
    showUserMessageFromCode,
    handleSuccessMessage,
    handleErrorMessage,
    getNameForId,
    momentAgo,
    collapseHelper,
    humanReadableDateTime,
    trackNavigationEvent,
    trackExceptionEvent,
    formatTime,
    formatDateTime,
    renderConferenceProvider,
  },
}

export default viewTeamsMixin
