<template>
  <section class="view-company-tokens">
    <skeleton v-if="isLoading"></skeleton>
    <SiynCollapse
      v-if="companyTokens || isUserCompanyAdmin"
      :title="panelTitle"
      emoji="🎁"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <template #header-extras>
        <b-tooltip
          multilined
          class="mx-4"
          type="is-dark"
          label="Use Guest Tokens to invite people who are not in your organization. This panel is only visible to the company admins"
          position="is-right"
          ><b-icon
            class="has-text-grey-lighter"
            pack="fas"
            icon="info-circle"
            size="is-small"
          >
          </b-icon></b-tooltip
        ><span class="pl-2 has-text-grey is-size-6" style="margin-top: 3px">
          {{ tokensUtilizedByCompany }} of {{ maxTokensPerCompany }} licenses
          used
        </span>
      </template>
      <template #default>
        <b-table
          v-if="companyTokens"
          ref="table"
          :data="companyTokens"
          hoverable
          mobile-cards
          narrowed
          :show-header="companyTokens.length > 0 ? true : false"
          :paginated="companyTokens.length > 6 ? true : false"
          custom-row-key="id"
          default-sort="endDateTime"
          default-sort-direction="desc"
          per-page="6"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          class="view-company-tokens__table mx-auto"
        >
          <b-table-column
            v-slot="props"
            scope="props"
            field="eventName"
            label="Token ID"
            cell-class="valign"
          >
            <span>
              <a
                class="no-text-decoration"
                @click="openViewCompanyTokenModal(props.row)"
              >
                <span>{{ props.row.id }}</span></a
              >
            </span>
          </b-table-column>
          <b-table-column
            v-slot="props"
            scope="props"
            field="createdBy.displayName"
            cell-class="valign"
            label="Created By"
            sortable
          >
            <template v-if="props.row?.createdBy">
              <router-link
                v-if="props.row.createdBy?.id"
                class="no-text-decoration"
                :to="{
                  name: 'profile',
                  params: { uid: props.row?.createdBy?.id },
                }"
              >
                <span class="view-company-tokens__table--displayName">{{
                  props.row.createdBy.displayName
                }}</span></router-link
              >
            </template>
          </b-table-column>

          <b-table-column
            v-slot="props"
            scope="props"
            field="endDateTime"
            cell-class="valign"
            label="Valid Until"
            sortable
          >
            <span>{{ formatDateWithYear(props.row.endDateTime) }} </span>
          </b-table-column>

          <b-table-column
            v-slot="props"
            scope="props"
            field="createdFor.displayName"
            cell-class="valign"
            label="Created For"
            sortable
          >
            <span>
              {{ props.row.createdFor.displayName }} -
              {{ props.row.createdFor.email }}
            </span>
          </b-table-column>
        </b-table>

        <div
          v-if="!isEmpty(companyTokens)"
          class="columns is-multiline is-gapless"
        ></div>
        <span v-else class="ml-4 empty-content">{{
          showUserMessageFromCode('generic/none')
        }}</span>

        <div
          v-if="isUserCompanyAdmin"
          class="has-text-center-touch has-text-right"
        >
          <b-dropdown
            :triggers="['hover']"
            aria-role="menu"
            :mobile-modal="false"
            position="is-bottom-left"
            class="has-text-left"
          >
            <template #trigger>
              <b-button
                :label="`${showLabelsFromCode(
                  'company/tokens/panelTitle',
                )} ${showLabelsFromCode('generic/menu')}`"
                type="is-primary"
                class="view-company-tokens__button background-color-override"
                icon-right="caret-down"
                icon-pack="fas"
              />
            </template>
            <b-dropdown-item
              v-if="
                !companyTokens?.length ||
                companyTokens?.length <= maxTokensPerCompany
              "
              aria-role="menuitem"
              @click="openAddTokensModel()"
              ><b-icon type="is-info" icon="plus-square" pack="fas" />{{
                showLabelsFromCode('generic/add')
              }}
              {{ showLabelsFromCode('company/tokens/token') }}
            </b-dropdown-item>
            <!-- <b-dropdown-item
              aria-role="menuitem"
              @click="gotoListAllTokensScreen()"
              ><b-icon type="is-info" icon="list" pack="fas" />{{
                showLabelsFromCode('generic/list')
              }}
              All
              {{ showLabelsFromCode('company/tokens/token') }}s</b-dropdown-item
            > -->
          </b-dropdown>
        </div>
      </template>
    </SiynCollapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations, mapActions } from 'vuex'
import { viewCompanyMixin } from '@/mixins/company/index'
import AddCompanyTokenFormModal from '@/views/company/tokens/AddCompanyTokenFormModal.vue'
import ViewCompanyTokenModal from '@/views/company/tokens/ViewCompanyTokenModal.vue'
// import ListCompanyTokens from '@/views/company/tokens/ListCompanyTokens.vue'
import { formatDateWithYear } from '@/misc/helpers'

export default {
  name: 'CompanyTokens',
  components: {},
  mixins: [viewCompanyMixin],
  data() {
    return {
      isLoading: true,
      panelTitle: this.showLabelsFromCode('company/tokens/panelTitle'),
      panelname: 'company_tokens',
      maxTokensPerCompany: 20,
      tokensUtilizedByCompany: 0,
    }
  },
  computed: {
    ...mapState('company', ['companyToView', 'companyTokens']),
  },
  created() {
    this.fetchLicense()
    this.fetchTokenCount()
  },
  mounted() {
    this.isLoading = false
    // console.log(JSON.stringify('companytokens mounted'))
    if (this.$route.params.companyid) {
      this.fetchCompanyTokens()
    }
  },
  methods: {
    formatDateWithYear,
    ...mapMutations('company', [
      'setCompanyToEdit',
      'setCompanyTokens',
      'setCompanyTokenToView',
    ]),
    ...mapActions('company', [
      'fetchCompanyTokensByCompanyId',
      'fetchCompanyLicenseByCompanyId',
      'fetchTotalCompanyTokensCount',
    ]),
    fetchTokenCount() {
      this.fetchTotalCompanyTokensCount(this.$route.params.companyid)
        .then((countResponse) => {
          this.tokensUtilizedByCompany = countResponse
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fetchLicense() {
      this.isLoading = true
      this.fetchCompanyLicenseByCompanyId({
        id: 'validity',
        companyId: this.$route.params.companyid,
      })
        .then((licenseResponse) => {
          this.maxTokensPerCompany = licenseResponse.maxGuestLicenses
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fetchCompanyTokens() {
      this.isLoading = true
      const orderBys = []
      orderBys.push(['endDateTime', 'asc'])
      this.fetchCompanyTokensByCompanyId({
        companyId: this.$route.params.companyid,
        constraints: null,
        limit: 6,
        orderBys,
      })
        .then((companyTokensResponse) => {
          if (!this.isEmpty(companyTokensResponse)) {
            // console.log(JSON.stringify(companyTokensResponse))
            this.setCompanyTokens(companyTokensResponse)
          }
        })
        .catch((error) => {
          console.log(error)
          this.handleErrorMessage('Error in fetching company tokens')
          this.setCompanyTokens(null)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    openAddTokensModel() {
      this.setCompanyToEdit(this.companyToView)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: AddCompanyTokenFormModal,
        events: {
          add_successful: () => {
            this.handleSuccessMessage('Token added successfully!')
          },
          trapFocus: true,
        },
      })
    },
    // gotoListAllTokensScreen() {
    //   this.setCompanyToEdit(this.companyToView)
    // },
    openViewCompanyTokenModal(companyToken) {
      this.setCompanyTokenToView(companyToken)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: ViewCompanyTokenModal,
        trapFocus: true,
      })
    },
  },
}
</script>
