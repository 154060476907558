<template>
  <div class="edit-event-speaker">
    <b-loading v-model="isLoading" :is-full-page="false">
      <b-icon
        pack="fas"
        icon="spinner"
        size="is-large"
        custom-class="fa-pulse"
        type="is-primary"
      >
      </b-icon
    ></b-loading>
    <synform
      v-if="!speakerIsExternalUser"
      :title="panelTitle"
      :operation="updateEventSpeaker"
      :button-text="panelTitle"
      :back-route="backRouteComputed"
      :modal-form="true"
    >
      <b-message v-model="showError" type="is-danger" class="mb-4">
        {{ errorMessage }}
      </b-message>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="email"
        rules="required|email"
      >
        <b-field
          class="required"
          label="Email Address of new Speaker"
          expanded
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          message="Must be a valid email address."
        >
          <b-input v-model.trim="form.email" name="email" required />
        </b-field>
      </ValidationProvider>
    </synform>
    <synform
      v-else
      :title="panelTitle2"
      :operation="addEventSpeaker"
      :button-text="panelTitle2"
      :back-route="backRouteComputed"
      :modal-form="true"
    >
      <b-message type="is-danger" class="mb-4">
        <p>
          Speaker not found, if this is an external user please add the
          following information about the speaker.
        </p>
      </b-message>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="displayName"
        rules="required|min:2|max:30"
      >
        <b-field
          class="required"
          label="Full Name of Speaker"
          expanded
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          message="Must be a valid name."
        >
          <b-input
            v-model.trim="newSpeaker.displayName"
            name="displayName"
            required
          />
        </b-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="email"
        rules="required|email"
      >
        <b-field
          class="required"
          label="Email Address of Speaker"
          expanded
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          message="Must be a valid email address."
        >
          <b-input v-model.trim="newSpeaker.email" name="email" required />
        </b-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="companyName"
        rules="required|min:2|max:30"
      >
        <b-field
          class="required"
          label="Company Name"
          expanded
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          message="Must be a valid name."
        >
          <b-input
            v-model.trim="newSpeaker.companyName"
            name="companyName"
            required
          />
        </b-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="title"
        rules="required|min:2|max:30"
      >
        <b-field
          class="required"
          label="Job Title of Speaker"
          expanded
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          message="Must be a valid title."
        >
          <b-input v-model.trim="newSpeaker.title" name="title" required />
        </b-field>
      </ValidationProvider>
      <b-field label="Photo of Speaker" expanded class="mt-2">
        <UploadImg
          hint="Upload Image"
          :foldername="`/company/${companyEventToEdit.companyId}/events`"
          filename="speakerImage.png"
          :resize-width="175"
          :resize-height="175"
          :width="130"
          :height="130"
          :uid="`${companyEventToEdit.id}/speaker`"
          round
          :img-url="newSpeaker.imageUrl"
          @uploaded="onUploadedImage"
        />
      </b-field>
    </synform>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations, mapActions } from 'vuex'
import { editCompanyMixin } from '@/mixins/company/index'
import UploadImg from '@/components/UploadImg.vue'
import { trackPageView, trackScreenView } from '@/misc/analytics'

export default {
  name: 'EditEventSpeaker',
  components: {
    UploadImg,
  },
  mixins: [editCompanyMixin],
  props: {},
  data() {
    return {
      isLoading: true,
      panelTitle: 'Update Event Speaker',
      panelTitle2: 'Add Event Speaker',
      speakerIsExternalUser: false,
      form: {
        email: null,
      },
      newSpeaker: {
        email: null,
        displayName: null,
        imageUrl: null,
        companyName: null,
        title: null,
      },
    }
  },
  metaInfo: {
    title: 'Edit Event Speaker',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  computed: {
    ...mapState('company', ['companyEventToEdit']),
    backRouteComputed() {
      return `/view/company/${this.companyEventToEdit.companyId}/event/${this.companyEventToEdit.id}`
    },
  },
  created() {
    if (this.isEmpty(this.companyEventToEdit)) {
      this.showError = true
    }
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapActions('company', [
      'getUserByEmailAddress',
      'updateCompanyEventAction',
    ]),
    ...mapMutations('company', ['setCompanyEventToView']),
    updateEventSpeaker() {
      this.isLoading = true
      this.getUserByEmailAddress({
        email: this.form.email,
        companyId: this.companyEventToEdit.companyId,
      })
        .then((user) => {
          if (user.length > 0) {
            const speaker = {
              id: user[0].id,
              displayName: user[0].displayName,
            }
            const tempEvent = {
              ...this.companyEventToEdit,
              speaker,
            }
            this.updateCompanyEventAction({
              companyEvent: tempEvent,
            })
              .then(() => {
                this.trackEngagementEvent(
                  'eventSpeaker_added',
                  `${user[0].displayName} was updated as Speaker for ${this.companyEventToEdit.id}`,
                  'page',
                  'button',
                )
                this.setCompanyEventToView(tempEvent)
                this.handleSuccessMessage(
                  `${user[0].displayName} was sucessfully updated as speaker.`,
                )
                this.$emit('updated_successfully', speaker)
                this.close()
              })
              .catch((err) => {
                this.trackExceptionEvent(
                  'eventSpeaker_add_falied',
                  err.message,
                  false,
                )
              })
          } else {
            this.speakerIsExternalUser = true
          }
        })
        .catch((error) => {
          this.trackExceptionEvent(
            'fetch_user_failed',
            `Fetch user failed for email: ${this.form.email}`,
          )
          console.log(error)
          this.errorMessage =
            'Unknown error occurred while looking up the user by email, please try again'
          this.showError = true
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    addEventSpeaker() {
      this.isLoading = true
      const speaker = this.newSpeaker
      const tempEvent = {
        ...this.companyEventToEdit,
        speaker,
      }
      this.updateCompanyEventAction({
        companyEvent: tempEvent,
      })
        .then(() => {
          this.trackEngagementEvent(
            'eventSpeaker_added',
            `${this.newSpeaker.displayName} was add as Speaker for ${this.companyEventToEdit.id}`,
            'page',
            'button',
          )
          this.handleSuccessMessage(
            `${this.newSpeaker.displayName} was sucessfully updated as the speaker. Refresh page to see changes.`,
          )
          this.$emit('updated_successfully', speaker)
          this.close()
        })
        .catch((err) => {
          this.trackExceptionEvent(
            'eventSpeaker_add_falied',
            err.message,
            false,
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    close() {
      this.$emit('close')
    },
    onUploadedImage(imageURL) {
      this.newSpeaker.imageUrl = imageURL
    },
  },
}
</script>
<style lang="scss">
.edit-event-speaker {
  .upload-img {
    margin: initial !important;
  }
  .upload-img .upload-overlay .hint.no-img {
    color: $body-color !important;
  }
}
</style>
