/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import UserLeisuresDB from '@/firebase/leisures/leisures-db'

export default {
  /** ************* NEW CODE ************ */
  fetchLeisuresByUserId: async ({ commit }, payload) => {
    const userLeisuresDB = new UserLeisuresDB(payload.userId)
    const constraints = payload.constraints || []
    const orderBys = payload.orderBys || []
    const limit = payload.limit || null
    return userLeisuresDB.readAllWithOrderByLimit(constraints, orderBys, limit)
  },

  addLeisure: async ({ commit, rootState }, leisure) => {
    const userLeisuresDB = new UserLeisuresDB(rootState.user.user.id)
    return userLeisuresDB.create(leisure)
  },

  updateLeisure: async ({ rootState, commit }, leisure) => {
    const userLeisuresDB = new UserLeisuresDB(rootState.user.user.id)
    return userLeisuresDB.update(leisure)
  },

  createUpdateLeisure: async ({ rootState, commit }, leisure) => {
    const userLeisuresDB = new UserLeisuresDB(rootState.user.user.id)
    return userLeisuresDB.createOrUpdate(leisure)
  },

  deleteLeisure: async ({ rootState, commit }, leisureId) => {
    const userLeisuresDB = new UserLeisuresDB(rootState.user.user.id)
    return userLeisuresDB.delete(leisureId)
  },
}
