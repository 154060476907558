var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"company-event"},[_c('full-page',{staticClass:"view-company-event",attrs:{"max-width":"is-max-widescreen","width-class":"is-10"}},[_c('b-loading',{attrs:{"is-full-page":true},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"spinner","size":"is-large","custom-class":"fa-pulse","type":"is-primary"}})],1),(!_vm.isLoading)?[(_vm.canUserView)?[_c('CompanyEventInfo'),_c('CompanyEventLocation'),_c('CompanyEventAdditionalInfo'),(
            _vm.companyEventToView?.eventStatus ===
            _vm.companyEventStatusConstants.SCHEDULED
          )?[(_vm.user)?_c('CompanyNetworkAttendees'):_vm._e(),(
              _vm.isUserCompanyAdmin ||
              _vm.companyEventToView?.createdBy?.id === _vm.getAuthUserId
            )?_c('CompanyAnalytics'):_vm._e()]:_vm._e(),_c('CompanyEventAttachments')]:_vm._e(),(_vm.companyErrors.unknown)?_c('ViewError',{attrs:{"title":_vm.errorTitle,"description":_vm.errorDescription}}):(_vm.companyErrors.companyEventNotFound)?_c('ViewError',{attrs:{"title":_vm.errorTitle,"description":_vm.errorDescription}}):(_vm.companyEventToView && !_vm.canUserView)?_c('ViewError'):_vm._e()]:_vm._e()],2),(_vm.companyEventToView && _vm.companyEventToView.eventName)?_c('div',{staticClass:"watermark"},[_c('span',[_c('b-icon',{attrs:{"pack":"fas","icon":"calendar-day","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.companyEventToView.eventName))],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }