var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-books"},[_c('div',{staticClass:"view-books__heading view-books__heading--warning"},[_c('span',{staticClass:"view-books__heading--emoji"},[_vm._v("📖")]),_c('span',{staticClass:"view-books__heading--title"},[_vm._v(_vm._s(_vm.showLabelsFromCode('profile/leisures/currentlyreading')))]),_c('b-button',{staticClass:"view-books__heading--button",attrs:{"tag":"router-link","to":{
        name: 'listbooks',
        params: {
          uid: _vm.profileUser.id,
          mode: 'view',
          fromScreen: 'viewprofile',
          books: _vm.books,
        },
      },"size":"is-small","icon-left":"star","pack":"fas","type":"is-ghost"}},[_vm._v(_vm._s(_vm.showLabelsFromCode('generic/ratings')))])],1),(_vm.isLoading)?_c('skeleton'):_c('div',{staticClass:"columns is-mobile is-multiline is-vcentered is-narrow is-gapless mt-2"},_vm._l((_vm.books),function(book,index){return _c('div',{key:book.id,staticClass:"column is-3"},[_c('a',{staticClass:"view-books__link",attrs:{"href":book.link
            ? `${book.link}/&ref_=as_li_ss_tl&tag=${_vm.amazonTagKey}`
            : '#',"target":book.link ? '_blank' : '_self'},on:{"click":function($event){return _vm.trackConversionEvent(
            'book_clicked',
            `${book.title} by ${book.author}`,
            book.link,
            `User ${_vm.user.id} clicked book ${index + 1} of ${_vm.books.length} `,
          )}}},[_c('img',{staticClass:"view-books__coverimage border-color--warning",attrs:{"src":book.coverImagePath,"alt":`${book.title} by ${book.author}`,"title":`${book.title} by ${book.author}`,"loading":"lazy"},on:{"error":_vm.setAltImg}})])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }