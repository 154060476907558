/* eslint-disable no-unused-vars */
import { mapState, mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import axios from 'axios'
import TiptapEditor from '@/components/TiptapEditor'
import Skeleton from '@/components/partials/Skeleton'
import Synform from '@/components/SynForm'
import FullPage from '@/components/partials/FullPage'
import {
  showLabelsFromCode,
  showTooltipsFromCode,
  showUserMessageFromCode,
  getNameForId,
  collapseHelper,
  formatDateTime,
  formatTime,
  closeDialog,
} from '@/misc/helpers'
import {
  handleSuccessMessage,
  handleErrorMessage,
  sendEmail,
} from '@/misc/user-messages'
import {
  trackExperienceEvent,
  trackExceptionEvent,
  trackEngagementEvent,
} from '@/misc/analytics'
import { teamStatusList, teamStatusConstants } from '@/data/teams/team-status'
import {
  teamMemberStatus,
  teamMemberStatusConstants,
} from '@/data/teams/team-member-status'
import memberRoles from '@/data/teams/team-member-role'
import {
  notificationActionTypes,
  notificationActionTypesConstants,
} from '@/data/notificationActionTypes'

import conferenceProviders from '@/data/events/conferenceProviders'
// import { addTeamUpdate } from '@/store/notifications-collection/notifications/store-helper'

const editTeamsMixin = {
  filters: {},
  components: {
    Synform,
    Skeleton,
    ValidationProvider,
    TiptapEditor,
    FullPage,
  },
  data() {
    return {
      conferenceProviders,
      teamStatusList,
      teamStatusConstants,
      teamMemberStatus,
      teamMemberStatusConstants,
      notificationActionTypes,
      notificationActionTypesConstants,
      memberRoles,
      defaultTeamImgSrc: process.env.VUE_APP_DEFAULT_TEAM_IMAGE,
      showError: false,
      errorMessage: `😕 ${this.showUserMessageFromCode(
        'form/generic/errorMessage',
      )}`,
    }
  },
  computed: {
    ...mapState('user', ['user', 'userCompany']),
    ...mapState('app', [
      'networkOnLine',
      'appTitle',
      'appShortTitle',
      'byline',
    ]),
    ...mapGetters('user', [
      'isUserLoggedIn',
      'getAuthUserId',
      'getAuthUserCompanyId',
      'getAuthUserLicenseType',
      'getAuthUserRole',
      'getTinyUser',
      'getAuthUserEmailAddress',
      'getCreatedBy',
      'getUpdatedBy',
      'isPaidUser',
      'isEnterpriseUser',
    ]),
    ...mapState('teams', ['teamErrors', 'teamToView']),
  },

  methods: {
    showLabelsFromCode,
    showTooltipsFromCode,
    showUserMessageFromCode,
    getNameForId,
    trackExperienceEvent,
    trackExceptionEvent,
    trackEngagementEvent,
    handleErrorMessage,
    handleSuccessMessage,
    sendEmail,
    isEmpty,
    first,
    collapseHelper,
    formatDateTime,
    formatTime,
    closeDialog,
  },
}

export default editTeamsMixin
