var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"list-company-admins"},[_c('div',{attrs:{"panel-title":_vm.panelTitle}},[_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"spinner","size":"is-large","custom-class":"fa-pulse","type":"is-primary"}})],1),(!_vm.isLoading && _vm.companyAdmins)?_c('div',[_c('b-table',{staticClass:"list-company-admins__table",attrs:{"data":_vm.companyAdmins,"hoverable":"","mobile-cards":"","show-header":_vm.companyAdmins.length > 0 ? true : false,"default-sort":"createTimestamp","default-sort-direction":"desc"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.showUserMessageFromCode('generic/none')))])]},proxy:true}],null,false,564667765)},[_c('b-table-column',{attrs:{"scope":"props","field":"displayName","label":_vm.showLabelsFromCode('generic/name'),"cell-class":"valign","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
              name: 'profile',
              params: {
                uid: props.row.id,
              },
            },"replace":""}},[_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(props.row.displayName)+" ")])])]}}],null,false,404540252)}),_c('b-table-column',{attrs:{"scope":"props","field":"createTimestamp","label":"Added On","cell-class":"valign","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"team-updates__table--when",attrs:{"title":new Date(props.row.createTimestamp).toLocaleString()}},[_vm._v(" "+_vm._s(_vm.humanReadableDateTime(props.row.createTimestamp))+" ")])]}}],null,false,2927117617)}),_c('b-table-column',{attrs:{"scope":"props","field":"createdBy.displayName","label":"Added By","cell-class":"valign","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
              name: 'profile',
              params: {
                uid: props.row?.createdBy?.id,
              },
            },"replace":""}},[_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(props.row?.createdBy?.displayName)+" ")])])]}}],null,false,426257020)}),_c('b-table-column',{attrs:{"label":_vm.showLabelsFromCode('generic/delete'),"cell-class":"valign","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.id !== _vm.getAuthUserId)?_c('span',[_c('a',{staticClass:"no-text-decoration",on:{"click":function($event){return _vm.deleteCompanyAdmin(props.row)}}},[_c('b-tooltip',{attrs:{"label":_vm.showTooltipsFromCode('profile/events/deleteevent'),"position":"is-left","type":"is-dark"}},[_c('b-icon',{attrs:{"icon":"trash-alt","pack":"fa","type":"is-danger","size":"is-small"}})],1)],1)]):_vm._e()]}}],null,false,1945065084)})],1)],1):_vm._e(),_c('div',{staticClass:"button-group has-text-centered mt-4"},[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('div',{staticClass:"control"},[_c('b-button',{staticClass:"button",attrs:{"pack":"far","type":"is-info"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Close ")])],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }