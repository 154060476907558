<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="event-info">
    <div class="view-event-info">
      <skeleton-media v-if="isLoading"></skeleton-media>

      <SynPanel v-if="teamEventToView && !isLoading">
        <div class="team-event-tag">
          <span class="team-event-tag__emoji">🎟️</span>
          <span>{{ showLabelsFromCode('teams/teamevents/tagTitle') }}</span>
        </div>
        <div class="view-event-info__wrapper px-4 pb-2">
          <b-icon
            v-if="teamEventToView.eventOccurence === 'recurring'"
            icon-pack="fas"
            icon="repeat"
            class="mr-2"
            size="is-large"
          ></b-icon>
          <h1 class="event-info__name is-inline-block">
            {{ teamEventToView.eventName }}
          </h1>
          <p
            v-if="teamEventToView.eventDescription"
            class="event-info__description"
          >
            <span v-html="teamEventToView.eventDescription"></span>
          </p>
          <hr v-if="teamEventToView.eventDescription" class="my-0" />
          <div class="columns is-multiline is-vcentered is-gapless mb-0 my-1">
            <div class="column is-5">
              <p class="event-info__teamName">
                <span class="event-info__teamName--label"
                  >{{
                    userCompany?.teamString
                      ? `${userCompany?.teamString}`
                      : `${showLabelsFromCode('generic/team')}`
                  }}
                  :
                </span>
                <router-link
                  v-if="user && isUserLoggedIn"
                  class="no-text-decoration"
                  :to="{
                    name: 'viewteam',
                    params: { id: teamEventToView?.team?.id },
                  }"
                >
                  <span class="event-info__teamName--value">{{
                    teamEventToView?.team?.name
                  }}</span></router-link
                >
                <span v-else class="event-info__teamName--value">{{
                  teamEventToView?.team?.name
                }}</span>
              </p>
            </div>
            <div class="column is-4">
              <span class="event-info__eventType">
                <span class="event-info__eventType--label">Type: </span>
                <span class="event-info__eventType--value">
                  {{ teamEventToView.eventType }}</span
                >
              </span>
            </div>
            <div class="column is-3">
              <span class="event-info__eventVisibility">
                <span class="sm-label">Visibility: </span>
                <span class="sm-value is-capitalized">
                  {{
                    teamEventToView?.eventVisibility === 'private'
                      ? '🛡️'
                      : '🌐'
                  }}{{ teamEventToView.eventVisibility }}</span
                >
              </span>
            </div>
          </div>
          <hr class="my-0" />
          <div class="columns is-multiline is-vcentered is-gapless mb-0 my-1">
            <div class="column is-auto is-4-touch is-narrow">
              <span class="event-info__owner-label">Organizer : </span>
            </div>
            <div class="column is-3 is-8-touch ml-2 mb-2-until-tablet">
              <small-card
                :uid="teamEventToView?.createdBy?.id"
                :display-name="teamEventToView.createdBy?.displayName"
                :title="teamEventToView.createdBy?.title"
              />
            </div>
            <div class="column is-2 is-4-touch is-narrow has-text-right">
              <span class="event-info__owner-label">Schedule : </span>
            </div>
            <div class="column is-5 is-12-touch ml-2">
              <template v-if="teamEventToView.eventOccurence === 'onetime'">
                <b-icon icon-pack="fas" icon="calendar-alt"></b-icon>
                <span class="event-info__dateTime">{{
                  renderFromToDates(
                    teamEventToView?.startDateTime,
                    teamEventToView?.endDateTime,
                  )
                }}</span>
                <AddToCalendar
                  :event-name="teamEventToView.eventName"
                  :meeting-u-r-l="teamEventToView.meetingURL"
                  :event-description="teamEventToView.eventDescription"
                  :start-date-time="teamEventToView.startDateTime"
                  :end-date-time="teamEventToView.endDateTime"
                >
                </AddToCalendar>
              </template>
              <template v-if="teamEventToView.eventOccurence === 'recurring'">
                🔁 Starting {{ formatDate(teamEventToView.startDateTime) }}
                <span class="sm-value mr-0">{{
                  teamEventToView.eventFrequencyText || ''
                }}</span>

                <span class="has-text-weight-semibold"> Time: </span>
                <span class="sm-value"
                  >{{ formatTime(teamEventToView.meetingStartTime) }} to
                  {{ formatTime(teamEventToView.meetingEndTime) }}</span
                >
              </template>
            </div>
          </div>

          <template v-if="user?.isCalendarIntegrated">
            <template
              v-if="isUserEventCreator && teamEventToView?.nylasEventId"
            >
              <hr class="my-1" />
              <span
                >✅ Calendar invite was successfully sent to all invitees</span
              >
            </template>
            <template v-else-if="!teamEventToView?.nylasEventId">
              <hr class="my-1" />
              <span
                v-if="teamEventToView.eventOccurence === 'onetime'"
                class="has-text-danger"
                >Calendar invite was not sent for this event, we recommend using
                'Add To' menu next to event times to manually add this event to
                your calendar application.</span
              >
              <span
                v-if="teamEventToView.eventOccurence === 'recurring'"
                class="has-text-danger"
                >Calendar invite was not sent for this event, we recommend
                sending it using your calendar application.</span
              >
            </template>
          </template>
          <hr class="my-1" />
          <div class="columns is-multiline is-vcentered is-gapless mb-0 mt-5">
            <div class="column is-5 is-12-touch">
              <div
                v-if="teamEventToView && teamEventToView.createTimestamp"
                class="event-info__timestamps mb-2-until-tablet"
              >
                <span class="event-info__timestamps--label">Created </span>
                <span>{{ momentAgo(teamEventToView.createTimestamp) }}</span>
              </div>
            </div>
            <div
              class="column is-7 is-12-touch has-text-centered has-text-right-desktop"
            >
              <b-tag
                v-if="teamEventToView.eventStatus"
                title="Event Status"
                class="event-info__status br-6 mb-2-until-tablet mr-2"
                type="is-info"
                size="is-medium"
                >Status :
                {{ getNameForId(teamEventToView.eventStatus, teamEventStatus) }}
              </b-tag>

              <b-button
                v-if="isUserEventCreator"
                type="is-danger"
                icon-left="edit"
                icon-pack="far"
                class="event-info__edit-button"
                title="Edit Team Event"
                @click="goToEditTeamEvent()"
              >
                {{ showLabelsFromCode('generic/edit') }} Event Info</b-button
              >
              <span
                v-else-if="isPastEvent"
                class="tag br-6 is-medium"
                style="vertical-align: text-bottom"
                >Past Event</span
              >
              <TeamEventButton
                v-else-if="!isEventUnEditable"
                :is-user-in-same-company="isUserInSameCompany"
              ></TeamEventButton>
              <b-button
                class="event-info__edit-button ml-2"
                title="Share event"
                type="is-info"
                icon-left="share-from-square"
                pack="fas"
                @click="openShareModal()"
              >
              </b-button>
            </div>
          </div>
        </div>
      </SynPanel>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import { mapMutations } from 'vuex'
import SynPanel from '@/components/SynPanel.vue'
import SkeletonMedia from '@/components/partials/SkeletonMedia'
import { viewTeamsMixin } from '@/mixins/teams/index'

import TeamEventButton from '../partials/TeamEventButton'
import {
  formatDateTime,
  renderFromToDates,
  formatDate,
  formatDateWithYear,
} from '@/misc/helpers'
import SmallCard from '@/components/SmallCard'
import AddToCalendar from '@/components/AddToCalendar'
import {
  teamEventStatusConstants,
  teamEventStatus,
} from '@/data/teams/team-event-status'

import ShareLink from '@/components/ShareLink'

export default {
  name: 'TeamEventInfo',
  components: {
    SynPanel,
    SkeletonMedia,
    TeamEventButton,
    SmallCard,
    AddToCalendar,
  },
  mixins: [viewTeamsMixin],
  props: {
    isUserInSameCompany: null,
  },
  data() {
    return {
      isLoading: true,
      teamEventStatusConstants,
      teamEventStatus,
      defaultBGColor: process.env.VUE_APP_BODY_BGCOLOR,
    }
  },
  computed: {
    isUserEventCreator() {
      return this.teamEventToView?.createdBy?.id === this.getAuthUserId
    },
    isPastEvent() {
      if (
        !this.teamEventToView.endDateTime.seconds &&
        new Date() > this.teamEventToView.endDateTime
      ) {
        return true
      }
      if (
        this.teamEventToView.endDateTime.seconds &&
        Math.round(new Date().getTime() / 1000) >
          this.teamEventToView.endDateTime.seconds
      ) {
        return true
      }
      return false
    },
    isEventUnEditable() {
      return (
        this.isPastEvent ||
        this.teamEventToView.eventStatus === teamEventStatusConstants.CANCELLED
      )
    },
  },

  mounted() {
    this.isLoading = false
  },

  methods: {
    formatDateTime,
    formatDate,
    formatDateWithYear,
    renderFromToDates,
    ...mapMutations('teamsEvents', ['setTeamEventToEdit']),
    openShareModal() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        props: {
          url: window.location.href,
          pageName: this.teamEventToView?.eventName,
        },
        component: ShareLink,
        trapFocus: true,
      })
    },

    goToEditTeamEvent() {
      this.setTeamEventToEdit(this.teamEventToView)
      let routeName = 'editonetimeteamevent'

      if (this.teamEventToView.eventOccurence === 'recurring') {
        routeName = 'editrecurringteamevent'
      }

      this.$router.push({
        name: routeName,
        params: {
          teamid: this.teamEventToView.team?.id,
          id: this.teamEventToView?.id,
        },
      })
    },
  },
}
</script>
