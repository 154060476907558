<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="company-event-info">
    <div width-class="is-12" class="view-company-event-info">
      <skeleton v-if="isLoading"></skeleton>

      <SynPanel v-if="companyEventToView && !isLoading">
        <img
          v-if="companyEventToView?.eventImage"
          class="view-company-event-info__eventImage"
          :src="companyEventToView?.eventImage"
          alt="Event Banner Image"
          style="height: auto; width: 100%; max-height: 450px"
        />
        <div class="columns is-multiline is-gapless is-vcentered mb-0">
          <div class="column is-9">
            <h1 class="view-company-event-info__name">
              {{ companyEventToView.eventName }}
            </h1>
          </div>

          <div
            v-if="
              companyEventToView.eventStatus ===
              companyEventStatusConstants.CANCELLED
            "
            class="column is-3 has-text-right"
          >
            <b-field>
              <b-tag size="is-medium" class="br-6">Cancelled</b-tag>
            </b-field>
          </div>
          <template v-if="user && isUserLoggedIn">
            <div v-if="isPastEvent" class="column is-3 has-text-right">
              <b-field>
                <b-tag size="is-medium" class="br-6">Past Event</b-tag>
              </b-field>
            </div>
            <div
              v-else-if="!isPastEvent"
              class="column is-3 has-text-centered has-text-right-desktop"
            >
              <b-button
                v-if="
                  isUserCompanyAdmin &&
                  companyEventToView.eventStatus ===
                    companyEventStatusConstants.DRAFT
                "
                type="is-info"
                class="mb-1"
                @click="gotoUnpublishedEventsScreen()"
                >🚀 Publish Event</b-button
              >
              <b-button
                v-if="
                  isUserEventCreator &&
                  companyEventToView.eventStatus !==
                    companyEventStatusConstants.CANCELLED
                "
                class="view-company-event-info__editbutton"
                type="is-danger"
                icon-left="edit"
                pack="far"
                @click="goToEditCompanyEvent()"
              >
                Edit Event
              </b-button>
              <b-button
                v-else-if="isUserAttending"
                class="view-company-event-info__editbutton"
                type="is-warning"
                icon-left="user-minus"
                pack="fas"
                @click="unAttendTheEventVue()"
              >
                Un Attend
              </b-button>
              <b-button
                v-else-if="
                  !isUserAttending &&
                  companyEventToView.eventStatus ===
                    companyEventStatusConstants.SCHEDULED
                "
                class="view-company-event-info__editbutton"
                type="is-info"
                icon-left="user-plus"
                pack="fas"
                @click="attendTheEventVue()"
              >
                Attend
              </b-button>
              <b-button
                v-if="
                  companyEventToView.eventStatus ===
                  companyEventStatusConstants.SCHEDULED
                "
                class="ml-2 view-company-event-info__editbutton"
                title="Share event"
                type="is-info"
                icon-left="share-from-square"
                pack="fas"
                @click="openShareModal()"
              >
              </b-button>
            </div>
          </template>
          <template v-else>
            <div class="column is-3 has-text-centered has-text-right-desktop">
              <b-button
                class="view-company-event-info__editbutton"
                type="is-info"
                icon-left="user-plus"
                pack="fas"
                @click="registerForEvent()"
              >
                Register
              </b-button>
              <b-button
                class="ml-2 view-company-event-info__editbutton"
                title="Share event"
                type="is-info"
                icon-left="share-from-square"
                pack="fas"
                @click="openShareModal()"
              >
              </b-button>
            </div>
          </template>

          <div v-if="companyEventToView.eventDescription" class="column is-12">
            <p
              class="has-text-centered has-text-left-desktop view-company-event-info__description"
            >
              <span v-html="companyEventToView?.eventDescription"></span>
            </p>
          </div>
        </div>

        <hr class="my-4" />
        <div class="columns is-vcentered is-gapless mb-0 my-2 is-multiline">
          <div v-if="companyEventToView.speaker" class="column is-2">
            <span class="has-text-weight-semibold">Event By / Speaker: </span>
          </div>
          <div class="column is-auto is-narrow">
            <div
              v-if="
                companyEventToView?.speaker && companyEventToView.speaker.email
              "
              class="small-card"
            >
              <div class="small-card__media">
                <view-user-avatar
                  class="small-card__media--photoURL"
                  :imgsrc="companyEventToView?.speaker?.imageUrl"
                  :alt-name="companyEventToView?.speaker.displayName"
                />
              </div>
              <div class="small-card__media-content">
                <a :href="`mailto:${companyEventToView.speaker.email}`">
                  <span class="small-card__media-content--displayName">{{
                    companyEventToView?.speaker?.displayName
                  }}</span></a
                >
                <span class="small-card__media-content--title truncate">{{
                  companyEventToView?.speaker?.title || ''
                }}</span>
                <span class="small-card__media-content--company"
                  ><span class="has-text-grey">@</span>
                  {{ companyEventToView?.speaker?.companyName || '' }}</span
                >
              </div>
            </div>
            <small-card
              v-else-if="companyEventToView?.speaker?.id"
              class="view-company-event-info__organizedby"
              :uid="companyEventToView.speaker.id"
              :display-name="companyEventToView.speaker.displayName"
            />
          </div>
          <div class="column is-2">
            <span
              v-if="
                (isUserCompanyAdmin || isUserEventCreator) && !isEventUnEditable
              "
            >
              <a
                class="has-text-danger has-text-centered"
                @click.stop.prevent="editSpeaker()"
              >
                <span v-if="companyEventToView.speaker"
                  >{{ showLabelsFromCode('generic/edit') }} Speaker |
                </span>
                <span v-else
                  >{{ showLabelsFromCode('generic/add') }} Speaker</span
                >
              </a>
              <a
                v-if="companyEventToView.speaker"
                class="has-text-danger has-text-centered"
                @click.stop.prevent="deleteEventSpeaker()"
              >
                {{ showLabelsFromCode('generic/delete') }} Speaker</a
              >
            </span>
          </div>
          <div class="column is-auto has-text-right-desktop">
            <span class="mr-2">
              <b-icon pack="fas" icon="calendar-day" size="is-small"> </b-icon>
              {{
                renderFromToDates(
                  companyEventToView.startDateTime,
                  companyEventToView.endDateTime,
                )
              }}</span
            ><span class="has-text-grey"
              >({{ momentAgo(companyEventToView.startDateTime) }})</span
            >
            <AddToCalendar
              :event-name="companyEventToView.eventName"
              :meeting-u-r-l="companyEventToView.eventLocation"
              :event-description="companyEventToView.eventDescription"
              :start-date-time="companyEventToView.startDateTime.seconds * 1000"
              :end-date-time="companyEventToView.endDateTime.seconds * 1000"
              addtext=""
            >
            </AddToCalendar>
          </div>
        </div>
        <hr class="my-4" />
        <div class="columns is-vcentered is-gapless mb-0 my-2 is-multiline">
          <div class="column is-6">
            <div
              v-if="
                !user &&
                !isUserLoggedIn &&
                companyEventToView.eventVisibility ===
                  companyEventVisibilityConstants.PUBLIC
              "
            >
              <span class="view-company-event-info__label">Organized By:</span>
              <router-link
                class="no-text-decoration"
                :to="{
                  name: 'viewcompany',
                  params: { companyid: companyEventToView.companyId },
                }"
              >
                <img
                  :src="companyToView.logoImageURL"
                  :alt="companyToView.companyName"
                  height="auto"
                  width="20px"
                  class="view-company-event-info__event-by--logoImageURL"
                />
                <span class="view-company-event-info__event-by--companyName">{{
                  companyToView.companyName
                }}</span>
              </router-link>
            </div>
            <template
              v-else-if="
                companyEventToView.eventVisibility ===
                companyEventVisibilityConstants.INTERNAL
              "
            >
              <span class="view-company-event-info__timestamps"
                >🔒 For {{ companyToView.companyName }} employees only</span
              >
            </template>
            <template
              v-else-if="
                companyEventToView.eventVisibility ===
                  companyEventVisibilityConstants.PUBLIC &&
                user &&
                isUserLoggedIn
              "
            >
              <span>🌐 External Event</span>
            </template>
          </div>
          <div class="column is-6 has-text-right-desktop">
            <div
              v-if="companyEventToView && companyEventToView.createTimestamp"
              class="view-company-event-info__timestamps"
            >
              <span class="view-company-event-info__timestamps--label"
                >Created
              </span>
              <span
                >{{ momentAgo(companyEventToView.createTimestamp) }} by

                <router-link
                  class="no-text-decoration"
                  :to="{
                    name: 'profile',
                    params: {
                      uid: companyEventToView?.createdBy?.id,
                    },
                  }"
                >
                  <span>{{
                    companyEventToView?.createdBy?.displayName
                  }}</span></router-link
                ></span
              >
            </div>
          </div>
          <div class="column is-12 has-text-right-desktop mt-2">
            <div
              v-if="
                companyEventToView.eventStatus ===
                companyEventStatusConstants.DRAFT
              "
              class="has-text-right mb-2-until-tablet"
            >
              <b-tag size="is-medium" type="is-dark" class="br-6 mt-1 mr-2"
                >Event is UNPUBLISHED</b-tag
              >
            </div>
          </div>
        </div>
      </SynPanel>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import { mapMutations, mapActions } from 'vuex'
import SynPanel from '@/components/SynPanel.vue'
import { viewCompanyMixin } from '@/mixins/company/index'
import AddToCalendar from '@/components/AddToCalendar'
import { companyEventVisibilityConstants } from '@/data/company/company-event-visibility'
// import EditCompanyEvent from '@/views/company/events/EditCompanyEvent'
import SmallCard from '@/components/SmallCard'
import EditCompanyEventSpeakerModalForm from '@/views/company/events/partials/EditCompanyEventSpeakerModalForm.vue'
import RegisterCompanyEvent from '@/views/company/events/partials/RegisterCompanyEvent.vue'

import ViewUserAvatar from '@/components/ViewUserAvatar'
import { companyEventStatusConstants } from '@/data/company/company-event-status'
import ShareLink from '@/components/ShareLink'

export default {
  name: 'CompanyEventInfo',
  components: {
    SynPanel,
    SmallCard,
    AddToCalendar,
    ViewUserAvatar,
  },
  mixins: [viewCompanyMixin],
  props: {},
  data() {
    return {
      isLoading: true,
      isUserAttending: false,
      companyEventVisibilityConstants,
      totalAttendees: 0,
      companyEventStatusConstants,
    }
  },
  computed: {
    currentURL() {
      return window.location.href
    },
    isUserEventCreator() {
      return this.companyEventToView?.createdBy?.id === this.getAuthUserId
    },
    isPastEvent() {
      if (
        Math.round(new Date().getTime() / 1000) >
        this.companyEventToView?.endDateTime.seconds
      ) {
        return true
      }
      return false
    },
    isEventUnEditable() {
      return (
        this.isPastEvent ||
        this.companyEventToView?.eventStatus ===
          companyEventStatusConstants.CANCELLED
      )
    },
  },
  created() {},
  mounted() {
    //  console.log(JSON.stringify(this.companyEventToView.attendees))
    this.isUserAttending = this.companyEventToView?.attendees.find(
      (user) => user.id === this.user?.id,
    )
    this.totalAttendees = this.companyEventToView?.attendees.length
    this.isLoading = false
  },
  methods: {
    ...mapMutations('company', [
      'setCompanyEventToEdit',
      'setCompanyToEdit',
      'setCompanyEventToView',
      'deleteSpeakerFromCompanyEvent',
    ]),
    ...mapActions('company', [
      'attendEventAction',
      'unAttendEventAction',
      'updateCompanyEventAction',
    ]),
    openShareModal() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        props: {
          url: window.location.href,
          pageName: `${this.companyEventToView?.eventName}`,
        },
        component: ShareLink,
        trapFocus: true,
      })
    },
    goToEditCompanyEvent() {
      this.setCompanyEventToEdit(this.companyEventToView)
      this.$router.push({
        name: 'editcompanyevent',
        params: {
          companyid: this.companyEventToView.companyId,
          id: this.companyEventToView.id,
        },
      })
    },
    gotoUnpublishedEventsScreen() {
      this.setCompanyToEdit(this.companyToView)
      this.$router.push({
        name: 'listunpublishedevents',
        params: {
          recordid: this.companyToView.id,
          mode: 'LIST',
        },
      })
    },
    unAttendTheEventVue() {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to un attend the event?')) {
        this.isLoading = true
        this.unAttendEventAction({
          companyEvent: this.companyEventToView,
          user: this.getTinyUser,
        })
          .then(() => {
            // console.log(JSON.stringify(eventResponse))
            this.isUserAttending = false
            this.totalAttendees -= 1
            this.handleSuccessMessage('Event updated successfully in database')
          })
          .then(() => {
            if (this.user?.isCalendarIntegrated) {
              this.$buefy.snackbar.open({
                indefinite: true,
                message: `Event updated in database but not removed from your calendar application. Please delete it manually.`,
                position: 'is-bottom-right',
                type: 'is-warning',
              })
            }
          })
          .catch((err) => {
            this.trackExceptionEvent(
              'company-event-unattend_failed',
              `By Uid ${this.user.id} Code: ${err.code}`,
            )
            this.handleErrorMessage()
            console.log(err)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    attendTheEventVue() {
      this.attendEventAction({
        companyEvent: this.companyEventToView,
        user: this.getTinyUser,
      })
        .then(() => {
          this.isUserAttending = true
          this.totalAttendees += 1
        })
        .then(() => {
          this.handleSuccessMessage('Thank you for attending!')
        })
        .then(async () => {
          if (this.user?.isCalendarIntegrated) {
            try {
              const url = `${process.env.VUE_APP_NYLAS_BASE_URL}/nylas/create-event`
              const res = await fetch(url, {
                method: 'POST',
                headers: {
                  Authorization: this?.user?.id,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  startTime: this.companyEventToView.startDateTime,
                  endTime: this.companyEventToView.endDateTime,
                  title: this.companyEventToView.eventName,
                  description: `Event created using ${this.appTitle}`,
                  participants: this.user.email,
                  location: this.currentURL,
                }),
              })
              if (!res.ok) {
                throw new Error(res.statusText)
              }
              this.handleSuccessMessage(
                'Event successfully saved in your calendar',
              )
            } catch (err) {
              this.handleErrorMessage(
                'Error in creating event in your calendar',
              )
            }
          }
        })
        .catch((err) => {
          this.trackExceptionEvent(
            'company-event-attend_failed',
            `By Uid ${this.user.id} Code: ${err.code}`,
          )
          this.handleErrorMessage()
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    editSpeaker() {
      this.setCompanyEventToEdit(this.companyEventToView)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditCompanyEventSpeakerModalForm,
        events: {
          update_successful: () => {
            this.handleSuccessMessage('Speaker updated successfully!')
          },
          trapFocus: true,
        },
      })
    },

    deleteEventSpeaker() {
      this.isLoading = true
      const speaker = null
      const tempEvent = {
        ...this.companyEventToView,
        speaker,
      }
      this.updateCompanyEventAction({
        companyEvent: tempEvent,
      })
        .then(() => {
          this.trackEngagementEvent(
            'eventSpeaker_deleted',
            `Speaker was deleted from ${this.companyEventToEdit.id}`,
            'page',
            'button',
          )
          this.deleteSpeakerFromCompanyEvent()
          this.handleSuccessMessage(`Speaker was sucessfully deleted.`)
        })
        .catch((err) => {
          this.trackExceptionEvent(
            'eventSpeaker_delete_falied',
            err.message,
            false,
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    registerForEvent() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          companyName: this.companyToView.companyName,
          companyEvent: this.companyEventToView,
        },
        hasModalCard: true,
        component: RegisterCompanyEvent,
        events: {
          trapFocus: true,
        },
      })
    },
  },
}
</script>
