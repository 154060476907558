/* eslint-disable no-unused-vars */
import TeamUpdatesDB from '@/firebase/teams/team-updates'

export default {
  addTeamUpdate: async ({ commit }, teamUpdate) => {
    const teamUpdatesDB = new TeamUpdatesDB(teamUpdate.team.id)
    return teamUpdatesDB.create(teamUpdate)
  },

  fetchTeamUpdatesByTeamId: async ({ commit }, payload) => {
    const teamUpdatesDB = new TeamUpdatesDB(payload.id)

    const teamUpdates = await teamUpdatesDB.readAllWithOrderByLimit(
      payload.constraints,
      payload.orderBys,
      payload.limit,
    )

    // console.log(JSON.stringify(payload))
    // console.log(JSON.stringify(constraints))
    // console.log(JSON.stringify(orderBys))
    // console.log(JSON.stringify(limit))
    // console.log(JSON.stringify(teamUpdates))
    if (teamUpdates) {
      // console.log(JSON.stringify('hello'))
      commit('setTeamUpdates', teamUpdates)
      return Object.keys(teamUpdates).map((o) => teamUpdates[o])
    }
    return null
  },

  fetchAllTeamUpdatesByTeamId: async ({ commit }, payload) => {
    // console.log(payload.teamId)
    const teamUpdatesDB = new TeamUpdatesDB(payload.teamId)
    const orderBys = []
    orderBys.push(['createTimestamp', 'desc'])
    const allTeamUpdates = await teamUpdatesDB.readAllWithOrderByLimit(
      undefined,
      orderBys,
      undefined,
    )
    if (allTeamUpdates) {
      commit('setAllTeamUpdates', allTeamUpdates)
      return Object.keys(allTeamUpdates).map((o) => allTeamUpdates[o])
    }
    return null
  },
}
