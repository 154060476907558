<template>
  <div class="edit-meeting-notes">
    <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="false">
      <b-icon
        pack="fas"
        icon="spinner"
        size="is-large"
        custom-class="fa-pulse"
        type="is-primary"
      >
      </b-icon
      ><b-message
        type="is-info"
        size="is-medium"
        :closable="false"
        title="Fetching Data.."
        >Fetching AI Summary. Please do not refresh the page. It usually takes
        upto 90 seconds.</b-message
      ></b-loading
    >
    <Synform
      :title="formTitle"
      :operation="editMeetingNotesVue"
      :button-text="formTitle"
      :show-second-button="false"
      :modal-form="true"
    >
      <input v-model.trim="form.id" type="hidden" name="id" />
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="notes"
        rules="required|min:10|max:10000"
      >
        <b-field
          class="required"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <tiptap-editor
            id="editor2"
            required
            :value="form.notes"
            character-limit="10000"
            name="notes"
            aria-placeholder="Meeting Notes"
            @input="tipTapValue($event)"
          ></tiptap-editor>
        </b-field>
      </ValidationProvider>
      <b-field class="my-4">
        <b-checkbox v-model="form.summarize"
          >Also create meeting notes summary using AI</b-checkbox
        >
      </b-field>
      <b-field
        v-if="form.summarize"
        label="🤖 Prompt for AI Bot "
        custom-class="is-medium"
      >
        <b-select
          v-model="form.promptId"
          placeholder="Select a prompt"
          @input="showPromptText($event)"
        >
          <option selected disabled hidden style="display: none" value="">
            {{ showLabelsFromCode('form/generic/pleaseSelect') }}
          </option>
          <option
            v-for="option in appMasterConfigs.openAIPrompts"
            :key="option.id"
            :value="option.id"
          >
            {{ option.promptName }}
          </option>
        </b-select>
      </b-field>
      <b-message v-if="promptText" type="is-info" class="my-2">
        {{ promptText }}
      </b-message>
    </Synform>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
/* import/no-extraneous-dependencies */
import { mapActions, mapState, mapMutations } from 'vuex'
import TiptapEditor from '@/components/TiptapEditor'
import { editTeamsMixin } from '@/mixins/teams/index'

import { trackPageView, trackScreenView } from '@/misc/analytics'

export default {
  name: 'EditMeetingNotes',
  components: {
    TiptapEditor,
  },
  mixins: [editTeamsMixin],
  props: {},
  data() {
    return {
      formTitle: `${this.showLabelsFromCode(
        'generic/update',
      )} ${this.showLabelsFromCode('form/team/meetingnotes/paneltitle')}`,

      isLoading: true,
      promptText: '',
      form: {
        id: null,
        notes: '',
        summarize: false,
        promptId: null,
      },
    }
  },
  metaInfo: {
    title: 'Edit Notes',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  computed: {
    ...mapState('teamsEvents', ['teamEventToView', 'teamMeetingNoteToEdit']),
    ...mapState('app', ['appMasterConfigs']),
  },

  mounted() {
    if (this.teamMeetingNoteToEdit) {
      this.form.id = this.teamMeetingNoteToEdit.id
      this.form.notes = this.teamMeetingNoteToEdit.notes
      this.form.summarize = this.teamMeetingNoteToEdit.summarize
      this.form.promptId = this.teamMeetingNoteToEdit.promptId
    }
    this.fetchOpenAIPrompts()
    this.isLoading = false
  },
  methods: {
    ...mapActions('teamsEvents', ['updateMeetingNotesAction']),
    ...mapMutations('teamsEvents', ['setMeetingNotesUpdate']),
    ...mapActions('app', ['fetchAppMasterConfigs']),
    ...mapMutations('app', ['setOpenAIPrompts']),
    tipTapValue($event) {
      this.form.notes = $event
    },
    fetchOpenAIPrompts() {
      this.fetchAppMasterConfigs({
        configName: 'openAI-Prompts',
      })
        .then((responsePrompts) => {
          this.setOpenAIPrompts(responsePrompts)
        })
        .catch((err) => {
          console.log(err)
          this.handleErrorMessage('Error in fetching AI prompts')
        })
    },
    editMeetingNotesVue() {
      this.isLoading = true
      const meetingNotes = {
        ...this.teamMeetingNoteToEdit,
        notes: this.form.notes || '',
        summarize: this.form.summarize || false,
        promptId: this.form.promptId,
      }
      const teamUpdate = {
        team: {
          id: this.teamEventToView.team.id,
          name: this.teamEventToView.team.name,
        },
        action: this.notificationActionTypesConstants.UPDATED,
        type: 'notes',
        emoji: '🗒️',
        message: `For event ${this.teamEventToView.eventName}`,
        showOnDashboard: true,
        priority: 2,
      }

      this.updateMeetingNotesAction({
        meetingNotes,
        teamUpdate,
        teamEventToView: this.teamEventToView,
        user: this.user,
      })
        .then((meetingNoteResponse) => {
          this.setMeetingNotesUpdate(meetingNoteResponse.meetingNotes)
          this.$emit('update_successful')
          this.close()
        })
        .catch((err) => {
          console.log(err)
          this.$emit('update_failed')
          this.close()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    close() {
      this.$emit('close')
    },
    showPromptText(promptId) {
      const promptRecord = this.appMasterConfigs.openAIPrompts.find(
        (prompt) => prompt.id === promptId,
      )
      this.promptText = promptRecord.promptText
    },
  },
}
</script>
