/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/extensions
import first from 'lodash/first'
import OfficeHoursDB from '@/firebase/officehours/office-hours-db'
import { showUserMessageFromCode } from '@/misc/helpers'

export default {
  /** ************** NEW CODE************ */
  fetchOfficehoursByUserId: async ({ commit }, userId) => {
    const officeHoursDB = new OfficeHoursDB(userId)
    return officeHoursDB.readAll()
  },

  createUpdateOfficeHours: async ({ rootState, commit }, payload) => {
    const officeHoursDB = new OfficeHoursDB(rootState.user.user.id)
    const response = await officeHoursDB
      .createOrUpdate(payload)
      .then((result) => {
        return result
      })
      .catch((err) => {
        throw err
      })
    return response
  },
  /*   getOfficeHoursById: async ({ commit }, userId) => {
    const officeHoursDB = new OfficeHoursDB(userId)
    const officehours = await officeHoursDB.readAll()
    commit('setOfficeHours', officehours[0])
  },
  updateOfficeHours: async ({ rootState, commit }, officehours) => {
    commit('setOfficeHoursEditPending', true)
    const officeHoursDB = new OfficeHoursDB(rootState.user.user.id)
    await officeHoursDB.update(officehours)
    await officeHoursDB.update(officehours)
    commit('setOfficeHours', officehours)
    commit('setOfficeHoursEditPending', false)
  },
  /**
   * Create for current loggedin user
   */
  /*   addOfficeHours: async ({ commit, rootState }, officehours) => {
    const officeHoursDB = new OfficeHoursDB(rootState.user.user.id)
    commit('setOfficeHoursEditPending', true)
    await officeHoursDB.create(officehours)
    commit('setOfficeHours', officehours)
    commit('setOfficeHoursEditPending', false)
  },  */
}
