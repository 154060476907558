const memberRoles = [
  { id: 'ps', name: 'Project Sponsor' },
  { id: 'po', name: 'Product Owner' },
  { id: 'sm', name: 'Scrum Master' },
  { id: 'dt', name: 'Software Developer' },
  { id: 'pm', name: 'Project Manager' },
  { id: 'pa', name: 'Project Analyst' },
  { id: 'ba', name: 'Business Analyst' },
  { id: 'tld', name: 'Team Lead' },
  { id: 'tl', name: 'Technical Lead' },
  { id: 'ux', name: 'UX/UI Designer' },
  { id: 'fe', name: 'Frontend Engineer' },
  { id: 'be', name: 'Backend Engineer' },
  { id: 'fse', name: 'Fullstack Engineer' },
  { id: 'qa', name: 'Quality Assurance' },
  { id: 'se', name: 'Security Engineer' },
  { id: 'sa', name: 'Sales lead' },
  { id: 'devops', name: 'DevOps' },
  { id: 'support', name: 'Technical Support' },
  { id: 'nwe', name: 'Network Engineer' },
  { id: 'pres', name: 'President' },
  { id: 'vp', name: 'Vice-President' },
  { id: 'chair', name: 'Chair' },
  { id: 'cochair', name: 'Co-Chair' },
  { id: 'board', name: 'Board Member' },
  { id: 'treasury', name: 'Treasury Secretary' },
  { id: 'finance', name: 'Finance Secretary' },
  { id: 'mkt', name: 'Marketing Manager' },
  { id: 'cw', name: 'Content Writer' },
  { id: 'tw', name: 'Technical Writer' },
  { id: 'member', name: 'Member' },
  { id: 'consultant', name: 'Consultant' },
  { id: 'subscriber', name: 'Subscriber' },
]
export default memberRoles
