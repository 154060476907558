<template>
  <div class="profile">
    <full-page max-width="is-max-widescreen" class="view-profile">
      <b-loading v-model="isLoading" :is-full-page="true">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon
      ></b-loading>
      <!-- ERROR HANDLING OBJECT HERE BASED ON APPCRITICALERROR OBJECT -->
      <ViewDebug v-if="showDebug"></ViewDebug>

      <template
        v-if="
          criticalAppError ||
          hardError.userDisabled ||
          hardError.profileNotFound
        "
      >
        <ViewError
          :title="errorTitle"
          :description="errorDescription"
        ></ViewError>
      </template>
      <template v-else>
        <ViewUserInfo></ViewUserInfo>
        <transition name="slide-fade">
          <ViewPrivateInfo
            v-if="showPrivateInfoPanel && (isPaidUser || isEnterpriseUser)"
          ></ViewPrivateInfo>
        </transition>
        <!-- <ViewEvents v-if="isPaidUser || isEnterpriseUser"></ViewEvents> CHANGED from tHIS ON 1/11-->
        <ViewEvents></ViewEvents>
        <template
          v-if="
            isEnterpriseUser &&
            isUserLoggedIn &&
            getProfileUserCompanyId === getAuthUserCompanyId
          "
        >
          <ViewUserTeams></ViewUserTeams>
        </template>
        <template
          v-if="
            isEnterpriseUser &&
            isUserLoggedIn &&
            getProfileUserCompanyId === getAuthUserCompanyId
          "
        >
          <ViewMentoring></ViewMentoring>
        </template>
        <ViewComment></ViewComment>
        <ViewLinks></ViewLinks>
        <ViewOfficeHours></ViewOfficeHours>
        <ViewEmailPreferences></ViewEmailPreferences>
        <ViewLeisures></ViewLeisures>
      </template>
    </full-page>
    <div v-if="profileUser && profileUser.displayName" class="watermark">
      <span>
        <b-icon pack="fas" icon="id-card" size="is-small"> </b-icon>
        {{ profileUser.displayName }}</span
      >
    </div>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapActions, mapMutations } from 'vuex'
import { viewProfileMixin } from '@/mixins/profile/index'
import ViewDebug from '@/views/errors/ViewDebug'
import ViewError from '@/views/errors/ViewError'
import ViewUserInfo from '@/views/user/ViewUserInfo'
import ViewOfficeHours from '@/views/office-hours/ViewOfficeHours'
import ViewEmailPreferences from '@/views/email-preferences/ViewEmailPreferences'
import ViewEvents from '@/views/events/ViewEvents'
import ViewUserTeams from '@/views/user-teams/ViewUserTeams'
import ViewLinks from '@/views/links/ViewLinks'
import ViewComment from '@/views/comment/ViewComment'
import ViewLeisures from '@/views/leisures/ViewLeisures'
import ViewPrivateInfo from '../private/ViewPrivateInfo'
import ViewMentoring from '../mentoring/ViewMentoring'
import { formatDateTime, destroyColorPersonalization } from '@/misc/helpers'

// import { networkStatusValues } from '@/data/networkStatus'

export default {
  name: 'ViewProfile',
  components: {
    ViewDebug,
    ViewError,
    ViewUserInfo,
    ViewOfficeHours,
    ViewEmailPreferences,
    ViewEvents,
    ViewUserTeams,
    ViewLinks,
    ViewComment,
    ViewLeisures,
    ViewPrivateInfo,
    ViewMentoring,
  },
  mixins: [viewProfileMixin],
  props: {
    uid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      errorTitle: this.showUserMessageFromCode('profile/notfound/title'),
      errorDescription: this.showUserMessageFromCode(
        'profile/notfound/description',
      ),
      // networkStatusValues,
      showDebug: false,
      isLoading: true,
      panelTitle: this.showLabelsFromCode('profile/view/panelTitle'),
      showProfileError: false,
      // setConnected: {
      //   status: networkStatusValues.REQUEST_APPROVED,
      // },
      // setNotConnected: {
      //   status: null,
      // },
    }
  },
  metaInfo() {
    return {
      title: 'View Profile',
      meta: [
        {
          property: 'og:url',
          content: `${
            this.$route.params.uid
              ? `${this.appURL}view/profile/${this.$route.params.uid}`
              : this.appURL
          }`,
          vmid: 'og:url',
        },
        {
          property: 'og:title',
          content: `${
            this.profileUser
              ? `${this.profileUser.displayName}'s Profile on ${this.appTitle}`
              : `See profiles on ${this.appTitle}`
          }`,
        },
        {
          name: 'description',
          content: `${
            this.profileUser
              ? `Connect with ${this.profileUser.displayName} on ${this.appTitle}. Share office hours, remote work schedule, OOO alerts, social media, books, and stay in touch.`
              : this.appTitle
          }`,
        },
        {
          property: 'og:description',
          content: `${
            this.profileUser
              ? `Connect with ${this.profileUser.displayName} on ${this.appTitle}. Share office hours, remote work schedule, OOO alerts, social media, books, and stay in touch.`
              : this.appTitle
          }`,
        },
        {
          property: 'og:image',
          content: `${this.profileUser ? this.profileUser.photoURL : ''}`,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `${
            this.$route.params.uid
              ? `${this.appURL}view/profile/${this.$route.params.uid}`
              : ''
          }`,
        },
      ],
    }
  },
  computed: {
    ...mapState('privateinfo', ['showPrivateInfoPanel']),
    ...mapState('redirects', ['redirectsList']),
  },
  watch: {
    redirectsList: {
      handler(redirectsList) {
        if (redirectsList && redirectsList.length > 0) {
          redirectsList.every((redirect) => {
            if (
              Date.now() >= redirect.startDateTime.getTime() &&
              Date.now() <= redirect.endDateTime.getTime()
            ) {
              this.redirectProfile(redirect)
              return false
            }
            return true // must return true if doesn't break
          })
        }
      },
      deep: true,
      immediate: true,
    },
    profileUser: {
      handler() {
        // if (profileUser && profileUser.id) {
        //   this.checkUserLicense(profileUser)
        // }
        this.fetchProfileUserAppConfig(this.$route.params.uid)
      },
      deep: true,
      immediate: true,
    },
    profileUserPanelsConfig: {
      handler(profileUserPanelsConfig) {
        if (profileUserPanelsConfig && profileUserPanelsConfig.id) {
          if (
            this.profileUser &&
            this.profileUser.companyId &&
            this.profileUser.licenseType === 'ENT'
          ) {
            this.fetchProfileUserCompany(this.profileUser.companyId)
          } else {
            this.populateMergedConfigValues(profileUserPanelsConfig)
          }
        }
      },
      deep: true,
      immediate: true,
    },
    company: {
      handler(company) {
        // console.log(JSON.stringify(company))
        if (company && company.id) {
          this.fetchCompanyAppConfig(company.id)
        }
      },
      deep: true,
      immediate: true,
    },
    companyPanelsConfig: {
      handler(companyPanelsConfig) {
        // console.log(JSON.stringify(companyPanelsConfig))
        if (!this.isEmpty(companyPanelsConfig)) {
          this.mergeProfileUserAndCompanyAppConfigs(companyPanelsConfig)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    const queryString = window.location.search
    if (queryString === '?showDebug') {
      this.showDebug = true
    }
    document.body.style.backgroundColor = this.defaultBGColor

    this.isLoading = true
    this.initializer()
    const constraints = []
    constraints.push(['endDateTime', '>=', new Date()])
    this.fetchRedirectsByUserId({
      userId: this.uid,
      constraints,
    })
      .then((result) => {
        if (!this.isEmpty(result)) {
          console.log(JSON.stringify(result))
          this.setRedirects(result)
        }
      })
      .catch((err) => {
        console.log(err)
      })

    if (this.$route.params.uid) {
      this.fetchUserByUserId(this.$route.params.uid)
        .then((profileUserResponse) => {
          if (profileUserResponse?.disabled) {
            console.info(
              `${profileUserResponse?.displayName} was disabled by ${
                profileUserResponse?.lastUpdatedBy?.displayName
              } on ${formatDateTime(
                profileUserResponse.updateTimestamp,
              )}. To re-enable please contact support.`,
              this.setProfileUserDisabled(true),
            )
          } else if (!this.isEmpty(profileUserResponse)) {
            this.setProfileUser(profileUserResponse)
          } else {
            this.errorTitle = this.showUserMessageFromCode(
              'profile/notfound/title',
            )
            this.errorDescription = this.showUserMessageFromCode(
              'profile/notfound/description',
            )
            this.setProfileNotFoundError(true)
          }
        })
        .catch((error) => {
          console.log(error)
          this.errorTitle = this.showUserMessageFromCode(
            'profile/notfound/title',
          )
          this.errorDescription = this.showUserMessageFromCode(
            'profile/notfound/description',
          )
          this.setProfileNotFoundError(true)
          this.setProfileUser(null)
        })
    }
  },
  mounted() {
    this.isLoading = false
  },
  destroyed() {
    destroyColorPersonalization()
    this.setProfileUser(null)
    this.setAllEvents([])
    this.setEvents(null)
    this.setRedirects(null)
    this.setComments(null)
    this.setLeisures(null)
    this.setLinks(null)
    this.setOfficeHours(null)
    this.setEmailPreferences(null)
  },
  methods: {
    //  mapActions
    ...mapActions('user', ['fetchUserByUserId']),
    ...mapActions('redirects', ['fetchRedirectsByUserId']),
    ...mapActions('networkStatus', ['getNetworkStatusByUserId']),
    ...mapActions('appconfig', ['fetchUserAppPanelsConfigByUserId']),
    ...mapActions('company', [
      'fetchCompanyByCompanyId',
      'fetchCompanyAppPanelsConfigByCompanyId',
    ]),
    // mapMutations
    ...mapMutations('events', ['setAllEvents', 'setEvents']),
    ...mapMutations('profileUser', ['setProfileUser']),
    ...mapMutations('redirects', ['setRedirects']),
    ...mapMutations('networkStatus', ['setNetworkStatus']),
    ...mapMutations('appconfig', [
      'setProfileUserAppConfig',
      'setMergedAppConfig',
    ]),
    ...mapMutations('comments', ['setComments']),
    ...mapMutations('leisures', ['setLeisures']),
    ...mapMutations('links', ['setLinks']),
    ...mapMutations('officehours', ['setOfficeHours']),
    ...mapMutations('emailpreferences', ['setEmailPreferences']),
    ...mapMutations('company', ['setCompany', 'setCompanyAppConfig']),
    ...mapMutations('app', [
      'setCriticalAppError',
      'setCompanyNotFound',
      'setAppConfigNotFoundForUser',
      'setProfileNotFoundError',
      'setProfileUserDisabled',
      'setAppConfigNotFoundForCompany',
      'setAppConfigNotFoundForUser',
    ]),
    initializer() {
      this.setAllEvents([])
      this.setProfileNotFoundError(false)
      this.setProfileUserDisabled(false)
      this.$store.commit('privateinfo/setShowPrivateInfoPanel', null, {
        root: true,
      })
      this.$store.commit('privateinfo/setPrivateContactInfo', null, {
        root: true,
      })
    },
    // initializeProfileState,
    redirectProfile(redirect) {
      this.$router
        .push({
          name: 'redirectinterstitial',
          params: {
            redirect,
          },
        })
        .catch(() => {})
    },

    async fetchProfileUserAppConfig() {
      // console.log(JSON.stringify('fetch app config'))
      this.isLoading = true
      await this.fetchUserAppPanelsConfigByUserId(this.uid)
        .then((profileUserAppConfigResponse) => {
          if (!this.isEmpty(profileUserAppConfigResponse)) {
            // console.log(JSON.stringify(profileUserAppConfigResponse))
            this.setProfileUserAppConfig(profileUserAppConfigResponse)
          } else {
            this.setAppConfigNotFoundForUser(true)
          }
        })
        .catch((error) => {
          console.log(error)
          this.setAppConfigNotFoundForUser(true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async fetchProfileUserCompany(companyId) {
      this.isLoading = true
      await this.fetchCompanyByCompanyId(companyId)
        .then((companyResponse) => {
          // console.log(JSON.stringify(companyResponse))
          if (!this.isEmpty(companyResponse)) {
            this.setCompany(companyResponse)
          } else {
            this.setCompanyNotFound(true)
            // console.log(JSON.stringify('line 330 : No company response'))
            this.setAppConfigNotFoundForCompany(true)
          }
        })
        .catch((error) => {
          console.log(error)
          this.setCompanyNotFound(true)
          // console.log(JSON.stringify('line 337 : Error in company response'))
          this.setAppConfigNotFoundForCompany(true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async fetchCompanyAppConfig(companyId) {
      this.isLoading = true
      // console.log(JSON.stringify(companyId))
      await this.fetchCompanyAppPanelsConfigByCompanyId(companyId)
        .then((companyAppConfigResponse) => {
          // console.log(JSON.stringify(companyAppConfigResponse))
          if (!this.isEmpty(companyAppConfigResponse)) {
            // console.log(JSON.stringify(companyAppConfigResponse))
            this.setCompanyAppConfig(companyAppConfigResponse)
          } else {
            console.log(JSON.stringify('line 251'))
            this.setAppConfigNotFoundForCompany(true)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /*
    The logic is quirky but needed, it can be improved though but its works so its fine for now!
    */
    mergeProfileUserAndCompanyAppConfigs(companyPanelsConfig) {
      // console.log(`Company panel offhrs ${JSON.stringify(companyPanelsConfig.officeHours)}`)
      // console.log(
      //   `Profile panel config offhrs ${JSON.stringify(this.profileUserPanelsConfig.officeHours)}`,
      // )
      if (this.profileUserPanelsConfig) {
        const populateMergedConfig = {
          comments: companyPanelsConfig.comments
            ? companyPanelsConfig.comments
            : this.profileUserPanelsConfig.comments,
          contactPreferences: companyPanelsConfig.contactPreferences
            ? companyPanelsConfig.contactPreferences
            : this.profileUserPanelsConfig.contactPreferences,
          events: companyPanelsConfig.events
            ? companyPanelsConfig.events
            : this.profileUserPanelsConfig.events,
          leisures: companyPanelsConfig.leisures
            ? companyPanelsConfig.leisures
            : this.profileUserPanelsConfig.leisures,
          links: companyPanelsConfig.links
            ? companyPanelsConfig.links
            : this.profileUserPanelsConfig.links,
          officeHours: companyPanelsConfig.officeHours
            ? companyPanelsConfig.officeHours
            : this.profileUserPanelsConfig.officeHours,
          personalInfo: companyPanelsConfig.personalInfo
            ? companyPanelsConfig.personalInfo
            : this.profileUserPanelsConfig.personalInfo,
          privateInfo: companyPanelsConfig.privateInfo
            ? companyPanelsConfig.privateInfo
            : this.profileUserPanelsConfig.privateInfo,
          projectTeams: companyPanelsConfig.projectTeams
            ? companyPanelsConfig.projectTeams
            : this.profileUserPanelsConfig.projectTeams,
          isPublicallyVisible: companyPanelsConfig.isPublicallyVisible
            ? companyPanelsConfig.isPublicallyVisible
            : this.profileUserPanelsConfig.isPublicallyVisible,
        }
        this.populateMergedConfigValues(populateMergedConfig)
      }
    },

    async populateMergedConfigValues(appConfig) {
      // console.log(JSON.stringify('mergeappconfig is setting'))
      await this.setMergedAppConfig(appConfig)
    },
  },
}
</script>

<style scoped lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100px);
  opacity: 0;
}
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100px);
  opacity: 0;
}
@media screen and (max-width: $tablet-down) {
  .synpanel {
    margin: 0 0.5rem 1rem;
  }
}
</style>
