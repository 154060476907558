export default {
  events: undefined,
  eventsConfig: undefined,
  eventToCreate: null,
  eventCreationPending: false,
  eventUpdatePending: false,
  eventDeletePending: false,
  eventToEdit: null,
  allEvents: [],
  eventComments: undefined,
}
