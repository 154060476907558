var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile"},[_c('full-page',{staticClass:"view-profile",attrs:{"max-width":"is-max-widescreen"}},[_c('b-loading',{attrs:{"is-full-page":true},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"spinner","size":"is-large","custom-class":"fa-pulse","type":"is-primary"}})],1),(_vm.showDebug)?_c('ViewDebug'):_vm._e(),(
        _vm.criticalAppError ||
        _vm.hardError.userDisabled ||
        _vm.hardError.profileNotFound
      )?[_c('ViewError',{attrs:{"title":_vm.errorTitle,"description":_vm.errorDescription}})]:[_c('ViewUserInfo'),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showPrivateInfoPanel && (_vm.isPaidUser || _vm.isEnterpriseUser))?_c('ViewPrivateInfo'):_vm._e()],1),_c('ViewEvents'),(
          _vm.isEnterpriseUser &&
          _vm.isUserLoggedIn &&
          _vm.getProfileUserCompanyId === _vm.getAuthUserCompanyId
        )?[_c('ViewUserTeams')]:_vm._e(),(
          _vm.isEnterpriseUser &&
          _vm.isUserLoggedIn &&
          _vm.getProfileUserCompanyId === _vm.getAuthUserCompanyId
        )?[_c('ViewMentoring')]:_vm._e(),_c('ViewComment'),_c('ViewLinks'),_c('ViewOfficeHours'),_c('ViewEmailPreferences'),_c('ViewLeisures')]],2),(_vm.profileUser && _vm.profileUser.displayName)?_c('div',{staticClass:"watermark"},[_c('span',[_c('b-icon',{attrs:{"pack":"fas","icon":"id-card","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.profileUser.displayName))],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }