<template>
  <span v-if="teamEventToView" class="team-event-button">
    <p v-if="isLoading" style="display: inline">Fetching Info...</p>
    <template v-else>
      <b-button
        v-if="isUserTeamEventAttendee"
        type="is-danger"
        icon-left="ban"
        icon-pack="fas"
        class="event-info__edit-button"
        @click="unJoinTeamEventVue()"
      >
        Unjoin Event
      </b-button>

      <b-button
        v-else-if="
          !isUserTeamEventAttendee &&
          isUserInSameCompany &&
          teamEventToView.allowSubscribe &&
          !isUserATeamEventSubscriber
        "
        type="is-danger"
        icon-left="rss"
        icon-pack="fas"
        class="event-info__edit-button"
        @click="subscribeToTeamEventVue()"
      >
        Subscribe
      </b-button>
      <b-button
        v-else-if="isUserATeamEventSubscriber"
        type="is-danger"
        icon-left="rss"
        icon-pack="fas"
        class="event-info__edit-button"
        @click="unSubscribeToTeamEventVue()"
      >
        Unsubscribe
      </b-button>
    </template>
  </span>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapMutations, mapActions } from 'vuex'
import { viewTeamsMixin } from '@/mixins/teams/index'

export default {
  name: 'TeamEventButton',
  mixins: [viewTeamsMixin],
  props: {
    isUserInSameCompany: null,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapState('teamsEvents', [
      'teamEvents',
      'teamEventErrors',
      'teamEventToView',
      'isUserATeamEventSubscriber',
      'isUserTeamEventAttendee',
    ]),
    ...mapState('teamMembers', ['teamMembers']),
    ...mapState('teamMembers', [
      'updateTeamMemberStatus',
      'removeTeamMemberById',
    ]),
  },
  watch: {},
  created() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('userTeams', ['setAllActiveUserTeams']),
    ...mapMutations('teamsEvents', [
      'addSubscriberToTeamEventToView',
      'addAttendeeToTeamEventToView',
      'removeSubscriberById',
      'removeAttendeeById',
      'setIsUserATeamEventSubscriber',
      'setIsUserTeamEventAttendee',
    ]),
    ...mapActions('teamsEvents', [
      'unJoinTeamEventAction',
      'subscribeToTeamEventAction',
      'unSubscribeToTeamEventAction',
    ]),

    subscribeToTeamEventVue() {
      this.isLoading = true
      const teamUpdate = {
        team: {
          id: this.teamToView.id,
          name: this.teamToView.teamName,
        },
        action: this.notificationActionTypesConstants.SUBSCRIBED,
        type: 'event',
        emoji: '🎟️',
        message: this.teamEventToView.eventName,
        showOnDashboard: false,
        priority: 2,
      }
      this.subscribeToTeamEventAction({
        teamEvent: this.teamEventToView,
        teamUpdate,
        user: this.user,
      })
        .then((response) => {
          if (response) {
            this.setIsUserATeamEventSubscriber(true)
            if (response.user) {
              // console.log(response.user)
              this.addSubscriberToTeamEventToView(response.user.id)

              this.handleSuccessMessage(
                this.showUserMessageFromCode('teams/teamevent/subscribed'),
              )
            }
          } else {
            this.setIsUserATeamEventSubscriber(false)
            this.handleErrorMessage(
              this.showUserMessageFromCode('generic/error/unknown/title'),
            )
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    unJoinTeamEventVue() {
      const removeObject = this.teamEventToView.attendees.find(
        (user) => user.id === this.user.id,
      )
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          'Are you sure you want to leave the event? This action is not reversible',
        )
      ) {
        this.isLoading = true
        const teamEvent = {
          id: this.teamEventToView.id,
          teamId: this.teamEventToView.team.id,
          removeObject,
        }

        const teamUpdate = {
          team: {
            id: this.teamToView.id,
            name: this.teamToView.teamName,
          },
          action: this.notificationActionTypesConstants.LEFT,
          type: 'event',
          emoji: '🎟️',
          message: this.teamEventToView.eventName,
          showOnDashboard: false,
          priority: 1,
        }
        this.unJoinTeamEventAction({
          teamEvent,
          teamUpdate,
          user: this.user,
        })
          .then((response) => {
            if (response) {
              this.handleSuccessMessage(
                'Event successfully updated in database',
              )
              this.setIsUserTeamEventAttendee(false)
              if (response.user) {
                this.removeAttendeeById(this.user.id)
              }
            } else {
              this.handleErrorMessage(
                this.showUserMessageFromCode('generic/error/unknown/title'),
              )
            }
          })
          .then(async () => {
            if (this.teamEventToView.nylasEventId) {
              try {
                const url = `${process.env.VUE_APP_NYLAS_BASE_URL}/nylas/rsvp-event`
                const res = await fetch(url, {
                  method: 'POST',
                  headers: {
                    Authorization: this?.teamEventToView?.createdBy?.id, // accessToken of event creator is needed for updates
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    nylasEventId: this.teamEventToView.nylasEventId,
                    rsvp: 'no',
                  }),
                })
                if (!res.ok) {
                  console.log(JSON.stringify(res.statusText))
                  console.log(JSON.stringify(res.status))
                  throw new Error(res.statusText)
                }
                this.handleSuccessMessage(
                  'Event successfully updated in calendar',
                )
              } catch (err) {
                this.handleErrorMessage(
                  'Error in updating event in your calendar, please manually do so.',
                )
              }
            }
          })
          .catch((error) => {
            console.log(error)
            this.handleErrorMessage(
              'Unknown error in updating event, please try again',
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    unSubscribeToTeamEventVue() {
      if (
        // eslint-disable-next-line no-alert
        window.confirm('Are you sure you want to unsubsribe to the event?')
      ) {
        this.isLoading = true

        const teamUpdate = {
          team: {
            id: this.teamToView.id,
            name: this.teamToView.teamName,
          },
          action: this.notificationActionTypesConstants.UNSUBSCRIBED,
          type: 'event',
          emoji: '🎟️',
          message: this.teamEventToView.eventName,
          showOnDashboard: false,
          priority: 2,
        }

        this.unSubscribeToTeamEventAction({
          teamEvent: this.teamEventToView,
          teamUpdate,
          user: this.user,
        })
          .then((response) => {
            if (response) {
              this.setIsUserATeamEventSubscriber(false)

              if (response.user) {
                // console.log(response.user)
                this.removeSubscriberById(response.user.id)
                this.handleSuccessMessage(
                  this.showUserMessageFromCode('teams/teamevent/unsubscribed'),
                )
              }
            } else {
              this.handleErrorMessage(
                this.showUserMessageFromCode('generic/error/unknown/title'),
              )
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
  },
}
</script>
