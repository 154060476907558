<template>
  <div class="linkstag">
    <b-tag>
      <a
        :href="getLink"
        class="linkstag__link"
        target="_blank"
        rel="noopener noreferrer"
        :title="title + ' : ' + getLink"
        @click="trackEvent(title)"
      >
        <b-icon :icon="icon" :size="size" :pack="pack"> </b-icon
      ></a>
      <slot
    /></b-tag>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import { trackClickLinkEvent } from '@/misc/analytics'

export default {
  name: 'LinksTag',
  props: {
    link: {
      type: String,
      default: '#',
    },
    icon: {
      type: String,
      default: 'external-link-alt',
    },
    size: {
      type: String,
      default: 'is-medium',
    },
    pack: {
      type: String,
      default: 'fab',
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    getLink() {
      return `${this.link}`
    },
  },
  methods: {
    trackEvent(title) {
      trackClickLinkEvent('social-media_links', title, this.link, 'icon')
    },
  },
}
</script>
<style lang="scss">
.linkstag {
  .tag {
    padding: 0;
    margin-right: $min-size;
    background-color: transparent;
  }
  @media screen and (min-width: $desktop) {
    .tag {
      margin-right: $base-size;
    }
  }
}
a.linkstag__link {
  :hover,
  :focus {
    outline: none !important;
  }
}
</style>
