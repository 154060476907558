/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import { cloneDeep } from 'lodash'
import TeamsDB from '@/firebase/teams/teams-db'
import { teamStatusConstants } from '@/data/teams/team-status'
import { teamEventStatusConstants } from '@/data/teams/team-event-status'

import {
  createTeamMemberBatch,
  updateTeamBatch,
  subscribeToTeamBatch,
  unSubscribeToTeamBatch,
} from './teamsBatch'

export default {
  createTeamAction: async ({ rootState }, team) => {
    const TeamsDb = new TeamsDB()
    return TeamsDb.create(team)
  },

  // createTeamMemberInBatch: async ({ rootState }, payload) => {
  //   return createTeamMemberBatch(payload)
  // },

  fetchTeambyTeamId: async ({ commit }, payload) => {
    const TeamsDb = new TeamsDB()
    return TeamsDb.read(payload.teamId)
  },

  updateTeam: async ({ commit }, payload) => {
    return updateTeamBatch(payload)
  },

  subscribeToTeamAction: async ({ commit }, payload) => {
    return subscribeToTeamBatch(payload)
  },
  unSubscribeToTeamAction: async ({ commit }, payload) => {
    return unSubscribeToTeamBatch(payload)
  },
}
