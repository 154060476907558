export const eventTypes = [
  { id: 51, name: '👨‍👩‍👧‍👦 Family Leave' },
  { id: 52, name: '🎖️ Military Leave' },
  { id: 65, name: 'Meeting' },
  { id: 53, name: 'National Holiday' },
  { id: 54, name: '👌 Personal Time Off' },
  { id: 55, name: '🛌 Sick Day' },
  { id: 56, name: '💼 🤝 Trade Show' },
  { id: 57, name: '💪 Training' },
  { id: 58, name: '🧳 Travelling' },
  { id: 59, name: '🏖️🌊✈️ Vacation' },
  { id: 61, name: '✨ Volunteering' },
  { id: 62, name: '🎂 Birthday' },
  { id: 63, name: '👫👬Anniversary' },
  { id: 64, name: '🎓 Graduation' },
  { id: 99, name: 'Other' },
]

export default function eventTypesHelper() {
  return true
}

const eventTypeConstants = {
  FAMILYLEAVE: 51,
  MILITARYLEAVE: 52,
  NATIONALHOLIDAY: 53,
  PTO: 54,
  PAPPT: 64,
  SICKDAY: 55,
  TRADESHOW: 56,
  TRAINING: 57,
  TRAVELLING: 58,
  VACATION: 59,
  VOLUNTEERING: 61,
  BIRTHDAY: 62,
  ANNIVERSARY: 63,
  GRADUATION: 64,
  MEETING: 65,
  OTHER: 99,
}

const eventTypesValues = [
  { id: eventTypeConstants.FAMILYLEAVE, name: '👨‍👩‍👧‍👦 Family Leave' },
  { id: eventTypeConstants.MILITARYLEAVE, name: '🎖️ Military Leave' },
  { id: eventTypeConstants.MEETING, name: '🤝 Meeting' },
  { id: eventTypeConstants.NATIONALHOLIDAY, name: 'National Holiday' },
  { id: eventTypeConstants.PTO, name: '😌 Personal Time Off' },
  { id: eventTypeConstants.PAPPT, name: '🙋🏽 Personal Appointment' },
  { id: eventTypeConstants.SICKDAY, name: '🛌 Sick Day' },
  { id: eventTypeConstants.TRADESHOW, name: '💼 Trade Show' },
  { id: eventTypeConstants.TRAINING, name: '💪 Training' },
  { id: eventTypeConstants.TRAVELLING, name: '🧳 Travelling' },
  { id: eventTypeConstants.VACATION, name: '🏖️ Vacation' },
  { id: eventTypeConstants.VOLUNTEERING, name: '✨ Volunteering' },
  { id: eventTypeConstants.BIRTHDAY, name: '🎂 Birthday' },
  { id: eventTypeConstants.ANNIVERSARY, name: '🎊 Anniversary' },
  { id: eventTypeConstants.GRADUATION, name: '🎓 Graduation' },
  { id: eventTypeConstants.OTHER, name: 'Other' },
]

export { eventTypeConstants, eventTypesValues }
