<!--  -->
<template>
  <span>
    <div class="private">
      <skeleton v-if="isLoading"></skeleton>
      <section v-if="!isLoading" class="view-private">
        <div class="siynactionbar card">
          <div class="siynactionbar__heading">
            <span class="siynactionbar__heading--title card-header-title">
              <span class="siynactionbar__heading--emoji"
                >&nbsp;📇 &nbsp; &nbsp; Contact</span
              >
            </span>

            <div
              v-if="privateContactInfo"
              class="siynactionbar__links columns is-multiline is-mobile is-variable is-2 mt-2"
            >
              <div
                v-if="privateContactInfo.chatVendor"
                class="column is-auto is-narrow"
              >
                <a
                  class="view-private__link"
                  title="Click to chat"
                  @click="clickToChat()"
                >
                  <b-icon pack="fas" icon="comment-dots" size="is-small">
                  </b-icon
                  ><span class="ml-2">{{
                    getChatName(privateContactInfo.chatVendor)
                  }}</span></a
                >
              </div>
              <div
                v-if="privateContactInfo.deskPhone"
                class="column is-auto is-narrow"
              >
                <a
                  class="view-private__link"
                  :title="showLabelsFromCode('form/generic/clicktocall')"
                  @click="clickToCall(privateContactInfo.deskPhone)"
                >
                  <b-icon pack="fas" icon="phone-square" size="is-small">
                  </b-icon
                  ><span class="ml-2">{{
                    privateContactInfo.deskPhone
                  }}</span></a
                >
              </div>

              <div
                v-if="privateContactInfo.mobilePhone"
                class="column is-auto is-narrow"
              >
                <a
                  class="view-private__link"
                  :title="showLabelsFromCode('form/generic/clicktocall')"
                  @click="clickToCall(privateContactInfo.mobilePhone)"
                >
                  <b-icon pack="fas" icon="mobile-alt" size="is-small"> </b-icon
                  ><span class="ml-2">{{
                    privateContactInfo.mobilePhone
                  }}</span></a
                >
              </div>
              <div
                v-if="privateContactInfo.sos"
                class="column is-auto is-narrow"
              >
                <a
                  class="view-private__link"
                  :title="showLabelsFromCode('form/privateInfo/emergencyCall')"
                  @click="clickToCall(privateContactInfo.sos)"
                >
                  <span class="ml-2">🆘 {{ privateContactInfo.sos }}</span></a
                >
              </div>
              <div class="column is-auto is-narrow">
                <a
                  class="view-private__link"
                  :title="showLabelsFromCode('form/generic/clicktoemail')"
                  @click="clickToEmail(profileUser.email)"
                >
                  <b-icon pack="fas" icon="envelope" size="is-small"> </b-icon
                  ><span class="ml-2">{{ profileUser.email }}</span></a
                >
              </div>
              <div
                v-if="privateContactInfo.companyAddress"
                class="column is-auto is-narrow"
              >
                <a
                  class="view-private__link"
                  :title="showLabelsFromCode('form/privateInfo/address')"
                  @click="clickToMaps(privateContactInfo.companyAddress)"
                >
                  <b-icon pack="fas" icon="location-arrow" size="is-small">
                  </b-icon
                  ><span class="ml-1 is-clipped">{{
                    privateContactInfo.companyAddress
                  }}</span></a
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </span>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapActions, mapMutations } from 'vuex'

import { viewProfileMixin } from '@/mixins/profile/index'
import { trackExceptionEvent } from '@/misc/analytics'
import { handleErrorMessage } from '@/misc/user-messages'
import { stripNonDigit } from '@/misc/helpers'
import chatProviders from '@/data/events/chatProviders'

export default {
  name: 'ViewPrivateInfo',
  components: {},
  mixins: [viewProfileMixin], // register mixin
  data() {
    return {
      chatProviders,
      panelTitle: '',
      isLoading: true,
    }
  },
  computed: {
    ...mapState('privateinfo', ['privateContactInfo']),
  },
  mounted() {
    this.fetchContent()
    //    console.log(JSON.stringify('I am mounted'))
    this.isLoading = false
  },
  methods: {
    ...mapMutations('privateinfo', ['setPrivateContactInfo']),
    ...mapActions('privateinfo', ['fetchPrivateContactInfoByUserId']),
    stripNonDigit,
    getChatName(id) {
      const response = this.chatProviders.find((vendor) => vendor.id === id)
      return response.name || 'None'
    },
    fetchContent() {
      // console.log(JSON.stringify(uid))
      this.isLoading = true
      this.fetchPrivateContactInfoByUserId(this.$route.params.uid)
        .then((privateContactInfoResponse) => {
          if (privateContactInfoResponse) {
            // console.log(JSON.stringify(privateContactInfoResponse))
            this.setPrivateContactInfo(privateContactInfoResponse)
          } else {
            handleErrorMessage(
              `${this.showUserMessageFromCode(
                'generic/error-in-fetch',
              )} private contact info`,
            )
            throw new Error(
              `Fetch private contact was null for ${this.$route.params.uid}`,
            )
          }
        })
        .catch((err) => {
          console.log(`Something failed ?? ${JSON.stringify(err)}`)
          if (err.code !== 'permission-denied') {
            handleErrorMessage(
              `${this.showUserMessageFromCode(
                'generic/error-in-fetch',
              )} private info`,
            )
          }
          this.setPrivateContactInfo(null)
          trackExceptionEvent(
            'private-contact-info_fetch_failed',
            `user ${
              this.user ? this.user.id : 'visitor'
            } tried to fetch info for  ${this.$route.params.uid}. ${err}`,
            false,
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    clickToMaps(address) {
      window.open(`https://maps.google.com/?q=${address}`, '_blank').focus()
    },
    clickToCall(number) {
      window.open(`tel:${stripNonDigit(number)}`)
    },
    clickToEmail(email) {
      window.open(`mailto:${email}`)
    },
    clickToChat() {
      console.log('chat with me')
    },
  },
}
</script>
<style scoped lang="scss">
.columns {
  padding: $base-size !important;
  padding-top: 0 !important;
}
button {
  padding: 0 $min-size !important;
}
</style>
@/data/chatProviders
