<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="event-meeting-notes">
    <div width-class="is-12" class="view-event-meeting-notes">
      <skeleton v-if="isLoading"></skeleton>
      <SiynCollapse
        v-if="teamEventToView && !isLoading"
        :title="panelTitle"
        :is-open="collapseHelper(panelname)"
        :panelname="panelname"
      >
        <template #header-extras>
          <b-tooltip
            multilined
            class="mx-4"
            type="is-dark"
            :label="showTooltipsFromCode('teamevents/restrict/visibility')"
            position="is-right"
            ><b-icon
              class="has-text-grey-label"
              pack="fas"
              icon="eye"
              size="is-small"
            >
            </b-icon
          ></b-tooltip>
        </template>
        <template #default>
          <div class="view-event-meeting-notes__wrapper px-4 py-2">
            <table
              v-if="teamEventToView?.meetingNotes"
              class="table is-striped is-narrow is-fullwidth"
            >
              <thead>
                <tr>
                  <th>Meeting Notes</th>
                  <th>🤖 AI Summary</th>
                  <th>Author</th>
                  <th>Created</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      v-if="teamEventToView?.meetingNotes"
                      label="Meeting Notes"
                      class=""
                      @click="viewNotesModal(teamEventToView?.meetingNotes)"
                      ><b-tag class="event-meeting-notes__tag"
                        ><b-icon
                          pack="far"
                          icon="eye"
                          type="is-dark"
                          label="View"
                          size="is-medium"
                        >
                        </b-icon></b-tag></a
                    ><a
                      v-if="
                        (teamEventToView?.meetingNotes &&
                          getAuthUserId ===
                            teamEventToView.notesCreatedBy.id) ||
                        isUserTeamAdminCache
                      "
                      label="Original Notes"
                      @click="editNotesModal(teamEventToView)"
                      ><b-tag class="event-meeting-notes__tag"
                        ><b-icon
                          pack="far"
                          icon="edit"
                          type="is-primary"
                          label="Edit"
                          size="is-medium"
                        >
                        </b-icon></b-tag
                    ></a>
                    <b-modal v-model="isNotesModalActive" :can-cancel="true">
                      <SynPanel :title="panelTitle">
                        <p>
                          <b>Created: </b
                          >{{ momentAgo(teamEventToView.notesCreateTimestamp) }}
                          <b class="ml-2">By: </b>
                          <router-link
                            class="no-text-decoration"
                            :to="{
                              name: 'profile',
                              params: {
                                uid: teamEventToView.notesCreatedBy.id,
                              },
                            }"
                            >{{
                              teamEventToView.notesCreatedBy.displayName
                            }}</router-link
                          >
                        </p>
                        <p v-if="teamEventToView?.notesUpdateTimestamp">
                          <b>Last Updated: </b
                          >{{ momentAgo(teamEventToView.notesUpdateTimestamp) }}
                          <b class="ml-2">By: </b>
                          <router-link
                            class="no-text-decoration"
                            :to="{
                              name: 'profile',
                              params: {
                                uid: teamEventToView.notesLastUpdatedBy.id,
                              },
                            }"
                            >{{
                              teamEventToView.notesLastUpdatedBy.displayName
                            }}</router-link
                          >
                        </p>
                        <p v-if="teamEventToView.notesImageURL" class="my-2">
                          <b>Handwritten Notes Image: </b>
                          <a
                            target="_blank"
                            :href="teamEventToView.notesImageURL"
                            >Click to view</a
                          >
                        </p>
                        <hr class="my-2" />
                        <div
                          class="white-space-preline event-meeting-notes__text sm-list-styles"
                          v-html="removeEmptyPTags(getTempNotes)"
                        ></div>
                      </SynPanel>
                    </b-modal>
                  </td>
                  <td>
                    <a
                      v-if="teamEventToView?.summarizedText"
                      label="AI Summary"
                      @click="
                        viewAISummaryModal(teamEventToView.summarizedText)
                      "
                      ><b-tag class="event-meeting-notes__tag"
                        ><b-icon
                          pack="far"
                          icon="eye"
                          type="is-dark"
                          label="View"
                          size="is-medium"
                        >
                        </b-icon></b-tag
                    ></a>
                    <a
                      v-if="
                        teamEventToView?.summarizedText &&
                        (getAuthUserId === teamEventToView?.notesCreatedBy.id ||
                          isUserTeamAdminCache)
                      "
                      label="Edit Summary"
                      @click="editAISummaryModal(teamEventToView)"
                      ><b-tag class="event-meeting-notes__tag"
                        ><b-icon
                          pack="far"
                          icon="edit"
                          type="is-primary"
                          label="Edit"
                          size="is-medium"
                        >
                        </b-icon></b-tag
                    ></a>
                    <b-modal v-model="isSummaryModalActive" :can-cancel="true">
                      <SynPanel
                        v-if="tempSummarizedText"
                        title="Notes Summarized by AI"
                      >
                        <p v-if="teamEventToView?.summaryDateTime">
                          <b>Created: </b
                          >{{ momentAgo(teamEventToView?.summaryDateTime) }}
                          <b class="ml-2">By Model: </b>
                          <span>{{
                            teamEventToView.summaryCreatedBy.displayName
                          }}</span>
                        </p>
                        <p
                          v-if="
                            teamEventToView?.summaryUpdateDateTime &&
                            teamEventToView?.summaryUpdatedBy
                          "
                        >
                          <b>Last Updated: </b
                          >{{
                            momentAgo(teamEventToView.summaryUpdateDateTime)
                          }}
                          <b class="ml-2">By: </b
                          ><router-link
                            v-if="teamEventToView.summaryUpdatedBy.id"
                            class="no-text-decoration"
                            :to="{
                              name: 'profile',
                              params: {
                                uid: teamEventToView.summaryUpdatedBy.id,
                              },
                            }"
                            >{{
                              teamEventToView.summaryUpdatedBy.displayName
                            }}</router-link
                          >
                          <span v-else>{{
                            teamEventToView.summaryUpdatedBy.displayName
                          }}</span>
                        </p>
                        <p v-if="teamEventToView.notesImageURL" class="my-2">
                          <b>Handwritten Notes Image: </b>
                          <a
                            target="_blank"
                            :href="teamEventToView.notesImageURL"
                            >Click to view</a
                          >
                        </p>
                        <hr class="my-2" />
                        <div
                          v-if="teamEventToView.summarizedText"
                          class="white-space-preline event-meeting-notes__text sm-list-styles"
                          v-html="removeEmptyPTags(getTempSummarizedText)"
                        ></div>
                        <div v-else>
                          <p><i>No Summary found</i></p>
                        </div>
                      </SynPanel>
                    </b-modal>
                  </td>
                  <td>
                    <span v-if="teamEventToView.notesCreatedBy">
                      <router-link
                        class="no-text-decoration"
                        :to="{
                          name: 'profile',
                          params: { uid: teamEventToView.notesCreatedBy.id },
                        }"
                        >{{
                          teamEventToView.notesCreatedBy.displayName
                        }}</router-link
                      >
                    </span>
                  </td>
                  <td>
                    <span v-if="teamEventToView.notesCreateTimestamp">
                      {{ momentAgo(teamEventToView.notesCreateTimestamp) }}
                    </span>
                  </td>
                  <td>
                    <a
                      class="no-text-decoration"
                      @click="openSideBySide(teamEventToView)"
                    >
                      <b-tooltip
                        label="View Notes & AI Summary Side By Side"
                        position="is-left"
                        type="is-dark"
                      >
                        <b-icon
                          icon="right-left"
                          pack="fas"
                          type="is-info"
                          size="is-medium"
                        >
                        </b-icon>
                      </b-tooltip>
                    </a>
                    <a
                      v-if="
                        getAuthUserId === teamEventToView.notesCreatedBy.id ||
                        isUserTeamAdminCache
                      "
                      class="no-text-decoration ml-2"
                      @click="deleteNotesVue(teamEventToView)"
                    >
                      <b-tooltip
                        :label="showLabelsFromCode('generic/delete')"
                        position="is-left"
                        type="is-dark"
                      >
                        <b-icon
                          icon="trash-alt"
                          pack="fa"
                          type="is-danger"
                          size="is-medium"
                        >
                        </b-icon>
                      </b-tooltip>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="
                (isUserTeamEventAttendee || isUserPartOfTeamCache) &&
                teamToView?.teamStatus == teamStatusConstants.ACTIVE
              "
              class="event-meeting-notes__button-wrapper"
            >
              <b-button
                type="is-danger"
                :disabled="!isEmpty(teamEventToView?.meetingNotes)"
                class="event-meeting-notes__edit-button"
                title="Add Notes"
                @click="addMeetingNotes"
                >Add Notes
              </b-button>
            </div>
          </div>
        </template>
      </SiynCollapse>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations, mapActions } from 'vuex'

import Skeleton from '@/components/partials/Skeleton'
import SynPanel from '@/components/SynPanel'
import { viewTeamsMixin } from '@/mixins/teams/index'
import EditMeetingNotes from '@/views/team/event/meetingnotes/onetime/EditMeetingNotes.vue'
import EditMeetingNotesAISummary from '@/views/team/event/meetingnotes/onetime/EditMeetingNotesAISummary'
import { removeEmptyPTags } from '@/misc/helpers'

export default {
  name: 'OnetimeTeamEventMeetingNotes',
  components: {
    Skeleton,
    SynPanel,
  },
  mixins: [viewTeamsMixin],
  props: {},
  data() {
    return {
      panelname: 'team_event_meeting_notes',
      isLoading: true,
      panelTitle: 'Meeting Notes',
      isNotesModalActive: false,
      isSummaryModalActive: false,
      tempSummarizedText: null,
      tempNotes: null,
    }
  },
  computed: {
    ...mapState('teamsEvents', [
      'teamEventToView',
      'teamMeetingNotes',
      'isUserTeamEventAttendee',
      'isUserATeamEventSubscriber',
    ]),
    getTempNotes() {
      return this.tempNotes
    },
    getTempSummarizedText() {
      return this.tempSummarizedText
    },
  },
  created() {
    if (this.$route.params.refresh) {
      this.$router.go(0) // this is to ensure that page is refreshed after adding notes
    }
  },
  mounted() {
    this.isLoading = false
  },
  destroyed() {},
  methods: {
    ...mapMutations('teamsEvents', [
      'setTeamMeetingNoteToEdit',
      'setTeamMeetingNoteToRemove',
      'setTeamEventToEdit',
      'removeOneTimeTeamEventMeetingNotes',
    ]),
    ...mapActions('teamsEvents', ['deleteOneTimeMeetingNotesAction']),
    viewNotesModal(meetingNotes) {
      this.isNotesModalActive = true
      this.tempNotes = meetingNotes
    },
    viewAISummaryModal(meetingNotes) {
      this.isSummaryModalActive = true
      this.tempSummarizedText = meetingNotes
    },
    addMeetingNotes() {
      this.$router.push({
        name: 'addonetimemeetingnotes',
        params: {
          teamEventToView: this.teamEventToView,
          teamId: this.teamEventToView.team.id,
          teamEventId: this.teamEventToView.id,
          mode: 'ADD',
        },
      })
    },

    editNotesModal(teamEvent) {
      this.setTeamEventToEdit(teamEvent)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditMeetingNotes,
        events: {
          update_successful: () => {
            this.handleSuccessMessage('Update Successful !')
          },
          update_failed: () => {
            this.handleErrorMessage('Update Failed !')
          },
        },
        trapFocus: true,
      })
    },
    editAISummaryModal(teamEvent) {
      this.setTeamEventToEdit(teamEvent)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditMeetingNotesAISummary,
        events: {
          update_successful: () => {
            this.handleSuccessMessage('Update Successful !')
          },
          update_failed: () => {
            this.handleErrorMessage('Update Failed !')
          },
        },
        trapFocus: true,
      })
    },
    removeEmptyPTags,
    openSideBySide(teamEvent) {
      this.$router.push({
        name: 'viewonetimeeventnotessidebyside',
        params: {
          teamEvent,
          teamId: teamEvent.team.id,
          teamEventId: teamEvent.id,
          mode: 'VIEW',
        },
      })
    },
    deleteNotesVue(meetingNotesId) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete the notes?')) {
        this.isLoading = true
        const teamUpdate = {
          team: {
            id: this.teamEventToView.team.id,
            name: this.teamToView.teamName,
          },
          action: this.notificationActionTypesConstants.DELETED,
          type: 'notes',
          message: `For event ${this.teamEventToView.eventName}`,
          showOnDashboard: false,
          priority: 1,
        }
        this.deleteOneTimeMeetingNotesAction({
          teamEventToView: this.teamEventToView,
          teamUpdate,
          user: this.user,
        })
          .then((deleteResponse) => {
            if (deleteResponse) {
              this.removeOneTimeTeamEventMeetingNotes(meetingNotesId)
              this.handleSuccessMessage('Meeting Notes deleted successfully')
            }
          })
          .catch((err) => {
            console.log(err)
            this.handleErrorMessage(
              this.showUserMessageFromCode('generic/error/unknown/title'),
            )
          })
          .finally(() => (this.isLoading = false))
      }
    },
  },
}
</script>
