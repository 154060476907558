/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import UserNotificationsDB from '@/firebase/notifications/user-notifications-db.js'
// import UserNotificationsNewDB from '@/firebase/notifications/OLD-user-notifications-new-db'

export default {
  fetchNotificationsByUserId: async ({ commit }, payload) => {
    const userNotificationsDB = new UserNotificationsDB(payload.userId)
    return userNotificationsDB.readAll(payload.constraints)
  },
  addNotification: async ({ commit }, payload) => {
    const userNotificationsDB = new UserNotificationsDB(payload.userId)
    await userNotificationsDB.create(payload.notification)
  },
  deleteNotification: async ({ commit, rootState }, payload) => {
    const userNotificationsDB = new UserNotificationsDB(payload.userId)
    await userNotificationsDB.delete(payload.notificationId)
  },
  // getNotificationsByUserId: async ({ rootState, commit }, user) => {
  //   console.log('i m in not actions')
  //   /*     const userNotificationsDB = new UserNotificationsDB(
  //     rootState.user.user.id
  //   )
  //   const notificationsList = await userNotificationsDB.readNotifications(
  //     user.id
  //   )
  //   // document.body.className += 'bodyColor'
  //   // this.wrapperBackgroundColor = '#ea4d46db'

  //   /*  this.$store.commit('app/setWrapperBackgroundColor', '#ea4d46db')
  //         setTimeout(() => {
  //           this.$store.commit('app/setWrapperBackgroundColor', 'transparent')
  //         }, 3000) */

  //   // break
  //   /*           console.log(`Start Time is ${this.data[i].startDateTime.getTime()}`)
  //         console.log('Today is between the two dates ')
  //         console.log(`End Time is ${this.data[i].endDateTime.getTime()}`) */
  //   // Do something

  //   // commit('setNotifications', notificationsList) */
  // },
  /**
   * Create notification
   */
}
