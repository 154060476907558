import state from './networkStatus.state'
import mutations from './networkStatus.mutations'
import actions from './networkStatus.actions'
import getters from './networkStatus.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
