/* eslint-disable no-unused-vars */

import TeamBulletinsDB from '@/firebase/teams/team-bulletins'

export default {
  fetchTeamBulletinsByTeamId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const teamBulletinsDB = new TeamBulletinsDB(payload.teamId)
    return teamBulletinsDB.readAll(payload.constraints || null)
  },

  fetchTeamBulletinsById: async ({ commit }, payload) => {
    const teamBulletinsDB = new TeamBulletinsDB(payload.teamId)
    return teamBulletinsDB.read(payload.id)
  },

  createTeamBulletin: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const teamBulletinsDB = new TeamBulletinsDB(payload.teamId)
    return teamBulletinsDB.create(payload.teamBulletin)
  },

  updateTeamBulletin: async ({ commit }, payload) => {
    const teamBulletinsDB = new TeamBulletinsDB(payload.teamId)
    return teamBulletinsDB.update(payload.teamBulletin)
  },

  deleteTeamBulletin: async ({ commit }, payload) => {
    const teamBulletinsDB = new TeamBulletinsDB(payload.teamId)
    return teamBulletinsDB.delete(payload.teamBulletinId)
  },

  bulletinReaction: async ({ commit }, payload) => {
    const teamBulletinsDB = new TeamBulletinsDB(payload.bulletin.team.id)
    return teamBulletinsDB.update(payload.bulletin)
  },
}
