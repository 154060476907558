var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"team-event"},[_c('full-page',{staticClass:"view-team-event",attrs:{"max-width":"is-max-desktop","width-class":"is-10"}},[_c('b-loading',{attrs:{"is-full-page":true},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"spinner","size":"is-large","custom-class":"fa-pulse","type":"is-primary"}})],1),(!_vm.isLoading)?[(_vm.canUserView && _vm.teamEventToView?.id)?[_c('TeamEventInfo',{attrs:{"is-user-in-same-company":_vm.isUserInSameCompanyAsEvent}}),_c('TeamEventLocation'),_c('TeamEventAttendees'),(
            _vm.teamEventToView?.allowSubscribe &&
            _vm.teamEventToView?.subscribers &&
            _vm.teamEventToView?.subscribers.length > 0
          )?_c('TeamEventSubscribers'):_vm._e(),(
            _vm.teamEventToView?.eventOccurence === 'recurring' &&
            (_vm.isUserATeamEventSubscriber ||
              _vm.isUserTeamEventAttendee ||
              _vm.isUserTeamAdminCache)
          )?_c('RecurringTeamEventMeetingNotes'):_vm._e(),(
            _vm.teamEventToView?.eventOccurence === 'onetime' &&
            (_vm.isUserATeamEventSubscriber ||
              _vm.isUserTeamEventAttendee ||
              _vm.isUserTeamAdminCache)
          )?_c('OneTimeTeamEventMeetingNotes'):_vm._e(),(
            _vm.isUserATeamEventSubscriber ||
            _vm.isUserTeamEventAttendee ||
            _vm.isUserTeamAdminCache
          )?_c('TeamEventAttachments'):_vm._e()]:_vm._e(),(_vm.teamEventErrors.unknown)?_c('ViewError',{attrs:{"title":_vm.errorTitle,"description":_vm.errorDescription}}):_vm._e(),(_vm.teamEventErrors.teamEventNotFound)?_c('ViewError',{attrs:{"title":_vm.errorTitle,"description":_vm.errorDescription}}):_vm._e()]:_vm._e()],2),(
      _vm.teamEventToView?.eventName &&
      (_vm.isUserTeamEventAttendee || _vm.isUserInSameCompanyAsEvent)
    )?_c('div',{staticClass:"watermark"},[_c('span',[_c('b-icon',{attrs:{"pack":"fas","icon":"calendar-day","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.teamEventToView.eventName))],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }