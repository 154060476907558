const teamEventTypes = [
  { id: 'teammeeting', name: '🤝🏽 Team Meeting' },
  { id: 'townhall', name: '🏢 Town Hall' },
  { id: 'leadership', name: '🧑🏿‍💼 Leadership Meeting' },
  { id: 'milestone', name: '🏆 Milestone' },
  { id: 'release', name: '🚀 Go Live' },
  { id: 'happyhour', name: '🍹Happy Hour' },
  { id: 'standup', name: '🧑‍🤝‍🧑 Stand Up' },
  { id: 'demo', name: '🧑‍🤝‍🧑 Demonstration' },
  { id: 'retrospective', name: '🧑‍🤝‍🧑 Retrospective' },
  { id: 'planning', name: '🧑‍🤝‍🧑 Planning' },
  { id: 'other', name: '📅 Other' },
  { id: 'tradeshow', name: '💼 Trade Show' },
  { id: 'training', name: '💪 Training' },
]

export default teamEventTypes
