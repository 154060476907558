import state from './profileUser.state'
import mutations from './profileUser.mutations'
import actions from './profileUser.actions'
import getters from './profileUser.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
