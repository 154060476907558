export default {
  // /* set boolean */
  setLeisures: (state, leisures) => (state.leisures = leisures),
  setLeisuresConfig: (state, leisuresConfig) =>
    (state.leisuresConfig = leisuresConfig),

  addToLeisuresState: (state, leisure) => state.leisures.push(leisure),
  /* remove leisures from state */
  removeLeisureById: (state, leisureId) => {
    const index = state?.leisures.findIndex(
      (leisures) => leisures.id === leisureId,
    )
    if (index !== -1) {
      state?.leisures.splice(index, 1)
    }
  },

  setLeisuresUpdate(state, leisure) {
    const index = state.leisures.findIndex((item) => item.id === leisure.id)
    if (index !== -1) {
      Object.assign(state.leisures[index], leisure)
      console.log(JSON.stringify(state.leisures[index]))
    }
  },

  setLeisuresToEdit: (state, leisureToEdit) =>
    (state.leisureToEdit = leisureToEdit),
}
