<template>
  <section class="container is-fullhd authentication login mx-auto my-6">
    <div class="columns is-vcentered is-centered">
      <div class="column is-5">
        <div class="box p-6">
          <h1 class="title is-3 has-text-centered mb-5">
            Email Verification Required
          </h1>
          <form>
            <b-message class="mb-3" type="is-info">
              Thank You for registering at <b>{{ appTitle }}</b
              >. Your email has not yet been verified. For verification please
              click the link in the email. To resend verification email please
              click the button below.
            </b-message>
            <b-field position="is-centered" class="has-text-centered mt-5">
              <b-button
                type="submit"
                class="button is-primary"
                @click="sendVerification()"
              >
                Resend Verification Email
              </b-button>
            </b-field>
          </form>
          <hr />
          <p class="has-text-centered">
            <router-link to="/login">Login</router-link>
            <span class="mx-2">|</span>
            <router-link to="/register">Register</router-link>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState } from 'vuex'

import { connectAuthEmulator, sendEmailVerification } from 'firebase/auth'
import { auth } from '@/firebase/initFirebase'

import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'
import {
  trackPageView,
  trackScreenView,
  trackEngagementEvent,
  trackExceptionEvent,
} from '@/misc/analytics'

export default {
  name: 'EmailVerfication',
  components: {},

  metaInfo: {
    title: 'Email Verfication',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  // the reason you need computed property because you want the component to react if the state changes.
  computed: {
    ...mapState('user', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'byline']),
  },
  methods: {
    sendVerification() {
      if (
        window.location.hostname === 'localhost' &&
        process.env.VUE_APP_USE_EMULATOR === 'true'
      ) {
        connectAuthEmulator(auth, 'http://127.0.0.1:9099')
      }

      sendEmailVerification(auth.currentUser)
        .then(() => {
          trackEngagementEvent(
            'email_verification_sent',
            'Resend Verification Email',
            'page',
            'button',
          )
          handleSuccessMessage(
            `Email sent sucessfully. Please check your Inbox for a verification link from us`,
          )
          this.$router.push({ name: 'home' })
        })
        .catch((err) => {
          trackExceptionEvent(
            'email_verification_sent_failed',
            err.message,
            false,
          )
          handleErrorMessage(
            `We're sorry email verification failed, please try again`,
          )
          console.log(err)
        })
    },
  },
}
</script>
