<template>
  <section class="event-files">
    <div width-class="is-12" class="view-event-files">
      <skeleton v-if="isLoading"></skeleton>
      <SiynCollapse
        v-if="teamEventToView && !isLoading"
        :title="panelTitle"
        :is-open="collapseHelper(panelname)"
        :panelname="panelname"
      >
        <template #header-extras>
          <b-tooltip
            multilined
            class="mx-4"
            type="is-dark"
            :label="showTooltipsFromCode('teamevents/restrict/visibility')"
            position="is-right"
            ><b-icon
              class="has-text-grey-label"
              pack="fas"
              icon="eye"
              size="is-small"
            >
            </b-icon
          ></b-tooltip>
        </template>
        <template #default>
          <div class="view-event-files__wrapper px-4 py-2">
            <firestore-files
              :folder-path="`/teamimages/${teamEventToView.teamId}/teamevents/${teamEventToView.id}`"
              :allowed-to-add-file="
                (isUserTeamEventAttendee || isUserTeamAdminCache) &&
                teamToView &&
                teamToView.teamStatus == teamStatusConstants.ACTIVE
              "
              :allowed-to-update-file="isUserTeamAdminCache"
              :allowed-to-delete-file="isUserTeamAdminCache"
            ></firestore-files>
            <!--  <b-table
          v-if="files"
          ref="table"
          :data="files"
          hoverable
          mobile-cards
          narrowed
          custom-row-key="id"
          class="event-files__table"
          :show-header="files.length > 0 ? true : false"
          :paginated="files.length > 8 ? true : false"
          default-sort="metadata.updated"
          default-sort-direction="desc"
          per-page="8"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <b-table-column
            v-slot="props"
            field="metadata.contentType"
            label="Type"
            sortable
            cell-class="valign"
          >
            <template
              v-if="props.row.metadata && props.row.metadata.contentType.split('/')[0] === 'image'"
            >
              <b-icon pack="far" icon="image" size="is-medium"> </b-icon>
            </template>
            <template v-else-if="props.row.metadata">
              <b-icon
                pack="far"
                :icon="populateIcon(props.row.metadata.contentType)"
                size="is-medium"
              >
              </b-icon>
            </template>
          </b-table-column>
          <b-table-column v-slot="props" cell-class="valign" field="name" label="Name" sortable>
            <span v-if="props.row.metadata && props.row.url">
              <a target="_blank" :href="props.row.url">{{
                props.row.metadata.customMetadata.filename || props.row.metadata.name
              }}</a>
            </span>
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="metadata.size"
            label="Size"
            sortable
            cell-class="valign"
          >
            <span v-if="props.row.metadata">{{
              Number((props.row.metadata.size / 1000).toFixed(0)) + ' KB'
            }}</span>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="metadata.updated"
            label="Updated"
            sortable
            cell-class="valign"
          >
            <span v-if="props.row.metadata">
              {{ momentAgo(props.row.metadata.updated) }}
            </span>
          </b-table-column>

          <b-table-column
            v-if="isUserTeamEventAttendee || isUserTeamAdminCache"
            v-slot="props"
            field="id"
            label="Action"
            centered
            cell-class="valign"
          >
            <a
              v-if="
                (props.row.metadata && getAuthUserId === props.row.createdById) ||
                isUserTeamAdminCache
              "
              class="no-text-decoration mr-3"
              @click="openEditName(props.row.metadata)"
            >
              <b-tooltip
                :label="showLabelsFromCode('generic/edit')"
                position="is-right"
                type="is-dark"
              >
                <b-icon pack="far" icon="edit" type="is-primary" size="is-medium"> </b-icon>
              </b-tooltip>
            </a>
            <a
              v-if="
                (isUserLoggedIn && getAuthUserId === props.row.createdById) || isUserTeamAdminCache
              "
              class="no-text-decoration"
              @click="deleteFileVue(props.row.metadata)"
            >
              <b-tooltip
                :label="showLabelsFromCode('generic/delete')"
                position="is-left"
                type="is-dark"
              >
                <b-icon icon="trash-alt" pack="fa" type="is-danger" size="is-medium"> </b-icon>
              </b-tooltip>
            </a>
          </b-table-column>

          <template #empty>
            <div class="has-text-centered">No Files</div>
          </template>
        </b-table>

        <b-progress v-if="processing" type="is-success" :rounded="false" show-value></b-progress>
        <div
          v-if="
            (isUserTeamEventAttendee ||
              isUserTeamAdminCache ||
              teamEventToView.createdBy.id === getAuthUserId) &&
            teamToView &&
            teamToView.teamStatus == teamStatusConstants.ACTIVE
          "
          class="event-files__button-wrapper"
        >
          <form enctype="multipart/form-data" novalidate>
            <label for="file-upload">
              <b-tag
                v-if="!processing"
                type="is-danger"
                size="is-medium"
                class="event-files__edit-button"
                title="Add File"
                >Add File
              </b-tag>
            </label>
            <input id="file-upload" type="file" @change="onUpload" />
          </form>
        </div> -->
          </div>
        </template>
      </SiynCollapse>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import { mapState } from 'vuex'

import { viewTeamsMixin } from '@/mixins/teams/index'
import FirestoreFiles from '@/components/FirestoreFiles.vue'

export default {
  name: 'TeamEventFiles',
  components: {
    FirestoreFiles,
  },
  mixins: [viewTeamsMixin],
  props: {},
  data() {
    return {
      isLoading: true,
      panelTitle: 'Files',
      panelname: 'team_event_attachments',
    }
  },

  computed: {
    ...mapState('teamsEvents', [
      'teamEventToView',
      'isUserTeamEventAttendee',
      'isUserATeamEventSubscriber',
    ]),
  },
  created() {},
  mounted() {
    this.isLoading = false
  },
  destroyed() {},
  methods: {},
}
</script>
