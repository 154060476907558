<template>
  <section v-cloak class="team-event">
    <full-page
      max-width="is-max-desktop"
      width-class="is-10"
      class="view-team-event"
    >
      <b-loading v-model="isLoading" :is-full-page="true">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon
      ></b-loading>

      <template v-if="!isLoading">
        <template v-if="canUserView && teamEventToView?.id">
          <TeamEventInfo
            :is-user-in-same-company="isUserInSameCompanyAsEvent"
          ></TeamEventInfo>
          <TeamEventLocation></TeamEventLocation>
          <TeamEventAttendees></TeamEventAttendees>
          <TeamEventSubscribers
            v-if="
              teamEventToView?.allowSubscribe &&
              teamEventToView?.subscribers &&
              teamEventToView?.subscribers.length > 0
            "
          ></TeamEventSubscribers>
          <RecurringTeamEventMeetingNotes
            v-if="
              teamEventToView?.eventOccurence === 'recurring' &&
              (isUserATeamEventSubscriber ||
                isUserTeamEventAttendee ||
                isUserTeamAdminCache)
            "
          ></RecurringTeamEventMeetingNotes>
          <OneTimeTeamEventMeetingNotes
            v-if="
              teamEventToView?.eventOccurence === 'onetime' &&
              (isUserATeamEventSubscriber ||
                isUserTeamEventAttendee ||
                isUserTeamAdminCache)
            "
          ></OneTimeTeamEventMeetingNotes>
          <TeamEventAttachments
            v-if="
              isUserATeamEventSubscriber ||
              isUserTeamEventAttendee ||
              isUserTeamAdminCache
            "
          ></TeamEventAttachments>
        </template>
        <ViewError
          v-if="teamEventErrors.unknown"
          :title="errorTitle"
          :description="errorDescription"
        >
        </ViewError>
        <ViewError
          v-if="teamEventErrors.teamEventNotFound"
          :title="errorTitle"
          :description="errorDescription"
        >
        </ViewError>
      </template>
    </full-page>
    <div
      v-if="
        teamEventToView?.eventName &&
        (isUserTeamEventAttendee || isUserInSameCompanyAsEvent)
      "
      class="watermark"
    >
      <span>
        <b-icon pack="fas" icon="calendar-day" size="is-small"> </b-icon>
        {{ teamEventToView.eventName }}</span
      >
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations, mapActions } from 'vuex'
import { viewTeamsMixin } from '@/mixins/teams/index'
import { trackPageView, trackScreenView } from '@/misc/analytics'
import { destroyColorPersonalization } from '@/misc/helpers'

import ViewError from '@/views/errors/ViewError'
import TeamEventInfo from './TeamEventInfo'
import TeamEventLocation from './TeamEventLocation'
import TeamEventAttendees from './TeamEventAttendees'
import TeamEventSubscribers from './TeamEventSubscribers'
import RecurringTeamEventMeetingNotes from './RecurringTeamEventMeetingNotes'
import OneTimeTeamEventMeetingNotes from './OneTimeTeamEventMeetingNotes'
import TeamEventAttachments from './TeamEventAttachments'

export default {
  name: 'ViewTeamEvent',
  components: {
    ViewError,
    TeamEventInfo,
    TeamEventLocation,
    TeamEventAttendees,
    TeamEventSubscribers,
    RecurringTeamEventMeetingNotes,
    OneTimeTeamEventMeetingNotes,
    TeamEventAttachments,
  },
  mixins: [viewTeamsMixin],
  props: {
    id: {
      type: String,
      default: null,
    },
    teamid: {
      type: String,
      default: null,
    },
    teamEventToViewProp: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      isUserInSameCompanyAsEvent: null,
      defaultBGColor: process.env.VUE_APP_BODY_BGCOLOR,
      errorTitle: this.showUserMessageFromCode(
        'teams/teamevent/notfound/title',
      ),
      errorDescription: this.showUserMessageFromCode(
        'teams/teamevent/notfound/description',
      ),
    }
  },
  metaInfo: {
    title: 'View Team Event',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  computed: {
    ...mapState('teamsEvents', [
      'teamEvents',
      'teamEventErrors',
      'teamEventToView',
      'isUserATeamEventSubscriber',
      'isUserTeamEventAttendee',
      'teamMeetingNotes',
    ]),
    ...mapState('teamMembers', ['teamMembers']),
    canUserView() {
      return (
        this.teamEventToView?.eventVisibility === 'public' ||
        this.isUserTeamEventAttendee ||
        this.isUserInSameCompanyAsEvent
      )
    },
  },
  watch: {
    teamEventToView: {
      handler(teamEventToView) {
        if (teamEventToView && this.user) {
          if (teamEventToView.companyId === this.getAuthUserCompanyId) {
            this.isUserInSameCompanyAsEvent = true
          }
          if (this.isEmpty(this.teamToView)) {
            this.fetchTeamById(teamEventToView.team.id)
          }
          this.fetchMeetingNotesByIdsVue(teamEventToView.id)
        }
      },
      deep: true,
      immediate: true,
    },
    teamToView: {
      handler(teamToView) {
        if (teamToView && this.user) {
          if (teamToView.companyId === this.user?.companyId) {
            this.isUserInSameCompanyAsEvent = true
          } else {
            this.isUserInSameCompanyAsEvent = false
            this.setTeamEventNotFoundError(false)
          }
        }
      },
      deep: true,
      immediate: true,
    },

    user: {
      handler(user) {
        if (user) {
          this.fetchTeamEvent()
        }
      },
    },
  },
  created() {
    // console.log(JSON.stringify(this.teamEventToViewProp))

    if (!this.isEmpty(this.teamEventToViewProp)) {
      this.setTeamEventToView(this.teamEventToViewProp)
      if (this.teamEventToViewProp.attendees) {
        const tempArray = this.teamEventToViewProp.attendees.map(
          (element) => element.id,
        )
        // console.log(JSON.stringify(tempArray))
        this.setIsUserTeamEventAttendee(!!tempArray.includes(this.user.id))
      }
      if (this.teamEventToViewProp.subscribers) {
        const tempArray2 = this.teamEventToViewProp.subscribers.map(
          (element) => element.id || null,
        )
        this.setIsUserATeamEventSubscriber(!!tempArray2.includes(this.user.id))
      }
    } else {
      this.fetchTeamEvent()
    }
  },
  mounted() {
    this.isLoading = false
  },
  updated() {
    if (this.teamEventToView?.eventVisibility === 'public') {
      // console.log(`Event visiblity is ${this.teamEventToView?.eventVisibility}`)
      document.body.style.backgroundColor = '#ea4d46'
      if (this.user) {
        this.$buefy.snackbar.open({
          duration: 5000,
          type: 'is-danger',
          message: this.showUserMessageFromCode('teams/teamevent/visibility'),
        })
      }
    } else {
      document.body.style.backgroundColor =
        process.env.VUE_APP_TEAM_EVENT_BGCOLOR
    }
  },
  destroyed() {
    destroyColorPersonalization()
  },
  methods: {
    ...mapMutations('teamsEvents', [
      'setTeamEventToView',
      'setTeamEventNotFoundError',
      'setTeamEventUnknownError',
      'setIsUserATeamEventSubscriber',
      'setIsUserTeamEventAttendee',
      'setTeamMeetingNotes',
    ]),
    ...mapActions('teamsEvents', [
      'fetchTeamEventById',
      'fetchMeetingNotesByIdsAction',
    ]),
    async fetchTeamById(teamId) {
      this.isLoading = true
      await this.$store
        .dispatch('teams/fetchTeambyTeamId', {
          teamId,
        })
        .then((teamResponse) => {
          // console.log(JSON.stringify(teamResponse))
          if (!this.isEmpty(teamResponse)) {
            this.setTeamToView(teamResponse)
            this.setIsUserTeamAdminCache(
              !!teamResponse.teamAdmins.includes(this.user?.id),
            )
          } else {
            this.setTeamEventNotFoundError(true)
            this.setIsUserTeamEventAttendee(false)
          }
        })
        .catch((error) => {
          console.log(error)
          this.setTeamEventNotFoundError(true)
          this.setTeamEventToView(null)
          this.isUserTeamEventAttendee = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async fetchTeamEvent() {
      this.isLoading = true
      await this.fetchTeamEventById(this.$route.params.id)
        .then((teamEventResponse) => {
          // console.log(JSON.stringify(teamEventResponse))
          if (!this.isEmpty(teamEventResponse)) {
            this.setTeamEventToView(teamEventResponse)
            if (teamEventResponse.attendees) {
              const tempArray = teamEventResponse.attendees.map(
                (element) => element.id,
              )
              // console.log(JSON.stringify(tempArray))
              this.setIsUserTeamEventAttendee(
                !!tempArray.includes(this.user?.id),
              )
            }
            if (teamEventResponse?.subscribers) {
              const isUserSubscriber = !!teamEventResponse.subscribers.includes(
                this.user?.id,
              )
              this.setIsUserATeamEventSubscriber(isUserSubscriber)
            }
          } else {
            this.setTeamEventNotFoundError(true)
            this.setIsUserTeamEventAttendee(false)
            this.setIsUserATeamEventSubscriber(false)
          }
        })
        .catch((error) => {
          console.log(error)
          this.setTeamEventNotFoundError(true)
          this.setTeamEventToView(null)
          this.setIsUserTeamEventAttendee(false)
          this.setIsUserATeamEventSubscriber(false)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async fetchMeetingNotesByIdsVue(teamEventId) {
      this.isLoading = true
      this.fetchMeetingNotesByIdsAction(teamEventId)
        .then((meetingNotesResponse) => {
          if (!this.isEmpty(meetingNotesResponse)) {
            this.setTeamMeetingNotes(meetingNotesResponse)
          }
        })
        .catch((error) => {
          console.log(error)
          this.setTeamMeetingNotes(null)
          // this.handleErrorMessage('Error in fetching meeting notes')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss"></style>
