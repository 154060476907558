export default {
  setAllNetworkEvents: (state, events) => (state.allNetworkEvents = events),
  setAllNetworkUpdates: (state, updates) => (state.allNetworkUpdates = updates),
  setAllTeamEvents: (state, events) => (state.allTeamEvents = events),
  setAllRecurringTeamEvents: (state, events) => (state.allRecurringTeamEvents = events),
  addToTeamEvents: (state, subscribedEvents) => state.allTeamEvents.push(subscribedEvents),
  setAllTeamBulletins: (state, bulletins) => (state.allTeamBulletins = bulletins),
  setAllTeamBroadcasts: (state, broadcasts) => (state.allTeamBroadcasts = broadcasts),
  setAllTeamUpdates: (state, updates) => (state.allTeamUpdates = updates),
  setAllCompanyEvents: (state, events) => (state.allCompanyEvents = events),
}
