<template>
  <section class="debug mb-4">
    <siyn-collapse title="Debug" emoji="⚠️" :is-open="isDebugOpen">
      <b-message v-if="criticalAppError" type="is-danger debug__error">
        <span class="debug__error--type">🛑 Critical App Error 🛑</span>
        <span class="debug_value">{{ JSON.stringify(criticalAppError) }}</span>
      </b-message>
      <b-message v-if="hardError" type="is-danger debug__error">
        <span class="debug__error--type">Hard Error</span>
        <div v-for="(value, key) in hardError" :key="key">
          <span v-if="value">
            <span class="debug__error--key">{{ key }}</span
            >: <span class="debug__error--value">{{ value }} </span>
          </span>
        </div>
      </b-message>
      <b-message v-if="warnError" type="is-warning debug__error">
        <span class="debug__error--type">Warn Error</span>
        <div v-for="(value, key) in warnError" :key="key">
          <span v-if="value">
            <span class="debug__error--key">{{ key }}</span
            >: <span class="debug__error--value">{{ value }} </span>
          </span>
        </div>
      </b-message>
      <b-message v-if="panelsConfig" type="is-info debug__error">
        <span class="debug__error--type">App Panel Config</span>
        <div v-for="(value, key) in panelsConfig" :key="key">
          <span class="debug__error--key">{{ key }}</span
          >: <span class="debug__error--value">{{ value }} </span>
        </div>
      </b-message>
      <b-message v-if="company" type="is-info debug__error">
        <span class="debug__error--key">Id</span>:
        <span class="debug__error--value">{{ company.id }} </span>
      </b-message>
    </siyn-collapse>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { viewProfileMixin } from '@/mixins/profile/index'

export default {
  name: 'ViewDebug',
  mixins: [viewProfileMixin], // register mixin
  data() {
    return {
      isDebugOpen: true,
    }
  },
  computed: {
    ...mapState('appconfig', ['panelsConfig']),
    ...mapState('company', ['company']),
  },
  mounted() {},
  methods: {},
}
</script>
<style lang="scss">
.message {
  margin-bottom: $min-size !important;
}
.message-body {
  padding: $min-size;
}
.debug {
  &__error {
    &--type {
    }
    &--key {
      font-weight: bold;
    }
    &--value {
      text-decoration: dotted;
    }
  }
}
</style>
