/* eslint-disable import/extensions */
import state from './sendamessage.state'
import mutations from './sendamessage.mutations'
import actions from './sendamessage.actions'
import getters from './sendamessage.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
