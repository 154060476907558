/* eslint-disable */

import {
  ToastProgrammatic as Toast,
  SnackbarProgrammatic as Snackbar,
} from 'buefy'
import emailjs from '@emailjs/browser'

function toastMessage(message, type, position, duration, indefinite) {
  Toast.open({
    message,
    type,
    position,
    duration,
    indefinite,
    pauseOnHover: true,
  })
}
export const handleSuccessMessage = async (
  message = 'Your action was successful',
  type = 'is-success',
  position = 'is-bottom-right',
  duration = 2000,
  indefinite = false,
) => {
  toastMessage(message, type, position, duration, indefinite)
  return true
}
export const handleErrorMessage = async (
  message = 'There was an error, please try again',
  type = 'is-danger',
  position = 'is-bottom-right',
  duration = 5000,
  indefinite = false,
) => {
  toastMessage(message, type, position, duration, indefinite)
  return true
}

export const handleWarningMessage = async (
  message = 'There was an issue',
  type = 'is-warning',
  position = 'is-bottom-right',
  duration = 5000,
  indefinite = false,
) => {
  toastMessage(message, type, position, duration, indefinite)
  return true
}

export const showSnackMessage = async (
  message = 'Your action was successful',
  type = 'is-success',
  actionText = '',
  duration = 6500,
) => {
  Snackbar.open({
    message,
    type,
    actionText,
    duration,
  })
}

/* #######################################################################

    TODO : COMMENT BELOW AND UNCOMMENT THE ONE AFTER THAT
####################################################################### */
// eslint-disable-next-line
// export async function sendEmail(
//   toEmail,
//   toName,
//   fromName,
//   replyTo = 'bot@siyn.email',
//   message,
//   templateId = '123',
// ) {
//   console.log(
//     `Email sent successfully to ${toName} at ${toEmail} from ${fromName} at ${replyTo} with message ${message}`,
//   )
// }
export const sendEmail = async (
  toEmail,
  toName,
  fromName,
  replyTo = 'bot@siyn.email',
  message,
  templateId = process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
) => {
  const templateParams = {
    to_email: toEmail,
    to_name: toName,
    from_name: fromName,
    reply_to: replyTo,
    message,
  }
  emailjs
    .send(
      process.env.VUE_APP_EMAILJS_SERVICE_ID,
      templateId,
      templateParams,
      process.env.VUE_APP_EMAILJS_USER_ID,
    )
    .then(
      (response) => {
        // console.log('SUCCESS!', response.status, response.text)
        return true
      },
      (error) => {
        console.log('Email send failed...', error)
        throw error
      },
    )
}
export default function userMessagesHelper() {
  return true
}
