/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */

import isEmpty from 'lodash/isEmpty'
import UserMentoringTopicsDB from '@/firebase/mentoring/mentoringtopics'
import UserMentoringSessionsTopicsDB from '@/firebase/mentoring/mentoringsessions-db'
import {
  mentoringSessionStatusConstants,
  mentoringSessionStatus,
} from '@/data/mentoring/mentoring-session-status'

export default {
  fetchMentoringTopicsByUserId: async ({ commit }, userId) => {
    const userMentoringTopicsDB = new UserMentoringTopicsDB(userId)
    return userMentoringTopicsDB.readAll()
  },

  fetchMentoringTopicById: async ({ commit }, payload) => {
    const userMentoringTopicsDB = new UserMentoringTopicsDB(payload.userId)
    return userMentoringTopicsDB.read(payload.topicId)
  },

  addUserMentoringTopic: async ({ commit }, payload) => {
    const userMentoringTopicsDB = new UserMentoringTopicsDB(payload.userId)
    return userMentoringTopicsDB.create(payload.data)
  },

  updateUserMentoringTopic: async ({ commit }, payload) => {
    const userMentoringTopicsDB = new UserMentoringTopicsDB(payload.userId)
    return userMentoringTopicsDB.update(payload.mentoringTopic)
  },

  deleteUserMentoringTopic: async ({ commit }, payload) => {
    const userMentoringTopicsDB = new UserMentoringTopicsDB(payload.userId)
    return userMentoringTopicsDB.delete(payload.mentoringTopicId)
  },

  /** ******************** MENTORING SESSIONS ************** */

  fetchMentoringSessionById: async ({ commit }, payload) => {
    const userMentoringSessionTopicsDB = new UserMentoringSessionsTopicsDB(
      payload.companyId,
    )
    return userMentoringSessionTopicsDB.read(payload.id)
  },

  fetchMentoringSessionByMentorId: async ({ commit }, payload) => {
    const userMentoringSessionTopicsDB = new UserMentoringSessionsTopicsDB(
      payload.companyId,
    )
    const constraints = []
    constraints.push(['mentor.id', '==', payload.mentorId])
    if (payload.eventStatus) {
      constraints.push(['eventStatus', '==', payload.eventStatus])
    }
    return userMentoringSessionTopicsDB.readAll(constraints)
  },

  fetchMentoringSessionByMenteeId: async ({ commit }, payload) => {
    const userMentoringSessionTopicsDB = new UserMentoringSessionsTopicsDB(
      payload.companyId,
    )
    const constraints = []
    constraints.push(['mentee.id', '==', payload.menteeId])
    if (payload.eventStatus) {
      constraints.push(['eventStatus', '==', payload.eventStatus])
    }
    return userMentoringSessionTopicsDB.readAll(constraints)
  },

  addUserMentoringSessionAction: async ({ commit }, payload) => {
    const userMentoringTopicsDB = new UserMentoringSessionsTopicsDB(
      payload.companyId,
    )
    return userMentoringTopicsDB.create(payload.mentoringSession)
  },

  updateUserMentoringSession: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const userMentoringSessionTopicsDB = new UserMentoringSessionsTopicsDB(
      payload.companyId,
    )
    return userMentoringSessionTopicsDB.update(payload.mentoringSession)
  },
}
