export default {
  setAllCompanyUsers: (state, allCompanyUsers) =>
    (state.allCompanyUsers = allCompanyUsers),

  addToCompanyTeams: (state, team) => state.allTeams.push(team),

  setAllCompanyTeams: (state, allTeams) => (state.allCompanyUsers = allTeams),

  setAllTeamEvents: (state, allTeamEvents) =>
    (state.allTeamEvents = allTeamEvents),

  setAllCompanyEvents: (state, allCompanyEvents) =>
    (state.allCompanyEvents = allCompanyEvents),

  addToMentoringTopics: (state, topic) => state.allMentoringTopics.push(topic),

  removeDisabledUser(state, userId) {
    const index = state.allCompanyUsers.findIndex((item) => item.id === userId)
    if (index !== -1) {
      state.allCompanyUsers.splice(index, 1)
    }
  },
}
