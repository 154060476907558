var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"view-company-events"},[(_vm.isLoading)?_c('skeleton'):_vm._e(),((_vm.companyEvents && _vm.companyEvents.length > 0) || _vm.isUserCompanyAdmin)?_c('SiynCollapse',{attrs:{"title":_vm.panelTitle,"emoji":"🎟️","is-open":_vm.collapseHelper(_vm.panelname),"panelname":_vm.panelname}},[(_vm.companyEvents)?_c('b-table',{ref:"table",staticClass:"view-company-events__table mx-auto",attrs:{"data":_vm.companyEvents,"hoverable":"","mobile-cards":"","narrowed":"","show-header":_vm.companyEvents.length > 0 ? true : false,"paginated":_vm.companyEvents.length > 5 ? true : false,"custom-row-key":"id","default-sort":"startDateTime","default-sort-direction":"desc","per-page":"5","aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"empty-content"},[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('generic/none'))+" ")])]},proxy:true}],null,false,3647384674)},[_c('b-table-column',{attrs:{"scope":"props","field":"eventName","label":_vm.showLabelsFromCode('teams/teamevents/eventname'),"cell-class":"valign","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_c('a',{staticClass:"no-text-decoration",on:{"click":function($event){return _vm.gotoViewCompanyEvent(props.row)}}},[_c('span',{attrs:{"title":props.row.eventName}},[_vm._v(_vm._s(props.row.eventName))])])]),(
            props.row.eventVisibility ===
            _vm.companyEventVisibilityConstants.PUBLIC
          )?_c('span',{staticClass:"ml-2",attrs:{"title":"External Event"}},[_vm._v("🌐")]):_vm._e()]}}],null,false,2534395614)}),_c('b-table-column',{attrs:{"scope":"props","field":"speaker.displayName","cell-class":"valign","label":"Speaker","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.speaker)?[(props.row.speaker.id)?_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
              name: 'profile',
              params: { uid: props.row.speaker.id },
            }}},[_c('view-user-avatar',{staticClass:"view-company-events__table--avatar",attrs:{"alt-name":props.row.speaker.displayName,"uid":props.row.speaker.id}}),_c('span',{staticClass:"view-company-events__table--displayName"},[_vm._v(_vm._s(props.row.speaker.displayName))])],1):(props.row.speaker.email)?_c('a',{attrs:{"href":`mailto:${props.row.speaker.email}`}},[_c('span',{staticClass:"view-company-events__table--displayName"},[_vm._v(_vm._s(props.row.speaker.displayName))])]):_vm._e()]:void 0]}}],null,false,3026673517)}),_c('b-table-column',{attrs:{"scope":"props","field":"startDateTime","cell-class":"valign","label":_vm.showLabelsFromCode('generic/when'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(props.row.startDateTime))+" "),_c('span',{staticClass:"view-company-events__table--startDateTime--daysAgo"},[_vm._v(" ("+_vm._s(_vm.humanReadableDateTime(props.row.startDateTime))+") ")])])]}}],null,false,1882699156)})],1):_vm._e(),(_vm.isUserCompanyAdmin || _vm.isUserInSameCompany)?_c('div',{staticClass:"has-text-center-touch has-text-right"},[_c('b-dropdown',{staticClass:"has-text-left",attrs:{"triggers":['hover'],"aria-role":"menu","mobile-modal":false,"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{staticClass:"view-company-events__button background-color-override",attrs:{"label":`${_vm.showLabelsFromCode(
              'company/events/panelTitle',
            )} ${_vm.showLabelsFromCode('generic/menu')}`,"type":"is-primary","icon-right":"caret-down","icon-pack":"fas"}})]},proxy:true}],null,false,2565775598)},[_c('b-dropdown-item',{attrs:{"aria-role":"menuitem"},on:{"click":function($event){return _vm.gotoAddCompanyEventScreen()}}},[_c('b-icon',{attrs:{"type":"is-info","icon":"square-plus","pack":"far"}}),_vm._v(_vm._s(_vm.showLabelsFromCode('generic/create'))+" "+_vm._s(_vm.showLabelsFromCode('generic/event')))],1),(_vm.isUserCompanyAdmin)?_c('b-dropdown-item',{attrs:{"aria-role":"menuitem"},on:{"click":function($event){return _vm.gotoListAllEventsScreen()}}},[_c('b-icon',{attrs:{"type":"is-info","icon":"list","pack":"fas"}}),_vm._v(_vm._s(_vm.showLabelsFromCode('generic/list'))+" All "+_vm._s(_vm.showLabelsFromCode('generic/events')))],1):_vm._e(),(_vm.isUserCompanyAdmin)?_c('b-dropdown-item',{attrs:{"aria-role":"menuitem"},on:{"click":function($event){return _vm.gotoUnpublishedEventsScreen()}}},[_c('b-icon',{attrs:{"type":"is-info","icon":"person-digging","pack":"fas"}}),_vm._v(_vm._s(_vm.showLabelsFromCode('generic/view'))+" All "+_vm._s(_vm.showLabelsFromCode('company/events/unpublished'))+" "+_vm._s(_vm.showLabelsFromCode('generic/events')))],1):_vm._e(),_c('b-dropdown-item',{attrs:{"aria-role":"menuitem"},on:{"click":function($event){return _vm.gotoMyUnpublishedEventsScreen()}}},[_c('b-icon',{attrs:{"type":"is-info","icon":"person-digging","pack":"fas"}}),_vm._v(_vm._s(_vm.showLabelsFromCode('generic/view'))+" My "+_vm._s(_vm.showLabelsFromCode('company/events/unpublished'))+" "+_vm._s(_vm.showLabelsFromCode('generic/events')))],1)],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }