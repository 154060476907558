/* eslint-disable import/extensions */
import state from './team-summaries.state'
import mutations from './team-summaries.mutations'
import actions from './team-summaries.actions'
import getters from './team-summaries.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
