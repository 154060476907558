import state from './userTeams.state'
import mutations from './userTeams.mutations'
import actions from './userTeams.actions'
import getters from './userTeams.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
