<template>
  <div class="view-likes">
    <b-taglist class="view-likes__list">
      <div class="view-likes__border">
        <span class="view-likes__list--emoji">👍</span
        ><span class="view-likes__list--title">{{
          showLabelsFromCode('profile/leisures/likes')
        }}</span>
      </div>
      <b-tag
        v-for="like in likes[0].likes"
        :key="like.id"
        class="view-likes__list--item border-color--success br-6"
      >
        {{ like }}
      </b-tag>
    </b-taglist>
  </div>
</template>

<script>
import { viewProfileMixin } from '@/mixins/profile/index'

export default {
  name: 'ViewLikes',
  mixins: [viewProfileMixin],
  props: {
    likes: Object,
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
