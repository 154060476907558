<!-- eslint-disable vue/no-v-html -->
<template>
  <section v-cloak class="view-token">
    <full-page>
      <SynPanel :title="`${panelTitle} ID: ${companyTokenToView.id}`">
        <b-notification
          v-if="errorMessage"
          type="is-danger is-light"
          aria-close-label="Close notification"
          class="sm-help-notification"
          ><span v-html="errorMessage"></span
        ></b-notification>

        <div class="columns is-gapless is-vcentered is-multiline">
          <div class="column is-3">
            <span class="sm-label">Created For: </span>
          </div>
          <div v-if="tokenToView?.createdFor" class="column is-9">
            <span class="sm-value"
              ><b>{{ tokenToView.createdFor?.displayName }}</b> -
              {{ tokenToView.createdFor?.email }}</span
            >
          </div>
          <div class="column is-3">
            <span class="sm-label">Created By: </span>
          </div>
          <div class="column is-9">
            <span class="sm-value"
              ><router-link
                v-if="tokenToView?.createdBy?.id"
                target="_blank"
                class="no-text-decoration"
                :to="{
                  name: 'profile',
                  params: { uid: tokenToView.createdBy.id },
                }"
              >
                <span>{{ tokenToView.createdBy.displayName }}</span>
              </router-link>
              on
              <span>{{ formatDate(tokenToView?.createTimestamp) }}</span
              ><span class="has-text-grey-light ml-2"
                >({{ momentAgo(tokenToView?.createTimestamp) }})</span
              ></span
            >
          </div>
          <div class="column is-3">
            <span class="sm-label">License Valid : </span>
          </div>
          <div class="column is-9">
            <span class="sm-value">
              From {{ formatDateWithYear(tokenToView?.startDateTime) }} to
              {{ formatDateWithYear(tokenToView?.endDateTime)
              }}<span class="has-text-grey-light ml-2"
                >({{ momentAgo(tokenToView?.endDateTime) }})</span
              ></span
            >
          </div>
          <div v-if="tokenToView?.utilizedOn" class="column is-3">
            <span class="sm-label">Token utilized on : </span>
          </div>
          <div v-if="tokenToView?.utilizedOn" class="column is-9">
            <span class="sm-value">
              {{ formatDateWithYear(tokenToView.utilizedOn)
              }}<span class="has-text-grey-light ml-2"
                >({{ momentAgo(tokenToView?.utilizedOn) }})</span
              >
            </span>
          </div>
          <div v-if="tokenToView?.profileCreated" class="column is-3">
            <span class="sm-label">Profile Created: </span>
          </div>
          <div v-if="tokenToView?.profileCreated" class="column is-9">
            <span class="sm-value">
              <router-link
                v-if="tokenToView.profileCreated?.id"
                target="_blank"
                class="no-text-decoration"
                :to="{
                  name: 'profile',
                  params: { uid: tokenToView.profileCreated?.id },
                }"
              >
                <span>{{ tokenToView.profileCreated?.displayName }}</span>
              </router-link>
            </span>
          </div>
        </div>
      </SynPanel>
    </full-page>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import SynPanel from '@/components/SynPanel'
import FullPage from '@/components/partials/FullPage.vue'
import {
  showLabelsFromCode,
  momentAgo,
  formatDate,
  formatDateWithYear,
} from '@/misc/helpers'

export default {
  name: 'ViewToken',
  components: { SynPanel, FullPage },
  mixins: [],
  data() {
    return {
      panelTitle: `${showLabelsFromCode('company/tokens/token')}`,
      isLoading: true,
      errorMessage: '',
    }
  },

  computed: {
    ...mapState('company', ['companyTokenToView']),
    ...mapState('tokens', ['tokenToView']),
    ...mapGetters('user', [
      'getTinyUser',
      'getAuthUserId',
      'getAuthUserCompanyId',
    ]),
  },
  created() {
    this.fetchToken()
  },
  mounted() {
    this.isLoading = false
  },

  methods: {
    ...mapActions('tokens', ['fetchTokenById']),
    ...mapMutations('tokens', ['setTokenToView']),
    momentAgo,
    formatDate,
    showLabelsFromCode,
    formatDateWithYear,
    fetchToken() {
      this.fetchTokenById(this.companyTokenToView.id)
        .then((tokenResponse) => {
          this.setTokenToView(tokenResponse)
        })
        .catch((err) => {
          this.errorMessage = `Error in fetching the token ${err.message}`
        })
    },
  },
}
</script>

<style lang="scss">
.view-token {
}
</style>
