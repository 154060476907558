<template>
  <div class="view-books">
    <div class="view-books__heading view-books__heading--warning">
      <span class="view-books__heading--emoji">📖</span
      ><span class="view-books__heading--title">{{
        showLabelsFromCode('profile/leisures/currentlyreading')
      }}</span>
      <b-button
        tag="router-link"
        :to="{
          name: 'listbooks',
          params: {
            uid: profileUser.id,
            mode: 'view',
            fromScreen: 'viewprofile',
            books: books,
          },
        }"
        class="view-books__heading--button"
        size="is-small"
        icon-left="star"
        pack="fas"
        type="is-ghost"
        >{{ showLabelsFromCode('generic/ratings') }}</b-button
      >
    </div>
    <skeleton v-if="isLoading"></skeleton>
    <div
      v-else
      class="columns is-mobile is-multiline is-vcentered is-narrow is-gapless mt-2"
    >
      <div v-for="(book, index) in books" :key="book.id" class="column is-3">
        <a
          class="view-books__link"
          :href="
            book.link
              ? `${book.link}/&ref_=as_li_ss_tl&tag=${amazonTagKey}`
              : '#'
          "
          :target="book.link ? '_blank' : '_self'"
          @click="
            trackConversionEvent(
              'book_clicked',
              `${book.title} by ${book.author}`,
              book.link,
              `User ${user.id} clicked book ${index + 1} of ${books.length} `,
            )
          "
        >
          <img
            class="view-books__coverimage border-color--warning"
            :src="book.coverImagePath"
            :alt="`${book.title} by ${book.author}`"
            :title="`${book.title} by ${book.author}`"
            loading="lazy"
            @error="setAltImg"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { viewProfileMixin } from '@/mixins/profile/index'

export default {
  name: 'ViewBooks',
  mixins: [viewProfileMixin],
  props: {
    books: Object,
  },
  data() {
    return {
      amazonTagKey: process.env.VUE_APP_AMAZON_TAGKEY,
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters('leisures', ['getAllBooksFromLeisures']),
  },
  mounted() {
    this.isLoading = false
  },
}
</script>
