export default {
  getProfileUserCompanyId: (state) => state?.profileUser?.companyId || null,

  getProfileUserGenericAvatar(state) {
    const name = state?.profileUser?.displayName || 'SM'
    const imgSrc = `https://ui-avatars.com/api/?name=${encodeURIComponent(
      name,
    )}&background=FFFFFF&color=48beb4&size=64&font-size=0.5&bold=true`
    return imgSrc
  },
}
