export default {
  setTeamSummaries: (state, teamSummaries) =>
    (state.teamSummaries = teamSummaries),

  addToTeamSummary: (state, teamSummary) =>
    state.teamSummaries.push(teamSummary),

  setTeamSummaryUpdate(state, teamSummary) {
    const index = state.teamSummaries.findIndex(
      (item) => item.id === teamSummary.id,
    )
    if (index !== -1) {
      state.teamSummaries[index].summaryText = teamSummary.summaryText
      state.teamSummaries[index].lastUpdatedBy = teamSummary.lastUpdatedBy
      state.teamSummaries[index].updateTimestamp = new Date()
    }
  },

  setTeamSummaryToRemove(state, teamSummaryId) {
    const index = state.teamSummaries.findIndex(
      (item) => item.id === teamSummaryId,
    )
    if (index !== -1) {
      state.teamSummaries.splice(index, 1)
    }
  },

  setTeamSummaryToView: (state, teamSummaryToView) => {
    //  console.log(JSON.stringify(teamSummaryToView))
    state.teamSummaryToView = teamSummaryToView
  },

  setTeamSummaryToEdit: (state, teamSummaryToEdit) =>
    (state.teamSummaryToEdit = teamSummaryToEdit),
}
