<template>
  <section class="card-footer pvfooter">
    <div class="card-footer-item pvfooter__wrapper">
      <span class="pvfooter__wrapper--label info__label"
        >{{ showLabelsFromCode('profile/edit/panelVisibility') }}
        <b-tooltip
          multilined
          type="is-dark"
          :label="showTooltipsFromCode('profile/edit/panelvisibility')"
          position="is-right"
          ><b-icon
            v-if="showEditPanelVisibilityButton"
            class="has-text-grey-light"
            pack="far"
            icon="question-circle"
            size="is-small"
          >
          </b-icon
        ></b-tooltip>
        :
      </span>
      <template v-if="isEnterpriseUser">
        <span
          v-if="companyPanelsConfig[pvname]"
          class="pvfooter__wrapper--value info__value"
          >{{
            getVisibilityPreferenceName(Number(companyPanelsConfig[pvname]))
          }}</span
        >
        <span v-else class="pvfooter__wrapper--value info__value">{{
          getVisibilityPreferenceName(Number(pvvalue))
        }}</span>
      </template>
      <template v-else>
        <span class="pvfooter__wrapper--value info__value">{{
          getVisibilityPreferenceName(Number(pvvalue))
        }}</span>
      </template>
      <template v-if="showEditPanelVisibilityButton">
        <b-button
          pack="fas"
          icon-left="eye"
          type="is-danger"
          class="editbutton pvfooter__wrapper--editbutton"
          title="Edit Panel Visibility"
          @click.stop.prevent="openEditPanelVisibility()"
        >
          {{ showLabelsFromCode('generic/edit') }}
          {{ showLabelsFromCode('profile/edit/panelVisibility') }}</b-button
        >
      </template>
      <template v-else>
        <b-button
          type="is-primary"
          class="editbutton pvfooter__wrapper--helpbutton"
          title="Help"
          icon-pack="far"
          icon-left="question-circle"
          @click="isModalActive = true"
        >
          {{ showLabelsFromCode('generic/help') }}
        </b-button>
        <b-modal
          v-model="isModalActive"
          :width="640"
          scroll="keep"
          has-modal-card
          can-cancel
          aria-modal
          aria-label="Help / Information"
          close-button-aria-label="Close"
        >
          <div class="card">
            <div class="card-content">
              <div class="content">
                <b-notification :closable="false" type="is-danger">
                  {{ showUserMessageFromCode('panelvisibility/help/intro') }}
                  <br />
                  {{
                    showUserMessageFromCode('panelvisibility/help/entlicense')
                  }}
                  <ul class="mt-2">
                    <li>
                      {{
                        showUserMessageFromCode(
                          'panelvisibility/help/ent/item1',
                        )
                      }}
                    </li>
                    <li>
                      {{
                        showUserMessageFromCode(
                          'panelvisibility/help/ent/item2',
                        )
                      }}
                    </li>
                    <li>
                      {{
                        showUserMessageFromCode(
                          'panelvisibility/help/ent/item3',
                        )
                      }}
                    </li>
                  </ul>
                </b-notification>
              </div>
            </div>
          </div>
        </b-modal>
      </template>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { visibilityPreferences } from '@/data/visibilityPreferences'
import {
  showLabelsFromCode,
  showUserMessageFromCode,
  showTooltipsFromCode,
  getNameForId,
} from '@/misc/helpers'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'
import AddEditPanelVisibility from '@/components/AddEditPanelVisibility'

export default {
  name: 'PVFooter',
  props: {
    pvvalue: {
      type: Number,
      default: 93,
    },
    pvname: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      visibilityPreferences,
      showEditPanelVisibilityButton: null,
      isModalActive: false,
    }
  },
  computed: {
    ...mapState('company', ['companyPanelsConfig']),
    ...mapState('appconfig', ['profileUserPanelsConfig']),
    ...mapGetters('user', ['isEnterpriseUser', 'isPaidUser', 'isGuestUser']),
  },
  watch: {
    companyPanelsConfig: {
      handler(companyPanelsConfig) {
        if (companyPanelsConfig && companyPanelsConfig.id) {
          this.checkIfSetByCompany()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.isEnterpriseUser) {
      this.checkIfSetByCompany()
    } else {
      this.showEditPanelVisibilityButton = true
    }
  },
  methods: {
    isEmpty,
    checkIfSetByCompany() {
      if (this.companyPanelsConfig[this.pvname] === null) {
        this.showEditPanelVisibilityButton = true
      } else {
        this.showEditPanelVisibilityButton = false
      }
    },

    openEditPanelVisibility() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          panelname: this.pvname,
          panelvalue: this.pvvalue,
        },
        hasModalCard: true,
        component: AddEditPanelVisibility,
        events: {
          update_successful: () => {
            handleSuccessMessage(
              showUserMessageFromCode('panelvisibility/update/success'),
            )
          },
          update_failed: () => {
            handleErrorMessage(
              showUserMessageFromCode('panelvisibility/update/error'),
            )
          },
        },
        trapFocus: true,
      })
    },
    getVisibilityPreferenceName(id) {
      return this.getNameForId(id, this.visibilityPreferences)
    },
    getNameForId,
    showLabelsFromCode,
    showUserMessageFromCode,
    showTooltipsFromCode,
  },
}
</script>
<style lang="scss">
.pvfooter {
  border-top-color: $grey-label !important;
  background-color: $grey-background;
  &__wrapper {
    &--label {
      color: $body-color !important;
    }
    &--value {
      margin-left: $min-size;
    }
    &--helpbutton {
      padding: $base-size !important;
      background-color: transparent !important;
      color: $primary !important;
    }
  }
}
</style>
