export default {
  // /* set boolean */
  setAllActiveUserTeams: (state, activeUserTeams) =>
    (state.activeUserTeams = activeUserTeams),
  setAllArchivedUserTeams: (state, archivedUserTeams) =>
    (state.archivedUserTeams = archivedUserTeams),
  setAllUserTeams: (state, allUserTeams) => (state.allUserTeams = allUserTeams),
  setUserTeamsConfig: (state, userTeamsConfig) =>
    (state.userTeamsConfig = userTeamsConfig),
}
