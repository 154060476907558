<!-- eslint-disable vue/no-v-html -->
<template>
  <section v-cloak class="view-booking-page">
    <div class="container view-booking-page__container">
      <b-loading v-model="isLoading" :is-full-page="true">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon
      ></b-loading>

      <template
        v-if="Object.keys(bookingErrors).some((key) => bookingErrors[key])"
      >
        <ViewError
          :title="errorTitle"
          :description="errorDescription"
        ></ViewError>
      </template>
      <template v-else>
        <MeetingTitle
          :booking-organizer="bookingOrganizer"
          :booking-record="schedulingPageToView"
        ></MeetingTitle>
        <MeetingDetails :booking-record="schedulingPageToView"></MeetingDetails>
        <MeetingEmbed
          :page-name="schedulingPageToView?.pageName"
          :page-slug="schedulingPageToView?.pageSlug"
        ></MeetingEmbed>
      </template>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapMutations, mapActions } from 'vuex'
import MeetingEmbed from '@/views/scheduling/partials/MeetingEmbed'
import MeetingTitle from '@/views/scheduling/partials/MeetingTitle'
import MeetingDetails from '@/views/scheduling/partials/MeetingDetails'
import { viewSchedulerMixin } from '@/mixins/scheduler/index'
import ViewError from '@/views/errors/ViewError'

import {
  companyColorPersonalization,
  destroyColorPersonalization,
} from '@/misc/helpers'

export default {
  name: 'ViewBookingPagePublic',
  components: { MeetingEmbed, ViewError, MeetingDetails, MeetingTitle },
  mixins: [viewSchedulerMixin],
  props: {
    uid: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      panelTitle: ``,
      isLoading: true,
      title: false,
      errorTitle: this.showUserMessageFromCode('schedulingpage/notfound/title'),
      errorDescription: this.showUserMessageFromCode(
        'schedulingpage/notfound/description',
      ),
      bookingOrganizer: {},
    }
  },
  metaInfo() {
    return {
      title: this.bookingOrganizer
        ? `${this.bookingOrganizer.displayName}'s Scheduling Page for ${this.schedulingPageToView?.pageName}`
        : 'Scheduling Page',
      meta: [
        {
          property: 'og:url',
          content: window.location.href,
          vmid: 'og:url',
        },
        {
          property: 'og:title',
          content: this.bookingOrganizer
            ? `${this.bookingOrganizer.displayName}'s Scheduling Page for ${this.schedulingPageToView?.pageName}`
            : 'Scheduling Page',
        },
        {
          name: 'description',
          content: `${
            this.bookingOrganizer
              ? `Connect with ${this.bookingOrganizer.displayName} on ${this.appTitle}.`
              : this.appTitle
          }`,
        },
        {
          property: 'og:description',
          content: `${
            this.bookingOrganizer
              ? `Connect with ${this.bookingOrganizer.displayName} on ${this.appTitle}.`
              : this.appTitle
          }`,
        },
      ],
    }
  },
  computed: {
    ...mapState('scheduling', ['schedulingPageToView', 'bookingErrors']),
  },
  async created() {
    if (this.$route.params.uid) {
      await this.fetchUserByUserId(this.$route.params.uid)
        .then((profileUserResponse) => {
          // console.log(JSON.stringify(profileUserResponse))
          if (profileUserResponse?.disabled) {
            this.$router
              .push({
                name: 'profile',
                params: { uid: this.$route.params.uid },
              })
              .catch(() => {})
          } else if (!profileUserResponse?.isCalendarIntegrated) {
            this.setProfileHasNoCalendarIntegratedError(true)
            this.errorTitle = this.showUserMessageFromCode(
              'schedulingpage/notfound/title',
            )
            this.errorDescription = this.showUserMessageFromCode(
              'schedulingpage/notfound/description',
            )
          } else {
            this.bookingOrganizer = profileUserResponse
          }
        })
        .catch((error) => {
          console.log(error)
          this.errorTitle = this.showUserMessageFromCode(
            'schedulingpage/notfound/title',
          )
          this.errorDescription = this.showUserMessageFromCode(
            'schedulingpage/notfound/description',
          )
          console.error(
            JSON.stringify(
              `Error in fetching booking with Id ${this.id} was not found`,
            ),
          )
          this.setBookingNotFoundError(true)
        })
    }

    await this.fetchUserSchedulingPageById({
      userId: this.$route.params.uid,
      id: this.$route.params.id,
    })
      .then((bookingResponse) => {
        if (bookingResponse) {
          // console.log(JSON.stringify(bookingResponse))
          if (
            bookingResponse?.expirationDate &&
            Date.now() >= bookingResponse?.expirationDate
          ) {
            this.setBookingExpiredError(true)
          } else if (
            bookingResponse?.pageVisibilityType !==
            this.schedulerVisibilityConstants.PUBLIC
          ) {
            this.setBookingNotFoundError(true)
          } else {
            this.setSchedulingPageToView(bookingResponse)
          }
        } else {
          this.setBookingNotFoundError(true)
        }
      })
      .catch((error) => {
        console.log(error)
        console.error(
          JSON.stringify(
            `Error in fetching booking with Id ${this.id} was not found`,
          ),
        )
        this.setBookingNotFoundError(true)
      })
  },
  mounted() {
    this.isLoading = false
    setTimeout(() => {
      if (
        this.schedulingPageToView?.backgroundColor &&
        !Object.keys(this.bookingErrors).some((key) => this.bookingErrors[key])
      ) {
        companyColorPersonalization(this.schedulingPageToView.backgroundColor)
      } else {
        companyColorPersonalization('#f2f6fa')
      }
      this.$buefy.toast.open({
        duration: 5000,
        message: `💡 Based on your availability please select a date & time for the meeting`,
        type: 'is-info',
        position: 'is-bottom',
        pauseOnHover: true,
      })
    }, '500')
  },
  destroyed() {
    destroyColorPersonalization()
  },
  methods: {
    ...mapMutations('scheduling', [
      'setSchedulingPageToView',
      'setProfileHasNoCalendarIntegratedError',
      'setBookingNotFoundError',
      'setBookingExpiredError',
    ]),
    ...mapActions('scheduling', ['fetchUserSchedulingPageById']),
    ...mapActions('user', ['fetchUserByUserId']),
  },
}
</script>
