<template>
  <section v-cloak class="company-event">
    <full-page
      max-width="is-max-widescreen"
      width-class="is-10"
      class="view-company-event"
    >
      <b-loading v-model="isLoading" :is-full-page="true">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon
      ></b-loading>

      <template v-if="!isLoading">
        <template v-if="canUserView">
          <CompanyEventInfo></CompanyEventInfo>
          <CompanyEventLocation></CompanyEventLocation>
          <CompanyEventAdditionalInfo></CompanyEventAdditionalInfo>
          <template
            v-if="
              companyEventToView?.eventStatus ===
              companyEventStatusConstants.SCHEDULED
            "
          >
            <CompanyNetworkAttendees v-if="user"></CompanyNetworkAttendees>
            <CompanyAnalytics
              v-if="
                isUserCompanyAdmin ||
                companyEventToView?.createdBy?.id === getAuthUserId
              "
            ></CompanyAnalytics>
          </template>
          <CompanyEventAttachments></CompanyEventAttachments>
        </template>
        <ViewError
          v-if="companyErrors.unknown"
          :title="errorTitle"
          :description="errorDescription"
        ></ViewError>
        <ViewError
          v-else-if="companyErrors.companyEventNotFound"
          :title="errorTitle"
          :description="errorDescription"
        ></ViewError>
        <ViewError v-else-if="companyEventToView && !canUserView"></ViewError>
      </template>
    </full-page>
    <div
      v-if="companyEventToView && companyEventToView.eventName"
      class="watermark"
    >
      <span>
        <b-icon pack="fas" icon="calendar-day" size="is-small"> </b-icon>
        {{ companyEventToView.eventName }}</span
      >
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapActions, mapMutations } from 'vuex'
import { viewCompanyMixin } from '@/mixins/company/index'
import { trackPageView, trackScreenView } from '@/misc/analytics'
import {
  companyColorPersonalization,
  destroyColorPersonalization,
} from '@/misc/helpers'

import ViewError from '@/views/errors/ViewError'
import CompanyEventInfo from './partials/CompanyEventInfo'
import CompanyEventLocation from './partials/CompanyEventLocation'
import CompanyNetworkAttendees from './partials/CompanyNetworkAttendees'
import CompanyEventAdditionalInfo from './partials/CompanyEventAdditionalInfo'
import CompanyEventAttachments from './partials/CompanyEventAttachments'
import CompanyAnalytics from './partials/CompanyAnalytics'
import { companyEventVisibilityConstants } from '@/data/company/company-event-visibility'
// import NetworkStatuses from '@/data/networkStatus'

import { companyEventStatusConstants } from '@/data/company/company-event-status'

export default {
  name: 'ViewCompanyEvent',
  components: {
    ViewError,
    CompanyEventInfo,
    CompanyEventLocation,
    CompanyNetworkAttendees,
    CompanyEventAdditionalInfo,
    CompanyEventAttachments,
    CompanyAnalytics,
  },
  mixins: [viewCompanyMixin],
  props: {
    id: {
      type: String,
      default: null,
    },
    companyid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,

      errorTitle: this.showUserMessageFromCode('company/event/notfound/title'),
      errorDescription: this.showUserMessageFromCode(
        'company/event/notfound/description',
      ),
      companyEventVisibilityConstants,
      // NetworkStatuses,
      companyEventStatusConstants,
    }
  },
  metaInfo() {
    return {
      title: 'Company Event',
      meta: [
        {
          property: 'og:url',
          content: `${
            this.$route.params.id
              ? `${this.appURL}view/company/${this.$route.params.companyid}/event/${this.$route.params.id}`
              : this.appURL
          }`,
          vmid: 'og:url',
        },
        {
          property: 'og:title',
          content: `${
            this.companyEventToView
              ? `Company Wide Event - ${this.companyEventToView.eventName}`
              : `See ${this.appTitle}`
          }`,
        },
        {
          name: 'description',
          content: `${
            this.companyEventToView && this.companyEventToView.eventDescription
              ? this.companyEventToView.eventDescription
              : ''
          }`,
        },
        {
          property: 'og:description',
          content: `${
            this.companyEventToView && this.companyEventToView.eventDescription
              ? this.companyEventToView.eventDescription
              : ''
          }`,
        },
        {
          property: 'og:image',
          content: `${
            this.companyEventToView ? this.companyEventToView.eventImage : ''
          }`,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: `${
            this.$route.params.uid
              ? `${this.appURL}view/company/${this.$route.params.companyid}/event/${this.$route.params.id}`
              : ''
          }`,
        },
      ],
      afterNavigation(metaInfo) {
        trackPageView()
        trackScreenView(metaInfo.title)
      },
    }
  },
  computed: {
    ...mapState('company', [
      'companyNetwork',
      'isUserInSameCompany',
      'isUserCompanyAdmin',
    ]),
    ...mapState('networkStatus', ['network']),
    canUserView() {
      if (this.companyEventToView) {
        return (
          this.companyEventToView?.eventVisibility ===
            this.companyEventVisibilityConstants.PUBLIC ||
          this.companyEventToView?.companyId === this.user?.companyId
        )
      }
      return false
    },
  },
  watch: {
    companyToView: {
      handler(companyToView) {
        if (companyToView) {
          this.fetchCompanyEventByIdVue()
        }
      },
      deep: true,
      immediate: true,
    },
    user: {
      handler(user) {
        if (user && this.companyToView) {
          if (this.user?.companyId === this.companyToView?.id) {
            this.fetchCompanyAdminsByCompanyIdVue(this.companyToView?.id)
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fetchCompanyById()
  },
  mounted() {
    this.isLoading = false
    setTimeout(() => {
      if (
        this.companyEventToView?.eventStatus ===
        this.companyEventStatusConstants.DRAFT
      ) {
        this.$buefy.toast.open({
          duration: 5000,
          message: `💡 This event is not yet published. Kindly contact the company administrator to approve and publish it.`,
          type: 'is-info',
          position: 'is-bottom',
          pauseOnHover: true,
        })
      }
    }, '500')
  },
  updated() {
    this.$nextTick(() => {
      if (
        this.companyEventToView &&
        this.companyEventToView.eventStatus ===
          this.companyEventStatusConstants.CANCELLED
      ) {
        setTimeout(() => {
          companyColorPersonalization('#f5f5f5')
        }, '500')
      } else if (this.companyToView) {
        setTimeout(() => {
          companyColorPersonalization(this.companyToView.bannerColor)
        }, '500')
      }
    })
  },
  destroyed() {
    destroyColorPersonalization()
    this.setCompanyEventToView(null)
  },
  methods: {
    ...mapActions('company', [
      'fetchCompanyByCompanyId',
      'fetchCompanyAdminsByCompanyId',
      'fetchCompanyLocationsByCompanyId',
      'fetchCompanyEventsById',
    ]),
    ...mapMutations('company', [
      'setCompanyToView',
      'setCompanyAdmins',
      'setCompanyEventToView',
      'setCompanyNotFound',
      'setIsUserCompanyAdmin',
      'setIsUserInSameCompany',
      'setCompanyAdminsNotFound',
      'setCompanyUnknownError',
      'setCompanyEventNotFound',
    ]),
    async fetchCompanyById() {
      await this.fetchCompanyByCompanyId(this.companyid)
        .then((companyResponse) => {
          // console.log(JSON.stringify(companyResponse))
          if (!this.isEmpty(companyResponse)) {
            this.setCompanyToView(companyResponse)
            // console.log(JSON.stringify(this.user.companyId))
            // console.log(JSON.stringify(this.companyid))
            if (this.getAuthUserCompanyId === this.companyid) {
              // console.log(JSON.stringify('user is in same company'))
              this.setIsUserInSameCompany(true)
            }
          } else {
            this.errorTitle = this.showUserMessageFromCode(
              'company/event/notfound/title',
            )
            this.errorDescription = this.showUserMessageFromCode(
              'company/event/notfound/description',
            )
            this.setCompanyEventNotFound(true)
          }
        })
        .catch((error) => {
          console.log(error)

          this.setCompanyEventNotFound(true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async fetchCompanyEventByIdVue() {
      await this.fetchCompanyEventsById(this.id)
        .then((companyEventResponse) => {
          if (!this.isEmpty(companyEventResponse)) {
            this.setCompanyEventToView(companyEventResponse)
          } else {
            this.errorTitle = this.showUserMessageFromCode(
              'company/event/notfound/title',
            )
            this.errorDescription = this.showUserMessageFromCode(
              'company/event/notfound/description',
            )
            this.setCompanyEventNotFound(true)
          }
        })
        .catch((error) => {
          console.log(error)
          this.setCompanyEventNotFound(true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async fetchCompanyAdminsByCompanyIdVue(companyId) {
      await this.fetchCompanyAdminsByCompanyId(companyId)
        .then((companyAdminsResponse) => {
          // console.log(JSON.stringify(companyAdminsResponse))
          if (!this.isEmpty(companyAdminsResponse)) {
            this.setCompanyAdmins(companyAdminsResponse)
            const isCompanyAdmin = companyAdminsResponse.some(
              (admin) => admin.id === this.getAuthUserId,
            )

            this.setIsUserCompanyAdmin(
              isCompanyAdmin &&
                this.getAuthUserCompanyId === this.companyid &&
                this.getAuthUserRole === 'COADMIN',
            )
          } else {
            this.errorTitle = 'Company Admins not found'
            this.errorDescription =
              'No Company Admins were set when adding the company. Please contact customer care or your Account Manager'
            this.setCompanyAdmins(undefined)
            this.setIsUserCompanyAdmin(false)
            this.setCompanyAdminsNotFound(true)
          }
        })
        .catch((error) => {
          console.log(error)
          this.errorTitle = 'Company Admins not found'
          this.errorDescription =
            'No Company Admins were set when adding the company. Please contact customer care or your Account Manager'
          this.setCompanyAdmins(undefined)
          this.setIsUserCompanyAdmin(false)
          this.setCompanyAdminsNotFound(true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss"></style>
