<template>
  <div class="full-page">
    <div class="hero">
      <div class="hero-body">
        <div :class="['container', maxWidth]">
          <div class="columns is-centered">
            <div :class="['column', widthClass]">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullPage',
  components: {},
  props: {
    widthClass: {
      type: String,
      default: 'is-8',
    },
    maxWidth: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.full-page {
  .hero {
    .hero-body {
      padding: 0 !important;
    }
  }
}
</style>
