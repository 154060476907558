/* eslint-disable no-unused-vars */
import isNil from 'lodash/isNil'

import AppMasterConfigDB from '@/firebase/app/app-master-config-db'

export default {
  /**
   * Closes "add to home screen" modal for apple
   */
  closeAddToHomeScreenModalForApple: async ({ commit }) => {
    localStorage.setItem('addToHomeIosPromptLastDate', Date.now())
    commit('setShowAddToHomeScreenModalForApple', false)
  },

  /**
   * Trigger service worker skipWating so the new service worker can take over.
   * This will also trigger a window refresh (see /src/misc/register-service-worker.js)
   */
  serviceWorkerSkipWaiting({ state, commit }) {
    if (isNil(state.SWRegistrationForNewContent)) return

    commit('setRefreshingApp', true)
    state.SWRegistrationForNewContent.waiting.postMessage('skipWaiting')
  },

  fetchAppMasterConfigs: async ({ commit }, payload) => {
    const appMasterConfigs = new AppMasterConfigDB(payload.configName)
    return appMasterConfigs.readAll()
  },
  fetchAppMasterConfigById: async ({ commit }, payload) => {
    const appMasterConfigs = new AppMasterConfigDB(payload.configName)
    return appMasterConfigs.read(payload.id)
  },
}

/* These are my app actions available through out the app */
