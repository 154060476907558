<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="view-error">
    <div class="center-of-page">
      <div class="card view-error__card is-family-monospace">
        <header class="card-header view-error__card--header">
          <h3 class="view-error__card--header--title">⚠️{{ title }}</h3>
        </header>
        <div class="card-content view-error__card--content">
          <span v-html="description"></span>
          <slot />
        </div>

        <footer v-if="showFooter" class="card-footer view-error__card--footer">
          <span class="view-error__card--footer--item">
            <span class="view-error__card--content--contact has-text-left">
              For additional help please contact
              <a
                class="view-error__footer--link"
                target="_blank"
                href="/contactus/bug"
              >
                Customer Care</a
              >
            </span>
          </span>
          <span class="view-error__card--footer--item">
            <span class="is-pulled-right">
              <b-button
                icon-left="home"
                icon-pack="fas"
                size="is-small"
                type="is-primary"
                tag="router-link"
                to="/home"
                >HOME</b-button
              >
            </span>
          </span>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ViewError',
  components: {},
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    backRoute: {
      type: String,
      default: '',
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultBGColor: process.env.VUE_APP_BODY_BGCOLOR,
    }
  },
  computed: {},
  destroyed() {
    document.body.style.backgroundColor = this.defaultBGColor
  },
  mounted() {
    document.body.style.backgroundColor = '#ea4d46'
  },
  methods: {
    goBack() {
      this.$router.back()
    },
  },
}
</script>
<style lang="scss">
.view-error {
  &__card {
    max-width: $tablet;
    padding: $base-size;
    margin: 0 auto;
    &--header {
      box-shadow: none;
      border-bottom: 1px solid $grey-lighter;
      padding-bottom: $base-size;
      &--title {
        @include sm-title();
        font-size: $base-size * 1.5;
        text-align: center !important;
        display: inline-block;
        margin: 0 auto;
      }
    }
    &--content {
      border-radius: $box-radius !important;
      margin: $base-size 0;
      background-color: $danger-light;
      font-size: 1.25rem;
      &--contact {
        text-align: center !important;
        margin: $base-size 0;
      }
    }
    &--footer {
      padding-top: $base-size;
      border-top: 1px solid $grey-lighter;
      text-align: right;
      .button {
        border-radius: $box-radius !important;
      }
      &--item {
        flex-grow: 1;
      }
    }
  }
}
</style>
