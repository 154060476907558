var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"view-company-configs"},[(_vm.isLoading)?_c('skeleton'):_vm._e(),(!_vm.isLoading)?_c('SiynCollapse',{attrs:{"title":_vm.panelTitle,"emoji":"⚙️","is-open":_vm.collapseHelper(_vm.panelname),"panelname":_vm.panelname},scopedSlots:_vm._u([{key:"header-extras",fn:function(){return [_c('b-tooltip',{staticClass:"mx-4",attrs:{"multilined":"","type":"is-dark","label":"Controls the visibility of each panel on a user's profile. Only visible to employees of the company. ","position":"is-right"}},[_c('b-icon',{staticClass:"has-text-grey-lighter",attrs:{"pack":"fas","icon":"info-circle","size":"is-small"}})],1)]},proxy:true},{key:"default",fn:function(){return [(_vm.companyPanelsConfig)?_c('div',{staticClass:"columns is-multiline is-variable is-1 mt-2"},[_vm._l((_vm.companyPanelsConfig),function(value,propertyName){return [(
              propertyName !== 'id' &&
              propertyName !== 'isPublicallyVisible' &&
              propertyName !== 'updateTimestamp' &&
              propertyName !== 'createTimestamp' &&
              propertyName !== 'lastUpdatedBy'
            )?[_c('div',{key:propertyName,staticClass:"column is-6"},[_c('span',{staticClass:"sm-label mr-2"},[_vm._v(_vm._s(_vm.convertPanelName(propertyName))+" Panel : ")]),_c('span',{staticClass:"sm-value"},[_vm._v(" "+_vm._s(_vm.getVisibilityPreferenceName(Number(value))))])])]:_vm._e(),(propertyName === 'isPublicallyVisible')?[_c('div',{key:propertyName,staticClass:"column is-6"},[_c('span',{staticClass:"sm-label mr-2"},[_vm._v("Are user profiles visible for users outside of organization? ")]),_c('span',{staticClass:"sm-value"},[_vm._v(_vm._s(value === true ? 'Yes' : 'No'))])])]:_vm._e()]})],2):_vm._e(),(_vm.isEmpty(_vm.companyPanelsConfig))?_c('span',{staticClass:"ml-4 empty-content"},[_vm._v(_vm._s(_vm.showUserMessageFromCode('generic/none'))+". Please contact your company admin ASAP")]):_vm._e(),(_vm.isUserCompanyAdmin || _vm.superAdmin === _vm.getAuthUserId)?_c('div',{staticClass:"has-text-center-touch has-text-right mt-4"},[(_vm.companyPanelsConfig)?_c('b-button',{staticClass:"view-company-configs__editbutton ml-2 background-color-override",attrs:{"type":"is-danger","icon-left":"edit","pack":"far","title":"Edit Company Configurations"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.gotoEditCompanyConfig()}}},[_vm._v("Edit Company Configurations ")]):_vm._e()],1):_vm._e()]},proxy:true}],null,false,2475148388)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }