/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */

import emailjs from '@emailjs/browser'
import UserNetworkDB from '@/firebase/user-network-db'

import { trackEngagementEvent, trackExceptionEvent } from '@/misc/analytics'

import {
  requestConnectionBatch,
  updateStatusBatch,
  removeConnectionBatch,
} from '@/store/users-collection/networkStatus/networkBatch'

export default {
  /* New Functions */
  fetchActiveNetworkByUserId: async ({ commit }, payload) => {
    const userNetworkDB = new UserNetworkDB(payload.userId)
    return userNetworkDB.readAll(payload.constraints) // when returning a Promise inside an async function, you don’t need to use await
  },

  /* -------------------------------------------------- */
  /*                Old Functions                       */
  /* -------------------------------------------------- */
  getNetworkStatusByUserId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    commit('setNetworkStatus', undefined)
    const userNetworkDB = new UserNetworkDB(payload.userId)
    const networkStatus = await userNetworkDB.read(payload.profileUserId)

    if (networkStatus) {
      console.log(JSON.stringify(networkStatus))
      return networkStatus
    }
    return null
  },

  cancelRejectUnblockNetworkRequest: async ({ commit }, payload) => {
    commit('SetNetworkUpdatePending', true)
    const userNetworkDB = new UserNetworkDB(payload.friendId)
    const myNetworkDB = new UserNetworkDB(payload.myId)

    await userNetworkDB.delete(payload.myId)
    await myNetworkDB.delete(payload.friendId)

    commit('SetNetworkUpdatePending', false)
    commit('setNetworkStatus', null)

    commit('removeNetworkByFriendId', payload.friendId)
  },
  // approveOrBlockNetworkRequest: async ({ commit }, payload) => {
  //   commit('SetNetworkUpdatePending', true)
  //   const networkRequest = {}
  //   const userNetworkDB = new UserNetworkDB(payload.friendId)
  //   const myNetworkRequest = {}
  //   const myNetworkDB = new UserNetworkDB(payload.myId)
  //   Promise.all([
  //     (networkRequest.id = payload.myId),
  //     (networkRequest.requestorId = payload.myId),
  //     (networkRequest.status = payload.status),
  //     await userNetworkDB.update(networkRequest),

  //     (myNetworkRequest.id = payload.friendId),
  //     (myNetworkRequest.requestorId = payload.myId),
  //     (myNetworkRequest.status = payload.status),
  //     await myNetworkDB.update(myNetworkRequest),
  //   ]).finally(() => {
  //     commit('SetNetworkUpdatePending', false)
  //     commit('setNetworkStatus', myNetworkRequest)
  //   })
  // },
  getNetworkById: async ({ commit }, payload) => {
    const userNetworkDB = new UserNetworkDB(payload.userId)
    const constraints = []
    constraints.push(['status', '==', payload.status])

    if (payload.requestorId) {
      constraints.push(['requestorId', '==', payload.requestorId])
    }

    return userNetworkDB.readAll(constraints)
  },
  updateSubscription: async ({ commit }, payload) => {
    const myNetworkDB = new UserNetworkDB(payload.userId)
    await myNetworkDB.update({
      id: payload.friendId,
      subscribed: payload.subscribed,
    })
  },

  requestConnectionAction: async ({ commit }, payload) => {
    return requestConnectionBatch(payload)
  },
  updateStatusAction: async ({ commit }, payload) => {
    return updateStatusBatch(payload)
  },
  removeConnectionAction: async ({ commit }, payload) => {
    return removeConnectionBatch(payload)
  },

  // removeConnectionByForce: async ({ commit }, payload) => {
  //   const userNetworkDB = new UserNetworkDB(payload.userId1)
  //   return userNetworkDB.delete(payload.userId2)
  // },
}
