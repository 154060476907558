<template>
  <div class="meeting-details">
    <div class="box mb-4">
      <div class="columns is-multiline is-gapless is-vcentered">
        <div v-if="bookingRecord?.agenda" class="column is-12 mb-2">
          <span class="sm-label"
            >{{ showLabelsFromCode('generic/agenda') }}:</span
          >
          <span class="sm-value">{{ bookingRecord?.agenda }}</span>
        </div>
        <div v-if="bookingRecord?.location" class="column is-6 is-narrow">
          <span class="sm-label"
            >{{ showLabelsFromCode('form/schedulingpages/location') }}:</span
          >
          <span class="sm-value">{{ bookingRecord?.location }}</span>
        </div>

        <div v-if="bookingRecord?.duration" class="column is-6 is-narrow">
          <span class="sm-label"
            >{{ showLabelsFromCode('scheduling/meetingduration') }}:</span
          >
          <span class="sm-value">{{ `${bookingRecord?.duration} mins` }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/extensions */

import { showLabelsFromCode } from '@/misc/helpers'

export default {
  name: 'MeetingDetails',
  components: {},
  props: {
    bookingRecord: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },

  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    showLabelsFromCode,
  },
}
</script>

<style scoped lang="scss"></style>
