<template>
  <section class="user-mentoring">
    <skeleton v-if="isLoading"></skeleton>
    <siyn-collapse
      v-if="(topics && topics.length > 0) || mentorCount > 0 || menteeCount > 0"
      :title="panelTitle"
      emoji="💪🏻"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <template #header-extras>
        <b-tooltip
          multilined
          class="mx-4"
          type="is-dark"
          :label="showLabelsFromCode('generic/onlyvisibletoemployees')"
          position="is-right"
          ><b-icon
            class="has-text-grey-lighter"
            pack="fas"
            icon="info-circle"
            size="is-small"
          >
          </b-icon
        ></b-tooltip>
      </template>
      <template #default>
        <div v-if="profileUser.openToMentoring && topics.length > 0">
          Provides mentoring on
          <span v-for="(item, index) in topics" :key="item.id">
            <a @click="openTopicsModal(item)">{{ item.topicTitle }}</a>
            <template v-if="index < topics.length - 1">, </template>
          </span>
        </div>
        <div v-if="mentorCount" class="my-2">
          <p>
            Has conducted
            <a @click="goToSessionsListAsMentor">{{ mentorCount }}</a>
            {{ mentorCount > 1 ? 'sessions' : 'session' }} as a mentor
            {{ mentorCount > 5 ? '👏👏' : '👏'
            }}{{ mentorCount > 10 ? '👏' : '' }}{{ mentorCount > 20 ? '👏' : ''
            }}{{ mentorCount > 30 ? '😇' : '' }}
          </p>
        </div>
        <div v-if="menteeCount" class="my-2">
          <p>
            Has attended
            <a @click="goToSessionsListAsMentee">{{ menteeCount }}</a>
            {{ menteeCount > 1 ? 'sessions' : 'session' }} as a mentee
            {{ menteeCount > 5 ? '🔥' : '' }}{{ menteeCount > 15 ? '🔥' : ''
            }}{{ menteeCount > 30 ? '🔥🔥' : ''
            }}{{ menteeCount > 50 ? '😇' : '' }}
          </p>
        </div>
      </template>
    </siyn-collapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapActions, mapMutations } from 'vuex'
import { viewProfileMixin } from '@/mixins/profile/index'
import ViewMentoringTopicModal from '@/views/mentoring/partials/ViewMentoringTopic'
import { mentoringSessionStatusConstants } from '@/data/mentoring/mentoring-session-status'

export default {
  name: 'ViewMentoring',
  components: {},
  mixins: [viewProfileMixin], // register mixin
  data() {
    return {
      panelTitle: this.showLabelsFromCode('profile/mentoring/panelTitle'),
      panelname: 'mentoring_view',
      isLoading: true,
      mentorCount: null,
      menteeCount: null,
      mentoringSessionStatusConstants,
    }
  },
  computed: {
    ...mapState('mentoring', [
      'topics',
      'mentoringsessions',
      'mentoringSessionsAsMentor',
      'mentoringSessionsAsMentee',
    ]),
  },
  created() {
    this.fetchUserMentoringTopicsVue()
    this.fetchMentoringSessionsAsMentor()
    this.fetchMentoringSessionsAsMentee()
  },
  mounted() {
    this.isLoading = false
    // console.log(JSON.stringify('view-mentoring-mounted'))
  },
  methods: {
    ...mapActions('mentoring', [
      'fetchMentoringTopicsByUserId',
      'deleteUserMentoringTopic',
      'fetchMentoringSessionByMentorId',
      'fetchMentoringSessionByMenteeId',
    ]),
    ...mapMutations('mentoring', [
      'setTopics',
      'setMentoringTopicToView',
      'setMentoringSessionsAsMentor',
      'setMentoringSessionsAsMentee',
    ]),

    async fetchUserMentoringTopicsVue() {
      this.isLoading = true
      await this.fetchMentoringTopicsByUserId(this.$route.params.uid)
        .then((topicsResponse) => {
          // console.log(JSON.stringify(topicsResponse))
          if (topicsResponse) {
            this.setTopics(topicsResponse)
          }
        })
        .catch((err) => {
          this.handleErrorMessage(
            `${this.showUserMessageFromCode(
              'generic/error-in-fetch',
            )} mentoring topics.`,
          )
          this.setTopics([])
          this.trackExceptionEvent(
            'mentoring-topics_fetch_failed',
            `${this.user.id}   tried to fetch mentoring topics for v. ${err}`,
            false,
          )
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },
    openTopicsModal(topic) {
      this.setMentoringTopicToView(topic)
      this.$buefy.modal.open({
        parent: this,
        component: ViewMentoringTopicModal,
        hasModalCard: true,
        trapFocus: true,
      })
    },
    async fetchMentoringSessionsAsMentor() {
      this.isLoading = true
      await this.fetchMentoringSessionByMentorId({
        companyId: this.profileUser?.companyId,
        mentorId: this.profileUser?.id,
        eventStatus: this.mentoringSessionStatusConstants.COMPLETED,
      })
        .then((sessionsResponse) => {
          if (sessionsResponse.length > 0) {
            this.mentorCount = sessionsResponse.length
            this.setMentoringSessionsAsMentor(sessionsResponse)
          }
        })
        .catch((err) => {
          this.handleErrorMessage(
            `${this.showUserMessageFromCode(
              'generic/error-in-fetch',
            )} mentoring sessions.`,
          )
          this.setTopics([])
          this.trackExceptionEvent(
            'mentoring-sessions_fetch_failed',
            `${this.user.id}   tried to fetch mentoring sessions as mentor. ${err}`,
            false,
          )
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },
    async fetchMentoringSessionsAsMentee() {
      this.isLoading = true
      await this.fetchMentoringSessionByMenteeId({
        companyId: this.profileUser?.companyId,
        menteeId: this.profileUser?.id,
        eventStatus: this.mentoringSessionStatusConstants.COMPLETED,
      })
        .then((sessionsMenteeResponse) => {
          if (sessionsMenteeResponse.length > 0) {
            this.menteeCount = sessionsMenteeResponse.length
            this.setMentoringSessionsAsMentee(sessionsMenteeResponse)
          }
        })
        .catch((err) => {
          if (err.code !== 'permission-denied') {
            this.handleErrorMessage(
              `${this.showUserMessageFromCode(
                'generic/error-in-fetch',
              )} mentoring sessions.`,
            )
          }
          this.setTopics([])
          this.trackExceptionEvent(
            'mentoring-sessions_fetch_failed',
            `${this.user.id}   tried to fetch mentoring sessions as mentee. ${err}`,
            false,
          )
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    },
    goToSessionsListAsMentor() {
      this.$router.push({
        name: 'mentoringsessionslist',
        params: {
          companyid: this.getAuthUserCompanyId,
          operation: 'MENTOR',
          sessions: this.mentoringSessionsAsMentor,
          uid: this.profileUser.id,
        },
      })
    },
    goToSessionsListAsMentee() {
      this.$router.push({
        name: 'mentoringsessionslist',
        params: {
          companyid: this.getAuthUserCompanyId,
          operation: 'MENTEE',
          sessions: this.mentoringSessionsAsMentee,
          uid: this.profileUser.id,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.media-content {
  ol,
  ul {
    padding: 0 $base-size;
  }
}
</style>
