export default {
  'generic/providecalaccess':
    'To auto create events in calendar please provide access to your calendar.',
  'generic/sendrequest': 'Send request to add new...',
  'generic/error-in-fetch':
    'Please try again; there was an error in fetching information about ',
  'generic/error-in-createUpdate': 'Error in updating the data for ',
  'generic/none': 'None',
  'generic/norecords': 'No Matching Records',
  'generic/preferencesaved/browser': '👍🏿 Preference saved',
  'generic/error/unknown/title': 'Unknown Error',
  'generic/error/unknown/description':
    'An unknown error occurred. Kindly review the URL and attempt the operation again.',
  'generic/addnewitem/thankyou':
    'Thank you for your request. We will carefully review your input before incorporating it.',
  'auth/account-exists':
    'It appears that an account with this information already exists. Please proceed to log in, or click on "Forgot Password" if needed.',
  'auth/check-verification-email':
    'Please click the link sent to your inbox for email verification. If the issue persists, you can <a href="/contactus/bug">contact Customer Care</a> for assistance.',
  'auth/verification-email-send':
    'Congratulations! Your registration has been successfully completed. An email containing a confirmation link has been sent to your provided email address. Kindly click on the link to finalize the registration process.',
  'auth/password-reset-success':
    'Password Reset Successful. Now redirecting you to login screen...',
  'auth/email-verification-success':
    'Email Verification successful, please login now to continue',
  'auth/email-unverified': 'Your email address is not yet verified',
  'auth/email-verified-no-data':
    'Login failed. Your email is verified but application failed to create you as a user. Please contact Customer Service',
  'auth/not-registered':
    'Login failed as you have not registered, please register your account',
  'auth/registration-success':
    'Congratulations! Your registration was successful, please login now to continue.',
  'auth/account-doesnot-exists':
    'Login failed. Your account does not exist please register first',
  'auth/invalid-action-code': 'Invalid verification link, please try again',
  'auth/fetch-network-failed':
    'Unknown error in fetching your network, please re-login.',
  'auth/login-success': '👍Successfully logged in!',

  'profile/notfound/title': 'Profile not found',
  'profile/notfound/description':
    "Apologies, we couldn't locate the profile you are searching for. Kindly verify the accuracy of the web address. Additionally, consider the possibility that the profile may no longer exist, was disabled, the license expired, or the user chose to mark their profile as private.",

  '/profile/profilebutton/connection/sent/success': 'Request sent successfully',
  '/profile/profilebutton/connection/reject/success':
    'Request rejected successfully',
  '/profile/profilebutton/connection/cancel/success':
    'Request cancelled successfully',
  '/profile/profilebutton/connection/block/success':
    'User blocked successfully',
  '/profile/profilebutton/connection/unblock/success':
    'Blocked removed successfully',
  '/profile/profilebutton/connection/approval/success':
    'Request approved successfully',
  '/profile/profilebutton/connection/removal/success':
    'Connection removed successfully',
  '/profile/profilebutton/connection/error': 'Error in updating network',

  'basicInfo/update/success': 'Basic Information was successfully updated',
  'basicInfo/update/error':
    'There was an unknown error in updating basic info, please try again or report a bug',

  'personalInfo/update/success':
    'Personal information was successfully updated',
  'personalInfo/update/error':
    'Error in update, please try again or report a bug',

  'private/contact/success': 'Contact information was successfully updated',
  'private/chat/success': 'Chat information was successfully updated',

  'contactPreferences/prefer': 'Prefers',
  'contactPreferences/medium': ' as a medium with internal colleagues and ',
  'contactPreferences/external': ' for external businesses.',
  'contactPreferences/update/success':
    'Contact preferences were successfully updated',

  'companyLocations/update/success':
    'Basic Information was successfully updated',
  'companyLocations/update/error':
    'There was an unknown error in updating basic info, please try again or report a bug',

  'form/generic/errorMessage':
    'Sorry the update failed due to unknown reason, please try again. If the issue persists please contact <a target = "_blank" href="/contactus/bug">Customer Service</a>.',
  'form/officeHours/redirectToEdit':
    'Please use Edit button on the Regular Office Hours panel',

  'officeHours/update/success': 'Office Hours were successfully updated',

  'events/update/success': 'Event was successfully updated',
  'events/update/error':
    'There was an unknown error in updating event, please try again or report a bug',
  'events/delete/success': 'Event was successfully deleted.',
  'events/delete/error': 'Error in deletion. Reason :',
  'events/add/success': 'Event was successfully added.',
  'events/addbulk/success':
    'Events were successfully added to the database, now updating your calendar...',
  'events/addbulk/noholidaysforcountry':
    'Sorry, no official holiday list is available for the country, please continue manually',
  'events/addbulk/error':
    'Error in adding events. Please try again or report a bug',
  'events/addbulk/error2':
    'Sorry! There was an error in auto-fetching holidays, please continue manually.',
  'events/add/error': 'Error in adding event. Reason :',
  'events/add/error2':
    'Error in adding event. please try again or report a bug',

  'links/update/success': 'Link was successfully updated',
  'links/update/error':
    'There was an unknown error in updating Links, please try again or report a bug',
  'links/delete/success': 'Link was successfully deleted.',
  'links/delete/error': 'Link in deletion. Reason :',
  'links/add/success': 'Links saved successfully! Taking you to edit screen...',
  'links/add/error': 'Error in adding link. Reason :',

  'leisures/update/success': ' was updated successfully.',
  'leisures/update/success2': ' were updated successfully',
  'leisures/update/error': ' update failed. Reason :',
  'leisures/delete/success': ' was deleted successfully.',
  'leisures/delete/error': ' delete failed. Reason :',
  'leisures/add/success': ' was added successfully. Do rate it once complete',
  'leisures/add/error': ' add failed. Reason :',
  'leisures/add/sportsteam/success': ' The team was added successfully.',

  'leisures/books/notfound': 'No matching books',
  'leisures/podcasts/notfound': 'No matching shows',
  'leisures/shows/notfound': 'No matching shows',
  'leisures/teams/notfound': 'No matching teams',
  'leisures/likes/notfound': 'No matching likes',

  'leisures/notfound/description':
    'Apologies, but we were unable to locate the leisure information. Please double-check the web address for accuracy. Additionally, consider the possibility that the profile may no longer exist, has been disabled, the license expired, or the user chose to mark their profile as private.',

  'generic/nomatchingresults': 'No matching results available',
  'generic/nonepleaseadd': 'None, please add ↗️',

  'comments/update/success': 'About was successfully updated',
  'comments/update/error':
    'An unknown error occurred while updating the "About" section. Please try the update again, and if the issue persists, consider reporting it as a bug. Thank you for your understanding.',
  'comments/delete/success': 'About was successfully deleted.',
  'comments/delete/error': 'Error in deleting About. Reason :',
  'comments/add/success': 'About added successfully!',
  'comments/add/error': 'Error in adding about. Reason :',

  'mentoring/update/error':
    'An unknown error occurred. Please try the operation again, and if the issue persists, consider reporting it as a bug. Thank you for your patience.',
  'mentoring/topic/add/success': 'Mentoring topic added successfully!',
  'mentoring/topic/add/error': 'Error in adding topic. Please try again',
  'mentoring/topic/update/success': 'Topic was successfully updated',
  'mentoring/topic/delete/success': 'Topic was successfully deleted',
  'mentoring/panelvisibility/message':
    'Only visible to colleagues of your organization',
  'mentoring/add/success': 'Available to mentor updated! ',
  'mentoring/add/error': 'Update failed',
  'mentoring/list/topics/help':
    'This is a compilation of topics that you are available to mentor your colleagues on.',

  'mentoring/session/request/email/success': 'Request send successfully',
  'mentoring/session/request/email/error':
    'An error occurred while sending the request. Please try again.',

  'mentoring/session/create/success': 'Session created successfully',
  'mentoring/session/create/error':
    'There was an error in creating the session. Kindly attempt the process again.',

  'mentoring/session/update/success': 'Session updated successfully',

  'mentoring/session/view/addinfo':
    'The panel is exclusively visible only to the mentor and mentee.',
  'mentoring/session/view/noack':
    'The session is awaiting acknowledgment from either the mentor or the mentee.',

  'mentoring/acknowledge/complete': 'Acknowledged as complete by the ',

  'mentoring/session/notfound/title': 'Mentoring Session not found',
  'mentoring/session/notfound/description':
    'I apologize for the inconvenience. It appears that the session is currently unavailable or may have been canceled. ',

  'mentoring/search/message':
    "To schedule a mentoring session on a topic go to mentor's profile and then on Mentoring panel click the topic and send a request to schedule a session.",

  'mentoring/request/notfound':
    'Please ensure you click the URL in the meeting request email or notification. A session cannot be created without a request.',

  'panelvisibility/update/success':
    'Panel Visibility has been successfully updated.',
  'panelvisibility/update/error':
    'The update to Panel Visibility has encountered an issue. Kindly attempt the operation again.',

  'panelvisibility/help/intro':
    'The Panel Visibility configuration empowers you to control who can view your information on your profile page.',
  'panelvisibility/help/intro2':
    "As an enterprise license customer, it's important to note that you cannot conceal your profile information from colleagues within your organization. The visibility settings are universally applicable within the organizational context.",
  'panelvisibility/help/companyconfig/intro':
    "The Panel Visibility configuration empowers you to determine who can access information on your colleagues' profile pages. Being an enterprise license customer grants you universal control over the visibility settings for each panel across all users within your company. The available options include: ",
  'panelvisibility/help/entlicense':
    'As an enterprise license customer, your organization has opted to establish universal visibility settings for this panel across all its members. The meanings of the values are as follows: ',
  'panelvisibility/help/item1':
    '🛡️ Visible to colleagues only : This signifies that the information is restricted to viewing exclusively by colleagues within the same organization or corporation operating under the Enterprise License.',
  'panelvisibility/help/ent/item1':
    '🛡️ Visible to colleagues only : This indicates that only colleagues within your organization or corporation have the capability to view the provided information.',
  'panelvisibility/help/companyconfig/item1':
    "🛡️ Visible to colleagues only : This setting ensures that only colleagues within the user's organization or corporation can view the panel on their profile; it restricts visibility to solely internal members.",
  'panelvisibility/help/item2':
    '👁️ Visible to everyone : This configuration signifies that the information is public and accessible to anyone who possesses the web address of your profile.',
  'panelvisibility/help/ent/item2':
    '👁️ Visible to everyone : This configuration indicates that information is public to anyone with the web address of your profile, unless your profile is explicitly marked as private for internal use only.',
  'panelvisibility/help/companyconfig/item2':
    '👁️ Visible to everyone : This configuration indicates that the panel is visible to anyone who possesses the web address of the profile.',
  'panelvisibility/help/item3':
    '🖧 Visible to my network & colleagues only: This configuration implies that the information is visible to individuals within your network, as well as employees of your organization or company. ',
  'panelvisibility/help/ent/item3':
    "🖧 Visible to my network & colleagues only: This setting ensures that the panel will only be visible to individuals within the user's network, including colleagues from their organization or company.",

  'panelvisibility/help/companyconfig/item3':
    '🖧 Visible to my network & colleagues only: This means panel will be visible only to the people in user`s network along with your org/company employees.',

  'panelvisibility/help/companyconfig/item4':
    '👍🏽 Profile User`s choice: This signifies that users have the autonomy to manage the visibility of the panel on their profile. In this scenario, the company admin delegates control over visibility to the individual user. ',

  'panelvisibility/help/personalinfo':
    'Unless the profile is designated as private for internal use only, your full name, photo, job title, and organization name will always be visible.',
  'panelvisibility/help/visibilityforsameorg':
    'This configuration ensures that your profile information is visible to your internal colleagues within the same organization or company.',
  'panelvisibility/help/visibilityforall':
    'This configuration implies that your information will be visible to anyone who visits your profile page. For further details, please refer to the open help section..',
  'panelvisibility/help/visibilityfornw':
    'This configuration ensures that your information is exclusively visible to individuals who are members of your network.',
  'panelvisibility/help/visibilityfornwsamorg':
    "As a user under Enterprise License, it's important to note that your internal colleagues within the same organization or company will consistently have access to view the provided information. For additional details, please refer to the open help section.",

  'teams/create/error':
    'There was an error in creating the team. Please try again.',
  'teams/update/success': 'Team was successfully updated.',
  'teams/update/error':
    'There was an error in udpating the team. Please try again.',
  'teams/notfound/description':
    "Apologies, but we were unable to locate the team you are searching for. Please verify the accuracy of the web address. Additionally, consider the possibility that access to the team may no longer be available, the team might no longer exist, the team owner's license has expired, or they have opted to designate the team as private. ",
  'teams/notfound/title': 'Team not found.',

  'teams/add/teammember/success': 'Team Member was added successfully.',
  'teams/add/teammember/error': 'Adding Team Member failed. Please try again.',
  'teams/add/teammember/search':
    'Search for team member by name in your network',
  'teams/add/teammember/help':
    'In order to prevent spam, the addition of team members is limited to individuals within your network. Utilize the search field below to add them to your team. Following the addition, a message will be dispatched to request their inclusion in the team. Their acceptance is required for them to officially become a part of the team.',
  'teams/edit/teammember/success': 'Team Member was updated successfully.',
  'teams/edit/teammember/error':
    'Updating Team Member failed. Please try again.',
  'teams/edit/teammember/help':
    'Given your role as a Team Admin, self-removal or modification of the Team Admin role is restricted. Only other Team Admins possess the authority to carry out such actions. This measure is in place to maintain security and uphold the integrity of team administration.',
  'teams/edit/teammember/help2':
    "Access to add, update, or remove team members is exclusively granted to Team Admins. It's important to note that a Team Admin cannot be removed without first adjusting their status to a non-Team Admin role. This ensures a systematic and secure process in managing team memberships.",
  'teams/teamupdates/notfound': 'No team updates',
  'teams/join/success': 'Thanks for joining the team!',
  'teams/leave/success': 'You have successfully left the team',
  'teams/leave/areyousure': 'Are you sure you want to leave the team?',

  'teams/team/subscribed': 'Subscription successful',
  'teams/team/unsubscribed': 'Unsubcription successful',

  'teams/events/autoschedule':
    "🪄Auto Schedule time based on everyone's availability in next 2 weeks",
  'teams/events/comingsoon':
    '🚀 Coming soon auto fetch best available date & time from an API.',
  'teams/events/recurring/ui': '👋 Show the UI for selecting recurring events.',
  'teams/events/conferenceurl':
    'Please enter a valid URL including the https:// at the start',
  'teams/events/create/verify':
    "Please verify the above event information before clicking the 'Create Team Event' button below👇",
  'teams/teamevent/notfound/title': 'Team Event not found.',
  'teams/teamevent/notfound/description':
    "Apologies, but we were unable to locate the team event you are searching for. Kindly verify the accuracy of the web address. Additionally, it's plausible that you may no longer have access to the event, the event may no longer exist, or the team owner's license has expired.",

  'teams/teamevent/update/success': 'Team Event was successfully updated.',
  'teams/teamevent/update/error':
    'There was an error in udpating the team event. Please try again.',
  'teams/teamevent/subscribed': 'Subscription successful.',
  'teams/teamevent/unsubscribed': 'Successfully Unsubscribed.',

  'teams/teamevent/visibility':
    'This event is currently marked as 🌐 Public, certain parts of the page are visible to anyone with the page URL. Team admins have the authority to change the visibility status to 🛡️ Private.',

  'teamslinks/add/success': 'Link were successfully added.',
  'teamslinks/update/success': 'Link was successfully updated.',
  'teamslinks/delete/success': 'Link was successfully deleted.',
  'teamslinks/update/error':
    'There was an error in updating the links. Please try again.',

  'teams/teammeetingnotes/redirect': 'Redirecting you to team event',

  'teams/teammeetingnotes/uploadImageHelp':
    '👆 💡 Optionally you can upload an image/photo of your notepad with handwritten notes and automatically convert it to text using Vision AI. Have multiple pages? Merge them into one page using a free service at ',

  'teams/add/teambulletin/success': 'Team Bulletin was added successfully.',
  'teams/add/teambulletin/error':
    'Adding Team Bulletin failed. Please try again.',
  'teams/update/teambulletin/success':
    'Team Bulletin was updated successfully.',
  'teams/update/teambulletin/error':
    'Editing Team Bulletin failed. Please try again.',
  'teams/delete/teambulletin/success':
    'Team Bulletin was deleted successfully.',
  'teams/delete/teambulletin/error':
    'Deleting Team Bulletin failed. Please try again.',

  'teams/add/teambroadcast/success': 'Message was successfully broadcasted.',

  'teams/add/teambooking/success': 'Team Booking was added successfully.',
  'teams/add/teambooking/error':
    'Adding Team Booking failed. Please try again.',
  'teams/update/teambooking/success': 'Team Booking was updated successfully.',
  'teams/update/teambooking/error':
    'Editing Team Booking failed. Please try again.',
  'teams/delete/teambooking/success': 'Team Booking was deleted successfully.',
  'teams/delete/teambooking/error':
    'Deleting Team Booking failed. Please try again.',

  'teams/teambooking/schedulingtype/what':
    'Scheduling Type determines how meetings are assigned when multiple team members are involved in a booking. The available options are: ',

  'teams/teambooking/schedulingtype/collective':
    'The visitor will be presented with available times only when all members (listed above) in the booking are collectively available.',

  'teams/teambooking/schedulingtype/round-robin-maximize-fairness':
    'Booking Requests are distributed evenly among all members, and a meeting request will be sent to any available team member based on their availability. Meeting times are displayed only when at least 50% of the members are available. This type is particularly useful for sales or open office hours-type meetings.',

  'teams/teambooking/schedulingtype/round-robin-maximize-availability':
    'In this type, the scheduler displays all time slots where at least one team member is available. This approach is beneficial for activities such as customer service, support calls, and similar scenarios.',

  'team/booking/notfound/title': 'Team Booking not found',
  'team/booking/notfound/description':
    "Apologies, but we couldn't find the team booking page you're seeking. Kindly double-check the web address. It's possible that the booking has expired, been deleted, the organizer's license has expired. For further assistance, we recommend reaching out to the organizer directly.",

  /** *********************** REDIRECTS ****************** */

  'redirects/add/success': 'Redirect successfully added',
  'redirects/update/success': 'Redirect successfully updated',
  'redirects/delete/success': 'Redirect successfully deleted',

  'redirects/redirectedto/msg': 'Your profile will be redirected to ',

  'redirects/error/notinsamecompany':
    'Sorry you are not in the same organization as the user. A redirect can only be configured users of same organization',

  'network/connected/nomembers':
    "Your network currently doesn't have any members. To expand your network, you can send connection requests from individual member's profile page.",

  /** ****************************** ******************************** */
  'company/notfound/description':
    "Apologies, but we couldn't find the company you're searching for. Please double-check the web address for accuracy. Additionally, consider the possibility that you may no longer have access to the company, the company may no longer exist, the company owner's license has expired, or they have chosen to designate the company as private.",
  'company/notfound/title': 'Company not found.',

  /** ****************************** ******************************** */
  'company/event/notfound/description':
    "Apologies, but we couldn't find the event you're looking for. Kindly double-check the web address. Additionally, it's possible that you no longer have access to the event, the event no longer exists, the company owner's license has expired, or they've opted to designate the event as private.",

  'company/event/notfound/title': 'Company event not found.',

  /** *********************** SCHEDULING PAGES ****************** */

  'schedulingpage/add/success': 'Booking Page successfully added',
  'schedulingpage/update/success': 'Booking Page successfully updated',
  'schedulingpage/delete/success': 'Booking Page successfully deleted',

  'schedulingpage/add/error': 'Booking Page add failed',
  'schedulingpage/update/error': 'Booking Page update failed',
  'schedulingpage/delete/error': 'Booking Page delete failed',

  'schedulingpage/nocalendarintegration/title': 'Calendar is not integrated',
  'schedulingpage/nocalendarintegration/description':
    'To use scheduler we need access to your calendar. Please click the button below to move forward.',

  'schedulingpage/notfound/title': 'Booking not found',
  'schedulingpage/notfound/description':
    "Apologies, but we couldn't find the booking page you're seeking. Kindly double-check the web address. It's possible that the booking has expired, been deleted, the organizer's license has expired, or they've opted to designate the booking as private for specific users. For further assistance, we recommend reaching out to the organizer directly.",
}

// Ideally user messages should be the success/error messages that user sees on the screen.
//  this.showUserMessageFromCode('')
