<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-half">
        <div class="box has-text-centered p-6">
          <h1 class="title is-1 has-text-weight-bold">About Us</h1>
          <p class="title is-5">
            <b
              ><u>{{ appTitle }}</u></b
            >
            - {{ byline }}
          </p>
          <hr />
          <p class="title is-3 pb-1">Vision</p>
          <p>Allow every professional to connect better with their peers</p>
          <p class="title is-3 mt-6 pb-1">Mission</p>
          <p>Our mission is to create better work relationships</p>
          <p class="title is-3 mt-6 pb-1">Who We Are</p>
          <p>
            <b>{{ appTitle }}</b> was designed & developed during the COVID-19 pandemic. Tired by the constant barrage of self promiting news feeds on existing
            business networking site we decided to launch a new way for people to connect with their peers, one that provides information for better work
            relationship without recuiters, sales people, and invasive tracking & monitoring
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState } from 'vuex'

import { trackPageView, trackScreenView } from '@/misc/analytics'

export default {
  name: 'AboutUs',
  computed: {
    ...mapState('app', ['appTitle', 'byline']),
  },
  metaInfo: {
    title: 'AboutUs',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
}
</script>
