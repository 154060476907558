<template>
  <section
    v-if="
      teamEventToView &&
      teamEventToView.attendees &&
      teamEventToView.attendees.length > 0
    "
    class="event-attendees"
  >
    <div class="view-event-attendees">
      <skeleton v-if="isLoading"></skeleton>

      <SiynCollapse
        v-else
        :title="panelTitle"
        :is-open="collapseHelper(panelname)"
        :panelname="panelname"
      >
        <div class="view-event-attendees__wrapper px-4 py-2">
          <div class="columns is-multiline is-gapless">
            <template v-for="attendee in teamEventToView.attendees">
              <small-card
                v-if="attendee.id"
                :key="attendee.id"
                class="column is-4 is-narrow"
                :uid="attendee.id"
                :display-name="attendee.displayName"
                :title="attendee.title"
              />

              <a
                v-else
                :key="attendee.email"
                class="column is-4 has-text-centered my-4"
                :href="`mailto:${attendee.email}`"
                >{{ attendee.displayName }}</a
              >
            </template>
          </div>
        </div>
      </SiynCollapse>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import { mapState } from 'vuex'
import { viewTeamsMixin } from '@/mixins/teams/index'
import SmallCard from '@/components/SmallCard'

export default {
  name: 'TeamEventAttendees',
  components: {
    SmallCard,
  },
  mixins: [viewTeamsMixin],
  props: {},
  data() {
    return {
      isLoading: true,
      panelTitle: 'Attendees',
      panelname: 'teamevent_attendees',
    }
  },
  computed: {
    ...mapState('teamsEvents', ['teamEventToView']),
  },
  created() {},
  mounted() {
    this.isLoading = false
  },
  destroyed() {},
  methods: {},
}
</script>
