<template>
  <section class="view-company-admins">
    <skeleton v-if="isLoading"></skeleton>
    <SiynCollapse
      v-if="!isLoading"
      :title="panelTitle"
      emoji="👑"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
      ><template #header-extras>
        <b-tooltip
          multilined
          class="mx-4"
          type="is-dark"
          label="Only visible to employees of the company"
          position="is-right"
          ><b-icon
            class="has-text-grey-lighter"
            pack="fas"
            icon="info-circle"
            size="is-small"
          >
          </b-icon
        ></b-tooltip>
      </template>
      <template #default>
        <div v-if="companyAdmins" class="columns is-multiline is-gapless">
          <template v-for="item in companyAdmins">
            <small-card
              v-if="item.isEnabled"
              :key="item.id"
              class="column is-4 is-narrow"
              :uid="item.id"
              :display-name="item.displayName"
            />
          </template>
        </div>
        <span v-if="isEmpty(companyAdmins)" class="ml-4 empty-content">{{
          showUserMessageFromCode('generic/none')
        }}</span>
        <div
          v-if="isUserCompanyAdmin || superAdmin === getAuthUserId"
          class="has-text-center-touch has-text-right"
        >
          <b-dropdown
            :triggers="['hover']"
            aria-role="menu"
            :mobile-modal="false"
            position="is-bottom-left"
            class="has-text-left"
          >
            <template #trigger>
              <b-button
                :label="`${showLabelsFromCode(
                  'company/admins/panelTitle',
                )} ${showLabelsFromCode('generic/menu')}`"
                type="is-primary"
                class="view-company-admins__button background-color-override"
                icon-right="caret-down"
                icon-pack="fas"
              />
            </template>
            <b-dropdown-item aria-role="menuitem" @click="goToAddCompanyAdmin()"
              ><b-icon type="is-info" icon="plus-square" pack="fas" />{{
                showLabelsFromCode('generic/add')
              }}
              {{ showLabelsFromCode('company/admin') }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="companyAdmins || superAdmin === getAuthUserId"
              aria-role="menuitem"
              @click="openEditCompanyAdminModal()"
              ><b-icon type="is-info" icon="edit" pack="far" />{{
                showLabelsFromCode('generic/edit')
              }}
              {{ showLabelsFromCode('company/admin') }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </template>
    </SiynCollapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations, mapActions } from 'vuex'
import { viewCompanyMixin } from '@/mixins/company/index'
import SmallCard from '@/components/SmallCard'
import EditCompanyAdminsModal from '@/views/company/admins/EditCompanyAdminsModal.vue'

export default {
  name: 'CompanyAdmins',
  components: { SmallCard },

  mixins: [viewCompanyMixin],
  data() {
    return {
      isLoading: true,
      panelTitle: 'Company Admins',
      panelname: 'company_admins',
      superAdmin: process.env.VUE_APP_SUPER_ADMIN,
    }
  },
  computed: {
    ...mapState('company', ['companyAdmins']),
  },
  watch: {},
  created() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('company', ['setCompanyToEdit']),
    ...mapActions('company', ['addCompanyUpdate']),
    goToAddCompanyAdmin() {
      this.setCompanyToEdit(this.companyToView)
      this.$router.push({
        name: 'reauthenticate',
        params: {
          routeName: 'addcompanyadmin',
          recordid: this.companyToView.id,
        },
      })
    },
    openEditCompanyAdminModal() {
      this.setCompanyToEdit(this.companyToView)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditCompanyAdminsModal,
        events: {
          update_successful: (adminDisplayName) => {
            this.handleSuccessMessage('Admin edited successfully!')
            const updateRecord = {
              action: 'updated',
              createdBy: this.getTinyUser,
              message: `${adminDisplayName}'s participation information was updated`,
              module: 'company-admin',
              type: 'company',
              priority: 3,
              company: {
                id: this.companyToView.id,
                name: this.companyToView.companyName,
              },
            }
            this.addCompanyUpdate({
              updateRecord,
              companyId: this.getAuthUserCompanyId,
            }).catch((err) => {
              this.trackExceptionEvent(
                'companyupdate_add_failed',
                err.message,
                false,
              )
            })
          },
          trapFocus: true,
        },
      })
    },
  },
}
</script>
