var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"view-token"},[_c('full-page',[_c('SynPanel',{attrs:{"title":`${_vm.panelTitle} ID: ${_vm.companyTokenToView.id}`}},[(_vm.errorMessage)?_c('b-notification',{staticClass:"sm-help-notification",attrs:{"type":"is-danger is-light","aria-close-label":"Close notification"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.errorMessage)}})]):_vm._e(),_c('div',{staticClass:"columns is-gapless is-vcentered is-multiline"},[_c('div',{staticClass:"column is-3"},[_c('span',{staticClass:"sm-label"},[_vm._v("Created For: ")])]),(_vm.tokenToView?.createdFor)?_c('div',{staticClass:"column is-9"},[_c('span',{staticClass:"sm-value"},[_c('b',[_vm._v(_vm._s(_vm.tokenToView.createdFor?.displayName))]),_vm._v(" - "+_vm._s(_vm.tokenToView.createdFor?.email))])]):_vm._e(),_c('div',{staticClass:"column is-3"},[_c('span',{staticClass:"sm-label"},[_vm._v("Created By: ")])]),_c('div',{staticClass:"column is-9"},[_c('span',{staticClass:"sm-value"},[(_vm.tokenToView?.createdBy?.id)?_c('router-link',{staticClass:"no-text-decoration",attrs:{"target":"_blank","to":{
                name: 'profile',
                params: { uid: _vm.tokenToView.createdBy.id },
              }}},[_c('span',[_vm._v(_vm._s(_vm.tokenToView.createdBy.displayName))])]):_vm._e(),_vm._v(" on "),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.tokenToView?.createTimestamp)))]),_c('span',{staticClass:"has-text-grey-light ml-2"},[_vm._v("("+_vm._s(_vm.momentAgo(_vm.tokenToView?.createTimestamp))+")")])],1)]),_c('div',{staticClass:"column is-3"},[_c('span',{staticClass:"sm-label"},[_vm._v("License Valid : ")])]),_c('div',{staticClass:"column is-9"},[_c('span',{staticClass:"sm-value"},[_vm._v(" From "+_vm._s(_vm.formatDateWithYear(_vm.tokenToView?.startDateTime))+" to "+_vm._s(_vm.formatDateWithYear(_vm.tokenToView?.endDateTime))),_c('span',{staticClass:"has-text-grey-light ml-2"},[_vm._v("("+_vm._s(_vm.momentAgo(_vm.tokenToView?.endDateTime))+")")])])]),(_vm.tokenToView?.utilizedOn)?_c('div',{staticClass:"column is-3"},[_c('span',{staticClass:"sm-label"},[_vm._v("Token utilized on : ")])]):_vm._e(),(_vm.tokenToView?.utilizedOn)?_c('div',{staticClass:"column is-9"},[_c('span',{staticClass:"sm-value"},[_vm._v(" "+_vm._s(_vm.formatDateWithYear(_vm.tokenToView.utilizedOn))),_c('span',{staticClass:"has-text-grey-light ml-2"},[_vm._v("("+_vm._s(_vm.momentAgo(_vm.tokenToView?.utilizedOn))+")")])])]):_vm._e(),(_vm.tokenToView?.profileCreated)?_c('div',{staticClass:"column is-3"},[_c('span',{staticClass:"sm-label"},[_vm._v("Profile Created: ")])]):_vm._e(),(_vm.tokenToView?.profileCreated)?_c('div',{staticClass:"column is-9"},[_c('span',{staticClass:"sm-value"},[(_vm.tokenToView.profileCreated?.id)?_c('router-link',{staticClass:"no-text-decoration",attrs:{"target":"_blank","to":{
                name: 'profile',
                params: { uid: _vm.tokenToView.profileCreated?.id },
              }}},[_c('span',[_vm._v(_vm._s(_vm.tokenToView.profileCreated?.displayName))])]):_vm._e()],1)]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }