export default {
  'generic/next': 'Next',
  'generic/goback': 'Go Back',
  'generic/previous': 'Previous',
  'generic/nextpage': 'Next page',
  'generic/previouspage': 'Previous page',
  'generic/page': 'Page',
  'generic/currentpage': 'Current page',
  'generic/none': 'None',
  'generic/action': 'Action',
  'generic/subscribe': 'Subscribe',
  'generic/subscribers': 'Subscribers',
  'generic/unsubscribe': 'Unsubscribe',
  'generic/help': 'Help / Info',
  'generic/start': 'Start',
  'generic/end': 'End',
  'generic/name': 'Name',
  'generic/person': 'Person',
  'generic/admin': 'Admin',
  'generic/submit': 'Submit',
  'generic/type': 'Type',
  'generic/description': 'Description',
  'generic/occurence': 'Occurence',
  'generic/add': 'Add',
  'generic/create': 'Create',
  'generic/addmore': 'Add More',
  'generic/edit': 'Edit',
  'generic/update': 'Update',
  'generic/cancel': 'Cancel',
  'generic/archive': 'Permanent Archive',
  'generic/exportpdf': 'Export as PDF',
  'generic/remove': 'Remove',
  'generic/delete': 'Delete',
  'generic/status': 'Status',
  'generic/list': 'List',
  'generic/event': 'Event',
  'generic/events': 'Events',
  'generic/notes': 'Notes',
  'generic/text': 'Text',
  'generic/comments': 'Comments',
  'generic/who': 'Who ?',
  'generic/when': 'When ?',
  'generic/what': 'What ?',
  'generic/since': 'Since',
  'generic/location': 'Location',
  'generic/share': 'Share',
  'generic/link': 'Link',
  'generic/menu': 'Menu',
  'generic/view': 'View',
  'generic/record': 'Record',
  'generic/connectinfo': 'How to join',
  'generic/author': 'Author',
  'generic/created': 'Created',
  'generic/requested': 'Requested',
  'generic/by': 'by',
  'generic/updatedby': 'Updated By',
  'generic/unassigned': 'Unassigned',
  'generic/urlvalidation': `Please enter valid URL with https://`,
  'generic/title': 'Title',
  'generic/lastupdated': 'Updated',
  'generic/backtoview': 'Back to View Profile',
  'generic/backtoedit': 'Back to Edit Profile',
  'generic/resetsorting': 'Reset Sorting',
  'generic/opensinnewtab': 'Opens in a new tab',
  'generic/nonepleaseadd': 'None, please add.',
  'generic/dateandtime': 'Date & Time',
  'generic/dateandtime/typeselect': 'Select or Type',
  'generic/startdateandtime': 'Start Date & Time',
  'generic/enddateandtime': 'End Date & Time',
  'generic/dates': 'Dates',
  'generic/address': 'Address',
  'generic/ratings': 'Ratings',
  'generic/onlyvisibletoemployees': 'Only visible to employees of the company',
  'generic/disableuser': 'Disable User',
  'generic/agenda': 'Agenda',
  'generic/network': 'Network',
  'generic/organization': 'Organization',
  'generic/documents': 'Documents',
  'generic/select': 'Select',
  'generic/role': 'Role',
  'generic/people': 'People',

  'form/generic/validationMessage': 'Please enter valid input',
  'form/generic/phoneValidationMessage': 'Please enter valid telephone number',
  'form/generic/doNotShow': 'Do Not Show',
  'form/generic/pleaseSelect': 'Please Select',
  'form/generic/clicktocall': 'Click to Call',
  'form/generic/clicktoemail': 'Click to Email',
  'form/generic/numbers-dashes': 'Only numbers and dashes allowed',
  'form/generic/chat': 'Chat',
  'form/generic/email': 'Email',
  'form/generic/phone': 'Phone',
  'form/generic/deskPhone': 'Desk Phone',
  'form/generic/mobilePhone': 'Mobile Phone',
  'form/generic/txtMsg': 'Text Messages',
  'form/generic/mtg': 'Schedule a Meeting',
  'form/generic/noPref': 'No Preference',
  'form/generic/dateTimeSelectOrEmpty': 'Select or type or leave empty',
  'form/generic/sendrequest': 'Send Request',

  'navbar/joinus': 'Come Join Us.',
  'navbar/register': 'Register',
  'navbar/register-check': 'Check License',
  'navbar/signin': 'Sign In',
  'navbar/notifications': 'Notifications',
  'navbar/viewmyprofile': 'View My Profile',
  'navbar/editmyprofile': 'Edit My Profile',
  'navbar/orgsearch': 'Search Across Org',
  'navbar/mynetwork': 'My Network',
  'navbar/connected': 'Connected',
  'navbar/pendingrequests': 'Pending Requests',
  'navbar/blockedusers': 'Blocked Users',
  'navbar/createteam': 'Create a Team',
  'navbar/myteams': 'My Teams',
  'navbar/emailsignaturetemplates': 'Email Signature Templates',
  'navbar/sendaninvite': 'Share link to my profile',
  'navbar/disableprofile': 'Deactivate My Account',
  'navbar/logout': 'Log Out',

  'navbar/search': 'Search Your Network...',
  'navbar/search/searchacrossorg': 'Search across organization',
  'navbar/search/noresults': 'No results for',

  'profile/generic/panelVisibility': 'Panel Visibility',
  'profile/generic/note': 'Note',

  'profile/view/panelTitle': 'View Profile',
  'profile/edit/panelTitle': 'Edit Profile',
  'profile/edit/panelVisibility': 'Panel Visibility',

  /** ****************** PERSONAL INFO ************************ */

  'profile/personalInfo/fullname': 'Your Full Name',
  'profile/personalInfo/profileImage': 'Update Profile Image',
  'profile/personalInfo/companyName': 'Company / Organization Name',
  'profile/personalInfo/worksfor': 'Works for',
  'profile/personalInfo/works': 'Works',
  'profile/personalInfo/jobtitle': 'Current Job Title',
  'profile/personalInfo/jobtitle2': 'Job Title',
  'profile/personalInfo/jobtitle3': 'as',
  'profile/personalInfo/panelTitle': 'Personal Info',
  'profile/personalInfo/suffix': 'Suffix or Certifications',
  'profile/personalInfo/pronouncedAs': 'Name pronounced as',
  'profile/personalInfo/pronouncedAs2': 'Pronounced as',
  'profile/personalInfo/pronouncedHelp': 'How to pronounce your name?',
  'profile/personalInfo/goesBy': 'Goes by',
  'profile/personalInfo/goesBy2': 'Goes by / Preferred name',
  'profile/personalInfo/goesBy2Help': 'Enter if its different than your name',
  'profile/personalInfo/pronoun': 'Preferred Pronoun',
  'profile/personalInfo/pronoun2': 'Pronoun',
  'profile/personalInfo/employmentType': 'Employment Type',
  'profile/personalInfo/employmentType2': 'Type',

  'form/personalInfo/edit': 'Edit Personal Information',
  'form/personalInfo/title': 'Update Personal Info',
  'form/personalInfo/fullname': 'Full Name',

  /** ******************* PROFILE BUTTON *********************** */

  '/profile/profilebutton/requested': 'Respond to networking',
  '/profile/profilebutton/logintoconnect': 'Login to network',
  '/profile/profilebutton/connect': 'Connect',
  '/profile/profilebutton/requestconnection': 'Request Connection',
  '/profile/profilebutton/report': 'Report this user',
  '/profile/profilebutton/approve': 'Approve Request',
  '/profile/profilebutton/reject': 'Reject Request',
  '/profile/profilebutton/block': 'Block this user',
  '/profile/profilebutton/pending': 'Pending',
  '/profile/profilebutton/cancel': 'Cancel Request',
  '/profile/profilebutton/innetwork': 'In Network',
  '/profile/profilebutton/removeconnection': 'Remove Connection',
  '/profile/profilebutton/blocked': 'Blocked',
  '/profile/profilebutton/unblockuser': 'Unblock User',
  '/profile/profilebutton/subscribe': 'Subscribe to updates',
  '/profile/profilebutton/unsubscribe': 'Unsubscribe',
  '/profile/profilebutton/sendchatmessage': 'Send a Message',
  '/profile/profilebutton/requestmeeting': 'Schedule Meeting',

  /** ****************** PRIVATE INFO ************************ */

  'profile/privateInfo/showContactInfo': 'Show contact information',
  'profile/privateInfo/panelTitle': 'Contact',
  'profile/privateInfo/chatVendor': 'Chat/IM Tool',
  'profile/privateInfo/deskPhone': 'Desk phone',
  'profile/privateInfo/mobilePhone': 'Mobile Phone',
  'profile/privateInfo/email': 'Email',
  'profile/privateInfo/companyAddress': 'Office Address',
  'profile/privateInfo/addressSelect': 'Select Official Address',
  'profile/privateInfo/addressSelect2': 'Official Company Address',
  'profile/privateInfo/sos': 'Emergency / Backup Phone',
  'profile/privateInfo/chat': 'Chat application used in your organization',

  'form/privateInfo/emergencyCall': 'Click to Call',
  'form/privateInfo/address': 'Select work address from company locations',
  'form/privateInfo/address2':
    'This is the registered company address. DO NOT share your home address if you are WFH',

  'form/privateInfo/addressSelect':
    'Select from the list of your company locations',
  'form/privateInfo/title': 'Contact Info',
  'form/privateInfo/chat': 'Select an application/provider',

  /** ****************** CONTACT PREF ************************ */

  'profile/contactPreferences/panelTitle': 'Contact Preferences',
  'profile/contactPreferences/emailCheckInterval': 'Email Check Interval',
  'profile/contactPreferences/responseExpectations': 'Response Expectation ',
  'profile/contactPreferences/actionsToTake': 'Action to Take',
  'profile/contactPreferences/preferenceInternal':
    'Preference for Internal Colleagues',
  'profile/contactPreferences/preferenceExternal':
    'Preference for External Colleagues',
  'form/contactPreferences/title': 'Update Contact Preferences',
  'form/contactPreferences/internalPreference':
    'Communications preference with internal colleagues',
  'form/contactPreferences/externalPreference':
    'Communications preference with external colleagues',

  /** ****************** OFFICE HOURS ************************ */

  'profile/officehours/panelTitle': 'Regular Office Hours',
  'profile/officehours/TZ': 'Timezone',
  'profile/officehours/convertTZ': 'Convert times to my timezone',
  'profile/officehours/sameTZ': 'In same timezone as you',
  'profile/officehours/startTime': 'Start Time',
  'profile/officehours/endTime': 'End Time',
  'profile/officehours/worksRemote': 'Works Remote?',
  'profile/officehours/worksRemote2': 'Do I work remote?',
  'profile/officehours/availabilityAfter': 'Availability after End Time',
  'profile/officehours/notFound': 'No office hours found, please edit to add',
  'form/officeHours/title': '🕗 Edit Office Hours',
  'form/officeHours/title2': 'Update Office Hours',
  'form/officeHours/notePH':
    'Ex: 1/2 hour lunch at noon. Available on Teams until 7 p.m.',
  'form/officeHours/afterHours': 'Availability after hours (in minutes)',

  /** ****************** EVENTS ************************ */

  'profile/events/panelTitle': 'Upcoming Events',
  'profile/events/events': 'Events',
  'profile/events/eventType': 'Event Type',
  'profile/events/upcomingEvents': 'Show all upcoming events for the year',
  'profile/events/list': '📆 Events List',

  'profile/events/backtoview': 'Back to View Profile',
  'profile/events/backtoedit': 'Back to Edit Profile',
  'profile/events/eventname': 'Event Name',
  'profile/events/eventtype': 'Event Type',
  'profile/events/startdate': 'Start Date',
  'profile/events/enddate': 'End Date',
  'profile/events/eventCategory': 'Category',
  'profile/events/startdatetime': 'Start Date & Time',
  'profile/events/enddatetime': 'End Date & Time',
  'profile/events/availability': 'Availability',
  'profile/events/unavailable': 'Unavailable',
  'profile/events/sos': 'Available only on SOS/Emergency number',
  'profile/events/deskphone': 'Available on Desk Phone',
  'profile/events/chat': 'Available on chat',
  'profile/events/email': 'Available on email',
  'profile/events/mobilePhone': 'Available on mobile phone',
  'profile/events/willbeooo':
    'Will you be Out of Office and not working during this event?',
  'profile/events/willbeavailable':
    'Will you be available via any communication channels during the event?',

  'form/events/deleteareyousure': 'Are you sure you want to delete the event?',
  'form/events/addevent': 'Add Event',
  'form/events/editevents': 'Edit Events',
  'form/events/editevent': 'Update Event',
  'form/events/addeventinbulk': 'Add Multiple Events',
  'form/events/button/saveall': 'Save All Events',
  'form/events/button/autofetch': 'Auto fetch all holidays',

  /** ****************** TEAM ************************ */

  'generic/team': 'Team',
  'generic/teams': 'Teams',
  'teams/tagTitle': 'Team',

  'teams/team/join': 'Join', // TODO Do not change this, there is some stupid dumb code in TeamButton that looks for this string
  'teams/team/leave': 'Leave', // TODO Do not change this, there is some stupid dumb code in TeamButton that looks for this string

  'teams/teammembers/panelTitle': 'Members',
  'teams/teammembers/teammember': 'Member',
  'teams/teammembers/membername': 'Member Name',
  'teams/teammembers/isteamadmin': 'Is Team Admin',
  'teams/teammembers/memberstatus': 'Member Status',
  'teams/teammembers/teamrole': 'Team Role',

  'teams/teamupdates/panelTitle': 'Latest Updates',
  'teams/teamupdates/listTitle': 'All Updates',
  'teams/teamupdates/showallupdates': 'Show All Updates',
  'teams/teamupdates/backtoteam': 'Back',
  'teams/teamupdates/operation': 'Operation',
  'teams/teamupdates/module': 'Module',

  'teams/teamevents/panelTitle': 'Events / Milestones',
  'teams/teamevents/tagTitle': 'Event / Milestone',
  'teams/teamevents/createonetimeevent': 'Create Onetime Event',
  'teams/teamevents/createrecurringevent': 'Create Recurring Event',
  'teams/teamevents/eventname': 'Event Name',
  'teams/teamevents/eventtype': 'Event Type',
  'teams/teamevents/eventdescription': 'Event Description',
  'teams/teamevents/eventVisibility': 'Event Visibility',

  'teams/teambulletins/panelTitle': 'Bulletins',
  'teams/bulletin': 'Bulletin',

  'teams/teambookings/panelTitle': 'Booking Pages',
  'teams/booking': 'Booking Page',

  'teams/teamSummaries/panelTitle': 'AI Assisted Summaries',
  'teams/summary': 'AI Assisted Summary',

  'teams/broadcasts/panelTitle': 'Broadcasts',
  'teams/broadcast': 'Broadcast',
  'teams/broadcast/expires': 'Expires In',

  'teams/teamevents/recurring': 'Recurring',
  'teams/teamevents/schedule': 'Schedule',
  'teams/teamevents/location': 'Location',

  'teams/teamlinks/panelTitle': 'External Links',
  'teams/teamlinks/addlink': 'Add Link',
  'teams/teamlinks/editlink': 'Edit Link',

  'form/projectteams/addteam': 'Add Team',
  'form/projectteams/myteams': 'My Teams',
  'form/userteams/editlinks': 'List My Teams',
  'form/teams/createteam': 'Create Team',
  'form/teams/edit/image': 'Upload Team Image',
  'form/teams/viewteam': 'View Team',
  'form/teams/teamstatus': 'Team Status',
  'form/teams/teamname': 'Team Name',
  'form/teams/teamname-ph': 'Be creative & succient in naming the team',
  'form/teams/teamdescription': 'Team Description',
  'form/teams/teamvisibility': 'Team Visibility',
  'form/teams/teamdescription-ph': 'Enter information about the team',
  'form/teams/teammember/selectmember': 'Select Team Member',
  'form/teams/teammember/selectrole': 'Select Role in the Team',

  'form/teams/teamevents/information': 'Information',
  'form/teams/teamevents/occcurence': 'Event Occurence',
  'form/teams/teamevents/occcurence/onetime': 'One Time',
  'form/teams/teamevents/occcurence/recurring': 'Recurring',
  'form/teams/teamevents/frequency': 'Frequency',
  'form/teams/teamevents/searchtoadd': 'Type to select...',
  'form/teams/teamevents/daily': 'Daily',
  'form/teams/teamevents/weekly': 'Weekly',
  'form/teams/teamevents/monthly': 'Monthly',
  'form/teams/teamevents/yearly': 'Yearly',
  'form/teams/teamevents/conference': 'Conference Meeting URL',
  'form/teams/teamevents/meetinglocation': 'Meeting Address',
  'form/teams/teamevents/datetime': 'Date & Time, Venue & People',
  'form/teams/teamevents/allowsubscribe':
    'Allow other colleagues to subscribe to this event',
  'form/teamlinks/url': 'URL for the link',
  'form/teamlinks/link': 'Link',
  'form/team/event/location': 'Location',
  'form/team/meetingnotes/paneltitle': 'Meeting Notes',
  /** ****************** LINKS ************************ */
  'profile/links/panelTitle': 'Links',

  'form/links/addlinks': 'Add Links',
  'form/links/editlinks': 'List Links for Edit',
  'form/links/button/saveall': 'Save All Links',
  'form/links/typeoflink': 'Type of Link',
  'form/links/uniqueurl': 'Your Unique URL',
  'form/links/title': 'Update Link',
  'form/links/openexisting': 'Open list of exisiting links',
  /** ****************** LEISURES ************************ */
  'profile/leisures/panelTitle': 'Leisure',
  'profile/leisures/book': 'Book',
  'profile/leisures/books': 'Books',
  'profile/leisures/currentlyreading': 'Reading',
  'profile/leisures/podcast': 'Podcast',
  'profile/leisures/podcasts': 'Podcasts',
  'profile/leisures/currentlylistening': 'Listening to',
  'profile/leisures/show': 'TV Show',
  'profile/leisures/shows': 'TV Shows',
  'profile/leisures/currentlywatching': 'Watching',
  'profile/leisures/sportsteam': 'Sports Team',
  'profile/leisures/sportsteams': 'Sports Teams',
  'profile/leisures/followingsportsteams': 'Following',
  'profile/leisures/followsteams': 'Follows Teams',
  'profile/leisures/likes': 'Likes',
  'profile/leisures/history': 'History',

  'form/leisures/rating': 'Rating',
  'form/leisures/urllink': 'Update URL Link',
  'form/leisures/book/finishedreading': 'Finished Reading ?',
  'form/leisures/myrating': 'My Rating',
  'form/leisures/book/title': 'Search book title to add',
  'form/leisures/books/author': 'Author',
  'form/leisures/books/hasread': 'Has Read?',
  'form/leisures/podcasts/title': 'Search Podcasts to add',
  'form/leisures/podcasts/hostedby': 'Hosted By',
  'form/leisures/podcasts/explicit': 'Explicit',
  'form/leisures/podcasts/finishedlistening': 'Finished Listening?',
  'form/leisures/shows/title': 'Search TV shows to add',
  'form/leisures/shows/finishedwatching': 'Finished Watching ?',
  'form/leisures/sportsteams/title': 'Search Teams to add',
  'form/leisures/sportsteams/shortname': 'Team Short Name',
  'form/leisures/sportsteams/team_location': 'Team Location',
  'form/leisures/sportsteams/stop_following': 'Stop Following?',
  'form/leisures/likes/title': 'Search likes to add',

  /** ****************** MENTORING ************************ */

  'profile/mentoring/panelTitle': 'Mentoring',
  'profile/mentoring/topic': 'Topic',
  'profile/mentoring/session': 'Session',
  'profile/mentoring/send/request': 'Send Mentoring Session Request',
  'profile/mentoring/requestsrecd': 'Mentoring Requests Received',
  'profile/mentoring/topics/list': 'Mentoring Topics List',

  'form/mentoring/topic/prereqs': 'Prerequisites',
  'form/mentoring/topic/description': 'Description',
  'form/mentoring/topic/duration': 'Duration (in Mins.)',
  'form/mentoring/add/topic': 'Add Mentoring Topic',
  'form/mentoring/list/topics': 'List / Edit My Mentoring Topics',
  'form/mentoring/list/sessions/mentee': 'List Mentoring Sessions as Mentee',
  'form/mentoring/list/sessions/mentor':
    'List / Edit Mentoring Sessions as Mentor',
  'form/mentoring/add/session': 'Add Mentoring Session',
  'form/mentoring/edit/session': 'Edit Mentoring Session',
  'form/mentoring/view/session': 'Mentoring Session',
  'form/mentoring/list/session': 'My Mentoring Sessions',
  'form/mentoring/open': 'Open to mentoring other colleagues?',
  'form/mentoring/mentor': 'Mentor',
  'form/mentoring/mentee': 'Mentee',
  'form/mentoring/acknowledge': 'Acknowledgement',
  'form/mentoring/acknowledgeneeded': 'Needs Acknowledgement',

  'form/mentoring/acknowledge/pending/mentee':
    'Mentee acknowledgement pending for session to be marked as complete',
  'form/mentoring/acknowledge/pending/mentor':
    'Mentor acknowledgement pending for session to be marked as complete',

  'form/mentoring/acknowledge/agreement/mentor':
    'I hereby acknowledge that I conducted the session as a mentor.',
  'form/mentoring/acknowledge/agreement/mentee':
    'I hereby acknowledge that I attended the session as a mentee.',
  'form/mentoring/connect': 'How to connect to the session?',
  'form/mentoring/instructions/mentee': 'Additional instructions for mentee',

  /** ****************** RE-DIRECTS ************************ */
  'profile/redirects/list/panelTitle': 'My Profile Redirects',
  'profile/redirects/redirectedto': 'Redirected To',
  'profile/redirects/interstitial/title': 'Profile Redirect',

  'form/add/redirect': 'Add Redirect',
  'form/edit/redirect': 'Update Redirect',
  'form/add/redirect/redirectTo': 'URL to Redirect your profile to: ',
  'form/add/redirect/note': 'Note to visitor',

  /** ****************** COMMENTS ************************ */

  'profile/comments/panelTitle': 'About',
  'form/comments/title': 'Describe about yourself in your current role',

  /** **************************************************** */
  /** ****************** COMPANY ************************ */
  /** ************************************************** */

  'company/locations/panelTitle': 'Office Locations',
  'company/locations/location': 'Location',

  'company/events/panelTitle': 'Company Events',
  'company/events/unpublished': 'Unpublished',

  'company/admins/panelTitle': 'Company Admins',
  'company/admin': 'Company Admin',

  'company/newsletters/panelTitle': 'Newsletters',
  'company/newsletter': 'Newsletter',

  'company/newsletters/createnewsletter': 'Create Newsletter',
  'company/newsletters/listallnewsletters': 'List all Newsletters',

  'company/broadcasts/panelTitle': 'Company Broadcasts',

  'form/company/newsletters/prereqs': 'Banner Image',
  'form/company/newsletters/content': 'Content / Text',
  'form/company/newsletters/author': 'Author',
  'form/company/newsletters/publishdate': 'Publish Date',

  'form/company/companyVisibility': 'Company Page Visibility',

  'company/tokens/panelTitle': 'Guest User Tokens',
  'company/tokens/token': 'Token',

  /** ****************** Schduling pages ************************ */

  'scheduling/panelTitle': 'Booking Pages',
  'scheduling/page': 'Booking Page',
  'scheduling/schedulingpage': 'Scheduling Page',
  'scheduling/bookingOrganizer': 'Booking Organizer',
  'scheduling/navbar/title': 'My Booking Pages',
  'scheduling/visibilitytype': 'Audience Type',
  'scheduling/schedulingType': 'Scheduling Type / Book When',
  'scheduling/meetingduration': 'Meeting Duration',
  'form/schedulingpages/addPage': 'Add Booking Page',
  'form/schedulingpages/pageName': 'Booking Name',
  'form/schedulingpages/location': 'Location',
  'form/schedulingpages/buttonText': 'Submit Button Text',
  'form/schedulingpages/expirationDate': 'Expiration Date',
  'form/schedulingpages/expiresOn': 'Expires On',
  'form/schedulingpages/maxBookingsAllowed': 'Max Bookings Per Page',
  'form/schedulingpages/thankYouMessage': 'Thank You Message',
  'form/schedulingpages/bookingMode': 'Confirmation Mode',
  'form/schedulingpages/showTeamMembers': 'Show Members on Page',
  'form/schedulingpages/teamMembersList': 'Team Members in the meeting',
  'form/schedulingpages/autoSchedule': 'Allow auto schedule for visitor',

  'scheduling/edit/availability': 'Edit Hours',
  'scheduling/edit/config': 'Edit Config',

  'scheduling/edit/team/availability': 'Edit Hours',
  'scheduling/edit/team/config': 'Edit Config',

  /** ****************** Home ************************ */
  'home/network/panelTitle': 'Network Feed',
  'home/teams/panelTitle': 'Teams Feed',
  'home/organization/panelTitle': 'Organization Feed',
}

// Ideally labels should be field labels and corresponding help and error messages associated with the field itself
// this.showLabelsFromCode('')
