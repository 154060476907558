<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="form register-company-event">
    <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="false">
      <b-icon
        pack="fas"
        icon="spinner"
        size="is-large"
        custom-class="fa-pulse"
        type="is-primary"
      >
      </b-icon
    ></b-loading>
    <synform
      v-if="showForm"
      :title="panelTitle"
      :operation="registerForCompanyEvent"
      :button-text="panelTitle"
      :modal-form="true"
      style="max-width: 576px"
    >
      <b-notification
        v-if="showError"
        type="is-danger is-light"
        aria-close-label="Close notification"
        class="sm-help-notification"
        ><span v-html="errorMessage"></span
      ></b-notification>

      <ValidationProvider
        v-slot="{ errors, valid }"
        name="displayName"
        rules="required:true|min:2"
      >
        <b-field
          :label="showLabelsFromCode('profile/personalInfo/fullname')"
          class="required"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            ref="displayName"
            v-model.trim="form.displayName"
            name="displayName"
            :placeholder="showLabelsFromCode('profile/personalInfo/fullname')"
          />
        </b-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="email"
        rules="required|min:8|max:40|email"
      >
        <b-field
          :label="`Business ${showLabelsFromCode('form/generic/email')}`"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="
            errors[0]
              ? errors
              : 'Business email only, personal email are prohibited'
          "
          class="required"
        >
          <b-input
            ref="email"
            v-model.trim="form.email"
            type="email"
            name="email"
            placeholder="Valid Business Email Address is required"
          >
          </b-input>
        </b-field>
      </ValidationProvider>
      <b-field
        label="Phone"
        :message="showLabelsFromCode('generic/numbers-dashes')"
        expanded
      >
        <b-input
          v-model.trim="form.phone"
          name="phone"
          :validation-message="
            showLabelsFromCode('form/generic/phoneValidationMessage')
          "
          pattern="^\d+(?:-\d+)*$"
          @keydown.native.space.prevent
        />
      </b-field>
      <b-checkbox v-model="form.consentToContact"
        >The event organizer may use the above information to contact
        me.</b-checkbox
      >
    </synform>
    <div v-if="showButton" class="center-of-page">
      <div class="card has-text-centered" style="width: 376px">
        <header class="card-header p-3"><p class="title">Thank You!</p></header>
        <div class="card-content">
          <div class="content">
            <p>
              You are now attending <b>{{ companyEvent.eventName }}</b> on
              {{ formatDateTime(companyEvent.startDateTime) }}
              ({{ momentAgo(companyEvent.startDateTime) }}).
            </p>
            <b-button size="is-medium">
              <AddToCalendar
                :event-name="companyEvent.eventName"
                :meeting-u-r-l="currentURL"
                :event-description="companyEvent.eventDescription"
                :start-date-time="companyEvent.startDateTime"
                :end-date-time="companyEvent.endDateTime"
                addtext="Add to Calendar"
              >
              </AddToCalendar>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/extensions */

import { Buffer } from 'buffer'
import { editCompanyMixin } from '@/mixins/company/index'
import { sendEmail } from '@/misc/user-messages'
import AddToCalendar from '@/components/AddToCalendar'
// @ts-ignore
window.Buffer = Buffer
// eslint-disable-next-line import/no-extraneous-dependencies
const emailInspector = require('email-inspector')

export default {
  name: 'RegisterCompanyEvent',
  components: { AddToCalendar },
  mixins: [editCompanyMixin],
  props: {
    companyName: {
      type: String,
      default: null,
    },
    companyEvent: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      panelTitle: `Register for ${this.companyName}'s event`,
      isLoading: true,
      showError: false,
      showForm: true,
      showButton: false,
      errorMessage: '',
      form: {
        displayName: '',
        email: '',
        phone: '',
        consentToContact: true,
      },
    }
  },
  computed: {
    currentURL() {
      return window.location.href
    },
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    registerForCompanyEvent() {
      this.isLoading = true
      if (!emailInspector.validate(this.form.email)) {
        this.errorMessage = 'Sorry, personal email addresses are prohibited.'
        this.showError = true
        this.isLoading = false
      } else {
        let emailBody = ''
        if (this.form.consentToContact) {
          emailBody = `Hi ${this.companyEvent?.createdBy?.displayName}; ${
            this.form.displayName
          } has filled in the online registration form for your event '${
            this.companyEvent.eventName
          }'. They have agreed to share their information with you. You can add them to your attendees list by <a href="${
            process.env.VUE_APP_URL
          }view/company/${this.companyEvent.companyId}/event/${
            this.companyEvent.id
          }/addAttendee/email/${this.form.email}/name/${encodeURIComponent(
            this.form.displayName,
          )}/phone/${this.form.phone}">clicking here</a>`
        } else {
          emailBody = `Hi ${this.companyEvent?.createdBy?.displayName}; Someone registered online for your event '${this.companyEvent?.eventName}'. They did not provide the consent to share their information with you.`
        }
        sendEmail(
          this.companyEvent?.createdBy?.email,
          this.companyEvent?.createdBy?.displayName,
          'Siyn.Email Bot',
          'bot@siyn.email',
          emailBody,
        )
          .then(() => {
            this.showForm = false
            this.showButton = true
            this.isLoading = false
          })
          .catch((error) => {
            this.trackExceptionEvent('sending-email_failed', error, false)
            this.handleErrorMessage(
              `Error in sending the request please try again`,
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>
