<template>
  <section class="info">
    <full-page width-class="is-12" class="view-info">
      <skeleton-media v-if="isLoading"></skeleton-media>
      <SynPanel v-if="!isLoading && profileUser">
        <div class="columns is-gapless is-vcentered is-multiline">
          <div v-if="profileUser.licenseType === 'GUEST'" class="column is-12">
            <div class="info__guest-tag-wrapper">
              <span class="info__guest-tag-wrapper--tag"
                ><b-tag size="is-medium" type="is-info" class="br-6"
                  >Guest User</b-tag
                ></span
              >
            </div>
          </div>
          <div class="column is-4-tablet is-4-desktop">
            <template
              v-if="
                profileUser?.id === user?.id ||
                getProfileUserCompanyId === getAuthUserCompanyId
              "
            >
              <view-user-avatar
                id="view-info-avatar"
                class="info__photoURL"
                :alt-name="profileUser?.displayName"
                :imgsrc="profileUser?.photoURL"
              />
            </template>
            <template
              v-else-if="
                profileUser?.profilePicNetWorkOnly &&
                !checkIfYouAreInMyNetwork({
                  userId: profileUser?.id,
                  network: network,
                })
              "
            >
              <view-user-avatar
                id="view-info-avatar"
                class="info__photoURL"
                :alt-name="profileUser?.displayName"
                :imgsrc="getProfileUserGenericAvatar"
              />
            </template>
            <template v-else>
              <view-user-avatar
                id="view-info-avatar"
                class="info__photoURL"
                :alt-name="profileUser?.displayName"
                :imgsrc="profileUser?.photoURL"
              />
            </template>
            <ProfileButton class="is-hidden-touch"> </ProfileButton>
          </div>
          <div class="column is-8">
            <h1 class="info__displayName">{{ profileUser.displayName }}</h1>
            <p
              v-if="
                profileUser && profileUser.suffix && showPersonalInfoContent
              "
              class="info__suffix"
            >
              {{ profileUser.suffix || '' }}
            </p>
            <div
              v-if="profileUser && showPersonalInfoContent"
              class="info__other-fields"
            >
              <div class="columns is-multiline is-gapless">
                <div v-if="profileUser.pronouncedAs" class="column is-narrow">
                  <span class="info__label"
                    >{{
                      showLabelsFromCode('profile/personalInfo/pronouncedAs2')
                    }}:
                  </span>
                  <span class="info__value">{{
                    profileUser.pronouncedAs
                  }}</span>
                </div>
                <div v-if="profileUser.goesBy" class="column is-narrow">
                  <span class="info__label"
                    >{{ showLabelsFromCode('profile/personalInfo/goesBy') }}:
                  </span>
                  <span class="info__value">{{ profileUser.goesBy }}</span>
                </div>
                <div v-if="profileUser.pronoun" class="column is-narrow">
                  <span class="info__label"
                    >{{ showLabelsFromCode('profile/personalInfo/pronoun2') }}:
                  </span>
                  <span class="info__value">{{
                    getPronoun(profileUser.pronoun)
                  }}</span>
                </div>
              </div>
            </div>
            <span class="info__mandatory-fields">
              <span class="info__company">
                <span class="info__label info__company--label"
                  >{{ showLabelsFromCode('profile/personalInfo/works') }}
                </span>
                <span
                  v-if="profileUser?.employmentType && showPersonalInfoContent"
                  class=""
                >
                  <span class="info__value is-lowercase mr-0"
                    >{{ getEmploymentType(profileUser?.employmentType) }}
                  </span>
                </span>
                <span v-if="profileUser?.title">
                  <span class="info__label"
                    >{{ showLabelsFromCode('profile/personalInfo/jobtitle3') }}
                  </span>
                  <span class="info__value mr-1">{{ profileUser?.title }}</span>
                </span>
                <br class="is-hidden-desktop" />
                <span class="info__label">for</span>
                <template v-if="company && profileUser?.licenseType === 'ENT'">
                  <router-link
                    v-if="company?.id"
                    class="info__company--link"
                    :to="{
                      name: 'viewcompany',
                      params: { companyid: company?.id },
                    }"
                  >
                    <img
                      :src="company.logoImageURL"
                      :alt="company.company"
                      height="auto"
                      width="20px"
                      class="info__company--logo"
                    />
                    <span
                      v-if="company.companyName"
                      class="info__value info__company--name"
                      >{{ company.companyName }}</span
                    >
                    <span v-else class="info__value info__company--name">{{
                      company.companyName
                    }}</span>
                  </router-link>
                </template>
                <template v-else-if="profileUser?.companyName">
                  <span class="info__label"></span>
                  <span class="info__value ml-1">{{
                    profileUser.companyName
                  }}</span>
                </template>
              </span>
            </span>
            <div
              v-if="showPrivateInfoContent && (isPaidUser || isEnterpriseUser)"
              class="info__private"
            >
              <a v-if="showClickToReveal" @click="clickToRevealPrivateInfo()">
                <span class="info__private--emoji">🔒</span>
                <span class="info__private--label">{{
                  showLabelsFromCode('profile/privateInfo/showContactInfo')
                }}</span></a
              >
              <section>
                <b-message
                  v-model="messageIsActive"
                  class="bottomright highz"
                  type="is-danger"
                  size="is-small"
                  auto-close
                  progress-bar
                  :duration="duration"
                  aria-close-label="Close message"
                >
                  {{ showTooltipsFromCode('profile/private/privacyWarning') }}
                  {{ duration / 1000 }} seconds.
                </b-message>
              </section>
            </div>
            <ProfileButton class="is-hidden-desktop"> </ProfileButton>
          </div>
        </div>
      </SynPanel>
    </full-page>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations } from 'vuex'
import { viewProfileMixin } from '@/mixins/profile/index'
import SynPanel from '@/components/SynPanel.vue'
import ViewUserAvatar from '@/components/ViewUserAvatar'
import SkeletonMedia from '@/components/partials/SkeletonMedia'
import ProfileButton from '@/views/profile/ProfileButton'
import { employmentTypes } from '@/data/employmentTypes'
import { pronouns } from '@/data/pronouns'
// import { visibilityConstants } from '@/data/visibilityPreferences'
import {
  companyColorPersonalization,
  destroyColorPersonalization,
} from '@/misc/helpers'

export default {
  name: 'ViewUserInfo',
  components: { SynPanel, ViewUserAvatar, SkeletonMedia, ProfileButton },
  mixins: [viewProfileMixin], // register mixin
  props: {
    uid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pronouns,
      employmentTypes,
      panelTitle: this.showLabelsFromCode('profile/personalInfo/panelTitle'),
      messageIsActive: false,
      duration: 30000,
      showClickToReveal: true,
      timeout: null,
      isPrivateProfile: false,
      isLoading: true,
      avatarURLValue: '',
      defaultBGColor: process.env.VUE_APP_BODY_BGCOLOR,
      showPersonalInfoContent: false,
      showPrivateInfoContent: false,
    }
  },
  computed: {
    ...mapState('privateinfo', ['showPrivateInfoPanel']),
  },
  watch: {
    company: {
      handler(company) {
        if (company && company?.id) {
          companyColorPersonalization(company.bannerColor)
        }
      },
      deep: true,
      immediate: true,
    },
    mergedPanelsConfig: {
      handler(mergedPanelsConfig) {
        setTimeout(() => {
          if (!this.isEmpty(mergedPanelsConfig) && this.profileUser) {
            if (
              this.getAuthUserId === this.$route.params.uid ||
              (this.getAuthUserCompanyId === this.profileUser.companyId &&
                this.getAuthUserCompanyId !== null)
            ) {
              this.showPersonalInfoContent = true
              this.showPrivateInfoContent = true
            }
            if (
              mergedPanelsConfig.isPublicallyVisible === false &&
              this.profileUser.licenseType === 'ENT' &&
              this.getAuthUserCompanyId !== this.profileUser.companyId
            ) {
              this.showPersonalInfoContent = false
              this.showPrivateInfoContent = false
              this.setProfileNotFoundError(true)
            }
            if (
              mergedPanelsConfig.personalInfo ===
              this.visibilityConstants.VISIBLE_TO_ALL
            ) {
              // console.log(JSON.stringify('setting true here'))
              this.showPersonalInfoContent = true
            }
            if (
              mergedPanelsConfig.privateInfo ===
              this.visibilityConstants.VISIBLE_TO_ALL
            ) {
              this.showPrivateInfoContent = true
            }
            if (
              mergedPanelsConfig.personalInfo ===
                this.visibilityConstants.VISIBLE_TO_MY_NW &&
              this.checkIfYouAreInMyNetwork({
                userId: this.profileUser?.id,
                network: this.network,
              })
            ) {
              this.showPersonalInfoContent = true
            }
            if (
              mergedPanelsConfig.privateInfo ===
                this.visibilityConstants.VISIBLE_TO_MY_NW &&
              this.checkIfYouAreInMyNetwork({
                userId: this.profileUser?.id,
                network: this.network,
              })
            ) {
              this.showPrivateInfoContent = true
            }
          }
        }, 500)
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.isLoading = false
  },
  updated() {
    this.$nextTick(() => {
      if (this.company && this.company.bannerColor) {
        setTimeout(() => {
          companyColorPersonalization(this.company.bannerColor)
        }, '800')
      } else {
        companyColorPersonalization('#48beb4')
      }
    })
  },
  destroyed() {
    clearTimeout(this.timeout)
    destroyColorPersonalization()
  },
  methods: {
    ...mapMutations('app', ['setProfileNotFoundError']),
    ...mapMutations('privateinfo', [
      'setShowPrivateInfoPanel',
      'setPrivateContactInfo',
      'setPrivateChatInfo',
    ]),
    getPronoun(id) {
      return this.getNameForId(id, this.pronouns)
    },
    getEmploymentType(id) {
      return this.getNameForId(id, this.employmentTypes)
    },

    clickToRevealPrivateInfo() {
      this.setShowPrivateInfoPanel(true)
      this.messageIsActive = !this.messageIsActive
      this.showClickToReveal = false
      this.timeout = setTimeout(() => {
        this.setPrivateContactInfo(null)
        this.setPrivateChatInfo(null)
        this.setShowPrivateInfoPanel(false)
        this.showClickToReveal = true
      }, this.duration)
    },
  },
}
</script>

<style scoped lang="scss">
.bottomright {
  position: fixed;
  right: 0px;
  bottom: 0px;
}

.highz {
  z-index: 999;
}
.message-body {
  padding: $base-size !important;
}
.message.is-danger {
  background-color: $danger-background !important;
}
</style>
