// eslint-disable-next-line import/prefer-default-export
export class FirebaseErrors {
  static Parse(errorCode) {
    let message = ''

    switch (errorCode) {
      case 'auth/wrong-password':
        message = 'Invalid login credentials.'
        break
      case 'auth/email-already-in-use':
        message =
          "Email is already registered. Please <a href='/login'>login</a> to your account."
        break
      case 'auth/network-request-failed':
        message = 'Please check your internet connection'
        break
      case 'auth/too-many-requests':
        message =
          'We have detected too many requests from your device. Take a break please!'
        break
      case 'auth/user-disabled':
        message =
          'Your account has been disabled or deleted. Please contact the system administrator.'
        break
      case 'auth/requires-recent-login':
        message = 'Please login again and try again!'
        break
      case 'auth/email-already-exists':
        message = 'Email address is already in use by an existing user.'
        break
      case 'auth/user-not-found':
        message =
          'We could not find user account associated with the email address.'
        break
      case 'auth/invalid-email':
        message = 'The email address is not a valid email address!'
        break
      case 'auth/cannot-delete-own-user-account':
        message = 'You cannot delete your own user account.'
        break
      case 'auth/expired-action-code':
        message =
          'The password reset or email verificiation request has expired, please request again.'
        break
      case 'auth/invalid-action-code':
        message =
          'Invalid password reset or email verificiation request. This can happen if the requst URL has typo, is expired, or has already been used'
        break
      case 'auth/weak-password':
        message =
          'Weak password. Passwords should have at least 6 characters and a mix of letters and numbers'
        break
      case 'permission-denied':
        message = 'Permission Denied'
        break
      default:
        message = 'Oops! Something went wrong. Try again later.'
        break
    }

    return message
  }
}
