<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="view-company-broadcasts">
    <skeleton v-if="isLoading"></skeleton>
    <siyn-collapse
      v-if="companyToView && !isLoading"
      :title="panelTitle"
      emoji="📣"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <template #header-extras>
        <b-tooltip
          multilined
          class="mx-4"
          type="is-dark"
          label="Broadcasts are short texts with a lifespan of 2 days. They stay on top of dashboard column. All employees will receive the message on all possible channels."
          position="is-right"
          ><b-icon
            class="has-text-grey-lighter"
            pack="fas"
            icon="info-circle"
            size="is-small"
          >
          </b-icon
        ></b-tooltip>
      </template>
      <template #default>
        <b-table
          v-if="companyBroadcasts"
          :data="companyBroadcasts"
          hoverable
          mobile-cards
          default-sort="createTimestamp"
          default-sort-direction="desc"
          class="view-company-broadcasts__table"
        >
          <b-table-column
            v-slot="props"
            scope="props"
            field="broadcastText"
            :label="`${showLabelsFromCode(
              'teams/broadcast',
            )} ${showLabelsFromCode('generic/text')}`"
          >
            {{ truncateText(props.row.broadcastText, 75) }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            sortable
            field="createTimestamp"
            scope="props"
            :label="showLabelsFromCode('generic/created')"
            >{{ momentAgo(props.row.createTimestamp) }}
          </b-table-column>
          <b-table-column
            v-if="isUserCompanyAdmin"
            v-slot="props"
            scope="props"
            field="id"
            :label="showLabelsFromCode('generic/action')"
            centered
            ><a @click="deleteCompanyBroadcastVue(props.row)">
              <b-tooltip
                :label="showLabelsFromCode('generic/delete')"
                position="is-left"
                type="is-dark"
              >
                <b-icon
                  pack="fa"
                  icon="trash-alt"
                  type="is-danger"
                  size="is-small"
                >
                </b-icon>
              </b-tooltip>
            </a>
          </b-table-column>
        </b-table>
        <div
          v-if="isUserCompanyAdmin"
          class="has-text-center-touch has-text-right"
        >
          <template v-if="timeDifference > 0">
            <b-button
              type="is-grey"
              class="info__edit-button has-text-white"
              title="Wait"
              >Wait {{ timerOutput }} to add new.
            </b-button>
          </template>
          <template v-else>
            <b-button
              type="is-danger"
              class="info__edit-button background-color-override"
              title="Add Company Broadcast"
              :disabled="disableCreateButton"
              @click.stop.prevent="openAddCompanyBroadcastModal"
              >{{ showLabelsFromCode('generic/create') }} a
              {{ showLabelsFromCode('teams/broadcast') }}
            </b-button>
          </template>
        </div>
      </template>
    </siyn-collapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import first from 'lodash/first'
import { mapState, mapMutations, mapActions } from 'vuex'
import { viewCompanyMixin } from '@/mixins/company/index'
import { truncateText } from '@/misc/helpers'

import AddCompanyBroadcastModalForm from './AddCompanyBroadcastModalForm'

export default {
  name: 'CompanyBroadcasts',
  components: {},

  mixins: [viewCompanyMixin],
  data() {
    return {
      panelTitle: this.showLabelsFromCode('company/broadcasts/panelTitle'),
      isLoading: true,
      panelname: 'companyBroadcasts_view',
      createPlusHour: 0,
      timerOutput: 0,
      timeDifference: 0,
      disableCreateButton: false,
    }
  },
  computed: {
    ...mapState('company', ['companyBroadcasts']),
  },
  created() {},
  async mounted() {
    this.isLoading = false
    await this.fetchCompanyBroadcastsVue()

    if (
      this.companyBroadcasts[0] &&
      this.companyBroadcasts[0].createTimestamp
    ) {
      const temp = first(this.companyBroadcasts.slice(0))
      this.createPlusHour = this.addHours(temp.createTimestamp, 1)
    }
    if (this.timeDifference >= 0) {
      setInterval(() => {
        this.startTimer()
      }, 1000)
    }
  },
  methods: {
    ...mapMutations('company', ['setCompanyToEdit']),
    ...mapMutations('company', [
      'setCompanyBroadcastToEdit',
      'setCompanyBroadcastToRemove',
      'setCompanyBroadcasts',
    ]),
    ...mapActions('company', [
      'deleteCompanyBroadcast',
      'fetchCompanyBroadcastsByCompanyId',
      'addCompanyUpdate',
    ]),

    async fetchCompanyBroadcastsVue() {
      const orderBys = []
      orderBys.push(['createTimestamp', 'desc'])
      const limit = 6
      await this.fetchCompanyBroadcastsByCompanyId({
        companyId: this.companyToView.id,
        undefined,
        orderBys,
        limit,
      })
        .then((response) => {
          // console.log(JSON.stringify(response))
          this.setCompanyBroadcasts(response)
        })
        .catch((error) => {
          console.log(error)
          throw error
        })
    },

    startTimer() {
      if (this.companyBroadcasts[0] && this.createPlusHour) {
        // console.log(JSON.stringify(this.companyBroadcasts[0]))
        // console.log(JSON.stringify(this.createPlusHour))
        const timeNow = new Date().getTime()
        this.timeDifference = this.createPlusHour - timeNow
        const millisecondsInOneSecond = 1000
        const millisecondsInOneMinute = millisecondsInOneSecond * 60
        const millisecondsInOneHour = millisecondsInOneMinute * 60
        const remainderDifferenceInMinutes =
          (this.timeDifference % millisecondsInOneHour) /
          millisecondsInOneMinute
        const remainderDifferenceInSeconds =
          (this.timeDifference % millisecondsInOneMinute) /
          millisecondsInOneSecond
        const remainingMinutes = Math.floor(remainderDifferenceInMinutes)
        const remainingSeconds = Math.floor(remainderDifferenceInSeconds)
        this.timerOutput = `${remainingMinutes}m : ${remainingSeconds}s`
      }
    },
    openAddCompanyBroadcastModal() {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(
          'Are you sure you want to broadcast a message to all company employees?',
        )
      ) {
        this.setCompanyToEdit(this.companyToView)
        this.$buefy.modal.open({
          parent: this,
          hasModalCard: true,
          component: AddCompanyBroadcastModalForm,
          events: {
            added_successfully: (newBroadcast) => {
              // console.log(JSON.stringify(newBroadcast))
              this.disableCreateButton = true
              const updateRecord = {
                action: 'added',
                createdBy: this.getTinyUser,
                message: newBroadcast.broadcastText,
                module: 'broadcast',
                type: 'company',
                priority: 1,
                company: {
                  id: this.companyToView.id,
                  name: this.companyToView.companyName,
                },
              }
              this.addCompanyUpdate({
                updateRecord,
                companyId: this.getAuthUserCompanyId,
              }).catch((err) => {
                this.trackExceptionEvent(
                  'companyupdate_add_failed',
                  err.message,
                  false,
                )
              })
              this.handleSuccessMessage()
            },
          },
          trapFocus: true,
        })
      }
    },
    deleteCompanyBroadcastVue(companyBroadcastRecord) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete the broadcast?')) {
        this.deleteCompanyBroadcast({
          companyBroadcastId: companyBroadcastRecord.id,
          companyId: this.companyToView.id,
        })
          .then(() => {
            const updateRecord = {
              action: 'deleted',
              createdBy: this.getTinyUser,
              message: companyBroadcastRecord.broadcastText,
              module: 'broadcast',
              type: 'company',
              priority: 1,
              company: {
                id: this.companyToView.id,
                name: this.companyToView.companyName,
              },
            }
            this.addCompanyUpdate({
              updateRecord,
              companyId: this.getAuthUserCompanyId,
            }).catch((err) => {
              this.trackExceptionEvent(
                'companyupdate_add_failed',
                err.message,
                false,
              )
            })
            this.timeDifference = -1
            this.disableCreateButton = false
            this.setCompanyBroadcastToRemove(companyBroadcastRecord.id)
            this.handleSuccessMessage()
          })
          .catch((err) => {
            this.trackExceptionEvent(
              'team_broadcast_delete_failed',
              err.message,
              false,
            )
            this.handleErrorMessage(
              this.showUserMessageFromCode('teams/delete/teambroadcast/error'),
            )
            console.log(err.message)
          })
      }
    },
    truncateText,
    addHours(date, hours) {
      const dateCopy = new Date(date.getTime())
      const hoursToAdd = hours * 60 * 60 * 1000
      dateCopy.setTime(date.getTime() + hoursToAdd)
      return dateCopy
    },
  },
}
</script>
