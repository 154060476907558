const teamMemberEventStatusConstants = {
  SUBSCRIBED: 1,
  INVITED: 2,
}

const teamMemberEventStatus = [
  { id: teamMemberEventStatusConstants.SUBSCRIBED, name: 'Subscribed' },
  { id: teamMemberEventStatusConstants.INVITED, name: 'Invited' },
]

export { teamMemberEventStatusConstants, teamMemberEventStatus }
