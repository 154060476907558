<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="view-mentoring-topic">
    <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="false">
      <b-icon
        pack="fas"
        icon="spinner"
        size="is-large"
        custom-class="fa-pulse"
        type="is-primary"
      >
      </b-icon
    ></b-loading>
    <Synform
      v-if="topicToView && !isLoading"
      :title="panelTitle"
      :operation="sendSessionRequest"
      :button-text="buttonText"
      :modal-form="true"
      :state-flag="!areYouSure"
    >
      <b-notification
        v-if="showError"
        type="is-danger is-light"
        aria-close-label="Close notification"
        class="sm-help-notification"
        ><span v-html="errorMessage"></span
      ></b-notification>

      <div class="columns is-multiline is-vcentered mb-0 is-gapless">
        <div class="column is-12">
          <span class="sm-label mr-2"
            >{{ showLabelsFromCode('generic/title') }}:
          </span>
          <span class="sm-value">{{ topicToView.topicTitle }}</span>
        </div>
        <div class="column is-12">
          <span class="sm-label mr-2"
            >{{ showLabelsFromCode('form/mentoring/topic/description') }}:
          </span>
          <span class="sm-value">{{ topicToView.description }}</span>
        </div>
        <div class="column is-12">
          <span class="sm-label mr-2"
            >{{ showLabelsFromCode('form/mentoring/topic/duration') }}:
          </span>
          <span class="sm-value">{{ topicToView.duration }} mins.</span>
        </div>
        <div class="column is-12">
          <span class="has-text-danger has-text-weight-bold is-underlined"
            >{{ showLabelsFromCode('form/mentoring/topic/prereqs') }}:
          </span>
        </div>
        <div class="column is-12">
          <span class="sm-value" v-html="topicToView.prerequisties"></span>
        </div>
      </div>
      <b-field>
        <b-message type="is-dark">
          <b-checkbox v-model="areYouSure" type="is-danger">
            <span v-if="topicToView.prerequisties">
              I confirm that I meet all prerequisties listed above. Please send
              request to
              <b>{{ profileUser.displayName }}</b> for a mentoring session on
              <b>{{ topicToView.topicTitle }}</b>
            </span>
            <span v-else>
              I confirm to sending {{ profileUser.displayName }} a mentoring
              session request via email.
            </span>
          </b-checkbox>
        </b-message>
      </b-field>
    </Synform>
  </div>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapActions, mapGetters } from 'vuex'
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import Synform from '@/components/SynForm'
import { showLabelsFromCode, showUserMessageFromCode } from '@/misc/helpers'
import { sendEmail, handleSuccessMessage } from '@/misc/user-messages'
import { trackExceptionEvent } from '@/misc/analytics'
import { notificationActionTypesConstants } from '@/data/notificationActionTypes'
import { functions } from '@/firebase/initFirebase'

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
}

export default {
  name: 'ViewMentoringTopicModal',
  components: { Synform },
  data() {
    return {
      panelTitle: showLabelsFromCode('profile/mentoring/send/request'),
      buttonText: showLabelsFromCode('form/generic/sendrequest'),
      areYouSure: false,
      isLoading: true,
      showError: '',
      errorMessage: '',
      notificationActionTypesConstants,
    }
  },
  computed: {
    ...mapState('mentoring', ['topicToView']),
    ...mapState('user', ['user']),
    ...mapState('profileUser', ['profileUser']),
    ...mapGetters('user', ['getAuthUserId']),
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    showLabelsFromCode,
    showUserMessageFromCode,
    handleSuccessMessage,
    async sendSessionRequest() {
      this.isLoading = true
      const emailMessage = `${this.user.displayName} (email: ${this.user.email}) requested a mentoring session with you on topic : <b>${this.topicToView.topicTitle}</b>. If you are willing then please create a new mentoring session at ${process.env.VUE_APP_URL}mentoring-session/request/${this.profileUser.id}/${this.user.id}/${this.topicToView.id} ; if not then please ignore this message.`

      const notificationMessage = `${this.user.displayName} requested a mentoring session on ${this.topicToView.topicTitle}. <a href="${process.env.VUE_APP_URL}mentoring-session/request/${this.profileUser.id}/${this.user.id}/${this.topicToView.id}">Click here</a> to create a mentoring session. See email for more information.`

      const notification = {
        type: this.notificationActionTypesConstants.REQUESTED,
        createdBy: {
          id: this.user.id,
          displayName: this.user.displayName,
        },
        message: notificationMessage,
      }
      this.addNotification({
        userId: this.profileUser.id,
        notification,
      })
      const notitificationRecord = {
        title: `💪🏻 Mentoring Request`,
        body: `${this.user.displayName} requested a mentoring session on ${this.topicToView.topicTitle}`,
        link: `${process.env.VUE_APP_URL}mentoring-session/request/${this.profileUser.id}/${this.user.id}/${this.topicToView.id}`,
      }
      const sendNotificationFunction = httpsCallable(
        functions,
        'sendNotification',
      )
      try {
        await sendNotificationFunction({
          uid: this.profileUser.id,
          notitificationRecord,
        })
      } catch (error) {
        console.log(error)
      }
      sendEmail(
        this.profileUser.email,
        this.profileUser.displayName,
        this.user.displayName,
        this.user.email,
        emailMessage,
      )
        .then(() => {
          this.close()
          this.handleSuccessMessage(
            this.showUserMessageFromCode(
              'mentoring/session/request/email/success',
            ),
          )
        })
        .catch((error) => {
          trackExceptionEvent(
            'sessionrequest_email_sent_failed',
            error.text,
            false,
          )
          this.showError = true
          this.errorMessage = this.showUserMessageFromCode(
            'mentoring/session/request/email/error',
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.column {
  margin-bottom: $min-size !important;
}
</style>
