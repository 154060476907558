const teamStatusConstants = {
  ACTIVE: 1,
  INACTIVE: 2,
  ARCHIVED: 3,
  UNKNOWN: 99,
}

const teamStatusList = [
  { id: teamStatusConstants.ACTIVE, name: 'Active' },
  { id: teamStatusConstants.INACTIVE, name: 'Inactive' },
  { id: teamStatusConstants.ARCHIVED, name: 'Archived' },
  { id: teamStatusConstants.UNKNOWN, name: 'Unknown' },
]
export { teamStatusConstants, teamStatusList }
