/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import { extend } from 'vee-validate'

import {
  required,
  confirmed,
  min,
  email,
  alpha_spaces,
  alpha_num,
  numeric,
  max,
  regex,
} from 'vee-validate/dist/rules'

extend('min', {
  ...min,
  message: 'This field must be at least {length} characters',
})
extend('max', {
  ...max,
  message: 'This field has exceeded maximum number of allowed characters',
})
extend('required', {
  ...required,
  message: 'This field is required',
})

extend('email', {
  ...email,
  message: 'This field must be a valid email',
})

extend('confirmed', {
  ...confirmed,
  message: 'Password fields do not match',
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'This field must be alphabetic',
})

extend('alpha_num', {
  ...alpha_num,
  message: 'This field must only have alphabets or numbers',
})

extend('numeric', {
  ...numeric,
  message: 'This field must only have numbers',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})
