export default {
  setTeams: (state, teams) => (state.teams = teams),

  setTeamToView: (state, teamToView) => (state.teamToView = teamToView),

  setIsUserTeamAdminCache: (state, value) =>
    (state.isUserTeamAdminCache = value),

  setIsUserPartOfTeamCache: (state, value) =>
    (state.isUserPartOfTeamCache = value),

  setIsUserATeamSubscriber: (state, value) =>
    (state.isUserATeamSubscriber = value),

  addSubscriberToTeamToView(state, subscriber) {
    if (state.teamToView.subscribers) {
      state.teamToView.subscribers.push(subscriber)
    } else {
      state.teamToView.subscribers = []
      state.teamToView.subscribers.push(subscriber)
    }
  },

  removeSubscriberById: (state, subscriberId) => {
    const index = state.teamToView.subscribers.findIndex(
      (subscriber) => subscriber === subscriberId,
    )
    state.teamToView.subscribers.splice(index, 1)
  },

  setIsUserInSameCompanyAsTeam: (state, value) =>
    (state.isUserInSameCompanyAsTeam = value),

  addToTeams: (state, team) => state.teams.push(team),

  setTeamNotFoundError: (state, value) =>
    (state.teamErrors.teamNotFound = value),

  setUnknownError: (state, value) => (state.teamErrors.unknown = value),

  setTeamToEdit: (state, teamToEdit) => (state.teamToEdit = teamToEdit),

  updateStateTeamLogoPhoto: (state, value) =>
    (state.teamToView.logoImageURL = value),

  setTeamAdmins: (state, teamAdmins) => (state.teamAdmins = teamAdmins),

  addTeamAdmin: (state, teamAdmins) => state.teamAdmins.push(teamAdmins),
}
