<template>
  <section class="list-company-admins">
    <div :panel-title="panelTitle">
      <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="false">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon
      ></b-loading>
      <div v-if="!isLoading && companyAdmins">
        <b-table
          :data="companyAdmins"
          hoverable
          mobile-cards
          class="list-company-admins__table"
          :show-header="companyAdmins.length > 0 ? true : false"
          default-sort="createTimestamp"
          default-sort-direction="desc"
        >
          <template #empty>
            <p>{{ showUserMessageFromCode('generic/none') }}</p>
          </template>
          <b-table-column
            v-slot="props"
            scope="props"
            field="displayName"
            :label="showLabelsFromCode('generic/name')"
            cell-class="valign"
            sortable
          >
            <router-link
              class="no-text-decoration"
              :to="{
                name: 'profile',
                params: {
                  uid: props.row.id,
                },
              }"
              replace
            >
              <span class="ml-2">
                {{ props.row.displayName }}
              </span></router-link
            >
          </b-table-column>
          <b-table-column
            v-slot="props"
            scope="props"
            field="createTimestamp"
            label="Added On"
            cell-class="valign"
            sortable
          >
            <span
              :title="new Date(props.row.createTimestamp).toLocaleString()"
              class="team-updates__table--when"
            >
              {{ humanReadableDateTime(props.row.createTimestamp) }}
            </span>
          </b-table-column>
          <b-table-column
            v-slot="props"
            scope="props"
            field="createdBy.displayName"
            label="Added By"
            cell-class="valign"
            sortable
          >
            <router-link
              class="no-text-decoration"
              :to="{
                name: 'profile',
                params: {
                  uid: props.row?.createdBy?.id,
                },
              }"
              replace
            >
              <span class="ml-2">
                {{ props.row?.createdBy?.displayName }}
              </span></router-link
            >
          </b-table-column>
          <b-table-column
            v-slot="props"
            :label="showLabelsFromCode('generic/delete')"
            cell-class="valign"
            centered
          >
            <span v-if="props.row.id !== getAuthUserId">
              <a
                class="no-text-decoration"
                @click="deleteCompanyAdmin(props.row)"
              >
                <b-tooltip
                  :label="showTooltipsFromCode('profile/events/deleteevent')"
                  position="is-left"
                  type="is-dark"
                >
                  <b-icon
                    icon="trash-alt"
                    pack="fa"
                    type="is-danger"
                    size="is-small"
                  >
                  </b-icon>
                </b-tooltip>
              </a>
            </span>
          </b-table-column>
        </b-table>
      </div>
      <div class="button-group has-text-centered mt-4">
        <b-field grouped position="is-centered">
          <div class="control">
            <b-button pack="far" type="is-info" class="button" @click="close()">
              Close
            </b-button>
          </div>
        </b-field>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapActions, mapMutations } from 'vuex'
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import { trackPageView, trackScreenView } from '@/misc/analytics'

import { editCompanyMixin } from '@/mixins/company/index'
import { functions } from '@/firebase/initFirebase'
import { sendEmail } from '@/misc/user-messages'

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
}

export default {
  name: 'ListcompanyAdminsForEdit',
  components: {},
  mixins: [editCompanyMixin],
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      panelTitle: 'List Company Admins for edit',
      isLoading: true,
    }
  },
  metaInfo: {
    title: 'List Company Admins for Edit',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  computed: {
    ...mapState('company', ['companyToView', 'companyAdmins', 'companyToEdit']),
  },
  created() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapActions('company', ['updateCompanyAdminStatus']),
    ...mapMutations('company', ['removeCompanyAdminById']),
    close() {
      this.$emit('close')
    },
    async deleteCompanyAdmin(companyAdmin) {
      this.isLoading = true
      if (
        // eslint-disable-next-line no-alert
        window.confirm('Are you sure you want to delete the company admin')
      ) {
        console.log(JSON.stringify(`Delete Admin id ${companyAdmin.id}`))
        const removeCompanyAdmin = httpsCallable(
          functions,
          'removeCompanyAdmin',
        )
        await removeCompanyAdmin({ companyAdmin })
          .then(async () => {
            sendEmail(
              companyAdmin.email,
              companyAdmin.displayName,
              this.user.displayName,
              this.user.email,
              `${this.user.displayName} has removed you a company admin for ${this.companyToEdit.companyName} <a href="${process.env.VUE_APP_URL}view/company/${this.companyToEdit.id}">Click here</a> to view the company page.`,
            ).catch((error) => {
              this.handleErrorMessage(
                `Error in sending email to ${companyAdmin.displayName}`,
              )
              this.trackExceptionEvent(
                'delete-company-admin_email_sent_failed',
                error.text,
                false,
              )
            })
            const notitificationRecord = {
              title: `👑 Company Admin removed`,
              body: `${this.user.displayName} removed you as the company admin for ${this.companyToEdit.companyName}`,
              link: `${process.env.VUE_APP_URL}view/company/${this.companyToEdit.id}`,
            }
            const sendNotificationFunction = httpsCallable(
              functions,
              'sendNotification',
            )
            try {
              await sendNotificationFunction({
                uid: companyAdmin.id,
                notitificationRecord,
              })
            } catch (error) {
              console.log(error)
            }
            this.handleSuccessMessage(
              `${companyAdmin.displayName} has been deleted as company admin`,
            )
            this.removeCompanyAdminById(companyAdmin.id)
            this.close()
          })
          .catch((error) => {
            console.log(error)
            this.handleErrorMessage(
              `Error in setting company admin, please try again - ${error.code}`,
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }
      // this.updateCompanyAdminStatus({
      //   id: companyAdminId,
      //   isEnabled,
      //   companyId: this.companyToEdit.id,
      // })
      //   .then(() => {
      //     this.trackEngagementEvent(
      //       'companyAdminStatus_updated',
      //       `Status Update for ${adminDisplayName} in company ${companyAdminId} was successful`,
      //       'page',
      //       'switch',
      //     )
      //     this.handleSuccessMessage(
      //       isEnabled ? 'Admin Enabled Successfully' : 'Admin Disabled Successfully',
      //     )
      //     this.enableDisableCompanyAdminById({
      //       companyAdminId,
      //       isEnabled,
      //     })
      //     this.$emit('update_successful', adminDisplayName)
      //     this.close()
      //   })
      //   .catch((err) => {
      //     this.trackExceptionEvent('companyAdminStatus_update_failed', err.message, false)
      //   })
    },
  },
}
</script>
