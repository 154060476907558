import { collection, getDocs, query, where } from 'firebase/firestore'

import GenericDB from './generic-db'
import { db } from './initFirebase'

export default class UserNetworkDB extends GenericDB {
  constructor(userId) {
    super(`users/${userId}/network`)
  }

  // Here you can extend UserNetworkDB with custom methods
  /**
   * Read events of x days
   * @param status
   */
  async readNetwork(status = 2) {
    const collectionRef = collection(db, this.collectionPath)
    // console.log(this.collectionPath)
    const query1 = query(collectionRef, where('status', '==', status))
    const formatResult = (result) =>
      result.docs.map((ref) =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data(),
        }),
      )

    return getDocs(query1).then(formatResult)
  }
}
