export default {
  /* set */
  setEmailHabits: (state, emailhabits) => (state.emailhabits = emailhabits),

  /* add */
  addEmailHabits: (state, emailHabits) => state.emailHabits.push(emailHabits),
  /* remove */
  removeEmailHabitsById: (state, emailHabitsId) => {
    const index = state.emailHabits.findIndex(
      (emailHabits) => emailHabits.id === emailHabitsId
    )
    state.emailHabits.splice(index, 1)
  },
  SetEmailHabitsAddPending: (state, value) =>
    (state.emailHabitsAddPending = value),

  SetEmailHabitsUpdatePending: (state, value) =>
    (state.emailHabitsUpdatePending = value),

  /** ************** NEW ******************* */
  /* set */
  setEmailPreferences: (state, emailPreferences) =>
    (state.emailPreferences = emailPreferences),
}
