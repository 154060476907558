/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/extensions
// import UserEventsDB from '@/firebase/user-events-db'
import isEmpty from 'lodash/isEmpty'
import { arrayUnion, arrayRemove } from 'firebase/firestore'

import UserEventsDB from '@/firebase/user-events/events-db'
import UserUpdatesDB from '@/firebase/notifications/user-updates-db'
import TeamBroadcastsDB from '@/firebase/teams/team-broadcasts'
import TeamBulletinsDB from '@/firebase/teams/team-bulletins'
import TeamEventsDB from '@/firebase/teams/team-events-db'
import TeamUpdatesDB from '@/firebase/teams/team-updates'

import { showUserMessageFromCode } from '@/misc/helpers'
import { teamEventStatusConstants } from '@/data/teams/team-event-status'

export default {
  fetchNetworkEventsAction: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const { network } = payload
    const { userId } = payload

    const allNetworkEvents = []

    let end = null
    if (payload.endDate) {
      end = payload.endDate
    } else {
      const endDays = payload.endDays || 7
      end = new Date()
      end.setDate(end.getDate() + endDays)
    }

    const start = new Date()
    // start = new Date(start.getFullYear(), start.getMonth(), start.getDate() - 1, 0, 0, 0)

    const constraints = []
    constraints.push(['endDateTime', '>=', start])
    constraints.push(['endDateTime', '<=', end])
    // constraints.push(['eventCategory', '==', 'individual'])

    const orderBys = []
    orderBys.push(['endDateTime', 'asc'])
    const limit = null
    // console.log(JSON.stringify(constraints))
    // eslint-disable-next-line no-restricted-syntax
    for (const person of network) {
      // console.log(JSON.stringify(person.friend.displayName))
      const userEventsDB = new UserEventsDB(person.id)
      // eslint-disable-next-line no-await-in-loop
      await userEventsDB
        .readAllWithOrderByLimit(constraints, orderBys, limit)
        .then((response) => {
          if (response && response.length > 0) {
            // console.log(response)
            const tempEvent = {
              person,
              events: response,
            }
            // console.log(JSON.stringify(tempEvent))
            allNetworkEvents.push(tempEvent)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    return allNetworkEvents
    // console.log(JSON.stringify(personWithEventsArray))
  },
  fetchNetworkUpdatesAction: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const { network } = payload
    const { userId } = payload

    const allNetworkUpdates = []

    let end = new Date()
    end = new Date(
      end.getFullYear(),
      end.getMonth(),
      end.getDate() - 7,
      0,
      0,
      0,
    )

    const constraints = []
    constraints.push(['showOnDashboard', 'in', [true]])
    // constraints.push(['eventCategory', '==', 'individual'])

    // const orderBys = []
    // orderBys.push(['createTimestamp', 'asc'])
    // const limit = null
    // console.log(JSON.stringify(constraints))
    // eslint-disable-next-line no-restricted-syntax
    for (const person of network) {
      constraints.push([
        'createTimestamp',
        '>=',
        end >= person.updateTimestamp ? end : person.updateTimestamp,
      ])
      // console.log(JSON.stringify(constraints))
      const userUpdatesDB = new UserUpdatesDB(person.id)
      // eslint-disable-next-line no-await-in-loop
      await userUpdatesDB
        .readAll(constraints)
        .then((response) => {
          if (response && response.length > 0) {
            //  console.log(response)
            allNetworkUpdates.push(response)
          }
        })
        .catch((err) => {
          throw err
        })
      constraints.pop()
    }
    return allNetworkUpdates
  },
  fetchUserTeamEventsAction: async ({ commit }, payload) => {
    //     console.log(JSON.stringify(payload))
    const { teams } = payload
    const { userId } = payload

    const allTeamEvents = []
    const limit = 3

    let end = null
    if (payload.endDate) {
      end = payload.endDate
    } else {
      const endDays = payload.endDays || 7
      end = new Date()
      end.setDate(end.getDate() + endDays)
    }

    let start = new Date()
    start = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate() - 1,
      0,
      0,
      0,
    )

    const constraints = []
    constraints.push(['endDateTime', '>=', start])
    constraints.push(['endDateTime', '<=', end])
    constraints.push(['eventStatus', '==', teamEventStatusConstants.SCHEDULED])
    constraints.push(['eventOccurence', '==', 'onetime'])
    const orderBys = []
    orderBys.push(['endDateTime', 'asc'])

    const teamEventsDb = new TeamEventsDB()

    // eslint-disable-next-line no-restricted-syntax
    for (const team of teams) {
      constraints.push(['team.id', '==', team.id])
      // eslint-disable-next-line no-await-in-loop
      await teamEventsDb
        .readAll(constraints)
        .then((response) => {
          allTeamEvents.push(response)
        })
        .catch((err) => {
          throw err
        })
      constraints.pop()
    }
    return allTeamEvents
  },

  fetchUserTeamRecurringEventsAction: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const { teams } = payload
    const { userId } = payload

    const allRecurringTeamEvents = []
    const limit = 3

    let end = null
    if (payload.endDate) {
      end = payload.endDate
    } else {
      const endDays = payload.endDays || 185
      end = new Date()
      end.setDate(end.getDate() + endDays)
    }

    let start = new Date()
    start = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate() - 1,
      0,
      0,
      0,
    )

    const constraints = []
    constraints.push(['endDateTime', '>=', start])
    constraints.push(['endDateTime', '<=', end])
    constraints.push(['eventStatus', '==', teamEventStatusConstants.SCHEDULED])
    constraints.push(['eventOccurence', '==', 'recurring'])
    const orderBys = []
    orderBys.push(['endDateTime', 'asc'])

    const teamEventsDb = new TeamEventsDB()

    // eslint-disable-next-line no-restricted-syntax
    for (const team of teams) {
      constraints.push(['team.id', '==', team.id])
      //  console.log(JSON.stringify(constraints))
      // eslint-disable-next-line no-await-in-loop
      await teamEventsDb
        .readAll(constraints)
        .then((response) => {
          allRecurringTeamEvents.push(response)
        })
        .catch((err) => {
          throw err
        })
      constraints.pop()
    }
    return allRecurringTeamEvents
  },

  fetchSubscribedTeamEventsAction: async ({ commit }, user) => {
    let end = null
    const endDays = 7
    end = new Date()
    end.setDate(end.getDate() + endDays)

    let start = new Date()
    start = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate() - 1,
      0,
      0,
      0,
    )

    const constraints = []
    constraints.push(['endDateTime', '>=', start])
    constraints.push(['endDateTime', '<=', end])
    constraints.push(['eventStatus', '==', teamEventStatusConstants.SCHEDULED])
    constraints.push(['subscribers', 'array-contains', user.id])
    constraints.push(['companyId', '==', user.companyId])
    const orderBys = []
    orderBys.push(['endDateTime', 'asc'])

    const teamEventsDb = new TeamEventsDB()

    await teamEventsDb
      .readAll(constraints)
      .then((response) => {
        return response
      })
      .catch((err) => {
        console.log(JSON.stringify(err))
        throw err
      })
  },
  fetchUserTeamBroadcastsAction: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const { teams } = payload
    const { userId } = payload

    const allTeamBroadcasts = []
    const limit = 3

    const today = new Date()
    const endDateTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      today.getHours() - 12,
      0,
      0,
    )
    const constraints = []
    constraints.push(['createTimestamp', '>', endDateTime])
    // eslint-disable-next-line no-restricted-syntax
    for (const team of teams) {
      const teamBroadcastsDB = new TeamBroadcastsDB(team.id)
      // console.log(JSON.stringify(team))
      // eslint-disable-next-line no-await-in-loop
      await teamBroadcastsDB
        .readAll(constraints)
        .then((response) => {
          // console.log(JSON.stringify(`Response is`))
          // console.log(JSON.stringify(response[0]))
          // console.log(JSON.stringify(`Today is ${today}`))
          // console.log(JSON.stringify(`EndDateTime is ${endDateTime}`))
          allTeamBroadcasts.push(response)
        })
        .catch((err) => {
          console.log(JSON.stringify(err))
          throw err
        })
    }
    return allTeamBroadcasts
  },
  fetchUserTeamBulletinsAction: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const { teams } = payload
    const { userId } = payload

    const allTeamBulletins = []

    const today = new Date()
    const twoWeeksAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 14, // show only for 2 weeks
      today.getHours(),
      0,
      0,
    )
    const constraints = []
    constraints.push(['createTimestamp', '>=', twoWeeksAgo])

    // console.log(JSON.stringify(constraints))
    // eslint-disable-next-line no-restricted-syntax
    for (const team of teams) {
      const teamBulletinsDB = new TeamBulletinsDB(team.id)
      // constraints.push(['team.id', '==', team.id])
      // console.log(JSON.stringify(team))
      // eslint-disable-next-line no-await-in-loop
      await teamBulletinsDB
        .readAll(constraints)
        .then((response) => {
          //  console.log(response)
          allTeamBulletins.push(response)
        })
        .catch((err) => {
          throw err
        })
    }
    return allTeamBulletins
  },
  fetchUserTeamUpdatesAction: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const { teams } = payload
    const { userId } = payload

    const allTeamUpdates = []
    const constraints = []

    constraints.push(['showOnDashboard', '==', true])
    const orderBys = []
    orderBys.push(['createTimestamp', 'desc'])
    // const limit = Math.floor(Math.random() * 3) // limit max 3 but generate random:)
    const limit = 3 // limit max 3 but generate random:)

    // console.log(JSON.stringify(constraints))
    // eslint-disable-next-line no-restricted-syntax
    for (const team of teams) {
      const teamUpdatesDB = new TeamUpdatesDB(team.id)
      // console.log(JSON.stringify(team))
      // console.log(JSON.stringify(constraints))
      // console.log(JSON.stringify(orderBys))
      // console.log(JSON.stringify(limit))
      // eslint-disable-next-line no-await-in-loop
      await teamUpdatesDB
        .readAllWithOrderByLimit(constraints, orderBys, limit)
        .then((response) => {
          // console.log(response)
          allTeamUpdates.push(response)
        })
        .catch((err) => {
          throw err
        })
    }
    return allTeamUpdates
  },

  updateReaction: async ({ commit }, payload) => {
    const userUpdatesDB = new UserUpdatesDB(payload.update.user.id)
    if (payload.liked) {
      payload.update.likedBy = arrayUnion(payload.likedBy)
    } else {
      payload.update.likedBy = arrayRemove(payload.likedBy)
    }
    // console.log(JSON.stringify(payload.update))
    return userUpdatesDB.update(payload.update)
  },

  // OLD__fetchUserTeamEventsAction: async ({ commit }, payload) => {
  //   // console.log(JSON.stringify(payload))
  //   const { teams } = payload
  //   const { userId } = payload

  //   const allTeamEvents = []
  //   const limit = 3

  //   let end = null
  //   if (payload.endDate) {
  //     end = payload.endDate
  //   } else {
  //     const endDays = payload.endDays || 30
  //     end = new Date()
  //     end.setDate(end.getDate() + endDays)
  //   }

  //   let start = new Date()
  //   start = new Date(start.getFullYear(), start.getMonth(), start.getDate() - 1, 0, 0, 0)

  //   const constraints = []
  //   constraints.push(['endDateTime', '>=', start])
  //   constraints.push(['endDateTime', '<=', end])
  //   const orderBys = []
  //   orderBys.push(['endDateTime', 'asc'])

  //   const teamEventsDb = new TeamEventsDB()

  //   let personWithEvents = {}
  //   personWithEvents.events = {}

  //   teams.forEach((person) => {
  //     personWithEvents = person
  //     teamEventsDb
  //       .readAllWithOrderByLimit(constraints, orderBys, limit)
  //       .then((response) => {
  //         console.log(JSON.stringify(response))
  //         allTeamEvents.push(response)
  //       })
  //       .catch((err) => {
  //         throw err
  //       })
  //   })
  //   console.log(JSON.stringify(allTeamEvents))
  //   return allTeamEvents
  // },
}
