const teamEventStatusConstants = {
  SCHEDULED: 1,
  CANCELLED: 2,
  UPDATED: 3,
  ARCHIVED: 4,
  SUCCESS: 5,
  FAILURE: 6,
  UNKNOWN: null,
}

const teamEventStatus = [
  { id: teamEventStatusConstants.SCHEDULED, name: 'Scheduled' },
  { id: teamEventStatusConstants.CANCELLED, name: 'Cancelled' },
  { id: teamEventStatusConstants.UPDATED, name: 'Updated' },
  { id: teamEventStatusConstants.ARCHIVED, name: 'Archived' },
  { id: teamEventStatusConstants.SUCCESS, name: 'Successful' },
  { id: teamEventStatusConstants.FAILURE, name: 'Not Successful' },
  { id: teamEventStatusConstants.UNKNOWN, name: 'Unknown' },
]

export { teamEventStatusConstants, teamEventStatus }
