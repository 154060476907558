/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */

import isEmpty from 'lodash/isEmpty'
import UserRedirectsDB from '@/firebase/redirects/user-redirects-db'

export default {
  fetchRedirectsByUserId: async ({ commit }, payload) => {
    const userRedirectsDB = new UserRedirectsDB(payload.userId)
    return userRedirectsDB.readAll(payload.constraints)
  },

  fetchRedirectById: async ({ commit }, payload) => {
    const userRedirectsDB = new UserRedirectsDB(payload.userId)
    return userRedirectsDB.read(payload.redirectId)
  },

  addUserRedirect: async ({ commit }, payload) => {
    const userRedirectsDB = new UserRedirectsDB(payload.userId)
    return userRedirectsDB.create(payload.redirectRecord)
  },

  updateUserRedirect: async ({ commit }, payload) => {
    const userRedirectsDB = new UserRedirectsDB(payload.userId)
    return userRedirectsDB.update(payload.redirectRecord)
  },

  deleteUserRedirect: async ({ commit }, payload) => {
    const userRedirectsDB = new UserRedirectsDB(payload.userId)
    return userRedirectsDB.delete(payload.redirectId)
  },
}
