<template>
  <section class="send-message">
    <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="false">
      <b-icon
        pack="fas"
        icon="spinner"
        size="is-large"
        custom-class="fa-pulse"
        type="is-primary"
      >
      </b-icon
    ></b-loading>
    <template v-if="showError">
      <ViewError
        :title="errorTitle"
        :description="errorDescription"
        :show-footer="false"
      ></ViewError>
    </template>
    <Synform
      v-else
      :title="`Send Message to ${messageTo}`"
      :operation="sendMessage"
      button-text="Send Message"
      :modal-form="true"
    >
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="name"
        rules="required:true|min:2"
      >
        <b-field
          :label="showLabelsFromCode('profile/personalInfo/fullname')"
          class="required"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            ref="name"
            v-model.trim="form.name"
            name="name"
            placeholder="Your full name"
            :disabled="disableField"
          />
        </b-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="email"
        rules="required|min:8|max:40|email"
      >
        <b-field
          :label="`Business ${showLabelsFromCode('form/generic/email')}`"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="
            errors[0]
              ? errors
              : 'Business email only, personal email are prohibited'
          "
          class="required"
        >
          <b-input
            ref="email"
            v-model.trim="form.email"
            type="email"
            name="email"
            placeholder="Valid business email address is required"
          >
          </b-input>
        </b-field>
      </ValidationProvider>
      <b-field
        label="Phone"
        :message="showLabelsFromCode('generic/numbers-dashes')"
        expanded
      >
        <b-input
          v-model.trim="form.phone"
          name="phone"
          :validation-message="
            showLabelsFromCode('form/generic/phoneValidationMessage')
          "
          pattern="^\d+(?:-\d+)*$"
          @keydown.native.space.prevent
        />
      </b-field>
      <ValidationProvider
        v-slot="{ errors }"
        name="message"
        rules="required|min:5|max:100"
      >
        <b-field
          label="Message"
          class="required"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            v-model="form.message"
            placeholder="Add your message"
            name="message"
            type="textarea"
            rows="3"
            minlength="5"
            maxlength="100"
            required
          >
          </b-input>
        </b-field>
      </ValidationProvider>
      <b-message v-if="formErrorMessage" type="is-danger" class="mt-3">
        {{ formErrorMessage }}
      </b-message>
    </Synform>
  </section>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { Buffer } from 'buffer'
import { mapState, mapGetters, mapActions } from 'vuex'
import { ValidationProvider } from 'vee-validate'

import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import { functions } from '@/firebase/initFirebase'
import Synform from '@/components/SynForm'
import ViewError from '@/views/errors/ViewError'
import { handleErrorMessage, handleSuccessMessage } from '@/misc/user-messages'
import { showUserMessageFromCode, showLabelsFromCode } from '@/misc/helpers'

// @ts-ignore
window.Buffer = Buffer
// eslint-disable-next-line import/no-extraneous-dependencies
const emailInspector = require('email-inspector')

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
}

export default {
  name: 'SendMessage',
  components: { Synform, ValidationProvider, ViewError },
  props: {
    messageTo: {
      type: String,
      default: '',
    },
    // pageName: {
    //   type: String,
    //   default: '',
    // },
  },
  data() {
    return {
      showError: false,
      disableField: false,
      errorTitle: '',
      errorDescription: '',
      formErrorMessage: '',
      isLoading: true,
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters('user', ['isUserLoggedIn']),
    ...mapState('user', ['user']),
    ...mapState('profileUser', ['profileUser']),
    ...mapState('app', ['appTitle']),
  },
  created() {
    if (!this.user && !this.isUserLoggedIn) {
      this.calculateIfAllowedToSend()
    } else {
      this.form.name = this.user.displayName
      this.form.email = this.user.email
      this.disableField = true
    }
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapActions('appconfig', ['fetchUserAppConfigByUserId']),
    showUserMessageFromCode,
    showLabelsFromCode,
    async calculateIfAllowedToSend() {
      this.isLoading = true
      await this.fetchUserAppConfigByUserId({
        userId: this.profileUser.id,
        documentId: 'sendamessage',
      })
        .then((response) => {
          if (response) {
            const currentTimeStamp = Date.now()
            const diffMinutes =
              (currentTimeStamp - response.lastMessageTimeStamp) / (1000 * 60)

            if (diffMinutes < 15) {
              this.showError = true
              this.errorTitle = 'Please wait to send message'
              this.errorDescription = `To prevent spam, message sending is restricted by time. A user can only receive one message every 15 minutes. Currently, the threshold has not been met, so please wait for ~'${Math.round(
                15 - Number(diffMinutes),
              )} minutes' before sending the message.`
            }
          }
        })
        .catch((error) => {
          console.log(error)
          handleErrorMessage("Error in fetching user's scheduler defaults")
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async sendMessage() {
      if (!emailInspector.validate(this.form.email)) {
        this.formErrorMessage =
          'Sorry, personal email addresses are prohibited.'
      } else {
        this.isLoading = true
        const sendMessageCallable = httpsCallable(functions, 'sendAMessage')
        await sendMessageCallable({
          profileUserId: this.profileUser.id,
          userId: this.user?.id || null,
          userDisplayName: this.user?.displayName || null,
          profileUserDisplayName: this.profileUser?.displayName,
          profileUserEmail: this.profileUser?.email,
          form: this.form,
        })
          .then(async (result) => {
            if (result) {
              if (this.user && this.isUserLoggedIn) {
                const notitificationRecord = {
                  title: `💬 Message send from profile page`,
                  body: `From your profile page ${this.form.name} has send you the following message '${this.form.message}'. Send via email as well.`,
                }
                const sendNotificationFunction = httpsCallable(
                  functions,
                  'sendNotification',
                )
                try {
                  await sendNotificationFunction({
                    uid: this.profileUser.id,
                    notitificationRecord,
                  })
                } catch (error) {
                  console.log(error)
                }
              }
              handleSuccessMessage('Message send successfully')
              this.close()
            } else {
              handleErrorMessage('Error in sending message, please try again!')
            }
          })
          .catch((error) => {
            handleErrorMessage('Error in sending message, please try again!')
            console.log(JSON.stringify(error))
            const { code } = error
            const { message } = error
            const { details } = error
            console.log(code)
            console.log(JSON.stringify(message))
            console.log(JSON.stringify(details))
            this.formErrorMessage = message
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
