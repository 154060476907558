import state from './comments.state'
import mutations from './comments.mutations'
import actions from './comments.actions'
import getters from './comments.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
