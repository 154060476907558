<!--  -->
<template>
  <section class="view-leisures">
    <skeleton v-if="isLoading"></skeleton>
    <siyn-collapse
      v-if="!isLoading && leisures && leisures.length > 0"
      :title="panelTitle"
      emoji="🎴"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <template #default>
        <skeleton v-if="isLoading || !leisures"></skeleton>
        <div v-if="!isLoading && leisures && leisures.length > 0">
          <div class="columns is-gapless is-multiline">
            <div
              v-if="books && books.length > 0"
              :class="[
                'column',
                isPaidUser || isEnterpriseUser ? 'is-6' : 'is-12',
              ]"
            >
              <ViewBooks :books="books"></ViewBooks>
            </div>
            <div v-if="podcasts && podcasts.length > 0" class="column is-6">
              <ViewPodcasts :podcasts="podcasts"></ViewPodcasts>
            </div>
            <div v-if="shows && shows.length > 0" class="column is-6">
              <ViewShows :shows="shows"></ViewShows>
            </div>
            <div v-if="teams && teams.length > 0" class="column is-6">
              <ViewSportsTeams :teams="teams"></ViewSportsTeams>
            </div>
            <div v-if="likes && likes.length > 0" class="column is-12">
              <ViewLikes :likes="likes"></ViewLikes>
            </div>
          </div>
        </div>

        <div v-if="leisures && leisures.length === 0">
          <p class="pl-3 pb-2">None</p>
        </div>
      </template>
    </siyn-collapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
import { mapState, mapActions, mapMutations } from 'vuex'
import { viewProfileMixin } from '@/mixins/profile/index'
import { trackExceptionEvent } from '@/misc/analytics'
import ViewBooks from './partials/ViewBooks'
import ViewPodcasts from './partials/ViewPodcasts'
import ViewShows from './partials/ViewShows'
import ViewSportsTeams from './partials/ViewSportsTeams'
import ViewLikes from './partials/ViewLikes'

export default {
  name: 'ViewLeisures',
  components: {
    ViewBooks,
    ViewPodcasts,
    ViewShows,
    ViewSportsTeams,
    ViewLikes,
  },
  mixins: [viewProfileMixin],
  data() {
    return {
      panelTitle: this.showLabelsFromCode('profile/leisures/panelTitle'),
      panelname: 'leisures_view',
      isLoading: true,
      books: [],
      shows: [],
      podcasts: [],
      teams: [],
      likes: [],
      showBaseLink: process.env.VUE_APP_TMDB_SHOWS_BASEURL,
      amazonTagKey: process.env.VUE_APP_AMAZON_TAGKEY,
      firebaseStorageBaseURL: process.env.VUE_APP_FIRESTORE_BASEURL,
    }
  },
  computed: {
    ...mapState('leisures', ['leisures']),
  },
  watch: {
    mergedPanelsConfig: {
      handler(mergedPanelsConfig) {
        if (!this.isEmpty(mergedPanelsConfig) && this.profileUser) {
          if (
            this.getAuthUserId === this.$route.params.uid ||
            this.getAuthUserCompanyId === this.profileUser.companyId
          ) {
            this.fetchLeisuresContent()
          } else if (
            mergedPanelsConfig.leisures ===
            this.visibilityConstants.VISIBLE_TO_ALL
          ) {
            this.fetchLeisuresContent()
          } else if (
            mergedPanelsConfig.leisures ===
              this.visibilityConstants.VISIBLE_TO_MY_NW &&
            this.checkIfYouAreInMyNetwork({
              userId: this.profileUser.id,
              network: this.network,
            })
          ) {
            this.fetchLeisuresContent()
          }
        }
      },
    },
    deep: true,
    immediate: true,
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('leisures', ['setLeisures']),
    ...mapActions('leisures', ['fetchLeisuresByUserId']),
    fetchLeisuresContent() {
      const constraints = []
      constraints.push(['completed', '==', false])
      this.fetchLeisuresByUserId({
        userId: this.$route.params.uid,
        constraints,
      })
        .then((leisuresResponse) => {
          if (leisuresResponse) {
            this.setLeisures(leisuresResponse)
            this.populateEachLeisure(leisuresResponse)
          }
        })
        .catch((err) => {
          if (err !== 'permission-denied') {
            console.log(err)
            this.handleErrorMessage(
              `${this.showUserMessageFromCode(
                'generic/error-in-fetch',
              )} leisures.`,
            )
          }

          this.setLeisures(null)
          trackExceptionEvent(
            'leisures_fetch_failed',
            `${
              this.user ? this.user.id : 'visitor'
            } tried to fetch leisures for ${this.$route.params.uid}. ${err}`,
            false,
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    populateEachLeisure(leisuresResponse) {
      this.books = leisuresResponse.filter(
        (leisures) => leisures.type === 'book',
      )
      this.shows = leisuresResponse.filter(
        (leisures) => leisures.type === 'show',
      )
      this.podcasts = leisuresResponse.filter(
        (leisures) => leisures.type === 'podcast',
      )
      this.teams = leisuresResponse.filter(
        (leisures) => leisures.type === 'team',
      )
      this.likes = leisuresResponse.filter(
        (leisures) => leisures.type === 'likes',
      )
    },
  },
}
</script>
