import GenericDB from '@/firebase/generic-db'

export default class UsersDB extends GenericDB {
  constructor() {
    super('users')
  }

  /*   // Here you can extend UserDB with custom methods
  async fetchUserEmailByuserId(userId) {
    // console.log(userId)
    const usersDb = this
    return usersDb.read(userId)
  } */
}
