export default {
  company: undefined,
  companyLocations: [],
  companyNetwork: undefined,
  companyAdmins: undefined,
  companyToView: undefined,
  companyToEdit: undefined,
  isUserCompanyAdmin: false,
  isUserInSameCompany: false,
  companyPanelsConfig: {},
  companyPanelsConfigToEdit: {},
  companyEvents: [],
  companyEventToEdit: undefined,
  companyEventToView: undefined,
  companyNewsletters: [],
  companyNewsletterToEdit: undefined,
  companyNewsletterToView: undefined,
  companyBroadcasts: [],
  companyBroadcastToView: undefined,
  companyBroadcastToEdit: undefined,
  companyTokens: [],
  companyTokenToView: undefined,
  companyUpdates: undefined,
  unpublishedEvents: [],
  companyErrors: {
    companyNotFound: false,
    companyEventNotFound: false,
    noCompanyAdmins: false,
    unknown: false,
  },
}
