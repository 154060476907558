/* eslint-disable global-require */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable func-names */
/* eslint-disable import/extensions */
/* eslint-disable import/no-dynamic-require */
import moment from 'moment-timezone'
// import UsersDB from '@/firebase/users/users-db'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { FirebaseErrors } from './FirebaseErrors' // TODO - Remove this
// import firebaseErrors from '@/firebase/errors'
import { networkStatusConstants } from '@/data/networkStatus'

const { language } = navigator || 'en-US'
// const language = 'en-US'
let userMessages
let labels = null
let tooltips = null

try {
  userMessages = require(`@/misc/usermessages/${language}.js`)
} catch (error) {
  userMessages = require('@/misc/usermessages/en-US.js')
}
try {
  labels = require(`@/misc/labels/${language}.js`)
} catch (error) {
  labels = require('@/misc/labels/en-US.js')
}
try {
  tooltips = require(`@/misc/tooltips/${language}.js`)
} catch (error) {
  tooltips = require('@/misc/tooltips/en-US.js')
}
/**
 * Create new user from firebase auth user infos
 */
// eslint-disable-next-line
/* export const createNewUserFromFirebaseAuthUser = async (firebaseAuthUser) => {
  // console.log('I am in createNewUserFromFirebaseAuthUser BEGIN')
  const providerData = firebaseAuthUser.providerData[0]
  if (providerData) {
    const { displayName, photoURL, email, providerId } = providerData
    const loginCount = 0
    const userDb = new UsersDB()
    const user = {
      displayName,
      photoURL,
      email,
      providerId,
      loginCount,
    }
    return userDb.create(user, firebaseAuthuser.id)
  }
}
 */
export function getOS() {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i
  const windowsPlatforms = /(win32|win64|windows|wince)/i
  const iosPlatforms = /(iphone|ipad|ipod)/i
  let os = null

  if (macosPlatforms.test(userAgent)) {
    os = 'macos'
  } else if (iosPlatforms.test(userAgent)) {
    os = 'ios'
  } else if (windowsPlatforms.test(userAgent)) {
    os = 'windows'
  } else if (/android/.test(userAgent)) {
    os = 'android'
  } else if (!os && /linux/.test(userAgent)) {
    os = 'linux'
  }
  return os
}

export function isMobile() {
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  if (isMobileDevice) {
    return true
  }
  return false
}

export function decodeValue(val) {
  // Try to parse as json

  if (typeof val === 'string') {
    return val
  }

  // Return as is
  return val
}

export function encodeValue(val) {
  if (typeof val === 'string') {
    return val
  }
  return JSON.stringify(val)
}
export const setLocalStorage = (key, value) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, encodeValue(value))
  }
}

export const removeLocaleStorage = (key) => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key)
  }
}

export const getLocaleStorage = (key) => {
  if (typeof window !== 'undefined') {
    const value = window.localStorage.getItem(key)
    return decodeValue(value)
  }
  return null
}

export const getJwt = () => {
  return getLocaleStorage('idToken')
}

export function collapseHelper(panelname) {
  if (!panelname) {
    return true
  }
  return getLocaleStorage(panelname) !== 'close'
}
export function showHideHelper(visibilityAttribute) {
  if (!visibilityAttribute) {
    return true
  }
  return getLocaleStorage(visibilityAttribute)
}

export function addhttp(url) {
  return url.indexOf('://') === -1 ? `https://${url}` : url
}

export function checkIfYouAreInMyNetwork(payload) {
  // console.log(JSON.stringify(payload))
  if (isEmpty(payload.network)) {
    return false
  }
  for (let i = 0; i < payload.network.length; i += 1) {
    if (
      payload.network[i].id === payload.userId &&
      payload.network[i].status === networkStatusConstants.REQUEST_APPROVED
    ) {
      return true
    }
  }
  return false
}
export const parseFirebaseError = (error) => {
  return FirebaseErrors.Parse(error.code)
}

export function encodeUsingRegex(value) {
  const regex = /[\u00A0-\u9999<>]/g
  return value.replace(regex, ['&#', '$&'.charCodeAt(0), ';'].join(''))
}

export function showUserMessageFromCode(successCode) {
  let message = 'Success!'
  if (successCode) {
    message = get(userMessages.default, successCode)
  }
  return message
}
export function showLabelsFromCode(labelCode) {
  let labelText = 'A Field'
  if (labelCode) {
    labelText = get(labels.default, labelCode)
  }
  return labelText
}
export function showTooltipsFromCode(tooltipCode) {
  let tooltipText = 'Sorry! Tooltip not found'
  if (tooltipCode) {
    tooltipText = get(tooltips.default, tooltipCode)
  }
  return tooltipText
}

// export async function handleFirebaseErrors(error) {
//   console.log(error)
//   const { code } = error
//   let message = 'Sorry, something went wrong. Please try again'
//   if (code) {
//     message = get(firebaseErrors, code)
//   }
//   return message
// }

export default function isPWA() {
  return window && window.matchMedia('(display-mode: standalone)').matches
}

export async function getUserIPAddress() {
  try {
    const res = await fetch('https://api.ipify.org/?format=json')
    const d = await res.json()
    // console.log(`ip address is ${d.ip}`)
    return String(d.ip)
  } catch (error) {
    return 'API_BLOCKED'
  }
}

// export async function fetchUserAvatarFullPath(avataruid) {
//   // TODO fix this URL thing.
//   const defaultURL =
//     'https://firebasestorage.googleapis.com/v0/b/synemail.appspot.com/o/assets%2Fmissing-profile-image.png?alt=media'
//   return firebase
//     .storage()
//     .ref(`avatars/${avataruid}/profile.png`)
//     .getDownloadURL()
//     .then((url) => {
//       if (url) {
//         return url
//       }
//       const customError = new Error('Image not found')
//       customError.code = 'NO_IMAGE'
//       return new Error(customError)
//     })
//     .catch(() => {
//       console.log(`error in fetching profile image for ${avataruid}`)
//       return defaultURL
//     })
// }
function convertFromSecs(dts) {
  const utcSeconds = dts.seconds
  const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
  return moment.utc(d.setUTCSeconds(utcSeconds))
}

export function formatDateTime(value) {
  if (!value) {
    return null
  }
  let formattedDateTime = ''
  if (value.seconds) {
    formattedDateTime = moment(String(convertFromSecs(value))).local()
  } else {
    formattedDateTime = moment(String(value)).local()
  }

  if (
    formattedDateTime.hours() === 0 &&
    formattedDateTime.minutes() === 0 &&
    formattedDateTime.seconds() === 0
  ) {
    return formattedDateTime.format('MMM D')
  }
  if (formattedDateTime.minutes() === 0 && formattedDateTime.seconds() === 0) {
    return formattedDateTime.format('MMM D - h a').toLocaleString()
  }

  return formattedDateTime.format('MMM D - h:mm a').toLocaleString()
}
export function formatDateTimeWithYear(value) {
  return moment(String(value))
    .local()
    .format('MMM D, YY - h a')
    .toLocaleString()
}
export function formatDateTimeFromSeconds(dts) {
  return formatDateTime(convertFromSecs(dts))
}

export function formatDate(value) {
  if (value?.seconds) {
    return moment(convertFromSecs(value)).local().format('MMM D YYYY')
  }
  const formattedDate = moment(String(value)).local()
  return formattedDate.format('MMM D')
}
export function formatDateWithYear(value) {
  if (value?.seconds) {
    return moment(convertFromSecs(value)).local().format('MMM DD YYYY')
  }
  const formattedDate = moment(String(value)).local()
  return formattedDate.format('MMM DD YYYY')
}
export function formatTime(value) {
  let formattedTime = ''
  if (value.seconds) {
    formattedTime = convertFromSecs(value).local()
    // console.log(formattedTime)
  } else {
    formattedTime = moment(String(value)).local()
  }

  if (formattedTime.minutes() === 0 && formattedTime.seconds() === 0) {
    // console.log(formattedTime.format('h a'))
    return formattedTime.format('h a')
  }
  // console.log(formattedTime.format('h:mm a'))
  return formattedTime.format('h:mm a')
}

export function momentAgo(datetimestamp) {
  if (datetimestamp?.seconds) {
    return convertFromSecs(datetimestamp).local().fromNow()
  }

  return moment(datetimestamp).local().fromNow()
}

export const momentAgoFromUTC = (ts) => moment.utc(ts * 1000).fromNow()

export const convertUTCToLocal = (ts) =>
  moment
    .utc(ts * 1000)
    .local()
    .format()

export const humanReadableDateTime = (ts) => moment(ts).fromNow()

export function stripNonDigit(myString) {
  return myString ? myString.replace(/\D/g, '') : ''
}
// eslint-disable-next-line no-unused-vars
function getDSTBias(tz) {
  console.log(tz)
  const janOffset = moment.tz({ month: 0, day: 1 }, tz).utcOffset()
  const junOffset = moment.tz({ month: 5, day: 1 }, tz).utcOffset()
  console.log(junOffset)
  console.log(janOffset)
  console.log(Math.abs(junOffset - janOffset))
  return Math.abs(junOffset - janOffset)
}
export function handleTimeZones(datetimeinUTC, timeZoneToConvertTo) {
  if (!datetimeinUTC) {
    return null
  }

  const timeZone = timeZoneToConvertTo || moment.tz.guess(true)
  // console.log(timeZone)
  const datetimeinUTCWithOffSet = datetimeinUTC

  /*  
    #####################################################################
    TODO :- Below code was commented because for some reason it was adding 1 hour
    There is probably something wrong in computing the DST, need to google more. 
    #####################################################################
    const d = convertFromSecs(datetimeinUTCWithOffSet).add(
    getDSTBias(timeZone),
    'minutes'
  ) */
  const d = convertFromSecs(datetimeinUTCWithOffSet)

  if (d.minutes() === 0 && d.seconds() === 0) {
    return d.tz(timeZone).format('h a')
  }
  return d.tz(timeZone).format('h:mm a')
}

// eslint-disable-next-line no-unused-vars
export function getCurrentDayOfWeek(timeZoneToConvertTo) {
  const timeZone = timeZoneToConvertTo || moment.tz.guess(true)
  return moment().tz(timeZone).day()
}

export function getOnlineStatus(officeHours) {
  // console.log(JSON.stringify(officeHours))
  /**
   *  Get current day of week and current time in profile timezone
   *  Get start and end time for current day of week
   *  Check if current time is between start & end time of the current day
   */

  const n = getCurrentDayOfWeek(officeHours.timeZone)
  let startTime = null
  let endTime = null
  // console.log(n)
  switch (n) {
    case 0:
      startTime = officeHours.sun.startTime || null
      endTime = officeHours.sun.endTime || null
      break
    case 1:
      startTime = officeHours.mon.startTime || null
      endTime = officeHours.mon.endTime || null
      break
    case 2:
      startTime = officeHours.tue.startTime || null
      endTime = officeHours.tue.endTime || null
      break
    case 3:
      startTime = officeHours.wed.startTime || null
      endTime = officeHours.wed.endTime || null
      break
    case 4:
      startTime = officeHours.thu.startTime || null
      endTime = officeHours.thu.endTime || null

      break
    case 5:
      startTime = officeHours.fri.startTime || null
      endTime = officeHours.fri.endTime || null
      break
    case 6:
      startTime = officeHours.sat.startTime || null
      endTime = officeHours.sat.endTime || null
      break
    default:
      break
  }

  if (startTime && endTime) {
    const startTimehhmm = moment.unix(startTime.seconds)
    const endTimehhmm = moment.unix(endTime.seconds)

    const sh = startTimehhmm.get('hour')
    const eh = endTimehhmm.get('hour')

    const sm = startTimehhmm.get('minute')
    const em = endTimehhmm.get('minute')

    // console.log(n)
    // console.log(startTimehhmm.format('hh:mm:ss'))
    // console.log(endTimehhmm.format('hh:mm:ss'))

    // console.log(sh)
    // console.log(eh)
    // console.log(sm)
    // console.log(em)

    const sminutes = sh * 60 + sm
    const eminutes = eh * 60 + em

    const mmt = moment().tz(officeHours.timeZone)
    // Your moment at midnight
    const mmtMidnight = mmt.clone().startOf('day')
    // Difference in minutes
    const minsSinceMidnight = mmt.diff(mmtMidnight, 'minutes')
    const afterhours = Number(officeHours.afterHours) || 0

    /*     console.log(`afterhours ${afterhours}`)
    console.log(`Start minutes ${sminutes}`)
    console.log(`End Minutes ${eminutes}`)
    console.log(`Mins since midnight ${minsSinceMidnight}`)
    console.log(`Emins+ afterhours ${eminutes + afterhours}`) */
    if (
      minsSinceMidnight > sminutes &&
      minsSinceMidnight < eminutes + afterhours
    ) {
      return 1 // available
    }
    if (
      minsSinceMidnight >= eminutes &&
      minsSinceMidnight <= eminutes + afterhours
    ) {
      return 2 // may be available
    }
    if (minsSinceMidnight > eminutes + afterhours) {
      return 0 // not available
    }
  }
  return null
}

export function renderFromToDates(startDate, endDate) {
  // console.log(JSON.stringify(startDate))
  const tempStartDate = new Date(startDate)
  const tempEndDate = new Date(endDate)

  // if (startDate.seconds) {
  //   tempStartDate = convertFromSecs(startDate)
  //   tempEndDate = convertFromSecs(endDate)
  // }

  try {
    if (new Date(tempStartDate).getDate() === new Date(tempEndDate).getDate()) {
      // console.log(JSON.stringify('dates are same'))
      return `${formatDate(tempStartDate)} - ${formatTime(
        tempStartDate,
      )} to ${formatTime(tempEndDate)}`
    }
  } catch (error) {
    console.log(JSON.stringify('dates have error'))
    // return `${moment.unix(startDate.seconds).format('MMM D YYYY')} to ${moment
    //   .unix(endDate.seconds)
    //   .format('MMM D YYYY')}`
  }

  return `${formatDateTime(tempStartDate)} to ${formatDateTime(tempEndDate)}`
}

export function getNameForId(id, data) {
  if (!id || !data) {
    return null
  }
  try {
    const response = data.find((type) => type.id === id)
    return response.name
  } catch (error) {
    // console.log(error)
    return null
  }
}

export function clickToMaps(address) {
  window.open(`https://maps.google.com/?q=${address}`, '_blank').focus()
}
export function clickToCall(number) {
  window.open(`tel:${stripNonDigit(number)}`, '_self')
}
export function clickToEmail(email) {
  window.open(`mailto:${email}`)
}
export function clickToChat() {
  console.log('chat with me')
}
export function closeDialog() {
  document.body.click()
}

export function errorMessageStringTweak(str) {
  str = str.replace(/-/g, ' ')
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : null
}

export function companyColorPersonalization(bannerColor) {
  document.body.style.backgroundColor = bannerColor
  const iconColorOverride = document.getElementsByClassName(
    'icon-color-override',
  )
  for (let i = 0; i < iconColorOverride.length; i += 1) {
    iconColorOverride[i].style.color = bannerColor
  }

  const bgOverride = document.getElementsByClassName(
    'background-color-override',
  )
  for (let i = 0; i < bgOverride.length; i += 1) {
    bgOverride[i].style.backgroundColor = bannerColor
  }

  const borderColorOverride = document.getElementsByClassName(
    'border-color-override',
  )
  for (let i = 0; i < borderColorOverride.length; i += 1) {
    borderColorOverride[i].style.borderColor = bannerColor
  }
}

export function destroyColorPersonalization(
  defaultBGColor = '#f2f6fa',
  defaultIconColor = '#48beb4',
  defaultPrimaryColor = '#7a7a7a',
) {
  document.body.style.backgroundColor = defaultBGColor
  const iconColorOverride = document.getElementsByClassName(
    'icon-color-override',
  )
  if (iconColorOverride.length > 0) {
    for (let i = 0; i < iconColorOverride.length; i += 1) {
      iconColorOverride[i].style.color = defaultIconColor
    }
  }
  const bgOverride = document.getElementsByClassName(
    'background-color-override',
  )
  if (bgOverride.length > 0) {
    for (let i = 0; i < bgOverride.length; i += 1) {
      bgOverride[i].style.backgroundColor = defaultPrimaryColor
    }
  }
}

export function buildGoogleMapsURL(address) {
  if (!isEmpty(address)) {
    this.panelTitle = 'Meeting Location'
    return `http://maps.google.com/maps?q=${encodeURIComponent(address)}`
  }
  return address
}

export function truncateText(text, maxChars = 200) {
  const textLength = text.length
  if (textLength <= maxChars) {
    return text
  }
  if (textLength > maxChars) return `${text.substring(0, maxChars)}...`
  return text
}

export function removeEmptyPTags(text) {
  if (text) {
    return text.split('<p></p>').join('').split('<p><br></p>').join('')
  }
  return ''
}
export function removeHTML(html) {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText
}

export function replaceBreakWithULTags(text) {
  if (text) {
    return text.replace(/\n/g, '<li/>')
  }
  return ''
}

export function renderConferenceProvider(providerId) {
  let response = ''
  switch (providerId) {
    case 'microsoftteams':
      response = 'Microsoft Teams'
      break
    case 'googlemeet':
      response = 'Google Meet'
      break
    case 'zoom':
      response = 'Zoom Meeting'
      break
    default:
      break
  }
  return response || 'Web Conference'
}

export function generateRandomString(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomString = ''
  for (let i = 0; i < length; i += 1) {
    const randomCharacter = characters.charAt(
      Math.floor(Math.random() * characters.length),
    )
    randomString += randomCharacter
  }
  return randomString
}
