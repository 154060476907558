import { find } from 'lodash'

export default {
  /**
   * Get  by id
   */
  getLinksById: (state) => (linksId) =>
    find(state.links, (links) => links.id === linksId),

  getCorporateLinksById: (state) => (linksId) =>
    find(state.links.corporate, (links) => links.id === linksId),
}
