<template>
  <div class="box my-4">
    <b-skeleton width="25%"></b-skeleton>
    <b-skeleton width="50%"></b-skeleton>
    <b-skeleton width="75%"></b-skeleton>
    <b-skeleton></b-skeleton>
  </div>
</template>

<script>
export default {
  name: 'ShowSkeleton',
}
</script>
