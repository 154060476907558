/* eslint-disable import/extensions */
import {
  // eslint-disable-next-line
  connectFirestoreEmulator,
  serverTimestamp as FirebaseServerTS,
  writeBatch,
  doc,
  arrayUnion,
  arrayRemove,
  collection,
  deleteField,
} from 'firebase/firestore'
import { db } from '@/firebase/initFirebase'

import { handleErrorMessage } from '@/misc/user-messages'

import { summarizeWithAI } from '@/misc/ai'
import { notificationActionTypesConstants } from '@/data/notificationActionTypes'

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}
const serverTimestamp = FirebaseServerTS()

export default function teameventsBatch() {
  return true
}

/** ********************  TEAM EVENTS *********************** * */

export const unJoinTeamEventBatch = async (payload) => {
  const { teamEvent } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const teamUpdates = {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  }
  const lastUpdatedBy = createdBy

  const batch = writeBatch(db)

  const teamEventRef = doc(db, 'teamevents', teamEvent.id)
  const teamUpdatesRef = doc(
    collection(db, 'teams', teamEvent.teamId, 'updates'),
  )

  batch.update(teamEventRef, {
    attendees: arrayRemove(teamEvent.removeObject),
    lastUpdatedBy,
    updateTimestamp: serverTimestamp,
  })
  batch.set(teamUpdatesRef, {
    ...teamUpdates,
  })

  try {
    await batch.commit()
    return { teamEvent, teamUpdates, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const subscribeToTeamEventBatch = async (payload) => {
  const { teamEvent } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy

  const batch = writeBatch(db)

  const teamEventRef = doc(db, 'teamevents', teamEvent.id)
  const teamUpdatesRef = doc(
    collection(db, 'teams', teamEvent.team.id, 'updates'),
  )

  batch.update(teamEventRef, {
    subscribers: arrayUnion(user.id),
    lastUpdatedBy,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  })

  try {
    await batch.commit()
    return { teamEvent, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const unSubscribeToTeamEventBatch = async (payload) => {
  const { teamEvent } = payload
  const { teamUpdate } = payload
  const { user } = payload

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy

  const teamUpdates = {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  }

  const batch = writeBatch(db)

  const teamEventRef = doc(db, 'teamevents', teamEvent.id)
  const teamUpdatesRef = doc(
    collection(db, 'teams', teamEvent.team.id, 'updates'),
  )

  batch.update(teamEventRef, {
    subscribers: arrayRemove(user.id),
    lastUpdatedBy,
    updateTimestamp: serverTimestamp,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdates,
  })
  try {
    await batch.commit()
    return { teamEvent, teamUpdates, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateTeamsEventBatch = async (payload) => {
  const teamEvent = payload.updatedTeamEventToEdit
  const { teamUpdate } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy

  const batch = writeBatch(db)

  const teamEventRef = doc(db, 'teamevents', teamEvent.id)
  const teamUpdatesRef = doc(
    collection(db, 'teams', teamEvent.team.id, 'updates'),
  )

  batch.update(teamEventRef, {
    ...teamEvent,
    updateTimestamp: serverTimestamp,
    lastUpdatedBy,
  })
  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  })

  try {
    await batch.commit()
    return { teamEvent, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

/** ********************  ONETIME MEETING NOTES *********************** * */

export const addOneTimeMeetingNotesBatch = async (payload) => {
  // console.log(JSON.stringify(payload))
  const { tempTeamEvent } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const notesCreatedBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const createdBy = notesCreatedBy
  const lastUpdatedBy = notesCreatedBy
  const summaryCreatedBy = {
    displayName: 'gpt-4.0',
  }

  const batch = writeBatch(db)

  if (tempTeamEvent.summarize) {
    const AIResponse = await summarizeWithAI({
      meetingNotes: tempTeamEvent.meetingNotes,
      promptId: tempTeamEvent.promptId,
    })

    switch (AIResponse.code) {
      case 'SUCCESS':
        tempTeamEvent.summarizedText = AIResponse.text
        tempTeamEvent.tokensUsed = AIResponse?.tokensUsed || 'UNKNOWN'
        tempTeamEvent.summaryDateTime = serverTimestamp
        tempTeamEvent.summaryCreatedBy = summaryCreatedBy
        break
      case 'TRUNCATED':
        throw new Error('TRUNCATED')
      case 'EMPTY':
        throw new Error('EMPTY')
      default:
        throw new Error('UNKNOWN')
    }
  }

  const teamEventRef = doc(db, 'teamevents', tempTeamEvent.id)

  const teamUpdatesRef = doc(
    collection(db, 'teams', tempTeamEvent.team.id, 'updates'),
  )

  batch.update(teamEventRef, {
    ...tempTeamEvent,
    notesCreateTimestamp: serverTimestamp,
    notesCreatedBy,
    updateTimestamp: serverTimestamp,
    lastUpdatedBy,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  })

  if (tempTeamEvent.subscribers && tempTeamEvent.subscribers.length > 0) {
    tempTeamEvent.subscribers.forEach((subscriberId) => {
      const subscriberRef = doc(db, 'users', subscriberId, 'notifications')

      const notificationRecord = {
        type: notificationActionTypesConstants.ADDED,
        createTimestamp: serverTimestamp,
        updateTimestamp: serverTimestamp,
        createdBy,
        message: `AI summarized meeting notes were added to the team event <a href="/view/team/${tempTeamEvent.team.id}/event/${tempTeamEvent.id}">${tempTeamEvent.eventName}</a>. Please see the team event page for more information `,
      }
      batch.set(subscriberRef, notificationRecord)
    })
  }

  try {
    await batch.commit()
    return { tempTeamEvent, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const udpateOneTimeMeetingNotesBatch = async (payload) => {
  let { tempTeamEvent } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy
  const summaryUpdatedBy = {
    displayName: 'gpt-4.0',
  }
  const notesLastUpdatedBy = lastUpdatedBy
  tempTeamEvent = {
    ...tempTeamEvent,
    updateTimestamp: serverTimestamp,
    notesUpdateTimestamp: serverTimestamp,
    notesLastUpdatedBy,
    lastUpdatedBy,
  }

  const batch = writeBatch(db)
  if (tempTeamEvent.summarize) {
    const AIResponse = await summarizeWithAI({
      meetingNotes: tempTeamEvent.meetingNotes,
      promptId: tempTeamEvent.promptId,
    })
    switch (AIResponse.code) {
      case 'SUCCESS':
        tempTeamEvent.summarizedText = AIResponse.text
        tempTeamEvent.summaryUpdateDateTime = serverTimestamp
        tempTeamEvent.summaryUpdatedBy = summaryUpdatedBy
        tempTeamEvent.tokensUsed = AIResponse?.tokensUsed || 'UNKNOWN'
        break
      case 'TRUNCATED':
        throw new Error('TRUNCATED')
      case 'EMPTY':
        throw new Error('EMPTY')
      default:
        throw new Error('UNKNOWN')
    }
  }

  const teamEventRef = doc(db, 'teamevents', tempTeamEvent.id)
  const teamUpdatesRef = doc(
    collection(db, 'teams', tempTeamEvent.team.id, 'updates'),
  )

  if (tempTeamEvent.subscribers && tempTeamEvent.subscribers.length > 0) {
    tempTeamEvent.subscribers.forEach((subscriberId) => {
      const subscriberRef = doc(db, 'users', subscriberId, 'notifications')

      const notificationRecord = {
        type: notificationActionTypesConstants.UPDATED,
        createTimestamp: serverTimestamp,
        updateTimestamp: serverTimestamp,
        createdBy,
        message: `Meeting notes were updated for the team event  <a href="/view/team/${tempTeamEvent.team.id}/event/${tempTeamEvent.id}">${tempTeamEvent.eventName}</a>. Please see the team event page for more information `,
      }

      batch.set(subscriberRef, notificationRecord)
    })
  }

  batch.update(teamEventRef, tempTeamEvent)
  batch.set(teamUpdatesRef, { tempTeamEvent })

  try {
    await batch.commit()
    return { tempTeamEvent, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const deleteOneTimeMeetingNotesBatch = async (payload) => {
  const { teamEventToView } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const lastUpdatedBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const createdBy = lastUpdatedBy

  const batch = writeBatch(db)

  const teamEventsRef = doc(db, 'teamevents', teamEventToView.id)
  const teamUpdatesRef = doc(
    collection(db, 'teams', teamEventToView.team.id, 'updates'),
  )

  batch.update(teamEventsRef, {
    meetingNotes: deleteField(),
    summarizedText: deleteField(),
    summaryDateTime: deleteField(),
    summaryCreatedBy: deleteField(),
    notesCreateTimestamp: deleteField(),
    notesCreatedBy: deleteField(),
    notesLastUpdatedBy: deleteField(),
    notesUpdateTimestamp: deleteField(),
    promptId: deleteField(),
    summarize: deleteField(),
    lastUpdatedBy,
    updateTimestamp: serverTimestamp,
  })
  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  })

  if (teamEventToView.subscribers && teamEventToView.subscribers.length > 0) {
    teamEventToView.subscribers.forEach((subscriberId) => {
      const subscriberRef = doc(
        collection(db, 'users', subscriberId, 'notifications'),
      )

      const notificationRecord = {
        type: notificationActionTypesConstants.DELETED,
        createTimestamp: serverTimestamp,
        updateTimestamp: serverTimestamp,
        createdBy,
        message: `Meeting notes were deleted for the team event  <a href="/view/team/${teamEventToView.team.id}/event/${teamEventToView.id}">${teamEventToView.eventName}</a>. Please see the team event page for more information `,
      }

      batch.set(subscriberRef, notificationRecord)
    })
  }

  try {
    await batch.commit()
    return { teamEventToView, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateOneTimeAISummaryBatch = async (payload) => {
  let { teamEvent } = payload
  const { summarizedText } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy
  const summaryUpdatedBy = {
    id: user.id,
    displayName: user.displayName,
  }
  teamEvent = {
    ...teamEvent,
    summarizedText,
    summaryUpdateDateTime: serverTimestamp,
    summaryUpdatedBy,
    lastUpdatedBy,
    updateTimestamp: serverTimestamp,
  }

  const batch = writeBatch(db)

  const teamEventsRef = doc(db, 'teamevents', teamEvent.id)
  const teamUpdatesRef = doc(
    collection(db, 'teams', teamEvent.team.id, 'updates'),
  )

  batch.update(teamEventsRef, teamEvent)
  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  })

  try {
    await batch.commit()
    return { teamEvent, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}
/** ********************  RECURRING MEETING NOTES *********************** * */

export const addMeetingNotesBatch = async (payload) => {
  const { meetingNotes } = payload
  const { teamEventToView } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const summaryCreatedBy = {
    displayName: 'gpt-4.0',
  }

  const batch = writeBatch(db)

  if (meetingNotes.summarize) {
    const AIResponse = await summarizeWithAI({
      meetingNotes: meetingNotes.notes,
      promptId: meetingNotes.promptId,
    })

    switch (AIResponse.code) {
      case 'SUCCESS':
        meetingNotes.summarizedText = AIResponse.text
        meetingNotes.summaryDateTime = serverTimestamp
        meetingNotes.summaryCreatedBy = summaryCreatedBy
        meetingNotes.tokensUsed = AIResponse?.tokensUsed || 'UNKNOWN'
        break
      case 'TRUNCATED':
        handleErrorMessage(
          'AI Response was truncated, please shorten the text.',
        )
        throw new Error('TRUNCATED')
      case 'EMPTY':
        handleErrorMessage('No Response from AI Engine')
        throw new Error('EMPTY')
      default:
        handleErrorMessage('Unknown error send from AI Engine')
        throw new Error('UNKNOWN')
    }
  }

  const teamMeetingNotesRef = doc(
    collection(db, 'teamevents', teamEventToView.id, 'meetingnotes'),
  )
  const teamUpdatesRef = doc(
    collection(db, 'teams', teamEventToView.team.id, 'updates'),
  )

  batch.set(teamMeetingNotesRef, {
    ...meetingNotes,
    createTimestamp: serverTimestamp,
    createdBy,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  })

  if (teamEventToView.subscribers && teamEventToView.subscribers.length > 0) {
    teamEventToView.subscribers.forEach((subscriberId) => {
      const subscriberRef = doc(db, 'users', subscriberId, 'notifications')

      const notificationRecord = {
        type: notificationActionTypesConstants.ADDED,
        createTimestamp: serverTimestamp,
        updateTimestamp: serverTimestamp,
        createdBy,
        message: `AI summarized meeting notes were added to the team event <a href="/view/team/${teamEventToView.team.id}/event/${teamEventToView.id}">${teamEventToView.eventName}</a>. Please see the team event page for more information `,
      }
      batch.set(subscriberRef, notificationRecord)
    })
  }

  try {
    await batch.commit()
    return { teamEventToView, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const udpateMeetingNotesBatch = async (payload) => {
  let { meetingNotes } = payload
  const { teamEventToView } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy
  const summaryUpdatedBy = {
    displayName: 'gpt-4.0',
  }
  meetingNotes = {
    ...meetingNotes,
    updateTimestamp: serverTimestamp,
    lastUpdatedBy,
  }

  const batch = writeBatch(db)
  if (meetingNotes.summarize) {
    const AIResponse = await summarizeWithAI({
      meetingNotes: meetingNotes.notes,
      promptId: meetingNotes.promptId,
    })
    switch (AIResponse.code) {
      case 'SUCCESS':
        meetingNotes.summarizedText = AIResponse.text
        meetingNotes.summaryUpdateDateTime = serverTimestamp
        meetingNotes.summaryUpdatedBy = summaryUpdatedBy
        meetingNotes.tokensUsed = AIResponse?.tokensUsed || 'UNKNOWN'
        break
      case 'TRUNCATED':
        throw new Error('TRUNCATED')
      case 'EMPTY':
        throw new Error('EMPTY')
      default:
        throw new Error('UNKNOWN')
    }
  }

  const teamMeetingNotesRef = doc(
    db,
    'teamevents',
    teamEventToView.id,
    'meetingnotes',
    meetingNotes.id,
  )
  const teamUpdatesRef = doc(
    collection(db, 'teams', teamEventToView.team.id, 'updates'),
  )

  if (teamEventToView.subscribers && teamEventToView.subscribers.length > 0) {
    teamEventToView.subscribers.forEach((subscriberId) => {
      const subscriberRef = doc(db, 'users', subscriberId, 'notifications')

      const notificationRecord = {
        type: notificationActionTypesConstants.UPDATED,
        createTimestamp: serverTimestamp,
        updateTimestamp: serverTimestamp,
        createdBy,
        message: `Meeting notes were updated for the team event  <a href="/view/team/${teamEventToView.team.id}/event/${teamEventToView.id}">${teamEventToView.eventName}</a>. Please see the team event page for more information `,
      }

      batch.set(subscriberRef, notificationRecord)
    })
  }

  batch.update(teamMeetingNotesRef, meetingNotes)
  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  })

  try {
    await batch.commit()
    return { meetingNotes, teamEventToView, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateAISummaryBatch = async (payload) => {
  let { meetingNotes } = payload
  const { teamEventToView } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy
  const summaryUpdatedBy = {
    id: user.id,
    displayName: user.displayName,
  }
  meetingNotes = {
    ...meetingNotes,
    summaryUpdateDateTime: serverTimestamp,
    summaryUpdatedBy,
  }

  const batch = writeBatch(db)

  const teamMeetingNotesRef = doc(
    db,
    'teamevents',
    teamEventToView.id,
    'meetingnotes',
    meetingNotes.id,
  )
  const teamUpdatesRef = doc(
    collection(db, 'teams', teamEventToView.team.id, 'updates'),
  )

  batch.update(teamMeetingNotesRef, {
    ...meetingNotes,
    updateTimestamp: serverTimestamp,
    lastUpdatedBy,
  })
  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  })

  try {
    await batch.commit()
    return { meetingNotes, teamEventToView, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const deleteMeetingNotesBatch = async (payload) => {
  const { meetingNotesId } = payload
  const { teamEventToView } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }

  const batch = writeBatch(db)

  const teamMeetingNotesRef = doc(
    db,
    'teamevents',
    teamEventToView.id,
    'meetingnotes',
    meetingNotesId,
  )
  const teamUpdatesRef = doc(
    collection(db, 'teams', teamEventToView.team.id, 'updates'),
  )

  batch.delete(teamMeetingNotesRef)
  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    createdBy,
  })

  if (teamEventToView.subscribers && teamEventToView.subscribers.length > 0) {
    teamEventToView.subscribers.forEach((subscriberId) => {
      const subscriberRef = doc(
        collection(db, 'users', subscriberId, 'notifications'),
      )

      const notificationRecord = {
        type: notificationActionTypesConstants.DELETED,
        createTimestamp: serverTimestamp,
        updateTimestamp: serverTimestamp,
        createdBy,
        message: `Meeting notes were deleted for the team event  <a href="/view/team/${teamEventToView.team.id}/event/${teamEventToView.id}">${teamEventToView.eventName}</a>. Please see the team event page for more information `,
      }

      batch.set(subscriberRef, notificationRecord)
    })
  }

  try {
    await batch.commit()
    return { meetingNotesId, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}
