var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-shows"},[_c('div',{staticClass:"view-shows__heading view-shows__heading--primary"},[_c('span',{staticClass:"view-shows__heading--emoji"},[_vm._v("📺")]),_c('span',{staticClass:"view-shows__heading--title"},[_vm._v("Currently watching")]),_c('b-button',{staticClass:"view-shows__heading--button",attrs:{"tag":"router-link","to":{
        name: 'listshows',
        params: { uid: _vm.profileUser.id, mode: 'view' },
        fromScreen: 'viewprofile',
        shows: _vm.shows,
      },"size":"is-small","icon-left":"star","pack":"fas","type":"is-ghost"}},[_vm._v(_vm._s(_vm.showLabelsFromCode('generic/ratings')))])],1),_c('div',{staticClass:"columns is-mobile is-multiline is-vcentered is-narrow is-gapless mt-2"},_vm._l((_vm.shows),function(tvshow,index){return _c('div',{key:tvshow.id,staticClass:"column is-3"},[_c('a',{staticClass:"view-shows__link",attrs:{"href":`${_vm.showBaseURL}${tvshow.TMDB_ID}`,"target":'_blank'},on:{"click":function($event){return _vm.trackConversionEvent(
            'tvshow_clicked',
            `${tvshow.title} by ${tvshow.author}`,
            tvshow.link,
            `User ${_vm.user.id} clicked tvshow ${index + 1} of ${_vm.shows.length} `,
          )}}},[_c('img',{staticClass:"view-shows__coverimage border-color--primary",attrs:{"src":tvshow.coverImagePath,"alt":`${tvshow.title} by ${tvshow.author}`,"title":`${tvshow.title}`},on:{"error":_vm.setAltImg}})])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }