/* See https://vuex.vuejs.org/ for more info */

import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import home from './home'
import tokens from './tokens'
import search from './search'

import notifications from './notifications-collection/notifications'

import company from './company-collection'

import teams from './teams-related/teams'
import teamsEvents from './teams-related/team-events'
import teamMembers from './teams-related/team-members'
import teamsUpdates from './teams-related/team-updates'
import teamLinks from './teams-related/team-links'
import teamBulletins from './teams-related/team-bulletins'
import teamBroadcasts from './teams-related/team-broadcasts'
import teamSummaries from './teams-related/team-summaries'
import teamBookings from './teams-related/team-bookings'

import user from './users-collection/user'
import userTeams from './users-collection/userTeams'
import appconfig from './users-collection/appconfig'
import profileUser from './users-collection/profileUser'
import mentoring from './users-collection/mentoring'
import redirects from './users-collection/redirects'
import userupdates from './users-collection/userupdates'
import comments from './users-collection/comments'
import links from './users-collection/links'
import events from './users-collection/events'
import networkStatus from './users-collection/networkStatus'
import emailpreferences from './users-collection/emailpreferences'
import privateinfo from './users-collection/privateinfo'
import officehours from './users-collection/officehours'
import leisures from './users-collection/leisures'
import scheduling from './users-collection/scheduling'
import sendamessage from './users-collection/sendamessage'
/* You also import vuex in your main.js */

Vue.use(Vuex)
Vue.config.productionTip = false
Vue.config.devtools = true
Vue.config.debug = true
Vue.config.silent = true
// This is the main entry point for the state, here you are telling Vuex that hey manage my state
export default new Vuex.Store({
  // strict: process.env.NODE_ENV !== 'production', TODO
  strict: false,
  // modules is where you are telling vuex where to get all states,mutators, actions, getters from
  modules: {
    user,
    app,
    appconfig,
    tokens,
    home,
    search,
    comments,
    links,
    events,
    emailpreferences,
    privateinfo,
    officehours,
    notifications,
    networkStatus,
    company,
    teams,
    teamsEvents,
    teamMembers,
    teamsUpdates,
    teamLinks,
    teamBulletins,
    teamBroadcasts,
    teamSummaries,
    teamBookings,
    userTeams,
    leisures,
    profileUser,
    mentoring,
    redirects,
    userupdates,
    scheduling,
    sendamessage,
  },
})
