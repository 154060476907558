<template>
  <div class="view-teams">
    <div class="view-teams__heading view-teams__heading--link">
      <span class="view-teams__heading--emoji">🏅</span
      ><span class="view-teams__heading--title">{{
        showLabelsFromCode('profile/leisures/followsteams')
      }}</span>
    </div>
    <div
      class="columns is-mobile is-multiline is-vcentered is-narrow is-gapless mt-2"
    >
      <div v-for="team in teams" :key="team.id" class="column is-2">
        <img
          v-if="team.siynemail_id"
          class="view-teams__coverimage"
          :src="buildSportsLogoImageURL(team.siynemail_id.toString())"
          :alt="`${team.team_displayName} from ${team.team_location}`"
          :title="`${team.team_displayName} from ${team.team_location}`"
          @error="setAltImg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { trackConversionEvent } from '@/misc/analytics'
import { viewProfileMixin } from '@/mixins/profile/index'

export default {
  name: 'ViewSportsteams',
  mixins: [viewProfileMixin],
  props: {
    teams: Object,
  },
  data() {
    return {
      teamLogoImageBaseURL: process.env.VUE_APP_BASEURL_TEAMLOGOS,
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },

  methods: {
    buildSportsLogoImageURL(idString) {
      if (typeof idString !== 'string') {
        return null
      }
      const teamSplit = idString.split('_')
      return `${this.teamLogoImageBaseURL}${teamSplit[1]}/500/${teamSplit[4]}.png`
    },
    setAltImg(e) {
      e.target.onerror = null
      e.target.src = this.noCoverImage
    },
    trackEvent: trackConversionEvent,
  },
}
</script>

<style lang="scss" scoped>
img.leisures-list__table--image {
  height: 40px !important;
  widows: auto !important;
}
</style>
