/* eslint-disable import/extensions */
import { find } from 'lodash'
// import store from '@/store'

export default {
  getTeamById: (state) => (teamId) => find(state.teams, (team) => team.id === teamId),

  getTeamMembers(state) {
    return state.teamMembers
  },
  /* 
  isUserTeamCreator(state, getters, rootState) {
    if (state.team.createdById === rootState.user.user.id) {
      return true
    }
    return false
  }, */

  // isUserTeamAdmin(state, getters, rootState) {
  //   if (state.teamToView && state.teamToView.teamAdmins) {
  //     return !!state.teamToView.teamAdmins.includes(rootState.user.user.id)
  //   }
  //   return false
  // },

  /*   isUserInSameCompanyAsTeam(state, getters, rootState) {
    if (
      state.team.companyId ===
        store.getters['authentication/getAuthUserCompanyId'] &&
      rootState.user.user.id === store.getters['authentication/getAuthUserId']
    ) {
      return true
    }
    return false
  }, */

  getTeamStatus(state) {
    if (state && state.teamToView) {
      return state.teamToView.teamStatus
    }
    return false
  },

  getUsersMemberStatusForTheTeam(state, getters, rootState) {
    if (state.teamMembers) {
      // console.log(state.teamMembers)
      const index = state.teamMembers.findIndex((item) => item.id === rootState.user.user.id)
      if (index !== -1) {
        return state.teamMembers[index].memberStatus
      }
    }
    return false
  },

  getTeamUpdates(state) {
    return state.teamUpdates
  },
}
