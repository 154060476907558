<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="company-event-addInfo">
    <div width-class="is-12" class="view-company-event-addInfo">
      <skeleton v-if="isLoading"></skeleton>

      <SynPanel v-if="companyEventToView && !isLoading" :title="panelTitle">
        <div class="view-company-event-addInfo__wrapper px-4 py-2">
          <template v-if="companyEventToView.additionalInfo">
            <span v-html="companyEventToView.additionalInfo"></span>
          </template>
          <template v-else>
            <p class="empty-content">None.</p>
          </template>
        </div>
      </SynPanel>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import SynPanel from '@/components/SynPanel.vue'
import { viewCompanyMixin } from '@/mixins/company/index'

export default {
  name: 'CompanyEventAdditionalInfo',
  components: {
    SynPanel,
  },
  mixins: [viewCompanyMixin],
  props: {},
  data() {
    return {
      panelTitle: 'Additional Information',
      isLoading: true,
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.isLoading = false
  },
  destroyed() {},
  methods: {},
}
</script>
