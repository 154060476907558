import state from './leisures.state'
import mutations from './leisures.mutations'
import actions from './leisures.actions'
import getters from './leisures.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
