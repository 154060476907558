import state from './home.state'
import mutations from './home.mutations'
import actions from './home.actions'
import getters from './home.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
