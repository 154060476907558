import {
  deleteObject,
  getDownloadURL as getStorageDownloadURL,
  ref,
  uploadBytes,
  // connectStorageEmulator,
} from 'firebase/storage'
import { storage } from '@/firebase/initFirebase'

// if (
//   window.location.hostname === 'localhost' &&
//   process.env.VUE_APP_USE_EMULATOR === 'true'
// ) {
//   connectStorageEmulator(storage, '127.0.0.1', 9199)
// }

// Bucket URL from Storage in Firebase Console
// const BUCKET_URL = 'gs://synemail.appspot.com' // TODO get from env file using process.env

// // Uploads image and returns the storage bucket
// export async function uploadImage(image, uid) {
//   const formattedDate = new Date()
//   const bucket = `${BUCKET_URL}/${uid}/${formattedDate}.jpg`
//   await uploadBytes(ref(storage, bucket), image)
//   return bucket
// }

// Replaces existing image in storage and returns the storage bucket
export async function replaceFile(image, bucket) {
  await uploadBytes(ref(storage, bucket), image)
}

// Deletes file in storage
export async function deleteFile(bucket) {
  await deleteObject(ref(storage, bucket))
}

// Gets the download URL from the reference URL
export async function getDownloadURL(bucket) {
  return getStorageDownloadURL(ref(storage, bucket))
}
