import viewCompanyMixin from './viewCompanyMixin'
import editCompanyMixin from './editCompanyMixin'
import store from '@/store'

function initializeCompanyState() {
  store.commit('company/setCompanyToView', undefined, { root: true })
  store.commit('company/setCompanyToEdit', undefined, { root: true })
  store.commit('company/setCompanyAdmins', undefined, { root: true })
  store.commit('company/setCompanyNetwork', undefined, { root: true })
  store.commit('company/setCompanyLocations', [], { root: true })
  store.commit('company/setCompanyBroadcasts', [], { root: true })
  store.commit('company/setCompanyTokens', [], { root: true })
  store.commit('company/setCompanyEventToView', undefined, { root: true })
  store.commit('company/setCompanyEventToEdit', undefined, { root: true })
  store.commit('company/setUnpublishedCompanyEvents', [], { root: true })
  store.commit('company/setIsUserCompanyAdmin', false, { root: true })
  store.commit('company/setIsUserInSameCompany', false, { root: true })

  store.commit('company/setCompanyAppConfig', {}, { root: true })
  store.commit('app/resetAppErrors', null, { root: true })
}
export { viewCompanyMixin, editCompanyMixin, initializeCompanyState }
