export default {
  allNetworkEvents: [],
  allNetworkUpdates: [],
  allTeamEvents: [],
  allRecurringTeamEvents: [],
  allTeamBulletins: [],
  allTeamBroadcasts: [],
  allTeamUpdates: [],
  allCompanyEvents: [],
}
