var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"event-meeting-notes"},[_c('div',{staticClass:"view-event-meeting-notes",attrs:{"width-class":"is-12"}},[(_vm.isLoading)?_c('skeleton'):_vm._e(),(_vm.teamEventToView)?_c('SiynCollapse',{attrs:{"title":_vm.panelTitle,"is-open":_vm.collapseHelper(_vm.panelname),"panelname":_vm.panelname},scopedSlots:_vm._u([{key:"header-extras",fn:function(){return [_c('b-tooltip',{staticClass:"mx-4",attrs:{"multilined":"","type":"is-dark","label":_vm.showTooltipsFromCode('teamevents/restrict/visibility'),"position":"is-right"}},[_c('b-icon',{staticClass:"has-text-grey-label",attrs:{"pack":"fas","icon":"eye","size":"is-small"}})],1)]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"view-event-meeting-notes__wrapper px-4 py-2"},[(_vm.teamMeetingNotes)?_c('b-table',{ref:"table",staticClass:"event-meeting-notes__table",attrs:{"data":_vm.teamMeetingNotes,"hoverable":"","mobile-cards":"","narrowed":"","custom-row-key":"id","show-header":_vm.teamMeetingNotes.length > 0 ? true : false,"paginated":_vm.teamMeetingNotes.length > 8 ? true : false,"default-sort":"createTimestamp","default-sort-direction":"desc","per-page":"8","aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page"}},[_c('b-table-column',{attrs:{"field":"notes","label":"Original Notes","cell-class":"valign","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.notes)?_c('a',{attrs:{"label":"Meeting Notes"},on:{"click":function($event){return _vm.viewNotesModal(props.row.id)}}},[_c('b-tag',{staticClass:"event-meeting-notes__tag"},[_c('b-icon',{attrs:{"pack":"far","icon":"eye","type":"is-dark","label":"View","size":"is-medium"}})],1)],1):_vm._e(),(
                  (props.row.notes &&
                    _vm.getAuthUserId === props.row.createdBy.id) ||
                  _vm.isUserTeamAdminCache
                )?_c('a',{attrs:{"label":"Meeting Notes"},on:{"click":function($event){return _vm.editNotesModal(props.row.id)}}},[_c('b-tag',{staticClass:"event-meeting-notes__tag"},[_c('b-icon',{attrs:{"pack":"far","icon":"edit","type":"is-primary","label":"Edit","size":"is-medium"}})],1)],1):_vm._e(),_c('b-modal',{attrs:{"can-cancel":true},model:{value:(_vm.isNotesModalActive),callback:function ($$v) {_vm.isNotesModalActive=$$v},expression:"isNotesModalActive"}},[(_vm.tempNotes)?_c('SynPanel',{attrs:{"title":_vm.panelTitle}},[_c('p',[_c('b',[_vm._v("Created: ")]),_vm._v(_vm._s(_vm.momentAgo(props.row.createTimestamp))+" "),_c('b',{staticClass:"ml-2"},[_vm._v("By: ")]),_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                        name: 'profile',
                        params: { uid: props.row.createdBy.id },
                      }}},[_vm._v(_vm._s(props.row.createdBy.displayName))])],1),(props.row.updateTimestamp)?_c('p',[_c('b',[_vm._v("Last Updated: ")]),_vm._v(_vm._s(_vm.momentAgo(props.row.updateTimestamp))+" "),_c('b',{staticClass:"ml-2"},[_vm._v("By: ")]),_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                        name: 'profile',
                        params: { uid: props.row.lastUpdatedBy.id },
                      }}},[_vm._v(_vm._s(props.row.lastUpdatedBy.displayName))])],1):_vm._e(),(props.row.notesImageURL)?_c('p',{staticClass:"my-2"},[_c('b',[_vm._v("Handwritten Notes Image: ")]),_c('a',{attrs:{"target":"_blank","href":props.row.notesImageURL}},[_vm._v("Click to view")])]):_vm._e(),_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"white-space-preline event-meeting-notes__text sm-list-styles",domProps:{"innerHTML":_vm._s(_vm.removeEmptyPTags(_vm.getTempNotes))}})]):_vm._e()],1)]}}],null,false,3485384932)}),_c('b-table-column',{attrs:{"field":"summarizedText","label":"🤖 AI Summary","cell-class":"valign","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.summarizedText)?_c('a',{attrs:{"label":"AI Summary"},on:{"click":function($event){return _vm.viewAISummaryModal(props.row.id)}}},[_c('b-tag',{staticClass:"event-meeting-notes__tag"},[_c('b-icon',{attrs:{"pack":"far","icon":"eye","type":"is-dark","label":"View","size":"is-medium"}})],1)],1):_vm._e(),(
                  props.row.summarizedText &&
                  (_vm.getAuthUserId === props.row.createdBy.id ||
                    _vm.isUserTeamAdminCache)
                )?_c('a',{attrs:{"label":"Edit Summary"},on:{"click":function($event){return _vm.editAISummaryModal(props.row.id)}}},[_c('b-tag',{staticClass:"event-meeting-notes__tag"},[_c('b-icon',{attrs:{"pack":"far","icon":"edit","type":"is-primary","label":"Edit","size":"is-medium"}})],1)],1):_vm._e(),_c('b-modal',{attrs:{"can-cancel":true},model:{value:(_vm.isSummaryModalActive),callback:function ($$v) {_vm.isSummaryModalActive=$$v},expression:"isSummaryModalActive"}},[(_vm.tempSummarizedText)?_c('SynPanel',{attrs:{"title":"Notes Summarized by ChatGPT®"}},[(props.row.summaryDateTime)?_c('p',[_c('b',[_vm._v("Created: ")]),_vm._v(_vm._s(_vm.momentAgo(props.row.summaryDateTime))+" "),_c('b',{staticClass:"ml-2"},[_vm._v("By Model: ")]),_c('span',[_vm._v(_vm._s(props.row.summaryCreatedBy.displayName))])]):_vm._e(),(
                      props.row.summaryUpdateDateTime &&
                      props.row.summaryUpdatedBy
                    )?_c('p',[_c('b',[_vm._v("Last Updated: ")]),_vm._v(_vm._s(_vm.momentAgo(props.row.summaryUpdateDateTime))+" "),_c('b',{staticClass:"ml-2"},[_vm._v("By: ")]),(props.row.summaryUpdatedBy.id)?_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                        name: 'profile',
                        params: { uid: props.row.summaryUpdatedBy.id },
                      }}},[_vm._v(_vm._s(props.row.summaryUpdatedBy.displayName))]):_c('span',[_vm._v(_vm._s(props.row.summaryUpdatedBy.displayName))])],1):_vm._e(),_c('hr',{staticClass:"my-2"}),(props.row.summarizedText)?_c('div',{staticClass:"white-space-preline event-meeting-notes__text sm-list-styles",domProps:{"innerHTML":_vm._s(_vm.removeEmptyPTags(_vm.getTempSummarizedText))}}):_c('div',[_c('p',[_c('i',[_vm._v("No Summary found")])])])]):_vm._e()],1)]}}],null,false,4150162020)}),_c('b-table-column',{attrs:{"field":"createdBy","label":"Author","sortable":"","cell-class":"valign"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.createdBy)?_c('span',[_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                    name: 'profile',
                    params: { uid: props.row.createdBy.id },
                  }}},[_vm._v(_vm._s(props.row.createdBy.displayName))])],1):_vm._e()]}}],null,false,3056532399)}),_c('b-table-column',{attrs:{"field":"createTimestamp","label":"Created","sortable":"","cell-class":"valign"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.createTimestamp)?_c('span',[_vm._v(" "+_vm._s(_vm.momentAgo(props.row.createTimestamp))+" ")]):_vm._e()]}}],null,false,3155487717)}),_c('b-table-column',{attrs:{"field":"id","label":"Action","centered":"","cell-class":"valign"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{staticClass:"no-text-decoration",on:{"click":function($event){return _vm.openSideBySide(props.row.id)}}},[_c('b-tooltip',{attrs:{"label":"View Notes & AI Summary Side By Side","position":"is-left","type":"is-dark"}},[_c('b-icon',{attrs:{"icon":"right-left","pack":"fas","type":"is-info","size":"is-medium"}})],1)],1),(
                  _vm.getAuthUserId === props.row.createdBy.id ||
                  _vm.isUserTeamAdminCache
                )?_c('a',{staticClass:"no-text-decoration ml-2",on:{"click":function($event){return _vm.deleteNotesVue(props.row.id)}}},[_c('b-tooltip',{attrs:{"label":_vm.showLabelsFromCode('generic/delete'),"position":"is-left","type":"is-dark"}},[_c('b-icon',{attrs:{"icon":"trash-alt","pack":"fa","type":"is-danger","size":"is-medium"}})],1)],1):_vm._e()]}}],null,false,3168022170)})],1):_vm._e(),(
              (_vm.isUserTeamEventAttendee ||
                _vm.isUserPartOfTeamCache ||
                _vm.teamEventToView.createdBy.id === _vm.getAuthUserId) &&
              _vm.teamToView &&
              _vm.teamToView.teamStatus == _vm.teamStatusConstants.ACTIVE
            )?_c('div',{staticClass:"event-meeting-notes__button-wrapper"},[_c('b-button',{staticClass:"event-meeting-notes__edit-button",attrs:{"type":"is-danger","title":"Add Notes"},on:{"click":_vm.addMeetingNotes}},[_vm._v("Add Notes ")])],1):_vm._e()],1)]},proxy:true}],null,false,776516626)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }