import { find } from 'lodash'

export default {
  /**
   * Check if an event has deletion pending
   */

  isEventsDeletePending: (state) => (eventsId) =>
    state.eventsDeletePending.includes(eventsId),

  /**
   * Get  by id
   */
  getEventsById: (state) => (eventsId) =>
    find(state.events, (events) => events.id === eventsId),
}
