<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="event-location">
    <div class="view-event-location">
      <skeleton v-if="isLoading"></skeleton>

      <SynPanel v-if="teamEventToView && !isLoading" :title="panelTitle">
        <div class="view-event-location__wrapper px-4 py-2">
          <template v-if="teamEventToView.eventLocationType === 'inperson'">
            <a
              :href="buildGoogleMapsURL(teamEventToView.eventAddressLocation)"
              class="no-text-decoration is-inline-block"
              rel="noopener noreferrer"
              target="_blank"
              ><b-icon pack="fas" icon="map-pin" class="ml-2 mr-1"> </b-icon>
              {{ teamEventToView.eventAddressLocation }}</a
            >
          </template>
          <template
            v-else-if="teamEventToView.eventLocationType === 'confcall'"
          >
            <div class="ml-2" v-html="teamEventToView.confcallInfo"></div>
          </template>
          <template v-else>
            <div class="columns is-vcentered is-multiline">
              <div class="column is-auto is-narrow">
                <span class="sm-label">Meeting Link : </span>
              </div>
              <div class="column is-9">
                <span class="sm-value">
                  <a
                    v-if="teamEventToView?.conferencing?.details?.url"
                    :href="teamEventToView?.conferencing?.details?.url"
                    class="button is-dark has-text-left"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <b-icon pack="fas" icon="video" class="mr-2"> </b-icon>
                    {{
                      renderConferenceProvider(
                        teamEventToView?.conferencing?.provider,
                      )
                    }}
                  </a>
                </span>
              </div>
              <div
                v-if="teamEventToView?.conferencing?.details?.phone"
                class="column is-2"
              >
                <span class="sm-label has-text-right">Phone : </span>
              </div>
              <div
                v-if="teamEventToView?.conferencing?.details?.phone"
                class="column is-4"
              >
                <span class="sm-value">{{
                  teamEventToView?.conferencing?.details?.phone
                }}</span>
              </div>
              <div
                v-if="teamEventToView?.conferencing?.details?.pin"
                class="column is-2"
              >
                <div class="sm-label has-text-right">Pin :</div>
              </div>
              <div
                v-if="teamEventToView?.conferencing?.details?.pin"
                class="column is-4"
              >
                <span class="sm-value">{{
                  teamEventToView.conferencing.details.pin
                }}</span>
              </div>
              <div
                v-if="teamEventToView?.conferencing?.details?.meetingCode"
                class="column is-2"
              >
                <div class="sm-label has-text-right">Code :</div>
              </div>
              <div
                v-if="teamEventToView?.conferencing?.details?.meetingCode"
                class="column is-4"
              >
                <span class="sm-value">{{
                  teamEventToView.conferencing.details.meetingCode
                }}</span>
              </div>
              <div
                v-if="teamEventToView?.conferencing?.details?.password"
                class="column is-2"
              >
                <div class="sm-label has-text-right">Password :</div>
              </div>
              <div
                v-if="teamEventToView?.conferencing?.details?.password"
                class="column is-4"
              >
                <span class="sm-value">{{
                  teamEventToView.conferencing.details.password
                }}</span>
              </div>
            </div>
          </template>
        </div>
      </SynPanel>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations } from 'vuex'
import SynPanel from '@/components/SynPanel.vue'
import Skeleton from '@/components/partials/SkeletonMedia'
import { viewTeamsMixin } from '@/mixins/teams/index'

export default {
  name: 'TeamEventLocation',
  components: {
    SynPanel,
    Skeleton,
  },
  mixins: [viewTeamsMixin],
  props: {},
  data() {
    return {
      googleMapsBaseURL: 'http://maps.google.com/maps?q=',
      isLoading: true,
      panelTitle: 'Meeting Information',
    }
  },
  computed: {
    ...mapState('teamsEvents', ['teamEventToView']),
  },
  created() {},
  mounted() {
    this.isLoading = false
  },
  destroyed() {},
  methods: {
    ...mapMutations('teamsEvents', ['setTeamEventToEdit']),
    buildGoogleMapsURL(address) {
      if (!this.isEmpty(address)) {
        this.panelTitle = 'Meeting Location'
        return `${this.googleMapsBaseURL}${encodeURIComponent(address)}`
      }
      return address
    },
  },
}
</script>
