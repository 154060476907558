/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/extensions
import first from 'lodash/first'
import UserEmailPreferencesDB from '@/firebase/emailpreferences/email-preferences-db'
import { showUserMessageFromCode } from '@/misc/helpers'

export default {
  /** ************ NEW CODE ************ */
  fetchEmailPreferencesByUserId: async ({ commit }, userId) => {
    const userEmailPreferencesDB = new UserEmailPreferencesDB(userId)
    return userEmailPreferencesDB.readAll()
  },
  createUpdateEmailPreferences: async ({ rootState, commit }, payload) => {
    const userEmailHabitsDb = new UserEmailPreferencesDB(rootState.user.user.id)
    const emailPrefResponse = await userEmailHabitsDb
      .createOrUpdate(payload)
      .then((result) => {
        return result
      })
      .catch((err) => {
        throw err
      })

    return emailPrefResponse
  },
}
// getUserEmailHabitsByUserId: async ({ commit }, userId) => {
//   const userEmailHabitsDB = new UserEmailPreferencesDB(userId)
//   const emailhabits = await userEmailHabitsDB.readAll()
//   commit('setEmailHabits', emailhabits) // have to send it back as array and not object because b-table expects array
// },
// getEmailHabitsObjectById: async ({ commit }, userId) => {
//   const userEmailHabitsDB = new UserEmailPreferencesDB(userId)
//   const emailhabits = await userEmailHabitsDB.readAll()
//   commit('setEmailHabits', emailhabits[0])
// },
// https://stackoverflow.com/questions/46097687/vuex-passing-multiple-parameters-to-mutation
// getEmailHabitsbyId: async ({ commit }, payload) => {
//   const userEmailHabitsDb = new UserEmailPreferencesDB(payload.userId)
//   const emailhabits = await userEmailHabitsDb.read(payload.ehId)
//   commit('setEmailHabits', emailhabits)
// },
/**
 * Create for current loggedin user
 */
// addEmailHabits: async ({ commit, rootState }, emailhabits) => {
//   const userEmailHabitsDb = new UserEmailPreferencesDB(
//     rootState.user.user.id
//   )
//   commit('SetEmailHabitsAddPending', true)
//   await userEmailHabitsDb.create(emailhabits)
//   commit('setEmailHabits', emailhabits)
//   commit('SetEmailHabitsAddPending', false)
// },
// updateEmailHabits: async ({ rootState, commit }, emailhabits) => {
//   commit('SetEmailHabitsUpdatePending', true)
//   const userEmailHabitsDb = new UserEmailPreferencesDB(
//     rootState.user.user.id
//   )
//   await userEmailHabitsDb.update(emailhabits)
//   commit('setEmailHabits', emailhabits)
//   commit('SetEmailHabitsUpdatePending', false)
// },
