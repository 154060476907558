<template>
  <section class="email-signature">
    <full-page max-width="is-max-widescreen" width-class="is-12">
      <b-loading v-model.trim="isLoading" :is-full-page="true">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon
      ></b-loading>
      <list-page panel-title="Email Signature Templates">
        <b-message type="is-info" class="email-signature__message">
          <p class="has-text-weight-bold is-size-6">
            How to share your <u>{{ appTitle }}</u> link in your Email
            Signature:
          </p>
          <ol class="pl-4">
            <li>
              <b-icon icon="clipboard" pack="fas" /> Select contents (image,
              text, etc.) of the chosen email signature template box with mouse
              and copy it (Select content, Right click and Copy OR Ctrl+C / ⌘+C)
              .
            </li>
            <li>
              <b-icon icon="paste" pack="fas" /> Open your Email Application and
              paste (Right click & select Paste OR Ctrl+V / ⌘+V) the contents in
              the Email Signature screen.
            </li>
            <li>
              See how to add Email Signature for:
              <ul class="pl-4">
                <li>
                  <a
                    href="https://support.microsoft.com/en-us/office/create-and-add-a-signature-to-messages-8ee5d4f4-68fd-464a-a1c1-0e1c80bb27f2"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Microsoft Outlook&reg;</a
                  >
                </li>
                <li>
                  <a
                    href="https://support.google.com/mail/answer/8395"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Gmail&reg;</a
                  >
                </li>
                <li>
                  <a
                    href="https://support.apple.com/guide/mail/create-and-use-email-signatures-mail11943/mac"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Mail on Mac&reg;</a
                  >
                </li>
                <li>
                  <a
                    href="https://help.airmailapp.com/en-us/article/signature-add-multiple-signatures-airmail-for-macos-11qva9j/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Airmail&reg;</a
                  >
                </li>
              </ul>
            </li>
          </ol>
        </b-message>

        <div class="box">
          <b-field grouped>
            <b-switch
              v-model="showImage"
              :rounded="false"
              :left-label="true"
              class="has-text-weight-bold"
            >
              Show Profile Image?
            </b-switch>
            <b-switch
              v-model="showTitle"
              :rounded="false"
              :left-label="true"
              class="has-text-weight-bold"
            >
              Show Title?
            </b-switch>
            <b-switch
              v-model="showContactInfo"
              :rounded="false"
              :left-label="true"
              class="has-text-weight-bold"
            >
              Show Contact Info?
            </b-switch>
          </b-field>
        </div>
        <div class="columns is-multiline">
          <div class="column is-3">
            <div class="card">
              <div class="card-content">
                <view-user-avatar
                  v-if="showImage"
                  :imgsrc="user.photoURL"
                  :alt-name="user.displayName"
                />
                <p class="has-text-weight-bold is-size-4">
                  {{ user.displayName }}
                </p>
                <p v-if="showTitle" class="is-size-5">
                  {{ user.title }}
                </p>
                <span v-if="showContactInfo" class="is-size-8">
                  <p v-if="privateContactInfo?.companyAddress">
                    {{ privateContactInfo.companyAddress }}
                  </p>
                  <a v-if="user.email" :href="'mailto:' + user.email">{{
                    user.email
                  }}</a>
                  <div
                    v-if="privateContactInfo?.deskPhone"
                    class="has-text-grey-light"
                  >
                    O:

                    <a
                      :href="
                        'tel:' + stripNonDigit(privateContactInfo?.deskPhone)
                      "
                      >{{ privateContactInfo?.deskPhone }}</a
                    >
                  </div>
                  <span
                    v-if="privateContactInfo?.mobilePhone"
                    class="has-text-grey-light"
                  >
                    M:
                  </span>
                  <a
                    :href="
                      'tel:' + stripNonDigit(privateContactInfo?.mobilePhone)
                    "
                    >{{ privateContactInfo?.mobilePhone }}</a
                  >
                </span>

                <router-link
                  class="is-block mt-1"
                  :to="{
                    name: 'profile',
                    params: { uid: user.id },
                  }"
                  >{{ appTitle }} Profile</router-link
                >
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="card">
              <div class="card-content">
                <view-user-avatar v-if="showImage" :imgsrc="user.photoURL" />
                <p class="has-text-weight-bold is-size-6">
                  {{ user.displayName }}
                </p>
                <p v-if="showTitle" class="is-size-6">
                  {{ user.title }}
                </p>
                <span v-if="showContactInfo" class="is-size-8">
                  <p v-if="privateContactInfo?.companyAddress">
                    <span class="has-text-grey-light">📍 : </span>
                    <a
                      :href="
                        buildGoogleMapsURL(privateContactInfo.companyAddress)
                      "
                      class="no-text-decoration"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {{ privateContactInfo.companyAddress }}</a
                    >
                  </p>
                  <p>
                    <span class="has-text-grey-light">📧 : </span>
                    <a v-if="user.email" :href="'mailto:' + user.email">{{
                      user.email
                    }}</a>
                  </p>
                  <div
                    v-if="privateContactInfo?.deskPhone"
                    class="has-text-grey-light"
                  >
                    📞 :

                    <a
                      :href="
                        'tel:' + stripNonDigit(privateContactInfo?.deskPhone)
                      "
                      >{{ privateContactInfo?.deskPhone }}</a
                    >
                  </div>
                  <div
                    v-if="privateContactInfo?.mobilePhone"
                    class="has-text-grey-light"
                  >
                    📱 :

                    <a
                      :href="
                        'tel:' + stripNonDigit(privateContactInfo?.mobilePhone)
                      "
                      >{{ privateContactInfo?.mobilePhone }}</a
                    >
                  </div>
                </span>
                <router-link
                  class="is-block mt-1"
                  :to="{
                    name: 'profile',
                    params: { uid: user.id },
                  }"
                  >{{ appTitle }} Profile</router-link
                >
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="card">
              <div class="card-content">
                <view-user-avatar v-if="showImage" :imgsrc="user.photoURL" />
                <p class="has-text-weight-bold is-size-8">
                  {{ user.displayName }}
                </p>
                <p v-if="showTitle" class="is-size-8">
                  {{ user.title }}
                </p>
                <span v-if="showContactInfo" class="is-size-8">
                  <p v-if="privateContactInfo?.companyAddress">
                    {{ privateContactInfo.companyAddress }}
                  </p>
                  <a v-if="user.email" :href="'mailto:' + user.email">{{
                    user.email
                  }}</a>

                  <a
                    class="is-block"
                    :href="
                      'tel:' + stripNonDigit(privateContactInfo?.deskPhone)
                    "
                    >{{ privateContactInfo?.deskPhone }}</a
                  >

                  <a
                    class="is-block"
                    :href="
                      'tel:' + stripNonDigit(privateContactInfo?.mobilePhone)
                    "
                    >{{ privateContactInfo?.mobilePhone }}</a
                  >
                </span>
                <router-link
                  class="is-block mt-1"
                  :to="{
                    name: 'profile',
                    params: { uid: user.id },
                  }"
                  >{{ appTitle }} Profile</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </list-page>
    </full-page>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapActions, mapMutations } from 'vuex'
import FullPage from '@/components/partials/FullPage'
import ViewUserAvatar from '@/components/ViewUserAvatar'
import ListPage from '@/components/ListPage.vue'
import {
  trackPageView,
  trackScreenView,
  trackExceptionEvent,
} from '@/misc/analytics'
import { handleErrorMessage } from '@/misc/user-messages'
import { stripNonDigit, buildGoogleMapsURL } from '@/misc/helpers'

export default {
  name: 'EmailSignatures',
  components: {
    ViewUserAvatar,
    ListPage,
    FullPage,
  },

  data() {
    return {
      isLoading: false,
      showImage: true,
      showTitle: true,
      showContactInfo: true,
    }
  },
  metaInfo: {
    title: 'Email Signature Templates',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  // the reason you need computed property because you want the component to react if the state changes.
  computed: {
    ...mapState('user', ['user']),
    ...mapState('app', ['appTitle', 'byline']),
    ...mapState('privateinfo', ['privateContactInfo']),
  },
  created() {
    this.fetchContactContent()
  },
  mounted() {
    this.isLoading = true
    if (!this.user.id && this.user) {
      this.$store.dispatch('user/getPublicProfileById', this.user.id)
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions('privateinfo', ['fetchPrivateContactInfoByUserId']),
    ...mapMutations('privateinfo', ['setPrivateContactInfo']),
    stripNonDigit,
    buildGoogleMapsURL,
    fetchContactContent() {
      this.fetchPrivateContactInfoByUserId(this.user.id)
        .then((privateDataResponse) => {
          this.setPrivateContactInfo(privateDataResponse)
        })
        .catch((err) => {
          console.log(err.message)
          handleErrorMessage('Issue in fetching contact information')
          trackExceptionEvent(
            'fetch_private_data',
            `Issue in fetching private data collection`,
            false,
          )
        })
    },
  },
}
</script>

<style scoped lang="scss">
.email-signature {
  &__message {
    padding: $base-size;
  }
  &__message-body {
  }
}
</style>
