<template>
  <a
    :href="getLink"
    target="_blank"
    rel="noopener noreferrer"
    :title="link.linkName + ' : ' + getLink"
    class="siynlink__link"
    @click="trackEvent(link.linkName)"
  >
    <span v-if="link.linkType !== 'other'">
      <b-icon
        class="siynlink__icon"
        :pack="link.pack"
        :icon="link.icon"
        size="is-medium"
      ></b-icon>
    </span>
    <span v-else>
      <b-taglist attached>
        <b-tag type="is-dark" class="siynlink__linkName tag-left">{{
          link.linkName
        }}</b-tag>
        <b-tag type="is-info" class="siynlink__icon tag-right"
          ><b-icon :pack="link.pack" :icon="link.icon" size="is-small"></b-icon
        ></b-tag>
      </b-taglist>
    </span>
  </a>
</template>

<script>
/* eslint-disable import/extensions */
import { trackClickLinkEvent } from '@/misc/analytics'

export default {
  name: 'SiynLink',
  props: {
    link: Object,
  },
  computed: {
    getLink() {
      return `${this.link.URL}`
    },
  },
  mounted() {},
  methods: {
    trackEvent(title) {
      trackClickLinkEvent('link_clicked', title, this.link.link, 'icon')
    },
  },
}
</script>
<style scoped lang="scss">
.siynlink {
  &__title {
    vertical-align: top;
  }
  &__linkName {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    font-size: 0.75 * $base-size;
    padding-right: $min-size * 0.5;
  }

  &__icon {
    padding-left: $base-size !important;
    padding-right: $min-size * 0.5 !important;
    margin-left: 0;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
</style>
