/* eslint-disable import/no-extraneous-dependencies */

// import OpenAI from 'openai'

// const openai = new OpenAI({
//   organization: 'org-k4MFfR55DL5mQJYuAezzXZGS',
//   apiKey: process.env.VUE_APP_OPENAI_API_KEY,
//   dangerouslyAllowBrowser: true,
// })

import { getApp } from 'firebase/app'
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions'

// import { encodeUsingRegex } from '@/misc/helpers'

const functions = getFunctions(getApp())

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
}

// replace HTML special character in javascript? https://stackoverflow.com/questions/36858774/how-to-replace-html-special-character-in-javascript
function generatePrompt(text) {
  // eslint-disable-next-line func-names
  const encodedStr = text.replace(/[\u00A0-\u9999<>]/g, function (i) {
    return `&#${i.charCodeAt(0)};`
  })
  return encodedStr
}

// function decodeHtml(html) {
//   const txt = document.createElement('textarea')
//   txt.innerHTML = html
//   return txt.value
// }
function replaceWithBRTags(text) {
  return text.replace(/\n/g, '<br />')
}
export async function summarizeWithAI(payload) {
  // console.log(JSON.stringify(payload))
  const summarizeNotes = httpsCallable(functions, 'summarizeNotes')

  const meetingNotesToSummarize = generatePrompt(payload.meetingNotes)

  const responseFromAI = await summarizeNotes({
    meetingNotesToSummarize,
    promptId: payload.promptId,
  })

  const responseText = {
    text: '',
    code: null,
  }

  const response = responseFromAI?.data?.summarizedText

  if (response?.choices?.length > 0) {
    console.log(
      JSON.stringify(
        `Tokens used by API Call : ${response.usage.total_tokens}`,
      ),
    )
    if (response.choices.length > 0 && response.choices[0].message.content) {
      if (response.choices[0].finish_reason === 'stop') {
        responseText.tokensUsed = response?.usage?.total_tokens || null
        responseText.text = replaceWithBRTags(
          response.choices[0].message.content,
        )
        responseText.code = 'SUCCESS'
      }
      if (response.choices[0].finish_reason === 'length') {
        responseText.code = 'TRUNCATED'
      }
    } else {
      responseText.text = 'AI Engine returned an empty response'
      responseText.code = 'EMPTY'
    }
  } else {
    responseText.text = 'AI Engine returned an unknown error'
    responseText.code = 'UNKNOWN'
  }
  return responseText
}

export default function aiHelper() {
  return true
}
