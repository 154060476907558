<template>
  <div class="edit-meeting-notes">
    <b-loading
      v-model="isLoading"
      :is-full-page="false"
      :can-cancel="false"
    ></b-loading>
    <Synform
      :title="formTitle"
      :operation="editAISummaryVue"
      :button-text="formTitle"
      :show-second-button="false"
      :modal-form="true"
    >
      <input v-model.trim="id" type="hidden" name="id" />
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="summarizedText"
        rules="required|min:10|max:10000"
      >
        <b-field
          class="required"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <tiptap-editor
            id="editor2"
            required
            :value="summarizedText"
            character-limit="10000"
            name="summarizedText"
            aria-placeholder="AI Summarized Text"
            @input="tipTapValue($event)"
          ></tiptap-editor>
        </b-field>
      </ValidationProvider>
    </Synform>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
/* import/no-extraneous-dependencies */
import { mapActions, mapState, mapMutations } from 'vuex'
import TiptapEditor from '@/components/TiptapEditor'
import { editTeamsMixin } from '@/mixins/teams/index'

import { trackPageView, trackScreenView } from '@/misc/analytics'

export default {
  name: 'EditOneTimeEventAISummaryNotes',
  components: {
    TiptapEditor,
  },
  mixins: [editTeamsMixin],
  props: {},
  data() {
    return {
      formTitle: `${this.showLabelsFromCode('generic/update')} AI Summary`,
      isLoading: true,
      id: null,
      summarizedText: '',
    }
  },
  metaInfo: {
    title: 'Edit AI Summary',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  computed: {
    ...mapState('teamsEvents', ['teamEventToEdit']),
  },

  created() {},
  mounted() {
    this.id = this.teamEventToEdit?.id
    this.summarizedText = this.teamEventToEdit?.summarizedText

    this.isLoading = false
  },
  methods: {
    ...mapActions('teamsEvents', ['updateOneTimeEventAISummaryAction']),
    ...mapMutations('teamsEvents', ['setTeamEventToView']),
    tipTapValue($event) {
      this.summarizedText = $event
    },
    editAISummaryVue() {
      this.isLoading = true

      const teamUpdate = {
        team: {
          id: this.teamEventToEdit.team.id,
          name: this.teamEventToEdit.team.name,
        },
        action: this.notificationActionTypesConstants.UPDATED,
        type: 'AI Summary',
        emoji: '🤖',
        message: `For event ${this.teamEventToEdit.eventName}`,
        showOnDashboard: true,
        priority: 2,
      }

      this.updateOneTimeEventAISummaryAction({
        teamEvent: this.teamEventToEdit,
        summarizedText: this.summarizedText,
        teamUpdate,
        user: this.user,
      })
        .then((reponse) => {
          this.setTeamEventToView(reponse.teamEvent)
          this.$emit('update_successful')
          this.close()
        })
        .catch((err) => {
          console.log(err)
          this.$emit('update_failed')
          this.close()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
