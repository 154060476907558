const networkStatusConstants = {
  REQUEST_SEND: 1,
  REQUEST_APPROVED: 2,
  REQUEST_REJECTED: 3,
  REMOVED: 4,
  BLOCKED: 5,
}

const networkStatusValues = [
  { id: networkStatusConstants.REQUEST_SEND, name: 'Request Send' },
  { id: networkStatusConstants.REQUEST_APPROVED, name: 'Approved' },
  { id: networkStatusConstants.REQUEST_REJECTED, name: 'Rejected' },
  { id: networkStatusConstants.REMOVED, name: 'Removed' },
  { id: networkStatusConstants.BLOCKED, name: 'Blocked' },
]

export { networkStatusConstants, networkStatusValues }
