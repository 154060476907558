var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"list-company-newsletteradmins"},[_c('div',{staticClass:"container"},[_c('div',{attrs:{"panel-title":_vm.panelTitle}},[_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('b-icon',{attrs:{"pack":"fas","icon":"spinner","size":"is-large","custom-class":"fa-pulse","type":"is-primary"}})],1),_c('SynPanel',{attrs:{"title":`${_vm.showLabelsFromCode('generic/edit')} ${_vm.showLabelsFromCode(
          'company/newsletter',
        )} ${_vm.showLabelsFromCode('generic/admin')}`}},[(_vm.companyToView.newsletterAdmins)?_c('div',[_c('b-table',{staticClass:"list-company-admins__table",attrs:{"data":_vm.companyToView.newsletterAdmins,"hoverable":"","mobile-cards":""}},[_c('b-table-column',{attrs:{"scope":"props","field":"displayName","label":_vm.showLabelsFromCode('generic/name'),"cell-class":"valign","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                  name: 'profile',
                  params: {
                    uid: props.row.id,
                  },
                },"replace":""}},[_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(props.row.displayName)+" ")])])]}}],null,false,896742492)}),_c('b-table-column',{attrs:{"field":"id2","label":"Delete","centered":"","cell-class":"valign"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_c('a',{staticClass:"no-text-decoration",on:{"click":function($event){return _vm.deleteCompanyNewsletterAdmin(props.row)}}},[_c('b-icon',{attrs:{"icon":"trash-alt","pack":"fa","type":"is-danger","size":"is-small"}})],1)])]}}],null,false,1808220890)})],1)],1):_c('div',[_c('span',{staticClass:"empty-content"},[_vm._v(_vm._s(_vm.showLabelsFromCode('generic/none')))])]),_c('div',{staticClass:"button-group has-text-centered mt-4"},[_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('div',{staticClass:"control"},[_c('b-button',{staticClass:"button",attrs:{"pack":"far","type":"is-info"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Close ")])],1)])],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }