<!-- For imgurl we dont know E.g. 
    <small-card :uid="user.id" :display-name="person.name" :title="person.title"  :company-name="person.companyName"/> -->
<!--For imgurl we do know E.g. 
     <small-card :imgsrc="user.photoURL" :display-name="person.name" :title="person.title"  :company-name="person.companyName"/> -->

<template>
  <small-card
    v-if="localUser"
    :uid="uid"
    :imgsrc="localUser.photoURL"
    :display-name="localUser.displayName"
    :title="localUser.title"
  />
</template>

<script>
/* eslint-disable import/extensions */
import { mapActions } from 'vuex'
import SmallCard from '@/components/SmallCard'

export default {
  name: 'BuildUserAvatar',
  components: { SmallCard },
  props: {
    uid: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      localUser: {
        type: Object,
        default: null,
      },
    }
  },
  created() {
    this.fetchUserByUserId(this.uid)
      .then((userResponse) => {
        if (userResponse.id) {
          this.localUser = userResponse
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    ...mapActions('user', ['fetchUserByUserId']),
  },
}
</script>
