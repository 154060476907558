<!--  -->
<template>
  <section class="view-comments">
    <skeleton v-if="isLoading"></skeleton>
    <siyn-collapse
      v-if="!isLoading && comments && comments.comment !== '<p></p>'"
      :title="panelTitle"
      emoji="💼"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <template #default>
        <!--eslint-disable-next-line vue/no-v-html !-->
        <span class="view-comments__comment" v-html="comments.comment"></span>
        <!--  <div class="view-comments__date">
          Last Updated : {{ renderDateTime(comments.updateTimestamp) }}
        </div> -->
      </template>
    </siyn-collapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
import { mapState, mapActions, mapMutations } from 'vuex'
import first from 'lodash/first'
import { viewProfileMixin } from '@/mixins/profile/index'
import { trackExceptionEvent } from '@/misc/analytics'
import { humanReadableDateTime } from '@/misc/helpers'

export default {
  name: 'ViewComments',
  mixins: [viewProfileMixin], // register mixin
  data() {
    return {
      panelTitle: this.showLabelsFromCode('profile/comments/panelTitle'),
      isLoading: true,
      panelname: 'comments_view',
    }
  },
  computed: {
    ...mapState('comments', ['comments']),
  },
  watch: {
    mergedPanelsConfig: {
      handler(mergedPanelsConfig) {
        // console.log(JSON.stringify(mergedPanelsConfig))
        if (!this.isEmpty(mergedPanelsConfig) && this.profileUser) {
          if (
            this.getAuthUserId === this.$route.params.uid ||
            this.getAuthUserCompanyId === this.profileUser.companyId
          ) {
            this.fetchCommentsContent()
          } else if (
            mergedPanelsConfig.comments ===
            this.visibilityConstants.VISIBLE_TO_ALL
          ) {
            this.fetchCommentsContent()
          } else if (
            mergedPanelsConfig.comments ===
              this.visibilityConstants.VISIBLE_TO_MY_NW &&
            this.checkIfYouAreInMyNetwork({
              userId: this.profileUser.id,
              network: this.network,
            })
          ) {
            this.fetchCommentsContent()
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    // console.log(JSON.stringify('comment created'))
  },
  mounted() {
    this.isLoading = false
    // console.log(JSON.stringify('comment mounted'))
  },
  methods: {
    ...mapMutations('comments', ['setComments']),
    ...mapActions('comments', ['fetchCommentsByUserId']),
    fetchCommentsContent() {
      this.fetchCommentsByUserId(this.$route.params.uid)
        .then((commentsResponse) => {
          if (commentsResponse) {
            // console.log(JSON.stringify(commentsResponse))
            this.nextTick(() => {
              this.setComments(first(commentsResponse))
            })
          }
        })
        .catch((err) => {
          if (err.code !== 'permission-denied') {
            console.log(err)
            this.handleErrorMessage(
              `${this.showUserMessageFromCode(
                'generic/error-in-fetch',
              )} comments.`,
            )
          }

          this.setComments(null)
          trackExceptionEvent(
            'comments_fetch_failed',
            `user  tried to fetch comments for ${this.$route.params.uid}. ${err}`,
            false,
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    renderDateTime: humanReadableDateTime,
  },
}
</script>
