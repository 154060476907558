var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mb-5"},[_c('b-table',{staticClass:"events__table",attrs:{"data":_vm.events,"hoverable":"","mobile-cards":"","show-header":_vm.events.length > 0 ? true : false,"default-sort":"startDateTime","default-sort-direction":"asc","paginated":_vm.events.length > 20 ? true : false,"per-page":"20","aria-next-label":_vm.showLabelsFromCode('generic/nextpage'),"aria-previous-label":_vm.showLabelsFromCode('generic/previouspage'),"aria-page-label":_vm.showLabelsFromCode('generic/page'),"aria-current-label":_vm.showLabelsFromCode('generic/currentpage')},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ml-4 empty-content"},[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('generic/none'))+" ")])]},proxy:true}])},[_c('b-table-column',{attrs:{"scope":"props","field":"eventName","label":_vm.showLabelsFromCode('profile/events/eventname'),"cell-class":"valign","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{attrs:{"title":_vm.renderEventTypeTitle(props.row.eventCategory, props.row.eventType)}},[(props.row.eventCategory === 'team')?_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
            name: 'viewteamevent',
            params: {
              teamid: props.row.team?.id,
              id: props.row.id,
            },
          }}},[_vm._v(_vm._s(_vm.truncateText(props.row.eventName, 20)))]):(props.row.eventCategory === 'company')?_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
            name: 'viewcompanyevent',
            params: {
              companyid: props.row.companyId,
              id: props.row.id,
            },
          }}},[_vm._v(_vm._s(_vm.truncateText(props.row.eventName, 20)))]):(
            _vm.uid === _vm.getAuthUserId ||
            _vm.getProfileUserCompanyId === _vm.getAuthUserCompanyId
          )?_c('span',[_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
              name: 'viewevent',
              params: {
                event: props.row,
                id: props.row.id,
                uid: _vm.uid,
              },
            }}},[_vm._v(_vm._s(_vm.mode === 'VIEW' ? _vm.truncateText(props.row.eventName, 20) : _vm.truncateText(props.row.eventName, 70)))]),(props.row.isOOO)?_c('b-tooltip',{attrs:{"type":"is-dark","label":_vm.showTooltipsFromCode('profile/events/isOOO'),"position":"is-right"}},[_c('b-icon',{staticClass:"ml-1 is-size-9",staticStyle:{"display":"inline-block","vertical-align":"text-top"},attrs:{"pack":"fas","icon":"building-circle-xmark","size":"is-small","type":"is-danger"}})],1):_vm._e()],1):_c('span',[_vm._v(_vm._s(_vm.truncateText(props.row.eventName, 40)))])],1)]}}])}),(_vm.getProfileUserCompanyId === _vm.getAuthUserCompanyId)?_c('b-table-column',{attrs:{"scope":"props","field":"eventCategory","label":_vm.showLabelsFromCode('profile/events/eventCategory'),"cell-class":"valign","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(_vm.capitalizeFirstLetter(props.row.eventCategory) || 'Individual')+" ")]}}],null,false,3114477842)}):_vm._e(),(_vm.mode === 'EDIT' || _vm.mode === 'LIST')?_c('b-table-column',{attrs:{"scope":"props","field":"eventType","label":_vm.showLabelsFromCode('profile/events/eventtype'),"cell-class":"valign","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.renderEventTypeColumn(props.row.eventCategory, props.row.eventType))+" ")])]}}],null,false,1395467855)}):_vm._e(),_c('b-table-column',{attrs:{"scope":"props","field":"startDateTime","label":_vm.showLabelsFromCode('generic/dates'),"cell-class":"valign","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.renderFromToDates(props.row.startDateTime, props.row.endDateTime))+" ")]),_c('AddToCalendar',{attrs:{"event-name":props.row.isOOO
            ? `${_vm.profileUser?.displayName} is Out of Office today (${props.row.eventName})`
            : props.row.eventName,"meeting-u-r-l":props.row.eventLocation || '',"event-description":`Added from event created by ${_vm.profileUser?.displayName} in ${_vm.appTitle}, view their profile at ${_vm.appURL}view/profile/${_vm.profileUser?.id} for more information.`,"start-date-time":props.row.startDateTime,"end-date-time":props.row.endDateTime,"addtext":""}})]}}])}),_c('b-table-column',{attrs:{"scope":"props","label":_vm.showLabelsFromCode('generic/comments'),"cell-class":"valign notes","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"notes-wrapper"},[(props.row.isAvailableOnSOS)?_c('span',[_c('span',{staticClass:"default-cursor",attrs:{"title":_vm.showLabelsFromCode('profile/events/sos')}},[_vm._v("🆘")])]):_c('span',[(props.row.isAvailableOnDeskPhone)?_c('span',{staticClass:"default-cursor",attrs:{"title":_vm.showLabelsFromCode('profile/events/deskphone')}},[_vm._v("☎️ ")]):_vm._e(),(props.row.isAvailableOnEmail)?_c('span',{staticClass:"default-cursor",attrs:{"title":_vm.showLabelsFromCode('profile/events/email')}},[_vm._v("✉️ ")]):_vm._e(),(props.row.isAvailableOnChat)?_c('span',{staticClass:"default-cursor",attrs:{"title":_vm.showLabelsFromCode('profile/events/chat')}},[_vm._v(" 💬")]):_vm._e(),(props.row.isAvailableOnMobilePhone)?_c('span',{staticClass:"default-cursor",attrs:{"title":_vm.showLabelsFromCode('profile/events/mobilePhone')}},[_vm._v("📱")]):_vm._e()]),(
            props.row.eventCategory === 'company' &&
            props.row.eventStatus === 2
          )?_c('span',[_c('span',{staticClass:"is-italic"},[_vm._v("Draft")])]):_vm._e(),(props.row.eventCategory === 'team')?_c('span',[_c('span',[_vm._v(_vm._s(_vm.getNameForId(props.row.eventType, _vm.teamEventTypes)))])]):_vm._e()])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }