/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import isNil from 'lodash/isNil'
import { connectAuthEmulator } from 'firebase/auth'
import { auth } from '@/firebase/initFirebase'
import store from '@/store/index'

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
}

auth.onAuthStateChanged((firebaseUser) => {
  const actionToDispatch = isNil(firebaseUser) ? 'logout' : 'login'
  store.dispatch(`user/${actionToDispatch}`, firebaseUser)
  // console.log(JSON.stringify(firebaseUser))
})
