/* eslint-disable import/extensions */
// eslint-disable-next-line no-unused-vars
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

import isPWA from '@/misc/helpers'
import packageInfo from '../../package'

const analytics = getAnalytics()

export default function FBanalyticshelper() {
  return true
}

export const trackPageView = async () => {
  logEvent(analytics, 'page_view')
  return true
}

export const trackScreenView = async (title) => {
  logEvent(analytics, 'screen_view', {
    app_name: isPWA() ? 'pwa' : 'web',
    firebase_screen: title,
    screen_name: title,
    app_version: packageInfo.version,
  })
  return true
}

export const logAppEvent = async (eventName) => {
  logEvent(analytics, eventName)
  return true
}

export const logAppEventCustomParams = async (eventName, params) => {
  logEvent(analytics, eventName, params)
  return true
}
/*
event_location can be 'footer', 'navbarmenu', 'navbar','page'
clickType can be 'link', 'button', 'image', 'text',
*/
export const trackNavigationEvent = async (
  eventName,
  label = '',
  eventLocation = '',
  clickType = 'link',
) => {
  logEvent(analytics, eventName, {
    event_category: 'navigation',
    event_label: label,
    event_location: eventLocation,
    clickType,
  })
  return true
}
/*
event_location can be 'footer', 'navbarmenu', 'navbar','page'
clickType can be 'link', 'button', 'image', 'text',
*/
export const trackEngagementEvent = async (
  eventName,
  label = '',
  eventLocation = '',
  clickType = 'link',
) => {
  logEvent(analytics, eventName, {
    event_category: 'engagement',
    event_label: label,
    event_location: eventLocation,
    clickType,
  })
  return true
}
/*
event_location can be 'footer', 'navbarmenu', 'navbar',
clickType can be 'link', 'button', 'image', 'text','page','icon','list'
*/
export const trackInteractionEvent = async (
  eventName,
  label = '',
  eventLocation = '',
  clickType = 'icon',
) => {
  logEvent(analytics, eventName, {
    event_category: 'interaction',
    event_label: label,
    event_location: eventLocation,
    clickType,
  })
  return true
}
/* event_type can be 'snackbar' */
export const trackExperienceEvent = async (eventName, type = '') => {
  logEvent(analytics, eventName, {
    event_category: 'experience',
    event_type: type,
  })
  return true
}
export const trackClickLinkEvent = async (
  eventName,
  label = '',
  clickDestination = '',
  clickType = 'icon',
) => {
  logEvent(analytics, eventName, {
    event_category: 'click_link',
    event_label: label,
    click_destination: clickDestination,
    clickType,
  })
  return true
}
// location: in a row what was the # of the item

export const trackConversionEvent = async (
  eventName,
  label = '',
  clickDestination = '',
  location = '',
) => {
  logEvent(analytics, eventName, {
    event_category: 'click_conversion',
    event_label: label,
    click_destination: clickDestination,
    item_location: location,
  })
  return true
}
export const trackExceptionEvent = async (
  eventName = 'app_exception',
  description = 'Error',
  isFatal = false,
) => {
  logEvent(analytics, eventName, {
    event_category: 'exception',
    event_description: description,
    is_event_fatal: isFatal,
  })
  return true
}
