/* eslint-disable no-unused-vars */

// import TeamTeamEventsDB from '@/firebase/teams/team-team-events'
import TeamEventsDB from '@/firebase/teams/team-events-db'
import TeamEventsMeetingNotesDB from '@/firebase/teams/team-events-meetingnotes'

import {
  unJoinTeamEventBatch,
  subscribeToTeamEventBatch,
  unSubscribeToTeamEventBatch,
  updateTeamsEventBatch,
  addMeetingNotesBatch,
  udpateMeetingNotesBatch,
  deleteMeetingNotesBatch,
  updateAISummaryBatch,
  addOneTimeMeetingNotesBatch,
  udpateOneTimeMeetingNotesBatch,
  deleteOneTimeMeetingNotesBatch,
  updateOneTimeAISummaryBatch,
} from '../teams/teameventsBatch'

import { teamEventStatusConstants } from '@/data/teams/team-event-status'

export default {
  fetchTeamEventById: async ({ commit }, teamEventId) => {
    const teamEventsDb = new TeamEventsDB()
    return teamEventsDb.read(teamEventId)
  },

  fetchTeamEventsByTeamId: async ({ commit }, payload) => {
    const teamEventsDb = new TeamEventsDB()
    return teamEventsDb.readAllWithOrderByLimit(
      payload.constraints,
      payload.orderBys,
      undefined,
    )
  },

  fetchTeamEventsByCompanyIdAndUserId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const teamEventsDb = new TeamEventsDB()
    const { limit } = payload

    let end = null
    if (payload.endDate) {
      end = payload.endDate
    } else {
      const endDays = payload.endDays || 30
      end = new Date()
      end.setDate(end.getDate() + endDays)
    }
    const today = new Date()
    const todayMidnight = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
    )

    const constraints = []
    constraints.push(['endDateTime', '>=', todayMidnight])
    constraints.push(['endDateTime', '<=', end])
    // constraints.push(['attendees', 'in', payload.attendee])
    // constraints.push(['attendees', 'array-contains', payload.attendee])
    constraints.push(['companyId', '==', payload.companyId])
    constraints.push(['eventStatus', '==', teamEventStatusConstants.SCHEDULED])
    const orderBys = []
    orderBys.push(['endDateTime', 'asc'])

    return teamEventsDb
      .readAllWithOrderByLimit(constraints, orderBys, limit)
      .then((response) => {
        // console.log(JSON.stringify(response))
        return response
      })
      .catch((err) => {
        throw err
      })

    /* if (teamEvents) {
      return Object.keys(teamEvents).map((o) => teamEvents[o]) // Converting to array for buefy table
    } */
    // return null
  },

  createTeamEvent: async ({ commit }, teamEvent) => {
    const TeamEventsDb = new TeamEventsDB()
    return TeamEventsDb.create(teamEvent)
  },

  // updateTeamEventRecords: async ({ commit }, teamEvent) => {
  //   return udpateTeamEventRecordsBatch(teamEvent)
  // },

  unJoinTeamEventAction: async ({ commit }, payload) => {
    return unJoinTeamEventBatch(payload)
  },

  subscribeToTeamEventAction: async ({ commit }, payload) => {
    return subscribeToTeamEventBatch(payload)
  },

  unSubscribeToTeamEventAction: async ({ commit }, payload) => {
    return unSubscribeToTeamEventBatch(payload)
  },

  updateTeamsEventAction: async ({ commit }, payload) => {
    return updateTeamsEventBatch(payload)
  },

  updateTeamEvent: async ({ commit }, teamEvent) => {
    const teamEventsDb = new TeamEventsDB()
    console.log(JSON.stringify(teamEvent.id))
    console.log(JSON.stringify(teamEvent.lastUpdatedBy))
    console.log(JSON.stringify(teamEvent.updateTimestamp))
    return teamEventsDb.update(teamEvent)
  },

  deleteTeamEvent: async ({ commit }, payload) => {
    const teamEventsDb = new TeamEventsDB()
    return teamEventsDb.delete(payload.teamEventId)
  },

  fetchMeetingNotesByIdsAction: async ({ commit }, teamEventId) => {
    const teamEventsMeetingNotesDB = new TeamEventsMeetingNotesDB(teamEventId)
    return teamEventsMeetingNotesDB.readAll() || null
  },

  /** ********************  RECURRING MEETING NOTES *********************** * */

  addMeetingNotesAction: async ({ commit }, payload) => {
    return addMeetingNotesBatch(payload)
  },
  updateMeetingNotesAction: async ({ commit }, payload) => {
    return udpateMeetingNotesBatch(payload)
  },
  deleteMeetingNotesAction: async ({ commit }, payload) => {
    return deleteMeetingNotesBatch(payload)
  },
  updateAISummaryAction: async ({ commit }, payload) => {
    return updateAISummaryBatch(payload)
  },
  /** ********************  ONETIME MEETING NOTES *********************** * */

  addOneTimeMeetingNotesAction: async ({ commit }, payload) => {
    return addOneTimeMeetingNotesBatch(payload)
  },
  updateOneTimeMeetingNotesAction: async ({ commit }, payload) => {
    return udpateOneTimeMeetingNotesBatch(payload)
  },
  deleteOneTimeMeetingNotesAction: async ({ commit }, payload) => {
    return deleteOneTimeMeetingNotesBatch(payload)
  },

  updateOneTimeEventAISummaryAction: async ({ commit }, payload) => {
    return updateOneTimeAISummaryBatch(payload)
  },
}
