<template>
  <section class="info">
    <full-page width-class="is-12" class="view-company-info">
      <skeleton-media v-if="isLoading"></skeleton-media>
      <SynPanel v-if="companyToView && !isLoading">
        <div class="columns is-gapless is-vcentered is-multiline">
          <div class="column is-4 has-text-centered">
            <view-user-avatar
              v-if="
                companyToView &&
                companyToView.logoImageURL &&
                companyToView.companyName
              "
              class="view-company-info__photoURL"
              :imgsrc="companyToView.logoImageURL"
              :alt-name="'Official Logo of ' + companyToView.companyName"
            />

            <b-button
              v-if="companyToView && isUserCompanyAdmin"
              class="view-company-info__editbutton background-color-override"
              type="is-danger"
              icon-left="edit"
              pack="far"
              @click="goToEditCompany()"
            >
              Edit Company
            </b-button>
          </div>

          <div class="column is-8">
            <div class="columns is-gapless is-vcentered is-multiline">
              <div class="column is-12">
                <h1 class="view-company-info__companyName">
                  {{ companyToView.companyName }}
                </h1>
              </div>
              <div v-if="companyToView.companyDescription" class="column is-12">
                <div class="view-company-info__companyDescription">
                  {{ companyToView.companyDescription }}
                </div>
              </div>
              <div
                v-if="companyToView && companyToView.companyContactInfo"
                class="column is-12"
              >
                <b-icon
                  v-if="companyToView.companyContactInfo.address"
                  class="view-company-info__address-icon icon-color-override"
                  pack="fas"
                  icon="location-arrow"
                  size="is-small"
                  title="Address"
                >
                </b-icon
                ><a
                  target="_blank"
                  @click="clickToMaps(companyToView.companyContactInfo.address)"
                  >{{ companyToView.companyContactInfo.address }}</a
                >
              </div>
              <div class="column is-12">
                <b-icon
                  v-if="companyToView.companyContactInfo.phoneNumber"
                  class="view-company-info__address-phoneNumber-icon icon-color-override"
                  pack="fas"
                  icon="phone-alt"
                  title="Main Phone Number"
                >
                </b-icon
                ><a
                  :href="
                    'tel:' +
                    stripNonDigit(companyToView.companyContactInfo.phoneNumber)
                  "
                  >{{ companyToView.companyContactInfo.phoneNumber }}</a
                >
              </div>
              <div class="column is-12">
                <b-icon
                  v-if="companyToView.companyURL"
                  class="view-company-info__companyURL-icon icon-color-override"
                  pack="fas"
                  icon="globe"
                  title="Address"
                >
                </b-icon
                ><a
                  target="_blank"
                  rel="noopener noreferrer"
                  :href="companyToView.companyURL"
                  >{{ companyToView.companyURL }}
                  <b-icon
                    pack="fas"
                    icon="external-link-alt"
                    class="icon-color-override"
                    title="Opens in a new tab"
                    style="vertical-align: text-top"
                  ></b-icon
                ></a>
              </div>
              <div
                v-if="companyToView && companyToView.companySocialMedia"
                class="column is-12"
              >
                <div
                  id="social-icons"
                  type="is-primary"
                  :closable="false"
                  class="view-company-info__companySocialMedia background-color-override"
                >
                  <b-taglist id="social-icons-list">
                    <linkstag
                      v-if="companyToView.companySocialMedia.blogURL"
                      :link="companyToView.companySocialMedia.blogURL"
                      icon="blog"
                      pack="fas"
                      title="Company Insights / Blog"
                    ></linkstag>
                    <linkstag
                      v-if="companyToView.companySocialMedia.linkedinURL"
                      :link="companyToView.companySocialMedia.linkedinURL"
                      icon="linkedin-in"
                      title="LinkedIn"
                    ></linkstag>
                    <linkstag
                      v-if="companyToView.companySocialMedia.twitterURL"
                      :link="companyToView.companySocialMedia.twitterURL"
                      icon="x-twitter"
                      title="X (Twitter)"
                    ></linkstag>
                    <linkstag
                      v-if="companyToView.companySocialMedia.facebookURL"
                      :link="companyToView.companySocialMedia.facebookURL"
                      icon="facebook"
                      title="Facebook"
                    ></linkstag>
                    <linkstag
                      v-if="companyToView.companySocialMedia.instagramURL"
                      :link="companyToView.companySocialMedia.instagramURL"
                      icon="instagram"
                      title="Instagram"
                    ></linkstag>
                    <linkstag
                      v-if="companyToView.companySocialMedia.youtubeURL"
                      :link="companyToView.companySocialMedia.youtubeURL"
                      icon="youtube"
                      title="Youtube"
                    ></linkstag>
                  </b-taglist>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SynPanel>
    </full-page>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapMutations } from 'vuex'
import linkstag from '@/components/LinksTag.vue'
import SynPanel from '@/components/SynPanel.vue'
import ViewUserAvatar from '@/components/ViewUserAvatar'
import SkeletonMedia from '@/components/partials/SkeletonMedia'
import { viewCompanyMixin } from '@/mixins/company/index'

export default {
  name: 'CompanyInfo',
  components: {
    SynPanel,
    SkeletonMedia,
    ViewUserAvatar,
    linkstag,
  },
  mixins: [viewCompanyMixin],
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.isLoading = false
  },

  methods: {
    ...mapMutations('company', ['setCompanyToEdit']),
    clickToMaps(address) {
      window.open(`https://maps.google.com/?q=${address}`, '_blank').focus()
    },
    goToEditCompany() {
      // console.log(JSON.stringify(this.companyToView))
      this.setCompanyToEdit(this.companyToView)
      this.$router.push({
        name: 'reauthenticate',
        params: {
          routeName: 'editcompany',
          recordid: this.companyToView.id,
        },
      })
    },
  },
}
</script>
