/* eslint-disable no-unused-vars */
import { mapState, mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import Skeleton from '@/components/partials/Skeleton'
import Synform from '@/components/SynForm'
import FullPage from '@/components/partials/FullPage'

import {
  showLabelsFromCode,
  showTooltipsFromCode,
  showUserMessageFromCode,
  getNameForId,
  momentAgo,
  closeDialog,
  humanReadableDateTime,
  formatDateTime,
} from '@/misc/helpers'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'
import {
  trackExperienceEvent,
  trackExceptionEvent,
  trackEngagementEvent,
} from '@/misc/analytics'
import router from '@/router'

const editCompanyMixin = {
  components: {
    Synform,
    Skeleton,
    ValidationProvider,
    FullPage,
  },
  data() {
    return {
      showError: false,
      errorMessage: `😕 ${this.showUserMessageFromCode(
        'form/generic/errorMessage',
      )}`,
    }
  },
  computed: {
    ...mapState('user', ['user', 'userCompany']),
    ...mapState('company', ['company', 'companyToEdit', 'companyToView']),
    ...mapState('app', ['appTitle', 'appShortTitle', 'byline']),
    ...mapGetters('user', [
      'isUserLoggedIn',
      'getAuthUserId',
      'getAuthUserCompanyId',
      'getAuthUserLicenseType',
      'getAuthUserRole',
      'getAuthUserEmailAddress',
      'getTinyUser',
    ]),
  },
  mounted() {
    if (this.$route.params.uid) {
      if (this.$route.params.uid !== this.user.id) {
        console.log(
          JSON.stringify(
            'Probably Illegal Operation, redirecting to edit/profile',
          ),
        )
        router.replace({ path: `/edit/profile` })
      }
    }
  },
  methods: {
    showLabelsFromCode,
    showTooltipsFromCode,
    showUserMessageFromCode,
    getNameForId,
    trackExperienceEvent,
    trackExceptionEvent,
    trackEngagementEvent,
    handleErrorMessage,
    handleSuccessMessage,
    humanReadableDateTime,
    isEmpty,
    first,
    momentAgo,
    closeDialog,
    formatDateTime,
  },
}

export default editCompanyMixin
