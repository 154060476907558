<template>
  <span class="box address-item border-color-override">
    <a
      tabindex="0"
      rel="noopener noreferrer"
      target="_blank"
      :href="createURL()"
      >{{ address
      }}<b-icon
        pack="fas"
        icon="external-link-alt"
        class="ml-2"
        title="Opens in a new tab"
        style="vertical-align: text-top"
      ></b-icon
    ></a>
  </span>
</template>

<script>
export default {
  name: 'AddressItem',
  props: {
    address: null,
  },
  data() {
    return {
      googleMapsURL: 'https://www.google.com/maps/search/?api=1&query=',
    }
  },
  computed: {
    encodeAddress() {
      return encodeURIComponent(this.address)
    },
  },
  methods: {
    createURL() {
      return this.googleMapsURL + this.encodeAddress
    },
  },
}
</script>
