<template>
  <footer class="footer has-background-white">
    <div class="container is-desktop has-text-centered">
      <div class="columns is-centered"></div>
      <div class="columns has-text-left">
        <div class="column">
          <div class="footer-column">
            <div class="footer-header">
              <p>Access</p>
            </div>
            <ul class="link-list">
              <li>
                <router-link
                  to="/login"
                  class="no-text-decoration"
                  style="text-decoration: none"
                  @click.native="
                    trackClick('click_to_login', 'Login', 'footer')
                  "
                  >Login</router-link
                >
              </li>
              <li>
                <router-link
                  to="/register"
                  class="no-text-decoration"
                  style="text-decoration: none"
                  @click.native="
                    trackClick(
                      'click_to_register',
                      'Sign Up for an Account',
                      'footer',
                    )
                  "
                  >Sign Up for an Account</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="column">
          <div class="footer-column">
            <div class="footer-header">
              <p>Company</p>
            </div>
            <ul class="link-list">
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <router-link
                  target="_blank"
                  to="/cookiepolicy"
                  @click.native="
                    trackClick(
                      'click_to_cookiepolicy',
                      'Cookie Policy',
                      'footer',
                    )
                  "
                  >Cookie Policy</router-link
                >
              </li>
              <li>
                <router-link
                  target="_blank"
                  to="/privacypolicy"
                  @click.native="
                    trackClick(
                      'click_to_privacypolicy',
                      'Privacy Policy',
                      'footer',
                    )
                  "
                  >Privacy Policy</router-link
                >
              </li>
              <li>
                <router-link
                  target="_blank"
                  to="/termsofservice"
                  @click.native="
                    trackClick('click_to_tos', 'Terms of Service', 'footer')
                  "
                  >Terms of Service</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="column">
          <div class="footer-column">
            <div class="footer-header">
              <p>Community</p>
            </div>
            <ul class="link-list">
              <li>
                <router-link
                  class="no-text-decoration"
                  :to="{ name: 'contactus', params: { type: 'bug' } }"
                  @click.native="
                    trackClick('click_to_contact', 'Report an Issue', 'footer')
                  "
                  >Report an Issue</router-link
                >
              </li>
              <li>
                <router-link
                  class="no-text-decoration"
                  :to="{ name: 'contactus', params: { type: 'feature' } }"
                  @click.native="
                    trackClick('click_to_contact', 'Request Feature', 'footer')
                  "
                >
                  Request Feature</router-link
                >
              </li>
              <li>
                <router-link
                  class="no-text-decoration"
                  :to="{ name: 'contactus', params: { type: 'question' } }"
                  @click.native="
                    trackClick('click_to_contact', 'Help', 'footer')
                  "
                >
                  Help</router-link
                >
              </li>
              <li>
                <router-link
                  class="no-text-decoration"
                  :to="{ name: 'contactus', params: { type: 'comment' } }"
                  @click.native="
                    trackClick('click_to_contact', 'General Comments', 'footer')
                  "
                  >General Comments</router-link
                >
              </li>
              <li>
                <router-link
                  class="no-text-decoration"
                  :to="{ name: 'contactus', params: { type: 'press' } }"
                  @click.native="
                    trackClick('click_to_contact', 'Press Inquiry', 'footer')
                  "
                  >Press Inquiry</router-link
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="column">
          <div class="footer-column">
            <div class="footer-header">
              <p>Connect</p>
            </div>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <b-icon pack="fab" icon="facebook-square"> </b-icon>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <b-icon pack="fab" icon="x-twitter"> </b-icon>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <b-icon pack="fab" icon="youtube-square"> </b-icon>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <b-icon pack="fab" icon="linkedin-in"> </b-icon>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <b-icon pack="fab" icon="reddit"> </b-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="container is-desktop has-text-centered-touch">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="logo">
              <router-link to="/home">
                <p class="has-text-body-color h3">
                  {{ appTitle }}
                </p>
                <p class="has-text-body-color">{{ byline }}</p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="copyright">
              <p class="has-text-body-color">
                &copy; 2022 - {{ currentYear }} {{ appTitle }}. All Rights
                Reserved.
              </p>

              <p class="has-text-body-color is-size-9">
                <router-link to="/dataproviders" @click.native="scrollToTop()"
                  >TV Show data</router-link
                >
                from
                <a href="https://www.themoviedb.org/" target="_blank">TMDb</a>
                |
                <router-link to="/dataproviders" @click.native="scrollToTop()"
                  >Book data</router-link
                >
                from
                <a href="https://openlibrary.org/" target="_blank"
                  >Open Library</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapGetters } from 'vuex'
import { trackNavigationEvent } from '@/misc/analytics'

export default {
  name: 'FooterPage',
  components: {},
  computed: {
    ...mapGetters('user', ['isUserLoggedIn']),
    ...mapState('app', [
      'networkOnLine',
      'appTitle',
      'appShortTitle',
      'byline',
    ]),
    currentYear() {
      return new Date().getFullYear()
    },
  },
  methods: {
    trackClick: trackNavigationEvent,
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  margin-top: $base-size;
  padding-bottom: $base-size * 2;
  .footer-header {
    margin-bottom: calc($base-size / 2);
    p {
      font-weight: 600;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
  }
}
</style>
