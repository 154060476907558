const companyEventVisibilityConstants = {
  INTERNAL: 1,
  PUBLIC: 2,
}

const companyEventVisibilityStatus = [
  {
    id: companyEventVisibilityConstants.INTERNAL,
    name: '🛡️ Internal Company Event',
  },
  { id: companyEventVisibilityConstants.PUBLIC, name: '🌐 Public Event' },
]

export { companyEventVisibilityConstants, companyEventVisibilityStatus }
