var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"panel-visibility"},[_c('Synform',{attrs:{"title":`${_vm.panelTitle} for ${_vm.convertPanelName(_vm.panelname)}`,"operation":_vm.updatePanelVisibility,"button-text":_vm.panelTitle,"modal-form":true}},[(_vm.showError)?_c('b-notification',{attrs:{"type":"is-danger is-light"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.errorMessage)}})]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"visibility","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{staticClass:"required",attrs:{"label":_vm.showLabelsFromCode('profile/generic/panelVisibility'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"name":"visibility"},model:{value:(_vm.visibility),callback:function ($$v) {_vm.visibility=$$v},expression:"visibility"}},[_c('option',{staticStyle:{"display":"none"},attrs:{"selected":"","disabled":"","hidden":"","value":""}},[_vm._v(" "+_vm._s(_vm.showLabelsFromCode('form/generic/pleaseSelect'))+" ")]),_vm._l((_vm.visibilityPreferences),function(frequency,index){return [(
                frequency.id !== _vm.visibilityConstants.VISIBLE_TO_NONE ||
                _vm.getAuthUserLicenseType === 'ENT'
              )?[_c('option',{key:index,domProps:{"value":frequency.id}},[_vm._v(" "+_vm._s(frequency.name)+" ")])]:_vm._e()]})],2)],1)]}}])}),(_vm.panelname === 'personalInfo')?_c('b-notification',{staticClass:"my-1",attrs:{"type":"is-danger is-light","aria-close-label":"Close notification","role":"alert"}},[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('panelvisibility/help/personalinfo'))+" ")]):_vm._e(),(
        _vm.visibility === _vm.visibilityConstants.VISIBLE_TO_NONE &&
        _vm.getAuthUserLicenseType === 'ENT'
      )?_c('b-notification',{staticClass:"my-1",attrs:{"type":"is-info is-light","aria-close-label":"Close notification","role":"alert"}},[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('panelvisibility/help/visibilityforsameorg'))+" ")]):_vm._e(),(_vm.visibility === _vm.visibilityConstants.VISIBLE_TO_ALL)?_c('b-notification',{staticClass:"my-1",attrs:{"type":"is-info is-light","aria-close-label":"Close notification","role":"alert"}},[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('panelvisibility/help/visibilityforall'))+" ")]):_vm._e(),(_vm.visibility === _vm.visibilityConstants.VISIBLE_TO_MY_NW)?_c('b-notification',{staticClass:"my-1",attrs:{"type":"is-info is-light","aria-close-label":"Close notification","role":"alert"}},[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('panelvisibility/help/visibilityfornw'))+" "),(_vm.getAuthUserLicenseType === 'ENT')?_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode( 'panelvisibility/help/visibilityfornwsamorg', ))+" ")]):_vm._e()]):_vm._e(),_c('b-collapse',{attrs:{"open":false,"position":"is-bottom","aria-id":"contentIdForA11y5"},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('a',{staticClass:"help-link",attrs:{"aria-controls":"contentIdForA11y5","aria-expanded":props.open}},[_c('b-icon',{attrs:{"pack":"fas","icon":props.open ? 'caret-up' : 'caret-down'}}),_vm._v(" "+_vm._s(!props.open ? 'Open Help' : 'Close Help')+" ")],1)]}}])},[_c('b-notification',{attrs:{"closable":false,"type":"is-danger"}},[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('panelvisibility/help/intro'))+" "+_vm._s(_vm.showUserMessageFromCode('panelvisibility/help/intro2'))+" "),_c('ul',{staticClass:"mt-2"},[_c('li',[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('panelvisibility/help/item1'))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('panelvisibility/help/item2'))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.showUserMessageFromCode('panelvisibility/help/item3'))+" ")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }