<template>
  <section>
    <div v-if="editor">
      <b-button
        size="is-small"
        :disabled="!editor.can().chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <b-icon type="is-dark" pack="fas" icon="bold" size="is-small"> </b-icon>
      </b-button>
      <b-button
        size="is-small"
        :disabled="!editor.can().chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <b-icon type="is-dark" pack="fas" icon="italic" size="is-small">
        </b-icon>
      </b-button>
      <b-button
        size="is-small"
        :disabled="!editor.can().chain().focus().toggleUnderline().run()"
        :class="{ 'is-active': editor.isActive('underline') }"
        @click="editor.chain().focus().toggleUnderline().run()"
      >
        <b-icon type="is-dark" pack="fas" icon="underline" size="is-small">
        </b-icon>
      </b-button>
      <b-button
        size="is-small"
        :disabled="!editor.can().chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
        @click="editor.chain().focus().toggleStrike().run()"
      >
        <b-icon type="is-dark" pack="fas" icon="strikethrough" size="is-small">
        </b-icon>
      </b-button>

      <b-button
        size="is-small"
        :class="{ 'is-active': editor.isActive('highlight') }"
        @click="editor.chain().focus().toggleHighlight().run()"
      >
        <b-icon
          type="is-dark"
          pack="fas"
          icon="highlighter"
          size="is-small"
        ></b-icon>
      </b-button>
      <b-button
        size="is-small"
        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
        @click="editor.chain().focus().setTextAlign('left').run()"
      >
        <b-icon
          type="is-dark"
          pack="fas"
          icon="align-left"
          size="is-small"
        ></b-icon>
      </b-button>
      <b-button
        size="is-small"
        :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
        @click="editor.chain().focus().setTextAlign('center').run()"
      >
        <b-icon
          type="is-dark"
          pack="fas"
          icon="align-center"
          size="is-small"
        ></b-icon>
      </b-button>
      <b-button
        size="is-small"
        :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
        @click="editor.chain().focus().setTextAlign('right').run()"
      >
        <b-icon
          type="is-dark"
          pack="fas"
          icon="align-right"
          size="is-small"
        ></b-icon>
      </b-button>
      <b-button
        size="is-small"
        :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
        @click="editor.chain().focus().setTextAlign('justify').run()"
      >
        <b-icon
          type="is-dark"
          pack="fas"
          icon="align-justify"
          size="is-small"
        ></b-icon>
      </b-button>
      <b-button
        size="is-small"
        :class="{ 'is-active': editor.isActive('link') }"
        @click="setLink"
      >
        <b-icon type="is-dark" pack="fas" icon="link" size="is-small"></b-icon
      ></b-button>
      <b-button
        size="is-small"
        @click="editor.chain().focus().unsetAllMarks().run()"
      >
        <b-icon
          type="is-dark"
          pack="fas"
          icon="eraser"
          size="is-small"
        ></b-icon>
      </b-button>
      <b-button
        size="is-small"
        @click="editor.chain().focus().clearNodes().run()"
      >
        <b-icon
          type="is-dark"
          pack="fas"
          icon="delete-left"
          size="is-small"
        ></b-icon>
      </b-button>
      <b-button
        size="is-small"
        :class="{ 'is-active': editor.isActive('paragraph') }"
        @click="editor.chain().focus().setParagraph().run()"
      >
        <b-icon type="is-dark" pack="fas" icon="p" size="is-small"> </b-icon>
      </b-button>
      <b-dropdown :triggers="['hover']" aria-role="list" :mobile-modal="false">
        <template #trigger>
          <b-button icon-right="caret-down" icon-pack="fas" size="is-small">
            <b-icon pack="fas" icon="heading" size="is-small"> </b-icon
          ></b-button>
        </template>

        <b-dropdown-item aria-role="listitem"
          ><b-button
            size="is-small"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            >H1
          </b-button></b-dropdown-item
        >
        <b-dropdown-item aria-role="listitem"
          ><b-button
            size="is-small"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          >
            H2
          </b-button></b-dropdown-item
        >
        <b-dropdown-item aria-role="listitem"
          ><b-button
            size="is-small"
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          >
            H3
          </b-button></b-dropdown-item
        >
        <b-dropdown-item aria-role="listitem"
          ><b-button
            size="is-small"
            :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
          >
            H4
          </b-button></b-dropdown-item
        >
        <b-dropdown-item aria-role="listitem"
          ><b-button
            size="is-small"
            :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
          >
            H5
          </b-button></b-dropdown-item
        >
        <b-dropdown-item aria-role="listitem"
          ><b-button
            size="is-small"
            :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
          >
            H6
          </b-button>
        </b-dropdown-item>
      </b-dropdown>

      <b-button
        size="is-small"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <b-icon
          type="is-dark"
          pack="fas"
          icon="list-ul"
          size="is-small"
        ></b-icon>
      </b-button>
      <b-button
        size="is-small"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        @click="editor.chain().focus().toggleOrderedList().run()"
      >
        <b-icon
          type="is-dark"
          pack="fas"
          icon="list-ol"
          size="is-small"
        ></b-icon>
      </b-button>
      <b-button
        size="is-small"
        :class="{ 'is-active': editor.isActive('blockquote') }"
        @click="editor.chain().focus().toggleBlockquote().run()"
      >
        <b-icon
          type="is-dark"
          pack="fas"
          icon="quote-left"
          size="is-small"
        ></b-icon>
      </b-button>
      <b-button
        size="is-small"
        @click="editor.chain().focus().setHorizontalRule().run()"
      >
        <svg
          class="icon w-6 h-6 text-gray-200"
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M2 11h2v2H2v-2zm4 0h12v2H6v-2zm14 0h2v2h-2v-2z"></path>
          </g>
        </svg>
      </b-button>
      <b-button
        size="is-small"
        @click="editor.chain().focus().setHardBreak().run()"
      >
        <svg
          class="icon w-6 h-6 text-gray-200"
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              d="M15 18h1.5a2.5 2.5 0 1 0 0-5H3v-2h13.5a4.5 4.5 0 1 1 0 9H15v2l-4-3 4-3v2zM3 4h18v2H3V4zm6 14v2H3v-2h6z"
            ></path>
          </g>
        </svg>
      </b-button>
      <b-button
        size="is-small"
        :disabled="!editor.can().chain().focus().undo().run()"
        @click="editor.chain().focus().undo().run()"
      >
        <b-icon type="is-dark" pack="fas" icon="rotate-left" size="is-small">
        </b-icon>
      </b-button>
      <b-button
        size="is-small"
        :disabled="!editor.can().chain().focus().redo().run()"
        @click="editor.chain().focus().redo().run()"
      >
        <b-icon type="is-dark" pack="fas" icon="rotate-right" size="is-small">
        </b-icon>
      </b-button>
    </div>
    <editor-content :editor="editor" />
    <div v-if="editor" class="character-count">
      {{ editor.storage.characterCount.characters() }} characters
    </div>
  </section>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { Editor, EditorContent } from '@tiptap/vue-2'
import CharacterCount from '@tiptap/extension-character-count'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import HardBreak from '@tiptap/extension-hard-break'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'

export default {
  name: 'TipTapEditor',
  components: {
    EditorContent,
    // eslint-disable-next-line vue/no-unused-components
    TextAlign,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    characterLimit: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      editor: null,
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        Typography,
        BulletList,
        OrderedList,
        ListItem,
        HardBreak,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Highlight,
        Placeholder.configure({
          // Use a placeholder:
          placeholder: 'Add content here …',
        }),
        CharacterCount.configure({
          limit: this.characterLimit,
        }),
        Link.configure({
          validate: (href) => /^https?:\/\//.test(href),
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      // eslint-disable-next-line no-alert
      const url = window.prompt(
        'URL (please add https://www at start)',
        previousUrl,
        'https://www.',
      )

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
  },
}
</script>
<style lang="scss">
/* Basic editor styles */
.tiptap {
  padding: $min-size;
  border-radius: 4px;
  min-height: 8rem;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  max-width: 100%;
  width: 100%;
  background-color: $white;
  border: 1px solid $grey-dark;
  border-radius: 4px;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }
}
.character-count {
  margin-left: 8px;
  @include smallFont();
  color: $grey-label;
}
</style>
