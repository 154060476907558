/* eslint-disable no-unused-vars */
import TeamLinksDB from '@/firebase/teams/team-links'

import {
  addTeamLinkBatch,
  updateTeamLinkBatch,
  deleteTeamLinkBatch,
} from '../teams/teamsBatch'

export default {
  addTeamLink: async ({ commit }, payload) => {
    const teamLinksDB = new TeamLinksDB(payload.team.id)
    try {
      teamLinksDB.create(payload.teamLink)
      return payload.teamLink
    } catch (error) {
      return error
    }
  },

  fetchTeamLinksByTeamId: async ({ commit }, payload) => {
    const teamLinksDB = new TeamLinksDB(payload.teamId)
    const teamLinks = await teamLinksDB.readAll()
    return teamLinks ? Object.keys(teamLinks).map((o) => teamLinks[o]) : null
  },

  updateTeamLink: async ({ commit }, teamLink) => {
    const teamLinksDB = new TeamLinksDB(teamLink.teamId)
    return teamLinksDB.update(teamLink)
  },

  addTeamLinkAction: async ({ commit }, payload) => {
    return addTeamLinkBatch(payload)
  },
  updateTeamLinkAction: async ({ commit }, payload) => {
    return updateTeamLinkBatch(payload)
  },
  deleteTeamLinkAction: async ({ commit }, payload) => {
    return deleteTeamLinkBatch(payload)
  },
}
