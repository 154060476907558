<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="panel-visibility">
    <Synform
      :title="`${panelTitle} for ${convertPanelName(panelname)}`"
      :operation="updatePanelVisibility"
      :button-text="panelTitle"
      :modal-form="true"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <b-notification v-if="showError" type="is-danger is-light">
        <div v-html="errorMessage"></div>
      </b-notification>

      <ValidationProvider
        v-slot="{ errors, valid }"
        name="visibility"
        rules="required"
      >
        <b-field
          :label="showLabelsFromCode('profile/generic/panelVisibility')"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          class="required"
        >
          <b-select v-model="visibility" name="visibility">
            <option selected disabled hidden style="display: none" value="">
              {{ showLabelsFromCode('form/generic/pleaseSelect') }}
            </option>
            <template v-for="(frequency, index) in visibilityPreferences">
              <template
                v-if="
                  frequency.id !== visibilityConstants.VISIBLE_TO_NONE ||
                  getAuthUserLicenseType === 'ENT'
                "
              >
                <option :key="index" :value="frequency.id">
                  {{ frequency.name }}
                </option>
              </template>
            </template>
          </b-select>
        </b-field>
      </ValidationProvider>
      <b-notification
        v-if="panelname === 'personalInfo'"
        class="my-1"
        type="is-danger is-light"
        aria-close-label="Close notification"
        role="alert"
      >
        {{ showUserMessageFromCode('panelvisibility/help/personalinfo') }}
      </b-notification>
      <b-notification
        v-if="
          visibility === visibilityConstants.VISIBLE_TO_NONE &&
          getAuthUserLicenseType === 'ENT'
        "
        class="my-1"
        type="is-info is-light"
        aria-close-label="Close notification"
        role="alert"
      >
        {{
          showUserMessageFromCode('panelvisibility/help/visibilityforsameorg')
        }}
      </b-notification>
      <b-notification
        v-if="visibility === visibilityConstants.VISIBLE_TO_ALL"
        class="my-1"
        type="is-info is-light"
        aria-close-label="Close notification"
        role="alert"
      >
        {{ showUserMessageFromCode('panelvisibility/help/visibilityforall') }}
      </b-notification>
      <b-notification
        v-if="visibility === visibilityConstants.VISIBLE_TO_MY_NW"
        class="my-1"
        type="is-info is-light"
        aria-close-label="Close notification"
        role="alert"
      >
        {{ showUserMessageFromCode('panelvisibility/help/visibilityfornw') }}

        <p v-if="getAuthUserLicenseType === 'ENT'" class="mt-2">
          {{
            showUserMessageFromCode(
              'panelvisibility/help/visibilityfornwsamorg',
            )
          }}
        </p>
      </b-notification>
      <b-collapse
        :open="false"
        position="is-bottom"
        aria-id="contentIdForA11y5"
      >
        <template #trigger="props">
          <a
            class="help-link"
            aria-controls="contentIdForA11y5"
            :aria-expanded="props.open"
          >
            <b-icon
              pack="fas"
              :icon="props.open ? 'caret-up' : 'caret-down'"
            ></b-icon>
            {{ !props.open ? 'Open Help' : 'Close Help' }}
          </a>
        </template>
        <b-notification :closable="false" type="is-danger">
          {{ showUserMessageFromCode('panelvisibility/help/intro') }}
          {{ showUserMessageFromCode('panelvisibility/help/intro2') }}
          <ul class="mt-2">
            <li>
              {{ showUserMessageFromCode('panelvisibility/help/item1') }}
            </li>
            <li>
              {{ showUserMessageFromCode('panelvisibility/help/item2') }}
            </li>
            <li>
              {{ showUserMessageFromCode('panelvisibility/help/item3') }}
            </li>
          </ul>
        </b-notification>
      </b-collapse>
    </Synform>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import startCase from 'lodash/startCase'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import Synform from '@/components/SynForm'
import { showLabelsFromCode, showUserMessageFromCode } from '@/misc/helpers'
import { trackExceptionEvent } from '@/misc/analytics'
import {
  visibilityPreferences,
  visibilityConstants,
} from '@/data/visibilityPreferences'

export default {
  name: 'AddEditLinksVisibility',
  components: {
    Synform,
    ValidationProvider,
  },
  props: {
    panelvalue: {
      type: Number,
      default: undefined,
    },
    panelname: {
      type: String,
      default: undefined,
      require: true,
    },
  },
  data() {
    return {
      visibilityPreferences,
      visibilityConstants,
      panelTitle: `${this.showLabelsFromCode(
        'generic/update',
      )} ${this.showLabelsFromCode('profile/generic/panelVisibility')}`,
      visibility: '',
      showError: '',
      errorMessage: '',
    }
  },
  computed: {
    ...mapGetters('user', ['getAuthUserLicenseType']),
    ...mapState('user', ['user']),
  }, // register mixin
  mounted() {
    this.visibility = this.panelvalue
  },
  methods: {
    showLabelsFromCode,
    showUserMessageFromCode,
    ...mapActions('appconfig', ['updateUserAppPanelsConfig']),
    ...mapMutations('appconfig', ['updateProfileUserAppConfigState']),

    updatePanelVisibility() {
      const visibilityObj = {
        id: 'panels',
        [this.panelname]: this.visibility,
      }
      this.updateUserAppPanelsConfig(visibilityObj)
        .then(() => {
          this.updateProfileUserAppConfigState({
            name: this.panelname,
            value: this.visibility,
          })
        })
        .then(() => {
          this.$emit('update_successful')
          this.close()
        })
        .catch((err) => {
          trackExceptionEvent(
            'visibility-panel_update_failed',
            `Uid ${this.user.id} tried to update panel visibliity for ${this.panelname}.  Error Message: ${err.message}`,
          )
          this.showError = true
          this.errorMessage = `${showUserMessageFromCode(
            'form/generic/errorMessage',
          )} Code : <b>'${err.code}'</b>`
          console.log(err.message)
        })
    },
    close() {
      this.$emit('close')
    },
    convertPanelName(panelname) {
      let response = ''
      switch (panelname) {
        case 'privateInfo':
          response = 'Contact'
          break
        default:
          response = startCase(panelname)
          break
      }
      return response
    },
  },
}
</script>
<style scoped lang="scss">
.modalform form {
  padding-top: 0 !important;
}
.help-link {
  padding: $min-size * 0.5 $min-size;
  background-color: $grey-background;
}

.notification {
  padding: $min-size $base-size !important;
}
.field {
  margin-bottom: $min-size;
}
</style>
