export default {
  setSchedulingPages: (state, schedulingPages) =>
    (state.schedulingPages = schedulingPages),

  addToSchedulingPages: (state, schedulingPage) =>
    state.schedulingPages.push(schedulingPage),

  setSchedulingPageUpdate(state, schedulingPage) {
    const index = state.schedulingPages.findIndex(
      (item) => item.id === schedulingPage.id,
    )
    if (index !== -1) {
      state.schedulingPages[index] = schedulingPage
    }
  },

  setSchedulingPageToRemove(state, schedulingPageId) {
    const index = state.schedulingPages.findIndex(
      (item) => item.id === schedulingPageId,
    )
    if (index !== -1) {
      state.schedulingPages.splice(index, 1)
    }
  },

  setSchedulingPageToView: (state, schedulingPageToView) =>
    (state.schedulingPageToView = schedulingPageToView),

  setSchedulingPageToEdit: (state, schedulingPageToEdit) =>
    (state.schedulingPageToEdit = schedulingPageToEdit),

  setBookingNotFoundError: (state, value) =>
    (state.bookingErrors.bookingNotFound = value),

  setBookingExpiredError: (state, value) =>
    (state.bookingErrors.bookingExpired = value),

  setBookingIsPrivateError: (state, value) =>
    (state.bookingErrors.bookingIsPrivate = value),

  setNoMoreBookingsAvailableError: (state, value) =>
    (state.bookingErrors.noMoreBookingsAvailable = value),

  setProfileHasNoCalendarIntegratedError: (state, value) =>
    (state.bookingErrors.profileHasNoCalendarIntegrated = value),
}
