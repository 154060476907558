const companyEventStatusConstants = {
  SCHEDULED: 1,
  DRAFT: 2,
  CANCELLED: 3,
}

const companyEventStatus = [
  { id: companyEventStatusConstants.SCHEDULED, name: 'Scheduled' },
  { id: companyEventStatusConstants.DRAFT, name: 'Draft' },
  { id: companyEventStatusConstants.CANCELLED, name: 'Cancelled' },
]

export { companyEventStatusConstants, companyEventStatus }
