/* eslint-disable import/extensions */
import isEmpty from 'lodash/isEmpty'
import { auth } from '@/firebase/initFirebase'
import store from '@/store'
// import { firebaseGetToken } from '@/firebase/authentication'

export default {
  isUserLoggedIn: (state) => !isEmpty(state.user),
  getUserFetchingStatus: (state) => state.fetchingUser,
  /* TODO This is currently hardcoded but needs to be calculated */
  isUserSuperAdmin: (state) => state.user.superAdmin,
  isUserCompanyAdmin: (state) => state.user.isUserCompanyAdmin,

  /*
  entity is things like user, events, teams, etc. each of those high level entity will have
  a company id as an attribute which you check against user's companyId. Once I have
  the company Id in Auth then I need to use that rather than companyId in user
  */
  isUserInSameCompanyAsEntity(state, entity, rootState) {
    if (state.entity && state.entity.companyId) {
      if (state.entity.companyId === rootState.user.user.companyId) return true
    }
    return false // TODO Returning true right now for all however this needs to be modified
  },
  isUserInSameCompany(state, company, rootState) {
    if (state?.company?.companyId) {
      if (state.company.companyId === rootState.user.user.companyId) return true
    }
    return false
  },
  getTinyUser() {
    return {
      id: auth.currentUser.uid,
      displayName: auth.currentUser.displayName,
    }
  },
  getAuthUserId() {
    try {
      return auth.currentUser.uid
    } catch (e) {
      return null
    }
  },
  getAuthUserEmailAddress() {
    return auth.currentUser.email || null
  },
  getAuthUserDisplayName() {
    return auth.currentUser.displayName || null
  },
  getAuthUserPhotoURL() {
    return auth.currentUser.photoURL || null
  },
  isThisMyProfile(routeUID) {
    try {
      return !!(routeUID || auth.currentUser.uid)
    } catch (error) {
      return false
    }
  },
  getCreatedBy(state) {
    return {
      id: state.user.id,
      displayName: state.user.displayName,
      title: state.user.title,
    }
  },
  getUpdatedBy(state) {
    return {
      id: state?.user?.id,
      displayName: state?.user?.displayName,
    }
  },

  // getAuthUserCompanyId() {
  //   // This will eventually come from custom claims

  //   return 'siyn.email'
  // },

  getAuthUserCompanyId(state) {
    return state?.user?.companyId === state?.authCompanyId &&
      state?.userCompany?.id === state?.authCompanyId
      ? state?.authCompanyId
      : null
  },

  getAuthUserLicenseType(state) {
    // firebase
    //   .auth()
    //   .currentUser.getIdTokenResult()
    //   .then((idTokenResult) => {
    //     if (idTokenResult.claims) {
    //       return idTokenResult.claims.licenseType
    //     }
    //     return null
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     return null
    //   })
    // This will eventually come from custom claims
    return state?.user?.licenseType === state?.authLicenseType
      ? state?.authLicenseType
      : null // trial==TRIAL, free=GUEST, paid=PAID, enterprise=ENT
  },

  getAuthUserRole(state) {
    return state?.user?.role === state?.authUserRole
      ? state?.authUserRole
      : 'REG' // regular=REG, company admin=COADMIN, system admin=SYSADMIN, team admin=TADMIN
  },
  getUserCompany(state) {
    return state?.userCompany || null
  },
  isPaidUser() {
    return store.getters['user/getAuthUserLicenseType'] === 'PAID'
  },
  isGuestUser() {
    return store.getters['user/getAuthUserLicenseType'] === 'GUEST'
  },
  isEnterpriseUser() {
    return store.getters['user/getAuthUserLicenseType'] === 'ENT'
  },
}
