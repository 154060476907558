<template>
  <section class="view-company-analytics">
    <skeleton v-if="isLoading"></skeleton>
    <SiynCollapse
      v-if="companyEventToView && !isLoading"
      :title="panelTitle"
      emoji="📊"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
      ><template #header-extras>
        <b-tooltip
          multilined
          class="mx-4"
          type="is-dark"
          label="Only visible to company admins and event creator"
          position="is-right"
          ><b-icon
            class="has-text-grey-lighter"
            pack="fas"
            icon="info-circle"
            size="is-small"
          >
          </b-icon
        ></b-tooltip>
      </template>
      <template #default>
        <div class="view-company-analytics__wrapper px-4 py-2">
          <div
            v-if="companyEventToView && companyEventToView.attendees"
            class="columns is-vcentered is-multiline"
          >
            <div class="column is-6">
              <a @click="gotoListAttendees(companyEventToView.attendees)"
                >Total Attendees: {{ companyEventToView.attendees.length }}</a
              >
            </div>
          </div>
        </div>
      </template>
    </SiynCollapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState } from 'vuex'
import { viewCompanyMixin } from '@/mixins/company/index'
import SiynCollapse from '@/components/SiynCollapse.vue'

export default {
  name: 'CompanyAnalytics',
  components: { SiynCollapse },

  mixins: [viewCompanyMixin],
  data() {
    return {
      isLoading: true,
      isModalActive: false,
      panelTitle: 'Event Analytics',
      panelname: 'event_analytics',
    }
  },
  computed: {
    ...mapState('company', ['companyEventToView']),
  },
  watch: {},
  created() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    gotoListAttendees(attendees) {
      this.$router.push({
        name: 'listallattendees',
        params: {
          companyid: this.$route.params.companyid,
          id: this.$route.params.id,
          attendees,
          companyEvent: this.companyEventToView,
        },
      })
    },
  },
}
</script>
