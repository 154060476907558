<template>
  <section class="company-event-network">
    <div width-class="is-12" class="view-company-event-network">
      <skeleton v-if="isLoading"></skeleton>
      <SynPanel v-if="companyEventToView && !isLoading" :title="panelTitle">
        <div class="view-company-event-network__wrapper px-4 py-2">
          <div
            v-if="companyNetworkAttendees && companyNetworkAttendees.length > 0"
            class="columns is-multiline is-gapless is-vcentered"
          >
            <template
              v-for="(item, index) in companyNetworkAttendees.slice(
                0,
                maxItems,
              )"
            >
              <template v-if="index <= maxItems">
                <small-card
                  :key="item.id"
                  class="column is-3 is-narrow"
                  :uid="item.id"
                  :display-name="item.friend.displayName"
                  :title="item.friend.title"
                />
              </template>
            </template>
            <template
              v-if="
                companyNetworkAttendees &&
                companyNetworkAttendees.length > maxItems
              "
            >
              <a class="my-4" @click="viewListModal()"
                >View All ({{ companyNetworkAttendees.length }})...</a
              >
            </template>
          </div>
          <div v-else>
            <p class="empty-content">None.</p>
          </div>
        </div>
      </SynPanel>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations, mapActions } from 'vuex'
import SynPanel from '@/components/SynPanel.vue'
import { viewCompanyMixin } from '@/mixins/company/index'
import { networkStatusConstants } from '@/data/networkStatus'
import SmallCard from '@/components/SmallCard'
import ListAllNetworkAttendees from './ListAllNetworkAttendees.vue'

export default {
  name: 'CompanyEventNetwork',
  components: {
    SynPanel,
    SmallCard,
  },
  mixins: [viewCompanyMixin],
  data() {
    return {
      panelTitle: 'People in network attending the event',
      isLoading: true,
      companyNetworkAttendees: [],
      maxItems: 2,
    }
  },
  computed: {
    ...mapState('company', ['companyNetwork']),
  },
  watch: {
    companyNetwork: {
      handler(companyNetwork) {
        if (companyNetwork) {
          this.companyNetworkAttendees = companyNetwork.filter((o1) => {
            return this.companyEventToView.attendees.some((o2) => {
              return o1.id === o2.id // return the ones with equal id
            })
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.fetchNetwork()
  },
  mounted() {
    this.isLoading = false
  },
  destroyed() {},
  methods: {
    ...mapMutations('company', ['setCompanyNetwork']),
    ...mapActions('networkStatus', ['fetchActiveNetworkByUserId']),
    async fetchNetwork() {
      const constraints = []
      constraints.push([
        'status',
        '==',
        networkStatusConstants.REQUEST_APPROVED,
      ])
      constraints.push(['friend.companyId', '==', this.$route.params.companyid])

      this.fetchActiveNetworkByUserId({
        userId: this.user?.id,
        constraints,
      })
        .then((result) => {
          this.setCompanyNetwork(result)
        })
        .catch((err) => {
          console.log(err)
          this.$store.commit('company/setCompanyNetwork', null, { root: true })
          this.handleErrorMessage(
            this.showUserMessageFromCode('auth/fetch-network-failed'),
          )
        })
    },
    viewListModal() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: ListAllNetworkAttendees,
        props: {
          companyNetworkAttendees: this.companyNetworkAttendees,
        },
        trapFocus: true,
      })
    },
  },
}
</script>
