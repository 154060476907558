import state from './links.state'
import mutations from './links.mutations'
import actions from './links.actions'
import getters from './links.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
