/** ************   WATCH OUT FOR POSSIBLE BREAKAGE BEFORE CHANGING THIS FILE ************************ */

/** THE visibilityConstants VARIABLE ALSO EXISTS ON FUNCTIONS,
 * IF YOU CHANGE HERE YOU HAVE TO CHANGE IT THERE
 * AS WELL ELSE IT WILL BREAK */
export const visibilityConstants = {
  VISIBLE_TO_NONE: 90,
  VISIBLE_TO_ALL: 91,
  // VISIBLE_TO_ORG_ONLY: 92,
  VISIBLE_TO_MY_NW: 93,
  PROFILE_USERS_CHOICE: null,
}

export const visibilityPreferences = [
  {
    id: visibilityConstants.VISIBLE_TO_NONE,
    name: '🛡️ Visible to colleagues only',
  },
  { id: visibilityConstants.VISIBLE_TO_ALL, name: '👁️ Visible to everyone' },
  {
    id: visibilityConstants.VISIBLE_TO_MY_NW,
    name: '🖧 Visible to network & colleagues only',
  },
]
export default function visibilityPreferencesHelper() {
  return true
}
