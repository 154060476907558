import {
  // eslint-disable-next-line
  connectFirestoreEmulator,
  serverTimestamp as FirebaseServerTS,
  writeBatch,
  doc,
  collection,
  addDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore'
import { db } from '@/firebase/initFirebase'

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}
const serverTimestamp = FirebaseServerTS()

export default function companyBatch() {
  return true
}

// export const addInitialCompanyAdminBatch = async (payload) => {
//   const { companyAdmin } = payload
//   const { companyId } = payload
//   const { user } = payload

//   const createdBy = {
//     id: user.id,
//     displayName: user.displayName,
//   }
//   const updateRecord = {
//     action: 'added',
//     user: createdBy,
//     message: 'added initial company admin',
//     module: 'company-admin',
//     type: 'company',
//     priority: 3,
//   }

//   const batch = writeBatch(db)

//   const companyAdminRef = doc(
//     db,
//     'company',
//     companyId,
//     'companyadmins',
//     companyAdmin.id,
//   )
//   const companyUpdatesRef = doc(collection(db, 'company', companyId, 'updates'))

//   batch.set(companyAdminRef, {
//     ...companyAdmin,
//     updateTimestamp: serverTimestamp,
//     createTimestamp: serverTimestamp,
//     createdBy,
//   })
//   batch.set(companyUpdatesRef, {
//     ...updateRecord,
//     createTimestamp: serverTimestamp,
//     updateTimestamp: serverTimestamp,
//     createdBy,
//   })

//   try {
//     await batch.commit()
//     return { companyAdmin, user }
//   } catch (error) {
//     console.log(error)
//     throw error
//   }
// }

// export const updateCompanyEventBatch = async (payload) => {
//   const { companyEvent } = payload
//   const { companyToEdit } = payload
//   const { user } = payload
//   const lastUpdatedBy = {
//     id: user.id,
//     displayName: user.displayName,
//   }

//   const batch = writeBatch(db)

//   const companyEventRef = doc(
//     db,
//     'company',
//     companyToEdit.id,
//     'events',
//     companyEvent.id,
//   )
//   const userEventsRef = doc(db, 'users', user.id, 'events', companyEvent.id)

//   batch.update(companyEventRef, {
//     ...companyEvent,
//     updateTimestamp: serverTimestamp,
//     lastUpdatedBy,
//   })
//   batch.update(userEventsRef, {
//     ...companyEvent,
//     updateTimestamp: serverTimestamp,
//     lastUpdatedBy,
//   })

//   try {
//     await batch.commit()
//     return { companyEvent, user }
//   } catch (error) {
//     console.log(error)
//     throw error
//   }
// }

// export const updateCompanyEventStatusBatch = async (payload) => {
//   let { companyEvent } = payload
//   const { companyToEdit } = payload
//   const { user } = payload
//   const lastUpdatedBy = {
//     id: user.id,
//     displayName: user.displayName,
//   }
//   companyEvent = {
//     ...this.companyEvent,
//     eventStatus: companyEventStatusConstants.SCHEDULED,
//   }

//   const batch = writeBatch(db)

//   const companyEventRef = doc(
//     db,
//     'company',
//     companyToEdit.id,
//     'events',
//     companyEvent.id,
//   )
//   const userEventsRef = doc(collection(db, 'users', user.id, 'events'))

//   batch.update(companyEventRef, {
//     ...companyEvent,
//     updateTimestamp: serverTimestamp,
//     lastUpdatedBy,
//   })
//   batch.set(userEventsRef, {
//     ...companyEvent,
//     updateTimestamp: serverTimestamp,
//     lastUpdatedBy,
//   })

//   try {
//     await batch.commit()
//     return { companyEvent, user }
//   } catch (error) {
//     console.log(error)
//     throw error
//   }
// }

// export const removeCompanyEventBatch = async (payload) => {
//   // console.log(JSON.stringify(payload))
//   const { companyEvent } = payload
//   const { companyToEdit } = payload
//   const { user } = payload

//   const batch = writeBatch(db)

//   const companyEventRef = doc(
//     db,
//     'company',
//     companyToEdit.id,
//     'events',
//     companyEvent.id,
//   )
//   const userEventsRef = doc(db, 'users', user.id, 'events', companyEvent.id)

//   batch.delete(companyEventRef)
//   batch.delete(userEventsRef)

//   try {
//     await batch.commit()
//     return { companyEvent, user }
//   } catch (error) {
//     console.log(error)
//     throw error
//   }
// }

export const addNewsletterAdminBatch = async (payload) => {
  const { newsletterAdmin } = payload
  const { company } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy
  const updateRecord = {
    action: 'added',
    message: newsletterAdmin.displayName,
    module: 'company-newsletter-admin',
    type: 'company',
    priority: 3,
    company: {
      id: company.id,
      name: company.companyName,
    },
  }

  const batch = writeBatch(db)

  const companyRef = doc(db, 'company', company.id)
  const companyUpdatesRef = doc(
    collection(db, 'company', company.id, 'updates'),
  )

  batch.update(companyRef, {
    newsletterAdmins: arrayUnion(newsletterAdmin),
    updateTimestamp: serverTimestamp,
    lastUpdatedBy,
  })
  batch.set(companyUpdatesRef, {
    ...updateRecord,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  return batch
    .commit()
    .then(() => {
      return { newsletterAdmin, user }
    })
    .catch((error) => {
      throw error
    })
}

export const removeNewsletterAdminBatch = async (payload) => {
  const { newsletterAdmin } = payload
  const { company } = payload
  const { user } = payload
  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy
  const updateRecord = {
    action: 'removed',
    message: newsletterAdmin.displayName,
    module: 'company-newsletter-admin',
    type: 'company',
    priority: 3,
    company: {
      id: company.id,
      name: company.companyName,
    },
  }

  const batch = writeBatch(db)

  const companyRef = doc(db, 'company', company.id)
  const companyUpdatesRef = doc(
    collection(db, 'company', company.id, 'updates'),
  )

  batch.update(companyRef, {
    newsletterAdmins: arrayRemove(newsletterAdmin),
    updateTimestamp: serverTimestamp,
    lastUpdatedBy,
  })
  batch.set(companyUpdatesRef, {
    ...updateRecord,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  try {
    await batch.commit()
    return { newsletterAdmin, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const addCompanyTokenBatch = async (payload) => {
  const { token } = payload
  const { user } = payload
  const { company } = payload

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const updateRecord = {
    action: 'added',
    message: `for ${token.createdFor.displayName}`,
    module: 'token',
    type: 'company',
    priority: 1,
    company: {
      id: company.id,
      name: company.companyName,
    },
  }

  // First add a token record get the token Id then use that to update other records and itself in batch
  const tokenDocRef = await addDoc(collection(db, 'tokens'), {
    createTimestamp: serverTimestamp,
  })

  const tokenId = tokenDocRef.id

  const batch = writeBatch(db)

  const tokenRef = doc(db, 'tokens', tokenId)
  const companyTokenRef = doc(db, 'company', user.companyId, 'tokens', tokenId)
  const companyUpdatesRef = doc(
    collection(db, 'company', user.companyId, 'updates'),
  )

  const tokenEndDate = new Date(token.startDateTime)
  tokenEndDate.setMonth(tokenEndDate.getMonth() + 3)

  batch.update(tokenRef, {
    ...token,
    endDateTime: tokenEndDate,
    licenseType: 'GUEST',
    companyTokenId: companyTokenRef.id,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })
  batch.set(companyTokenRef, {
    ...token,
    endDateTime: tokenEndDate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })
  batch.set(companyUpdatesRef, {
    ...updateRecord,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  await batch.commit().catch((error) => {
    throw error
  })
  const tokenResponse = {
    id: tokenRef.id,
    ...token,
    endDateTime: tokenEndDate,
    createTimestamp: new Date(),
    updateTimestamp: new Date(),
    createdBy,
  }
  return tokenResponse
}
