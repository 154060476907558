<!-- -->
<template>
  <section class="scheduler">
    <full-page
      max-width="is-max-widescreen"
      width-class="is-12"
      class="view-profile"
    >
      <b-loading v-model="isLoading" :is-full-page="true">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon>
      </b-loading>

      <div><b>UID: </b>{{ uid }}</div>
      <div><b>Access Token: </b>{{ accessToken }}</div>
      <b-button @click="openNylas">Open Scheduler</b-button>
      <b-button @click="editNylas()">Edit kavin-mehta-30min Page</b-button>
      <iframe
        id="inlineFrameExample"
        title="Inline Frame Example"
        width="100%"
        height="700px"
        src="https://schedule.nylas.com/1f8MmCOPUTnUJM7hAPVTgrCEh19SSCj8TJnHTzyxtOpf0P8Q"
      >
      </iframe>
    </full-page>
  </section>
</template>

<script>
/*  eslint-disable no-unused-vars */
/*  eslint-disable no-undef */
import { mapState, mapMutations } from 'vuex'

import FullPage from '@/components/partials/FullPage'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'

export default {
  name: 'Scheduler',
  components: { FullPage },
  props: {
    uid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      accessToken: 'qWRTLLLg4XmobDCmsSNjkOKsOfxMgk',
      // accessToken: 'hdSQoYfCWNHZlsa4ReySYiavJn7psv',
      // editToken: 'JdXDcaBOEqgZUVc1y_fCmTNZocoIdxH',
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('app', ['appTitle']),
  },
  destroyed() {},
  mounted() {
    this.isLoading = false
    const nylasSchedulerScript = document.createElement('script')
    nylasSchedulerScript.setAttribute(
      'src',
      'https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js',
    )
    document.head.appendChild(nylasSchedulerScript)
  },
  methods: {
    openNylas() {
      console.log(JSON.stringify('open'))
      // eslint-disable-next-line no-undef
      nylas?.scheduler?.show({
        // pageDomain: `https://siyn.email/<will-be-generated-later>/`,
        auth: {
          // Account <ACCESS_TOKEN> with active calendar scope
          accessToken: this.accessToken,
        },
        style: {
          // Style the Schedule Editor
          tintColor: '#32325d',
          backgroundColor: 'white',
        },
        // defaults: {
        //   event: {
        //     title: 'Testing',
        //     duration: 30,
        //   },
        // },
        // behavior: {
        //   // disableSlugChanges: true,
        //   // displayOnly: ['event-info', 'opening-hours', 'calendars'],
        //   // disableEditing: ['slug', 'available_days_in_future'],
        // },
      })
    },
    editNylas() {
      const editToken = 'aB8GC540GRQMjqGS_oQKAxnQOWbO5unJ'
      nylas.scheduler.show({
        auth: {
          pageEditToken: editToken,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.scheduler {
  &__card {
    padding: $base-size;
    text-align: center;
    margin: 0 auto;
    &--header {
      @include sm-title();
      box-shadow: none;
      border-bottom: 1px solid $grey-lighter;
      padding-bottom: $base-size;
    }
    &--content {
      text-align: center;
      &--help {
        margin-top: $base-size;
      }
    }
    &--footer {
      text-align: right;
      display: block;
      padding-top: $base-size;
      button {
        border-radius: $box-radius !important;
      }
    }
  }
}
</style>
