<template>
  <section class="talk-to-sales">
    <div class="container is-fullhd">
      <b-loading v-model="isLoading" :is-full-page="true">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon
      ></b-loading>
      <div class="my-4 columns is-vcentered is-centered is-variable is-2">
        <div class="column is-5">
          <div class="box p-5">
            <h1 class="title is-1 register__left--title">{{ appTitle }}</h1>
            <h2 class="subtitle register__left--description">{{ byline }}</h2>
            <p class="mb-2">Some content on why you need {{ appTitle }}</p>
          </div>
        </div>
        <div class="column is-5">
          <synform
            v-if="showForm"
            title="Talk to Sales"
            :operation="submitForm"
            button-text="Submit"
            :show-second-button="false"
            icon-left="paper-plane"
            icon-pack="fas"
          >
            <ValidationProvider
              name="displayName"
              rules="required|min:2|max:40"
            >
              <b-field
                slot-scope="{ errors, valid }"
                :label="showLabelsFromCode('form/personalInfo/fullname')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
                class="required"
              >
                <b-input
                  ref="displayName"
                  v-model.trim="form.displayName"
                  required
                  name="displayName"
                  :placeholder="
                    showLabelsFromCode('profile/personalInfo/fullname')
                  "
                  minlength="2"
                >
                </b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="email" rules="required|min:8|max:40">
              <b-field
                slot-scope="{ errors, valid }"
                :label="`Business ${showLabelsFromCode('form/generic/email')}`"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors[0] ? errors : 'No personal emails please'"
                class="required"
              >
                <b-input
                  v-model.trim="form.email"
                  type="email"
                  name="email"
                  placeholder="Your Email Address"
                >
                </b-input>
              </b-field>
            </ValidationProvider>
            <b-field grouped>
              <ValidationProvider
                v-slot="{ errors, valid }"
                name="title"
                rules="required|min:2|max:45"
              >
                <b-field
                  :label="showLabelsFromCode('profile/personalInfo/jobtitle')"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  class="required"
                >
                  <b-input
                    v-model.trim="form.title"
                    name="title"
                    placeholder="e.g. Project Manager"
                  />
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="phone" rules="min:2|max:40">
                <b-field
                  label="Phone"
                  :message="showLabelsFromCode('generic/numbers-dashes')"
                  expanded
                >
                  <b-input
                    v-model.trim="form.phone"
                    name="phone"
                    :validation-message="
                      showLabelsFromCode('form/generic/phoneValidationMessage')
                    "
                    pattern="^\d+(?:-\d+)*$"
                    placeholder="Office phone"
                    @keydown.native.space.prevent
                  />
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-message v-if="formError" type="is-danger" class="mt-3">
              {{ formError }}
            </b-message>
          </synform>
          <div v-if="showMessage" class="box">
            <b-message type="is-success" class="mt-6 mb-6">
              <h2 class="title is-4">Thank You!</h2>
              <p class="subtitle">We've received your message.</p>
              <p>
                Someone from our team will be in touch with you in next
                <b>48 hours.</b>
              </p>
            </b-message>
            <div class="has-text-centered my-6">
              <b-button tag="router-link" to="/index" type="is-info"
                >Back to Home Page</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState } from 'vuex'
import { Buffer } from 'buffer'
import { ValidationProvider } from 'vee-validate'
import synform from '@/components/SynForm.vue'
import { trackPageView, trackScreenView } from '@/misc/analytics'

import { showLabelsFromCode, showUserMessageFromCode } from '@/misc/helpers'
// import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'

// @ts-ignore
window.Buffer = Buffer
// eslint-disable-next-line import/no-extraneous-dependencies
const emailInspector = require('email-inspector')

export default {
  name: 'TalkToSales',
  components: { ValidationProvider, synform },
  data() {
    return {
      isLoading: true,
      form: {
        email: '',
        displayName: '',
        phone: '',
        title: '',
      },
      showForm: true,
      showMessage: '',
      formError: '',
      count: 200,
      defaults: {
        origin: { y: 0.7 },
      },
    }
  },
  metaInfo() {
    return {
      title: `Register for ${this.appTitle}`,
      afterNavigation(metaInfo) {
        trackPageView()
        trackScreenView(metaInfo.title)
      },
      meta: [
        {
          property: 'og:title',
          content: `Register for ${this.appTitle}`,
        },
        {
          name: 'description',
          content: `Register for ${this.appTitle}. Share office hours, remote work schedule, OOO alerts, social media, books, and stay in touch.`,
        },
        {
          property: 'og:description',
          content: `Register for ${this.appTitle}. Share office hours, remote work schedule, OOO alerts, social media, books, and stay in touch.`,
        },
      ],
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('app', ['appTitle', 'byline']),
  },

  created() {},
  mounted() {
    this.isLoading = false
    if (this.$refs.displayName) {
      this.$refs.displayName.focus()
    }
    const confettiScript = document.createElement('script')
    confettiScript.setAttribute(
      'src',
      'https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.2/dist/confetti.browser.min.js',
    )
    document.head.appendChild(confettiScript)
  },
  methods: {
    showLabelsFromCode,
    showUserMessageFromCode,
    fire(particleRatio, opts) {
      // eslint-disable-next-line no-undef
      confetti({
        ...this.defaults,
        ...opts,
        particleCount: Math.floor(this.count * particleRatio),
      })
    },
    submitForm() {
      if (!emailInspector.validate(this.form.email)) {
        this.formError = 'Sorry, personal email addresses are prohibited.'
      } else {
        // console.log(JSON.stringify('move forward'))
        this.formError = ''
        fetch('https://fabform.io/f/X396bSN', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.form),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success === 'true') {
              this.showForm = false
              this.showMessage = true
              this.fire(0.25, {
                spread: 26,
                startVelocity: 55,
              })
              this.fire(0.2, {
                spread: 60,
              })
              this.fire(0.35, {
                spread: 100,
                decay: 0.91,
                scalar: 0.8,
              })
              this.fire(0.1, {
                spread: 120,
                startVelocity: 25,
                decay: 0.92,
                scalar: 1.2,
              })
              this.fire(0.1, {
                spread: 120,
                startVelocity: 45,
              })
            } else {
              throw new Error('Server response indicates failure')
            }
          })
          .catch((error) => {
            console.error('Error:', error)
            this.formError = 'There was an error please try again!'
          })
      }
    },
  },
}
</script>
