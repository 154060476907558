import state from './teams.state'
import mutations from './teams.mutations'
import actions from './teams.actions'
import getters from './teams.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
