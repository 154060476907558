export default {
  setTeamLinks: (state, teamLinks) => (state.teamLinks = teamLinks),

  addToTeamLinks: (state, teamLink) => state.teamLinks.push(teamLink),
  setTeamLinkToEdit: (state, teamLink) => (state.teamLinkToEdit = teamLink),
  setTeamLinkUpdate(state, teamLink) {
    const index = state.teamLinks.findIndex((item) => item.id === teamLink.id)
    if (index !== -1) {
      Object.assign(state.teamLinks[index], teamLink)
      // console.log(JSON.stringify(state.events[index]))
    }
  },
  removeTeamLinkById: (state, teamLinkId) => {
    const index = state.teamLinks.findIndex(
      (teamLink) => teamLink.id === teamLinkId,
    )
    state.teamLinks.splice(index, 1)
  },
}
