<template>
  <section class="firestore-files">
    <div v-if="isLoading">
      <b-skeleton width="20%" animated></b-skeleton>
      <b-skeleton width="40%" animated></b-skeleton>
      <b-skeleton width="80%" animated></b-skeleton>
      <b-skeleton animated></b-skeleton>
    </div>
    <div v-else>
      <b-table
        v-if="files"
        ref="table"
        :data="files"
        hoverable
        mobile-cards
        narrowed
        custom-row-key="id"
        class="firestore-files__table"
        :show-header="files.length > 0 ? true : false"
        :paginated="files.length > 8 ? true : false"
        pagination-size="is-small"
        default-sort="metadata.updated"
        default-sort-direction="desc"
        per-page="8"
        :aria-next-label="showLabelsFromCode('generic/nextpage')"
        :aria-previous-label="showLabelsFromCode('generic/previouspage')"
        :aria-page-label="showLabelsFromCode('generic/page')"
        :aria-current-label="showLabelsFromCode('generic/currentpage')"
      >
        <b-table-column
          v-slot="props"
          field="metadata.contentType"
          label="Type"
          sortable
          cell-class="valign"
        >
          <template
            v-if="
              props.row.metadata &&
              props.row.metadata.contentType.split('/')[0] === 'image'
            "
          >
            <b-icon pack="far" icon="image" size="is-medium"> </b-icon>
          </template>
          <template v-else-if="props.row.metadata">
            <b-icon
              pack="far"
              :icon="populateIcon(props.row.metadata.contentType)"
              size="is-medium"
            >
            </b-icon>
          </template>
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="valign"
          field="metadata.name"
          label="Name"
          sortable
        >
          <span v-if="props.row.metadata && props.row.url">
            <a target="_blank" :href="props.row.url">{{
              props.row.metadata.customMetadata.filename ||
              props.row.metadata.name
            }}</a>
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="metadata.size"
          label="Size"
          sortable
          cell-class="valign"
        >
          <span v-if="props.row.metadata">{{
            Number((props.row.metadata.size / 1000).toFixed(0)) + ' KB'
          }}</span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="metadata.updated"
          label="Updated"
          sortable
          cell-class="valign"
        >
          <span v-if="props.row.metadata">
            {{ momentAgo(props.row.metadata.updated) }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="id"
          label="Action"
          centered
          cell-class="valign"
        >
          <template
            v-if="
              allowedToUpdateFile ||
              allowedToDeleteFile ||
              user.id === props.row.createdById
            "
          >
            <a
              v-if="allowedToUpdateFile || user.id === props.row.createdById"
              class="no-text-decoration mr-3"
              @click="updateFileName(props.row.metadata)"
            >
              <b-tooltip
                :label="showLabelsFromCode('generic/edit')"
                position="is-right"
                type="is-dark"
              >
                <b-icon
                  pack="far"
                  icon="edit"
                  type="is-primary"
                  size="is-medium"
                >
                </b-icon>
              </b-tooltip>
            </a>
            <a
              v-if="allowedToDeleteFile || user.id === props.row.createdById"
              class="no-text-decoration"
              @click="deleteFile(props.row.metadata)"
            >
              <b-tooltip
                :label="showLabelsFromCode('generic/delete')"
                position="is-left"
                type="is-dark"
              >
                <b-icon
                  icon="trash-alt"
                  pack="fa"
                  type="is-danger"
                  size="is-medium"
                >
                </b-icon>
              </b-tooltip>
            </a>
          </template>
          <template v-else>
            <p class="empty-content">N/A</p>
          </template>
        </b-table-column>
      </b-table>

      <b-progress
        v-if="processing"
        type="is-success"
        :rounded="false"
        show-value
      ></b-progress>
      <div v-if="allowedToAddFile" class="firestore-files__button-wrapper">
        <form enctype="multipart/form-data" novalidate>
          <label for="file-upload">
            <b-tag
              v-if="!processing"
              type="is-danger"
              class="firestore-files__edit-button background-color-override br-6"
              title="Add File"
              size="is-medium"
              >Add File
            </b-tag>
          </label>
          <input id="file-upload" type="file" @change="addFile" />
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import {
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
  updateMetadata,
  uploadBytes,
  connectStorageEmulator,
} from 'firebase/storage'
import { storage } from '@/firebase/initFirebase'

import { deleteFile } from '@/firebase/helpers/storage'
import { showLabelsFromCode, momentAgo } from '@/misc/helpers'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectStorageEmulator(storage, '127.0.0.1', 9199)
}

export default {
  name: 'FirestoreFiles',
  props: {
    folderPath: {
      type: String,
      default: '',
    },
    allowedToAddFile: {
      type: Boolean,
      default: false,
    },
    allowedToUpdateFile: {
      type: Boolean,
      default: false,
    },
    allowedToDeleteFile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      files: [],
      processing: false,
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },
  created() {
    this.fetchAllFiles()
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    fetchAllFiles() {
      const listRef = ref(storage, this.folderPath)

      listAll(listRef)
        .then((res) => {
          res.items.forEach((itemRef) => {
            const fileObject = {
              url: null,
              metadata: null,
              createdById: null,
            }
            // All the items under listRef getDownloadURL(ref(storage, 'images/stars.jpg'))

            getDownloadURL(ref(itemRef))
              .then((url) => {
                fileObject.url = url
              })
              .catch((error) => {
                throw error
              })

            getMetadata(itemRef)
              .then((metadata) => {
                fileObject.metadata = metadata
                fileObject.createdById =
                  metadata.customMetadata.createdById || null
              })
              .catch((error) => {
                throw error
              })
            this.files.push(fileObject)
          })
        })
        .catch((error) => {
          throw error
        })
    },

    updateMetaDataFileName: async (payload) => {
      const { metadata } = payload
      metadata.customMetadata.filename = payload.newName
      const fileRef = ref(storage, payload.metadata.fullPath)

      // Update metadata properties
      updateMetadata(fileRef, metadata)
        .then((metadataResponse) => {
          return metadataResponse
        })
        .catch((error) => {
          throw error
        })
    },

    deleteFileAction: async (payload) => {
      const fileRef = ref(storage, payload.metadata.fullPath)
      return deleteFile(fileRef)
    },

    updateFileName(metadata) {
      this.$buefy.dialog.prompt({
        message: 'Update file name',
        inputAttrs: {
          type: 'text',
          placeholder: 'Updated file name',
          value: metadata.customMetadata.filename || metadata.name,
          cancelText: 'Cancel',
          required: true,
        },
        confirmText: 'Update',
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: (value, { close }) => {
          this.updateMetaDataFileName({
            newName: value,
            metadata,
          })
            .then(() => {
              const index = this.files.findIndex(
                (f) => f.metadata.fullPath === metadata.fullPath,
              )
              this.files[index].metadata.customMetadata.filename = value
              this.files[index].metadata.name = value
              handleSuccessMessage('File Updated Successfully!')
              close()
            })
            .catch((err) => {
              console.log(err)
              handleErrorMessage()
              close()
            })
        },
      })
    },

    deleteFile(metadata) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this file?',
        confirmText: 'Delete',
        type: 'is-danger',
        trapFocus: true,
        ariaRole: 'alertdialog',
        ariaModal: true,
        closeOnConfirm: true,
        cancelText: 'Cancel',
        onConfirm: () => {
          this.deleteFileAction({
            metadata,
          })
            .then(() => {
              const index = this.files.findIndex(
                (f) => f.metadata.fullPath === metadata.fullPath,
              )

              if (index > -1) {
                // only splice array when item is found
                this.files.splice(index, 1) // 2nd parameter means remove one item only
                handleSuccessMessage('File Deleted Successfully!')
              }
            })
            .catch((err) => {
              console.log(err)
              handleErrorMessage()
            })
        },
      })
    },

    populateIcon(type) {
      let icon = ''
      switch (type) {
        case 'application/pdf':
          icon = 'file-pdf'
          break
        case 'text/plain':
          icon = 'file-lines'
          break
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          icon = 'file-word'
          break
        case 'application/msword':
          icon = 'file-word'
          break
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          icon = 'file-excel'
          break
        case 'application/ms-excel':
          icon = 'file-excel'
          break
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          icon = 'file-powerpoint'
          break
        case 'application/ms-powerpoint':
          icon = 'file-powerpoint'
          break
        default:
          icon = 'file'
          break
      }
      return icon
    },
    async addFile(e) {
      // console.log(e.target.files[0])
      this.processing = true
      const fileRef = ref(
        storage,
        `${this.folderPath}/${e.target.files[0].name}`,
      )
      const metadata = {
        customMetadata: {
          createdById: this.user.id,
        },
      }
      uploadBytes(fileRef, e.target.files[0], metadata)
        .then(() => {
          const fileObject = {
            url: null,
            metadata: null,
            createdById: this.user.id,
          }
          setTimeout(() => {
            getDownloadURL(fileRef).then((url) => (fileObject.url = url))
            getMetadata(fileRef).then(
              (metadataNew) => (fileObject.metadata = metadataNew),
            )
            this.files.push(fileObject)
          }, 1000)
          handleSuccessMessage('File Added Successfully!')
        })
        .catch((err) => {
          console.log(err)
          handleErrorMessage()
        })
        .finally(() => {
          this.processing = false
        })
    },
    showLabelsFromCode,
    momentAgo,
  },
}
</script>
<style scoped lang="scss">
#file-upload {
  display: none;
}
.firestore-files {
  &__table {
    td {
      word-break: break-word;
      max-width: 200px;
    }
  }
  &__button-wrapper {
    margin-top: $base-size;
    text-align: right;
  }
  &__edit-button {
    @include sm-button;
  }
}
</style>
