export default {
  // /* set boolean */

  setLinks: (state, links) => (state.links = links),
  setLinksEditPending: (state, value) => (state.linksEditPending = value),
  setLinksAddPending: (state, value) => (state.linksAddPending = value),
  setLinksConfig: (state, linksConfig) => (state.linksConfig = linksConfig),
  setLinkToEdit: (state, linkToEdit) => (state.linkToEdit = linkToEdit),

  /* remove link from state */
  removeLinkById: (state, linkId) => {
    const index = state.links.findIndex((links) => links.id === linkId)
    state.links.splice(index, 1)
  },
  setLinkUpdate(state, link) {
    const index = state.links.findIndex((item) => item.id === link.id)
    if (index !== -1) {
      Object.assign(state.links[index], link)
      // console.log(JSON.stringify(state.events[index]))
    }
  },
}
