<template>
  <div class="meeting-title">
    <div class="box mb-4">
      <div class="columns is-vcentered">
        <div class="column is-offset-3 is-6">
          <div
            class="has-text-centered title is-4 has-text-weight-normal"
            style="line-height: 1.5"
          >
            <router-link
              target="_blank"
              :to="{
                name: 'profile',
                params: { uid: bookingOrganizer.id },
              }"
            >
              <span
                v-if="bookingOrganizer?.photoURL"
                class="view-booking-page__imageURL"
              >
                <img
                  :src="bookingOrganizer.photoURL"
                  :alt="bookingOrganizer.displayName"
                  @error="setAltImg"
                />
              </span>
              <span class="view-booking-page__memberName"
                >{{ bookingOrganizer?.displayName }}'s
              </span>
            </router-link>
            <span>{{ showLabelsFromCode('scheduling/schedulingpage') }}</span>
          </div>
        </div>
        <div class="column is-3 has-text-right">
          <b-button
            class="info__edit-button ml-2"
            title="Share event"
            type="is-info"
            icon-left="share-from-square"
            pack="fas"
            @click="openShareModal()"
            >Share
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/extensions */

import { showLabelsFromCode } from '@/misc/helpers'
import ShareLink from '@/components/ShareLink'

export default {
  name: 'MeetingTitle',
  components: {},
  props: {
    bookingOrganizer: {
      type: Object,
      default: null,
    },
    bookingRecord: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },

  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    showLabelsFromCode,
    setAltImg(e) {
      e.target.onerror = null
      e.target.src = `${this.firebaseStorageBaseURL}/assets%2Fmissing-profile-image.png?alt=media`
    },
    openShareModal() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        props: {
          url: window.location.href,
          pageName: `${
            this.bookingOrganizer?.displayName
          }'s ${this.showLabelsFromCode('scheduling/schedulingpage')}`,
        },
        component: ShareLink,
        trapFocus: true,
      })
    },
  },
}
</script>
