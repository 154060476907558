/* spellchecker: disable */
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import isNil from 'lodash/isNil'
// import Home from '@/views/Home'
import Index from '@/views/Index'
import About from '@/views/About'
import CheckLogin from '@/views/user/CheckLogin'
import IntegrateCalendarTool from '@/views/IntegrateCalendar'
import Scheduler from '@/views/Scheduler'
import ContactUs from '@/views/ContactUs'
import TalkToSales from '@/views/TalkToSales'
import EmailSignatures from '@/views/EmailSignatures'
import ViewProfile from '@/views/profile/ViewProfile'
import EmailVerification from '@/views/EmailVerification'
import TermsOfService from '@/views/TermsOfService'
import ViewBookingPagePublic from '@/views/scheduling/ViewBookingPagePublic'
import ViewTeamBookingPagePublic from '@/views/team/view/ViewTeamBookingPagePublic'
import ViewTeamEvent from '@/views/team/event/ViewTeamEvent'
import ViewCompanyEvent from '@/views/company/events/ViewCompanyEvent'
import ViewCompany from '@/views/company/ViewCompany'

// import Ads from '@/views/Ads'
// eslint-disable-next-line import/extensions
import store from '@/store/index'

Vue.use(Router)
Vue.use(Meta, {
  refreshOnceOnNavigation: true,
})

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

const router = new Router({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/view/profile/:uid',
      name: 'profile',
      component: ViewProfile,
      props: true,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/verifyemail',
      name: 'verifyemail',
      component: EmailVerification,
      props: true,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/nylas/callback',
      name: 'nylascallback',
      props: true,
      meta: {
        authNotRequired: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/NylasCallback'
        ),
    },
    {
      path: '/integrate/calendar',
      name: 'integratecalendar',
      props: true,
      component: IntegrateCalendarTool,
    },
    {
      path: '/scheduler',
      name: 'scheduler',
      props: true,
      component: Scheduler,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      component: About,
      alias: '/about',
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/talktosales',
      name: 'talktosales',
      component: TalkToSales,
      alias: ['/sales', 'talk-to-sales'],
      meta: {
        authNotRequired: true,
      },
    },
    /*     {
      path: '/ads',
      name: 'ads',
      component: Ads,
      meta: {
        authNotRequired: true,
      },
    }, */
    {
      path: '/home',
      name: 'home',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/Home'
        ),
    },
    {
      path: '/invite',
      name: 'invite',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/SendInvitation'
        ),
    },
    {
      path: '/report',
      name: 'report',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/Report'
        ),
    },
    {
      path: '/release',
      name: 'release',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/Release'
        ),
    },
    {
      path: '/email-signature-templates',
      name: 'emailsignatures',
      component: EmailSignatures,
    },
    {
      path: '/reauthenticate',
      name: 'reauthenticate',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/user/ReAuthenticate'
        ),
    },
    {
      /* see router.beforeEach for use */
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/contactus/:type',
      name: 'contactus',
      component: ContactUs,
    },
    {
      path: '/termsofservice',
      name: 'termsofservice',
      component: TermsOfService,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/PrivacyPolicy'
        ),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/cookiepolicy',
      name: 'cookiepolicy',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/CookiePolicy'
        ),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/user/Login'
        ),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/user/Register'
        ),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/e/register',
      name: 'registerenterprise',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/user/RegisterEnterprise'
        ),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/g/register',
      name: 'registerguest',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/user/RegisterGuest'
        ),
      meta: {
        authNotRequired: true,
      },
    },
    // {
    //   path: '/delete-me',
    //   name: 'delete-user',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "client-chunk-login" */ '@/views/user/DeleteMe'
    //     ),
    // },
    {
      path: '/deactivate-my-account',
      name: 'disable-user',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/user/DisableAccount'
        ),
    },
    {
      path: '/force-create-user',
      name: 'force-create-user',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/user/ForceCreateUser'
        ),
    },
    {
      path: '/password-recovery',
      name: 'password-recovery',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/user/PasswordRecovery'
        ),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/emailverification',
      name: 'emailverification',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/EmailVerification'
        ),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/dataproviders',
      name: 'dataproviders',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/DataProviders'
        ),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/__/auth/action',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/user/EmailAuthHandler'
        ),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/edit/profile/',
      name: 'editprofile',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/profile/EditProfile'
        ),
    },

    {
      path: '/profile/:uid/edit/office-hours',
      name: 'editofficehoursform',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/office-hours/EditOfficeHoursForm'
        ),
    },
    {
      path: '/org/search',
      name: 'organizationsearch',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/search/OrganizationSearch'
        ),
    },
    {
      path: '/profile/:uid/add/events/bulk',
      name: 'addeventsbulk',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/events/AddEventsBulk'
        ),
    },
    {
      path: '/profile/:uid/add/event',
      name: 'addevent',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/events/AddEvent'
        ),
    },
    {
      path: '/profile/:uid/edit/event/:id',
      name: 'editevent',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/events/EditEvent'
        ),
    },
    {
      path: '/profile/:uid/edit/events',
      name: 'editevents',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/events/ListEventsForEdit'
        ),
    },
    {
      path: '/profile/:uid/view/event/:id',
      name: 'viewevent',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/events/ViewEvent'
        ),
    },
    {
      path: '/profile/:uid/view/events',
      name: 'viewevents',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/events/ListEventsForView'
        ),
    },
    {
      path: '/profile/:uid/add/links',
      name: 'addlinks',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/links/AddLinks'
        ),
    },
    {
      path: '/profile/:uid/edit/links',
      name: 'editlinks',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/links/ListLinksForEdit'
        ),
    },

    // {
    //   path: '/profile/:uid/add/books',
    //   name: 'addbooks',
    //   props: true,
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "client-chunk-product-details" */ '@/views/leisures/AddBook'
    //     ),
    // },
    // {
    //   path: '/profile/:uid/edit/books/:recordid',
    //   name: 'editbooks',
    //   props: true,
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "client-chunk-product-details" */ '@/views/leisures/EditBook'
    //     ),
    // },
    {
      path: '/profile/:uid/:mode/list/books',
      name: 'listbooks',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/leisures/ListBooksForViewEdit'
        ),
    },
    {
      path: '/profile/:uid/:mode/list/shows',
      name: 'listshows',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/leisures/ListShowsForViewEdit'
        ),
    },
    {
      path: '/profile/:uid/:mode/list/podcasts',
      name: 'listpodcasts',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/leisures/ListPodcastsForViewEdit'
        ),
    },
    {
      path: '/profile/:uid/:mode/list/sportsteams',
      name: 'listsportsteams',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/leisures/ListSportsTeamsForViewEdit'
        ),
    },
    {
      path: '/profile/:uid/:mode/list/likes',
      name: 'listlikes',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/leisures/ListLikesForViewEdit'
        ),
    },
    // {
    //   path: '/profile/:uid/add/comment',
    //   name: 'addcomments',
    //   props: true,
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "client-chunk-product-details" */ '@/views/comment/AddComments'
    //     ),
    // },
    {
      path: '/profile/:uid/edit/comments/:recordid',
      name: 'editcomments',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/comment/EditComments'
        ),
    },
    {
      path: '/view/company/:companyid',
      name: 'viewcompany',
      props: true,
      meta: {
        authNotRequired: true,
      },
      component: ViewCompany,
    },
    {
      path: '/add/company/NWAnWToSVlSd',
      name: 'addcompany',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/AddCompany'
        ),
    },
    {
      path: '/edit/company/:recordid',
      name: 'editcompany',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/EditCompany'
        ),
    },

    {
      path: '/add/company-admin/:recordid',
      name: 'addcompanyadmin',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/admins/AddCompanyAdmin'
        ),
    },
    {
      path: '/view/company/:companyid/event/:id',
      name: 'viewcompanyevent',
      props: true,
      meta: {
        authNotRequired: true,
      },
      component: ViewCompanyEvent,
    },

    {
      path: '/add/company-event/:recordid',
      name: 'addcompanyevent',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/events/AddCompanyEvent'
        ),
    },
    {
      path: '/view/company/:companyid/event/:id/addAttendee/email/:email/name/:name/phone/:phone',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/events/AddAttendee'
        ),
    },
    {
      path: '/edit/company/:companyid/event/:id',
      name: 'editcompanyevent',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/events/EditCompanyEvent'
        ),
    },
    {
      path: '/list/company/:recordid/all/events',
      name: 'listallcompanyevents',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/events/ListAllCompanyEvents'
        ),
    },
    {
      path: '/view/company/:companyid/list/event/:id/attendees',
      name: 'listallattendees',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/events/ListAllAttendees'
        ),
    },
    {
      path: '/list/company/:recordid/unpublished/events',
      name: 'listunpublishedevents',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/events/ListUnpublishedEvents'
        ),
    },
    {
      path: '/add/company-newsletter/admin/:recordid',
      name: 'addcompanynewsletteradmin',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/newsletters/AddCompanyNewsletterAdmin'
        ),
    },
    {
      path: '/add/company-newsletter/:recordid',
      name: 'addcompanynewsletter',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/newsletters/AddCompanyNewsletter'
        ),
    },
    {
      path: '/edit/company/:companyid/newsletter/:id',
      name: 'editcompanynewsletter',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/newsletters/EditCompanyNewsletter'
        ),
    },
    {
      path: '/view/company/:companyid/newsletter/:id',
      name: 'viewcompanynewsletter',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/newsletters/ViewCompanyNewsletter'
        ),
    },
    {
      path: '/list/company/:companyid/all/newsletters',
      name: 'listallcompanynewsletters',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/newsletters/ListAllCompanyNewsletters'
        ),
    },

    {
      path: '/edit/company-config/:recordid',
      name: 'editcompanypanelsconfig',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/config/EditCompanyConfigs'
        ),
    },
    // {
    //   path: '/edit/company-locations/:companyid',
    //   name: 'editcompanylocations',
    //   props: true,
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "client-chunk-product-details" */ '@/views/company/EditCompanyLocations'
    //     ),
    // },
    {
      path: '/profile/redirects/list',
      name: 'profileredirects',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/redirects/ListRedirects'
        ),
    },
    {
      path: '/mynetwork',
      name: 'my-network',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/network/MyNetwork'
        ),
    },
    {
      path: '/profile/redirectinterstitial',
      name: 'redirectinterstitial',
      props: true,
      meta: {
        authNotRequired: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/redirects/RedirectInterstitial'
        ),
    },
    {
      path: '/mentoring/topics/list',
      name: 'listmentoringtopics',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/mentoring/ListMentoringTopics'
        ),
    },
    {
      path: '/view/profile/:uid/company/:companyid/list/mentoring-sessions',
      name: 'mentoringsessionslist',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/mentoring/session/ListMentoringSessions'
        ),
    },
    {
      path: '/view/my/mentoring-sessions',
      name: 'mymentoringsessionlist',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/mentoring/session/MyMentoringSessions'
        ),
    },
    {
      path: '/mentoring-session/request/:mentorid/:menteeid/:topicid',
      name: 'mentoringsessionrequest',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/mentoring/session/MentoringSessionRequest'
        ),
    },
    {
      path: '/mentoring-session/create',
      name: 'AddMentoringSession',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/mentoring/session/AddMentoringSession'
        ),
    },
    {
      path: '/view/company/:companyid/mentoring-session/:id',
      name: 'viewmentoringsession',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/mentoring/session/ViewMentoringSession'
        ),
    },
    {
      path: '/company/:companyid/edit/mentoring-session/:id',
      name: 'editmentoringsession',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/mentoring/session/EditMentoringSession'
        ),
    },
    {
      path: '/my-teams',
      name: 'myteams',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/view/MyTeams'
        ),
    },
    {
      path: '/view/team/:id',
      name: 'viewteam',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/ViewTeam'
        ),
    },

    {
      path: '/create/team',
      name: 'createteam',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/AddTeam'
        ),
    },

    {
      path: '/add/team/:teamid/o/event',
      name: 'addonetimeteamevent',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/event/AddOnetimeTeamEvent'
        ),
    },
    {
      path: '/add/team/:teamid/r/event',
      name: 'addrecurringteamevent',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/event/AddRecurringTeamEvent'
        ),
    },
    {
      path: '/view/team/:teamid/summary/:id',
      name: 'viewteamsummary',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/view/ViewTeamSummary'
        ),
    },
    {
      path: '/view/team/:teamid/event/:id',
      name: 'viewteamevent',
      props: true,
      meta: {
        authNotRequired: true,
      },
      component: ViewTeamEvent,
    },
    {
      path: '/team/:teamid/edit/teamevent/o/:id',
      name: 'editonetimeteamevent',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/event/EditOneTimeTeamEvent'
        ),
    },
    {
      path: '/team/:teamid/edit/teamevent/r/:id',
      name: 'editrecurringteamevent',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/event/EditRecurringTeamEvent'
        ),
    },

    {
      path: '/team/:teamId/view/teamevent/:teamEventId/add/notes',
      name: 'addrecurringmeetingnotes',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/event/meetingnotes/recurring/AddMeetingNotes'
        ),
    },
    {
      path: '/team/:teamId/view/teamevent/:teamEventId/add/notes',
      name: 'addonetimemeetingnotes',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/event/meetingnotes/onetime/AddMeetingNotes'
        ),
    },

    {
      path: '/team/:teamId/view/teamevent/:teamEventId/notes/:id',
      name: 'viewnotessidebyside',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/event/meetingnotes/recurring/ViewMeetingNotesSideBySide'
        ),
    },
    {
      path: '/team/:teamId/view/teamevent/:teamEventId/notes',
      name: 'viewonetimeeventnotessidebyside',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/event/meetingnotes/onetime/ViewMeetingNotesSideBySide'
        ),
    },
    {
      path: '/team/:teamid/add/booking-page',
      name: 'addteambookingpage',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/edit/AddTeamBookingPage'
        ),
    },
    {
      path: '/team/:teamid/edit/booking-page/:id',
      name: 'editteambookingpage',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/edit/EditTeamBookingPage'
        ),
    },
    {
      path: '/view/team/:teamid/private/booking/:id',
      name: 'viewteambookingpage',
      props: true,

      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/view/ViewTeamBookingPage'
        ),
    },

    {
      path: '/team/:teamId/list/updates',
      name: 'listteamupdates',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/team/view/ListAllTeamUpdates'
        ),
    },

    {
      path: '/my-scheduling-pages',
      name: 'schedulingpageslist',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/scheduling/MySchedulerPages'
        ),
    },
    {
      path: '/create/scheduling-page',
      name: 'createschedulingpage',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/scheduling/AddSchedulingPage'
        ),
    },
    {
      path: '/view/profile/:uid/meeting/:id',
      name: 'viewschedulingpage',
      props: true,

      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/scheduling/ViewBookingPage'
        ),
    },
    {
      path: '/index',
      name: 'index',
      component: Index,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/view/public/:uid/meeting/:id',
      name: 'viewschedulingpagepublic',
      props: true,
      meta: {
        authNotRequired: true,
      },
      component: ViewBookingPagePublic,
    },
    {
      path: '/view/team/:teamid/public/booking/:id',
      name: 'viewteambookingpagepublic',
      props: true,
      meta: {
        authNotRequired: true,
      },
      component: ViewTeamBookingPagePublic,
    },

    //    { path: '*', redirect: '/index' },

    {
      // path: "*",
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: Index,
      meta: {
        authNotRequired: true,
      },
    },
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  },
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (!(to.meta && to.meta.authNotRequired) && isNil(store.state.user.user)) {
    const path = store.state.user.user === null ? '/login' : '/check-login'
    console.log(JSON.stringify(`Path is ${path}`))
    return next(`${path}?redirectUrl=${to.path}`)
  }

  next()
})

// https://routerjs.org/guide/advanced/navigation-guards.html#global-before-guards
/* router.beforeEach((to, from, next) => {
  const currentUser = store.state.user.user
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !currentUser) {
    const loginpath = window.location.pathname
    next({ name: 'login', query: { from: loginpath } })
  } else next()
}) */

// eslint-disable-next-line no-console
// console.log('Hello i am in router/index.js')
export default router
