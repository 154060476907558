export default {
  setNetworkOnline: (state, value) => (state.networkOnLine = value),
  setAppHasUpdates: (state, value) => (state.appHasUpdates = value),
  setWrapperBackgroundColor: (state, value) =>
    (state.wrapperBackgroundColor = value),
  setSWRegistrationForNewContent: (state, value) =>
    (state.SWRegistrationForNewContent = value),
  setShowAddToHomeScreenModalForApple: (state, value) =>
    (state.showAddToHomeScreenModalForApple = value),
  setRefreshingApp: (state, value) => (state.refreshingApp = value),
  setCriticalAppError: (state, value) => (state.criticalAppError = value),
  setProfileNotFoundError: (state, value) =>
    (state.hardError.profileNotFound = value),
  setCompanyNotFound: (state, value) =>
    (state.hardError.companyNotFound = value),
  setAppConfigNotFoundForUser: (state, value) =>
    (state.warnError.appConfigNotFoundForUser = value),
  setAppConfigNotFoundForCompany: (state, value) =>
    (state.warnError.appConfigNotFoundForCompany = value),
  setProfileUserDisabled: (state, value) =>
    (state.hardError.userDisabled = value),
  resetAppErrors: (state) => {
    state.criticalAppError = null
    state.hardError.profileNotFound = null
    state.hardError.companyNotFound = null
    state.hardError.userDisabled = null
    state.warnError.appConfigNotFoundForUser = null
    state.warnError.appConfigNotFoundForCompany = null
  },
  setOpenAIPrompts: (state, value) =>
    (state.appMasterConfigs.openAIPrompts = value),
}
