/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import {
  writeBatch,
  serverTimestamp as FirebaseServerTS,
  collection,
  doc,
} from 'firebase/firestore'
import { db } from '@/firebase/initFirebase'

import UserLinksDB from '@/firebase/user-links-db'

export default {
  /** ************* NEW CODE ************ */
  fetchLinksByUserId: async ({ commit }, userId) => {
    const userLinksDB = new UserLinksDB(userId)
    const links = await userLinksDB.readAll()
    // links = links.filter((option) => option.bulkAdded === true)
    if (links) {
      return Object.keys(links).map((o) => links[o])
    }
    return null
  },

  addLinksBulk: async ({ commit, rootState }, linksArray) => {
    const batch = writeBatch(db)
    const serverTimestamp = FirebaseServerTS()

    linksArray.forEach((link) => {
      const userLinksRef = doc(
        collection(db, 'users', rootState.user.user.id, 'links'),
      )
      batch.set(userLinksRef, {
        ...link,
        createTimestamp: serverTimestamp,
        updateTimestamp: serverTimestamp,
      })
    })
    batch
      .commit()
      .then(() => {
        return true
      })
      .catch((error) => {
        console.log(error)
        throw error.code
      })
  },

  updateLink: async ({ rootState, commit }, payload) => {
    const userLinksDB = new UserLinksDB(rootState.user.user.id)
    const response = await userLinksDB
      .createOrUpdate(payload)
      .then((result) => {
        return result
      })
      .catch((err) => {
        throw err
      })
    return response
  },
  deleteLink: async ({ rootState, commit }, linkId) => {
    const userLinksDB = new UserLinksDB(rootState.user.user.id)
    const response = userLinksDB
      .delete(linkId)
      .then(() => {
        return linkId
      })
      .catch((error) => {
        console.log(error)
        throw error.code
      })
    // console.log(JSON.stringify(response))
    return response
  },
}
/* getLinksById: async ({ commit }, userId) => {
    const userLinksDB = new UserLinksDB(userId)
    const links = await userLinksDB.readAll()
    commit('setLinks', links[0])
  },

  updateLinks: async ({ rootState, commit }, links) => {
    commit('setLinksEditPending', true)
    const userLinksDB = new UserLinksDB(rootState.user.user.id)
    await userLinksDB.update(links)
    commit('setLinks', links)
    commit('setLinksEditPending', false)
  },
  /**
   * Create for current loggedin user
   */
/*   addLinks: async ({ commit, rootState }, links) => {
    const userLinksDB = new UserLinksDB(rootState.user.user.id)
    commit('setLinksAddPending', true)
    await userLinksDB.create(links)
    commit('setLinks', links)
    commit('setLinksAddPending', true)
  }, */
/*   fetchLinksConfigByUserId: async ({ commit, dispatch }, userId) => {
    const userLinksDB = new UserLinksDB(userId)
    try {
      const linksConfig = await userLinksDB.read('config')
      if (linksConfig) {
        return linksConfig
      }
      const newLinksConfig = dispatch('createUpdateLinksConfig', {
        visibility: 92,
      })
      return newLinksConfig
    } catch (error) {
      return null
    }
  },

  createUpdateLinksConfig: async ({ rootState, commit }, payload) => {
    const userLinksDB = new UserLinksDB(rootState.user.user.id)
    try {
      const response = await userLinksDB.create(payload, 'config')
      return response
    } catch (error) {
      return null
    }
  }, */
