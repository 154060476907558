const teamMemberStatusConstants = {
  REQUEST_SEND: 1,
  REQUEST_APPROVED: 2,
  REQUEST_REJECTED: 3,
  BLOCKED: 4,
  LEFT_TEAM: 5,
  ACCESS_REQUESTED: 6,
  SUBSCRIBED: 7,
}

const teamMemberStatus = [
  { id: teamMemberStatusConstants.REQUEST_SEND, name: 'Request Sent' },
  { id: teamMemberStatusConstants.REQUEST_APPROVED, name: 'Joined' },
  { id: teamMemberStatusConstants.REQUEST_REJECTED, name: 'Rejected' },
  { id: teamMemberStatusConstants.BLOCKED, name: 'Blocked' },
  { id: teamMemberStatusConstants.LEFT_TEAM, name: 'Left' },
  { id: teamMemberStatusConstants.ACCESS_REQUESTED, name: 'Access Requested' },
  { id: teamMemberStatusConstants.SUBSCRIBED, name: 'Subscribed' },
]

export { teamMemberStatusConstants, teamMemberStatus }
