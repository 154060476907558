<template>
  <div class="share-link">
    <div class="container is-max-desktop">
      <div class="container box is-max-desktop py-5 mb-0">
        <div class="sm-title has-text-centered">
          <b-icon class="mr-4" icon="share-from-square" pack="fas" />Share Link
        </div>
      </div>
    </div>
    <Synform
      title="Email Link"
      :operation="sendInvite"
      icon-left="envelope"
      icon-pack="fas"
      button-text="Send Link"
      :modal-form="true"
      :state-flag="!showForm"
    >
      <span v-if="pageName" class="label is-inline mr-2">Page:</span>
      <a v-if="pageName" target="_blank" :href="url" class="mb-1">{{
        pageName
      }}</a>
      <template v-if="showForm">
        <ValidationProvider
          v-slot="{ errors, valid }"
          name="email"
          rules="required"
        >
          <b-field
            label="Send link to: "
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            message="Valid email address required. You can add multiple email addresses seperated by a comma"
            class="required"
          >
            <b-taginput
              id="emailAddress"
              v-model="form.emailTo"
              icon-pack="fas"
              icon="envelope"
              placeholder="Add Email to Send Invite"
              aria-close-label="Delete this email"
              :before-adding="beforeAdding"
            >
            </b-taginput>
          </b-field>
        </ValidationProvider>
      </template>
      <b-message v-else class="my-3" type="is-success">
        Email send successfully!
      </b-message>
    </Synform>
    <div class="container box is-max-desktop p-5">
      <div class="columns is-multiline is-vcentered is-centered">
        <div class="column is-4" style="border: 1px solid black; padding: 8px">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64">
                <img :src="`${qrCodeAPIBaseURL}${url}&amp;size=300x300`" />
              </p>
            </figure>
            <div class="media-content">
              <div class="content">
                <strong>QR Code</strong>
                <p class="is-size-9">Right click on image to save</p>
              </div>
            </div>
          </article>
        </div>
        <div class="column is-4 has-text-centered has-text-right-desktop">
          <b-button
            type="is-dark"
            icon-pack="far"
            icon-left="clipboard"
            @click="copyToClipboard"
          >
            Copy link
          </b-button>
        </div>
        <div class="column is-4 has-text-centered has-text-right-desktop">
          <b-button
            v-if="webShareApiSupported"
            title="Share"
            type="is-info"
            icon-pack="fas"
            icon-left="paper-plane"
            @click="shareViaWebShare()"
            >Share
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapState, mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import SynPanel from '@/components/SynPanel'
import Synform from '@/components/SynForm'
import FullPage from '@/components/partials/FullPage'
import { handleSuccessMessage, sendEmail } from '@/misc/user-messages'

export default {
  name: 'ShareLink',
  // eslint-disable-next-line vue/no-unused-components
  components: { SynPanel, Synform, ValidationProvider, FullPage },
  props: {
    url: {
      type: String,
      default: '',
    },
    pageName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showForm: true,
      qrCodeAPIBaseURL: process.env.VUE_APP_QRCODE_API_BASE_URL,
      form: {
        emailTo: null,
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters('user', ['isUserLoggedIn']),
    ...mapState('user', ['user']),
    ...mapState('app', ['appTitle']),
    webShareApiSupported() {
      return navigator.share
    },
  },
  methods: {
    sendInvite() {
      sendEmail(
        this.form.emailTo,
        this.form.emailTo,
        this.user.displayName,
        'bot@siyn.email',
        `${this.user.displayName} has send you an invite to view their profile on ${this.appTitle}. Please visit <a href="${process.env.VUE_APP_URL}view/profile/${this.user.id}">${this.user.displayName}'s profile</a>.`,
      ).catch((error) => {
        this.handleErrorMessage(`Error in sending email`)
        this.trackExceptionEvent(
          'add-sharelink_email_sent_failed',
          error.text,
          false,
        )
      })
      this.showForm = false
    },
    close() {
      this.$emit('close')
    },
    beforeAdding(tag) {
      let valid = true
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (tag === '' || !regex.test(tag)) {
        valid = false
      }
      return valid
    },
    shareViaWebShare() {
      navigator
        .share({
          title: `Sharing page from ${this.appTitle}`,
          text: `I would like to share this page with you`,
          url: this.url,
        })
        .catch(() => {})
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.url)
      handleSuccessMessage('Link copied to clipboard')
    },
  },
}
</script>
