/* eslint-disable no-unused-vars */

import TeamSummariesDB from '@/firebase/teams/team-summaries'

import { addTeamSummaryBatch } from '@/store/teams-related/teams/teamsBatch'

export default {
  fetchTeamSummariesByTeamId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const teamSummariesDB = new TeamSummariesDB(payload.teamId)

    return teamSummariesDB.readAllWithOrderByLimit(
      payload.constraints,
      payload.orderBys,
      undefined,
    )
  },

  fetchTeamSummariesById: async ({ commit }, payload) => {
    const teamSummariesDB = new TeamSummariesDB(payload.teamId)
    return teamSummariesDB.read(payload.id)
  },

  createTeamSummary: async ({ commit }, payload) => {
    return addTeamSummaryBatch(payload)
  },

  updateTeamSummary: async ({ commit }, payload) => {
    const teamSummariesDB = new TeamSummariesDB(payload.teamId)
    return teamSummariesDB.update(payload.teamSummary)
  },

  deleteTeamSummary: async ({ commit }, payload) => {
    const teamSummariesDB = new TeamSummariesDB(payload.teamId)
    return teamSummariesDB.delete(payload.teamSummaryId)
  },
}
