/* eslint-disable import/extensions */
// import networkStatusConstants from '@/misc/network-status'
// import isEmpty from 'lodash/isEmpty'
import isEmpty from 'lodash/isEmpty'
import { networkStatusConstants } from '@/data/networkStatus'

export default {
  /**
   * Get  by id
   */
  areWeConnected(state) {
    if (
      state.networkStatus &&
      state.networkStatus.status === networkStatusConstants.REQUEST_APPROVED
    ) {
      return true
    }
    return false
  },

  getOurNetworkRecord: (state) => (friendId) => {
    if (state.network) {
      const networkRecord = state.network.find(
        (record) => record.id === friendId,
      )
      if (networkRecord) {
        return networkRecord
      }
    }
    return null
  },

  getAllActiveNetwork: (state) => {
    if (state.network) {
      return state.network.filter(
        (record) => record.status === networkStatusConstants.REQUEST_APPROVED,
      )
    }
    return null
  },

  getAllPendingRequests: (state) => {
    return state.network.filter(
      (record) => record.status === networkStatusConstants.REQUEST_SEND,
    )
  },

  getAllBlockedUsers: (state) => (blockedById) => {
    if (isEmpty(state.network)) {
      return null
    }
    const blockedUsers = []
    for (let i = 0; i < state.network.length; i += 1) {
      if (
        state.network[i].blockedById === blockedById &&
        state.network[i].status === networkStatusConstants.BLOCKED
      ) {
        blockedUsers.push(state.network[i])
      }
    }
    return blockedUsers
  },
}
