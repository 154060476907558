<template>
  <div class="meeting-embed">
    <iframe
      id="inlineFrameExample"
      :title="pageName"
      width="100%"
      height="700px"
      :src="`${nylasSchedulerBaseURL}/${pageSlug}`"
    >
    </iframe>
  </div>
</template>

<script>
/* eslint-disable import/extensions */

import { showLabelsFromCode } from '@/misc/helpers'

export default {
  name: 'MeetingEmbed',
  components: {},
  props: {
    pageName: {
      type: String,
      default: null,
    },
    pageSlug: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      nylasSchedulerBaseURL: process.env.VUE_APP_NYLAS_SCHEDULER_BASE_URL,
    }
  },

  computed: {},
  watch: {},
  created() {},
  mounted() {},

  methods: {
    showLabelsFromCode,
  },
}
</script>

<style scoped lang="scss"></style>
