<template>
  <section class="user-teams">
    <skeleton v-if="isLoading"></skeleton>
    <siyn-collapse
      v-if="activeUserTeams && activeUserTeams.length > 0 && !isLoading"
      :title="
        userCompany?.teamString
          ? `${userCompany?.teamString}s`
          : `${showLabelsFromCode('generic/teams')}`
      "
      emoji="🙌🏽"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <template #header-extras>
        <b-tooltip
          multilined
          class="mx-4"
          type="is-dark"
          label="Only visible to employees of the company. Only shows teams where person is a team member"
          position="is-right"
          ><b-icon
            class="has-text-grey-lighter"
            pack="fas"
            icon="info-circle"
            size="is-small"
          >
          </b-icon
        ></b-tooltip>
      </template>
      <template #default>
        <div>
          <b-table
            v-if="activeUserTeams"
            ref="table"
            :data="getApprovedSendTeam"
            hoverable
            mobile-cards
            narrowed
            custom-row-key="id"
            default-sort="memberStatus"
            :show-header="getApprovedSendTeam.length > 0 ? true : false"
            :paginated="getApprovedSendTeam.length > 10 ? true : false"
            per-page="10"
            :aria-next-label="showLabelsFromCode('generic/nextpage')"
            :aria-previous-label="showLabelsFromCode('generic/previouspage')"
            :aria-page-label="showLabelsFromCode('generic/page')"
            :aria-current-label="showLabelsFromCode('generic/currentpage')"
            class="user-teams__table"
          >
            <template #empty>
              <div>{{ showLabelsFromCode('generic/none') }}</div>
            </template>
            <b-table-column
              v-slot="props"
              scope="props"
              field="teamName"
              :label="showLabelsFromCode('generic/name')"
              cell-class="valign"
              sortable
            >
              <router-link
                class="no-text-decoration"
                :to="{ name: 'viewteam', params: { id: props.row.id } }"
              >
                <span class="user-teams__table--teamName">{{
                  props.row.teamName
                }}</span></router-link
              >
            </b-table-column>
            <b-table-column
              v-slot="props"
              scope="props"
              field="teamType"
              label="Type"
              cell-class="valign"
              sortable
              >{{ getNameForId(props.row.teamType, teamTypesList) || '-' }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              scope="props"
              field="memberRole"
              label="Role"
              cell-class="valign"
              sortable
              >{{ getNameForId(props.row.memberRole, memberRoles) }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              scope="props"
              field="memberStatus"
              label="Member Status"
              cell-class="valign"
              sortable
            >
              <span>
                {{ getNameForId(props.row.memberStatus, teamMemberStatus) }}
              </span>
            </b-table-column>
          </b-table>
        </div>
      </template>
    </siyn-collapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { trackExceptionEvent } from '@/misc/analytics'
import { viewProfileMixin } from '@/mixins/profile/index'
import memberRoles from '@/data/teams/team-member-role'
import { teamStatusConstants } from '@/data/teams/team-status'
import {
  teamMemberStatus,
  teamMemberStatusConstants,
} from '@/data/teams/team-member-status'

import { teamTypesList } from '@/data/teams/team-types'

export default {
  name: 'UserTeams',
  components: {},
  mixins: [viewProfileMixin], // register mixin
  data() {
    return {
      panelTitle: this.showLabelsFromCode('generic/teams'),
      panelname: 'projectTeams_view',
      isLoading: true,
      memberRoles,
      teamMemberStatus,
      teamMemberStatusConstants,
      teamTypesList,
    }
  },
  computed: {
    ...mapState('userTeams', ['activeUserTeams']),
    ...mapGetters('userTeams', ['getApprovedSendTeam']),
  },
  created() {
    this.fetchUserTeamsContent()
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('userTeams', ['setAllActiveUserTeams']),
    ...mapActions('userTeams', ['fetchAllActiveTeamsByUserId']),
    fetchUserTeamsContent() {
      const constraints = []
      constraints.push(['teamStatus', '==', teamStatusConstants.ACTIVE])
      constraints.push(['isTeamPrivate', '==', false])
      this.fetchAllActiveTeamsByUserId({
        userId: this.$route.params.uid,
        constraints,
      })
        .then((allActiveUserTeams) => {
          this.setAllActiveUserTeams(allActiveUserTeams)
        })
        .catch((err) => {
          if (err.code !== 'permission-denied') {
            console.log(err)
            this.handleErrorMessage(
              `${this.showUserMessageFromCode(
                'generic/error-in-fetch',
              )} user teams.`,
            )
          }
          this.setAllActiveUserTeams(null)
          trackExceptionEvent(
            'user-teams_fetch_failed',
            `${
              this.user ? this.user.id : 'visitor'
            } tried to fetch user teams for ${this.uid2}. ${err}`,
            false,
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
<style lang="scss">
.view-user-teams {
  .siyncollapse__heading--emoji {
    margin-bottom: 6px;
  }
}
</style>
