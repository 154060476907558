<template>
  <div class="view-index-page">
    <div class="container my-2">
      <b-message
        title="Our Journey has Ended"
        type="is-info is-light"
        aria-close-label="Close message"
      >
        We're here to deliver some bad news: BeInSync MVP Project will be
        shutting down at the end of April 2024. Thank you so much for your
        support over the past 12 months.
        <p class="my-2">
          We know it's a pain to deal with services shutting down. This was not
          a decision that we made lightly. After investing more time and
          resources into the product over the past few months, we've made the
          decision to discontinue the service at the end of the April. If you
          have any questions please contact us and we will do our best to help.
        </p>
        <p class="my-2">
          <strong>- Your team at TypeDef, LLC.</strong>
        </p>
      </b-message>
    </div>
    <section class="section hero-section">
      <div class="container is-fullhd">
        <div class="columns is-vcentered is-gapless">
          <div class="column is-12 is-7-desktop">
            <div class="scrolling-words-container">
              <span>Build a tribe with</span>
            </div>
          </div>
          <div class="column is-12 is-5-desktop">
            <div class="scrolling-words-box">
              <ul>
                <li class="has-text-danger">Managers</li>
                <li class="has-text-info">Customers</li>
                <li class="has-text-success">Prospects</li>
                <li class="has-text-light-orange">Contractors</li>
                <li class="has-text-primary">Employees</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section why-section has-background-white">
      <div class="container">
        <div class="columns is-centered">
          <div class="column has-text-centered">
            <p class="hero-font">
              <strong class="has-text-danger">53%</strong>
              of customers say it’s harder to feel connected, we can help remedy
              that.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section digital-profile-section">
      <div class="container is-fullhd">
        <div class="columns is-multiline is-vcentered">
          <div class="column is-12-tablet is-5-desktop">
            <h2 class="title">Personalized Profiles</h2>
            <ul>
              <li>
                Share your expertise and projects. Let your colleagues and
                customers know who you are beyond your job title.
              </li>
              <li>
                Find and network with customers and colleagues effortlessly.
                Strengthen your network and foster collaboration.
              </li>
              <li>
                Your profile is your digital brand. Take advantage of this
                platform to showcase your skillset, and contribute to a culture
                of collaboration and innovation.
              </li>
            </ul>
          </div>
          <div class="column is-12-tablet is-7-desktop">
            <hooper
              :items-to-show="1.0"
              :auto-play="true"
              :play-speed="8000"
              :infinite-scroll="true"
              :mouse-drag="false"
              :touch-drag="false"
              :wheel-control="false"
              :short-drag="false"
            >
              <slide>
                <img
                  class="hero-image"
                  src="@/assets/img/index/profile-1.webp"
                />
              </slide>
              <slide>
                <img class="hero-image" src="@/assets/img/index/profile-2.webp"
              /></slide>
              <slide>
                <img
                  class="hero-image"
                  src="@/assets/img/index/profile-3.webp"
                />
              </slide>
              <slide>
                <img
                  class="hero-image"
                  src="@/assets/img/index/profile-4.webp"
                />
              </slide>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
          </div>
        </div>
      </div>
    </section>
    <section class="section why-section has-background-white">
      <div class="container">
        <div class="columns is-centered">
          <div class="column has-text-centered">
            <p class="hero-font">
              Subscribe to the information you need, from people to projects,
              preventing burnout from digital communication tools.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section digital-watercooler-section">
      <h2 class="title is-hidden-desktop has-text-center mt-4">
        Digital Watercooler
      </h2>
      <div class="container is-fullhd">
        <div class="columns is-multiline is-vcentered">
          <div class="column is-12-tablet is-7-desktop">
            <hooper
              :items-to-show="1.0"
              :auto-play="true"
              :play-speed="8000"
              :infinite-scroll="true"
              :mouse-drag="false"
              :touch-drag="false"
              :wheel-control="false"
              :short-drag="false"
            >
              <slide>
                <img
                  class="hero-image"
                  src="@/assets/img/index/digital-watercooler-1.webp"
                />
              </slide>
              <slide>
                <img
                  class="hero-image"
                  src="@/assets/img/index/digital-watercooler-2.webp"
              /></slide>
              <slide>
                <img
                  class="hero-image"
                  src="@/assets/img/index/digital-watercooler-3.webp"
                />
              </slide>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
          </div>
          <div class="column is-12-tablet is-5-desktop">
            <h2 class="title is-hidden-touch mb-3">Digital Watercooler</h2>
            <ul>
              <li>
                Say goodbye to nonstop chat notifications, overflowing inboxes,
                and endless meeting invites! Stay informed about everything
                happening in your orbit — all on one page.
              </li>
              <li>
                Our Digital Watercooler keeps everyone informed, from customers,
                to project milestones; promoting a more connected and
                knowledgeable workforce.
              </li>
              <li>
                Encourage interaction, share insights, and connect with peers to
                foster team cohesion.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-team">
      <h2 class="has-text-centered">Team Management</h2>
      <div class="columns is-centered is-multiline is-vcentered">
        <div class="column is-4 has-text-centered">
          <div class="box">
            <img class="card-image" src="@/assets/img/index/team-1.webp" />
            <h3>Team Dashboard</h3>
            <p class="body2">
              Team dashboard serves as a central location for all team related
              information.
            </p>
          </div>
        </div>
        <div class="column is-4 has-text-centered">
          <div class="box">
            <img
              class="card-image"
              src="@/assets/img/index/team-event-1.webp"
            />
            <h3>Team Events</h3>
            <p class="body2">
              Create one time or recurring team events. Auto fetch best
              available time between all users.
            </p>
          </div>
        </div>
        <div class="column is-4 has-text-centered">
          <div class="box">
            <img class="card-image" src="@/assets/img/index/team-2.webp" />
            <h3>Team Bulletins</h3>
            <p class="body2">Seamlessly share project updates.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="section scheduling-section">
      <h2 class="has-text-centered">Personal & Team Scheduling Pages</h2>
      <p class="subtitle">
        Bid Adieu to scheduling headaches! Whether you're coordinating with
        clients, prospects, or contractors, we've got you covered—no more
        endless back-and-forth emails to find a suitable meeting time.
      </p>
      <div class="container is-fullhd">
        <div class="columns is-vcentered is-multiline is-gapless">
          <div class="column is-12-tablet is-5-desktop">
            <ul>
              <li>
                For 1 on 1 or team meetings, instantly see when participants are
                available, even if on different calendering system.
              </li>
              <li>
                For team scheduling choose between two convenient scheduling
                options: book when the entire team is available or when at least
                one member is free.
              </li>
              <li>
                Smart Availability finder analyzes participants' calendars and
                suggests available time slots even across different
                organizations.
              </li>
            </ul>
          </div>
          <div class="column is-12-tablet is-7-desktop">
            <hooper
              :items-to-show="1.0"
              :auto-play="true"
              :play-speed="7000"
              :infinite-scroll="true"
              :mouse-drag="false"
              :touch-drag="false"
              :wheel-control="false"
              :short-drag="false"
            >
              <slide>
                <img
                  class="hero-image"
                  src="@/assets/img/index/scheduling-4.webp"
                />
              </slide>
              <slide>
                <img
                  class="hero-image"
                  src="@/assets/img/index/scheduling-6.webp"
              /></slide>
              <slide>
                <img
                  class="hero-image"
                  src="@/assets/img/index/scheduling-5.webp"
                />
              </slide>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
          </div>
        </div>
      </div>
    </section>
    <section class="section why-section has-background-white">
      <div class="container">
        <div class="columns is-centered">
          <div class="column has-text-centered">
            <p class="hero-font">
              We use AI to bridge the knowledge chasm due to lack to
              communications and mutual interactions.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section ai-section">
      <h2 class="title is-hidden-desktop has-text-center mt-4">
        AI Notes and AI Monthly Reports
      </h2>
      <div class="container is-fullhd">
        <div class="columns is-vcentered is-multiline">
          <div class="column is-12-tablet is-7-desktop">
            <hooper
              :items-to-show="1.0"
              :auto-play="true"
              :play-speed="8000"
              :infinite-scroll="true"
              :mouse-drag="false"
              :touch-drag="false"
              :wheel-control="false"
              :short-drag="false"
            >
              <slide>
                <img class="hero-image" src="@/assets/img/index/ai-1.webp" />
              </slide>
              <slide>
                <img class="hero-image" src="@/assets/img/index/ai-2.webp"
              /></slide>
              <slide>
                <img class="hero-image" src="@/assets/img/index/ai-3.webp" />
              </slide>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
          </div>
          <div class="column is-12-tablet is-5-desktop">
            <h2 class="title is-hidden-touch mb-3">
              AI Notes and AI Monthly Reports
            </h2>
            <ul>
              <li>
                With our AI-powered summaries, you can get the short and sweet
                version of all meetings, saving you time and effort.
              </li>
              <li>
                Unlock the power of AI to automatically generate your monthly
                and quarterly reports with ease. With AI Monthly Reports, you
                can transform raw data into actionable insights, empowering you
                to make informed decisions and drive business success.
              </li>
              <li>
                Your data remains private and confidential at all times. We
                adhere to strict privacy standards and never use your
                information to train AI models or for any other purposes.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-company">
      <h2 class="has-text-centered">Company Management</h2>
      <div class="columns is-centered is-multiline is-vcentered">
        <div class="column is-4 has-text-centered">
          <div class="box">
            <img
              class="card-image"
              src="@/assets/img/index/company-event-1.webp"
            />
            <h3>Company Events</h3>
            <p class="body2">
              Create internal or public events. Public events ask for user
              registration.
            </p>
          </div>
        </div>
        <div class="column is-4 has-text-centered">
          <div class="box">
            <img
              class="card-image"
              src="@/assets/img/index/company-newsletter-1.webp"
            />
            <h3>Company Newsletters</h3>
            <p class="body2">
              Create newsletters / blogs to distribute information across your
              organization.
            </p>
          </div>
        </div>
        <div class="column is-4 has-text-centered">
          <div class="box">
            <img
              class="card-image"
              src="@/assets/img/index/company-broadcasts-1.webp"
            />
            <h3>Company Broadcasts</h3>
            <p class="body2">
              Broadcast important messages to all company employees at the same
              time.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section why-section has-background-white">
      <div class="container">
        <div class="columns is-centered">
          <div class="column has-text-centered">
            <p class="hero-font">
              {{ appTitle }} - Building a tribe by offering a secure platform to
              gather and share information.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="hero">
        <div class="hero-body">
          <div class="container">
            <div class="columns is-vcentered">
              <div class="column is-two-thirds">
                <h1 class="app-name title">{{ appTitle }}</h1>
                <div class="app-byline title">{{ byline }}</div>
                <div class="value-prop">
                  <p class="value-prop__heading title">
                    Boost your employee productivity by up to 20%¹.
                  </p>
                  <ul class="value-prop__list">
                    <li class="value-prop__list--item">
                      🕗 Scheduling automation platform
                    </li>
                    <li class="value-prop__list--item">
                      🪄 AI-powered auto-summarization and insights from your
                      meetings
                    </li>
                    <li class="value-prop__list--item">
                      🤝 Digital watercooler to enchance team cammaradarie &
                      morale
                    </li>
                    <li class="value-prop__list--item">
                      📅 Event management for teams & organizations
                    </li>
                    <li class="value-prop__list--item">
                      💪 Employee mentoring management
                    </li>
                    <li class="value-prop__list--item">
                      📺 Dashboard view on what's happening across your people,
                      your teams and your organization.
                    </li>
                    <li class="value-prop__list--item">
                      👨🏽‍💼 Build your profile page and a professional network
                    </li>
                  </ul>
                </div>

                <b-button
                  v-if="isUserLoggedIn"
                  class="button is-warning create-go-button mt-4"
                  tag="router-link"
                  :to="{
                    name: 'profile',
                    params: { uid: user.id },
                  }"
                >
                  <span class="has-text-weight-medium">Go to My Profile</span>
                </b-button>
              </div>
              <div class="column is-hidden-mobile">
                <img
                  class="hero-image"
                  src="@/assets/img/synemail-email-profile.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </section> -->
    <!-- <section class="section testimonials">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="media">
              <div class="media-left my-auto">
                <img src="https://randomuser.me/api/portraits/men/50.jpg" />
              </div>
              <div class="ml-2 media-content">
                <blockquote>
                  "{{ appTitle }} makes it a breeze for me to schedule meetings
                  with leads, clients, vendors, and consultants. No more endless
                  back-and-forth emails trying to find a suitable date and
                  time."
                </blockquote>
                <a target="_blank" href="www.siyn.email/profile/AV"
                  ><b>Parikh Patel</b></a
                >
                <br />
                CEO and President,
                <strong>QuantumByte Innovations Inc.</strong>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="media">
              <div class="media-left my-auto">
                <img src="https://randomuser.me/api/portraits/men/4.jpg" />
              </div>
              <div class="ml-2 media-content">
                <blockquote>
                  "Using AI, {{ appTitle }} generates monthly and quarterly
                  reports for everything my teams have accomplished and what we
                  need to focus on. Its like magic!"
                </blockquote>
                <a
                  target="_blank"
                  href="http://localhost:8081/public/meeting/vdJ88Sh2AVtnqFLr6TyPozZqQ6yyGwQ0j8f4xTeelLfiaCcR"
                  ><b>Tom Tsui</b></a
                >
                <br />
                Sr. Global Technology Lead, <b>Tohosa Motor Limited</b>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="media">
              <div class="media-left my-auto">
                <img src="https://randomuser.me/api/portraits/women/3.jpg" />
              </div>
              <div class="ml-2 media-content">
                <blockquote>
                  "Being aware of vacation schedules, conference participations,
                  tradeshows, office hours, and more of our global team helps me
                  stay efficient and current."
                </blockquote>
                <a target="_blank" href="www.siyn.email/profile/MW"
                  ><b>Suzaane Knowles</b></a
                >
                <br />
                Sr. Portfolio Manager, <b>Emerging Worldwide, Inc.</b>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="media">
              <div class="media-left my-auto">
                <img src="https://randomuser.me/api/portraits/men/75.jpg" />
              </div>
              <div class="ml-2 media-content">
                <blockquote>
                  "My professional portfolio page serves as a hub for sharing
                  information about myself, networking with peers, finding
                  mentors in my org and learning about what books others are
                  reading."
                </blockquote>
                <a target="_blank" href="www.siyn.email/profile/JK"
                  ><b>Aahad Khalil</b></a
                >
                <br />
                Sr. Consultant, <b>First Worldwide, Inc.</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <section class="section information has-background-white">
      <div class="container">
        <div class="columns reverse-row-order is-desktop">
          <div class="column">
            <div class="lottie-wrapper">
              <div class="lottie-animation">
                <img
                  class="screenshot1"
                  src="@/assets/img/synemail-social-network-connect.svg"
                />
              </div>
            </div>
          </div>
          <div class="column is-4-desktop">
            <h2 class="h2">Only Share Within Your Network</h2>
            <p class="body-text">
              Share your contact information within your network by adding a
              link to your
              <b>{{ appTitle }} </b>profile in your email signature.
            </p>
            <br />
            <p class="body-text">
              Our unique URL is not easy to memorize and not searchable on
              search engines.
            </p>
            <br />
            <p class="body-text">
              We do not have APIs to share your information to advertisers, we
              do not allow searching for people outside your own network.
            </p>
            <br />
            <p class="body-text">
              We do not sell your information to recruiters.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section features has-background-info-light">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half has-text-centered">
            <div class="box">
              <img src="@/assets/img/synemail-profile-2.svg" />
              <h3 class="h3">Be Yourself</h3>
              <p class="body-text">
                Share how to pronounce your name, what name you prefer to go by,
                and who you work for; all without sharing your work history.
              </p>
            </div>
          </div>
          <div class="column is-half has-text-centered">
            <div class="box">
              <img src="@/assets/img/synemail-office-hours.svg" />
              <h3 class="h3">Your Office Hours</h3>
              <p class="body-text">
                Easiest way to share your regular work hours, what days you work
                remote v/s in office, and how often do you check your emails.
              </p>
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-half has-text-centered">
            <div class="box">
              <img src="@/assets/img/synemail-calendar.svg" />
              <h3 class="h3">Your Out of Office Alerts</h3>
              <p class="body-text">
                Let your network know the days you plan to be Out of Office.
                Your profile page look changes on the day you are OOO so they
                know.
              </p>
            </div>
          </div>
          <div class="column is-half has-text-centered">
            <div class="box">
              <img src="@/assets/img/synemail-books-share.svg" />
              <h3 class="h3">Books You are Reading</h3>
              <p class="body-text">
                Share the books you are currently reading with your coworkers,
                team, friends and family.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section information has-background-white">
      <div class="container">
        <div class="columns is-desktop reverse-row-order">
          <div class="column">
            <img src="@/assets/img/syemail-social-networks.svg" />
          </div>
          <div class="column is-4-desktop">
            <h2 class="h2">
              Links to Your Social Media, Your Conference Call, Your Appointment
              Scheduler.
            </h2>
            <p class="body-text">
              Share links to your corporate and personal social media pages,
              your unique conference call link, your Slack&reg;, MS Teams&reg;
              or other messageing link, and your appointment scheduler.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section information">
      <div class="container">
        <div class="columns is-desktop">
          <div class="column is-4-desktop">
            <h2 class="h2">Some more info...</h2>
            <p class="body-text mb-6">More info coming...</p>

            <b-button
              v-if="isUserLoggedIn"
              class="button is-large is-warning create-go-button"
              tag="router-link"
              :to="{
                name: 'profile',
                params: { uid: user.id },
              }"
            >
              <span class="has-text-weight-medium">Go to My Profile</span>
            </b-button>
          </div>
          <div class="column is-hidden-desktop">
            <img src="@/assets/img/synemail-profile-3.svg" />
          </div>

          <div class="column is-hidden-touch has-text-right">
            <img src="@/assets/img/synemail-profile-3.svg" />
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */

import { mapState, mapGetters } from 'vuex'
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper'
import {
  trackPageView,
  trackScreenView,
  trackNavigationEvent,
} from '@/misc/analytics'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'hooper/dist/hooper.css'

export default {
  name: 'IndexScreen',
  metaInfo() {
    return {
      title: `${this.appTitle}`,
      meta: [
        { property: 'og:title', content: `${this.appTitle}` },
        { property: 'og:url', content: `${this.appURL}home` },
        {
          name: 'description',
          content: `${this.appTitle} - ${this.byline}. Share office hours, remote work schedule, OOO alerts, social media, books, and stay in touch.`,
        },
        {
          property: 'og:description',
          content: `${this.appTitle} - ${this.byline}. Share office hours, remote work schedule, OOO alerts, social media, books, and stay in touch.`,
        },
        {
          property: 'og:image',
          content: `${this.appURL}img/synemail-email-profile.85f137aa.svg`, // TODO change the logo image
        },
      ],
      link: [{ rel: 'canonical', href: `${this.appURL}home` }],
    }
  },
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  computed: {
    ...mapState('app', [
      'networkOnLine',
      'appTitle',
      'appURL',
      'appShortTitle',
      'byline',
    ]),
    ...mapGetters('user', ['isUserLoggedIn']),
    ...mapState('user', ['user']),
  },
  mounted() {
    trackPageView()
    trackScreenView('No User Home')
  },
  methods: {
    async redirectToJoin(location) {
      trackNavigationEvent(
        'request_to_join',
        'Create Siyn.Email Account',
        location,
        'button',
      )
      this.$router.push({
        path: '/register',
      })
    },
  },
}
</script>
<!-- <style lang="scss" scoped>
.mt-10 {
  margin-top: -10px;
}
p.body-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.hero {
  background-color: $primary;
  //max-height: 760px;
  // margin-top: -16px;
  @media (min-width: $desktop) {
    //max-height: 680px;
  }

  p.subtitle {
    color: $body-color;
    margin: 0;
    padding-bottom: $base-size * 2;
  }
  p.body-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
  img.hero-image {
    height: auto;
    @media (min-width: $tablet) {
      max-width: 36vw;
      width: 100vw;
      margin-left: -8vw;
    }
    @media (min-width: $desktop-up) {
      max-width: 49vw;
      width: 100vw;
      height: 73vh;
      margin-left: -18vw;
      margin-bottom: -7vw;
    }
  }
}
.app-name {
  font-size: $base-size * 3;
  @media (min-width: $tablet) {
    font-size: $base-size * 4;
  }
  font-weight: bold;
  margin-bottom: $min-size;
}
.app-byline {
  font-size: $base-size * 1.5;
  @media (min-width: $tablet) {
    font-size: $base-size * 1.75;
  }
  font-weight: bold;
}
.value-prop {
  &__heading {
    font-size: $base-size * 1.75;
    font-weight: bold;
    margin-bottom: $min-size;
    @media (min-width: $tablet) {
      font-size: $base-size * 2;
      text-align: left;
    }
    @media (min-width: $desktop-up) {
      font-size: $base-size * 2;
    }
  }
  &__list {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
    margin-top: $base-size;
    @media (min-width: $tablet) {
      text-align: left;
    }
    @media (min-width: $desktop-up) {
      padding-left: $base-size * 1;
    }
    &--item {
      color: $title-color;
      margin-bottom: 0.5 * $base-size;
      font-size: 1.25 * $base-size;
    }
  }
}
.create-go-button {
  margin-bottom: $base-size * 1.25;
}
.testimonials {
  @media screen and (min-width: $desktop-up) {
    padding: 8rem 1.5rem 6rem;
  }
  img {
    border-radius: 50%;
    width: 75px;
    @media (min-width: $desktop) {
      width: 100px;
    }
  }
  blockquote {
    font-size: $base-size;
  }
  .column .columns {
    padding: 16px;
  }
}
.why {
  .h2 {
    margin-bottom: $base-size;
  }
}
.information {
  .h2 {
    margin-bottom: $base-size * 2;
  }
  .lottie-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    @media (max-width: 1024px) {
      justify-content: center;
    }
    .lottie-animation {
      background-color: transparent;
      border-radius: 16px;
      padding: 16px;
    }
  }

  @media (min-width: 768px) {
    .section.information img,
    .section.information .lottie-animation {
      width: 680px;
    }
  }
  .column {
    img {
      width: 680px;
    }
  }
}
.features {
  .box {
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 12%), 0px 0px 2px rgb(0 0 0 / 8%);
    height: 100%;
    padding: 30px;
  }
  img {
    max-width: 210px;
  }
  .h3 {
    font-weight: 800;
    margin-top: $base-size * 2;
    margin-bottom: calc($base-size / 2);
  }
}
</style> -->
