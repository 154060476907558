const emailCheckingFrequencies = [
  { id: 1, name: 'On Notification' },
  { id: 2, name: 'Every Few Minutes' },
  { id: 3, name: 'Hourly' },
  { id: 4, name: 'Every Few Hours' },
  { id: 5, name: 'Once A Day' },
  { id: 6, name: 'Twice A Day' },
  { id: 7, name: 'No Pattern' },
  { id: 9, name: 'Not Applicable' },
]
const escalationPaths = [
  { id: 1, name: 'Send Chat Message' },
  { id: 2, name: 'Call Cell Phone' },
  { id: 3, name: 'Call Desk Phone' },
  { id: 3, name: 'Schedule a Meeting' },
  { id: 4, name: 'Contact Supervisor' },
  { id: 5, name: 'Contact Admin Assistant' },
  { id: 7, name: 'Not Applicable' },
]

export { emailCheckingFrequencies, escalationPaths }
