<template>
  <div class="box synpanel">
    <p v-if="title">
      <span class="synpanel__heading"
        ><span class="synpanel__heading--emoji">{{ emoji }}</span
        >{{ title }}</span
      >
      <router-link
        v-if="icon && isUserLoggedIn && user.id === $route.params.uid"
        class="no-text-decoration"
        :to="{ name: routeName, params: { recordid: recordId } }"
      >
        <b-tooltip
          :label="tagLabel + ' ' + title"
          position="is-right"
          type="is-light"
        >
          <b-tag
            v-if="icon"
            :icon="icon"
            :pack="iconPack"
            size="is-small"
            type="is-primary"
            class="synpanel__heading--tag"
            >{{ tagLabel }}</b-tag
          >
        </b-tooltip>
      </router-link>
    </p>
    <div class="synpanel__content">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SynPanel',
  props: {
    title: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconPack: {
      type: String,
      default: 'far',
    },
    recordId: {
      type: Number,
      default: null,
    },
    routeName: {
      type: String,
      default: null,
    },
    tagLabel: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('user', ['isUserLoggedIn']),
    ...mapState('user', ['user']),
  },
}
</script>
