<!-- eslint-disable vue/no-v-html -->
<template>
  <article class="notifications__item">
    <div class="notifications__item--message">
      <span v-html="data.message"></span>
      <span class="notifications__item--timestamp">• {{ momentAgo(data.createTimestamp) }}</span>
    </div>
    <div v-if="data.networkRequest" class="has-text-right">
      <b-button
        type="is-ghost"
        size="is-small"
        icon-left="trash-alt"
        icon-pack="fas"
        @click="removeNotification()"
        >Remove Alert</b-button
      >
    </div>
  </article>
</template>

<script>
/* eslint-disable import/extensions */
import { momentAgo } from '@/misc/helpers'

export default {
  name: 'AsideUpdatesItem',
  components: {},
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  methods: {
    momentAgo,
    close() {
      // // destroy the vue listeners, etc
      // this.$destroy()
      // // remove the element from the DOM
      // this.$el.parentNode.removeChild(this.$el)
    },
    removeNotification() {
      // console.log(`Delete notification of id ${this.notificationId}`)
      // this.$store
      //   .dispatch(
      //     'notifications/deleteNotification',
      //     this.data.notificationId ? this.data.notificationId : ''
      //   )
      //   .then(() => {
      //     this.close()
      //   })
      //   .catch((error) => {
      //     console.error(error.message)
      //   })
    },
  },
}
</script>
