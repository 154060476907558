var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team-booking-details"},[_c('div',{staticClass:"box mb-4"},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-10"},[_c('div',{staticClass:"title is-4 has-text-weight-normal"},[_vm._v(" "+_vm._s(_vm.showLabelsFromCode('generic/team'))+" "+_vm._s(_vm.showLabelsFromCode('scheduling/schedulingpage'))+" for "),(_vm.user)?_c('router-link',{attrs:{"target":"_blank","to":{
              name: 'viewteam',
              params: { id: _vm.bookingRecord?.team?.id },
            }}},[_c('span',{staticClass:"team-booking-details__teamName"},[_vm._v(_vm._s(_vm.bookingRecord?.team?.teamName)+" ")])]):_c('span',[_vm._v(_vm._s(_vm.bookingRecord?.team?.teamName))]),(_vm.companyName)?[_vm._v(" at "),(_vm.companyName)?_c('router-link',{attrs:{"target":"_blank","to":{
                name: 'viewcompany',
                params: { companyid: _vm.bookingRecord?.companyId },
              }}},[(_vm.companyLogoImageURL)?_c('view-user-avatar',{staticClass:"team-booking-details__companyImageURL",attrs:{"alt-name":_vm.companyName,"imgsrc":_vm.companyLogoImageURL}}):_vm._e(),_vm._v(" "+_vm._s(_vm.companyName))],1):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"column is-2 has-text-right"},[_c('b-button',{staticClass:"info__edit-button ml-2",attrs:{"title":"Share event","type":"is-info","icon-left":"share-from-square","pack":"fas"},on:{"click":function($event){return _vm.openShareModal()}}},[_vm._v("Share ")])],1)])]),(_vm.bookingRecord?.showTeamMembers)?_c('div',{staticClass:"box mb-4"},[_c('div',{staticClass:"columns is-gapless is-vcentered"},[_c('div',{staticClass:"column is-12"},[_c('span',{staticClass:"valign-super sm-label mr-2"},[_vm._v(_vm._s(_vm.showLabelsFromCode('teams/teammembers/panelTitle'))+":")]),_vm._l((_vm.bookingRecord?.teamMembersList),function(teamMember){return _c('span',{key:teamMember.id,staticClass:"is-inline-block mr-2"},[_c('router-link',{staticClass:"no-text-decoration is-block mt-1",attrs:{"target":"_blank","to":{
              name: 'profile',
              params: { uid: teamMember.id },
            }}},[(teamMember?.photoURL)?_c('span',{staticClass:"team-booking-details__imageURL"},[_c('img',{attrs:{"src":teamMember.photoURL,"alt":teamMember.displayName},on:{"error":_vm.setAltImg}})]):_vm._e(),_c('span',{staticClass:"team-booking-details__memberName valign-super"},[_vm._v(_vm._s(teamMember?.displayName)+" ")])])],1)})],2)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }