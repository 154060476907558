/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import first from 'lodash/first'
import UserCommentsDB from '@/firebase/comments/user-comments-db'

export default {
  /** ************* NEW CODE ************ */

  fetchCommentsByUserId: async ({ commit }, userId) => {
    const userCommentsDb = new UserCommentsDB(userId)
    return userCommentsDb.readAll()
  },

  createUpdateComment: async ({ rootState, commit }, payload) => {
    const userCommentsDb = new UserCommentsDB(rootState.user.user.id)
    const response = await userCommentsDb
      .createOrUpdate(payload)
      .then((result) => {
        return result
      })
      .catch((err) => {
        throw err
      })
    return response
  },
  /**
   * Fetch comments of a user
   */
  /*   getUserComments: async ({ commit }, userId) => {
    const userCommentsDb = new UserCommentsDB(userId)
    const comments = await userCommentsDb.readAll()
    commit('setComments', comments)
  }, */
  /**
   * Fetch comments by Id
   */
  // getCommentsById: async ({ commit }, userId) => {
  //   const userCommentsDB = new UserCommentsDB(userId)
  //   const comments = await userCommentsDB.readAll()
  //   commit('setComments', comments[0])
  // },
  // getUserCommentsbyId: async ({ commit }, payload) => {
  //   const userCommentsDB = new UserCommentsDB(payload.userId)
  //   const comments = await userCommentsDB.read(payload.cId)
  //   commit('setComments', comments)
  // },
  // /**
  //  * Create for current loggedin user
  //  */
  // addComment: async ({ commit, rootState }, comments) => {
  //   const userCommentsDb = new UserCommentsDB(rootState.user.user.id)
  //   commit('setCommentsCreationPending', true)
  //   await userCommentsDb.create(comments)
  //   commit('setComments', comments)
  //   commit('setCommentsCreationPending', false)
  // },

  // /**
  //  * Update Comments for the user
  //  */
  // updateComment: async ({ rootState, commit }, comments) => {
  //   commit('setCommentsUpdatePending', true)
  //   const userCommentsDb = new UserCommentsDB(rootState.user.user.id)
  //   await userCommentsDb.update(comments)
  //   commit('setComments', comments)
  //   commit('setCommentsUpdatePending', false)
  // },

  /**
   * Deletes a comment
   */
  deleteCommentById: async ({ rootState, commit }, commentsId) => {
    const userCommentsDb = new UserCommentsDB(rootState.user.user.id)
    return userCommentsDb.delete(commentsId)
  },
}
