<template>
  <section class="view-office-hours">
    <skeleton v-if="isLoading"></skeleton>
    <siyn-collapse
      v-if="!isLoading && officeHours"
      :title="panelTitle"
      emoji="🕗"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <template #header-extras>
        <b-tooltip
          multilined
          :label="toolTipLabel"
          position="is-right"
          type="is-dark"
        >
          <div
            class="siyncollapse__heading--extras indicator"
            :class="renderClass"
          ></div>
        </b-tooltip>
      </template>
      <template #default>
        <div v-if="officeHours && !isLoading" class="table-container">
          <table
            class="table is-fullwidth is-hoverable view-office-hours__table"
          >
            <colgroup>
              <col />
              <col class="class_1" />
              <col class="class_2" />
              <col class="class_3" />
              <col class="class_4" />
              <col class="class_5" />
              <col class="class_6" />
              <col class="class_0" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <div></div>
                </th>
                <th v-if="officeHours.mon.startTime" class="class_1">M</th>
                <th v-if="officeHours.tue.startTime" class="class_2">Tu</th>
                <th v-if="officeHours.wed.startTime" class="class_3">W</th>
                <th v-if="officeHours.thu.startTime" class="class_">T</th>
                <th v-if="officeHours.fri.startTime" class="class_">F</th>
                <th v-if="officeHours.sat.startTime" class="class_">S</th>
                <th v-if="officeHours.sun.startTime" class="class_">Su</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="has-text-right">
                  {{ showLabelsFromCode('profile/officehours/startTime') }}
                </td>
                <td v-if="officeHours.mon.startTime" class="class_1">
                  {{ formatOfficeHours(officeHours.mon.startTime) }}
                </td>
                <td v-if="officeHours.tue.startTime" class="class_2">
                  {{ formatOfficeHours(officeHours.tue.startTime) }}
                </td>
                <td v-if="officeHours.wed.startTime" class="class_3">
                  {{ formatOfficeHours(officeHours.wed.startTime) }}
                </td>
                <td v-if="officeHours.thu.startTime" class="class_4">
                  {{ formatOfficeHours(officeHours.thu.startTime) }}
                </td>
                <td v-if="officeHours.fri.startTime" class="class_5">
                  {{ formatOfficeHours(officeHours.fri.startTime) }}
                </td>
                <td v-if="officeHours.sat.startTime" class="class_6">
                  {{ formatOfficeHours(officeHours.sat.startTime) }}
                </td>
                <td v-if="officeHours.sun.startTime" class="class_0">
                  {{ formatOfficeHours(officeHours.sun.startTime) }}
                </td>
              </tr>
              <tr>
                <td class="has-text-right">
                  {{ showLabelsFromCode('profile/officehours/endTime') }}
                </td>
                <td v-if="officeHours.mon.endTime" class="class_1">
                  {{ formatOfficeHours(officeHours.mon.endTime) }}
                </td>
                <td v-if="officeHours.tue.endTime" class="class_2">
                  {{ formatOfficeHours(officeHours.tue.endTime) }}
                </td>
                <td v-if="officeHours.wed.endTime" class="class_3">
                  {{ formatOfficeHours(officeHours.wed.endTime) }}
                </td>
                <td v-if="officeHours.thu.endTime" class="class_4">
                  {{ formatOfficeHours(officeHours.thu.endTime) }}
                </td>
                <td v-if="officeHours.fri.endTime" class="class_5">
                  {{ formatOfficeHours(officeHours.fri.endTime) }}
                </td>
                <td v-if="officeHours.sat.endTime" class="class_6">
                  {{ formatOfficeHours(officeHours.sat.endTime) }}
                </td>
                <td v-if="officeHours.sun.endTime" class="class_0">
                  {{ formatOfficeHours(officeHours.sun.endTime) }}
                </td>
              </tr>
              <tr v-if="officeHours">
                <td class="has-text-right">
                  {{ showLabelsFromCode('profile/officehours/worksRemote') }}
                </td>
                <td v-if="officeHours.mon.startTime" class="class_1">
                  {{ officeHours.mon.isRemote ? 'Yes' : 'No' }}
                </td>
                <td v-if="officeHours.tue.startTime" class="class_2">
                  {{ officeHours.tue.isRemote ? 'Yes' : 'No' }}
                </td>
                <td v-if="officeHours.wed.startTime" class="class_3">
                  {{ officeHours.wed.isRemote ? 'Yes' : 'No' }}
                </td>
                <td v-if="officeHours.thu.startTime" class="class_4">
                  {{ officeHours.thu.isRemote ? 'Yes' : 'No' }}
                </td>
                <td v-if="officeHours.fri.startTime" class="class_5">
                  {{ officeHours.fri.isRemote ? 'Yes' : 'No' }}
                </td>
                <td v-if="officeHours.sat.startTime" class="class_6">
                  {{ officeHours.sat.isRemote ? 'Yes' : 'No' }}
                </td>
                <td v-if="officeHours.sun.startTime" class="class_0">
                  {{ officeHours.sun.isRemote ? 'Yes' : 'No' }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!isInSameTimeZone" class="columns is-multiline is-gapless">
            <div class="column is-auto">
              <b-tooltip
                :label="showTooltipsFromCode('profile/officehours/currentTime')"
                position="is-top"
                type="is-dark"
                ><span class="emoji">🕰️</span
                ><b-tag type="is-primary" style="vertical-align: bottom">{{
                  time
                }}</b-tag></b-tooltip
              >
            </div>

            <div class="column is-auto">
              <b-checkbox
                v-model="changeTimeZoneCheckboxVal"
                @input="changeTimeZone"
                >{{
                  showLabelsFromCode('profile/officehours/convertTZ')
                }}</b-checkbox
              >
            </div>
          </div>
          <p v-else>
            {{ showLabelsFromCode('profile/officehours/sameTZ') }} ({{
              officeHours.timeZone
            }})
          </p>
        </div>
        <span v-if="officeHours && officeHours.note"
          ><span class="mr-1 has-text-weight-bold">Note :</span
          ><span>{{ officeHours.note }}</span></span
        >
        <p v-if="!officeHours && !isLoading" class="ml-4">
          <span>{{ showUserMessageFromCode('generic/none') }}</span>
        </p>
      </template>
    </siyn-collapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
/* eslint-disable func-names */

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import {
  getOnlineStatus,
  handleTimeZones,
  getCurrentDayOfWeek,
} from '@/misc/helpers'
import { viewProfileMixin } from '@/mixins/profile/index'

export default {
  name: 'ViewOfficeHours',
  components: {},
  mixins: [viewProfileMixin],
  data() {
    return {
      panelTitle: this.showLabelsFromCode('profile/officehours/panelTitle'),
      panelname: 'officeHours_view',
      changeTimeZoneCheckboxVal: false,
      interval: null,
      time: null,
      timeZoneTemp: null,
      isLoading: true,
      isInSameTimeZone: false,
      renderClass: 'is-unsure',
      toolTipLabel: this.showTooltipsFromCode('profile/officehours/unsure'),
      currentDaySelector: undefined,
    }
  },
  computed: {
    ...mapState('officehours', ['officeHours']),
    ...mapGetters('officehours', ['getOfficeHours']),
  },
  watch: {
    mergedPanelsConfig: {
      handler(mergedPanelsConfig) {
        if (!this.isEmpty(mergedPanelsConfig) && this.profileUser) {
          if (
            this.getAuthUserId === this.$route.params.uid ||
            this.getAuthUserCompanyId === this.profileUser.companyId
          ) {
            this.fetchOfficeHoursContent()
          } else if (
            mergedPanelsConfig.officeHours ===
            this.visibilityConstants.VISIBLE_TO_ALL
          ) {
            // console.log('line 201')
            this.fetchOfficeHoursContent()
          } else if (
            mergedPanelsConfig.officeHours ===
              this.visibilityConstants.VISIBLE_TO_MY_NW &&
            this.checkIfYouAreInMyNetwork({
              userId: this.profileUser.id,
              network: this.network,
            })
          ) {
            this.fetchOfficeHoursContent()
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('officehours', ['setOfficeHours']),
    ...mapActions('officehours', ['fetchOfficehoursByUserId']),
    formatOfficeHours(dateTimeinUTC) {
      return handleTimeZones(dateTimeinUTC, this.timeZoneTemp)
    },
    async fetchOfficeHoursContent() {
      // console.log(JSON.stringify('fetch office hours'))
      await this.fetchOfficehoursByUserId(this.$route.params.uid)
        .then((officeHoursResponse) => {
          // console.log(JSON.stringify(officeHoursResponse))
          if (officeHoursResponse) {
            this.nextTick(() => {
              this.setOfficeHours(this.first(officeHoursResponse))
            })
          }
        })
        .then(() => {
          if (this.officeHours) {
            this.timeZoneTemp = this.officeHours.timeZone || null
            this.isLoading = true
          }
        })
        .then(() => {
          if (this.officeHours) {
            if (
              Intl.DateTimeFormat().resolvedOptions().timeZone ===
              this.officeHours.timeZone
            ) {
              this.isInSameTimeZone = true
            }
          }
        })
        .catch((err) => {
          if (err.code !== 'permission-denied') {
            console.log(err)
            this.handleErrorMessage(
              `${this.showUserMessageFromCode(
                'generic/error-in-fetch',
              )} regular office hours.`,
            )
          }

          this.setOfficeHours(null)
          this.trackExceptionEvent(
            'office-hours_fetch_failed',
            `${this.user ? this.user.id : 'visitor'} tried to fetch info for  ${
              this.$route.params.uid
            }. ${err}`,
            false,
          )
        })
        .finally(() => {
          const n = getCurrentDayOfWeek(this.timeZoneTemp)
          // console.log(JSON.stringify(n))
          this.$nextTick(function () {
            this.renderItems(n)
          })
          this.isLoading = false
        })
      this.isLoading = false
    },
    renderItems(n) {
      this.currentDaySelectors = document.getElementsByClassName(`class_${n}`)
      this.$nextTick(function () {
        Array.from(this.currentDaySelectors).forEach(function (
          currentDaySelector,
        ) {
          currentDaySelector.style.fontWeight = '600'
          currentDaySelector.style.backgroundColor = '#75e6b31a' // TODO hardcoded.
        })

        this.showClock()
        if (!this.isEmpty(this.getOfficeHours)) {
          const statusResponse = getOnlineStatus(this.getOfficeHours)
          switch (statusResponse) {
            case 0:
              this.renderClass = 'is-not-online'
              this.toolTipLabel = this.showTooltipsFromCode(
                'profile/officehours/isnotonline',
              )
              break
            case 1:
              this.renderClass = 'is-online'
              this.toolTipLabel = this.showTooltipsFromCode(
                'profile/officehours/isonline',
              )
              break
            case 2:
              this.renderClass = 'is-maybe-online'
              this.toolTipLabel = this.showTooltipsFromCode(
                'profile/officehours/maybeonline',
              )
              break
            default:
              this.renderClass = 'is-unsure'
              this.toolTipLabel = this.showTooltipsFromCode(
                'profile/officehours/unsure',
              )
              break
          }
        }
      })
    },

    showClock() {
      if (this.officeHours && this.officeHours.timeZone) {
        const date = Intl.DateTimeFormat(navigator.language, {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          timeZone: this.officeHours.timeZone,
        }).format()
        this.time = `${date} ${this.officeHours.timeZone}`
        setTimeout(() => {
          this.showClock()
        }, 60000)
      } else {
        this.time = 'fetching...'
      }
    },
    changeTimeZone() {
      const n = getCurrentDayOfWeek(this.timeZoneTemp)
      if (!this.changeTimeZoneCheckboxVal) {
        this.timeZoneTemp = this.officeHours.timeZone
        this.renderItems(n)
        return false
      }
      this.timeZoneTemp = null
      this.renderItems(n)
      return true
    },
  },
}
</script>
<style scoped lang="scss">
.view-office-hours {
  &__table {
    th,
    td {
      font-weight: 400;
      text-align: center !important;
      border-color: $primary;
    }

    .has-text-right {
      text-align: right !important;
    }
  }
  .is-online {
    background-color: green;
  }
  .is-not-online {
    background-color: gray;
  }
  .is-maybe-online {
    background-color: orange;
  }
  .is-unsure {
    background-color: $grey-lighter;
  }
  .indicator {
    border-radius: 50%;
    width: $base-size;
    height: $base-size;
    animation: fadeIn 1s linear 3;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .emoji {
    margin-right: $base-size * 0.5;
    vertical-align: bottom;
  }
  .tag {
    border-radius: $box-radius;
    font-weight: 400;
    font-size: $base-size * 0.75;
  }
}
</style>
