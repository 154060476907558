export default {
  setNetwork: (state, network) => (state.network = network),

  addToNetwork: (state, network) => state.network.push(network),

  setNetworkStatus: (state, payload) => {
    // console.log(JSON.stringify(payload))
    const index = state.network.findIndex(
      (record) => record.id === payload.friendId,
    )
    if (index !== -1) {
      state.network[index].status = payload.status
      state.network[index].updateTimestamp = new Date()
    }
  },
  blockUserInCache: (state, payload) => {
    const index = state.network.findIndex(
      (record) => record.id === payload.friendId,
    )
    if (index !== -1) {
      state.network[index].status = payload.status
      state.network[index].blockedById = payload.blockedById
      state.network[index].updateTimestamp = new Date()
    }
  },

  removeNetworkByFriendId: (state, friendId) => {
    console.log(JSON.stringify(friendId))
    if (state.network) {
      const index = state.network.findIndex(
        (network) => network.id === friendId,
      )
      console.log(JSON.stringify(index))
      if (index !== -1) {
        state.network.splice(index, 1)
      }
    }
  },

  setSubscriptionStatus: (state, payload) => {
    const index = state.network.findIndex(
      (record) => record.id === payload.friendId,
    )

    if (index !== -1) {
      state.network[index].subscribed = payload.subscribed
      state.network[index].updateTimestamp = new Date()
    }
  },
}
