import state from './mentoring.state'
import mutations from './mentoring.mutations'
import actions from './mentoring.actions'
import getters from './mentoring.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
