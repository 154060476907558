<template>
  <section class="event-subscribers">
    <div width-class="is-12" class="view-event-subscribers">
      <skeleton v-if="isLoading"></skeleton>

      <SiynCollapse
        v-else
        :title="panelTitle"
        :is-open="collapseHelper(panelname)"
        :panelname="panelname"
      >
        <div class="columns is-multiline is-gapless">
          <template v-for="subscriberId in teamEventToView.subscribers">
            <BuildUserAvatar
              :key="subscriberId"
              class="column is-4 is-narrow"
              :uid="subscriberId"
            />
          </template>
        </div>
      </SiynCollapse>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import { mapState } from 'vuex'
import { viewTeamsMixin } from '@/mixins/teams/index'
import BuildUserAvatar from '@/components/BuildUserAvatar'

export default {
  name: 'TeamEventSubscribers',
  components: {
    BuildUserAvatar,
  },
  mixins: [viewTeamsMixin],
  props: {},
  data() {
    return {
      isLoading: true,
      panelTitle: this.showLabelsFromCode('generic/subscribers'),
      panelname: 'teamevent_subscribers',
    }
  },
  computed: {
    ...mapState('teamsEvents', ['teamEventToView']),
  },
  created() {},
  mounted() {
    this.isLoading = false
  },
  destroyed() {},
  methods: {},
}
</script>
