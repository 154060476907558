<template>
  <div
    class="upload-img"
    :class="{ round }"
    :style="{
      width: `${width}px`,
      height: `${height}px`,
    }"
  >
    <img v-if="imgUrl" :src="imgUrl" alt="Image" />
    <div class="upload-overlay">
      <div
        class="hint"
        :class="{ 'no-img': !imgUrl }"
        :style="progress != -1 ? 'opacity: 1' : ''"
      >
        {{ hint }}
        <b-icon class="edit-icon" icon="edit" pack="far" />
        <b-progress
          v-if="progress >= 0"
          type="is-primary"
          :value="progress"
          show-value
          :rounded="round"
          format="percent"
          size="is-medium"
        ></b-progress>
        <input type="file" accept="image/*" @change="onUpload" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable import/extensions */
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  connectStorageEmulator,
} from 'firebase/storage'
import { storage } from '@/firebase/initFirebase'
import { trackExceptionEvent } from '@/misc/analytics'

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectStorageEmulator(storage, '127.0.0.1', 9199)
}

export default {
  name: 'UploadImg',
  props: {
    hint: {
      type: String,
      default: 'Click to upload/change image!',
    },
    foldername: String,
    uid: String,
    filename: String,
    resizeWidth: Number,
    resizeHeight: Number,
    width: Number,
    height: Number,
    round: {
      type: Boolean,
      default: false,
    },
    imgUrl: String,
  },
  data() {
    return {
      progress: -1,
    }
  },
  methods: {
    convertImg(file, filename, cb) {
      // load original image
      const original = new Image()
      original.onload = () => {
        // put image to canvas
        const canvas = document.createElement('canvas')
        canvas.width = this.resizeWidth
        canvas.height = this.resizeHeight
        // reszie image using canvas
        const ctx = canvas.getContext('2d')
        const wCount = original.width / this.resizeWidth
        const hCount = original.height / this.resizeHeight
        let sx
        let sy
        let sw
        let sh
        if (wCount > hCount) {
          // crop "vertically"
          sh = original.height
          sw = (original.height * this.resizeWidth) / this.resizeHeight
          sy = 0
          sx = (original.width - sw) / 2
        } else {
          // crop horizontally
          sw = original.width
          sh = (original.width * this.resizeHeight) / this.resizeWidth
          sx = 0
          sy = (original.height - sh) / 2
        }

        ctx.drawImage(
          original,
          sx,
          sy,
          sw,
          sh,
          0,
          0,
          this.resizeWidth,
          this.resizeHeight,
        )
        // read from canvas to png image file
        const dataURL = canvas.toDataURL('image/png')
        // https://stackoverflow.com/a/43358515/12017013
        const arr = dataURL.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        cb(new File([u8arr], filename, { type: mime }))
      }
      original.onerror = () => {
        this.$buefy.snackbar.open({
          message: `We're sorry this is an invalid file type, please use an image file`,
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 3000,
        })
      }
      original.src = URL.createObjectURL(file)
    },
    onUpload(e) {
      // Create a root reference
      const filename = this.filename || new Date().toISOString()
      const fileRef = ref(storage, `${this.foldername}/${this.uid}/${filename}`)

      // console.log(JSON.stringify(this.foldername))
      // console.log(JSON.stringify(filename))
      // console.log(JSON.stringify(ref))
      this.convertImg(e.target.files[0], filename, (png) => {
        const uploadTask = uploadBytesResumable(fileRef, png)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // progress
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          },
          (err) => {
            // error
            console.log(`Error while uploading ${filename} photo:`, err.message)
            setTimeout(() => {
              this.progress = -1
            }, 1000)
            trackExceptionEvent('profile_photo_uploaded_failed', err.message)
          },
          () => {
            // success
            // console.log("Uploaded");
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setTimeout(() => {
                this.progress = -1
              }, 1000)
              this.$emit('uploaded', url)
            })
          },
        )
      })
    },
  },
}
</script>
