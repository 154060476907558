// import firebase from 'firebase/compat/app'
// import 'firebase/compat/app-check'
// import 'firebase/compat/auth'

import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getMessaging, isSupported } from 'firebase/messaging'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions } from 'firebase/functions'
import { getRemoteConfig } from 'firebase/remote-config'
// import {
//   initializeAppCheck,
//   ReCaptchaEnterpriseProvider,
// } from 'firebase/app-check'

// import 'firebase/compat/analytics'

// import Vue from 'vue'
// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: process.env.VUE_APP_FIRE_BASE_API_KEY,
  authDomain: process.env.VUE_APP_APP_FIRE_BASE_AUTH_DOMAIN,
  databaseURL: 'http://localhost:8080',
  projectId: process.env.VUE_APP_FIRE_BASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIRE_BASE_APP_ID,
  measurementId: process.env.VUE_APP_FIRE_BASE_MEASURMENT_ID,
  timestampsInSnapshots: false,
}

// if (window.location.hostname === 'localhost') {
//   config.databaseURL = 'localhost:4000?ns=synemail'
// }

// const app = getApps().length === 0 ? initializeApp(config) : getApp()
const app = initializeApp(config)

// eslint-disable-next-line import/prefer-default-export
export const auth = getAuth(app)
export const db = getFirestore(app)
export const messaging = async () => (await isSupported()) && getMessaging(app)
export const storage = getStorage(app)
export const analytics = getAnalytics(app)
export const functions = getFunctions(app)
export const remoteConfig = getRemoteConfig(app)

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
// if (process.env.NODE_ENV !== 'production') {
//   window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
// }
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaEnterpriseProvider(
//     '59D891AD-DE1D-492D-9E15-979F7BBC1316',
//   ),
//   isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
// })
// Appcheck setup video - https://www.youtube.com/watch?v=bpTw4aMxCU8
// appCheck.activate()
/* const appCheck = firebase.appCheck()
appCheck.activate('6Ld5yLkeAAAAALVzhLPsgv4K96maORG_um4vLNGt', true) */
// Vue.prototype.$analytics = firebase.analytics()
