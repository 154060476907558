import state from './emailpreferences.state'
import mutations from './emailpreferences.mutations'
import actions from './emailpreferences.actions'
import getters from './emailpreferences.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
