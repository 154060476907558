export default {
  setTeamBulletins: (state, teamBulletins) =>
    (state.teamBulletins = teamBulletins),

  addToTeamBulletin: (state, teamBulletin) =>
    state.teamBulletins.push(teamBulletin),

  setTeamBulletinUpdate(state, teamBulletin) {
    const index = state.teamBulletins.findIndex(
      (item) => item.id === teamBulletin.id,
    )
    if (index !== -1) {
      state.teamBulletins[index].bulletinTitle = teamBulletin.bulletinTitle
      state.teamBulletins[index].bulletinText = teamBulletin.bulletinText
      state.teamBulletins[index].lastUpdatedBy = teamBulletin.lastUpdatedBy
    }
  },

  setBulletinReaction(state, likedByArray) {
    // console.log(JSON.stringify(likedByArray))
    // eslint-disable-next-line no-underscore-dangle
    state.teamBulletinToView.likedBy = likedByArray
  },

  setTeamBulletinToRemove(state, teamBulletinId) {
    const index = state.teamBulletins.findIndex(
      (item) => item.id === teamBulletinId,
    )
    if (index !== -1) {
      state.teamBulletins.splice(index, 1)
    }
  },

  setTeamBulletinToView: (state, teamBulletinToView) =>
    (state.teamBulletinToView = teamBulletinToView),

  setTeamBulletinToEdit: (state, teamBulletinToEdit) =>
    (state.teamBulletinToEdit = teamBulletinToEdit),
}
