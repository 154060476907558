var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"firestore-files"},[(_vm.isLoading)?_c('div',[_c('b-skeleton',{attrs:{"width":"20%","animated":""}}),_c('b-skeleton',{attrs:{"width":"40%","animated":""}}),_c('b-skeleton',{attrs:{"width":"80%","animated":""}}),_c('b-skeleton',{attrs:{"animated":""}})],1):_c('div',[(_vm.files)?_c('b-table',{ref:"table",staticClass:"firestore-files__table",attrs:{"data":_vm.files,"hoverable":"","mobile-cards":"","narrowed":"","custom-row-key":"id","show-header":_vm.files.length > 0 ? true : false,"paginated":_vm.files.length > 8 ? true : false,"pagination-size":"is-small","default-sort":"metadata.updated","default-sort-direction":"desc","per-page":"8","aria-next-label":_vm.showLabelsFromCode('generic/nextpage'),"aria-previous-label":_vm.showLabelsFromCode('generic/previouspage'),"aria-page-label":_vm.showLabelsFromCode('generic/page'),"aria-current-label":_vm.showLabelsFromCode('generic/currentpage')}},[_c('b-table-column',{attrs:{"field":"metadata.contentType","label":"Type","sortable":"","cell-class":"valign"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
            props.row.metadata &&
            props.row.metadata.contentType.split('/')[0] === 'image'
          )?[_c('b-icon',{attrs:{"pack":"far","icon":"image","size":"is-medium"}})]:(props.row.metadata)?[_c('b-icon',{attrs:{"pack":"far","icon":_vm.populateIcon(props.row.metadata.contentType),"size":"is-medium"}})]:_vm._e()]}}],null,false,771496312)}),_c('b-table-column',{attrs:{"cell-class":"valign","field":"metadata.name","label":"Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.metadata && props.row.url)?_c('span',[_c('a',{attrs:{"target":"_blank","href":props.row.url}},[_vm._v(_vm._s(props.row.metadata.customMetadata.filename || props.row.metadata.name))])]):_vm._e()]}}],null,false,1915924627)}),_c('b-table-column',{attrs:{"field":"metadata.size","label":"Size","sortable":"","cell-class":"valign"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.metadata)?_c('span',[_vm._v(_vm._s(Number((props.row.metadata.size / 1000).toFixed(0)) + ' KB'))]):_vm._e()]}}],null,false,2168738507)}),_c('b-table-column',{attrs:{"field":"metadata.updated","label":"Updated","sortable":"","cell-class":"valign"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.metadata)?_c('span',[_vm._v(" "+_vm._s(_vm.momentAgo(props.row.metadata.updated))+" ")]):_vm._e()]}}],null,false,3393027390)}),_c('b-table-column',{attrs:{"field":"id","label":"Action","centered":"","cell-class":"valign"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
            _vm.allowedToUpdateFile ||
            _vm.allowedToDeleteFile ||
            _vm.user.id === props.row.createdById
          )?[(_vm.allowedToUpdateFile || _vm.user.id === props.row.createdById)?_c('a',{staticClass:"no-text-decoration mr-3",on:{"click":function($event){return _vm.updateFileName(props.row.metadata)}}},[_c('b-tooltip',{attrs:{"label":_vm.showLabelsFromCode('generic/edit'),"position":"is-right","type":"is-dark"}},[_c('b-icon',{attrs:{"pack":"far","icon":"edit","type":"is-primary","size":"is-medium"}})],1)],1):_vm._e(),(_vm.allowedToDeleteFile || _vm.user.id === props.row.createdById)?_c('a',{staticClass:"no-text-decoration",on:{"click":function($event){return _vm.deleteFile(props.row.metadata)}}},[_c('b-tooltip',{attrs:{"label":_vm.showLabelsFromCode('generic/delete'),"position":"is-left","type":"is-dark"}},[_c('b-icon',{attrs:{"icon":"trash-alt","pack":"fa","type":"is-danger","size":"is-medium"}})],1)],1):_vm._e()]:[_c('p',{staticClass:"empty-content"},[_vm._v("N/A")])]]}}],null,false,1103340784)})],1):_vm._e(),(_vm.processing)?_c('b-progress',{attrs:{"type":"is-success","rounded":false,"show-value":""}}):_vm._e(),(_vm.allowedToAddFile)?_c('div',{staticClass:"firestore-files__button-wrapper"},[_c('form',{attrs:{"enctype":"multipart/form-data","novalidate":""}},[_c('label',{attrs:{"for":"file-upload"}},[(!_vm.processing)?_c('b-tag',{staticClass:"firestore-files__edit-button background-color-override br-6",attrs:{"type":"is-danger","title":"Add File","size":"is-medium"}},[_vm._v("Add File ")]):_vm._e()],1),_c('input',{attrs:{"id":"file-upload","type":"file"},on:{"change":_vm.addFile}})])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }