/* WARNING !! This is also used on server side, make sure they are in sync!! */

const notificationActionTypesConstants = {
  ADDED: 1,
  CREATED: 2,
  UPDATED: 3,
  DELETED: 4,
  REMOVED: 5,
  LEFT: 6,
  JOINED: 7,
  SUBSCRIBED: 8,
  UNSUBSCRIBED: 9,
  REQUESTED: 10,
  UNKNOWN: null,
}

const notificationActionTypes = [
  { id: notificationActionTypesConstants.ADDED, name: 'Added' },
  { id: notificationActionTypesConstants.JOINED, name: 'Joined' },
  { id: notificationActionTypesConstants.SUBSCRIBED, name: 'Subscribed' },
  { id: notificationActionTypesConstants.CREATED, name: 'Created' },
  { id: notificationActionTypesConstants.UPDATED, name: 'Updated' },
  { id: notificationActionTypesConstants.DELETED, name: 'Deleted' },
  { id: notificationActionTypesConstants.REMOVED, name: 'Removed' },
  { id: notificationActionTypesConstants.LEFT, name: 'Left' },
  { id: notificationActionTypesConstants.UNSUBSCRIBED, name: 'Unsubscribed' },
  { id: notificationActionTypesConstants.REQUESTED, name: 'Requested' },
  { id: notificationActionTypesConstants.UNKNOWN, name: 'Unknown' },
]
export { notificationActionTypesConstants, notificationActionTypes }
