const mentoringSessionStatusConstants = {
  SCHEDULED: 1,
  CANCELLED: 2,
  ACKPENDING: 3,
  COMPLETED: 4,
}

const mentoringSessionStatus = [
  { id: mentoringSessionStatusConstants.SCHEDULED, name: 'Scheduled' },
  { id: mentoringSessionStatusConstants.CANCELLED, name: 'Cancelled' },
  { id: mentoringSessionStatusConstants.ACKPENDING, name: 'Acknowledge Pending' },
  { id: mentoringSessionStatusConstants.COMPLETED, name: 'Completed' },
]

export { mentoringSessionStatusConstants, mentoringSessionStatus }
