<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="company-event-location">
    <div class="view-company-event-location">
      <skeleton v-if="isLoading"></skeleton>

      <SynPanel v-if="companyEventToView && !isLoading" :title="panelTitle">
        <div class="view-event-location__wrapper px-4 py-2">
          <template v-if="companyEventToView.eventLocationType === 'inperson'">
            <a
              :href="buildGoogleMapsURL(companyEventToView.eventLocation)"
              class="no-text-decoration is-inline-block"
              rel="noopener noreferrer"
              target="_blank"
            >
              🏢 {{ companyEventToView.eventLocation }}</a
            >
          </template>
          <template
            v-else-if="companyEventToView.eventLocationType === 'confcall'"
          >
            <p class="view-company-event-location__confcallInfo">
              <span v-html="companyEventToView.confcallInfo"></span>
            </p>
          </template>
          <template v-else>
            <div class="columns is-vcentered is-multiline">
              <div class="column is-2 is-narrow">
                <span class="sm-label">Meeting Link : </span>
              </div>
              <div class="column is-10">
                <span class="sm-value">
                  <a
                    v-if="companyEventToView?.conferencing?.details?.url"
                    :href="companyEventToView?.conferencing?.details?.url"
                    class="button is-dark has-text-left"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <b-icon pack="fas" icon="video" class="mr-2"> </b-icon>
                    {{
                      renderConferenceProvider(
                        companyEventToView?.conferencing?.provider,
                      )
                    }}
                  </a>
                </span>
              </div>
              <div
                v-if="companyEventToView?.conferencing?.details?.phone"
                class="column is-2"
              >
                <span class="sm-label has-text-right">Phone : </span>
              </div>
              <div
                v-if="companyEventToView?.conferencing?.details?.phone"
                class="column is-4"
              >
                <span class="sm-value">{{
                  companyEventToView?.conferencing?.details?.phone
                }}</span>
              </div>
              <div
                v-if="companyEventToView?.conferencing?.details?.pin"
                class="column is-2"
              >
                <div class="sm-label has-text-right">Pin :</div>
              </div>
              <div
                v-if="companyEventToView?.conferencing?.details?.pin"
                class="column is-4"
              >
                <span class="sm-value">{{
                  companyEventToView.conferencing.details.pin
                }}</span>
              </div>
              <div
                v-if="companyEventToView?.conferencing?.details?.meetingCode"
                class="column is-2"
              >
                <div class="sm-label has-text-right">Code :</div>
              </div>
              <div
                v-if="companyEventToView?.conferencing?.details?.meetingCode"
                class="column is-4"
              >
                <span class="sm-value">{{
                  companyEventToView.conferencing.details.meetingCode
                }}</span>
              </div>
              <div
                v-if="companyEventToView?.conferencing?.details?.password"
                class="column is-2"
              >
                <div class="sm-label has-text-right">Password :</div>
              </div>
              <div
                v-if="companyEventToView?.conferencing?.details?.password"
                class="column is-4"
              >
                <span class="sm-value">{{
                  companyEventToView.conferencing.details.password
                }}</span>
              </div>
            </div>
          </template>
        </div>
      </SynPanel>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import SynPanel from '@/components/SynPanel.vue'
import { viewCompanyMixin } from '@/mixins/company/index'

export default {
  name: 'CompanyEventLocation',
  components: {
    SynPanel,
  },
  mixins: [viewCompanyMixin],
  props: {},
  data() {
    return {
      googleMapsBaseURL: 'http://maps.google.com/maps?q=',
      isLoading: true,
    }
  },
  computed: {
    panelTitle() {
      if (this.companyEventToView.eventLocationType === 'inperson') {
        return 'Event Location'
      }
      if (this.companyEventToView.eventLocationType === 'chattool') {
        return 'Event URL'
      }

      return 'Event Connect Information'
    },
  },
  created() {},
  mounted() {
    this.isLoading = false
  },
  destroyed() {},
  methods: {
    buildGoogleMapsURL(address) {
      if (!this.isEmpty(address)) {
        this.panelTitle = 'Event Location'
        return `${this.googleMapsBaseURL}${encodeURIComponent(address)}`
      }
      return address
    },
  },
}
</script>
