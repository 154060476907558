import state from './officehours.state'
import mutations from './officehours.mutations'
import actions from './officehours.actions'
import getters from './officehours.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
