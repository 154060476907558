import isEmpty from 'lodash/isEmpty'
import {
  // eslint-disable-next-line
  connectFirestoreEmulator,
  serverTimestamp as FirebaseServerTS,
  writeBatch,
  doc,
  collection,
} from 'firebase/firestore'

import { db, auth } from '@/firebase/initFirebase'

import { networkStatusConstants } from '@/data/networkStatus'
import { notificationActionTypesConstants } from '@/data/notificationActionTypes'

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}
const serverTimestamp = FirebaseServerTS()

// const fbf = firebase.functions()
// if (window.location.hostname === 'localhost') {
//   fbf.useEmulator('127.0.0.1', 5001)
// }

export default function networkBatch() {
  return true
}

export const requestConnectionBatch = async (payload) => {
  const allowedOperation = await auth.currentUser
    .getIdTokenResult()
    .then((idTokenResult) => {
      if (
        idTokenResult.claims.licenseType === 'ENT' ||
        idTokenResult.claims.licenseType === 'PAID'
      ) {
        return true
      }
      return false
    })
    .catch((error) => {
      console.log(error)
      throw error
    })

  if (!allowedOperation) {
    throw new Error('Unauthorized Operation')
  }
  if (allowedOperation) {
    const { profileUser } = payload
    const { user } = payload
    const { status } = payload
    const createdBy = {
      id: user.id,
      displayName: user.displayName,
    }
    const userRecord = {
      requestor: createdBy,
      subscribed: true,
      friend: {
        id: profileUser.id,
        displayName: profileUser.displayName,
        photoURL: profileUser.photoURL,
        title: profileUser.title || '',
        email: profileUser.email,
        companyId: profileUser.companyId || null,
        companyName: profileUser.companyName || profileUser.companyId,
        licenseType: profileUser.licenseType || 'GUEST',
      },
      status,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp,
      createdBy,
    }
    const userNotificationRecord = {
      type: notificationActionTypesConstants.REQUESTED,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp,
      createdBy,
      message: `Your Network request to <a href="/view/profile/${profileUser.id}">${profileUser.displayName}</a> has been send.`,
    }
    const friendNotificationRecord = {
      type: notificationActionTypesConstants.REQUESTED,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp,
      createdBy,
      message: `You have received Network request from <a href="/view/profile/${user.id}">${user.displayName}</a>. See email for more information.`,
    }
    const friendRecord = {
      requestor: createdBy,
      subscribed: true,
      friend: {
        id: user.id,
        displayName: user.displayName,
        photoURL: user.photoURL,
        title: user.title || '',
        email: user.email,
        companyId: user.companyId || null,
        companyName: user.companyName || profileUser.companyId,
        licenseType: user.licenseType || 'GUEST',
      },
      status,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp,
      createdBy,
    }

    const batch = writeBatch(db)

    const userRef = doc(db, 'users', user.id, 'network', profileUser.id)
    const userNotificationRef = doc(
      collection(db, 'users', user.id, 'notifications'),
    )
    const friendNotificationRef = doc(
      collection(db, 'users', profileUser.id, 'notifications'),
    )
    const friendRef = doc(db, 'users', profileUser.id, 'network', user.id)

    batch.set(userRef, userRecord)
    batch.set(userNotificationRef, userNotificationRecord)
    batch.set(friendNotificationRef, friendNotificationRecord)
    batch.set(friendRef, friendRecord)

    await batch
      .commit()
      .then(() => {
        return { userRecord, friendRecord }
      })
      .catch((error) => {
        console.log(error)
        throw error
      })
  } else {
    throw new Error('Illegal Operation')
  }
}

export const updateStatusBatch = async (payload) => {
  // console.log(payload)
  const { profileUser } = payload
  const { user } = payload
  const { status } = payload
  const { blockedById } = payload
  const updatedBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const createdBy = updatedBy

  const batch = writeBatch(db)

  let updateRecord = {
    status,
    updateTimestamp: serverTimestamp,
    updatedBy,
  }

  if (!isEmpty(blockedById)) {
    updateRecord = {
      ...updateRecord,
      blockedById: user.id,
    }
  }

  if (status === networkStatusConstants.REQUEST_APPROVED) {
    const friendNotificationRef = doc(
      collection(db, 'users', profileUser.id, 'notifications'),
    )

    const friendNotificationRecord = {
      type: notificationActionTypesConstants.UPDATED,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp,
      createdBy,
      message: `Your Network request to <a href="/view/profile/${user.id}">${user.displayName}</a> has been approved.`,
    }
    batch.set(friendNotificationRef, friendNotificationRecord)
  }

  const userRef = doc(db, 'users', user.id, 'network', profileUser.id)
  const friendRef = doc(db, 'users', profileUser.id, 'network', user.id)

  batch.update(userRef, updateRecord)
  batch.update(friendRef, updateRecord)

  batch
    .commit()
    .then(() => {
      return { user, profileUser, updateRecord }
    })
    .catch((error) => {
      console.log(error)
      throw error
    })
}

export const removeConnectionBatch = async (payload) => {
  // console.log(payload)

  const { profileUser } = payload
  const { user } = payload

  const batch = writeBatch(db)

  const userRef = doc(db, 'users', user.id, 'network', profileUser.id)
  const friendRef = doc(db, 'users', profileUser.id, 'network', user.id)

  batch.delete(userRef)
  batch.delete(friendRef)

  batch
    .commit()
    .then(() => {
      return { user, profileUser }
    })
    .catch((error) => {
      console.log(error)
      throw error
    })
}
