<template>
  <div id="app">
    <template v-if="!networkOnLine">
      <ViewError
        style="max-width: 650px"
        class="mx-auto"
        title="You are offline"
        description="Application is not available as you are currently offline"
      ></ViewError>
    </template>
    <template v-else>
      <nav-bar></nav-bar>
      <div
        id="main-wrapper"
        class="main-wrapper"
        :style="{ backgroundColor: wrapperBackgroundColor }"
      >
        <router-view :key="$route.fullPath" />
      </div>
      <footer-page></footer-page>
      <new-content-available-toastr
        v-if="newContentAvailable"
        class="new-content-available-toastr"
        :refreshing-app="refreshingApp"
        @refresh="serviceWorkerSkipWaiting"
      ></new-content-available-toastr>
      <apple-add-to-home-screen-modal
        v-if="showAddToHomeScreenModalForApple"
        class="apple-add-to-home-screen-modal"
        @close="closeAddToHomeScreenModalForApple(false)"
      >
      </apple-add-to-home-screen-modal>
      <aside-right v-if="user" />
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
/* eslint-disable import/extensions */
import NavBar from '@/components/NavBar'
import FooterPage from '@/components/FooterPage.vue'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr.vue'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal.vue'
import AsideRight from '@/components/AsideRight'
import ViewError from '@/views/errors/ViewError'

export default {
  metaInfo() {
    return {
      title: `${this.appTitle}`,
      titleTemplate: `%s | ${this.appTitle}`,
      meta: [
        { property: 'og:site_name', content: `${this.appTitle}` },
        {
          property: 'og:url',
          content: `${this.appURL}`,
          vmid: 'og:url',
        },
      ],
    }
  },
  components: {
    NavBar,
    FooterPage,
    NewContentAvailableToastr,
    AppleAddToHomeScreenModal,
    AsideRight,
    ViewError,
  },
  computed: {
    ...mapState('app', [
      'networkOnLine',
      'appTitle',
      'appURL',
      'appShortTitle',
      'byline',
      'showAddToHomeScreenModalForApple',
      'refreshingApp',
      'wrapperBackgroundColor',
    ]),
    ...mapState('user', ['user']),
    ...mapGetters('app', ['newContentAvailable']),
  },
  methods: mapActions('app', [
    'closeAddToHomeScreenModalForApple',
    'serviceWorkerSkipWaiting',
  ]),
}
</script>
