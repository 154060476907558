const teamTypesConstants = {
  ACCOUNT: 5,
  PROJECT: 10,
  DIVISION: 20,
  DEPARTMENT: 25,
  LEADERSHIP: 30,
  DEI: 40,
  SOCIAL: 50,
  DIRECTREPORTS: 60,
  SUPPORT: 60,
  OTHER: 99,
}

const teamTypesList = [
  { id: teamTypesConstants.ACCOUNT, name: 'Account' },
  { id: teamTypesConstants.PROJECT, name: 'Project' },
  { id: teamTypesConstants.DIVISION, name: 'Division' },
  { id: teamTypesConstants.DEPARTMENT, name: 'Department' },
  { id: teamTypesConstants.LEADERSHIP, name: 'Leadership' },
  { id: teamTypesConstants.DEI, name: 'DEI' },
  { id: teamTypesConstants.SOCIAL, name: 'Social' },
  { id: teamTypesConstants.DIRECTREPORTS, name: 'Direct Reports' },
  { id: teamTypesConstants.SUPPORT, name: 'Support' },
  { id: teamTypesConstants.OTHER, name: 'Other' },
]
export { teamTypesConstants, teamTypesList }
