<template>
  <div class="wrapper">
    <template v-if="refreshingApp"> Loading App Ver. {{ packageInfo.version }}</template>
    <template v-else>
      New App Version available. Please
      <span class="refresh-btn" @click="$emit('refresh')">REFRESH</span>
    </template>
  </div>
</template>

<script>
import packageInfo from '../../package'

export default {
  props: {
    refreshingApp: Boolean,
  },
  data() {
    return {
      packageInfo,
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  display: inline-block;
  border-radius: 4px;

  .refresh-btn {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
