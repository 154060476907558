export default {
  'form/basicInfoPanel':
    'This panel shows basic information which will be visible to everyone who visits your unique web page.',
  'form/generic/panelVisibility':
    'Who can view above information on your profile?',
  'form/generic/panelVisibilityAlert':
    'Your organization administrator can override this configuration for everyone in your org.',
  'form/privateInfo/emailAddress':
    'Email address cannot be updated as its associated with your account.',
  'form/generic/numbersDashesOnly': 'Numbers and dashes only',
  'form/privateInfo/sos':
    "Your 'professional' emergency contact when you are not available.",
  'profile/private/privacyWarning':
    "⚠️ For privacy 'Contact' panel auto hides in",
  'profile/contactPreferences/checkMails': 'How often do they check emails?',
  'profile/contactPreferences/checkMailsMe':
    'How often do you check your emails?',
  'profile/contactPreferences/actionsToTake':
    'What action to take if there is no response from them',
  'profile/contactPreferences/responseExpectations':
    'How long to wait for a response from them',
  'profile/contactPreferences/responseExpectationsMe':
    'How long should others wait for a response from you before taking next action',
  'profile/contactPreferences/actionsToTakeMe':
    'What action should they take if there is no response from you',
  'profile/officehours/currentTime': 'Current time in their timezone',
  'profile/officehours/isnotonline':
    'Per regular schedule they are unavailable.',
  'profile/officehours/isonline':
    'Per regular schedule they are currently available.',
  'profile/officehours/maybeonline':
    'They may be available even if it is after regular schedule',
  'profile/officehours/unsure': 'Unsure about their availability',
  'profile/officehours/minutesAfterEndTime':
    'Minutes after End Time that you are available if need be',
  'profile/events/panelInfo':
    'List of upcoming events ending in next 30 days. Company & Team events only visible to employees of the company.',
  'profile/events/editevent': 'Edit Event',
  'profile/events/isOOO': 'Is Out of Office',
  'profile/events/deleteevent': 'Delete Event',
  'profile/events/bulkevents/holidays':
    'You can auto fetch all national holidays for the year by clicking the button below.',
  'profile/events/fetchingholidays': 'Fetching official national holidays ...',
  'profile/events/fetchingtip1': 'Do review the holiday list before saving.',
  'profile/events/fetchingtip2':
    'Long weekends may need changes since official national holidays are different than what is usually practiced in offices.',
  'profile/links/uniqueurl': 'Type or copy/paste the URL',
  'profile/edit/panelvisibility':
    'Determines who can view this panel on your profile',

  'profile/mentoring/duration':
    'How long will the mentoring session be (in mins)?',

  'form/mentoring/status':
    'Deleting a session is not permitted. Please mark the status as "Cancelled" instead of deleting the session.',

  'teams/add/teammember/RACI':
    "A RACI chart (sometimes called a Responsibility Assignment Matrix) is a way to identify your project teams' roles and responsibilities for any task, milestone, or project deliverable",
  'teams/update/status':
    'Use Inactive when team is no longer working on a task/project. Use Archived in lieu of delete.',
  'team/teamvisibility':
    'Private team is only visible to its team members, non private teams are visible to team members and to all colleagues in the organization',
  'teamevents/update/status':
    'Use Cancelled when you want to preserve information but cancel event. You can delete event or use Archived in lieu of delete.',
  'teamevents/visibility':
    'Private events are only visible to authorized users. Public Events are visible to anyone with a link to the page.',
  'teamevents/restrict/visibility':
    'This information is never public regardless of event visiblity. It is only visible to team members and subscribers.',
  'company/config/panel/personalInfo':
    'Contains information about the profile like name, title, photo, suffix, preferred pronoun, etc. Full name, photo, job title and organization name are always visible unless all profiles are marked are marked to not be visible outside company.',
  'company/config/panel/privateInfo':
    'Contains sensitive contact information like email, telephone, office address, etc.',
  'company/config/panel/events': 'Shows user`s personal and team events',
  'company/config/panel/leisures':
    'Shows books, tvshows, podcasts, teams and likes entered by the user',
  'company/config/panel/links': 'Allows user`s to share external links',
  'company/config/panel/comments':
    'Allows user`s to add freeflow information about their current role in the organization',
  'company/config/panel/contactPreferences':
    'Shows user`s preferences for contacting them',
  'company/config/panel/officeHours':
    'Shows user`s office hours for each day of the week and if they are remote on that day',
  'company/config/panel/projectTeams':
    'Shows the teams that user are part of. Project Teams information is only available for internal colleagues within the organization.',
  'company/config/panel/mentoring':
    'Shows mentoring information. Mentoring information is only available for internal colleagues within the organization.',

  'company/event/visibility':
    'An internal company event is only visible to people within your organization. On the other hand, a public event is visible to anyone with a link to the event webpage.',

  'company/companyVisibility':
    'This option enables you to control what users see when they visit your company page. People outside your organization will never have access to any company information like private events, broadcasts, newsletters, admins, tokens, or configurations. If set to Private, individuals outside your organization will encounter an error page when they attempt to visit.Our recommendation is to keep it Public so that they can access basic information like name, social media links, address, etc. about your company. ',

  'redirect/url':
    'Redirected profile should belong to someone in the same organization as you.',
  'redirect/note/msg': 'Optional note for the person visiting your profile',

  'scheduling/pageName': 'Name of the meeting',
  'scheduling/meetingDuration': 'How long will each meeting be',
  'scheduling/location':
    'Location can be an address, meeting room #, phone number,  or a conference meeting URL',
  'scheduling/pageVisibilityType': 'Who is this booking intended for',
  'scheduling/schedulingType':
    "See 'Open Help' section below for more information",
  'form/schedulingpages/showTeamMembers':
    'Show list of team members on the booking page',
  'scheduling/expirationDate': 'Date when this booking will expire',
  'scheduling/maxBookingsAllowed':
    'Maximum bookings allowed against this booking page',

  'scheduling/buttonText':
    'The text of button on meeting form e.g. Make Appointment, Let us meet, etc.',
  'scheduling/autoSchedule':
    'Auto Schedule makes it easy to find shared availability between you and the visitor. Since your account is already connected to our application the visitor only needs to share their free or busy information from their calendar. Scheduler automatically finds times that are available for both parties.',
  'scheduling/thankYouMessage':
    'The text that appears after visitor creates the meeting',
  'scheduling/teamMembersList':
    'Access is restricted to members with integrated calendars only. Team members with integrated calendars have been automatically added.',
  'team/teambooking/help':
    'Use Team Bookings to schedule meetings with attendees whose calendars are not shared with you. You can also use it for scheduling support calls or open office hours.',

  'home/network/updates/visible':
    'Shows updates in last 7 days. Only shown for people within your organization.',

  'home/network/events/visible': 'Shows events in next 7 days.',
  'home/teams/broadcasts/visible':
    'Shows maximum of 3 broadcasts published in last 12 hours.',
  'home/teams/events/visible':
    'Shows events scheduled from yesterday till 7 days from today.',
  'home/teams/recurringevents/visible':
    'Shows current recurring events scheduled to end for upto 6 months from today.',
  'home/teams/bulletins/visible':
    'Shows team bulletins published in last 2 weeks.',
  'home/teams/updates/visible': 'Shows maximum of 3 latest updates per team.',

  'home/company/broadcasts/visible':
    'Shows company broadcasts published in last 5 days.',

  'home/company/events/visible':
    'Shows events that have end date in next 3 weeks.',

  'home/company/newsletters/visible':
    'Shows newsletters updated in last 10 days.',
}

// Ideally tooltips is whenever B-tooltip is used.
// showTooltipsFromCode()
