<template>
  <div class="error-content">
    <synpanel :title="title">
      <img
        v-if="imageSrc"
        :src="imageSrc"
        :alt="imageAlt"
        class="error-content__image"
      />
      <div class="error-content__body">
        <slot />
      </div>
    </synpanel>
  </div>
</template>

<script>
import synpanel from '@/components/SynPanel'

export default {
  name: 'ErrorContent',
  components: { synpanel },
  props: {
    title: {
      type: String,
      default: 'Error',
    },
    imageSrc: {
      type: String,
      default: null,
    },
    imageAlt: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.error-content {
  margin: 0 auto;
  &__image {
    height: 240px;
  }
  .synpanel:hover {
    box-shadow: none !important;
  }
}
</style>
