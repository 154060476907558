export default {
  // // /* set boolean */

  setProfileUserAppConfig: (state, panelsConfig) =>
    (state.profileUserPanelsConfig = panelsConfig),

  setMergedAppConfig: (state, panelsConfig) =>
    (state.mergedPanelsConfig = panelsConfig),

  setProfileUserSchedulerDefaults: (state, value) =>
    (state.profileUserSchedulerDefaults = value),

  updateProfileUserAppConfigState: (state, panelsConfig) => {
    state.profileUserPanelsConfig[panelsConfig.name] = panelsConfig.value
  },
}
