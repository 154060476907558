<template>
  <section class="view-company-locations">
    <skeleton v-if="isLoading"></skeleton>
    <SiynCollapse
      v-if="companyLocations || isUserCompanyAdmin"
      :title="panelTitle"
      emoji="🏢"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <div
        v-if="!isEmpty(companyLocations)"
        class="columns is-multiline is-gapless"
      >
        <div
          v-for="location in companyLocations"
          :key="location.id"
          :class="{
            column: true,
            'is-4': companyLocations.length <= 3,
            'is-3': companyLocations.length > 3,
          }"
        >
          <address-item :address="location?.address || location"></address-item>
        </div>
      </div>
      <span v-else class="ml-4 empty-content">{{
        showUserMessageFromCode('generic/none')
      }}</span>
      <div
        v-if="isUserCompanyAdmin"
        class="has-text-center-touch has-text-right"
      >
        <b-dropdown
          :triggers="['hover']"
          aria-role="menu"
          :mobile-modal="false"
          position="is-bottom-left"
          class="has-text-left"
        >
          <template #trigger>
            <b-button
              :label="`${showLabelsFromCode(
                'company/locations/panelTitle',
              )} ${showLabelsFromCode('generic/menu')}`"
              type="is-primary"
              class="view-company-locations__button background-color-override"
              icon-right="caret-down"
              icon-pack="fas"
            />
          </template>
          <b-dropdown-item aria-role="menuitem" @click="openAddLocationsModel()"
            ><b-icon type="is-info" icon="plus-square" pack="fas" />{{
              showLabelsFromCode('generic/add')
            }}
            {{
              showLabelsFromCode('company/locations/location')
            }}</b-dropdown-item
          >
          <b-dropdown-item
            aria-role="menuitem"
            @click="openDeleteLocationsModal()"
            ><b-icon type="is-info" icon="trash-alt" pack="fas" />{{
              showLabelsFromCode('generic/delete')
            }}
            {{
              showLabelsFromCode('company/locations/location')
            }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </SiynCollapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations, mapActions } from 'vuex'
import { viewCompanyMixin } from '@/mixins/company/index'
import AddressItem from '@/components/AddressItem.vue'
import AddCompanyLocationFormModal from '@/views/company/locations/AddCompanyLocationFormModal.vue'
import EditCompanyLocationsModal from '@/views/company/locations/EditCompanyLocationsModal.vue'

export default {
  name: 'CompanyLocations',
  components: { AddressItem },
  mixins: [viewCompanyMixin],
  data() {
    return {
      isLoading: true,
      panelTitle: this.showLabelsFromCode('company/locations/panelTitle'),
      panelname: 'company_locations',
    }
  },
  computed: {
    ...mapState('company', ['companyLocations']),
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('company', ['setCompanyToEdit']),
    ...mapActions('company', ['addCompanyUpdate']),

    openAddLocationsModel() {
      this.setCompanyToEdit(this.companyToView)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: AddCompanyLocationFormModal,
        events: {
          added_successfully: (companyLocation) => {
            this.handleSuccessMessage('Location added successfully!')
            // console.log(JSON.stringify(companyLocation))
            const updateRecord = {
              action: 'added',
              createdBy: this.getTinyUser,
              message:
                companyLocation.addressObject?.address?.freeformAddress || '',
              module: 'company-location',
              type: 'company',
              priority: 3,
              company: {
                id: this.companyToView.id,
                name: this.companyToView.companyName,
              },
            }
            this.addCompanyUpdate({
              updateRecord,
              companyId: this.getAuthUserCompanyId,
            }).catch((err) => {
              this.trackExceptionEvent(
                'companyupdate_add_failed',
                err.message,
                false,
              )
            })
          },
          trapFocus: true,
        },
      })
    },
    openDeleteLocationsModal() {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditCompanyLocationsModal,
        events: {
          remove_successful: () => {
            const updateRecord = {
              action: 'deleted',
              createdBy: this.getTinyUser,
              message: '',
              module: 'company-location',
              type: 'company',
              priority: 1,
              company: {
                id: this.companyToView.id,
                name: this.companyToView.companyName,
              },
            }
            this.addCompanyUpdate({
              updateRecord,
              companyId: this.getAuthUserCompanyId,
            }).catch((err) => {
              this.trackExceptionEvent(
                'companyupdate_delete_failed',
                err.message,
                false,
              )
            })
            this.handleSuccessMessage('Locations deleted successfully!')
          },
          trapFocus: true,
        },
      })
    },
  },
}
</script>
