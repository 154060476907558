import { mapState, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import first from 'lodash/first'
import Error from '@/components/partials/Error'
import FullPage from '@/components/partials/FullPage'
import Skeleton from '@/components/partials/Skeleton'
import SiynCollapse from '@/components/SiynCollapse'

import {
  showUserMessageFromCode,
  showLabelsFromCode,
  showTooltipsFromCode,
  humanReadableDateTime,
  getNameForId,
  stripNonDigit,
  collapseHelper,
  formatDateTime,
  renderFromToDates,
  momentAgo,
  renderConferenceProvider,
} from '@/misc/helpers'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'
import {
  trackExperienceEvent,
  trackExceptionEvent,
  trackEngagementEvent,
  trackConversionEvent,
} from '@/misc/analytics'

const viewCompanyMixin = {
  components: {
    Error,
    FullPage,
    Skeleton,
    SiynCollapse,
  },
  filters: {},
  data() {
    return {
      showError: false,
      isPanelVisible: false,
      isLoading: true,
    }
  },
  computed: {
    ...mapState('user', ['user', 'fetchingUser']),
    ...mapState('app', ['appTitle']),
    ...mapState('appconfig', [
      'profileUserPanelsConfig',
      'companyPanelsConfig',
      'mergedPanelsConfig',
    ]),
    ...mapState('company', [
      'companyPanelsConfig',
      'companyToView',
      'companyErrors',
      'isUserCompanyAdmin',
      'isUserInSameCompany',
      'companyAdmins',
      'companyEventToView',
    ]),
    ...mapState('networkStatus', ['network']),
    ...mapGetters('networkStatus', ['areWeConnected']),
    ...mapState('app', [
      'criticalAppError',
      'hardError',
      'warnError',
      'appURL',
      'defaultBGColor',
      'defaultIconColor',
      'defaultPrimaryColor',
      'backgroundColor',
    ]),
    ...mapGetters('user', [
      'isUserLoggedIn',
      'isThisMyProfile',
      'getAuthUserId',
      'getAuthUserCompanyId',
      'getAuthUserLicenseType',
      'getAuthUserRole',
      'isUserInSameCompany',
      'getTinyUser',
    ]),
  },
  methods: {
    showLabelsFromCode,
    showTooltipsFromCode,
    showUserMessageFromCode,
    handleErrorMessage,
    handleSuccessMessage,
    trackExperienceEvent,
    trackExceptionEvent,
    trackEngagementEvent,
    trackConversionEvent,
    getNameForId,
    isEmpty,
    first,
    stripNonDigit,
    collapseHelper,
    humanReadableDateTime,
    momentAgo,
    formatDateTime,
    renderFromToDates,
    renderConferenceProvider,
    setAltImg(e) {
      e.target.onerror = null
      e.target.src = this.noCoverImage
    },
  },
}

export default viewCompanyMixin
