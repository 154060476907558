// import { find } from 'lodash'
import { teamMemberStatusConstants } from '@/data/teams/team-member-status'

export default {
  /**
   * Get  by id
    
  getBookFromStateByBookId: (state) => (bookId) =>
    find(state.books, (books) => books.id === bookId),
    */

  getApprovedSendTeam: (state) =>
    state.activeUserTeams.filter(
      (team) =>
        team.memberStatus === teamMemberStatusConstants.REQUEST_APPROVED ||
        team.memberStatus === teamMemberStatusConstants.REQUEST_SEND,
    ),

  getApprovedSubscribedTeam: (state) =>
    state.activeUserTeams.filter(
      (team) =>
        team.memberStatus === teamMemberStatusConstants.REQUEST_APPROVED ||
        team.memberStatus === teamMemberStatusConstants.SUBSCRIBED,
    ),
}
