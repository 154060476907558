/* eslint-disable import/extensions */
import state from './scheduling.state'
import mutations from './scheduling.mutations'
import actions from './scheduling.actions'
import getters from './scheduling.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
