/* eslint-disable no-underscore-dangle */

import isNil from 'lodash/isNil'
import keys from 'lodash/keys'
import cloneDeep from 'lodash/cloneDeep'

import {
  serverTimestamp as FirebaseServerTS,
  addDoc,
  collection,
  deleteDoc,
  updateDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
  connectFirestoreEmulator,
  Timestamp,
  limit,
} from 'firebase/firestore'
import { db } from './initFirebase'

// import firestore from './async-firestore'

const serverTimestamp = FirebaseServerTS()

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}

export default class GenericDB {
  constructor(collectionPath) {
    this.collectionPath = collectionPath
  }

  /**
   * Create a document in the collection
   * @param data
   * @param id
   */
  async create(data, id = null) {
    const collectionRef = collection(db, this.collectionPath)

    const dataToCreate = {
      ...data,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp,
    }
    // console.log(JSON.stringify(dataToCreate))
    let response
    if (isNil(id)) {
      response = await addDoc(collectionRef, { ...dataToCreate })
        .then((document) => {
          return {
            id: document.id,
            ...data,
            createTimestamp: serverTimestamp,
            updateTimestamp: serverTimestamp,
          }
        })
        .catch((error) => {
          console.log(
            `Collection: ${
              this.collectionPath
            } . Error in add : ${JSON.stringify(error)}`,
          )
          throw error
        })
    } else {
      response = await setDoc(doc(collectionRef, id), { ...dataToCreate })
        .then(() => {
          return {
            id,
            ...data,
            createTimestamp: serverTimestamp,
            updateTimestamp: serverTimestamp,
          }
        })
        .catch((error) => {
          console.log(
            `Collection: ${
              this.collectionPath
            } . Error in set : ${JSON.stringify(error)}`,
          )
          throw error
        })
    }
    return response
  }

  async read(id) {
    // console.log(JSON.stringify(db))
    // console.log(JSON.stringify(id))
    // console.log(JSON.stringify(this.collectionPath))
    const collectionRef = this.collectionPath
    const docRef = doc(db, collectionRef, id)

    // console.log(JSON.stringify(collectionRef))
    // console.log(JSON.stringify(docRef))

    const result = await getDoc(docRef).catch((error) => {
      console.log(
        `Collection: ${this.collectionPath} . Error in read: ${JSON.stringify(
          error,
        )}`,
      )
      // console.log(JSON.stringify(query._delegate._query._t.ft))
      throw error
    })

    let data = result.exists() ? result.data() : null

    if (isNil(data)) return null

    data = this.convertObjectTimestampPropertiesToDate(data)
    //  console.log(JSON.stringify(data))
    return { id, ...data }
  }

  // /**
  //  * Read a document in the collection
  //  * @param id
  //  */
  // async readOld(id) {
  //   await (
  //     await firestore()
  //   )
  //     .collection(this.collectionPath)
  //     .doc(id)
  //     .get()
  //     .then((doc) => {
  //       const data = doc.exists ? doc.data() : null
  //       if (isNil(data)) return null
  //       this.convertObjectTimestampPropertiesToDate(data)
  //       return { id, ...data }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       console.log(
  //         `Error in read for '${this.collectionPath}' Reason - ${error.code}`
  //       )
  //       throw error
  //     })
  // }

  /**
   * Read all documents in the collection following constraints
   * @param constraints
   * returns array of objects
   */
  async readAll(constraints = null) {
    const collectionRef = collection(db, this.collectionPath)
    // console.log(JSON.stringify(this.collectionPath))
    let q = query(collectionRef)
    // console.log(constraints)
    if (constraints) {
      constraints.forEach((constraint) => {
        // console.log(constraint)
        q = query(q, where(...constraint))
        // console.log(query)
      })
    }

    // console.log(constraints)
    const formatResult = (result) =>
      result.docs.map((ref) =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data(),
        }),
      )
    // console.log(formatResult)
    // console.log(query.get().then(formatResult))
    return getDocs(q)
      .then(formatResult)
      .catch((error) => {
        console.log(
          `Collection: ${
            this.collectionPath
          } . Error in readAll: ${JSON.stringify(error)}`,
        )
        // console.log(JSON.stringify(query._delegate._query._t.ft))
        throw error
      })
  }

  /**
   * Read all documents in the collection following constraints
   * @param orderBy, Limit
   * returns an array of objects
   */
  async readAllWithOrderByLimit(
    constraints = null,
    orderBys = null,
    limitNumber = null,
  ) {
    const collectionRef = collection(db, this.collectionPath)
    // console.log(JSON.stringify(this.collectionPath))
    let q = query(collectionRef)
    // console.log(`Constraints are ${constraints}`)
    // console.log(`OrderBy field is ${orderBys}`)
    // console.log(`Limit field is ${limit}`)
    if (constraints && constraints.length > 0) {
      constraints.forEach((constraint) => {
        // console.log(constraint)
        q = query(q, where(...constraint))
        // console.log(query)
      })
    }
    if (orderBys && orderBys.length > 0) {
      orderBys.forEach((orderByAttribute) => {
        // console.log(orderBy)
        q = query(q, orderBy(...orderByAttribute))
        // console.log(query)
      })
    }
    if (limitNumber) {
      q = query(q, limit(limitNumber))
      // console.log(query)
    }

    // console.log(constraints)
    const formatResult = (result) =>
      result.docs.map((ref) =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data(),
        }),
      )

    return getDocs(q)
      .then(formatResult)
      .catch((error) => {
        // console.log(error)
        console.log(
          `Collection: ${
            this.collectionPath
          } . Error in readAllWithOrderByLimit: ${JSON.stringify(error)}`,
        )
        //  console.log(JSON.stringify(error + this.collectionPath))
        // eslint-disable-next-line no-underscore-dangle
        // console.log(JSON.stringify(query._delegate._query._t.ft))
        throw error.code
      })
  }

  /**
   * Update a document in the collection
   * @param data
   */
  async update(data) {
    // console.log(JSON.stringify(data))
    const collectionRef = this.collectionPath
    const { id } = data // This is object destructing and is same as const id = data.id
    const clonedData = cloneDeep(data)
    delete clonedData.id

    const docRef = doc(db, collectionRef, id)
    // console.log(JSON.stringify(docRef))
    return updateDoc(docRef, {
      ...clonedData,
      updateTimestamp: serverTimestamp,
    })
      .then(() => {
        // console.log(JSON.stringify(data))
        return {
          ...data,
          updateTimestamp: new Date(),
        }
      })
      .catch((error) => {
        console.log(JSON.stringify(data))
        console.log(
          `Collection: ${
            this.collectionPath
          } . Error in update: ${JSON.stringify(error)}`,
        )
        throw error
      })
  }

  /**
   * Delete a document in the collection
   * @param id
   */
  async delete(id) {
    const collectionRef = this.collectionPath
    const docRef = doc(db, collectionRef, id)

    return deleteDoc(docRef).catch((error) => {
      console.log(
        `Collection: ${this.collectionPath} . Error in delete: ${JSON.stringify(
          error,
        )}`,
      )
      throw error
    })
  }

  async createOrUpdate(payload) {
    let response
    if (!payload.id) {
      const payloadClone = { ...payload }
      delete payloadClone.id
      response = await this.create(payloadClone, null).catch((error) => {
        console.log(
          `Collection: ${
            this.collectionPath
          } . Error in createOrUpdate: ${JSON.stringify(error)}`,
        )
        throw error
      })
    } else {
      await this.update(payload).catch((error) => {
        console.log(console.log(error))
        throw error
      })
      response = payload
    }
    return response
  }

  /**
   * Convert all object Timestamp properties to date
   * @param obj
   */
  convertObjectTimestampPropertiesToDate(obj) {
    const newObj = {}

    keys(obj)
      .filter((prop) => obj[prop] instanceof Object)
      .forEach((prop) => {
        if (obj[prop] instanceof Timestamp) {
          newObj[prop] = obj[prop].toDate()
        } else {
          this.convertObjectTimestampPropertiesToDate(obj[prop])
        }
      })

    return {
      ...obj,
      ...newObj,
    }
  }
}
/*   read = (id) => {
    return new Promise((resolve, reject) => {
      console.log(id)
      try {
        const dbRef = firestore().collection(this.collectionPath).doc(id)
        const doc = dbRef
          .get()
          .then((result) => {
            console.log(JSON.stringify(result))
          })
          .catch((err) => {
            console.log(err)
            reject(new Error(err))
          })
        let data = doc.data()
        data = this.convertObjectTimestampPropertiesToDate(data)
        console.log(JSON.stringify(data))
        resolve({ id, ...data })
      } catch (error) {
        console.log(error)
        reject(new Error(error))
      }
    })
  } */
