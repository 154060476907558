import viewProfileMixin from './viewProfileMixin'
import editProfileMixin from './editProfileMixin'
import store from '@/store'

function initializeProfileState() {
  store.commit('profileUser/setProfileUser', null, { root: true })
  store.commit('appconfig/setProfileUserAppConfig', {}, { root: true })
  store.commit('appconfig/setMergedAppConfig', {}, { root: true })
  store.commit('privateinfo/setShowPrivateInfoPanel', null, { root: true })
  store.commit('privateinfo/setPrivateContactInfo', null, { root: true })
  store.commit('emailpreferences/setEmailPreferences', null, { root: true })
  store.commit('officehours/setOfficeHours', null, { root: true })
  store.commit('events/setEvents', null, { root: true })
  store.commit('events/setAllEvents', [], { root: true })
  store.commit('userTeams/setAllActiveUserTeams', null, { root: true })
  store.commit('links/setLinks', null, { root: true })
  store.commit('redirects/setRedirects', null, { root: true })
  store.commit('leisures/setLeisures', null, { root: true })
  store.commit('comments/setComments', null, { root: true })
  store.commit('company/setCompany', null, { root: true })
  store.commit('company/setCompanyAppConfig', {}, { root: true })

  store.commit('mentoring/setTopics', [], { root: true })
  store.commit('mentoring/setMentoringTopicToView', null, { root: true })
  store.commit('mentoring/setMentoringTopicToEdit', null, { root: true })
  store.commit('app/resetAppErrors', null, { root: true })
}
export { viewProfileMixin, editProfileMixin, initializeProfileState }
