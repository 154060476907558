import { mapMutations, mapActions } from 'vuex'

import {
  formatDateTime,
  clickToMaps,
  clickToCall,
  clickToEmail,
  clickToChat,
  closeDialog,
} from '@/misc/helpers'

import { eventTypesValues, eventTypeConstants } from '@/data/eventTypes'

import teamEventTypes from '@/data/teams/team-event-types'

export const eventMixin = {
  data() {
    return {
      isLoading: true,
      eventTypesValues,
      eventTypeConstants,
      teamEventTypes,
      timePlaceholder: 'Select or type',
      minutesGranularity: 15,
      uid2: this.$route.params.uid,
    }
  },

  methods: {
    ...mapActions('events', ['deleteEvent']),
    ...mapMutations('privateinfo', [
      'setPrivateContactInfo',
      'setPrivateChatInfo',
    ]),
    formatDateTimeHere: formatDateTime,
    clickToMaps,
    clickToCall,
    clickToEmail,
    clickToChat,
    closeDialog,
  },
}

export default function editEventMixinDefault() {
  return true
}
