import GenericDB from '@/firebase/generic-db'

// import firestore from './async-firestore'

export default class UserEventsDB extends GenericDB {
  constructor(userId) {
    super(`users/${userId}/events`)
  }

  // Here you can extend UserEventsDB with custom methods
  /**
   * Read events of x days
   * @param endDays
   */
  /*   async readEvents(endDays = 30) {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    // console.log(this.collectionPath)
    let start = new Date()
    // To ensure it shows today's event, I had to reset time to 0 for current day
    start = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      0,
      0,
      0
    )
    const end = new Date()
    end.setDate(end.getDate() + endDays)

    const query = collectionRef
      .where('endDateTime', '>=', start)
      .where('endDateTime', '<=', end)
      .limit(30)

    const formatResult = (result) =>
      result.docs.map((ref) =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data(),
        })
      )
    // console.log(query.get().then(formatResult))
    return query.get().then(formatResult)
  } */
}
