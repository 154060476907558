var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"view-company-locations"},[(_vm.isLoading)?_c('skeleton'):_vm._e(),(_vm.companyLocations || _vm.isUserCompanyAdmin)?_c('SiynCollapse',{attrs:{"title":_vm.panelTitle,"emoji":"🏢","is-open":_vm.collapseHelper(_vm.panelname),"panelname":_vm.panelname}},[(!_vm.isEmpty(_vm.companyLocations))?_c('div',{staticClass:"columns is-multiline is-gapless"},_vm._l((_vm.companyLocations),function(location){return _c('div',{key:location.id,class:{
          column: true,
          'is-4': _vm.companyLocations.length <= 3,
          'is-3': _vm.companyLocations.length > 3,
        }},[_c('address-item',{attrs:{"address":location?.address || location}})],1)}),0):_c('span',{staticClass:"ml-4 empty-content"},[_vm._v(_vm._s(_vm.showUserMessageFromCode('generic/none')))]),(_vm.isUserCompanyAdmin)?_c('div',{staticClass:"has-text-center-touch has-text-right"},[_c('b-dropdown',{staticClass:"has-text-left",attrs:{"triggers":['hover'],"aria-role":"menu","mobile-modal":false,"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{staticClass:"view-company-locations__button background-color-override",attrs:{"label":`${_vm.showLabelsFromCode(
              'company/locations/panelTitle',
            )} ${_vm.showLabelsFromCode('generic/menu')}`,"type":"is-primary","icon-right":"caret-down","icon-pack":"fas"}})]},proxy:true}],null,false,1126619822)},[_c('b-dropdown-item',{attrs:{"aria-role":"menuitem"},on:{"click":function($event){return _vm.openAddLocationsModel()}}},[_c('b-icon',{attrs:{"type":"is-info","icon":"plus-square","pack":"fas"}}),_vm._v(_vm._s(_vm.showLabelsFromCode('generic/add'))+" "+_vm._s(_vm.showLabelsFromCode('company/locations/location')))],1),_c('b-dropdown-item',{attrs:{"aria-role":"menuitem"},on:{"click":function($event){return _vm.openDeleteLocationsModal()}}},[_c('b-icon',{attrs:{"type":"is-info","icon":"trash-alt","pack":"fas"}}),_vm._v(_vm._s(_vm.showLabelsFromCode('generic/delete'))+" "+_vm._s(_vm.showLabelsFromCode('company/locations/location')))],1)],1)],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }