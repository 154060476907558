export default {
  /* COMPANY */
  setCompany: (state, company) => (state.company = company),

  setCompanyToView: (state, companyToView) =>
    (state.companyToView = companyToView),

  setCompanyToEdit: (state, companyToEdit) =>
    (state.companyToEdit = companyToEdit),

  updateStateCompanyLogoPhoto: (state, value) =>
    (state.company.logoImageURL = value),

  setIsUserInSameCompany: (state, isUserInSameCompany) =>
    (state.isUserInSameCompany = isUserInSameCompany),

  /** *************** ****************  COMPANY NETWORK **************** **************** */

  setCompanyNetwork: (state, companyNetwork) =>
    (state.companyNetwork = companyNetwork),

  /* **************** **************** COMPANY LOCATIONS**************** ****************  */
  setCompanyLocations: (state, companyLocations) => {
    if (state.companyLocations.length === 0) {
      companyLocations.forEach((location) => {
        state.companyLocations.push({
          address:
            location.addressObject?.address?.freeformAddress ||
            location.addressObject,
          id: location.id,
        })
      })
    }
  },

  addToCompanyLocations: (state, companyLocations) =>
    state.companyLocations.push(companyLocations),

  setLocationUpdate(state, companyLocation) {
    const index = state.companyLocations.findIndex(
      (location) => location.id === companyLocation.id,
    )
    if (index !== -1) {
      Object.assign(state.companyLocation[index], companyLocation)
      // console.log(JSON.stringify(state.events[index]))
    }
  },
  removeCompanyLocationById: (state, locationId) => {
    const index = state.companyLocations.findIndex(
      (location) => location.id === locationId,
    )
    state.companyLocations.splice(index, 1)
  },
  /* COMPANY ADMINS */
  removeCompanyAdminById: (state, companyAdminId) => {
    const index = state.companyAdmins.findIndex(
      (admin) => admin.id === companyAdminId,
    )
    state.companyAdmins.splice(index, 1)
  },
  setCompanyAdmins: (state, companyAdmins) =>
    (state.companyAdmins = companyAdmins),

  addToCompanyAdmin: (state, companyAdmin) =>
    state.companyAdmins.push(companyAdmin),

  setIsUserCompanyAdmin: (state, isUserCompanyAdmin) =>
    (state.isUserCompanyAdmin = isUserCompanyAdmin),
  /* COMPANY ERROR */
  setCompanyNotFound: (state, companyNotFound) =>
    (state.companyErrors.companyNotFound = companyNotFound),

  setCompanyEventNotFound: (state, companyEventNotFound) =>
    (state.companyErrors.companyEventNotFound = companyEventNotFound),

  setCompanyAdminsNotFound: (state, companyAdminsNotFound) =>
    (state.companyErrors.noCompanyAdmins = companyAdminsNotFound),

  setCompanyUnknownError: (state, unknownError) =>
    (state.companyErrors.unknown = unknownError),

  /** **************** COMPANY EVENTS ************************* */

  setCompanyEvents: (state, companyEvents) =>
    (state.companyEvents = companyEvents),

  addCompanyEvents: (state, companyEvent) =>
    state.companyEvents.push(companyEvent),

  setCompanyEventToView: (state, companyEventToView) => {
    state.companyEventToView = companyEventToView
  },

  setCompanyEventToEdit: (state, companyEventToEdit) =>
    (state.companyEventToEdit = companyEventToEdit),

  addToCompanyEvent: (state, companyEvent) =>
    state.companyEvents.push(companyEvent),

  removeCompanyEventById: (state, companyEventId) => {
    const index = state.companyEvents.findIndex(
      (event) => event.id === companyEventId,
    )
    state.companyEvents.splice(index, 1)
  },

  setCompanyEventUpdate(state, companyEvent) {
    const index = state.companyEvents.findIndex(
      (event) => event.id === companyEvent.id,
    )
    if (index !== -1) {
      Object.assign(state.companyEvents[index], companyEvent)
    }
  },

  addAttendeeToCompanyEvent: (state, user) =>
    state.companyEventToView.attendees.push(user),

  removeAttendeeFromCompanyEvent: (state, user) => {
    const index = state.companyEventToView.attendees.findIndex(
      (attendee) => attendee.id === user.id,
    )
    state.companyEventToView.attendees.splice(index, 1)
  },

  setUnpublishedCompanyEvents: (state, unpublishedEvents) =>
    (state.unpublishedEvents = unpublishedEvents),

  addToUnpublishedCompanyEvents: (state, unpublishedEvent) =>
    state.unpublishedEvents.push(unpublishedEvent),

  removeUnpublishedEventById: (state, unpublishedEventId) => {
    const index = state.unpublishedEvents.findIndex(
      (event) => event.id === unpublishedEventId,
    )
    state.unpublishedEvents.splice(index, 1)
  },

  setUnpublishedEventUpdate(state, unpublishedEvent) {
    const index = state.unpublishedEvents.findIndex(
      (event) => event.id === unpublishedEvent.id,
    )
    if (index !== -1) {
      Object.assign(state.unpublishedEvents[index], unpublishedEvent)
    }
  },

  setCompanyEventStatus: (state, companyStatus) =>
    (state.companyEventToEdit.eventStatus = companyStatus),

  deleteSpeakerFromCompanyEvent: (state) =>
    (state.companyEventToView.speaker = null),

  /** **************** COMPANY APP CONFIG ******************* */
  setCompanyAppConfig: (state, value) => (state.companyPanelsConfig = value),
  setCompanyPanelsConfigToEdit: (state, value) =>
    (state.companyPanelsConfigToEdit = value),

  /** **************** COMPANY NEWSLETTERS ************************* */

  setCompanyNewsletters: (state, companyNewsletters) =>
    (state.companyNewsletters = companyNewsletters),

  setCompanyNewsletterToView: (state, companyNewsletterToView) => {
    state.companyNewsletterToView = companyNewsletterToView
  },

  setCompanyNewsletterToEdit: (state, companyNewsletterToEdit) =>
    (state.companyNewsletterToEdit = companyNewsletterToEdit),

  addToCompanyNewsletters: (state, companyNewsletter) =>
    state.companyNewsletters.push(companyNewsletter),

  setCompanyNewsletterUpdate(state, companyNewsletter) {
    const index = state.companyNewsletters.findIndex(
      (letter) => letter.id === companyNewsletter.id,
    )
    if (index !== -1) {
      Object.assign(state.companyNewsletters[index], companyNewsletter)
    }
  },

  removeNewsletterAdminInCache: (state, newsletterAdminId) => {
    const index = state.companyToView.newsletterAdmins.findIndex(
      (admin) => admin.id === newsletterAdminId,
    )
    state.companyToView.newsletterAdmins.splice(index, 1)
  },

  /* ******************* COMPANY UPDATES ****************** */
  setCompanyUpdates: (state, value) => (state.companyUpdates = value),
  addToCompanyUpdates: (state, value) => state.companyUpdates.push(value),

  /* ******************* COMPANY BROADCASTS ****************** */

  setCompanyBroadcasts: (state, companyBroadcasts) =>
    (state.companyBroadcasts = companyBroadcasts),

  addToCompanyBroadcast: (state, companyBroadcast) =>
    state.companyBroadcasts.push(companyBroadcast),

  setCompanyBroadcastUpdate(state, companyBroadcast) {
    const index = state.companyBroadcasts.findIndex(
      (item) => item.id === companyBroadcast.id,
    )
    if (index !== -1) {
      state.companyBroadcasts[index].text = companyBroadcast.text
    }
  },

  setCompanyBroadcastToRemove(state, companyBroadcastId) {
    const index = state.companyBroadcasts.findIndex(
      (item) => item.id === companyBroadcastId,
    )
    if (index !== -1) {
      state.companyBroadcasts.splice(index, 1)
    }
  },

  setCompanyBroadcastToView: (state, companyBroadcastToView) =>
    (state.companyBroadcastToView = companyBroadcastToView),

  setCompanyBroadcastToEdit: (state, companyBroadcastToEdit) =>
    (state.companyBroadcastToEdit = companyBroadcastToEdit),

  /** **************** COMPANY TOKENS ************************* */

  setCompanyTokens: (state, companyTokens) =>
    (state.companyTokens = companyTokens),

  setCompanyTokenToView: (state, companyTokenToView) => {
    state.companyTokenToView = companyTokenToView
  },

  addToCompanyTokens: (state, companyToken) =>
    state.companyTokens.push(companyToken),
}
