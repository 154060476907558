<template>
  <div class="edit-meeting-notes">
    <b-loading
      v-model="isLoading"
      :is-full-page="false"
      :can-cancel="false"
    ></b-loading>
    <Synform
      :title="formTitle"
      :operation="editAISummaryVue"
      :button-text="formTitle"
      :show-second-button="false"
      :modal-form="true"
    >
      <input v-model.trim="form.id" type="hidden" name="id" />
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="summarizedText"
        rules="required|min:10|max:10000"
      >
        <b-field
          class="required"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <tiptap-editor
            id="editor2"
            required
            :value="form.summarizedText"
            character-limit="10000"
            name="summarizedText"
            aria-placeholder="AI Summarized Text"
            @input="tipTapValue($event)"
          ></tiptap-editor>
        </b-field>
      </ValidationProvider>
    </Synform>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
/* import/no-extraneous-dependencies */
import { mapActions, mapState, mapMutations } from 'vuex'
import TiptapEditor from '@/components/TiptapEditor'
import { editTeamsMixin } from '@/mixins/teams/index'

import { trackPageView, trackScreenView } from '@/misc/analytics'

export default {
  name: 'EditAISummaryMeetingNotes',
  components: {
    TiptapEditor,
  },
  mixins: [editTeamsMixin],
  props: {},
  data() {
    return {
      formTitle: `${this.showLabelsFromCode('generic/update')} AI Summary`,
      isLoading: true,
      form: {
        id: null,
        summarizedText: '',
      },
    }
  },
  metaInfo: {
    title: 'Edit AI Summary',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  computed: {
    ...mapState('teamsEvents', ['teamEventToView', 'teamMeetingNoteToEdit']),
  },

  created() {},
  mounted() {
    if (this.teamMeetingNoteToEdit) {
      this.form.id = this.teamMeetingNoteToEdit.id
      this.form.summarizedText = this.teamMeetingNoteToEdit.summarizedText
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions('teamsEvents', ['updateAISummaryAction']),
    ...mapMutations('teamsEvents', [
      'setTeamMeetingNoteUpdate',
      'setMeetingNotesUpdate',
    ]),
    tipTapValue($event) {
      this.form.summarizedText = $event
    },
    editAISummaryVue() {
      this.isLoading = true
      const meetingNotes = {
        ...this.teamMeetingNoteToEdit,
        summarizedText: this.form.summarizedText,
      }
      const teamUpdate = {
        team: {
          id: this.teamEventToView.team.id,
          name: this.teamEventToView.team.name,
        },
        action: this.notificationActionTypesConstants.UPDATED,
        type: 'AI Summary',
        emoji: '🤖',
        message: `For event ${this.teamEventToView.eventName}`,
        showOnDashboard: true,
        priority: 2,
      }
      this.updateAISummaryAction({
        meetingNotes,
        teamUpdate,
        teamEventToView: this.teamEventToView,
        user: this.user,
      })
        .then((meetingNoteResponse) => {
          this.setMeetingNotesUpdate(meetingNoteResponse.meetingNotes)
          this.$emit('update_successful')
          this.close()
        })
        .catch((err) => {
          console.log(err)
          this.$emit('update_failed')
          this.close()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
