/* eslint-disable import/extensions */
import state from './team-bulletins.state'
import mutations from './team-bulletins.mutations'
import actions from './team-bulletins.actions'
import getters from './team-bulletins.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
