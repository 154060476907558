var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"event-meeting-notes"},[_c('div',{staticClass:"view-event-meeting-notes",attrs:{"width-class":"is-12"}},[(_vm.isLoading)?_c('skeleton'):_vm._e(),(_vm.teamEventToView && !_vm.isLoading)?_c('SiynCollapse',{attrs:{"title":_vm.panelTitle,"is-open":_vm.collapseHelper(_vm.panelname),"panelname":_vm.panelname},scopedSlots:_vm._u([{key:"header-extras",fn:function(){return [_c('b-tooltip',{staticClass:"mx-4",attrs:{"multilined":"","type":"is-dark","label":_vm.showTooltipsFromCode('teamevents/restrict/visibility'),"position":"is-right"}},[_c('b-icon',{staticClass:"has-text-grey-label",attrs:{"pack":"fas","icon":"eye","size":"is-small"}})],1)]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"view-event-meeting-notes__wrapper px-4 py-2"},[(_vm.teamEventToView?.meetingNotes)?_c('table',{staticClass:"table is-striped is-narrow is-fullwidth"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Meeting Notes")]),_c('th',[_vm._v("🤖 AI Summary")]),_c('th',[_vm._v("Author")]),_c('th',[_vm._v("Created")]),_c('th',[_vm._v("Action")])])]),_c('tbody',[_c('tr',[_c('td',[(_vm.teamEventToView?.meetingNotes)?_c('a',{attrs:{"label":"Meeting Notes"},on:{"click":function($event){return _vm.viewNotesModal(_vm.teamEventToView?.meetingNotes)}}},[_c('b-tag',{staticClass:"event-meeting-notes__tag"},[_c('b-icon',{attrs:{"pack":"far","icon":"eye","type":"is-dark","label":"View","size":"is-medium"}})],1)],1):_vm._e(),(
                      (_vm.teamEventToView?.meetingNotes &&
                        _vm.getAuthUserId ===
                          _vm.teamEventToView.notesCreatedBy.id) ||
                      _vm.isUserTeamAdminCache
                    )?_c('a',{attrs:{"label":"Original Notes"},on:{"click":function($event){return _vm.editNotesModal(_vm.teamEventToView)}}},[_c('b-tag',{staticClass:"event-meeting-notes__tag"},[_c('b-icon',{attrs:{"pack":"far","icon":"edit","type":"is-primary","label":"Edit","size":"is-medium"}})],1)],1):_vm._e(),_c('b-modal',{attrs:{"can-cancel":true},model:{value:(_vm.isNotesModalActive),callback:function ($$v) {_vm.isNotesModalActive=$$v},expression:"isNotesModalActive"}},[_c('SynPanel',{attrs:{"title":_vm.panelTitle}},[_c('p',[_c('b',[_vm._v("Created: ")]),_vm._v(_vm._s(_vm.momentAgo(_vm.teamEventToView.notesCreateTimestamp))+" "),_c('b',{staticClass:"ml-2"},[_vm._v("By: ")]),_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                            name: 'profile',
                            params: {
                              uid: _vm.teamEventToView.notesCreatedBy.id,
                            },
                          }}},[_vm._v(_vm._s(_vm.teamEventToView.notesCreatedBy.displayName))])],1),(_vm.teamEventToView?.notesUpdateTimestamp)?_c('p',[_c('b',[_vm._v("Last Updated: ")]),_vm._v(_vm._s(_vm.momentAgo(_vm.teamEventToView.notesUpdateTimestamp))+" "),_c('b',{staticClass:"ml-2"},[_vm._v("By: ")]),_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                            name: 'profile',
                            params: {
                              uid: _vm.teamEventToView.notesLastUpdatedBy.id,
                            },
                          }}},[_vm._v(_vm._s(_vm.teamEventToView.notesLastUpdatedBy.displayName))])],1):_vm._e(),(_vm.teamEventToView.notesImageURL)?_c('p',{staticClass:"my-2"},[_c('b',[_vm._v("Handwritten Notes Image: ")]),_c('a',{attrs:{"target":"_blank","href":_vm.teamEventToView.notesImageURL}},[_vm._v("Click to view")])]):_vm._e(),_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"white-space-preline event-meeting-notes__text sm-list-styles",domProps:{"innerHTML":_vm._s(_vm.removeEmptyPTags(_vm.getTempNotes))}})])],1)],1),_c('td',[(_vm.teamEventToView?.summarizedText)?_c('a',{attrs:{"label":"AI Summary"},on:{"click":function($event){return _vm.viewAISummaryModal(_vm.teamEventToView.summarizedText)}}},[_c('b-tag',{staticClass:"event-meeting-notes__tag"},[_c('b-icon',{attrs:{"pack":"far","icon":"eye","type":"is-dark","label":"View","size":"is-medium"}})],1)],1):_vm._e(),(
                      _vm.teamEventToView?.summarizedText &&
                      (_vm.getAuthUserId === _vm.teamEventToView?.notesCreatedBy.id ||
                        _vm.isUserTeamAdminCache)
                    )?_c('a',{attrs:{"label":"Edit Summary"},on:{"click":function($event){return _vm.editAISummaryModal(_vm.teamEventToView)}}},[_c('b-tag',{staticClass:"event-meeting-notes__tag"},[_c('b-icon',{attrs:{"pack":"far","icon":"edit","type":"is-primary","label":"Edit","size":"is-medium"}})],1)],1):_vm._e(),_c('b-modal',{attrs:{"can-cancel":true},model:{value:(_vm.isSummaryModalActive),callback:function ($$v) {_vm.isSummaryModalActive=$$v},expression:"isSummaryModalActive"}},[(_vm.tempSummarizedText)?_c('SynPanel',{attrs:{"title":"Notes Summarized by AI"}},[(_vm.teamEventToView?.summaryDateTime)?_c('p',[_c('b',[_vm._v("Created: ")]),_vm._v(_vm._s(_vm.momentAgo(_vm.teamEventToView?.summaryDateTime))+" "),_c('b',{staticClass:"ml-2"},[_vm._v("By Model: ")]),_c('span',[_vm._v(_vm._s(_vm.teamEventToView.summaryCreatedBy.displayName))])]):_vm._e(),(
                          _vm.teamEventToView?.summaryUpdateDateTime &&
                          _vm.teamEventToView?.summaryUpdatedBy
                        )?_c('p',[_c('b',[_vm._v("Last Updated: ")]),_vm._v(_vm._s(_vm.momentAgo(_vm.teamEventToView.summaryUpdateDateTime))+" "),_c('b',{staticClass:"ml-2"},[_vm._v("By: ")]),(_vm.teamEventToView.summaryUpdatedBy.id)?_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                            name: 'profile',
                            params: {
                              uid: _vm.teamEventToView.summaryUpdatedBy.id,
                            },
                          }}},[_vm._v(_vm._s(_vm.teamEventToView.summaryUpdatedBy.displayName))]):_c('span',[_vm._v(_vm._s(_vm.teamEventToView.summaryUpdatedBy.displayName))])],1):_vm._e(),(_vm.teamEventToView.notesImageURL)?_c('p',{staticClass:"my-2"},[_c('b',[_vm._v("Handwritten Notes Image: ")]),_c('a',{attrs:{"target":"_blank","href":_vm.teamEventToView.notesImageURL}},[_vm._v("Click to view")])]):_vm._e(),_c('hr',{staticClass:"my-2"}),(_vm.teamEventToView.summarizedText)?_c('div',{staticClass:"white-space-preline event-meeting-notes__text sm-list-styles",domProps:{"innerHTML":_vm._s(_vm.removeEmptyPTags(_vm.getTempSummarizedText))}}):_c('div',[_c('p',[_c('i',[_vm._v("No Summary found")])])])]):_vm._e()],1)],1),_c('td',[(_vm.teamEventToView.notesCreatedBy)?_c('span',[_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                        name: 'profile',
                        params: { uid: _vm.teamEventToView.notesCreatedBy.id },
                      }}},[_vm._v(_vm._s(_vm.teamEventToView.notesCreatedBy.displayName))])],1):_vm._e()]),_c('td',[(_vm.teamEventToView.notesCreateTimestamp)?_c('span',[_vm._v(" "+_vm._s(_vm.momentAgo(_vm.teamEventToView.notesCreateTimestamp))+" ")]):_vm._e()]),_c('td',[_c('a',{staticClass:"no-text-decoration",on:{"click":function($event){return _vm.openSideBySide(_vm.teamEventToView)}}},[_c('b-tooltip',{attrs:{"label":"View Notes & AI Summary Side By Side","position":"is-left","type":"is-dark"}},[_c('b-icon',{attrs:{"icon":"right-left","pack":"fas","type":"is-info","size":"is-medium"}})],1)],1),(
                      _vm.getAuthUserId === _vm.teamEventToView.notesCreatedBy.id ||
                      _vm.isUserTeamAdminCache
                    )?_c('a',{staticClass:"no-text-decoration ml-2",on:{"click":function($event){return _vm.deleteNotesVue(_vm.teamEventToView)}}},[_c('b-tooltip',{attrs:{"label":_vm.showLabelsFromCode('generic/delete'),"position":"is-left","type":"is-dark"}},[_c('b-icon',{attrs:{"icon":"trash-alt","pack":"fa","type":"is-danger","size":"is-medium"}})],1)],1):_vm._e()])])])]):_vm._e(),(
              (_vm.isUserTeamEventAttendee || _vm.isUserPartOfTeamCache) &&
              _vm.teamToView?.teamStatus == _vm.teamStatusConstants.ACTIVE
            )?_c('div',{staticClass:"event-meeting-notes__button-wrapper"},[_c('b-button',{staticClass:"event-meeting-notes__edit-button",attrs:{"type":"is-danger","disabled":!_vm.isEmpty(_vm.teamEventToView?.meetingNotes),"title":"Add Notes"},on:{"click":_vm.addMeetingNotes}},[_vm._v("Add Notes ")])],1):_vm._e()])]},proxy:true}],null,false,2393070085)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }