<template>
  <div class="box siyncollapse">
    <b-collapse
      ref="siyncollapse"
      :open="isOpen"
      class="card"
      animation="slide"
      @open="collapseOpened()"
      @close="collapseClosed()"
    >
      <template #trigger="props">
        <div
          class="siyncollapse__heading card-header"
          role="button"
          :aria-expanded="props.open"
        >
          <span class="siyncollapse__heading--title card-header-title">
            <span class="siyncollapse__heading--emoji">{{ emoji }}</span>
            <span class="siyncollapse__heading--emoji--paneltitle">{{
              title
            }}</span>

            <slot name="header-extras"></slot>
          </span>

          <a
            class="card-header-icon siyncollapse__heading--icon icon-color-override"
          >
            <b-icon pack="fas" :icon="props.open ? 'caret-down' : 'caret-up'">
            </b-icon>
          </a>
        </div>
      </template>
      <div class="siyncollapse__content card-content">
        <div class="content">
          <slot />
        </div>
      </div>
      <slot name="footer"> </slot>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { showUserMessageFromCode, setLocalStorage } from '@/misc/helpers'
import { handleSuccessMessage } from '@/misc/user-messages'

export default {
  name: 'SiynCollapse',
  props: {
    title: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    panelname: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
  },
  methods: {
    collapseOpened() {
      if (this.panelname) {
        setLocalStorage(`${this.panelname}`, 'open')
        handleSuccessMessage(
          showUserMessageFromCode('generic/preferencesaved/browser'),
        )
      }
    },
    collapseClosed() {
      if (this.panelname) {
        setLocalStorage(`${this.panelname}`, 'close')
        handleSuccessMessage(
          showUserMessageFromCode('generic/preferencesaved/browser'),
        )
      }
    },
  },
}
</script>
<style scoped lang="scss">
.box {
  padding: 0 !important;
}
</style>
