export default {
  teamBookings: [],
  teamBookingToView: undefined,
  teamBookingToEdit: undefined,
  teamBookingErrors: {
    bookingNotFound: false,
    bookingExpired: false,
    bookingIsPrivate: false,
    noMoreBookingsAvailable: false,
    noProfileFound: false,
    profileHasNoCalendarIntegrated: false,
  },
}
