var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"company-event-info"},[_c('div',{staticClass:"view-company-event-info",attrs:{"width-class":"is-12"}},[(_vm.isLoading)?_c('skeleton'):_vm._e(),(_vm.companyEventToView && !_vm.isLoading)?_c('SynPanel',[(_vm.companyEventToView?.eventImage)?_c('img',{staticClass:"view-company-event-info__eventImage",staticStyle:{"height":"auto","width":"100%","max-height":"450px"},attrs:{"src":_vm.companyEventToView?.eventImage,"alt":"Event Banner Image"}}):_vm._e(),_c('div',{staticClass:"columns is-multiline is-gapless is-vcentered mb-0"},[_c('div',{staticClass:"column is-9"},[_c('h1',{staticClass:"view-company-event-info__name"},[_vm._v(" "+_vm._s(_vm.companyEventToView.eventName)+" ")])]),(
            _vm.companyEventToView.eventStatus ===
            _vm.companyEventStatusConstants.CANCELLED
          )?_c('div',{staticClass:"column is-3 has-text-right"},[_c('b-field',[_c('b-tag',{staticClass:"br-6",attrs:{"size":"is-medium"}},[_vm._v("Cancelled")])],1)],1):_vm._e(),(_vm.user && _vm.isUserLoggedIn)?[(_vm.isPastEvent)?_c('div',{staticClass:"column is-3 has-text-right"},[_c('b-field',[_c('b-tag',{staticClass:"br-6",attrs:{"size":"is-medium"}},[_vm._v("Past Event")])],1)],1):(!_vm.isPastEvent)?_c('div',{staticClass:"column is-3 has-text-centered has-text-right-desktop"},[(
                _vm.isUserCompanyAdmin &&
                _vm.companyEventToView.eventStatus ===
                  _vm.companyEventStatusConstants.DRAFT
              )?_c('b-button',{staticClass:"mb-1",attrs:{"type":"is-info"},on:{"click":function($event){return _vm.gotoUnpublishedEventsScreen()}}},[_vm._v("🚀 Publish Event")]):_vm._e(),(
                _vm.isUserEventCreator &&
                _vm.companyEventToView.eventStatus !==
                  _vm.companyEventStatusConstants.CANCELLED
              )?_c('b-button',{staticClass:"view-company-event-info__editbutton",attrs:{"type":"is-danger","icon-left":"edit","pack":"far"},on:{"click":function($event){return _vm.goToEditCompanyEvent()}}},[_vm._v(" Edit Event ")]):(_vm.isUserAttending)?_c('b-button',{staticClass:"view-company-event-info__editbutton",attrs:{"type":"is-warning","icon-left":"user-minus","pack":"fas"},on:{"click":function($event){return _vm.unAttendTheEventVue()}}},[_vm._v(" Un Attend ")]):(
                !_vm.isUserAttending &&
                _vm.companyEventToView.eventStatus ===
                  _vm.companyEventStatusConstants.SCHEDULED
              )?_c('b-button',{staticClass:"view-company-event-info__editbutton",attrs:{"type":"is-info","icon-left":"user-plus","pack":"fas"},on:{"click":function($event){return _vm.attendTheEventVue()}}},[_vm._v(" Attend ")]):_vm._e(),(
                _vm.companyEventToView.eventStatus ===
                _vm.companyEventStatusConstants.SCHEDULED
              )?_c('b-button',{staticClass:"ml-2 view-company-event-info__editbutton",attrs:{"title":"Share event","type":"is-info","icon-left":"share-from-square","pack":"fas"},on:{"click":function($event){return _vm.openShareModal()}}}):_vm._e()],1):_vm._e()]:[_c('div',{staticClass:"column is-3 has-text-centered has-text-right-desktop"},[_c('b-button',{staticClass:"view-company-event-info__editbutton",attrs:{"type":"is-info","icon-left":"user-plus","pack":"fas"},on:{"click":function($event){return _vm.registerForEvent()}}},[_vm._v(" Register ")]),_c('b-button',{staticClass:"ml-2 view-company-event-info__editbutton",attrs:{"title":"Share event","type":"is-info","icon-left":"share-from-square","pack":"fas"},on:{"click":function($event){return _vm.openShareModal()}}})],1)],(_vm.companyEventToView.eventDescription)?_c('div',{staticClass:"column is-12"},[_c('p',{staticClass:"has-text-centered has-text-left-desktop view-company-event-info__description"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.companyEventToView?.eventDescription)}})])]):_vm._e()],2),_c('hr',{staticClass:"my-4"}),_c('div',{staticClass:"columns is-vcentered is-gapless mb-0 my-2 is-multiline"},[(_vm.companyEventToView.speaker)?_c('div',{staticClass:"column is-2"},[_c('span',{staticClass:"has-text-weight-semibold"},[_vm._v("Event By / Speaker: ")])]):_vm._e(),_c('div',{staticClass:"column is-auto is-narrow"},[(
              _vm.companyEventToView?.speaker && _vm.companyEventToView.speaker.email
            )?_c('div',{staticClass:"small-card"},[_c('div',{staticClass:"small-card__media"},[_c('view-user-avatar',{staticClass:"small-card__media--photoURL",attrs:{"imgsrc":_vm.companyEventToView?.speaker?.imageUrl,"alt-name":_vm.companyEventToView?.speaker.displayName}})],1),_c('div',{staticClass:"small-card__media-content"},[_c('a',{attrs:{"href":`mailto:${_vm.companyEventToView.speaker.email}`}},[_c('span',{staticClass:"small-card__media-content--displayName"},[_vm._v(_vm._s(_vm.companyEventToView?.speaker?.displayName))])]),_c('span',{staticClass:"small-card__media-content--title truncate"},[_vm._v(_vm._s(_vm.companyEventToView?.speaker?.title || ''))]),_c('span',{staticClass:"small-card__media-content--company"},[_c('span',{staticClass:"has-text-grey"},[_vm._v("@")]),_vm._v(" "+_vm._s(_vm.companyEventToView?.speaker?.companyName || ''))])])]):(_vm.companyEventToView?.speaker?.id)?_c('small-card',{staticClass:"view-company-event-info__organizedby",attrs:{"uid":_vm.companyEventToView.speaker.id,"display-name":_vm.companyEventToView.speaker.displayName}}):_vm._e()],1),_c('div',{staticClass:"column is-2"},[(
              (_vm.isUserCompanyAdmin || _vm.isUserEventCreator) && !_vm.isEventUnEditable
            )?_c('span',[_c('a',{staticClass:"has-text-danger has-text-centered",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.editSpeaker()}}},[(_vm.companyEventToView.speaker)?_c('span',[_vm._v(_vm._s(_vm.showLabelsFromCode('generic/edit'))+" Speaker | ")]):_c('span',[_vm._v(_vm._s(_vm.showLabelsFromCode('generic/add'))+" Speaker")])]),(_vm.companyEventToView.speaker)?_c('a',{staticClass:"has-text-danger has-text-centered",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteEventSpeaker()}}},[_vm._v(" "+_vm._s(_vm.showLabelsFromCode('generic/delete'))+" Speaker")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"column is-auto has-text-right-desktop"},[_c('span',{staticClass:"mr-2"},[_c('b-icon',{attrs:{"pack":"fas","icon":"calendar-day","size":"is-small"}}),_vm._v(" "+_vm._s(_vm.renderFromToDates( _vm.companyEventToView.startDateTime, _vm.companyEventToView.endDateTime, )))],1),_c('span',{staticClass:"has-text-grey"},[_vm._v("("+_vm._s(_vm.momentAgo(_vm.companyEventToView.startDateTime))+")")]),_c('AddToCalendar',{attrs:{"event-name":_vm.companyEventToView.eventName,"meeting-u-r-l":_vm.companyEventToView.eventLocation,"event-description":_vm.companyEventToView.eventDescription,"start-date-time":_vm.companyEventToView.startDateTime.seconds * 1000,"end-date-time":_vm.companyEventToView.endDateTime.seconds * 1000,"addtext":""}})],1)]),_c('hr',{staticClass:"my-4"}),_c('div',{staticClass:"columns is-vcentered is-gapless mb-0 my-2 is-multiline"},[_c('div',{staticClass:"column is-6"},[(
              !_vm.user &&
              !_vm.isUserLoggedIn &&
              _vm.companyEventToView.eventVisibility ===
                _vm.companyEventVisibilityConstants.PUBLIC
            )?_c('div',[_c('span',{staticClass:"view-company-event-info__label"},[_vm._v("Organized By:")]),_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                name: 'viewcompany',
                params: { companyid: _vm.companyEventToView.companyId },
              }}},[_c('img',{staticClass:"view-company-event-info__event-by--logoImageURL",attrs:{"src":_vm.companyToView.logoImageURL,"alt":_vm.companyToView.companyName,"height":"auto","width":"20px"}}),_c('span',{staticClass:"view-company-event-info__event-by--companyName"},[_vm._v(_vm._s(_vm.companyToView.companyName))])])],1):(
              _vm.companyEventToView.eventVisibility ===
              _vm.companyEventVisibilityConstants.INTERNAL
            )?[_c('span',{staticClass:"view-company-event-info__timestamps"},[_vm._v("🔒 For "+_vm._s(_vm.companyToView.companyName)+" employees only")])]:(
              _vm.companyEventToView.eventVisibility ===
                _vm.companyEventVisibilityConstants.PUBLIC &&
              _vm.user &&
              _vm.isUserLoggedIn
            )?[_c('span',[_vm._v("🌐 External Event")])]:_vm._e()],2),_c('div',{staticClass:"column is-6 has-text-right-desktop"},[(_vm.companyEventToView && _vm.companyEventToView.createTimestamp)?_c('div',{staticClass:"view-company-event-info__timestamps"},[_c('span',{staticClass:"view-company-event-info__timestamps--label"},[_vm._v("Created ")]),_c('span',[_vm._v(_vm._s(_vm.momentAgo(_vm.companyEventToView.createTimestamp))+" by "),_c('router-link',{staticClass:"no-text-decoration",attrs:{"to":{
                  name: 'profile',
                  params: {
                    uid: _vm.companyEventToView?.createdBy?.id,
                  },
                }}},[_c('span',[_vm._v(_vm._s(_vm.companyEventToView?.createdBy?.displayName))])])],1)]):_vm._e()]),_c('div',{staticClass:"column is-12 has-text-right-desktop mt-2"},[(
              _vm.companyEventToView.eventStatus ===
              _vm.companyEventStatusConstants.DRAFT
            )?_c('div',{staticClass:"has-text-right mb-2-until-tablet"},[_c('b-tag',{staticClass:"br-6 mt-1 mr-2",attrs:{"size":"is-medium","type":"is-dark"}},[_vm._v("Event is UNPUBLISHED")])],1):_vm._e()])])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }