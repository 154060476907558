/* eslint-disable import/extensions */
import state from './team-broadcasts.state'
import mutations from './team-broadcasts.mutations'
import actions from './team-broadcasts.actions'
import getters from './team-broadcasts.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
