<!-- eslint-disable vue/no-v-html -->
<template>
  <section v-cloak class="view-team-booking-page">
    <div class="container view-team-booking-page__container">
      <b-loading v-model="isLoading" :is-full-page="true">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon
      ></b-loading>

      <template
        v-if="
          Object.keys(teamBookingErrors).some((key) => teamBookingErrors[key])
        "
      >
        <ViewError
          :title="errorTitle"
          :description="errorDescription"
        ></ViewError>
      </template>
      <template v-else>
        <TeamBookingDetails
          :booking-record="teamBookingToView"
        ></TeamBookingDetails>
        <MeetingDetails :booking-record="teamBookingToView"></MeetingDetails>
        <MeetingEmbed
          :page-name="teamBookingToView?.pageName"
          :page-slug="teamBookingToView?.pageSlug"
        ></MeetingEmbed>
      </template>
    </div>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapMutations, mapActions } from 'vuex'
import MeetingEmbed from '@/views/scheduling/partials/MeetingEmbed'
import MeetingDetails from '@/views/scheduling/partials/MeetingDetails'
import TeamBookingDetails from '@/views/team/partials/TeamBookingDetails'
import { viewSchedulerMixin } from '@/mixins/scheduler/index'
import ViewError from '@/views/errors/ViewError'

import {
  companyColorPersonalization,
  destroyColorPersonalization,
} from '@/misc/helpers'

export default {
  name: 'ViewBookingPagePublic',
  components: { MeetingEmbed, ViewError, TeamBookingDetails, MeetingDetails },
  mixins: [viewSchedulerMixin],
  props: {
    teamid: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      errorTitle: this.showUserMessageFromCode('team/booking/notfound/title'),
      errorDescription: this.showUserMessageFromCode(
        'team/booking/notfound/description',
      ),
    }
  },
  metaInfo() {
    return {
      title: this.teamBookingToView
        ? `${this.teamBookingToView.team?.teamName}'s Scheduling Page for ${this.teamBookingToView?.pageName}`
        : 'Scheduling Page',
      meta: [
        {
          property: 'og:url',
          content: window.location.href,
          vmid: 'og:url',
        },
        {
          property: 'og:title',
          content: this.teamBookingToView
            ? `${this.teamBookingToView.team?.teamName}'s Scheduling Page for ${this.teamBookingToView?.pageName}`
            : 'Team Scheduling Page',
        },
        {
          name: 'description',
          content: `${
            this.teamBookingToView
              ? `Connect with ${this.teamBookingToView.team?.teamName} on ${this.appTitle}.`
              : this.appTitle
          }`,
        },
        {
          property: 'og:description',
          content: `${
            this.teamBookingToView
              ? `Connect with ${this.teamBookingToView.team?.teamName} on ${this.appTitle}.`
              : this.appTitle
          }`,
        },
      ],
    }
  },
  computed: {
    ...mapState('teamBookings', ['teamBookingToView', 'teamBookingErrors']),
  },
  async created() {
    await this.fetchTeamBookingsById({
      teamId: this.$route.params.teamid,
      id: this.$route.params.id,
    })
      .then((teamBookingResponse) => {
        if (teamBookingResponse) {
          if (
            teamBookingResponse?.expirationDate &&
            new Date() >= new Date(teamBookingResponse?.expirationDate)
          ) {
            this.setTeamBookingExpiredError(true)
          } else if (
            teamBookingResponse?.pageVisibilityType !==
            this.schedulerVisibilityConstants.PUBLIC
          ) {
            this.setTeamBookingNotFoundError(true)
          } else {
            this.setTeamBookingToView(teamBookingResponse)
          }
        } else {
          this.setTeamBookingNotFoundError(true)
        }
      })
      .catch((error) => {
        console.log(error)
        console.error(
          JSON.stringify(`Error in fetching team booking with Id ${this.id}`),
        )
        this.setBookingNotFoundError(true)
      })
  },
  mounted() {
    this.isLoading = false
    setTimeout(() => {
      if (
        this.teamBookingToView?.backgroundColor &&
        !Object.keys(this.teamBookingErrors).some(
          (key) => this.teamBookingErrors[key],
        )
      ) {
        companyColorPersonalization(this.teamBookingToView.backgroundColor)
      } else {
        companyColorPersonalization('#f2f6fa')
      }
      this.$buefy.toast.open({
        duration: 5000,
        message: `💡 Based on your availability please select a date & time for the meeting`,
        type: 'is-info',
        position: 'is-bottom',
        pauseOnHover: true,
      })
    }, '500')
  },
  destroyed() {
    destroyColorPersonalization()
  },
  methods: {
    ...mapMutations('teamBookings', [
      'setTeamBookingToView',
      'setTeamBookingNotFoundError',
      'setTeamBookingExpiredError',
      'setTeamBookingIsPrivateError',
    ]),
    ...mapActions('teamBookings', ['fetchTeamBookingsById']),
  },
}
</script>
