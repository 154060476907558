export const employmentTypes = [
  { id: 1, name: 'Full Time' },
  { id: 2, name: 'Part Time' },
  { id: 3, name: 'as a Contractor' },
  { id: 4, name: 'in Internship' },
  { id: 5, name: 'Pro Bono' },
  { id: 7, name: 'Freelancer' },
  { id: 8, name: 'as Student' },
]
export default function employmentTypesHelper() {
  return true
}
