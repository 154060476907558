<template>
  <div class="columns is-centered center-of-page">
    <div class="column is-auto">
      <div class="box is-family-monospace has-text-centered">
        <h4>
          Checking Authorization. Refresh page if it takes more than few seconds
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import isNil from 'lodash/isNil'

export default {
  computed: mapState('user', ['user']),
  watch: {
    user: {
      handler(user) {
        // console.log(JSON.stringify(user))
        if (user === undefined) return

        if (this.$route.query.redirectUrl === this.$route.path) {
          this.$router.push('/')
        }

        const redirectUrl = isNil(user)
          ? `/login?redirectUrl=${this.$route.query.redirectUrl}`
          : this.$route.query.redirectUrl

        this.$router.push(redirectUrl)
      },
      immediate: true,
    },
  },
  mounted() {
    console.log(JSON.stringify('Check Login is mounted'))
  },
}
</script>
