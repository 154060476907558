const companyNewsletterStatusConstants = {
  DRAFT: 1,
  PUBLISHED: 2,
  UNPUBLISHED: 3,
  ARCHIVED: 4,
}

const companyNewsletterStatus = [
  { id: companyNewsletterStatusConstants.DRAFT, name: 'Draft' },
  { id: companyNewsletterStatusConstants.PUBLISHED, name: 'Published' },
  { id: companyNewsletterStatusConstants.UNPUBLISHED, name: 'Unpublished' },
  { id: companyNewsletterStatusConstants.ARCHIVED, name: 'Archived' },
]

export { companyNewsletterStatusConstants, companyNewsletterStatus }
