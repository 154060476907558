/* eslint-disable import/extensions */
import cloneDeep from 'lodash/cloneDeep'
import {
  // eslint-disable-next-line
  connectFirestoreEmulator,
  serverTimestamp as FirebaseServerTS,
  writeBatch,
  getDocs,
  where,
  query,
  doc,
  collection,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore'
import { db } from '@/firebase/initFirebase'
import { teamMemberStatusConstants } from '@/data/teams/team-member-status'
import { teamStatusConstants } from '@/data/teams/team-status'
import { notificationActionTypesConstants } from '@/data/notificationActionTypes'
import { summarizeWithAI } from '@/misc/ai'
import { handleErrorMessage } from '@/misc/user-messages'

if (
  window.location.hostname === 'localhost' &&
  process.env.VUE_APP_USE_EMULATOR === 'true'
) {
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}

// const fbf = firebase.functions()
// if (window.location.hostname === 'localhost') {
//   fbf.useEmulator('127.0.0.1', 5001)
// }

const serverTimestamp = FirebaseServerTS()

export default function teamsBatch() {
  return true
}

export const updateTeamBatch = async (payload) => {
  // console.log(payload)
  let { team } = payload
  const { oldTeam } = payload
  const { teamUpdate } = payload
  const { user } = payload

  const batch = writeBatch(db)

  const teamRef = doc(db, 'teams', team.id)
  const teamUpdatesRef = doc(collection(db, 'teams', team.id, 'updates'))

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }

  const lastUpdatedBy = createdBy

  batch.update(teamRef, {
    teamName: team.teamName,
    teamImageURL: team.teamImageURL,
    teamDescription: team.teamDescription,
    teamStatus: team.teamStatus,
    isTeamPrivate: team.isTeamPrivate,
    teamType: team.teamType,
    lastUpdatedBy,
    updateTimestamp: serverTimestamp,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  // only update individual team members if name, type, isTeamPrivate or status is updated.
  if (
    oldTeam.teamName !== team.teamName ||
    oldTeam.teamType !== team.teamType ||
    oldTeam.teamStatus !== team.teamStatus ||
    oldTeam.isTeamPrivate !== team.isTeamPrivate
  ) {
    console.log(JSON.stringify('update all places.'))
    // Below gets the list of teammembers from teams then updates each user's teams node with latest info.
    await getDocs(collection(db, `teams/${team.id}/teammembers`)).then(
      (results) => {
        results.forEach((document) => {
          const userTeamRef = doc(db, 'users', document.id, 'teams', team.id)

          batch.update(userTeamRef, {
            teamName: team.teamName,
            teamStatus: team.teamStatus,
            teamType: team.teamType,
            isTeamPrivate: team.isTeamPrivate,
            lastUpdatedBy,
            updateTimestamp: serverTimestamp,
          })
        })
      },
    )
  }
  try {
    await batch.commit()
    team = { ...team, teamAdmins: oldTeam.teamAdmins }
    return { team, teamUpdate }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const subscribeToTeamBatch = async (payload) => {
  const { team } = payload
  const { teamUpdate } = payload
  const { user } = payload

  const batch = writeBatch(db)

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const subscriber = {
    ...createdBy,
    email: user.email,
    preferences: {
      app: true,
      email: true,
      browserNotify: true,
      chat: false,
      txtMsg: false,
      priority: 2,
    },
  }

  const lastUpdatedBy = createdBy

  const teamRef = doc(db, 'teams', team.id)
  const teamUpdatesRef = doc(collection(db, 'teams', team.id, 'updates'))
  const userTeamsRef = doc(db, 'users', user.id, 'teams', team.id)

  batch.update(teamRef, {
    subscribers: arrayUnion(subscriber),
    lastUpdatedBy,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  const userTeams = {
    teamName: team.teamName,
    teamType: team.teamType,
    teamStatus: team.teamStatus || teamStatusConstants.ACTIVE,
    memberRole: 'subscriber',
    memberStatus: teamMemberStatusConstants.SUBSCRIBED,
    lastUpdatedBy,
    updateTimestamp: serverTimestamp,
    createTimestamp: serverTimestamp,
    createdBy,
  }
  // console.log(JSON.stringify(userTeamsRef))
  // console.log(JSON.stringify(user.id))
  // console.log(JSON.stringify(team.id))

  batch.set(userTeamsRef, {
    ...userTeams,
  })
  try {
    await batch.commit()
    return { team, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const unSubscribeToTeamBatch = async (payload) => {
  const { team } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const { subscriber } = payload

  const batch = writeBatch(db)

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy

  const teamRef = doc(db, 'teams', team.id)
  const teamUpdatesRef = doc(collection(db, 'teams', team.id, 'updates'))
  const userTeamsRef = doc(db, 'users', user.id, 'teams', team.id)

  batch.update(teamRef, {
    subscribers: arrayRemove(subscriber),
    lastUpdatedBy,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  batch.delete(userTeamsRef)
  try {
    await batch.commit()
    return { team, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

/** ********************  TEAM MEMBER *********************** * */

export const createTeamMemberBatch = async (payload) => {
  // console.log(JSON.stringify(payload))
  const { teamMember } = payload
  const { team } = payload
  const { teamUpdate } = payload
  const { user } = payload

  const batch = writeBatch(db)

  const teamMemberid = teamMember.id
  const clonedTeamMember = cloneDeep(teamMember)
  delete clonedTeamMember.id

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }

  const lastUpdatedBy = createdBy

  const teamUpdates = {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  }
  const userTeams = {
    teamName: team.teamName || '',
    teamType: team.teamType || '',
    teamStatus: team.teamStatus || teamStatusConstants.ACTIVE,
    isTeamPrivate: team.isTeamPrivate || false,
    memberRole: teamMember.memberRole || '',
    memberStatus: teamMember.isTeamAdmin
      ? teamMemberStatusConstants.REQUEST_APPROVED
      : teamMemberStatusConstants.REQUEST_SEND,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
    lastUpdatedBy,
  }

  const teamTeamMembersRef = doc(
    db,
    'teams',
    team.id,
    'teammembers',
    teamMemberid,
  )
  const userTeamsRef = doc(db, 'users', teamMember.id, 'teams', team.id)
  const teamUpdatesRef = doc(collection(db, 'teams', team.id, 'updates'))
  const teamMemberNotificationRef = doc(
    collection(db, 'users', teamMember.id, 'notifications'),
  )

  batch.set(teamTeamMembersRef, {
    ...clonedTeamMember,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  batch.set(userTeamsRef, {
    ...userTeams,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdates,
  })

  if (user.id !== teamMember.id) {
    batch.set(teamMemberNotificationRef, {
      type: notificationActionTypesConstants.ADDED,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp,
      createdBy,
      message: `You have received a request from ${user.displayName} to be added to the team: <a href="/view/team/${team.id}">${team.teamName}</a>. Please see team page or email for more information`,
    })
  }

  try {
    await batch.commit()
    // console.log(JSON.stringify(userTeamsRef.id))
    return { teamMember, userTeams, teamUpdates, team }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateTeamMemberBatch = async (payload) => {
  // console.log(JSON.stringify(payload))
  const { teamMember } = payload
  const { team } = payload
  const { teamUpdate } = payload
  const { user } = payload

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy

  const batch = writeBatch(db)

  const teamRef = doc(db, 'teams', team.id)

  const teamUpdatesRef = doc(collection(db, 'teams', team.id, 'updates'))

  const userTeamRef = doc(db, 'users', teamMember.id, 'teams', team.id)
  const teamTeamMembersRef = doc(
    db,
    'teams',
    team.id,
    'teammembers',
    teamMember.id,
  )
  const teamMemberNotificationRef = doc(
    collection(db, 'users', teamMember.id, 'notifications'),
  )

  if (teamMember.isTeamAdmin) {
    batch.update(teamRef, {
      teamAdmins: arrayUnion(teamMember.id),
    })
  } else {
    batch.update(teamRef, {
      teamAdmins: arrayRemove(teamMember.id),
    })
  }

  batch.update(teamTeamMembersRef, {
    ...teamMember,
    lastUpdatedBy,
    updateTimestamp: serverTimestamp,
  })

  batch.update(userTeamRef, {
    memberStatus: teamMember.memberStatus,
    memberRole: teamMember.memberRole,
    lastUpdatedBy,
    updateTimestamp: serverTimestamp,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  if (user.id !== teamMember.id) {
    const notificationRecord = {
      type: notificationActionTypesConstants.UPDATED,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp,
      createdBy,
      message: `${user.displayName} has updated your information in the team : <a href="/view/team/${team.id}">${team.teamName}</a>. Please see team page for more information`,
    }
    batch.set(teamMemberNotificationRef, notificationRecord)
  }
  try {
    await batch.commit()
    return { teamMember, teamUpdate, team }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateTeamMemberStatusBatch = async (payload) => {
  const { team } = payload
  const teamMember = payload.teamMemberToUpdate
  const { teamUpdate } = payload
  const { user } = payload

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy

  const batch = writeBatch(db)

  const teamTeamMembersRef = doc(
    db,
    'teams',
    team.id,
    'teammembers',
    teamMember.id,
  )
  const userTeamRef = doc(db, 'users', user.id, 'teams', team.id)
  const teamUpdatesRef = doc(collection(db, 'teams', team.id, 'updates'))

  batch.update(teamTeamMembersRef, {
    memberStatus: teamMember.newStatus,
    lastUpdatedBy,
    updateTimestamp: serverTimestamp,
  })

  batch.update(userTeamRef, {
    memberStatus: teamMember.newStatus,
    lastUpdatedBy,
    updateTimestamp: serverTimestamp,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })
  try {
    await batch.commit()
    return { team, teamMember, teamUpdate }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const removeTeamMemberBatch = async (payload) => {
  const { teamMember } = payload
  const { team } = payload
  const { user } = payload
  const { teamUpdate } = payload

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }

  const notificationRecord = {
    type: notificationActionTypesConstants.UPDATED,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
    message: `${user.displayName} has removed you from the team : <a href="/view/team/${team.id}">${team.teamName}</a>. Please see email or the team page for more information`,
  }

  const batch = writeBatch(db)
  const teamTeamMembersRef = doc(
    db,
    'teams',
    team.id,
    'teammembers',
    teamMember.id,
  )
  const userTeamRef = doc(db, 'users', teamMember.id, 'teams', team.id)
  const teamUpdatesRef = doc(collection(db, 'teams', team.id, 'updates'))
  const teamMemberNotificationRef = doc(
    collection(db, 'users', teamMember.id, 'notifications'),
  )

  batch.delete(teamTeamMembersRef)
  batch.delete(userTeamRef)
  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  batch.set(teamMemberNotificationRef, notificationRecord)

  try {
    await batch.commit()
    return { teamUpdate, teamMember, team, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

/** ********************  TEAM LINKS *********************** * */

export const addTeamLinkBatch = async (payload) => {
  let { teamLink } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const { team } = payload

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }

  const batch = writeBatch(db)

  const teamUpdatesRef = doc(
    collection(db, 'teams', teamLink.teamId, 'updates'),
  )

  const teamLinksRef = doc(collection(db, 'teams', team.id, 'teamlinks'))

  batch.set(teamLinksRef, {
    ...teamLink,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  teamLink = {
    id: teamLinksRef.id,
    ...teamLink,
  }
  // console.log(JSON.stringify(teamLink))
  try {
    await batch.commit()
    return { teamLink, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateTeamLinkBatch = async (payload) => {
  // console.log(JSON.stringify(payload))
  const { teamLink } = payload
  const { teamUpdate } = payload
  const { user } = payload
  const { team } = payload

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const lastUpdatedBy = createdBy

  const batch = writeBatch(db)

  const teamLinksRef = doc(db, 'teams', team.id, 'teamlinks', teamLink.id)

  const teamUpdatesRef = doc(collection(db, 'teams', team.id, 'updates'))

  batch.update(teamLinksRef, {
    ...teamLink,
    updateTimestamp: serverTimestamp,
    lastUpdatedBy,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  try {
    await batch.commit()
    return { teamLink, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const deleteTeamLinkBatch = async (payload) => {
  const { teamLink } = payload
  const { team } = payload
  const { user } = payload
  const { teamUpdate } = payload

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }

  const batch = writeBatch(db)

  const teamLinksRef = doc(db, 'teams', team.id, 'teamlinks', teamLink.id)
  const teamUpdatesRef = doc(collection(db, 'teams', team.id, 'updates'))

  batch.delete(teamLinksRef)

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  try {
    await batch.commit()
    return { teamLink, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}

/** ********************  TEAM SUMMARY BATCH *********************** * */

export const addTeamSummaryBatch = async (payload) => {
  let { teamSummary } = payload
  const { teamId } = payload
  const { user } = payload
  const { teamUpdate } = payload

  // console.log(JSON.stringify(Timestamp.fromDate(teamSummary.startDateTime)))
  // console.log(JSON.stringify(Timestamp.fromDate(teamSummary.endDateTime)))

  const createdBy = {
    id: user.id,
    displayName: user.displayName,
  }
  const summaryCreatedBy = {
    displayName: 'gpt-4.0',
  }

  const teamBulletinTexts = []
  const teamEventMeetingNotes = []

  const querySnapshot = await getDocs(
    collection(db, `teams/${teamId}/bulletins`),
  )
  querySnapshot.forEach((bulletin) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(bulletin.data().bulletinText)
    teamBulletinTexts.push(bulletin.data().bulletinText)
  })

  const q = query(
    collection(db, 'teamevents'),
    where('team.id', '==', teamId),
    where('eventOccurence', '==', 'onetime'),
    where('startDateTime', '>=', teamSummary.startDateTime),
    where('startDateTime', '<=', teamSummary.endDateTime),
  )
  // console.log(JSON.stringify(q))
  const querySnapshot2 = await getDocs(q)
  querySnapshot2.forEach((notes) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(notes.data())
    teamEventMeetingNotes.push(notes.data().meetingNotes)
  })

  // console.log(JSON.stringify(teamBulletinTexts))
  // console.log(JSON.stringify(teamEventMeetingNotes))

  const mergedText = teamBulletinTexts.concat(teamEventMeetingNotes).join(' ')
  // console.log(JSON.stringify(mergedText))
  const AIResponse = await summarizeWithAI({
    meetingNotes: mergedText,
    promptId: teamSummary.promptId,
  })
  // console.log(JSON.stringify(teamSummary.promptId))
  switch (AIResponse.code) {
    case 'SUCCESS':
      teamSummary.summaryText = AIResponse.text
      teamSummary.tokensUsed = AIResponse?.tokensUsed || 'UNKNOWN'
      teamSummary.summaryDateTime = serverTimestamp
      teamSummary.summaryCreatedBy = summaryCreatedBy
      break
    case 'TRUNCATED':
      handleErrorMessage(
        'Too large input data, output may be empty or truncated',
      )
      throw new Error('TRUNCATED')

    case 'EMPTY':
      handleErrorMessage('Empty content, please try again')
      throw new Error('EMPTY')
    default:
      handleErrorMessage(
        'Sorry, there was an unknown AI Error, please try again',
      )
      throw new Error('UNKNOWN')
  }
  // console.log(JSON.stringify(teamSummary.summaryText))
  const batch = writeBatch(db)

  const teamUpdatesRef = doc(collection(db, 'teams', teamId, 'updates'))

  const teamSummariesRef = doc(collection(db, 'teams', teamId, 'summaries'))

  batch.set(teamSummariesRef, {
    ...teamSummary,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  batch.set(teamUpdatesRef, {
    ...teamUpdate,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp,
    createdBy,
  })

  teamSummary = {
    id: teamSummariesRef.id,
    ...teamSummary,
  }

  try {
    await batch.commit()
    return { teamSummary, teamUpdate, user }
  } catch (error) {
    console.log(error)
    throw error
  }
}
