/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import isEmpty from 'lodash/isEmpty'
import UserAppConfigDB from '@/firebase/app-config/app-config-db'
import CompanyAppConfigDB from '@/firebase/company/company-app-config-db'
import { visibilityConstants } from '@/data/visibilityPreferences'

const defaultProfileUserAppConfig = {
  comments: visibilityConstants.VISIBLE_TO_ALL,
  contactPreferences: visibilityConstants.VISIBLE_TO_NONE,
  events: visibilityConstants.VISIBLE_TO_MY_NW,
  leisures: visibilityConstants.VISIBLE_TO_ALL,
  links: visibilityConstants.VISIBLE_TO_ALL,
  officeHours: visibilityConstants.VISIBLE_TO_NONE,
  personalInfo: visibilityConstants.VISIBLE_TO_ALL,
  privateInfo: visibilityConstants.VISIBLE_TO_NONE,
  projectTeams: visibilityConstants.VISIBLE_TO_NONE,
  mentoring: visibilityConstants.VISIBLE_TO_NONE,
  canScheduleWithoutUser: false,
  isPublicallyVisible: true,
}
export default {
  fetchUserAppPanelsConfigByUserId: async ({ commit, dispatch }, userId) => {
    const userAppConfigDB = new UserAppConfigDB(userId)
    let response = await userAppConfigDB.read('panels')
    if (isEmpty(response)) {
      response = {
        id: 'panels',
        ...defaultProfileUserAppConfig,
      }
    }
    return response
  },

  createUserAppPanelsConfig: async ({ commit }, payload) => {
    const userAppConfigDB = new UserAppConfigDB(payload.userId)
    return userAppConfigDB.create(defaultProfileUserAppConfig, 'panels')
  },

  updateUserAppPanelsConfig: async ({ rootState, dispatch }, visibilityObj) => {
    const userAppConfigDB = new UserAppConfigDB(rootState.user.user.id)
    return userAppConfigDB.update(visibilityObj).catch((err) => {
      console.log(err)
      if (err.code === 'not-found') {
        dispatch('createUserAppPanelsConfig', rootState.user.user)
        throw new Error({ message: 'Please try again', code: 'Try Again' })
      }
    })
  },
  /** GENERIC APP CONFIG  */

  fetchUserAppConfigByUserId: async ({ commit, dispatch }, payload) => {
    const userAppConfigDB = new UserAppConfigDB(payload.userId)
    return userAppConfigDB.read(payload.documentId)
  },

  createUserAppConfig: async ({ commit }, payload) => {
    const userAppConfigDB = new UserAppConfigDB(payload.userId)
    return userAppConfigDB.create(payload.data, payload.documentId)
  },

  updateUserAppConfig: async ({ rootState, dispatch }, payload) => {
    // console.log(JSON.stringify(payload))
    const userAppConfigDB = new UserAppConfigDB(payload.userId)
    return userAppConfigDB.update(payload.data)
  },
}
