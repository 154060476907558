<template>
  <section class="list-draft-events">
    <full-page max-width="is-max-widescreen" width-class="is-12">
      <list-page :panel-title="panelTitle">
        <Skeleton v-if="isLoading"></Skeleton>
        <b-table
          v-if="companyNetworkAttendees"
          :data="companyNetworkAttendees"
          hoverable
          mobile-cards
          class="mx-auto"
          custom-row-key="id"
          default-sort="friend.displayName"
          default-sort-direction="desc"
          paginated
          per-page="40"
          :aria-next-label="showLabelsFromCode('generic/nextpage')"
          :aria-previous-label="showLabelsFromCode('generic/previouspage')"
          :aria-page-label="showLabelsFromCode('generic/page')"
          :aria-current-label="showLabelsFromCode('generic/currentpage')"
        >
          <template #empty>
            <p>{{ showUserMessageFromCode('generic/none') }}</p>
          </template>
          <b-table-column
            scope="props"
            field="user"
            :label="showLabelsFromCode('generic/name')"
            cell-class="valign"
            sortable
            searchable
          >
            <template #searchable="props">
              <b-input
                v-model="props.filters['friend.displayName']"
                placeholder="Search..."
                icon="search"
                icon-pack="fas"
              />
            </template>
            <template #default="props">
              <router-link
                class="no-text-decoration"
                :to="{
                  name: 'profile',
                  params: {
                    uid: props.row.id,
                  },
                }"
                replace
              >
                <span class="ml-2">{{ props.row.friend.displayName }}</span></router-link
              >
            </template>
          </b-table-column>

          <b-table-column
            v-slot="props"
            scope="props"
            field="friend.title"
            label="Title"
            cell-class="valign"
            sortable
          >
            <span> {{ props.row.friend.title }} </span>
          </b-table-column>
          <b-table-column
            scope="props"
            field="friend.companyName"
            label="Company Name"
            cell-class="valign"
            sortable
            searchable
          >
            <template #searchable="props">
              <b-input
                v-model="props.filters['friend.companyName']"
                placeholder="Search..."
                icon="search"
                icon-pack="fas"
              />
            </template>
            <template #default="props">
              <span>
                {{ props.row.friend.companyName }}
              </span>
            </template>
          </b-table-column>
          <b-table-column
            v-slot="props"
            scope="props"
            field="friend.email"
            label="Email Address"
            cell-class="valign"
            sortable
          >
            <span> {{ props.row.friend.email }} </span>
          </b-table-column>
        </b-table>
      </list-page>
    </full-page>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import ListPage from '@/components/ListPage.vue'
import { showLabelsFromCode, showTooltipsFromCode, showUserMessageFromCode } from '@/misc/helpers'
import FullPage from '@/components/partials/FullPage.vue'

export default {
  name: 'ListEventsForEdit',
  components: {
    ListPage,
    FullPage,
  },
  props: {
    companyNetworkAttendees: {
      type: Array,
    },
  },
  data() {
    return {
      panelTitle: 'List all network attendees',
    }
  },

  mounted() {
    this.isLoading = false
  },
  methods: {
    showLabelsFromCode,
    showTooltipsFromCode,
    showUserMessageFromCode,
  },
}
</script>
