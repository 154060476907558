import { schedulerVisibilityConstants } from '@/data/schedulingVisibility'

export default {
  getGuestSchedulingPages(state) {
    try {
      return state.schedulingPages.filter(
        (page) =>
          page.pageVisibilityType === schedulerVisibilityConstants.PUBLIC,
      )
    } catch (error) {
      return null
    }
  },
  getColleaguesSchedulingPages(state) {
    try {
      return state.schedulingPages.filter(
        (page) =>
          page.pageVisibilityType === schedulerVisibilityConstants.COLLEAGUES,
      )
    } catch (error) {
      return null
    }
  },
  getNetworkSchedulingPages(state) {
    try {
      return state.schedulingPages.filter(
        (page) =>
          page.pageVisibilityType === schedulerVisibilityConstants.NETWORK,
      )
    } catch (error) {
      return null
    }
  },
}
