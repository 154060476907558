<template>
  <div class="search-network">
    <b-autocomplete
      v-if="network"
      ref="searchnetwork"
      v-model="name"
      :data="filteredDataArray"
      :placeholder="showLabelsFromCode('navbar/search')"
      icon="search"
      icon-clickable
      icon-pack="fas"
      dropdown-position="bottom"
      @select="(event) => $emit('networkItemSelected', event)"
    >
      <template slot-scope="props">
        <div class="media search-network__result">
          <small-card
            :imgsrc="props.option.friend.photoURL"
            :display-name="props.option.friend.displayName"
            :title="props.option.friend.title"
            :company-name="props.option.friend.companyName"
          />
          <!-- <div class="media-left search-network__leftpanel">
            <img
              class="search-network__leftpanel--avatar"
              :src="renderImageSrc(props.option.id)"
              :class="isImageLoaded ? 'show' : ''"
              height="50"
              width="50"
              @error="setAltImg"
              @load="imgLoadedMethod"
            />
          </div>
          <div class="media-content search-network__rightpanel">
            <span class="search-network__rightpanel--displayName">
              {{ props.option.friend.displayName }}
            </span>
            <p class="search-network__rightpanel--line2">
              <span
                v-if="props.option.friend.title"
                class="search-network__rightpanel--line2--title"
                >{{ props.option.friend.title }}
              </span>
              <span
                v-if="props.option.friend.companyName"
                class="search-network__rightpanel--line2--companyname"
                ><span class="has-text-grey-light mx-1">at</span
                >{{ props.option.friend.companyName }}
              </span>
            </p>
          </div> -->
        </div>
      </template>
      <template v-if="isEnterpriseUser" #footer>
        <span class="search-network__footer">
          <hr class="navbar-divider" />
          <a class="search-network__footer--link" @click="orgSearchRouteHandler"
            ><span class="search-network__footer--link--text">
              {{ showLabelsFromCode('navbar/search/searchacrossorg') }}
            </span></a
          >
        </span>
      </template>
      <template #empty>
        {{ showLabelsFromCode('navbar/search/noresults') }} {{ name }}
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapGetters } from 'vuex'
import { showLabelsFromCode } from '@/misc/helpers'
import SmallCard from './SmallCard.vue'

export default {
  name: 'SearchNetwork',
  components: { SmallCard },
  data() {
    return {
      isImageLoaded: false,
      name: '',
      firestoreBaseURL: process.env.VUE_APP_FIRESTORE_BASEURL,
      firestoreMissingProfileImage: process.env.VUE_APP_MISSING_PROFILE_IMAGE,
      firestoreAvatarURL:
        process.env.VUE_APP_FIRESTORE_AVATAR_FOLDER || '/avatars',
    }
  },
  computed: {
    ...mapState('networkStatus', ['network']),
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isEnterpriseUser']),
    filteredDataArray() {
      if (this.network && this.name) {
        return this.network.filter((option) => {
          return (
            option.friend.displayName
              .toString()
              .toLowerCase()
              .indexOf(this.name.toLowerCase()) >= 0
          )
        })
      }
      return ''
    },
  },
  methods: {
    imgLoadedMethod() {
      this.isImageLoaded = true
    },
    orgSearchRouteHandler() {
      this.$router.push({
        name: 'organizationsearch',
        params: {
          companyid: this.$route.params.recordid,
        },
      })
    },
    showLabelsFromCode,
    renderImageSrc(id) {
      // return `${this.firestoreBaseURL}${this.firestoreAvatarURL}/%2F${id}%2Fprofile.png?alt=media`
      return `${this.firestoreBaseURL}/avatars%2F${id}%2Fprofile.png?alt=media`
    },
    setAltImg(e) {
      e.target.onerror = null
      e.target.src = `${this.firestoreMissingProfileImage}`
    },
  },
  /* async fetchNetwork() {
      // console.log(this.networkArray)
      this.networkArray = []
      if (this.networkArray.length === 0) {
        // console.log('Go Fetch')
        this.isFetching = true
        // console.log(this.user.id)
        await this.$store.dispatch('networkStatus/getNetworkById', {
          userId: this.user.id,
          status: 2, // TODO use some constant.
        })

        for (let i = 0; i < this.network.length; i += 1) {
          this.networkArray.push(this.network[i])
        }
        // console.log(JSON.stringify(this.networkArray))
        this.isFetching = false
      }
    }, */
}
</script>
