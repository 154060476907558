<template>
  <section class="integrate-calendar-tool">
    <full-page max-width="is-max-desktop" class="view-profile">
      <b-loading v-model="isLoading" :is-full-page="true">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon>
        <b-message
          type="is-info"
          :closable="false"
          title="Connecting..🌐🔌📡👨‍💻💬"
          >Please do not refresh the page, it can take upto 30 seconds for
          computers to talk to each other.
        </b-message></b-loading
      >
      <div class="center-of-page">
        <div class="card integrate-calendar-tool__card">
          <header class="card-header integrate-calendar-tool__card--header">
            <h3 class="integrate-calendar-tool__card--header--title">
              <span v-if="user && !user.isCalendarIntegrated">Integrate</span
              ><span v-else>Disconnect</span> Your Calendar with {{ appTitle }}
            </h3>
          </header>
          <div class="card-content integrate-calendar-tool__card--content">
            <p v-if="user?.isCalendarIntegrated" class="block">
              <b-icon pack="fas" icon="calendar-days" size="is-small"> </b-icon
              ><span class="ml-2 has-text-weight-bold">Account : </span
              >{{ user?.email }}
            </p>

            <b-button
              v-if="user && !user.isCalendarIntegrated"
              type="is-info"
              icon-left="calendar-check"
              icon-pack="far"
              size="is-medium"
              @click="integrateCalendar()"
            >
              Provide Calendar Access
            </b-button>
            <b-button
              v-else
              type="is-danger"
              icon-left="calendar-xmark"
              icon-pack="far"
              @click="disconnectCalendar()"
            >
              Revoke Calendar Access
            </b-button>
            <p class="integrate-calendar-tool__card--content--help">
              For additional help please contact
              <a
                class="no-text-decoration"
                target="_blank"
                href="/contactus/question"
              >
                Customer Care</a
              >
            </p>
          </div>
          <footer class="card-footer integrate-calendar-tool__card--footer">
            <b-button
              icon-left="user"
              icon-pack="fas"
              size="is-small"
              type="is-danger"
              href="/edit/profile"
              >Edit Profile</b-button
            >
            <b-button
              class="ml-2"
              size="is-small"
              type="is-primary"
              @click="$router.push('/home')"
              >Go Home</b-button
            >
          </footer>
        </div>
      </div>
    </full-page>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies
import Nylas from '@nylas/nylas-js'

import FullPage from '@/components/partials/FullPage'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'

const nylas = new Nylas({
  serverBaseUrl: process.env.VUE_APP_NYLAS_BASE_URL, // Config
})

export default {
  name: 'IntegrateCalendarTool',
  components: { FullPage },
  props: {
    code: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('app', ['appTitle']),
  },
  destroyed() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('user', ['setIsCalendarIntegrated']),
    integrateCalendar() {
      this.isLoading = true
      nylas.authWithRedirect({
        emailAddress: this?.user?.email,
        successRedirectUrl: '',
      })
    },
    goBack() {
      this.$router.back()
    },
    async disconnectCalendar() {
      this.isLoading = true
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to revoke calendar access?')) {
        try {
          const url = `${process.env.VUE_APP_NYLAS_BASE_URL}/nylas/revoke-account`
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              Authorization: this?.user?.id,
              'Content-Type': 'application/json',
            },
          })
          if (!res.ok) {
            handleErrorMessage('Error in disconnecting account')
            console.log(JSON.stringify(res.statusText))
            throw new Error(res.statusText)
          } else {
            handleSuccessMessage('Account disconnected successfully')
            this.setIsCalendarIntegrated(false)
          }
        } catch (error) {
          handleErrorMessage('Error in disconnecting account')
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.integrate-calendar-tool {
  &__card {
    padding: $base-size;
    text-align: center;
    margin: 0 auto;
    &--header {
      @include sm-title();
      box-shadow: none;
      border-bottom: 1px solid $grey-lighter;
      padding-bottom: $base-size;
    }
    &--content {
      text-align: center;
      &--help {
        margin-top: $base-size;
      }
    }
    &--footer {
      text-align: right;
      display: block;
      padding-top: $base-size;
      button {
        border-radius: $box-radius !important;
      }
    }
  }
}
</style>
