<template>
  <div class="synform" :class="{ modalform: modalForm }">
    <div class="container box is-widescreen">
      <herobar v-if="title">{{ title }}</herobar>

      <ValidationObserver ref="synform">
        <form id="synform" ref="synformForm" @submit.prevent="operation">
          <slot />
          <b-message
            class="mt-3 mb-0"
            type="is-info"
            size="is-small"
            has-icon
            icon-pack="fas"
            icon=""
          >
            Fields with
            <span class="has-text-danger"> red asterisk * </span> are required.
          </b-message>

          <div class="button-group has-text-centered">
            <b-field grouped position="is-centered">
              <div class="control">
                <b-button
                  :disabled="stateFlag"
                  type="submit"
                  :icon-left="iconLeft"
                  :icon-pack="iconPack"
                  size="is-medium"
                  class="button is-primary mr-2"
                  @click="buttonClickFunction(operation)"
                >
                  {{ buttonText }}
                </b-button>
                <template v-if="showSecondButton">
                  <b-button
                    v-if="modalForm"
                    label="Close"
                    type="is-text"
                    size="is-medium"
                    @click="$parent.close()"
                    >Close
                  </b-button>
                  <b-button
                    v-else
                    size="is-medium"
                    type="is-text"
                    @click="$router.push({ path: backRoute })"
                    >Go Back</b-button
                  >
                </template>
              </div>
            </b-field>
            <b-field
              v-if="buttonText2"
              position="is-centered"
              class="has-text-centered pt-4"
            >
              <p class="control">
                <b-button
                  :disabled="stateFlagDelete"
                  size="is-medium"
                  icon-left="trash-alt"
                  icon-pack="fa"
                  type="is-danger"
                  @click="buttonClickFunction(operation2)"
                  >{{ buttonText2 }}
                </b-button>
              </p>
            </b-field>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
import { ValidationObserver } from 'vee-validate'
import herobar from '@/components/HeroBar.vue'
import { handleErrorMessage } from '@/misc/user-messages'

export default {
  name: 'SynForm',
  components: {
    herobar,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
      default: 'Edit Form',
    },
    icon: {
      type: String,
      default: null,
    },
    iconLeft: {
      type: String,
      default: 'save',
    },
    iconPack: {
      type: String,
      default: 'fa',
    },
    stateFlag: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Submit',
    },
    stateFlagDelete: {
      type: Boolean,
      default: null,
    },
    modalForm: {
      type: Boolean,
      default: false,
    },
    showSecondButton: {
      type: Boolean,
      default: true,
    },
    buttonText2: {
      type: String,
      default: null,
    },
    backRoute: {
      type: String,
      default: '/edit/profile',
    },
    operation: Function,
    operation2: Function,
  },
  methods: {
    buttonClickFunction(op) {
      this.$refs.synform.validate().then((success) => {
        if (!success) {
          handleErrorMessage(
            'Please validate and populate all required fields before submitting',
          )
          return
        }
        op()
      })
    },
  },
}
</script>
