/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/extensions
// import UserEventsDB from '@/firebase/user-events-db'

import {
  writeBatch,
  serverTimestamp as FirebaseServerTS,
  doc,
  collection,
} from 'firebase/firestore'
import { db } from '@/firebase/initFirebase'

import UserEventsDB from '@/firebase/user-events/events-db'
import UserEventCommentsDB from '@/firebase/user-events/user-events-comments-db'

import { showUserMessageFromCode } from '@/misc/helpers'

export default {
  /** ************* NEW CODE ************ */
  fetchEventsByUserId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const { limit } = payload
    let end = null
    if (payload.endDate) {
      end = payload.endDate
    } else {
      const endDays = payload.endDays || 30
      end = new Date()
      end.setDate(end.getDate() + endDays)
    }

    let start = new Date()
    start = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      0,
      0,
      0,
    )

    const userEventsDb = new UserEventsDB(payload.userId)
    const constraints = []
    constraints.push(['endDateTime', '>=', start])
    constraints.push(['endDateTime', '<=', end])
    const orderBys = []
    orderBys.push(['endDateTime', 'asc'])
    const events = await userEventsDb
      .readAllWithOrderByLimit(constraints, orderBys, limit)
      .then((response) => {
        return response
      })
      .catch((err) => {
        // console.log(err)
        throw err
      })
    // console.log(JSON.stringify(events))
    if (events) {
      return Object.keys(events).map((o) => events[o]) // Converting to array for buefy table
    }
    return null
  },

  fetchEventById: async ({ rootState, commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const userEventsDb = new UserEventsDB(payload.userId)
    return userEventsDb.read(payload.eventId)
  },

  fetchEventComments: async ({ commit }, payload) => {
    //  console.log(JSON.stringify(payload))
    const userEventCommentsDb = new UserEventCommentsDB(
      payload.userId,
      payload.eventId,
    )
    return userEventCommentsDb.readAll()
  },

  addEvent: async ({ commit, rootState }, event) => {
    const userEventsDb = new UserEventsDB(rootState.user.user.id)
    return userEventsDb.create(event)
  },
  addEventWithId: async ({ commit, rootState }, payload) => {
    const userEventsDb = new UserEventsDB(rootState.user.user.id)
    return userEventsDb.create(payload.event, payload.id)
  },

  addEventsBulk: async ({ commit, rootState }, eventsArray) => {
    const batch = writeBatch(db)
    const serverTimestamp = FirebaseServerTS()
    const bulkEventsWithIds = []
    eventsArray.forEach((event) => {
      const userEventsRef = doc(
        collection(db, 'users', rootState.user.user.id, 'events'),
      )
      batch.set(userEventsRef, {
        ...event,
        createTimestamp: serverTimestamp,
        updateTimestamp: serverTimestamp,
      })
      bulkEventsWithIds.push({ id: userEventsRef.id, ...event })
    })
    // console.log(JSON.stringify(bulkEventsWithIds))
    return batch
      .commit()
      .then(() => {
        return bulkEventsWithIds
      })
      .catch((error) => {
        console.log(error)
        throw error.code
      })
  },

  updateEvent: async ({ rootState, commit }, payload) => {
    const userEventsDb = new UserEventsDB(rootState.user.user.id)
    return userEventsDb.update(payload)
  },

  deleteEvent: async ({ rootState, commit }, eventsId) => {
    const userEventsDb = new UserEventsDB(rootState.user.user.id)
    return userEventsDb.delete(eventsId)
  },

  addEventComment: async ({ commit }, comment) => {
    //  console.log(JSON.stringify(comment))
    const userEventCommentsDb = new UserEventCommentsDB(
      comment.friendId,
      comment.eventId,
    )
    return userEventCommentsDb.create(comment)
  },
  deleteEventComment: async ({ commit }, payload) => {
    const userEventCommentsDb = new UserEventCommentsDB(
      payload.userId,
      payload.eventId,
    )
    return userEventCommentsDb.delete(payload.commentId)
  },
}
/* 
  fetchEventsConfigByUserId: async ({ commit, dispatch }, userId) => {
    const userEventsDb = new UserEventsDB(userId)
    try {
      const eventsConfig = await userEventsDb.read('config')
      if (eventsConfig) {
        return eventsConfig
      }
      const newEventsConfig = dispatch('createUpdateEventsConfig', {
        visibility: 92,
      })
      return newEventsConfig
    } catch (error) {
      return null
    }
  }, */
/* 
  createUpdateEventsConfig: async ({ rootState, commit }, payload) => {
    const userEventsDb = new UserEventsDB(rootState.user.user.id)
    try {
      return await userEventsDb.create(payload, 'config')
    } catch (error) {
      return null
    }
  }, */
