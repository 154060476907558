import { render, staticRenderFns } from "./CompanyEventInfo.vue?vue&type=template&id=d920da20"
import script from "./CompanyEventInfo.vue?vue&type=script&lang=js"
export * from "./CompanyEventInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports