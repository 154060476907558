<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="event-meeting-notes">
    <div width-class="is-12" class="view-event-meeting-notes">
      <skeleton v-if="isLoading"></skeleton>

      <SiynCollapse
        v-if="teamEventToView"
        :title="panelTitle"
        :is-open="collapseHelper(panelname)"
        :panelname="panelname"
        ><template #header-extras>
          <b-tooltip
            multilined
            class="mx-4"
            type="is-dark"
            :label="showTooltipsFromCode('teamevents/restrict/visibility')"
            position="is-right"
            ><b-icon
              class="has-text-grey-label"
              pack="fas"
              icon="eye"
              size="is-small"
            >
            </b-icon
          ></b-tooltip>
        </template>
        <template #default>
          <div class="view-event-meeting-notes__wrapper px-4 py-2">
            <b-table
              v-if="teamMeetingNotes"
              ref="table"
              :data="teamMeetingNotes"
              hoverable
              mobile-cards
              narrowed
              custom-row-key="id"
              class="event-meeting-notes__table"
              :show-header="teamMeetingNotes.length > 0 ? true : false"
              :paginated="teamMeetingNotes.length > 8 ? true : false"
              default-sort="createTimestamp"
              default-sort-direction="desc"
              per-page="8"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
              <b-table-column
                v-slot="props"
                field="notes"
                label="Original Notes"
                cell-class="valign"
                width="110"
              >
                <a
                  v-if="props.row.notes"
                  label="Meeting Notes"
                  class=""
                  @click="viewNotesModal(props.row.id)"
                  ><b-tag class="event-meeting-notes__tag"
                    ><b-icon
                      pack="far"
                      icon="eye"
                      type="is-dark"
                      label="View"
                      size="is-medium"
                    >
                    </b-icon></b-tag
                ></a>
                <a
                  v-if="
                    (props.row.notes &&
                      getAuthUserId === props.row.createdBy.id) ||
                    isUserTeamAdminCache
                  "
                  label="Meeting Notes"
                  @click="editNotesModal(props.row.id)"
                  ><b-tag class="event-meeting-notes__tag"
                    ><b-icon
                      pack="far"
                      icon="edit"
                      type="is-primary"
                      label="Edit"
                      size="is-medium"
                    >
                    </b-icon></b-tag
                ></a>
                <b-modal v-model="isNotesModalActive" :can-cancel="true">
                  <SynPanel v-if="tempNotes" :title="panelTitle">
                    <p>
                      <b>Created: </b>{{ momentAgo(props.row.createTimestamp) }}
                      <b class="ml-2">By: </b>
                      <router-link
                        class="no-text-decoration"
                        :to="{
                          name: 'profile',
                          params: { uid: props.row.createdBy.id },
                        }"
                        >{{ props.row.createdBy.displayName }}</router-link
                      >
                    </p>
                    <p v-if="props.row.updateTimestamp">
                      <b>Last Updated: </b
                      >{{ momentAgo(props.row.updateTimestamp) }}
                      <b class="ml-2">By: </b>
                      <router-link
                        class="no-text-decoration"
                        :to="{
                          name: 'profile',
                          params: { uid: props.row.lastUpdatedBy.id },
                        }"
                        >{{ props.row.lastUpdatedBy.displayName }}</router-link
                      >
                    </p>
                    <p v-if="props.row.notesImageURL" class="my-2">
                      <b>Handwritten Notes Image: </b>
                      <a target="_blank" :href="props.row.notesImageURL"
                        >Click to view</a
                      >
                    </p>
                    <hr class="my-2" />
                    <div
                      class="white-space-preline event-meeting-notes__text sm-list-styles"
                      v-html="removeEmptyPTags(getTempNotes)"
                    ></div>
                  </SynPanel>
                </b-modal>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="summarizedText"
                label="🤖 AI Summary"
                cell-class="valign"
                width="110"
              >
                <a
                  v-if="props.row.summarizedText"
                  label="AI Summary"
                  @click="viewAISummaryModal(props.row.id)"
                  ><b-tag class="event-meeting-notes__tag"
                    ><b-icon
                      pack="far"
                      icon="eye"
                      type="is-dark"
                      label="View"
                      size="is-medium"
                    >
                    </b-icon></b-tag
                ></a>
                <a
                  v-if="
                    props.row.summarizedText &&
                    (getAuthUserId === props.row.createdBy.id ||
                      isUserTeamAdminCache)
                  "
                  label="Edit Summary"
                  @click="editAISummaryModal(props.row.id)"
                  ><b-tag class="event-meeting-notes__tag"
                    ><b-icon
                      pack="far"
                      icon="edit"
                      type="is-primary"
                      label="Edit"
                      size="is-medium"
                    >
                    </b-icon></b-tag
                ></a>
                <b-modal v-model="isSummaryModalActive" :can-cancel="true">
                  <SynPanel
                    v-if="tempSummarizedText"
                    title="Notes Summarized by ChatGPT®"
                  >
                    <p v-if="props.row.summaryDateTime">
                      <b>Created: </b>{{ momentAgo(props.row.summaryDateTime) }}
                      <b class="ml-2">By Model: </b>
                      <span>{{ props.row.summaryCreatedBy.displayName }}</span>
                    </p>
                    <p
                      v-if="
                        props.row.summaryUpdateDateTime &&
                        props.row.summaryUpdatedBy
                      "
                    >
                      <b>Last Updated: </b
                      >{{ momentAgo(props.row.summaryUpdateDateTime) }}
                      <b class="ml-2">By: </b
                      ><router-link
                        v-if="props.row.summaryUpdatedBy.id"
                        class="no-text-decoration"
                        :to="{
                          name: 'profile',
                          params: { uid: props.row.summaryUpdatedBy.id },
                        }"
                        >{{
                          props.row.summaryUpdatedBy.displayName
                        }}</router-link
                      >
                      <span v-else>{{
                        props.row.summaryUpdatedBy.displayName
                      }}</span>
                    </p>
                    <hr class="my-2" />
                    <div
                      v-if="props.row.summarizedText"
                      class="white-space-preline event-meeting-notes__text sm-list-styles"
                      v-html="removeEmptyPTags(getTempSummarizedText)"
                    ></div>
                    <div v-else>
                      <p><i>No Summary found</i></p>
                    </div>
                  </SynPanel>
                </b-modal>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="createdBy"
                label="Author"
                sortable
                cell-class="valign"
              >
                <span v-if="props.row.createdBy">
                  <router-link
                    class="no-text-decoration"
                    :to="{
                      name: 'profile',
                      params: { uid: props.row.createdBy.id },
                    }"
                    >{{ props.row.createdBy.displayName }}</router-link
                  >
                </span>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="createTimestamp"
                label="Created"
                sortable
                cell-class="valign"
              >
                <span v-if="props.row.createTimestamp">
                  {{ momentAgo(props.row.createTimestamp) }}
                </span>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="id"
                label="Action"
                centered
                cell-class="valign"
              >
                <a
                  class="no-text-decoration"
                  @click="openSideBySide(props.row.id)"
                >
                  <b-tooltip
                    label="View Notes & AI Summary Side By Side"
                    position="is-left"
                    type="is-dark"
                  >
                    <b-icon
                      icon="right-left"
                      pack="fas"
                      type="is-info"
                      size="is-medium"
                    >
                    </b-icon>
                  </b-tooltip>
                </a>
                <a
                  v-if="
                    getAuthUserId === props.row.createdBy.id ||
                    isUserTeamAdminCache
                  "
                  class="no-text-decoration ml-2"
                  @click="deleteNotesVue(props.row.id)"
                >
                  <b-tooltip
                    :label="showLabelsFromCode('generic/delete')"
                    position="is-left"
                    type="is-dark"
                  >
                    <b-icon
                      icon="trash-alt"
                      pack="fa"
                      type="is-danger"
                      size="is-medium"
                    >
                    </b-icon>
                  </b-tooltip>
                </a>
              </b-table-column>
            </b-table>
            <div
              v-if="
                (isUserTeamEventAttendee ||
                  isUserPartOfTeamCache ||
                  teamEventToView.createdBy.id === getAuthUserId) &&
                teamToView &&
                teamToView.teamStatus == teamStatusConstants.ACTIVE
              "
              class="event-meeting-notes__button-wrapper"
            >
              <b-button
                type="is-danger"
                class="event-meeting-notes__edit-button"
                title="Add Notes"
                @click="addMeetingNotes"
                >Add Notes
              </b-button>
            </div>
          </div>
        </template>
      </SiynCollapse>
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations, mapActions } from 'vuex'

import Skeleton from '@/components/partials/Skeleton'
import SynPanel from '@/components/SynPanel'
import { viewTeamsMixin } from '@/mixins/teams/index'
import EditMeetingNotes from '@/views/team/event/meetingnotes/recurring/EditMeetingNotes.vue'
import EditMeetingNotesAISummary from '@/views/team/event/meetingnotes/recurring/EditMeetingNotesAISummary'
import { removeEmptyPTags } from '@/misc/helpers'

export default {
  name: 'RecurringTeamEventMeetingNotes',
  components: {
    Skeleton,
    SynPanel,
  },
  mixins: [viewTeamsMixin],
  props: {},
  data() {
    return {
      isLoading: true,
      panelname: 'team_event_meeting_notes',
      panelTitle: 'Meeting Notes',
      isNotesModalActive: false,
      isSummaryModalActive: false,
      tempSummarizedText: null,
      tempNotes: null,
    }
  },
  computed: {
    ...mapState('teamsEvents', ['teamEventToView', 'teamMeetingNotes']),
    getTempNotes() {
      return this.tempNotes
    },
    getTempSummarizedText() {
      return this.tempSummarizedText
    },
  },
  created() {
    if (this.$route.params.refresh) {
      this.$router.go(0) // this is to ensure that page is refreshed after adding notes
    }
  },
  mounted() {
    this.isLoading = false
  },
  destroyed() {},
  methods: {
    ...mapMutations('teamsEvents', [
      'setTeamMeetingNoteToEdit',
      'setTeamMeetingNoteToRemove',
    ]),
    ...mapActions('teamsEvents', ['deleteMeetingNotesAction']),
    viewNotesModal(meetingsRecordId) {
      this.isNotesModalActive = true
      const temp = this.teamMeetingNotes.find(
        (meetingRecord) => meetingRecord.id === meetingsRecordId,
      )
      this.tempNotes = temp.notes
    },
    viewAISummaryModal(meetingsRecordId) {
      this.isSummaryModalActive = true
      const temp = this.teamMeetingNotes.find(
        (meetingRecord) => meetingRecord.id === meetingsRecordId,
      )
      this.tempSummarizedText = temp.summarizedText
    },
    addMeetingNotes() {
      this.$router.push({
        name: 'addrecurringmeetingnotes',
        params: {
          teamEventToView: this.teamEventToView,
          teamId: this.teamEventToView.team.id,
          teamEventId: this.teamEventToView.id,
          mode: 'ADD',
        },
      })
    },
    editNotesModal(meetingNotesRecordId) {
      const meetingNotesRecord = this.teamMeetingNotes.find(
        (item) => item.id === meetingNotesRecordId,
      )
      this.setTeamMeetingNoteToEdit(meetingNotesRecord)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditMeetingNotes,
        events: {
          update_successful: () => {
            this.handleSuccessMessage('Update Successful !')
          },
          update_failed: () => {
            this.handleErrorMessage('Update Failed !')
          },
        },
        trapFocus: true,
      })
    },
    editAISummaryModal(meetingNotesRecordId) {
      const meetingNotesRecord = this.teamMeetingNotes.find(
        (item) => item.id === meetingNotesRecordId,
      )
      this.setTeamMeetingNoteToEdit(meetingNotesRecord)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditMeetingNotesAISummary,
        events: {
          update_successful: () => {
            this.handleSuccessMessage('Update Successful !')
          },
          update_failed: () => {
            this.handleErrorMessage('Update Failed !')
          },
        },
        trapFocus: true,
      })
    },
    removeEmptyPTags,
    openSideBySide(meetingNotesRecordId) {
      const meetingNotesRecord = this.teamMeetingNotes.find(
        (item) => item.id === meetingNotesRecordId,
      )
      this.$router.push({
        name: 'viewnotessidebyside',
        params: {
          teamEventToView: this.teamEventToView,
          teamId: this.teamEventToView.team.id,
          teamEventId: this.teamEventToView.id,
          id: meetingNotesRecordId,
          meetingNotesRecord,
          mode: 'VIEW',
        },
      })
    },
    deleteNotesVue(meetingNotesId) {
      if (
        // eslint-disable-next-line no-alert
        window.confirm('Are you sure you want to delete the meeting notes?')
      ) {
        this.isLoading = true
        const teamUpdate = {
          team: {
            id: this.teamEventToView.team.id,
            name: this.teamToView.teamName,
          },
          action: this.notificationActionTypesConstants.DELETED,
          type: 'notes',
          message: `For event ${this.teamEventToView.eventName}`,
          showOnDashboard: false,
          priority: 1,
        }
        this.deleteMeetingNotesAction({
          meetingNotesId,
          teamEventToView: this.teamEventToView,
          teamUpdate,
          user: this.user,
        })
          .then((deleteResponse) => {
            if (deleteResponse) {
              this.setTeamMeetingNoteToRemove(meetingNotesId)
              this.handleSuccessMessage('Meeting Note deleted successfully')
            }
          })
          .catch((err) => {
            console.log(err)
            this.handleErrorMessage(
              this.showUserMessageFromCode('generic/error/unknown/title'),
            )
          })
          .finally(() => (this.isLoading = false))
      }
    },
  },
}
</script>
