import state from './team-events.state'
import mutations from './team-events.mutations'
import actions from './team-events.actions'
import getters from './team-events.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
