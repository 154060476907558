<template>
  <section class="view-company-events">
    <skeleton v-if="isLoading"></skeleton>

    <SiynCollapse
      v-if="(companyEvents && companyEvents.length > 0) || isUserCompanyAdmin"
      :title="panelTitle"
      emoji="🎟️"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <b-table
        v-if="companyEvents"
        ref="table"
        :data="companyEvents"
        hoverable
        mobile-cards
        narrowed
        :show-header="companyEvents.length > 0 ? true : false"
        :paginated="companyEvents.length > 5 ? true : false"
        custom-row-key="id"
        default-sort="startDateTime"
        default-sort-direction="desc"
        per-page="5"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        class="view-company-events__table mx-auto"
      >
        <template #empty>
          <p class="empty-content">
            {{ showUserMessageFromCode('generic/none') }}
          </p>
        </template>
        <b-table-column
          v-slot="props"
          scope="props"
          field="eventName"
          :label="showLabelsFromCode('teams/teamevents/eventname')"
          cell-class="valign"
          sortable
        >
          <span>
            <a
              class="no-text-decoration"
              @click="gotoViewCompanyEvent(props.row)"
            >
              <span :title="props.row.eventName">{{
                props.row.eventName
              }}</span></a
            > </span
          ><span
            v-if="
              props.row.eventVisibility ===
              companyEventVisibilityConstants.PUBLIC
            "
            title="External Event"
            class="ml-2"
            >🌐</span
          >
        </b-table-column>
        <b-table-column
          v-slot="props"
          scope="props"
          field="speaker.displayName"
          cell-class="valign"
          label="Speaker"
          sortable
        >
          <template v-if="props.row.speaker">
            <router-link
              v-if="props.row.speaker.id"
              class="no-text-decoration"
              :to="{
                name: 'profile',
                params: { uid: props.row.speaker.id },
              }"
              ><view-user-avatar
                class="view-company-events__table--avatar"
                :alt-name="props.row.speaker.displayName"
                :uid="props.row.speaker.id"
              />
              <span class="view-company-events__table--displayName">{{
                props.row.speaker.displayName
              }}</span></router-link
            >
            <a
              v-else-if="props.row.speaker.email"
              :href="`mailto:${props.row.speaker.email}`"
            >
              <span class="view-company-events__table--displayName">{{
                props.row.speaker.displayName
              }}</span></a
            >
          </template>

          <template v-else></template>
        </b-table-column>
        <b-table-column
          v-slot="props"
          scope="props"
          field="startDateTime"
          cell-class="valign"
          :label="showLabelsFromCode('generic/when')"
          sortable
        >
          <span
            >{{ formatDateTime(props.row.startDateTime) }}
            <span class="view-company-events__table--startDateTime--daysAgo">
              ({{ humanReadableDateTime(props.row.startDateTime) }})
            </span>
          </span>
        </b-table-column>
      </b-table>
      <div
        v-if="isUserCompanyAdmin || isUserInSameCompany"
        class="has-text-center-touch has-text-right"
      >
        <b-dropdown
          :triggers="['hover']"
          aria-role="menu"
          :mobile-modal="false"
          position="is-bottom-left"
          class="has-text-left"
        >
          <template #trigger>
            <b-button
              :label="`${showLabelsFromCode(
                'company/events/panelTitle',
              )} ${showLabelsFromCode('generic/menu')}`"
              type="is-primary"
              class="view-company-events__button background-color-override"
              icon-right="caret-down"
              icon-pack="fas"
            />
          </template>
          <b-dropdown-item
            aria-role="menuitem"
            @click="gotoAddCompanyEventScreen()"
            ><b-icon type="is-info" icon="square-plus" pack="far" />{{
              showLabelsFromCode('generic/create')
            }}
            {{ showLabelsFromCode('generic/event') }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="isUserCompanyAdmin"
            aria-role="menuitem"
            @click="gotoListAllEventsScreen()"
            ><b-icon type="is-info" icon="list" pack="fas" />{{
              showLabelsFromCode('generic/list')
            }}
            All {{ showLabelsFromCode('generic/events') }}</b-dropdown-item
          >
          <b-dropdown-item
            v-if="isUserCompanyAdmin"
            aria-role="menuitem"
            @click="gotoUnpublishedEventsScreen()"
            ><b-icon type="is-info" icon="person-digging" pack="fas" />{{
              showLabelsFromCode('generic/view')
            }}
            All
            {{ showLabelsFromCode('company/events/unpublished') }}
            {{ showLabelsFromCode('generic/events') }}</b-dropdown-item
          >
          <b-dropdown-item
            aria-role="menuitem"
            @click="gotoMyUnpublishedEventsScreen()"
            ><b-icon type="is-info" icon="person-digging" pack="fas" />{{
              showLabelsFromCode('generic/view')
            }}
            My {{ showLabelsFromCode('company/events/unpublished') }}
            {{ showLabelsFromCode('generic/events') }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </SiynCollapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapActions, mapState, mapMutations } from 'vuex'
import { viewCompanyMixin } from '@/mixins/company/index'
import ViewUserAvatar from '@/components/ViewUserAvatar'
import { companyEventVisibilityConstants } from '@/data/company/company-event-visibility'
import { companyEventStatusConstants } from '@/data/company/company-event-status'

export default {
  name: 'CompanyEvents',
  components: { ViewUserAvatar },
  mixins: [viewCompanyMixin],
  data() {
    return {
      isLoading: true,
      fetchExternalEvents: false,
      panelTitle: this.showLabelsFromCode('company/events/panelTitle'),
      panelname: 'company_events',
      companyEventVisibilityConstants,
      companyEventStatusConstants,
    }
  },
  computed: {
    ...mapState('company', ['companyEvents', 'isUserInSameCompany']),
    ...mapState('user', ['user', 'fetchingUser']),
  },
  watch: {
    user: {
      handler(user) {
        // console.log(JSON.stringify(user))
        if (user && this.user.companyId === this.companyToView.id) {
          console.log(JSON.stringify('fetch internal events'))
          this.fetchCompanyEvents({
            companyId: this.companyToView.id,
            eventVisibility: this.companyEventVisibilityConstants.INTERNAL,
          })
        }
      },
    },
  },
  created() {
    this.setCompanyEvents([])
  },

  mounted() {
    this.isLoading = false

    this.fetchCompanyEvents({
      companyId: this.companyToView.id,
      eventVisibility: this.companyEventVisibilityConstants.PUBLIC,
    })
    if (
      this.user &&
      this.isUserLoggedIn &&
      this.user.companyId === this.companyToView.id
    ) {
      this.fetchCompanyEvents({
        companyId: this.companyToView.id,
        eventVisibility: this.companyEventVisibilityConstants.INTERNAL,
      })
    }
  },
  methods: {
    ...mapMutations('company', [
      'setCompanyEvents',
      'setCompanyToEdit',
      'setCompanyToView',
      'setCompanyEventToView',
      'setIsUserCompanyAdmin',
      'setIsUserInSameCompany',
      'addCompanyEvents',
    ]),
    ...mapActions('company', ['fetchCompanyEventsByCompanyId']),
    async gotoViewCompanyEvent(companyEvent) {
      await this.setCompanyEventToView(companyEvent)
      await this.setCompanyToView(this.companyToView)
      await this.setIsUserCompanyAdmin(this.isUserCompanyAdmin)
      await this.setIsUserInSameCompany(this.isUserInSameCompany)
      this.$router.push({
        name: 'viewcompanyevent',
        params: {
          companyid: companyEvent.companyId,
          id: companyEvent.id,
        },
      })
    },
    gotoAddCompanyEventScreen() {
      this.setCompanyToEdit(this.companyToView)
      this.$router.push({
        name: 'addcompanyevent',
        params: {
          recordid: this.companyToView.id,
        },
      })
    },

    gotoListAllEventsScreen() {
      this.setCompanyToEdit(this.companyToView)
      this.$router.push({
        name: 'listallcompanyevents',
        params: {
          recordid: this.getAuthUserCompanyId,
          mode: 'LIST',
        },
      })
    },
    gotoMyUnpublishedEventsScreen() {
      this.setCompanyToEdit(this.companyToView)
      this.$router.push({
        name: 'listunpublishedevents',
        params: {
          recordid: this.companyToView.id,
          mode: 'LIST',
        },
      })
    },
    gotoUnpublishedEventsScreen() {
      this.setCompanyToEdit(this.companyToView)
      this.$router.push({
        name: 'listunpublishedevents',
        params: {
          recordid: this.companyToView.id,
          mode: 'LIST',
        },
      })
    },
    async fetchCompanyEvents(payload) {
      this.isLoading = true
      const end = new Date()
      const endDays = 30
      end.setDate(end.getDate() + endDays)
      let start = new Date()
      start = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        0,
        0,
        0,
      )
      const constraints = []
      constraints.push([
        'eventStatus',
        '==',
        this.companyEventStatusConstants.SCHEDULED,
      ])
      constraints.push(['companyId', '==', payload.companyId])
      constraints.push(['endDateTime', '>=', start])
      constraints.push(['endDateTime', '<=', end])
      constraints.push(['eventVisibility', '==', payload.eventVisibility])

      // constraints.push(['attendees', 'array-contains', this.getTinyUser])

      await this.fetchCompanyEventsByCompanyId({
        companyId: payload.companyId,
        constraints,
      })
        .then((companyEventsResponse) => {
          // console.log(JSON.stringify(companyEventsResponse))
          if (!this.isEmpty(companyEventsResponse)) {
            this.addCompanyEvents(...companyEventsResponse)
            // console.log(JSON.stringify(this.companyEvents))
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
