<template>
  <div class="siynactionbar card">
    <div class="siynactionbar__heading">
      <span class="siynactionbar__heading--title card-header-title">
        <div class="level">
          <div class="level-left">
            <span class="level-item siynactionbar__heading--emoji"
              >{{ emoji }} </span
            ><span class="level-item siynactionbar__heading--title">{{
              title
            }}</span>
          </div>
          <div
            class="siynactionbar__links columns is-mobile is-vcentered is-multiline"
          >
            <div v-for="link in links" :key="link.id" class="column is-narrow">
              <SiynLink :link="link"> </SiynLink>
            </div>
          </div>
        </div>
      </span>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import SiynLink from './SiynLink'

export default {
  name: 'SiynActionBar',
  components: { SiynLink },
  props: {
    links: Array,
    emoji: String,
    title: String,
  },
}
</script>
