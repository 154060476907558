<template>
  <section v-cloak class="company">
    <full-page max-width="is-max-widescreen" class="view-company">
      <b-loading v-model="isLoading" :is-full-page="true">
        <b-icon
          pack="fas"
          icon="spinner"
          size="is-large"
          custom-class="fa-pulse"
          type="is-primary"
        >
        </b-icon
      ></b-loading>
      <template v-if="!isLoading">
        <template v-if="!isEmpty(companyToView) && canUserView">
          <CompanyInfo></CompanyInfo>
          <CompanyLocations></CompanyLocations>
          <CompanyNetwork v-if="user"></CompanyNetwork>
          <CompanyEvents></CompanyEvents>
          <template v-if="isUserInSameCompany">
            <CompanyBroadcasts></CompanyBroadcasts>
            <CompanyNewsletters></CompanyNewsletters>
            <CompanyAdmins></CompanyAdmins>
            <CompanyTokens v-if="isUserCompanyAdmin"></CompanyTokens>
            <CompanyConfigs></CompanyConfigs>
          </template>
        </template>
        <!-- TODO: Change the title and description to say companynotfound title using user messages file-->
        <ViewError
          v-else-if="
            (companyToView && !isEmpty(companyPanelsConfig) && !canUserView) ||
            companyErrors.companyNotFound
          "
          :title="showUserMessageFromCode('company/notfound/title')"
          :description="showUserMessageFromCode('company/notfound/description')"
        >
        </ViewError>
        <ViewError
          v-else-if="companyErrors.unknown"
          :title="errorTitle"
          :description="errorDescription"
        ></ViewError>
        <ViewError
          v-else-if="companyErrors.noCompanyAdmins"
          :title="errorTitle"
          :description="errorDescription"
        ></ViewError>
      </template>
    </full-page>
    <div v-if="companyToView && companyToView.companyName" class="watermark">
      <span>
        <b-icon pack="fas" icon="building" size="is-small"> </b-icon>
        {{ companyToView.companyName }}</span
      >
    </div>
  </section>
</template>
<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations, mapActions } from 'vuex'
import {
  viewCompanyMixin,
  initializeCompanyState,
} from '@/mixins/company/index'
import ViewError from '@/views/errors/ViewError'
import CompanyInfo from './CompanyInfo'
import CompanyLocations from './locations/CompanyLocations'
import CompanyEvents from './events/CompanyEvents.vue'
import CompanyNetwork from './network/CompanyNetwork'
import CompanyAdmins from './admins/CompanyAdmins'
import CompanyConfigs from './config/CompanyConfigs'
import CompanyNewsletters from './newsletters/CompanyNewsletters'
import CompanyBroadcasts from './broadcasts/CompanyBroadcasts'
import CompanyTokens from './tokens/CompanyTokens'
import { trackPageView, trackScreenView } from '@/misc/analytics'
import { companyEventStatusConstants } from '@/data/company/company-event-status'
import {
  companyColorPersonalization,
  destroyColorPersonalization,
} from '@/misc/helpers'

export default {
  name: 'ViewCompany',
  components: {
    ViewError,
    CompanyInfo,
    CompanyLocations,
    CompanyEvents,
    CompanyNetwork,
    CompanyAdmins,
    CompanyConfigs,
    CompanyNewsletters,
    CompanyTokens,
    CompanyBroadcasts,
  },
  mixins: [viewCompanyMixin],
  props: {
    companyid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      panelTitle: 'View Company',
      isLoading: true,
      errorTitle: 'Unknown Error',
      errorDescription: 'Sorry there was an unknown error, please try again.',
      companyEventStatusConstants,
      superAdmin: process.env.VUE_APP_SUPER_ADMIN,
    }
  },
  metaInfo: {
    title: 'View Company',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  computed: {
    ...mapState('company', [
      'companyToView',
      'isUserInSameCompany',
      'isUserCompanyAdmin',
      'companyEvents',
    ]),
    isUserSuperAdmin() {
      return this.getAuthUserId === this.superAdmin
    },
    canUserView() {
      return (
        this.isUserInSameCompany ||
        this.companyToView.companyVisibility === 'public'
      )
    },
  },
  watch: {
    companyToView: {
      handler(companyToView) {
        if (companyToView) {
          this.fetchCompanyAdminsByCompanyIdVue(companyToView.id)
          this.fetchCompanyLocationsByCompanyIdVue(companyToView.id)
          // this.fetchCompanyEventsByCompanyIdVue(companyToView.id)
          this.fetchCompanyAppConfigByCompanyIdVue(companyToView.id)
        }
      },
      deep: true,
      immediate: true,
    },
    user: {
      handler(user) {
        if (user) {
          if (this.getAuthUserCompanyId === this.$route.params.companyid) {
            this.setIsUserInSameCompany(true)
          }
          if (this.companyAdmins) {
            const isCompanyAdmin = this.companyAdmins.some(
              (admin) => admin.id === this.getAuthUserId,
            )
            // console.log(JSON.stringify(isCompanyAdmin))
            this.setIsUserCompanyAdmin(
              isCompanyAdmin &&
                this.getAuthUserCompanyId === this.companyid &&
                this.getAuthUserRole === 'COADMIN',
            )
          }
        }
      },
    },
  },
  created() {
    this.initializeCompanyState()
    this.fetchCompanyByIdVue()
  },
  mounted() {
    this.isLoading = false
    if (this.getAuthUserCompanyId === this.$route.params.companyid) {
      this.setIsUserInSameCompany(true)
    }
  },
  updated() {
    this.$nextTick(() => {
      if (this.companyToView) {
        setTimeout(() => {
          companyColorPersonalization(this.companyToView.bannerColor)
        }, '800')
      }
    })
  },
  destroyed() {
    destroyColorPersonalization()
  },
  methods: {
    ...mapActions('company', [
      'fetchCompanyByCompanyId',
      'fetchCompanyAdminsByCompanyId',
      'fetchCompanyLocationsByCompanyId',
      'fetchCompanyAppPanelsConfigByCompanyId',
    ]),
    ...mapMutations('company', [
      'setCompanyToView',
      'setCompanyAdmins',
      'setCompanyLocations',
      'setCompanyEvents',
      'setCompanyNotFound',
      'setIsUserCompanyAdmin',
      'setIsUserInSameCompany',
      'setCompanyAdminsNotFound',
      'setCompanyUnknownError',
      'setCompanyAppConfig',
    ]),
    initializeCompanyState,

    async fetchCompanyByIdVue() {
      await this.fetchCompanyByCompanyId(this.companyid)
        .then((companyResponse) => {
          if (!this.isEmpty(companyResponse)) {
            // console.log(JSON.stringify(companyResponse))
            this.setCompanyToView(companyResponse)
          } else {
            this.setCompanyNotFound(true)
          }
        })
        .catch((error) => {
          console.log(error)
          this.setCompanyNotFound(true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async fetchCompanyAdminsByCompanyIdVue(companyId) {
      await this.fetchCompanyAdminsByCompanyId(companyId)
        .then((companyAdminsResponse) => {
          // console.log(JSON.stringify(companyAdminsResponse))
          if (!this.isEmpty(companyAdminsResponse)) {
            this.setCompanyAdmins(companyAdminsResponse)
            const isCompanyAdmin = companyAdminsResponse.some(
              (admin) => admin.id === this.getAuthUserId,
            )
            // console.log(JSON.stringify(isCompanyAdmin))
            this.setIsUserCompanyAdmin(
              isCompanyAdmin &&
                this.getAuthUserCompanyId === this.companyid &&
                this.getAuthUserRole === 'COADMIN',
            )
          } else if (this.getAuthUserId === this.superAdmin) {
            this.handleSuccessMessage(
              'Super Admin should only add initial admin',
            )
          } else {
            this.errorTitle = 'Company Admins not found'
            this.errorDescription =
              'No Company Admins were set when adding the company. Please contact customer care or your Account Manager'
            this.setCompanyAdmins(undefined)
            this.setIsUserCompanyAdmin(false)
            this.setCompanyAdminsNotFound(true)
          }
        })
        .catch((error) => {
          console.log(error)
          // console.log(error.code)
          if (error.code !== 'permission-denied') {
            this.errorTitle = 'Company Admins not found'
            this.errorDescription =
              'No Company Admins were set when adding the company. Please contact customer care or your Account Manager'
            this.setCompanyAdmins(undefined)
            this.setIsUserCompanyAdmin(false)
            this.setCompanyAdminsNotFound(true)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async fetchCompanyLocationsByCompanyIdVue(companyId) {
      await this.fetchCompanyLocationsByCompanyId(companyId)
        .then((companyLocations) => {
          // console.log(JSON.stringify(companyLocations))
          if (!this.isEmpty(companyLocations)) {
            this.setCompanyLocations(companyLocations)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    // async fetchCompanyEventsByCompanyIdVue(companyId) {
    //   const end = new Date()
    //   const endDays = 30
    //   end.setDate(end.getDate() + endDays)
    //   let start = new Date()
    //   start = new Date(
    //     start.getFullYear(),
    //     start.getMonth(),
    //     start.getDate(),
    //     0,
    //     0,
    //     0,
    //   )
    //   const constraints = []
    //   constraints.push([
    //     'eventStatus',
    //     '==',
    //     this.companyEventStatusConstants.SCHEDULED,
    //   ])
    //   constraints.push(['companyId', '==', companyId])
    //   constraints.push(['endDateTime', '>=', start])
    //   constraints.push(['endDateTime', '<=', end])
    //   // constraints.push(['attendees', 'array-contains', this.getTinyUser])

    //   await this.fetchCompanyEventsByCompanyId({
    //     companyId,
    //     constraints,
    //   })
    //     .then((companyEvents) => {
    //       // console.log(JSON.stringify(companyEvents))
    //       if (!this.isEmpty(companyEvents)) {
    //         this.setCompanyEvents(companyEvents)
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //     })
    //     .finally(() => {
    //       this.isLoading = false
    //     })
    // },

    async fetchCompanyAppConfigByCompanyIdVue(companyId) {
      this.isLoading = true
      await this.fetchCompanyAppPanelsConfigByCompanyId(companyId)
        .then((companyAppConfigResponse) => {
          // console.log(JSON.stringify(companyAppConfigResponse))
          if (!this.isEmpty(companyAppConfigResponse)) {
            this.setCompanyAppConfig(companyAppConfigResponse)
          } else {
            this.setCompanyAppConfig(null)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
