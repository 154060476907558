var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"company-event-network"},[_c('div',{staticClass:"view-company-event-network",attrs:{"width-class":"is-12"}},[(_vm.isLoading)?_c('skeleton'):_vm._e(),(_vm.companyEventToView && !_vm.isLoading)?_c('SynPanel',{attrs:{"title":_vm.panelTitle}},[_c('div',{staticClass:"view-company-event-network__wrapper px-4 py-2"},[(_vm.companyNetworkAttendees && _vm.companyNetworkAttendees.length > 0)?_c('div',{staticClass:"columns is-multiline is-gapless is-vcentered"},[_vm._l((_vm.companyNetworkAttendees.slice(
              0,
              _vm.maxItems,
            )),function(item,index){return [(index <= _vm.maxItems)?[_c('small-card',{key:item.id,staticClass:"column is-3 is-narrow",attrs:{"uid":item.id,"display-name":item.friend.displayName,"title":item.friend.title}})]:_vm._e()]}),(
              _vm.companyNetworkAttendees &&
              _vm.companyNetworkAttendees.length > _vm.maxItems
            )?[_c('a',{staticClass:"my-4",on:{"click":function($event){return _vm.viewListModal()}}},[_vm._v("View All ("+_vm._s(_vm.companyNetworkAttendees.length)+")...")])]:_vm._e()],2):_c('div',[_c('p',{staticClass:"empty-content"},[_vm._v("None.")])])])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }