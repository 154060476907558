/* eslint-disable import/extensions */
import state from './tokens.state'
import mutations from './tokens.mutations'
import actions from './tokens.actions'
import getters from './tokens.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
