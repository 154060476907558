<template>
  <section class="view-company-newsletters">
    <skeleton v-if="isLoading"></skeleton>
    <SiynCollapse
      v-if="companyNewsletters || isUserCompanyAdmin"
      :title="panelTitle"
      emoji="📰"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <template #header-extras>
        <b-tooltip
          multilined
          class="mx-4"
          type="is-dark"
          label="Only visible to employees of the company"
          position="is-right"
          ><b-icon
            class="has-text-grey-lighter"
            pack="fas"
            icon="info-circle"
            size="is-small"
          >
          </b-icon
        ></b-tooltip>
      </template>
      <template #default>
        <b-table
          v-if="companyNewsletters"
          ref="table"
          :data="companyNewsletters"
          hoverable
          mobile-cards
          narrowed
          :show-header="companyNewsletters.length > 0 ? true : false"
          :paginated="companyNewsletters.length > 6 ? true : false"
          custom-row-key="id"
          default-sort="startDateTime"
          default-sort-direction="desc"
          per-page="6"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          class="view-company-newsletters__table mx-auto"
        >
          <b-table-column
            v-slot="props"
            scope="props"
            field="newsletterTitle"
            :label="showLabelsFromCode('generic/title')"
            cell-class="valign"
            sortable
          >
            <span>
              <a
                class="no-text-decoration"
                @click="gotoViewCompanyNewsletter(props.row)"
              >
                <span :title="props.row.newsletterTitle">{{
                  props.row.newsletterTitle
                }}</span></a
              >
            </span>
          </b-table-column>
          <b-table-column
            v-slot="props"
            scope="props"
            field="author.displayName"
            cell-class="valign"
            :label="showLabelsFromCode('generic/author')"
            sortable
          >
            <router-link
              class="no-text-decoration"
              :to="{
                name: 'profile',
                params: { uid: props.row.author.id },
              }"
              ><view-user-avatar
                class="view-company-newsletters__table--avatar"
                :uid="props.row.author.id"
                :alt-name="props.row.author.displayName"
              />
              <span class="view-company-newsletters__table--displayName">{{
                props.row.author.displayName
              }}</span></router-link
            >
          </b-table-column>
          <b-table-column
            v-slot="props"
            scope="props"
            field="updateTimestamp"
            cell-class="valign"
            :label="showLabelsFromCode('generic/lastupdated')"
            sortable
          >
            <span>{{ formatDateTime(props.row.updateTimestamp) }}</span>
          </b-table-column>
        </b-table>
        <div
          v-if="isUserCompanyAdmin || isUserNewsletterAdmin"
          class="has-text-center-touch has-text-right mt-4"
        >
          <b-dropdown
            :triggers="['hover']"
            aria-role="menu"
            :mobile-modal="false"
            position="is-bottom-left"
            class="has-text-left"
          >
            <template #trigger>
              <b-button
                :label="`${showLabelsFromCode(
                  'company/newsletters/panelTitle',
                )} ${showLabelsFromCode('generic/menu')}`"
                type="is-primary"
                class="view-company-newsletters__button background-color-override"
                icon-right="caret-down"
                icon-pack="fas"
              />
            </template>
            <b-dropdown-item
              v-if="isUserNewsletterAdmin || isUserCompanyAdmin"
              aria-role="menuitem"
              @click="gotoListAllNewslettersScreen()"
              ><b-icon type="is-info" icon="list" pack="fas" class="mr-2" />{{
                showLabelsFromCode('company/newsletters/listallnewsletters')
              }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="isUserNewsletterAdmin || isUserCompanyAdmin"
              aria-role="menuitem"
              @click="gotoAddCompanyNewsletterScreen()"
              ><b-icon
                type="is-info"
                icon="square-plus"
                pack="far"
                class="mr-2"
              />{{ showLabelsFromCode('generic/create') }}
              {{ showLabelsFromCode('company/newsletter') }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="isUserCompanyAdmin"
              aria-role="menuitem"
              @click="gotoAddNewsletteradmin()"
              ><b-icon
                type="is-info"
                icon="plus-square"
                pack="fas"
                class="mr-2"
              />{{ showLabelsFromCode('generic/add') }}
              {{ showLabelsFromCode('company/newsletter') }}
              {{ showLabelsFromCode('generic/admin') }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="isUserCompanyAdmin"
              aria-role="menuitem"
              @click="openEditNewsletterAdminModal()"
              ><b-icon type="is-info" icon="edit" pack="fas" class="mr-2" />{{
                showLabelsFromCode('generic/edit')
              }}
              {{ showLabelsFromCode('company/newsletter') }}
              {{ showLabelsFromCode('generic/admin') }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </template>
    </SiynCollapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import { mapState, mapMutations, mapActions } from 'vuex'
import { viewCompanyMixin } from '@/mixins/company/index'
import ViewUserAvatar from '@/components/ViewUserAvatar'
import EditCompanyNewsletterAdminModal from './EditCompanyNewsletterAdminModal'
import {
  companyNewsletterStatusConstants,
  companyNewsletterStatus,
} from '@/data/company/company-newsletter-status'

export default {
  name: 'CompanyNewsletters',
  components: { ViewUserAvatar },
  mixins: [viewCompanyMixin],
  props: {
    companyid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      panelTitle: this.showLabelsFromCode('company/newsletters/panelTitle'),
      panelname: 'company_newsletters',
      isUserNewsletterAdmin: false,
      companyNewsletterStatusConstants,
      companyNewsletterStatus,
    }
  },
  computed: {
    ...mapState('company', ['companyNewsletters']),
  },
  watch: {
    companyToView: {
      handler(companyToView) {
        if (companyToView && companyToView.newsletterAdmins) {
          this.isUserNewsletterAdmin = companyToView.newsletterAdmins.some(
            (admin) => admin.id === this.getAuthUserId,
          )
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fetchCompanyNewslettersByCompanyIdVue()
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapActions('company', ['fetchCompanyNewsletterByCompanyId']),
    ...mapMutations('company', [
      'setCompanyToEdit',
      'setCompanyToView',
      'setCompanyNewsletters',
      'setCompanyNewsletterToView',
      'setIsUserCompanyAdmin',
      'setIsUserInSameCompany',
    ]),
    async fetchCompanyNewslettersByCompanyIdVue() {
      const constraints = []
      constraints.push([
        'newsletterStatus',
        '==',
        companyNewsletterStatusConstants.PUBLISHED,
      ])

      this.fetchCompanyNewsletterByCompanyId({
        companyId: this.$route.params.companyid,
        constraints,
      })
        .then((companyNewslettersResponse) => {
          if (!this.isEmpty(companyNewslettersResponse)) {
            // console.log(JSON.stringify(companyNewslettersResponse))
            this.setCompanyNewsletters(companyNewslettersResponse)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    gotoViewCompanyNewsletter(companyNewsletter) {
      this.setCompanyNewsletterToView(companyNewsletter)
      this.setIsUserCompanyAdmin(this.isUserCompanyAdmin)
      this.setIsUserInSameCompany(this.isUserInSameCompany)
      this.$router.push({
        name: 'viewcompanynewsletter',
        params: {
          companyid: companyNewsletter.companyId,
          id: companyNewsletter.id,
        },
      })
    },
    gotoAddCompanyNewsletterScreen() {
      this.setCompanyToEdit(this.companyToView)
      this.$router.push({
        name: 'addcompanynewsletter',
        params: {
          recordid: this.companyToView.id,
        },
      })
    },
    gotoListAllNewslettersScreen() {
      this.setCompanyToEdit(this.companyToView)
      this.setIsUserCompanyAdmin(this.isUserCompanyAdmin)
      this.$router.push({
        name: 'listallcompanynewsletters',
        params: {
          recordid: this.getAuthUserCompanyId,
        },
      })
    },
    gotoAddNewsletteradmin() {
      this.setCompanyToEdit(this.companyToView)
      this.$router.push({
        name: 'addcompanynewsletteradmin',
        params: {
          recordid: this.$route.params.companyid,
        },
      })
    },
    openEditNewsletterAdminModal() {
      this.setCompanyToEdit(this.companyToView)
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: EditCompanyNewsletterAdminModal,
        events: {
          remove_successful: (newsletterAdmin) => {
            this.handleSuccessMessage(
              `${newsletterAdmin.displayName} was sucessfully removed as newsletter admin.`,
            )
          },
        },
        trapFocus: true,
      })
    },
  },
}
</script>
