// VISIBLE_TO_NONE: 90,
// VISIBLE_TO_ALL: 91,
// VISIBLE_TO_ORG_ONLY: 92,
// VISIBLE_TO_MY_NW: 93,
// PROFILE_USERS_CHOICE: null,
export default {
  profileUserPanelsConfig: undefined,
  mergedPanelsConfig: undefined,
  profileUserSchedulerDefaults: undefined,
}
