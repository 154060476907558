import state from './privateinfo.state'
import mutations from './privateinfo.mutations'
import actions from './privateinfo.actions'
import getters from './privateinfo.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
