/* eslint-disable no-unused-vars */
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'

export default {
  /**
   * Get  by id
   */
  getLeisureById: (state) => (leisureId) =>
    find(state.leisure, (leisure) => leisureId.id === leisureId),

  getAllBooksFromLeisures(state) {
    try {
      return state.leisures.filter(
        (leisuresRecord) => leisuresRecord.type === 'book',
      )
    } catch (error) {
      return null
    }
  },

  getAllShowsFromLeisures(state, type, completed = false) {
    try {
      return state.leisures.filter(
        (leisuresRecord) => leisuresRecord.type === 'show',
      )
    } catch (error) {
      return null
    }
  },
  getAllPodcastsFromLeisures(state, type, completed = false) {
    try {
      return state.leisures.filter(
        (leisuresRecord) => leisuresRecord.type === 'podcast',
      )
    } catch (error) {
      return null
    }
  },
  getAllTeamsFromLeisures(state, type, completed = false) {
    try {
      const response = state.leisures.filter(
        (leisuresRecord) => leisuresRecord.type === 'team',
      )

      return orderBy(response, ['siynemail_id'], ['desc'])
    } catch (error) {
      return null
    }
  },
  getAllLikesFromLeisures(state, type, completed = false) {
    try {
      const response = state.leisures.filter(
        (leisuresRecord) => leisuresRecord.type === 'likes',
      )
      return orderBy(response, ['id'], ['asc'])
    } catch (error) {
      return null
    }
  },
}
