<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="form add-company-broadcast">
    <Skeleton v-if="isLoading"></Skeleton>
    <synform
      :title="formTitle"
      :operation="addCompanyBroadcastVue"
      :button-text="formTitle"
      :modal-form="true"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <b-notification v-if="showError" type="is-danger is-light"
        ><span v-html="errorMessage"></span
      ></b-notification>
      <b-message class="my-2" type="is-info" size="is-small">
        Only one broadcast allowed per hour. They will appear on top of screen
        on dashboard and expire in 5 days.
      </b-message>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="broadcastText"
        rules="required|min:2|max:85"
      >
        <b-field
          :label="`${showLabelsFromCode(
            'teams/broadcast',
          )} ${showLabelsFromCode('generic/text')}`"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="required"
        >
          <b-input
            ref="broadcastText"
            v-model.trim="broadcast.broadcastText"
            name="broadcastTitle"
            required
            type="textarea"
            minlength="2"
            maxlength="85"
            placeholder="Add broadcast text"
          >
          </b-input>
        </b-field>
      </ValidationProvider>
    </synform>
  </div>
</template>

<script>
/* eslint-disable import/extensions */
// import debounce from 'lodash/debounce'
import { mapActions, mapMutations, mapState } from 'vuex'
import { editCompanyMixin } from '@/mixins/company/index'
import { notificationActionTypesConstants } from '@/data/notificationActionTypes'

export default {
  name: 'AddCompanyBroadcastModalForm',
  components: {},
  mixins: [editCompanyMixin],
  data() {
    return {
      notificationActionTypesConstants,
      formTitle: `${this.showLabelsFromCode(
        'generic/add',
      )} ${this.showLabelsFromCode('teams/broadcast')}`,
      broadcast: {
        broadcastText: '',
      },
    }
  },
  computed: {
    ...mapState('company', ['companyToEdit']),
  },
  mounted() {
    this.isLoading = false
    if (this.$refs.broadcastText) {
      this.$refs.broadcastText.focus()
    }
  },
  methods: {
    ...mapActions('company', ['createCompanyBroadcast']),
    ...mapMutations('company', ['addToCompanyBroadcast']),

    addCompanyBroadcastVue() {
      this.broadcast = {
        ...this.broadcast,
        createdBy: {
          id: this.user.id,
          displayName: this.user.displayName,
        },
      }
      this.createCompanyBroadcast({
        companyBroadcast: this.broadcast,
        companyId: this.companyToEdit.id,
      })
        .then((newBroadcast) => {
          this.addToCompanyBroadcast(newBroadcast)
          this.trackEngagementEvent('broadcast_added', `Uid ${this.user.id}`)
          this.$emit('added_successfully', newBroadcast)
          this.close()
        })
        .catch((err) => {
          this.trackExceptionEvent(
            'broadcast_add_failed',
            `Uid ${this.user.id} Message: ${err.message}`,
          )
          this.showError = true
          this.errorMessage = `${this.showUserMessageFromCode(
            'form/generic/errorMessage',
          )}. Code : <b>'${err.code}'</b>`
          console.log(err)
        })
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
