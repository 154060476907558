/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */

import isEmpty from 'lodash/isEmpty'

import UserUpdatesDB from '@/firebase/notifications/user-updates-db'

export default {
  fetchUserUpdatesByUserId: async ({ commit }, payload) => {
    const userUpdatesDB = new UserUpdatesDB(payload.userId)
    return userUpdatesDB.readAll(payload.constraints)
  },

  fetchUserUpdateById: async ({ commit }, payload) => {
    const userUpdatesDB = new UserUpdatesDB(payload.userId)
    return userUpdatesDB.read(payload.redirectId)
  },

  addUserUpdate: async ({ commit }, payload) => {
    // ` console.log(JSON.stringify(payload))
    const userUpdatesDB = new UserUpdatesDB(payload.userId)
    return userUpdatesDB.create(payload.updateRecord)
  },

  // updateUserRedirect: async ({ commit }, payload) => {
  //   const userUpdatesDB = new UserUpdatesDB(payload.userId)
  //   return userUpdatesDB.update(payload.redirectRecord)
  // },

  // deleteUserRedirect: async ({ commit }, payload) => {
  //   const userUpdatesDB = new UserUpdatesDB(payload.userId)
  //   return userUpdatesDB.delete(payload.redirectId)
  // },
}
