const TeamBookingSchedulingTypesConstants = {
  COLLECTIVE: 'collective',
  MAXFAIRNESS: 'round-robin-maximize-fairness',
  MAXAVAILABLE: 'round-robin-maximize-availability',
}

const TeamBookingSchedulingTypes = [
  {
    id: TeamBookingSchedulingTypesConstants.COLLECTIVE,
    name: 'All members are available',
  },
  {
    id: TeamBookingSchedulingTypesConstants.MAXFAIRNESS,
    name: 'Atleast 50% of members are available',
  },
  {
    id: TeamBookingSchedulingTypesConstants.MAXAVAILABLE,
    name: 'Atleast one member is available',
  },
]

export { TeamBookingSchedulingTypes, TeamBookingSchedulingTypesConstants }
