export default {
  setTeamBookings: (state, teamBookings) => (state.teamBookings = teamBookings),

  addToTeamBooking: (state, teamBooking) =>
    state.teamBookings.push(teamBooking),

  setTeamBookingUpdate(state, teamBooking) {
    const index = state.teamBookings.findIndex(
      (item) => item.id === teamBooking.id,
    )
    if (index !== -1) {
      state.teamBookings[index] = teamBooking
    }
  },

  setTeamBookingToRemove(state, teamBookingId) {
    const index = state.teamBookings.findIndex(
      (item) => item.id === teamBookingId,
    )
    if (index !== -1) {
      state.teamBookings.splice(index, 1)
    }
  },

  setTeamBookingToView: (state, teamBookingToView) =>
    (state.teamBookingToView = teamBookingToView),

  setTeamBookingToEdit: (state, teamBookingToEdit) =>
    (state.teamBookingToEdit = teamBookingToEdit),

  setTeamBookingNotFoundError: (state, value) =>
    (state.teamBookingErrors.bookingNotFound = value),

  setTeamBookingExpiredError: (state, value) =>
    (state.teamBookingErrors.bookingExpired = value),

  setTeamBookingIsPrivateError: (state, value) =>
    (state.teamBookingErrors.bookingIsPrivate = value),

  setTeamNoMoreBookingsAvailableError: (state, value) =>
    (state.teamBookingErrors.noMoreBookingsAvailable = value),
}
