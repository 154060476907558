export default {
  teamEvents: undefined,
  teamEventToView: undefined,
  teamEventToEdit: undefined,
  isUserATeamEventSubscriber: false,
  isUserTeamEventAttendee: false,
  teamEventFileList: null,
  teamMeetingNotes: undefined,
  teamMeetingNoteToView: undefined,
  teamMeetingNoteToEdit: undefined,
  teamEventErrors: {
    teamEventNotFound: false,
    notAllowed: false,
    unknown: false,
  },
}
