export default {
  setEvents: (state, events) => (state.events = events),

  setEvent: (state, event) => state.events.push(event),

  setAllEvents: (state, events) => (state.allEvents = events),

  addToAllEvents: (state, events) => state.allEvents.push(...events),

  removeEventById: (state, eventsId) => {
    const index = state.events.findIndex((events) => events.id === eventsId)
    state.events.splice(index, 1)
  },

  setEventsConfig: (state, eventsConfig) => (state.eventsConfig = eventsConfig),

  setEventToEdit: (state, eventToEdit) => (state.eventToEdit = eventToEdit),

  setEventUpdate(state, event) {
    const index = state.events.findIndex((item) => item.id === event.id)
    if (index !== -1) {
      Object.assign(state.events[index], event)
    }
  },
  setEventComments: (state, eventComments) =>
    (state.eventComments = eventComments),

  addToEventComment: (state, eventComment) => {
    try {
      state.eventComments.push(eventComment)
    } catch (error) {
      console.log(error)
    }
  },

  removeEventCommentById: (state, commentId) => {
    if (state.eventComments && state.eventComments.length > 0) {
      const index = state.eventComments.findIndex(
        (comment) => comment.id === commentId,
      )
      state.eventComments.splice(index, 1)
    }
  },
}
