export default {
  setTeamEvents: (state, teamEvents) => (state.teamEvents = teamEvents),

  setTeamEvent: (state, teamEvent) => state.teamEvents.push(teamEvent),

  setTeamEventUpdate(state, teamEvent) {
    const index = state.teamEvents.findIndex((item) => item.id === teamEvent.id)
    if (index !== -1) {
      state.teamEvents[index] = teamEvent
    }
  },

  setTeamEventToRemove(state, teamEventId) {
    const index = state?.teamEvents.findIndex((item) => item.id === teamEventId)
    if (index !== -1) {
      state.teamEvents.splice(index, 1)
    }
  },

  setTeamEventToView: (state, teamEventToView) =>
    (state.teamEventToView = teamEventToView),

  setTeamEventToEdit: (state, teamEventToEdit) =>
    (state.teamEventToEdit = teamEventToEdit),

  setIsUserATeamEventSubscriber: (state, value) =>
    (state.isUserATeamEventSubscriber = value),

  setIsUserTeamEventAttendee: (state, value) =>
    (state.isUserTeamEventAttendee = value),

  setTeamEventNotFoundError: (state, value) =>
    (state.teamEventErrors.teamEventNotFound = value),

  setTeamEventUnknownError: (state, value) =>
    (state.teamEventErrors.unknown = value),

  addSubscriberToTeamEventToView(state, subscriber) {
    if (state.teamEventToView.subscribers) {
      state.teamEventToView.subscribers.push(subscriber)
    } else {
      state.teamEventToView.subscribers = []
      state.teamEventToView.subscribers.push(subscriber)
    }
  },

  removeSubscriberById: (state, subscriberId) => {
    const index = state.teamEventToView.subscribers.findIndex(
      (subscriber) => subscriber === subscriberId,
    )
    state.teamEventToView.subscribers.splice(index, 1)
  },

  addAttendeeToTeamEventToView: (state, attendee) =>
    state.teamEventToView.emailTo.push(attendee),

  removeAttendeeById: (state, attendeeId) => {
    const index = state.teamEventToView.emailTo.findIndex(
      (attendee) => attendee.id === attendeeId,
    )
    state.teamEventToView.emailTo.splice(index, 1)
  },

  setTeamMeetingNotes: (state, teamMeetingNotes) =>
    (state.teamMeetingNotes = teamMeetingNotes),

  setTeamMeetingNote: (state, teamsMeetingNote) =>
    state.teamsMeetingNotes.push(teamsMeetingNote),

  setTeamMeetingNoteToView: (state, teamMeetingNoteToView) =>
    (state.teamMeetingNoteToView = teamMeetingNoteToView),

  setTeamMeetingNoteToEdit: (state, teamMeetingNoteToEdit) =>
    (state.teamMeetingNoteToEdit = teamMeetingNoteToEdit),

  // setTeamMeetingNoteUpdate(state, teamMeetingNote) {
  //   const index = state.teamMeetingNotes.findIndex(
  //     (item) => item.id === teamMeetingNote.id
  //   )
  //   if (index !== -1) {
  //     state.teamMeetingNotes[index].notes = teamMeetingNote.notes
  //   }
  // },
  setMeetingNotesUpdate(state, teamMeetingNote) {
    // console.log(JSON.stringify(teamMeetingNote))
    const index = state.teamMeetingNotes.findIndex(
      (item) => item.id === teamMeetingNote.id,
    )
    // console.log(JSON.stringify(index))
    if (index !== -1) {
      state.teamMeetingNotes[index] = teamMeetingNote
    }
  },
  setTeamMeetingNoteToRemove(state, teamMeetingNoteIdToRemove) {
    const index = state.teamMeetingNotes.findIndex(
      (item) => item.id === teamMeetingNoteIdToRemove,
    )
    if (index !== -1) {
      state.teamMeetingNotes.splice(index, 1)
    }
  },

  removeOneTimeTeamEventMeetingNotes(state) {
    state.teamEventToView.meetingNotes = null
  },
}
