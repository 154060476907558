<template>
  <section class="view-company-configs">
    <skeleton v-if="isLoading"></skeleton>
    <SiynCollapse
      v-if="!isLoading"
      :title="panelTitle"
      emoji="⚙️"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
      ><template #header-extras>
        <b-tooltip
          multilined
          class="mx-4"
          type="is-dark"
          label="Controls the visibility of each panel on a user's profile. Only visible to employees of the company. "
          position="is-right"
          ><b-icon
            class="has-text-grey-lighter"
            pack="fas"
            icon="info-circle"
            size="is-small"
          >
          </b-icon
        ></b-tooltip>
      </template>
      <template #default>
        <div
          v-if="companyPanelsConfig"
          class="columns is-multiline is-variable is-1 mt-2"
        >
          <template v-for="(value, propertyName) in companyPanelsConfig">
            <template
              v-if="
                propertyName !== 'id' &&
                propertyName !== 'isPublicallyVisible' &&
                propertyName !== 'updateTimestamp' &&
                propertyName !== 'createTimestamp' &&
                propertyName !== 'lastUpdatedBy'
              "
            >
              <div :key="propertyName" class="column is-6">
                <span class="sm-label mr-2"
                  >{{ convertPanelName(propertyName) }} Panel :
                </span>
                <span class="sm-value">
                  {{ getVisibilityPreferenceName(Number(value)) }}</span
                >
              </div>
            </template>
            <template v-if="propertyName === 'isPublicallyVisible'">
              <div :key="propertyName" class="column is-6">
                <span class="sm-label mr-2"
                  >Are user profiles visible for users outside of organization?
                </span>
                <span class="sm-value">{{
                  value === true ? 'Yes' : 'No'
                }}</span>
              </div>
            </template>
          </template>
        </div>
        <span v-if="isEmpty(companyPanelsConfig)" class="ml-4 empty-content"
          >{{ showUserMessageFromCode('generic/none') }}. Please contact your
          company admin ASAP</span
        >
        <div
          v-if="isUserCompanyAdmin || superAdmin === getAuthUserId"
          class="has-text-center-touch has-text-right mt-4"
        >
          <b-button
            v-if="companyPanelsConfig"
            class="view-company-configs__editbutton ml-2 background-color-override"
            type="is-danger"
            icon-left="edit"
            pack="far"
            title="Edit Company Configurations"
            @click.stop.prevent="gotoEditCompanyConfig()"
            >Edit Company Configurations
          </b-button>
        </div>
      </template>
    </SiynCollapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */
import startCase from 'lodash/startCase'
import { mapState, mapMutations } from 'vuex'
import { viewCompanyMixin } from '@/mixins/company/index'
import { visibilityPreferences } from '@/data/visibilityPreferences'

export default {
  name: 'CompanyConfig',
  components: {},

  mixins: [viewCompanyMixin],
  props: {
    operationType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      panelTitle: 'Profile Panel Visibility Configurations for Employees',
      panelname: 'company_configs',
      superAdmin: process.env.VUE_APP_SUPER_ADMIN,
      visibilityPreferences,
    }
  },
  computed: {
    ...mapState('company', ['companyPanelsConfig']),
  },
  watch: {},
  created() {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('company', ['setCompanyPanelsConfigToEdit']),
    getVisibilityPreferenceName(id) {
      if (id) {
        return this.getNameForId(id, this.visibilityPreferences)
      }
      return "👍🏽 Profile User's Choice"
    },
    gotoEditCompanyConfig() {
      this.setCompanyPanelsConfigToEdit(this.companyPanelsConfig)
      this.$router.push({
        name: 'reauthenticate',
        params: {
          routeName: 'editcompanypanelsconfig',
          recordid: this.companyToView.id,
        },
      })
    },
    convertPanelName(panelname) {
      let response = ''
      switch (panelname) {
        case 'privateInfo':
          response = 'Contact'
          break
        case 'comments':
          response = 'About'
          break
        default:
          response = startCase(panelname)
          break
      }
      return response
    },
  },
}
</script>
<style scoped lang="scss">
.column.is-6 {
  border-bottom: 0.5px solid $body-color;
  border-right: 0.5px solid $body-color;
}
.columns {
  border: 0.5px solid $body-color;
}
</style>
