/* eslint-disable no-unused-vars */
import TeamsDB from '@/firebase/teams/teams-db'
import TeamMembersDB from '@/firebase/teams/team-team-members'
import { teamMemberStatusConstants } from '@/data/teams/team-member-status'

import {
  createTeamMemberBatch,
  updateTeamMemberBatch,
  removeTeamMemberBatch,
  updateTeamMemberStatusBatch,
} from '../teams/teamsBatch'

export default {
  fetchTeamMembersByTeamId: async ({ commit }, payload) => {
    const teamMembersDB = new TeamMembersDB(payload.id)
    const teamMembers = await teamMembersDB.readAll()
    return teamMembers ? Object.keys(teamMembers).map((o) => teamMembers[o]) : null
  },
  // TODO : Tried to combine above and below into single function but it caused a lot of ruckus so just left it as it, in future this can be improved
  createTeamMemberAction: async ({ rootState }, payload) => {
    return createTeamMemberBatch(payload)
  },

  getTeamMemberById: async ({ commit }, payload) => {
    commit('setTeamMemberToEdit', null)
    const teamMembersDB = new TeamMembersDB(payload.teamId)
    const teamMember = await teamMembersDB.read(payload.teamMemberId)
    if (teamMember) {
      commit('setTeamMemberToEdit', teamMember)
      return true
    }
    return null
  },
  updateTeamMemberBatch: async ({ rootState }, payload) => {
    return updateTeamMemberBatch(payload).catch((error) => {
      throw error
    })
  },
  updateTeamImageURL: async ({ commit }, payload) => {
    commit('setTeamUpdatePending', true)
    const TeamsDb = new TeamsDB(payload.teamId)
    await TeamsDb.update(payload)
    commit('updateTeamImage', payload.logoImageURL)
    commit('setTeamUpdatePending', false)
  },
  removeTeamMemberById: async ({ commit }, payload) => {
    return removeTeamMemberBatch(payload)
  },

  updateTeamMemberStatus: async ({ commit }, payload) => {
    return updateTeamMemberStatusBatch(payload)
  },
}
