export default {
  setTopics: (state, value) => (state.topics = value),
  addToMentoringTopic: (state, value) => state.topics.push(value),
  setRequestsSend: (state, value) => (state.requestssend = value),
  setRequestsReceived: (state, value) => (state.requestsreceived = value),

  setMentoringTopicToEdit: (state, topicToEdit) => (state.topicToEdit = topicToEdit),
  setMentoringTopicToView: (state, topicToView) => (state.topicToView = topicToView),

  setMentoringTopicUpdate(state, topic) {
    // console.log(JSON.stringify(topic))
    const index = state.topics.findIndex((item) => item.id === topic.id)

    if (index !== -1) {
      // TODO Couldn't figure out why the table was not getting updated if i copied the entire object, hence had to do field by field
      state.topics[index].id = topic.id
      state.topics[index].topicTitle = topic.topicTitle
      state.topics[index].duration = topic.duration
      state.topics[index].prerequisties = topic.prerequisties
      state.topics[index].description = topic.description
    }
  },

  setMentoringTopicToRemove(state, topicId) {
    const index = state.topics.findIndex((item) => item.id === topicId)
    if (index !== -1) {
      state.topics.splice(index, 1)
    }
  },

  setMentoringSessionToEdit: (state, mentoringSessionToEdit) =>
    (state.mentoringSessionToEdit = mentoringSessionToEdit),
  setMentoringSessionToView: (state, mentoringSessionToView) =>
    (state.mentoringSessionToView = mentoringSessionToView),

  setMentoringSessionsAsMentor: (state, mentoringSessionsAsMentor) =>
    (state.mentoringSessionsAsMentor = mentoringSessionsAsMentor),
  setMentoringSessionsAsMentee: (state, mentoringSessionsAsMentee) =>
    (state.mentoringSessionsAsMentee = mentoringSessionsAsMentee),
}
