const schedulerVisibilityConstants = {
  PUBLIC: '41',
  COLLEAGUES: '76',
  NETWORK: '69',
}
const schedulerVisibilityPreferences = [
  {
    id: schedulerVisibilityConstants.PUBLIC,
    name: '🌐 Public',
  },
  {
    id: schedulerVisibilityConstants.COLLEAGUES,
    name: '🏢 For Colleagues',
  },
  {
    id: schedulerVisibilityConstants.NETWORK,
    name: '🖧 For Network',
  },
]
export { schedulerVisibilityConstants, schedulerVisibilityPreferences }
