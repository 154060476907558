<!-- For imgurl we dont know E.g. 
    <small-card :uid="user.id" :display-name="person.name" :title="person.title"  :company-name="person.companyName"/> -->
<!--For imgurl we do know E.g. 
     <small-card :imgsrc="user.photoURL" :display-name="person.name" :title="person.title"  :company-name="person.companyName"/> -->

<template>
  <router-link
    class="no-text-decoration"
    :to="{
      name: 'profile',
      params: { uid: uid },
    }"
  >
    <div class="small-card">
      <div class="small-card__media">
        <view-user-avatar
          class="small-card__media--photoURL"
          :alt-name="displayName"
          :uid="uid"
          :imgsrc="imgsrc"
        />
      </div>
      <div class="small-card__media-content">
        <span class="small-card__media-content--displayName">{{
          displayName
        }}</span>
        <span v-if="title" class="small-card__media-content--title truncate">{{
          title || ''
        }}</span>
        <span v-if="companyName" class="small-card__media-content--company"
          ><span class="has-text-grey">@</span> {{ companyName || '' }}</span
        >
      </div>
    </div>
  </router-link>
</template>

<script>
/* eslint-disable import/extensions */
import ViewUserAvatar from '@/components/ViewUserAvatar'

export default {
  name: 'SmallCard',
  components: { ViewUserAvatar },
  props: {
    uid: {
      type: String,
      default: null,
      required: true,
    },
    imgsrc: {
      type: String,
      default: null,
    },
    displayName: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
    companyName: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.small-card {
  min-width: 0;
  min-height: 0;
  max-width: 340px;
  height: auto;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  gap: $min-size;
  &__media {
    margin-top: $min-size;
    // for whatever reason I am unable to add height of image here so had to add it in useravatar.scss
  }
  &__media-content {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    &--title {
      display: block;
      color: $grey-label;
      font-size: 0.8 * $base-size;
      line-height: 12px;
      max-width: 150px;
    }
    &--company {
      display: block;
      @include clearfix;
      color: $body-color;
      font-size: 0.8 * $base-size;
    }
  }
}
.search-people,
.search-teams,
.search-mentoring-topics,
.search-network {
  .small-card {
    img {
      height: 20px;
      width: 20px;
    }
  }
}
</style>
