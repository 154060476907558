import { mapState, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import FullPage from '@/components/partials/FullPage'
import SynPanel from '@/components/SynPanel'

import {
  schedulerVisibilityConstants,
  schedulerVisibilityPreferences,
} from '@/data/schedulingVisibility'
import {
  showUserMessageFromCode,
  showLabelsFromCode,
  showTooltipsFromCode,
  getNameForId,
  checkIfYouAreInMyNetwork,
} from '@/misc/helpers'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'
import {
  trackExperienceEvent,
  trackExceptionEvent,
  trackEngagementEvent,
  trackConversionEvent,
} from '@/misc/analytics'

const viewSchedulerMixin = {
  components: {
    FullPage,
    SynPanel,
  },
  data() {
    return {
      schedulerVisibilityConstants,
      schedulerVisibilityPreferences,
      showError: false,
      isPanelVisible: false,
      isLoading: true,
    }
  },
  computed: {
    ...mapState('user', ['user', 'userCompany']),

    ...mapState('app', ['appURL', 'appTitle']),
    ...mapGetters('user', [
      'getAuthUserId',
      'getAuthUserCompanyId',
      'getAuthUserLicenseType',
      'getAuthUserRole',
      'getTinyUser',
      'isUserInSameCompany',
      'isPaidUser',
      'isEnterpriseUser',
      'isGuestUser',
    ]),
  },
  methods: {
    showLabelsFromCode,
    showTooltipsFromCode,
    showUserMessageFromCode,
    handleErrorMessage,
    handleSuccessMessage,
    trackExperienceEvent,
    trackExceptionEvent,
    trackEngagementEvent,
    trackConversionEvent,
    getNameForId,
    isEmpty,
    checkIfYouAreInMyNetwork,
    setAltImg(e) {
      e.target.onerror = null
      e.target.src = this.noCoverImage
    },
    getCompleted(leisureType) {
      return leisureType.filter(
        (leisuresRecord) => leisuresRecord.completed === false,
      )
    },
  },
}

export default viewSchedulerMixin
