/* eslint-disable no-unused-vars */
import { mapState, mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'
import Skeleton from '@/components/partials/Skeleton'
import Synform from '@/components/SynForm'
import FullPage from '@/components/partials/FullPage'
import SiynCollapse from '@/components/SiynCollapse'

import {
  showLabelsFromCode,
  showTooltipsFromCode,
  showUserMessageFromCode,
  getNameForId,
  collapseHelper,
} from '@/misc/helpers'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'
import {
  trackExperienceEvent,
  trackExceptionEvent,
  trackEngagementEvent,
} from '@/misc/analytics'
import {
  schedulerVisibilityConstants,
  schedulerVisibilityPreferences,
} from '@/data/schedulingVisibility'

import {
  TeamBookingSchedulingTypes,
  TeamBookingSchedulingTypesConstants,
} from '@/data/teams/team-booking-scheduling-types'

import router from '@/router'

const editProfileMixin = {
  components: {
    Synform,
    Skeleton,
    ValidationProvider,
    FullPage,
    SiynCollapse,
  },
  data() {
    return {
      showError: false,
      TeamBookingSchedulingTypes,
      TeamBookingSchedulingTypesConstants,
      schedulerVisibilityConstants,
      schedulerVisibilityPreferences,
      errorMessage: `😕 ${this.showUserMessageFromCode(
        'form/generic/errorMessage',
      )}`,
    }
  },
  computed: {
    ...mapState('user', ['user', 'userCompany']),
    ...mapState('app', ['appTitle', 'appShortTitle', 'byline']),
    ...mapGetters('user', [
      'isUserLoggedIn',
      'getAuthUserId',
      'getAuthUserCompanyId',
      'getAuthUserLicenseType',
      'getAuthUserRole',
      'getAuthUserEmailAddress',
      'getTinyUser',
      'isPaidUser',
      'isEnterpriseUser',
    ]),
    ...mapState('appconfig', ['profileUserPanelsConfig']),
  },
  mounted() {
    if (this.$route?.params?.uid) {
      if (this.$route?.params?.uid !== this.user?.id) {
        handleErrorMessage(
          'Illegal Operation, redirecting to Edit Profile screen',
        )

        router.replace({ path: `/edit/profile` })
      }
    }
  },
  methods: {
    showLabelsFromCode,
    showTooltipsFromCode,
    showUserMessageFromCode,
    getNameForId,
    trackExperienceEvent,
    trackExceptionEvent,
    trackEngagementEvent,
    handleErrorMessage,
    handleSuccessMessage,
    isEmpty,
    collapseHelper,
  },
}

export default editProfileMixin
