/* eslint-disable import/extensions */
import state from './team-links.state'
import mutations from './team-links.mutations'
import actions from './team-links.actions'
import getters from './team-links.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
