import state from './redirects.state'
import mutations from './redirects.mutations'
import actions from './redirects.actions'
import getters from './redirects.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
