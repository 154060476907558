<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="form add-company-location">
    <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="false">
      <b-icon
        pack="fas"
        icon="spinner"
        size="is-large"
        custom-class="fa-pulse"
        type="is-primary"
      >
      </b-icon
    ></b-loading>
    <synform
      v-if="companyToEdit && !isLoading"
      :title="panelTitle"
      :operation="addCompanyLocationVue"
      :button-text="panelTitle"
      :modal-form="true"
    >
      <b-notification
        v-if="showError"
        type="is-danger is-light"
        aria-close-label="Close notification"
        class="sm-help-notification"
        ><span v-html="errorMessage"></span
      ></b-notification>

      <b-field
        label="Company Location"
        message="You can paste an address if it is not found by search"
        expanded
        class="required"
      >
        <b-autocomplete
          v-model="address"
          required
          :data="data"
          placeholder="Type Address"
          field="address"
          :loading="isFetching"
          keep-first
          clearable
          name="address"
          icon-pack="fas"
          @typing="getAsyncData"
          @select="addressSelected($event)"
        >
          <template slot-scope="props">
            <div class="add-company-location__address">
              <b>{{ props.option.address }}</b>
            </div>
          </template>
        </b-autocomplete>
      </b-field>
    </synform>
  </div>
</template>

<script>
/* eslint-disable import/extensions */

import { mapMutations, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import axios from 'axios'
import { editCompanyMixin } from '@/mixins/company/index'

export default {
  name: 'AddCompanyLocationFormModal',
  components: {},
  mixins: [editCompanyMixin],
  data() {
    return {
      baseAPIURL: process.env.VUE_APP_TOMTOM_BASEAPIURL,
      apiKey: process.env.VUE_APP_TOMTOM_APIKEY,
      panelTitle: 'Add Company Location',
      address: '',
      addressObject: null,
      isLoading: true,
      showError: '',
      errorMessage: '',
    }
  },
  computed: {},
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('company', ['addToCompanyLocations']),
    ...mapActions('company', ['createCompanyLocation', 'addCompanyUpdate']),
    addCompanyLocationVue() {
      this.isLoading = true
      const createdBy = {
        id: this.user.id,
        displayName: this.user.displayName,
      }
      const companyLocation = {
        addressObject: this.addressObject || this.address,
        createdBy,
      }
      this.createCompanyLocation({
        companyLocation,
        companyId: this.companyToEdit.id,
      })
        .then((batchResponse) => {
          // console.log(JSON.stringify(batchResponse))
          this.addToCompanyLocations({
            address:
              batchResponse?.addressObject?.address?.freeformAddress ||
              this.address,
            id: batchResponse.id,
          })
          this.trackEngagementEvent(
            'company_location_added',
            `${this.user.displayName} added company location ${this.address}`,
            'page',
            'button',
          )
          this.$emit('added_successfully', batchResponse)
          this.close()
        })
        .catch((err) => {
          this.trackExceptionEvent(
            'company-location_add_failed',
            `By Uid ${this.user.id} Code: ${err.code}`,
          )
          this.showError = true
          this.errorMessage = `${this.showUserMessageFromCode(
            'form/generic/errorMessage',
          )}<br/> Code : <b>'${err.code}'</b>`
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // eslint-disable-next-line func-names
    getAsyncData: debounce(function (name) {
      // String update
      if (this.name !== name) {
        this.name = name
        this.data = []
      }
      // String cleared
      if (!name.length) {
        this.data = []
        return
      }
      this.isFetching = true

      axios
        .get(
          `${this.baseAPIURL}${name}.json?language=en-US&typeahead=true&key=${this.apiKey}`,
        )
        .then((response) => {
          this.data = response.data.results.map((v) => {
            return {
              address: v.address.freeformAddress,
              addressObject: v,
            }
          })
          // console.log(JSON.stringify(this.data))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.isFetching = false
        })
    }, 500),
    addressSelected($event) {
      if ($event) {
        this.address = $event.address
        this.addressObject = $event.addressObject
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
