<template>
  <section class="events">
    <skeleton v-if="isLoading"></skeleton>
    <siyn-collapse
      v-if="!isLoading && allEvents && allEvents.length > 0 && showEventsPanel"
      :title="panelTitle"
      emoji="📆"
      :is-open="collapseHelper(panelname)"
      :panelname="panelname"
    >
      <template #header-extras>
        <b-tooltip
          multilined
          class="mx-4"
          type="is-dark"
          :label="showTooltipsFromCode('profile/events/panelInfo')"
          position="is-right"
          ><b-icon
            class="has-text-grey-lighter"
            pack="fas"
            icon="info-circle"
            size="is-small"
          >
          </b-icon
        ></b-tooltip>
      </template>
      <template #default>
        <list-events-table
          :events="allEvents"
          :uid="uid2"
          mode="VIEW"
          :pagination="false"
        ></list-events-table>
        <!-- <p v-if="user" class="ml-2">
          <router-link
            class="no-text-decoration"
            :to="{ name: 'viewevents', params: { uid: uid2 } }"
            >📜
            {{
              showLabelsFromCode('profile/events/upcomingEvents')
            }}</router-link
          >
        </p> -->
        <p v-if="!allEvents && !isLoading" class="ml-4">
          <span>{{ showUserMessageFromCode('generic/none') }}</span>
        </p>
      </template>
    </siyn-collapse>
  </section>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapActions, mapMutations } from 'vuex'

import { viewProfileMixin } from '@/mixins/profile/index'
import ListEventsTable from '@/views/events/partials/ListEventsTable'

export default {
  name: 'ViewEvents',
  components: { ListEventsTable },
  mixins: [viewProfileMixin], // register mixin
  data() {
    return {
      panelTitle: this.showLabelsFromCode('profile/events/panelTitle'),
      panelname: 'events_view',
      isLoading: true,
      uid2: this.$route.params.uid,
      profileUserisOOO: false,
      showEventsPanel: false,
      teamEventsData: undefined,
      companyEventsData: undefined,
    }
  },
  computed: {
    ...mapState('events', ['events', 'allEvents']),
  },
  watch: {
    mergedPanelsConfig: {
      handler(mergedPanelsConfig) {
        if (!this.isEmpty(mergedPanelsConfig) && this.profileUser) {
          // console.log(JSON.stringify(mergedPanelsConfig))
          if (
            (this.getAuthUserLicenseType === 'ENT' &&
              this.getAuthUserId === this.$route.params.uid) ||
            (this.getAuthUserCompanyId === this.profileUser.companyId &&
              this.getAuthUserLicenseType === 'ENT')
          ) {
            // console.log(JSON.stringify('go fetch ENT events for me'))
            this.fetchEventContent()
            setTimeout(() => {
              this.fetchTeamEvents()
              this.fetchCompanyEvents()
            }, 500)

            this.showEventsPanel = true
          } else if (
            mergedPanelsConfig.events ===
            this.visibilityConstants.VISIBLE_TO_ALL
          ) {
            // console.log('Show if events are  visible to all')
            this.fetchEventContent()
            this.showEventsPanel = true
          } else if (
            mergedPanelsConfig.events ===
              this.visibilityConstants.VISIBLE_TO_MY_NW &&
            this.checkIfYouAreInMyNetwork({
              userId: this.profileUser.id,
              network: this.network,
            })
          ) {
            // console.log('Show events because we are in n/w')
            this.fetchEventContent()
            this.showEventsPanel = true
          }
        }
      },
      deep: true,
      immediate: true,
    },
    allEvents: {
      handler(allEvents) {
        if (allEvents) {
          allEvents.every((event) => {
            // console.log(JSON.stringify(event))
            if (event.isOOO) {
              if (
                Date.now() >= event.startDateTime.getTime() &&
                Date.now() <= event.endDateTime.getTime()
              ) {
                this.$buefy.toast.open({
                  duration: 5000,
                  message: `📆 ${this.profileUser.displayName} is currently out of office`,
                  type: 'is-danger',
                  position: 'is-bottom-right',
                  pauseOnHover: true,
                })

                this.profileUserisOOO = true
                return false // "break"
              }
            }
            return true // must return true if doesn't break
          })
        }
      },
    },
  },
  mounted() {
    this.isLoading = false
    // console.log(JSON.stringify('events mounted'))
  },
  methods: {
    ...mapMutations('events', [
      'setEvents',
      'addToAllEvents',
      'setEventComments',
    ]),
    ...mapActions('events', ['fetchEventsByUserId']),
    ...mapActions('company', ['fetchCompanyEventsByCompanyIdAndUserId']),
    ...mapActions('teamsEvents', ['fetchTeamEventsByCompanyIdAndUserId']),
    getType(type) {
      return `${this.showLabelsFromCode(
        'profile/events/eventType',
      )} : ${this.getNameForId(type)}`
    },
    // Fetch user's personal events
    async fetchEventContent() {
      // console.log(JSON.stringify('fetch events'))
      this.setEventComments(null) // this is to ensure event comments are initialized and don't cause troubles, its a hack.
      await this.fetchEventsByUserId({
        userId: this.$route.params.uid,
        limit: 6,
        endDays: 30,
      })
        .then((eventsResponse) => {
          // console.log(JSON.stringify(eventsResponse))
          if (eventsResponse && eventsResponse.length > 0) {
            if (this.isEmpty(this.allEvents)) {
              this.addToAllEvents(eventsResponse)
            }
          }
        })
        .catch((err) => {
          if (err.code !== 'permission-denied') {
            console.log(err)
            this.handleErrorMessage(
              `${this.showUserMessageFromCode(
                'generic/error-in-fetch',
              )} events.`,
            )
          }
          this.setEvents(null)
          this.trackExceptionEvent(
            'events_fetch_failed',
            `${this.user ? this.user.id : 'visitor'} tried to fetch info for  ${
              this.$route.params.uid
            }. ${err}`,
            false,
          )
        })
    },
    // Fetch user's team events
    async fetchTeamEvents() {
      // console.log(JSON.stringify(this.getAuthUserCompanyId))
      await this.fetchTeamEventsByCompanyIdAndUserId({
        limit: 6,
        endDays: 30,
        companyId: this.user.companyId,
      })
        .then((teamEventsResponse) => {
          if (
            teamEventsResponse &&
            teamEventsResponse.length > 0 &&
            this.isEmpty(this.teamEventsData)
          ) {
            // console.log(JSON.stringify(teamEventsResponse))
            /**
             *  This is done because in Firebase you cannot query using more than 1 array
             *  hence I had to filter it on the client side. The user can be either an
             *  attendee or subscriber to an event.
             *
             * */
            const myTempTeamEvents = []

            teamEventsResponse.forEach((teamEvent) => {
              if (teamEvent.attendees && teamEvent.attendees.length > 0) {
                const temp = teamEvent.attendees.filter(
                  (attendee) => attendee.id === this.profileUser.id,
                )
                if (!this.isEmpty(temp)) {
                  myTempTeamEvents.push(teamEvent)
                }
              }

              if (teamEvent.subscribers && teamEvent.subscribers.length > 0) {
                const temp2 = teamEvent.subscribers.filter(
                  (subscriberId) => subscriberId === this.user.id,
                )
                if (!this.isEmpty(temp2)) {
                  myTempTeamEvents.push(teamEvent)
                }
              }
            })

            // console.log(JSON.stringify(myTempTeamEvents))

            this.teamEventsData = myTempTeamEvents
            this.addToAllEvents(myTempTeamEvents)
          }
        })
        .catch((error) => {
          console.log(error)
          if (error !== 'permission-denied') {
            this.handleErrorMessage('Issue in fetching team events')
          }
        })
    },

    // Fetch user's company events
    async fetchCompanyEvents() {
      const attendee = {
        id: this.profileUser.id,
        displayName: this.profileUser.displayName,
      }
      await this.fetchCompanyEventsByCompanyIdAndUserId({
        userId: this.$route.params.uid,
        limit: 6,
        endDays: 30,
        companyId: this.user.companyId,
        attendee,
      })
        .then((companyEventsResponse) => {
          if (
            companyEventsResponse &&
            companyEventsResponse.length > 0 &&
            this.isEmpty(this.companyEventsData)
          ) {
            this.companyEventsData = companyEventsResponse
            this.addToAllEvents(companyEventsResponse)
          }
        })
        .catch((error) => {
          console.log(error)
          if (error !== 'permission-denied') {
            this.handleErrorMessage('Issue in fetching company events')
          }
        })
    },
  },
}
</script>
<style lang="scss">
.notes {
  span {
    margin-right: $min-size * 0.5;
  }
}
</style>
