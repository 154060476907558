<template>
  <div class="addform">
    <b-loading v-model="isLoading" :is-full-page="true">
      <b-icon
        pack="fas"
        icon="spinner"
        size="is-large"
        custom-class="fa-pulse"
        type="is-primary"
      >
      </b-icon
    ></b-loading>
    <synform :title="panelTitle" :operation="contactUs" button-text="Submit">
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="type"
        rules="required"
      >
        <b-field
          label="Type"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="required"
        >
          <b-select
            id="type"
            v-model="form.type"
            placeholder="Select a type"
            name="type"
          >
            <option value="bug" selected>Report an Issue</option>
            <option value="feature">Request a feature</option>
            <option value="compliment">Compliment</option>
            <option value="comment">General Comments</option>
            <option value="question">Question</option>
            <option value="press">Press Inquiry</option>
          </b-select>
        </b-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="message"
        rules="required|max:200"
      >
        <b-field
          label="Message"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="required"
        >
          <b-input
            id="message"
            v-model.trim="form.message"
            maxlength="200"
            type="textarea"
            name="message"
          ></b-input>
        </b-field>
      </ValidationProvider>
    </synform>
  </div>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'
import {
  serverTimestamp as FirebaseServerTS,
  addDoc,
  collection,
} from 'firebase/firestore'
import { db } from '@/firebase/initFirebase'
import synform from '@/components/SynForm.vue'
import { handleSuccessMessage, handleErrorMessage } from '@/misc/user-messages'
import {
  trackPageView,
  trackScreenView,
  trackEngagementEvent,
  trackExceptionEvent,
} from '@/misc/analytics'

export default {
  name: 'ContactUs',
  components: {
    synform,
    ValidationProvider,
  },
  data() {
    return {
      panelTitle: 'Contact Us',
      isLoading: true,
      typeFromRoute: 'comment',
      form: {
        type: null,
        message: '',
      },
    }
  },
  metaInfo: {
    title: 'Contact Us',
    afterNavigation(metaInfo) {
      trackPageView()
      trackScreenView(metaInfo.title)
    },
  },
  computed: {
    ...mapGetters('user', ['isUserLoggedIn']),
    ...mapState('user', ['user']),
  },
  mounted() {
    this.isLoading = false
    if (this.$route.params.type) {
      this.form.type = this.$route.params.type
      document.getElementById('message').focus()
    }
  },
  methods: {
    async contactUs() {
      const validValues = [
        'bug',
        'feature',
        'compliment',
        'comment',
        'question',
        'press',
      ]
      if (validValues.includes(this.form.type)) {
        await addDoc(collection(db, 'contact', this.form.type, this.user.id), {
          message: this.form.message,
          read: false,
          user: {
            id: this.user.id,
            email: this.user.email,
            displayName: this.user.displayName,
            createTimeStamp: FirebaseServerTS,
          },
        })
          .then(() => {
            trackEngagementEvent(
              'contacted_by_user',
              this.form.type,
              'page',
              'button',
            )
            handleSuccessMessage(`Thank you contacting us!`)
            this.$router.push({ name: 'home' })
          })
          .catch((err) => {
            trackExceptionEvent('contacted_by_user_failed', err.message, false)
            handleErrorMessage(
              `We're sorry there was an error in submitting the form, please try again`,
            )
            console.log(err.message)
          })
      } else {
        this.$buefy.snackbar.open({
          message: `We're sorry there was an error in submitting the form, please try again`,
          type: 'is-danger',
          position: 'is-bottom-right',
          duration: 3000,
        })
      }
    },
  },
}
</script>

<style lang="scss"></style>
