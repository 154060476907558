export default {
  setTeamMembers: (state, teamMembers) => {
    // console.log(teamMembers)
    state.teamMembers = teamMembers
  },

  setNewTeamMember: (state, teamMember) => {
    // console.log(JSON.stringify(teamMember))
    state.teamMembers.push(teamMember)
  },

  setTeamMemberToEdit: (state, teamMemberToEdit) =>
    (state.teamMemberToEdit = teamMemberToEdit),

  setTeamMemberUpdate(state, teamMember) {
    const index = state.teamMembers.findIndex(
      (item) => item.id === teamMember.id
    )

    if (index !== -1) {
      state.teamMembers[index].memberRole = teamMember.memberRole || ''
      state.teamMembers[index].raci = teamMember.raci || ''
      state.teamMembers[index].isTeamAdmin = teamMember.isTeamAdmin || false
    }
  },

  setTeamMemberRemovalPending: (state, value) =>
    (state.teamMemberRemovalPending = value),

  setTeamMemberToRemove(state, teamMemberIdToRemove) {
    const index = state.teamMembers.findIndex(
      (item) => item.id === teamMemberIdToRemove
    )
    if (index !== -1) {
      state.teamMembers.splice(index, 1)
    }
  },

  setTeamMemberStatusUpdate: (state, payload) => {
    const index = state.teamMembers.findIndex(
      (item) => item.id === payload.teamMemberId
    )
    if (index !== -1) {
      state.teamMembers[index].memberStatus = payload.newStatus
    }
  },
}
