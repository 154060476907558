<template>
  <div class="list-screen">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-auto pb-1">
          <div class="box">
            <div class="list-screen__emoji">{{ emoji }}</div>
            <h1 class="list-screen__title">
              <span class="has-text-centered">{{ panelTitle }}</span>
            </h1>
          </div>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListPage',
  props: {
    panelTitle: {
      type: String,
      default: 'List Screen',
    },
    emoji: {
      type: String,
      default: '',
    },
  },
}
</script>
<style scoped lang="scss">
h1 {
  @include sm-title();
}
.list-screen {
  margin: 1rem 0 !important;

  &__emoji {
    @include sm-title();
    display: inline-block;
  }
  &__title {
    @include sm-title();
    display: inline-block;
  }
}
</style>
