/* eslint-disable no-unused-vars */

import UserSchedulingPagesDB from '@/firebase/schedulingpages/schedulingpages-db'

export default {
  fetchUserSchedulingPagesByUserId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const userSchedulingPagesDB = new UserSchedulingPagesDB(payload.userId)
    return userSchedulingPagesDB.readAllWithOrderByLimit(
      payload.constraints,
      payload.orderBys,
      payload.limit,
    )
  },

  fetchUserSchedulingPageById: async ({ commit }, payload) => {
    const userSchedulingPagesDB = new UserSchedulingPagesDB(payload.userId)
    return userSchedulingPagesDB.read(payload.id)
  },

  createUserSchedulingPage: async ({ commit }, payload) => {
    const userSchedulingPagesDB = new UserSchedulingPagesDB(payload.userId)
    return userSchedulingPagesDB.create(payload.schedulingPage)
  },

  updateUserSchedulingPage: async ({ commit }, payload) => {
    const userSchedulingPagesDB = new UserSchedulingPagesDB(payload.userId)
    return userSchedulingPagesDB.update(payload.schedulingPage)
  },

  deleteUserSchedulingPage: async ({ commit }, payload) => {
    const userSchedulingPagesDB = new UserSchedulingPagesDB(payload.userId)
    return userSchedulingPagesDB.delete(payload.schedulingPageId)
  },
}
