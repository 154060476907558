<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="form add-company-location">
    <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="false">
      <b-icon
        pack="fas"
        icon="spinner"
        size="is-large"
        custom-class="fa-pulse"
        type="is-primary"
      >
      </b-icon
    ></b-loading>
    <synform
      v-if="companyToEdit && !isLoading"
      :title="panelTitle"
      :operation="addCompanyTokenVue"
      :button-text="panelTitle"
      :modal-form="true"
    >
      <b-notification
        v-if="showError"
        type="is-danger is-light"
        aria-close-label="Close notification"
        class="sm-help-notification"
        ><span v-html="errorMessage"></span
      ></b-notification>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="displayName"
        rules="required:true|min:2"
      >
        <b-field
          :label="showLabelsFromCode('form/personalInfo/fullname')"
          class="required"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            ref="displayName"
            v-model.trim="form.createdFor.displayName"
            name="displayName"
            :placeholder="showLabelsFromCode('profile/personalInfo/fullname')"
          />
        </b-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="email"
        rules="required|min:8|max:40|email"
      >
        <b-field
          :label="`Business ${showLabelsFromCode('form/generic/email')}`"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="
            errors[0]
              ? errors
              : 'Business email only, personal email are prohibited'
          "
          class="required"
        >
          <b-input
            ref="email"
            v-model.trim="form.createdFor.email"
            type="email"
            name="email"
            placeholder="Valid Business Email Address is required"
          >
          </b-input>
        </b-field>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors, valid }"
        name="startDateTime"
        rules="required"
      >
        <b-field
          label="License Start Date"
          class="required"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-datepicker
            v-model="form.startDateTime"
            required
            icon="calendar-day"
            icon-pack="fas"
            :min-date="minDateTimeStart"
            :icon-right="form.startDateTime ? 'close-circle' : ''"
            icon-right-clickable
            editable
            position="is-bottom-left"
          ></b-datepicker>
        </b-field>
      </ValidationProvider>
      <b-message class="mt-3 mb-0" type="is-danger" size="is-small">
        Guest User Tokens are valid for 3 months only. Only one token allowed
        per email.
      </b-message>
    </synform>
  </div>
</template>

<script>
/* eslint-disable import/extensions */

import { mapState, mapMutations, mapActions } from 'vuex'
import { editCompanyMixin } from '@/mixins/company/index'
import { sendEmail } from '@/misc/user-messages'

// eslint-disable-next-line import/no-extraneous-dependencies
const emailInspector = require('email-inspector')

export default {
  name: 'AddCompanyTokenFormModal',
  components: {},
  mixins: [editCompanyMixin],
  data() {
    const today = new Date()
    return {
      panelTitle: `${this.showLabelsFromCode(
        'generic/add',
      )} ${this.showLabelsFromCode('company/tokens/panelTitle')}`,
      isLoading: true,
      showError: false,
      errorMessage: '',
      minDateTimeStart: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
      ),
      form: {
        createdFor: {
          displayName: '',
          email: '',
        },
        startDateTime: new Date(new Date().setHours(0, 0, 0, 0)),
      },
    }
  },
  computed: {
    ...mapState('company', ['companyTokens']),
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations('company', ['addToCompanyTokens']),
    ...mapActions('company', ['createCompanyTokenAction']),
    addCompanyTokenVue() {
      this.isLoading = true
      const index = this.companyTokens.findIndex(
        (token) => token.createdFor.email === this.form.createdFor.email,
      )
      if (index !== -1) {
        this.errorMessage = 'Sorry, this email has already been used before.'
        this.showError = true
        this.isLoading = false
      } else if (!emailInspector.validate(this.form.createdFor.email)) {
        this.errorMessage = 'Sorry, personal email addresses are prohibited.'
        this.showError = true
        this.isLoading = false
      } else {
        this.createCompanyTokenAction({
          token: this.form,
          user: this.user,
          company: this.companyToEdit,
        })
          .then((batchResponse) => {
            // console.log(JSON.stringify(batchResponse))
            this.addToCompanyTokens(batchResponse)
            this.trackEngagementEvent(
              'company_token_added',
              `${this.user.displayName} added company token for ${this.form.createdFor.email}`,
              'page',
              'button',
            )
            sendEmail(
              this.form.createdFor.email,
              this.form.createdFor.displayName,
              this.user.displayName,
              this.user.email,
              `Hi ${this.form.createdFor.displayName}; ${this.user.displayName} would like you to join their network on ${process.env.VUE_APP_TITLE}. They have provided you with a token number : ${batchResponse.id} which you will need to register your account. You can create your account by going to ${process.env.VUE_APP_URL}g/register or follow the prompts on the Register screen. Welcome to the ${process.env.VUE_APP_TITLE} community, we are looking forward to having you onboard. `,
            ).catch((error) => {
              this.trackExceptionEvent(
                'new-token-email_sent_failed',
                error.text,
                false,
              )
              this.handleErrorMessage(
                `Error in sending onboarding email to ${this.form.createdFor.displayName} at ${this.form.createdFor.email}`,
              )
              this.showError = true
              this.errorMessage = `Error in sending onboarding email to ${this.form.createdFor.displayName} at ${this.form.createdFor.email}. Please reach out to them and provide the token number : ${batchResponse.id}. They will need that to register on the application.`
            })
            this.$emit('added_successfully', batchResponse)
            this.close()
          })
          .catch((err) => {
            this.trackExceptionEvent(
              'company-token_add_failed',
              `By Uid ${this.user.id} Code: ${err.code}`,
            )
            this.showError = true
            this.errorMessage = `${this.showUserMessageFromCode(
              'form/generic/errorMessage',
            )}<br/> Code : <b>'${err.code}'</b>`
            console.log(err)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>
