/* eslint-disable no-unused-vars */

import TeamBookinsDB from '@/firebase/teams/team-bookings'

export default {
  fetchTeamBookingsByTeamId: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const teamBookingsDB = new TeamBookinsDB(payload.teamId)
    return teamBookingsDB.readAll(payload.constraints || null)
  },

  fetchTeamBookingsById: async ({ commit }, payload) => {
    const teamBookingsDB = new TeamBookinsDB(payload.teamId)
    return teamBookingsDB.read(payload.id)
  },

  createTeamBooking: async ({ commit }, payload) => {
    // console.log(JSON.stringify(payload))
    const teamBookingsDB = new TeamBookinsDB(payload.teamId)
    return teamBookingsDB.create(payload.teamBooking)
  },

  updateTeamBooking: async ({ commit }, payload) => {
    const teamBookingsDB = new TeamBookinsDB(payload.teamId)
    return teamBookingsDB.update(payload.teamBooking)
  },

  deleteTeamBooking: async ({ commit }, payload) => {
    const teamBookingsDB = new TeamBookinsDB(payload.teamId)
    return teamBookingsDB.delete(payload.teamBookingId)
  },
}
