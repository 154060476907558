<!-- ALERT Always send precalculated src to this component else it shows promise. -->
<!-- Use this when showing profile pics which are not stored in user.photoURL -->
<!-- E.g. when stored in network node. This is to avoid stale images. -->
<!-- Rest just use imgSrc i.e. direct URL of image -->
<!--E.g. <view-user-avatar :uid="props.row.id"/> -->
<!--E.g. <view-user-avatar :imgsrc="user.photoURL"/> -->

<template>
  <div class="view-user-avatar">
    <img
      :src="imgsrc || calcsrc"
      :class="['border-color-override', isImageLoaded ? 'show' : '']"
      :alt="renderAlt"
      @load="imgLoadedMethod"
      @error="setAltImg"
    />
  </div>
</template>

<script>
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
import { mapState } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { getDownloadURL } from '@/firebase/helpers/storage'

export default {
  name: 'ViewUserAvatar',
  props: {
    altName: {
      type: String,
      default: null,
    },
    uid: {
      type: String,
      default: null,
    },
    imgsrc: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      calcsrc: '',
      firebaseStorageBaseURL: process.env.VUE_APP_FIRESTORE_BASEURL,
      isImageLoaded: false,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    renderAlt() {
      if (this.altName) {
        return this.altName
      }
      return 'Profile Photo'
    },
  },
  created() {
    // console.log(this.imgsrc)
    this.buildURL()
  },
  methods: {
    async buildURL() {
      if (!isEmpty(this.uid)) {
        const response = await getDownloadURL(`avatars/${this.uid}/profile.png`)
        this.calcsrc = response
      }
    },
    setAltImg(e) {
      const name = this.altName || 'SM'
      const src = `https://ui-avatars.com/api/?name=${encodeURIComponent(
        name.trim(),
      )}&background=FFFFFF&color=48beb4&size=64&font-size=0.5&bold=true`
      // console.log(JSON.stringify(src))
      e.target.onerror = null
      e.target.src = src
    },
    imgLoadedMethod() {
      this.isImageLoaded = true
    },
  },
}
</script>
<style scoped lang="scss">
img {
  opacity: 0;
  border-radius: 50%;
  border: 2px solid $primary;
}
img.show {
  opacity: 1;
}
</style>
