<template>
  <span>
    <b-dropdown v-model="navigation" position="is-bottom-left" append-to-body aria-role="menu">
      <template #trigger>
        <a>
          <span v-if="addtext" class="ml-2">{{ addtext }}</span
          ><b-icon icon-pack="fas" icon="circle-chevron-down"></b-icon>
        </a>
      </template>
      <b-dropdown-item has-link aria-role="menuitem">
        <a target="_blank" @click="openCalendarWindow('google')">
          <i class="fab fa-google mr-2"></i>Add To Google
        </a>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <a target="_blank" @click="openCalendarWindow('microsoft')">
          <i class="fab fa-microsoft mr-2"></i>Add To Outlook
        </a>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <a target="_blank" @click="openCalendarWindow('ics')">
          <i class="fas fa-calendar mr-2"></i>
          Download *ics file
        </a>
      </b-dropdown-item>
    </b-dropdown>
  </span>
</template>

<script>
/* eslint-disable import/extensions */
import { GoogleCalendar, OutlookCalendar, ICalendar } from 'datebook'
// eslint-disable-next-line import/no-extraneous-dependencies
import * as FileSaver from 'file-saver'

export default {
  name: 'AddToCalendar',
  components: {},
  props: {
    eventName: {
      type: String,
      default: '',
    },
    meetingURL: {
      type: String,
      default: '',
    },
    eventDescription: {
      type: String,
      default: '',
    },
    startDateTime: {
      type: Date,
      default: new Date(),
    },
    endDateTime: {
      type: Date,
      default: new Date(),
    },
    attendees: {
      type: Array,
    },
    addtext: {
      type: String,
      default: 'Add To',
    },
  },
  data() {
    return {}
  },
  methods: {
    openCalendarWindow(type) {
      const config = {
        title: this.eventName,
        location: this.meetingURL,
        description: this.eventDescription,
        start: new Date(this.startDateTime),
        end: new Date(this.endDateTime),
        attendees: this.attendees,
      }
      switch (type) {
        case 'google': {
          const googleCalendar = new GoogleCalendar(config)
          window.open(googleCalendar.render(), '_blank')
          break
        }
        case 'microsoft': {
          const outlookCalendar = new OutlookCalendar(config)
          window.open(outlookCalendar.render(), '_blank')
          break
        }
        default: {
          const iCalendar = new ICalendar(config)
          const ics = iCalendar.render()
          const blob = new Blob([ics], {
            type: 'text/calendar',
          })
          FileSaver.saveAs(blob, `${this.eventName}.ics`)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
