export default {
  schedulingPages: [],
  schedulingPageToView: undefined,
  schedulingPageToEdit: undefined,
  bookingErrors: {
    bookingNotFound: false,
    bookingExpired: false,
    bookingIsPrivate: false,
    noMoreBookingsAvailable: false,
    noProfileFound: false,
    profileHasNoCalendarIntegrated: false,
  },
}
