<template>
  <div class="view-shows">
    <div class="view-shows__heading view-shows__heading--primary">
      <span class="view-shows__heading--emoji">📺</span
      ><span class="view-shows__heading--title">Currently watching</span>
      <b-button
        tag="router-link"
        :to="{
          name: 'listshows',
          params: { uid: profileUser.id, mode: 'view' },
          fromScreen: 'viewprofile',
          shows: shows,
        }"
        class="view-shows__heading--button"
        size="is-small"
        icon-left="star"
        pack="fas"
        type="is-ghost"
        >{{ showLabelsFromCode('generic/ratings') }}</b-button
      >
    </div>
    <div
      class="columns is-mobile is-multiline is-vcentered is-narrow is-gapless mt-2"
    >
      <div
        v-for="(tvshow, index) in shows"
        :key="tvshow.id"
        class="column is-3"
      >
        <a
          class="view-shows__link"
          :href="`${showBaseURL}${tvshow.TMDB_ID}`"
          :target="'_blank'"
          @click="
            trackConversionEvent(
              'tvshow_clicked',
              `${tvshow.title} by ${tvshow.author}`,
              tvshow.link,
              `User ${user.id} clicked tvshow ${index + 1} of ${shows.length} `,
            )
          "
        >
          <img
            class="view-shows__coverimage border-color--primary"
            :src="tvshow.coverImagePath"
            :alt="`${tvshow.title} by ${tvshow.author}`"
            :title="`${tvshow.title}`"
            @error="setAltImg"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { trackConversionEvent } from '@/misc/analytics'
import { viewProfileMixin } from '@/mixins/profile/index'

export default {
  name: 'ViewShows',
  mixins: [viewProfileMixin],
  props: {
    shows: Object,
  },
  data() {
    return {
      noCoverImage: process.env.VUE_APP_NO_COVER_IMAGE,
      showBaseURL: process.env.VUE_APP_TMDB_SHOWS_BASEURL,
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },

  methods: {
    setAltImg(e) {
      e.target.onerror = null
      e.target.src = this.noCoverImage
    },
    trackConversionEvent,
  },
}
</script>

<style lang="scss" scoped></style>
